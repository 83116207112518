import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {errorLoggingMiddleware} from '@app/store/middlewares/errorLogging.middleware';
import rootReducer from '@app/store/slices';

import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist';

const loginPersistConfig = {
  key: 'auth',
  storage,
};

const hotelPersistConfig = {
  key: 'hotelSlice',
  storage,
};

const commonPersistConfig = {
  key: 'commonSlice',
  storage,
};
const userPersistConfig = {
  key: 'user',
  storage,
};

const servicePersistConfig = {
  key: 'serviceSlice',
  storage,
};

const waiterDasboardPersistConfig = {
  key: 'waiterDasboard',
  storage,
};

const categoryNavPersistConfig = {
  key: 'foodCard',
  storage,
};

const loginReducer = persistReducer(loginPersistConfig, rootReducer.auth);
const hotelReducer = persistReducer(hotelPersistConfig, rootReducer.hotelSlice);
const commonReducer = persistReducer(commonPersistConfig, rootReducer.commonSlice);
const userReducer = persistReducer(userPersistConfig, rootReducer.user);
const serviceReducer = persistReducer(servicePersistConfig, rootReducer.serviceSlice);
// const waiterDasboardReducer = persistReducer(waiterDasboardPersistConfig, rootReducer.waiterDasbordSlice);
const categoryNavReducer = persistReducer(categoryNavPersistConfig, rootReducer.categoryNav);

// const userReducer2 = persistReducer(navPersistConfig, rootReducer.catNav);

const rootReducerAll = combineReducers({
  ...rootReducer,
  auth: loginReducer,
  hotelSlice: hotelReducer,
  commonSlice: commonReducer,
  user: userReducer,
  serviceSlice: serviceReducer,
  // waiterDasboardReducer: waiterDasboardReducer,
  categoryNavReducer: categoryNavReducer,
});

export const store = configureStore({
  reducer: rootReducerAll,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(errorLoggingMiddleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
