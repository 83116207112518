import restInstance, {RESTAURANT_SERVICE} from '@app/api/resturantInstance';
export type VatRegistryType = 'RESERVATION' | 'RESTAURANT' | 'ADDITIONAL_SERVICE' | 'ADDITIONAL_ACTIVITY';

export interface VatRegistryRequest {
  id?: number;
  name: string;
  address: string;
  vatNumber: string;
  email: string;
  hotelId: number;
  registryType: VatRegistryType[];
  groupId:number
}

export interface VatRegistryResponse {
  message: string;
  result: any;
  status: string;
  statusCode: string;
}

export const createVatRegistry = (payload: VatRegistryRequest): Promise<VatRegistryResponse> =>
  restInstance.post<VatRegistryResponse>(RESTAURANT_SERVICE + 'vat-registry', payload).then(({data}) => data);

export const getAllVatRegistry = (
  hotelId: number,
  groupId: number,
  searchQuery: any,
  pageSize: number | undefined,
  current: number,
  status: string[],
): Promise<VatRegistryResponse> => {
  const joinedStatuses = status.join(',');
  return restInstance
    .get<VatRegistryResponse>(
      RESTAURANT_SERVICE +
        `vat-registry/get?page=${current}&size=${pageSize}&sortField=id&direction=ASC&id=6&name=${searchQuery?.name?searchQuery?.name:""}&address=${searchQuery?.address?searchQuery?.address:''}&vatNumber=${searchQuery?.vatNumber?searchQuery?.vatNumber:''}&email=${searchQuery?.email?searchQuery?.email:''}&hotelId=${hotelId}&groupsId=${groupId}&registryType=${joinedStatuses}`,
    )
    .then(({data}) => data);
};
export const updateVatRegistry = (payload: VatRegistryRequest): Promise<VatRegistryResponse> =>
  restInstance.put<VatRegistryResponse>(RESTAURANT_SERVICE + 'vat-registry', payload).then(({data}) => data);

export const deleteVatRegistry = (id: number): Promise<VatRegistryResponse> =>
  restInstance.delete<VatRegistryResponse>(RESTAURANT_SERVICE + `vat-registry/${id}`).then(({data}) => data);

export const searchVatRegistryUser = (email: string, vatNo: string): Promise<VatRegistryResponse> =>
  restInstance
    .get<VatRegistryResponse>(RESTAURANT_SERVICE + `vat-registry?email=${email}&vatNumber=${vatNo}`)
    .then(({data}) => data);
