/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import * as S from './ServiceReservation.style';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {setIsTouchAction, setLoading, setModalVisible} from '@app/store/slices/commonSlice';
import {Col, Radio, Row, Select} from 'antd';
import {Input, TextArea} from '@app/components/common/inputs/Input/Input';
import {Option} from '@app/components/common/selects/Select/Select';
import styled from 'styled-components';
import {DatePicker} from '@app/components/common/pickers/DatePicker';
import {
  createAdditionalBooking,
  getAvailableAdditionalServices,
  getCheckedInReservations,
} from '@app/api/hotel/additionalBookings/additionalBookings.api';
import {IAvailableService, ICreatePayload} from './interface';
import {find, isEmpty} from 'lodash';
import moment from 'moment';
import {BOOKING_METHOD, NATIONALITY} from '@app/shared/constants';
import {notificationController} from '@app/controllers/notificationController';
import {convertNumberFormatWithDecimal} from '@app/utils/utils';
import {
  calculateTotalTaxWithSequence,
  checkVATAvailability,
  validateEmail,
  validateEmailWithoutRequired,
} from '@app/utils/functions';
import {BASE_COLORS} from '@app/styles/themes/constants';
import Tooltip from '@app/components/common/Tooltip/Tooltip';
import {Checkbox} from '@app/components/common/Checkbox/Checkbox';
import {AutoComplete} from '@app/components/common/AutoComplete/AutoComplete';
import {searchedExactVatUsersApi, searchedVatUsersApi} from '@app/api/hotel/guest/guest.api';
import {Switch} from '@app/components/common/Switch/Switch';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';

export default function MakeReservation({form, reloadData, isClosedModal}: any) {
  const {hotelId, groupId} = useAppSelector(state => state.hotelSlice.hotelConfig);
  const [availableServices, setavailableServices] = useState<IAvailableService[]>();
  const [checkedInReservations, setCheckedInReservations] = useState([]);
  const [selectedService, setselectedService]: any = useState<IAvailableService>();
  const [selectedMethod, setselectedMethod] = useState<'PER_HOUR' | 'FULL_DAY' | 'HALF_DAY'>();
  const [totalAmount, settotalAmount] = useState(0);
  const [finalTotalAmount, setFinalTotalAmount] = useState(0);
  const [isResident, setisResident]: any = useState(true);
  const [selectedInvoiceType, setselectedInvoiceType] = useState(3);
  const [selectedReservation, setselectedReservation]: any = useState({});
  const [isVatDetailsAvailable, setisVatDetailsAvailable] = useState(false);
  const [searchedVatUsers, setsearchedVatUsers] = useState([]);
  const [isExistingVatUser, setisExistingVatUser] = useState(false);
  const [vatUserFormData, setvatUserFormData]: any = useState({
    vatPersonName: null,
    vatNumber: null,
    vatPersonEmail: null,
    vatPersonAddress: null,
  });
  const [isVatActive, setisVatActive] = useState(false);
  const [isVatApplicable, setisVatApplicable] = useState(false);

  const disabledDate = (current: {isBefore: (arg0: any) => any; isAfter: (arg0: any) => any}) => {
    const checkInDate: any = new Date(selectedReservation && selectedReservation?.checkInDate);
    const checkOutDate: any = new Date(selectedReservation && selectedReservation?.checkOutDate);
    const nextDayofCheckout: any = new Date(checkOutDate);

    const currentDate: any = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    nextDayofCheckout.setDate(checkOutDate.getDate() + 1);
    if (!checkInDate || !nextDayofCheckout) {
      return false;
    }
    return current.isBefore(checkInDate) || current.isAfter(nextDayofCheckout) || current.isBefore(currentDate);
  };

  const CustomDatePicker = styled(DatePicker)`
    width: 100%;
  `;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isClosedModal) {
      resetVatDetails();
      setselectedService({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClosedModal]);

  const onCloseModal = () => {
    form.resetFields();
    dispatch(setLoading(false));
    reloadData();
    resetVatDetails();
    setselectedService({});
  };

  const onFinish = async (data: any) => {
    const findObj: any = checkedInReservations.find(
      (reservation: {reservedRoomId: number}) => reservation.reservedRoomId === data.reservedRoomId,
    );

    const updatedTaxList = selectedService?.taxList?.map((tax: any) => ({
      ...tax,
      useReservation: tax.vat ? isVatApplicable : tax.serviceCharge ? selectedService.taxInclude : tax.useReservation,
    }));

    const payload: ICreatePayload[] = [
      {
        additionalServiceId: data?.serviceId,
        checkInDate: data?.checkInDate,
        checkInTime: moment(data?.checkInTime).format('hh:mm:ss'),
        comments: data?.comments,
        hallTime: data?.hallTime,
        numberOfHour: data?.hours,
        ownerName: data?.ownerName,
        resident: selectedInvoiceType === 3 ? data?.resident : findObj?.resident,
        numberOfDays: data?.days,
        count: selectedService?.hallId === null ? parseInt(data?.count) : 1,
        totalPrice: totalAmount,
        reservedRoomsId:
          selectedInvoiceType === 1 ? data?.reservedRoomId : selectedInvoiceType === 2 ? data?.roomId : null,
        advancedPayment: data?.advancedPayment,
        paymentMethod: data?.paymentMethod,
        taxList: updatedTaxList,
        vatRegistry: {
          email: data?.vatPersonEmail,
          registryType: ['ADDITIONAL_SERVICE'],
          hotelId: hotelId,
          vatNumber: data?.vatNumber,
          onlyThisHotelView: false,
          name: data?.vatPersonName,
          address: data?.vatPersonAddress,
        },
        vatRegistryApplicable: isVatDetailsAvailable,
        vatApplicable: isVatDetailsAvailable,
      },
    ];
    try {
      const response = await createAdditionalBooking(payload);
      if (response.statusCode === '20000') {
        notificationController.success({message: 'Additional service booked successfully'});
        setselectedInvoiceType(3);
        setselectedReservation({});
      } else {
        notificationController.error({message: response.message});
      }
      dispatch(setModalVisible(false));
      onCloseModal();
    } catch (error) {}
  };

  const getAvailableServices = async (resident: boolean, checkInDate: string) => {
    try {
      const data = await getAvailableAdditionalServices(hotelId, resident, checkInDate);
      setavailableServices(data.result.additionalServiceReservation);
    } catch (error) {}
  };

  const getCheckedInReservationsData = async () => {
    try {
      const data = await getCheckedInReservations(hotelId);
      setCheckedInReservations(data.result.additionalServiceReservation);
    } catch (error) {}
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelect = (value: any) => {
    const findObj = find(availableServices, data => data.additionalServiceId === value);
    if (findObj !== undefined) {
      if (findObj.fullDayPrice === null && findObj.halfDayPrice === null && findObj.hourPrice === null) {
        notificationController.info({message: 'Enter the relevant Price for the selected Service', placement: 'top'});
        setselectedService({});
        return;
      }

      setselectedService(findObj);
      const isActiveVat = checkVATAvailability(findObj?.taxList);
      setisVatActive(isActiveVat);
      if (selectedInvoiceType === 3) {
        setisVatApplicable(true);
      } else {
        setisVatApplicable(selectedReservation?.vatApplicable);
      }
    }
  };

  const calculateAmount = (vatAvailable: boolean) => {
    const count = form.getFieldValue('count') === undefined ? 1 : form.getFieldValue('count');
    const method = selectedMethod;
    const taxListWithoutServiceCharge = selectedService?.taxList?.filter(
      (tax: {serviceCharge: boolean}) => tax.serviceCharge !== true,
    );
    if (method === 'PER_HOUR') {
      const hours = form.getFieldValue('hours');
      const price: any = selectedService?.hourPrice || 0;
      const amount = parseFloat(hours) * parseFloat(price) * parseFloat(count);

      const totalAmount = isNaN(amount)
        ? 0
        : !selectedService?.taxInclude
        ? amount + calculateTotalTaxWithSequence(amount, taxListWithoutServiceCharge, vatAvailable).totalTaxAmount
        : selectedService?.taxInclude
        ? amount + calculateTotalTaxWithSequence(amount, selectedService?.taxList, vatAvailable).totalTaxAmount
        : amount;
      form.setFieldValue(
        'totalAmount',
        `${convertNumberFormatWithDecimal(totalAmount, 2)} ${selectedService?.taxInclude ? '(Inc. taxes)' : ''}`,
      );
      settotalAmount(isNaN(amount) ? 0 : amount);
      setFinalTotalAmount(totalAmount);
    } else if (method === 'FULL_DAY') {
      const days = form.getFieldValue('days');
      const price: any = selectedService?.fullDayPrice || 0;
      const amount = parseFloat(days) * parseFloat(price) * parseFloat(count);
      const totalAmount = isNaN(amount)
        ? 0
        : !selectedService?.taxInclude
        ? amount + calculateTotalTaxWithSequence(amount, taxListWithoutServiceCharge, vatAvailable).totalTaxAmount
        : selectedService?.taxInclude
        ? amount + calculateTotalTaxWithSequence(amount, selectedService?.taxList, vatAvailable).totalTaxAmount
        : amount;
      form.setFieldValue(
        'totalAmount',
        `${convertNumberFormatWithDecimal(totalAmount, 2)} ${selectedService?.taxInclude ? '(Inc. taxes)' : ''}`,
      );
      settotalAmount(isNaN(amount) ? 0 : amount);
      setFinalTotalAmount(totalAmount);
    } else if (method === 'HALF_DAY') {
      const price: any = selectedService?.halfDayPrice || 0;
      const amount = parseFloat(price) * parseFloat(count);
      const totalAmount = isNaN(amount)
        ? 0
        : !selectedService?.taxInclude
        ? amount + calculateTotalTaxWithSequence(amount, taxListWithoutServiceCharge, vatAvailable).totalTaxAmount
        : selectedService?.taxInclude
        ? amount + calculateTotalTaxWithSequence(amount, selectedService?.taxList, vatAvailable).totalTaxAmount
        : amount;
      form.setFieldValue(
        'totalAmount',
        `${convertNumberFormatWithDecimal(totalAmount, 2)} ${selectedService?.taxInclude ? '(Inc. taxes)' : ''}`,
      );
      settotalAmount(isNaN(amount) ? 0 : amount);
      setFinalTotalAmount(totalAmount);
    }
  };

  useEffect(() => {
    // getAvailableServices(isResident);
    getCheckedInReservationsData();
  }, []);

  const invoiceType = [
    {label: 'Cash', value: 3},
    {label: 'Reservation', value: 1},
    {label: 'Room', value: 2},
  ];

  const onSearchRooms = (value: string) => {
    //
  };
  const onSearchRoomName = (value: string) => {
    //
  };

  const resetVatSearchedData = () => {
    setsearchedVatUsers([]);
  };

  const handleSearchVatUsers = async (value: string, searchField: string) => {
    let searchParams = {};
    if (searchField === 'vatPersonEmail') {
      searchParams = {email: value};
    } else if (searchField === 'vatNumber') {
      searchParams = {vatNumber: value};
    }

    const result = await searchedVatUsersApi(groupId, searchParams, 20, 0);

    const data = result?.result?.Guests?.map(
      (item: {id: number; vatNumber: number; address: string; name: string; email: string}) => ({
        id: item.id,
        vatNumber: item.vatNumber,
        address: item.address,
        name: item.name,
        email: item.email,
        value: item.id,
        label: searchField === 'vatPersonEmail' ? item.email : item.vatNumber,
      }),
    );

    setsearchedVatUsers(data);
  };

  const handlePopulateVatInformation = (vatDetails: any) => {
    const {name, address, vatNumber, email} = vatDetails;
    const fieldData = {
      vatPersonName: name,
      vatNumber: vatNumber,
      vatPersonEmail: email,
      vatPersonAddress: address,
    };
    form.setFieldsValue(fieldData);
    setvatUserFormData(fieldData);
    setisExistingVatUser(true);
  };

  const handleChangeVatInput = (changedFieldName: string) => {
    const fields = ['vatPersonName', 'vatNumber', 'vatPersonEmail', 'vatPersonAddress'];
    const fieldsForReset = fields.filter(o => o !== changedFieldName);

    if (isExistingVatUser && vatUserFormData[changedFieldName] !== null) {
      form.resetFields(fieldsForReset);
      setvatUserFormData({
        vatPersonName: null,
        vatNumber: null,
        vatPersonEmail: null,
        vatPersonAddress: null,
      });
      setisExistingVatUser(false);
    }
  };

  const handleClickVATDetails = (checked: boolean) => {
    setisVatDetailsAvailable(checked);
    if (selectedReservation?.vatRegistry && selectedReservation?.vatRegistry?.vatNumber !== null) {
      const vatFormData = {
        vatPersonName: selectedReservation.vatRegistry.name,
        vatNumber: selectedReservation.vatRegistry.vatNumber,
        vatPersonEmail: selectedReservation.vatRegistry.email,
        vatPersonAddress: selectedReservation.vatRegistry.address,
      };
      form.setFieldsValue(vatFormData);
      setvatUserFormData(vatFormData);
    }
  };

  const resetVatDetails = () => {
    form.resetFields(['vatPersonName', 'vatNumber', 'vatPersonEmail', 'vatPersonAddress']);
    setisVatDetailsAvailable(false);
    setsearchedVatUsers([]);
    setisExistingVatUser(false);
    setvatUserFormData({
      vatPersonName: null,
      vatNumber: null,
      vatPersonEmail: null,
      vatPersonAddress: null,
    });
    setisVatActive(false);
    setisVatApplicable(false);
  };

  const handleBlurPopulate = async (value: string, searchField: string) => {
    let searchParams = {};
    if (searchField === 'vatPersonEmail') {
      searchParams = {email: value};
    } else if (searchField === 'vatNumber') {
      searchParams = {vatNumber: value};
    }
    const result = await searchedExactVatUsersApi(groupId, searchParams, 20, 0);
    const data = result?.result?.Guests?.map(
      (item: {id: number; vatNumber: number; address: string; name: string; email: string}) => ({
        id: item.id,
        vatNumber: item.vatNumber,
        address: item.address,
        name: item.name,
        email: item.email,
        value: item.id,
        label: searchField === 'vatPersonEmail' ? item.email : item.vatNumber,
      }),
    );
    if (!isEmpty(data)) handlePopulateVatInformation(data[0]);
  };

  return (
    <S.FormContent>
      <BaseForm
        initialValues={{
          resident: true,
          invoiceType: 3,
          paymentMethod: 'CASH',
        }}
        name="stepForm"
        form={form}
        onFinish={onFinish}
        onFieldsChange={(_, allFields) => {
          dispatch(setIsTouchAction(true));
          calculateAmount(isVatApplicable);
        }}>
        <Row gutter={{xs: 10, md: 15, xl: 30}}>
          <Col xs={24} md={18} xl={12}>
            <BaseForm.Item
              name="invoiceType"
              label="Cash/Room"
              rules={[{required: false, message: 'field is required'}]}>
              <Radio.Group
                options={invoiceType}
                onChange={e => {
                  setselectedService(undefined);
                  setselectedReservation({});
                  setselectedInvoiceType(e.target.value);
                  resetVatDetails();
                  if (e.target.value === 1 || e.target.value === 2) {
                    setavailableServices([]);
                  } else {
                    // getAvailableServices(true);
                  }
                  form.resetFields([
                    'serviceId',
                    'count',
                    'ownerName',
                    'hallTime',
                    'hours',
                    'days',
                    'checkInDate',
                    'checkInTime',
                    'resident',
                    'reservationCheckinDate',
                  ]);
                  setisResident(true);
                }}
              />
            </BaseForm.Item>
          </Col>

          {selectedInvoiceType === 1 ? (
            <>
              <Col xs={24} sm={24} md={12} xl={6}>
                <BaseForm.Item
                  name="reservedRoomId"
                  label="Reservation"
                  rules={[{required: true, message: 'Reservation is required'}]}>
                  <Select
                    showSearch
                    onSelect={(_value: any) => {
                      const findObj: any = checkedInReservations.find(
                        (reservation: {reservedRoomId: number}) => reservation.reservedRoomId === _value,
                      );

                      resetVatDetails();
                      form.setFieldValue('reservationCheckinDate', `${findObj.checkInDate} - ${findObj.checkOutDate}`);
                      setselectedReservation(findObj);
                      setisResident(findObj?.resident);
                      const name = findObj !== undefined ? `${findObj?.guestFirstName} ${findObj?.guestLastName}` : '';
                      form.resetFields([
                        'serviceId',
                        'count',
                        'ownerName',
                        'hallTime',
                        'hours',
                        'days',
                        'checkInDate',
                        'checkInTime',
                        'resident',
                      ]);
                      form.setFieldValue('ownerName', name);
                      setselectedService(undefined);
                    }}
                    options={
                      checkedInReservations &&
                      checkedInReservations.map(
                        (
                          reservation: {
                            reservedRoomId: number;
                            reservationRefNumber: number;
                            reservedRoomNumber: string;
                            roomName: string;
                          },
                          idx,
                        ) => {
                          return {
                            label: (
                              <Tooltip
                                title={`${reservation?.reservationRefNumber} - ${reservation?.roomName} (
                                  ${reservation?.reservedRoomNumber})`}>
                                <S.CustomOptionText>
                                  {`${reservation?.reservationRefNumber} - ${reservation?.roomName} (${reservation?.reservedRoomNumber})`}
                                </S.CustomOptionText>
                              </Tooltip>
                            ),
                            value: reservation?.reservedRoomId,
                          };
                        },
                      )
                    }
                    onSearch={onSearchRooms}
                    filterOption={(input, option) => {
                      const optionText = option?.label?.props?.title ? option?.label?.props?.title.toString() : ''; // Convert option.children to string
                      return optionText.toLowerCase().includes(input.toLowerCase());
                    }}
                    defaultValue={true}
                    placeholder="Select reservation"
                  />
                </BaseForm.Item>
              </Col>
              <Col xs={24} sm={24} md={12} xl={6}>
                <BaseForm.Item name="reservationCheckinDate" label="Reservation Date" rules={[{required: false}]}>
                  <Input disabled />
                </BaseForm.Item>
              </Col>
            </>
          ) : selectedInvoiceType === 2 ? (
            <>
              <Col xs={24} sm={24} md={12} xl={6}>
                <BaseForm.Item name="roomId" label="Room" rules={[{required: true, message: 'Room is required'}]}>
                  <Select
                    showSearch
                    onSelect={(_value: any) => {
                      const findObj: any = checkedInReservations.find(
                        (reservation: {reservedRoomId: number}) => reservation.reservedRoomId === _value,
                      );

                      resetVatDetails();
                      form.setFieldValue('reservationCheckinDate', `${findObj.checkInDate} - ${findObj.checkOutDate}`);
                      setselectedReservation(findObj);
                      setisResident(findObj?.resident);
                      const name = findObj !== undefined ? `${findObj?.guestFirstName} ${findObj?.guestLastName}` : '';
                      form.resetFields([
                        'serviceId',
                        'count',
                        'ownerName',
                        'hallTime',
                        'hours',
                        'days',
                        'checkInDate',
                        'checkInTime',
                        'resident',
                      ]);
                      form.setFieldValue('ownerName', name);
                      setselectedService(undefined);
                    }}
                    defaultValue={true}
                    placeholder="Select reservation"
                    options={
                      checkedInReservations &&
                      checkedInReservations.map(
                        (
                          reservation: {
                            reservedRoomId: number;
                            reservationRefNumber: number;
                            roomName: string;
                            reservedRoomNumber: string;
                          },
                          idx,
                        ) => {
                          return {
                            label: (
                              <Tooltip
                                title={`${reservation?.roomName} - ${reservation?.reservedRoomNumber} (
                                  ${reservation?.reservationRefNumber})`}>
                                <S.CustomOptionText>
                                  {`${reservation?.roomName} - ${reservation?.reservedRoomNumber} (
                                  ${reservation?.reservationRefNumber})`}
                                </S.CustomOptionText>
                              </Tooltip>
                            ),
                            value: reservation?.reservedRoomId,
                          };
                        },
                      )
                    }
                    onSearch={onSearchRoomName}
                    filterOption={(input, option) => {
                      const optionText = option?.label?.props?.title ? option?.label?.props?.title.toString() : ''; // Convert option.children to string
                      return optionText.toLowerCase().includes(input.toLowerCase());
                    }}
                  />
                </BaseForm.Item>
              </Col>
              <Col xs={24} sm={24} md={12} xl={6}>
                <BaseForm.Item name="reservationCheckinDate" label="Reservation Date" rules={[{required: false}]}>
                  <Input disabled />
                </BaseForm.Item>
              </Col>
            </>
          ) : null}

          {selectedInvoiceType === 3 && (
            <Col xs={24} sm={24} md={12} xl={6}>
              <BaseForm.Item
                name="resident"
                label="Resident/Non-Resident"
                rules={[{required: true, message: 'Service is required'}]}>
                <Select
                  onSelect={(value: any) => {
                    setisResident(value);
                    form.resetFields([
                      'serviceId',
                      'count',
                      'ownerName',
                      'hallTime',
                      'hours',
                      'days',
                      'checkInDate',
                      'checkInTime',
                    ]);
                    setselectedService(undefined);
                  }}
                  defaultValue={true}
                  placeholder="Select nationality">
                  {NATIONALITY.map(nationality => {
                    return (
                      <Option key={nationality.key} value={nationality.value}>
                        {nationality.name}
                      </Option>
                    );
                  })}
                </Select>
              </BaseForm.Item>
            </Col>
          )}
          <Col xs={24} sm={24} md={12} xl={6}>
            <BaseForm.Item
              name="checkInDate"
              label="Booking date"
              rules={[{required: true, message: 'Date is required'}]}>
              <CustomDatePicker
                onChange={(value: any, date: string) => {
                  getAvailableServices(isResident, date);
                }}
                disabledDate={disabledDate}
                dateRender={(current: any) => {
                  const checkInDate = selectedReservation && new Date(selectedReservation.checkInDate);
                  const checkOutDate = selectedReservation && new Date(selectedReservation.checkOutDate);
                  checkInDate.setDate(checkInDate.getDate() - 1);
                  if (checkInDate && checkOutDate) {
                    const currentDate = new Date(current.year(), current.month(), current.date());
                    if (currentDate >= checkInDate && currentDate <= checkOutDate) {
                      return (
                        <div style={{border: `1px solid ${BASE_COLORS.primary}`, borderRadius: '10px'}}>
                          {current.date()}
                        </div>
                      );
                    }
                  }

                  return <div>{current.date()}</div>;
                }}
              />
            </BaseForm.Item>
          </Col>
          <Col xs={24} sm={24} md={12} xl={6}>
            <BaseForm.Item
              name="serviceId"
              label="Additional Service"
              rules={[{required: true, message: 'Service is required'}]}>
              <Select onSelect={handleSelect} placeholder="Select service">
                {availableServices &&
                  availableServices.map((res, idx) => {
                    return (
                      <Option key={idx} value={res.additionalServiceId}>
                        {res.service}
                      </Option>
                    );
                  })}
              </Select>
            </BaseForm.Item>
          </Col>
          {selectedService?.hallId == null && (
            <Col xs={24} sm={24} md={12} xl={6}>
              <BaseForm.Item name="count" label="Quantity" rules={[{required: true, message: 'Quantity is required'}]}>
                <Select placeholder="Select quantity">
                  {Array.from(
                    {
                      length: Number(selectedService?.availableCount),
                    },
                    (_, index) => index + 1,
                  ).map(cNumber => {
                    return <Option key={cNumber}>{cNumber}</Option>;
                  })}
                </Select>
              </BaseForm.Item>
            </Col>
          )}
          <Col xs={24} sm={24} md={12} xl={6}>
            <BaseForm.Item name="ownerName" label="Guest Name" rules={[{required: true, message: 'Name is required'}]}>
              <Input
                disabled={selectedInvoiceType === 1 || selectedInvoiceType === 2 ? true : false}
                placeholder="Enter guest name"
              />
            </BaseForm.Item>
          </Col>
          <Col xs={24} sm={24} md={12} xl={6}>
            <BaseForm.Item
              name="hallTime"
              label="Booking rate"
              rules={[{required: true, message: 'Booking hours is required'}]}>
              <Select
                onSelect={(value: any) => {
                  setselectedMethod(value);
                }}
                placeholder="Select booking hours">
                {BOOKING_METHOD.map(method => {
                  let price;
                  switch (method.name) {
                    case 'PER_HOUR':
                      price = selectedService?.hourPrice
                        ? `${isResident ? 'LKR' : 'USD'} ${selectedService?.hourPrice}`
                        : null;
                      break;
                    case 'FULL_DAY':
                      price = selectedService?.fullDayPrice
                        ? `${isResident ? 'LKR' : 'USD'} ${selectedService?.fullDayPrice}`
                        : null;
                      break;
                    case 'HALF_DAY':
                      price = selectedService?.halfDayPrice
                        ? `${isResident ? 'LKR' : 'USD'} ${selectedService?.halfDayPrice}`
                        : null;
                      break;
                    default:
                      price = null;
                  }

                  if (price) {
                    return (
                      <Option key={method.key} value={method.name}>
                        {method.name} - {price}
                      </Option>
                    );
                  }

                  return null; // Exclude the option if price is null
                })}
              </Select>
            </BaseForm.Item>
          </Col>
          {selectedMethod === 'PER_HOUR' ? (
            <Col xs={24} sm={24} md={12} xl={6}>
              <BaseForm.Item
                name="hours"
                label="Number of hours"
                rules={[
                  {
                    required: true,

                    validator: (rule, value) => {
                      if (value < 0) {
                        return Promise.reject('Hours cannot be negative');
                      } else {
                        return value === 0 || value === '0' || value === '' || value === undefined
                          ? Promise.reject('Hours is required')
                          : Promise.resolve();
                      }
                    },
                  },
                ]}>
                <Input type="number" placeholder="Enter the total hours" />
              </BaseForm.Item>
            </Col>
          ) : selectedMethod === 'FULL_DAY' ? (
            <Col xs={24} sm={24} md={12} xl={6}>
              <BaseForm.Item
                name="days"
                label="Number of days"
                rules={[
                  {
                    required: true,
                    validator: (rule, value) => {
                      if (value < 0) {
                        return Promise.reject('Days cannot be negative');
                      } else {
                        return value === 0 || value === '0' || value === '' || value === undefined
                          ? Promise.reject('Days is required')
                          : Promise.resolve();
                      }
                    },
                  },
                ]}>
                <Input type="number" placeholder="Enter the days" />
              </BaseForm.Item>
            </Col>
          ) : null}
        </Row>

        <Row gutter={{xs: 10, md: 15, xl: 30}}>
          <Col xs={24} md={24}>
            <BaseForm.Item name="comments" label="Comments" rules={[{required: false}]}>
              <TextArea rows={2} placeholder="Enter comments" />
            </BaseForm.Item>
          </Col>
        </Row>

        <S.BlurCardWrapper>
          <S.BlueCard>
            <S.Padding>
              {isVatActive ? (
                <Row gutter={{xs: 10, md: 15, xl: 30}}>
                  <Col xs={24} md={24} xl={24}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1rem',
                        alignItems: 'center',
                        marginBottom: '10px',
                        justifyContent: 'flex-end',
                      }}>
                      <S.Title>Applicable VAT</S.Title>
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        // defaultChecked
                        checked={isVatApplicable}
                        onChange={value => {
                          setisVatApplicable(value);
                          calculateAmount(value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              <Row gutter={{xs: 10, md: 15, xl: 30}}>
                <Col xs={24} sm={24} md={12} xl={6}>
                  <BaseForm.Item
                    name="totalAmount"
                    label={`Total Amount(${isResident ? 'LKR' : 'USD'}) `}
                    rules={[{required: false}]}>
                    <Input disabled />
                  </BaseForm.Item>
                </Col>
                {selectedInvoiceType === 3 && (
                  <Col xs={24} sm={24} md={12} xl={6}>
                    <BaseForm.Item
                      name="advancedPayment"
                      label={`Advance Payment(${isResident ? 'LKR' : 'USD'})`}
                      rules={[
                        {
                          required: false,
                          validator: (rule, value) => {
                            const inputValue = parseFloat(value);

                            if (inputValue < 0) {
                              return Promise.reject('Invalid amount');
                            } else if (inputValue > Number(finalTotalAmount.toFixed(2))) {
                              return Promise.reject("Ensure payment doesn't exceed exact charges");
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}>
                      <Input type="number" placeholder="Enter the advance payment" />
                    </BaseForm.Item>
                  </Col>
                )}
                {selectedInvoiceType === 3 && (
                  <Col xs={24} sm={24} md={12} xl={6}>
                    <BaseForm.Item name="paymentMethod" label="Payment Method" rules={[{required: false}]}>
                      <Select placeholder="Select payment method">
                        <Option value="CASH">Cash</Option>
                        <Option value="CREDITCARD">Credit card</Option>
                      </Select>
                    </BaseForm.Item>
                  </Col>
                )}
              </Row>
              {selectedInvoiceType === 3 && isVatActive ? (
                <>
                  <S.CardTitle>VAT Details</S.CardTitle>
                  <Row style={{gap: 10}} justify="start">
                    <BaseForm.Item label="">
                      <Checkbox
                        value={isVatDetailsAvailable}
                        checked={isVatDetailsAvailable}
                        onChange={e => handleClickVATDetails(e.target.checked)}>
                        <S.CheckboxLabel>Add VAT Details</S.CheckboxLabel>
                      </Checkbox>
                    </BaseForm.Item>
                  </Row>
                  {isVatDetailsAvailable ? (
                    <>
                      <Row gutter={{xs: 10, md: 15, xl: 30}}>
                        <Col xs={24} md={8}>
                          <BaseForm.Item
                            name="vatPersonEmail"
                            label="Email"
                            rules={[
                              {
                                required: isVatDetailsAvailable,
                                validator: isVatDetailsAvailable ? validateEmail : validateEmailWithoutRequired,
                              },
                            ]}>
                            <AutoComplete
                              disabled={!isVatDetailsAvailable}
                              onBlur={(event: any) => {
                                const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(event.target.value);
                                if (event.target.value.trim() !== '' && isValidEmail) {
                                  handleBlurPopulate(event.target.value, 'vatPersonEmail');
                                }
                                resetVatSearchedData();
                              }}
                              options={searchedVatUsers}
                              onSearch={value => handleSearchVatUsers(value, 'vatPersonEmail')}
                              onSelect={value => {
                                const selectedGuest = find(searchedVatUsers, (o: {value: number}) => o.value === value);

                                if (selectedGuest !== undefined) handlePopulateVatInformation(selectedGuest);
                              }}>
                              <Input
                                disabled={!isVatDetailsAvailable}
                                onChange={() => handleChangeVatInput('vatPersonEmail')}
                              />
                            </AutoComplete>
                          </BaseForm.Item>
                        </Col>
                        <Col xs={24} md={8}>
                          <BaseForm.Item
                            name="vatNumber"
                            label="VAT Number"
                            rules={[
                              {
                                required: isVatDetailsAvailable,
                                message: 'Required field',
                              },
                            ]}>
                            <AutoComplete
                              disabled={!isVatDetailsAvailable}
                              onBlur={(event: any) => {
                                if (event.target.value.trim() !== '') {
                                  handleBlurPopulate(event.target.value, 'vatNumber');
                                }
                                resetVatSearchedData();
                              }}
                              options={searchedVatUsers}
                              onSearch={value => handleSearchVatUsers(value, 'vatNumber')}
                              onSelect={value => {
                                const selectedGuest = find(searchedVatUsers, (o: {value: number}) => o.value === value);

                                if (selectedGuest !== undefined) handlePopulateVatInformation(selectedGuest);
                              }}>
                              <Input
                                disabled={!isVatDetailsAvailable}
                                onChange={() => handleChangeVatInput('vatNumber')}
                              />
                            </AutoComplete>
                          </BaseForm.Item>
                        </Col>
                        <Col xs={24} md={8}>
                          <BaseForm.Item
                            name="vatPersonName"
                            label="VAT Person name"
                            rules={[
                              {
                                required: isVatDetailsAvailable,
                                message: 'Required field',
                              },
                            ]}>
                            <Input
                              disabled={!isVatDetailsAvailable}
                              onChange={() => handleChangeVatInput('vatPersonName')}
                            />
                          </BaseForm.Item>
                        </Col>
                        <Col xs={24} md={8}>
                          <BaseForm.Item
                            name="vatPersonAddress"
                            label="VAT Person Address"
                            rules={[
                              {
                                required: isVatDetailsAvailable,
                                message: 'Required field',
                              },
                            ]}>
                            <Input
                              disabled={!isVatDetailsAvailable}
                              onChange={() => handleChangeVatInput('vatPersonAddress')}
                            />
                          </BaseForm.Item>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </>
              ) : null}
            </S.Padding>
          </S.BlueCard>
        </S.BlurCardWrapper>
      </BaseForm>
    </S.FormContent>
  );
}
