import {BASE_COLORS, FONT_SIZE} from '@app/styles/themes/constants';
import {Typography} from 'antd';
import styled from 'styled-components';

interface PropsSelect {
  $selected: boolean;
}

export const FormContent = styled.div``;
export const PayButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const PaymentOptionWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;
export const PaymentOptionOutline = styled.div<PropsSelect>`
  display: flex;
  height: 6rem;
  width: 7rem;
  border: ${props => (props.$selected ? '1px solid var(--primary-color)' : '1px solid var(--border-color)')};
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  flex-direction: column;
  background-color: ${BASE_COLORS.white};
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  box-shadow: ${props => (props.$selected ? '0 0 8px var(--ant-primary-color-hover)' : 'none')};

  &:hover {
    box-shadow: ${props => (props.$selected ? '0 0 8px var(--ant-primary-color-hover)' : '0 0 4px rgba(0, 0, 0, 0.1)')};
  }
`;

export const Image = styled.img`
  height: 4rem;
  width: 4rem;
`;
export const PaymentTypeContainer = styled.div`
  background-color: ${BASE_COLORS.skyblue};
  height: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;
export const PaymentType = styled(Typography.Text)<PropsSelect>`
  font-size: ${FONT_SIZE.xs};
  color: ${props => (props.$selected ? BASE_COLORS.primary : BASE_COLORS.black)};
  margin-top: 5px;
`;
export const PaymentOptionSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
