import instance, {HOTEL_SERVICE} from '@app/api/instance';

export const getIdeleTimeout = (): Promise<Response> =>
  instance.get<Response>(HOTEL_SERVICE + `idle-time/all`).then(({data}) => data);

export const updateIdleTimeout = (payload: any): Promise<Response> =>
  instance.put<Response>(HOTEL_SERVICE + `idle-time`, payload).then(({data}) => data);

export const getReservationTimeConfig = (): Promise<Response> =>
  instance.get<Response>(HOTEL_SERVICE + `standard-time-config/all?hotelId=0`).then(({data}) => data);

export const updateReservationTimeConfig = (payload: any): Promise<Response> =>
  instance.put<Response>(HOTEL_SERVICE + `standard-time-config`, payload).then(({data}) => data);

export interface Response {
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
  status: string;
  statusCode: string;
  pagination: {
    pageNumber: number;
    totalRecords: number;
  };
}
