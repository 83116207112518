import React from 'react';
import * as S from './DateRangeFilter.style';
import {FaFilterCircleXmark} from 'react-icons/fa6';
import {FaFilter} from 'react-icons/fa';
import CustomDateRangeFilter from './CustomDateRangeFilter';

interface IDateRange {
  startDate: string;
  endDate: string;
}

interface IAdvanceDateFilter {
  key: string | number;
  getDateRange: (range: IDateRange) => void;
}

function AdvanceDateFilter(props: IAdvanceDateFilter) {
  const {key, getDateRange} = props;
  const [showFilter, setShowFilter] = React.useState<boolean>(false);

  return (
    <S.FilterWrapper key={key}>
      {showFilter && <CustomDateRangeFilter getDateRange={getDateRange} />}
      <S.FilterBtn
        onClick={() => {
          showFilter && getDateRange({startDate: '', endDate: ''});
          setShowFilter(!showFilter);
        }}>
        <div style={{fontSize: '14px', marginLeft: '8px', marginTop: '6px'}}>
          {showFilter ? <FaFilterCircleXmark style={{color: 'red'}} /> : <FaFilter />}
        </div>
      </S.FilterBtn>
    </S.FilterWrapper>
  );
}

export default React.memo(AdvanceDateFilter);
