import React from 'react';
import {BsPerson, BsFillHouseHeartFill, BsList, BsCardChecklist} from 'react-icons/bs';
import {MdLocalLaundryService, MdTrolley} from 'react-icons/md';
import {GiAmpleDress, GiChecklist} from 'react-icons/gi';
import {GiVacuumCleaner} from 'react-icons/gi';
import {
  DashboardFilled,
  DiffOutlined,
  InsertRowLeftOutlined,
  SettingOutlined,
  TableOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import {TbTrolley} from 'react-icons/tb';
import {HOUSEKEEPING_ADMIN_MODULE_NAME, SERVICE_ADMIN_MODULE_NAME} from '@app/utils/permissions';
import {BiUser} from 'react-icons/bi';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  isSubMenu: boolean;
}

export const HouseKeepingSidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Housekeeper',
    key: HOUSEKEEPING_ADMIN_MODULE_NAME.HOUSE_KEEPER,
    url: '/housekeeping/house-keeper',
    icon: <BsPerson />,
    isSubMenu: false,
  },

  // {
  //   title: 'House Keeper Allocation',
  //   key: 'house-keeper-allocation',
  //   url: '/housekeeping/house-keeper-allocation',
  //   icon: <BsFillHouseHeartFill />,
  //   isSubMenu: false,
  // },
  {
    title: 'Trolleys Creation',
    key: HOUSEKEEPING_ADMIN_MODULE_NAME.TROLLEY_CREATION,
    url: '/housekeeping/Trolley-item',
    icon: <MdTrolley />,
    isSubMenu: false,
  },
  {
    title: 'Trolley Allocation',
    key: HOUSEKEEPING_ADMIN_MODULE_NAME.TROLLEY_ALLOCATION,
    url: '/housekeeping/TrolleyAllocation-item',
    icon: <TbTrolley />,
    isSubMenu: false,
  },

  // {
  //   title: 'Cleaning Request',
  //   key: 'cleaning-request',
  //   url: '/housekeeping/cleaning-request',
  //   icon: <GiVacuumCleaner />,
  //   isSubMenu: false,
  // },
  {
    title: 'Checklist',
    key: 'checklist',
    icon: <UnorderedListOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Checklist items',
        key: HOUSEKEEPING_ADMIN_MODULE_NAME.CHECK_LIST_ITEMS,
        url: '/housekeeping/check-list-action',
        isSubMenu: false,
      },

      {
        title: 'Checklist',
        key: HOUSEKEEPING_ADMIN_MODULE_NAME.CHECK_LIST,
        url: '/housekeeping/check-list',
        isSubMenu: false,
      },
    ],
  },
  {
    title: 'Setting',
    key: 'Setting',
    icon: <SettingOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Service Role',
        key: SERVICE_ADMIN_MODULE_NAME.SERVICE_ROLE,
        url: '/housekeeping/service-role',
        icon: <TeamOutlined />,
        isSubMenu: false,
      },
      {
        title: 'Service Role Privilege',
        key: SERVICE_ADMIN_MODULE_NAME.SERVICE_ROLE_PRIVILEGE,
        url: '/housekeeping/service-role-privilege',
        icon: <BiUser />,
        isSubMenu: false,
      },
      {
        title: 'User',
        key: SERVICE_ADMIN_MODULE_NAME.EMPLOYEE,
        url: '/housekeeping/hotel-employee',
        icon: <UserAddOutlined />,
        isSubMenu: false,
      },
    ],
  },
];
