import {calculateTotalTaxWithSequence} from '@app/utils/functions';
import {ConvertedDataProps} from '../Modals/RetriveServiceModal';

const calculateTotalForEachService = (
  service: {
    quantity: number;
    method: any;
    numberOfDays: number;
    numberOfHours: number;
    perHourPrice: number;
    fullDayPrice: number;
    halfDayPrice: number;
    hallId: number | null;
    discountType: string;
    discountValue: number;
    taxList?: any[];
    name: string;
  },
  resident: boolean,
  isVatApplicable: boolean,
) => {
  const {
    quantity,
    method,
    numberOfDays,
    numberOfHours,
    perHourPrice,
    fullDayPrice,
    halfDayPrice,
    hallId,
    discountType,
    discountValue,
    taxList = [],
  } = service;

  const adjustedQuantity = hallId !== null ? 1 : quantity;
  if (!adjustedQuantity || !method)
    return {
      totalAmountWithoutTax: 0,
      totalAmountWithDiscountAndWithoutTax: 0,
      totalAmountWithTax: 0,
      totalTaxAmount: 0,
      taxArray: [],
      discountAmount: 0,
      finalAmount: 0,
    };

  let amount = 0;

  switch (method) {
    case 'PER_HOUR':
      if (numberOfHours === 0)
        return {
          totalAmountWithoutTax: 0,
          totalAmountWithDiscountAndWithoutTax: 0,
          totalAmountWithTax: 0,
          totalTaxAmount: 0,
          taxArray: [],
          discountAmount: 0,
          finalAmount: 0,
        };
      amount = adjustedQuantity * numberOfHours * perHourPrice;
      break;
    case 'FULL_DAY':
      if (numberOfDays === 0)
        return {
          totalAmountWithoutTax: 0,
          totalAmountWithDiscountAndWithoutTax: 0,
          totalAmountWithTax: 0,
          totalTaxAmount: 0,
          taxArray: [],
          discountAmount: 0,
          finalAmount: 0,
        };
      amount = adjustedQuantity * numberOfDays * fullDayPrice;
      break;
    case 'HALF_DAY':
      amount = adjustedQuantity * halfDayPrice;
      break;
    default:
      return {
        totalAmountWithoutTax: 0,
        totalAmountWithDiscountAndWithoutTax: 0,
        totalAmountWithTax: 0,
        totalTaxAmount: 0,
        taxArray: [],
        discountAmount: 0,
        finalAmount: 0,
      };
  }

  let discountAmount = 0;

  if (discountType === 'PERCENTAGE' && discountValue > 0) {
    discountAmount = amount * (discountValue / 100);
  } else if (discountType === 'AMOUNT' && discountValue > 0) {
    discountAmount = discountValue;
  }

  const totalAmountWithDiscountAndWithoutTax = amount - discountAmount;

  let totalAmountWithTax = totalAmountWithDiscountAndWithoutTax;
  let totalTaxAmount = 0;
  let taxArray = [];

  if (taxList && taxList.length > 0) {
    const {
      totalAmount,
      totalTaxAmount: calculatedTaxAmount,
      taxArray: calculatedTaxArray,
    } = calculateTotalTaxWithSequence(
      isNaN(totalAmountWithDiscountAndWithoutTax) ? 0 : totalAmountWithDiscountAndWithoutTax,
      taxList,
      isVatApplicable,
    );

    totalAmountWithTax = totalAmount; // Update total amount with tax included
    totalTaxAmount = calculatedTaxAmount; // Set total tax amount
    taxArray = calculatedTaxArray; // Set tax details
  }
  console.log({amount});

  return {
    serviceName: service.name,
    totalAmountWithoutTax: isNaN(amount) ? 0 : amount,
    totalAmountWithDiscountAndWithoutTax: isNaN(totalAmountWithDiscountAndWithoutTax)
      ? 0
      : totalAmountWithDiscountAndWithoutTax,
    totalAmountWithTax: isNaN(totalAmountWithTax) ? 0 : totalAmountWithTax,
    totalTaxAmount: isNaN(totalTaxAmount) ? 0 : totalTaxAmount,
    taxArray: taxArray,
    discountAmount: isNaN(discountAmount) ? 0 : discountAmount,
    finalAmount: isNaN(totalAmountWithTax) ? 0 : totalAmountWithTax, // This is the final amount including tax
  };
};

const isValidService = (
  service: {
    additionalServiceId: string | number;
    selectedQuantity: number;
    numberOfHours: number;
    numberOfDays: number;
    hallId: number;
  },
  selectedMethod: {[x: string]: string},
) => {
  if (service.hallId === null) {
    if (selectedMethod[service.additionalServiceId] === 'PER_HOUR') {
      return service.selectedQuantity > 0 && service.numberOfHours > 0;
    } else if (selectedMethod[service.additionalServiceId] === 'FULL_DAY') {
      return service.selectedQuantity > 0 && service.numberOfDays > 0;
    } else if (selectedMethod[service.additionalServiceId] === 'HALF_DAY') {
      return service.selectedQuantity > 0;
    }
    return false;
  } else {
    if (selectedMethod[service.additionalServiceId] === 'PER_HOUR') {
      return service.numberOfHours > 0;
    } else if (selectedMethod[service.additionalServiceId] === 'FULL_DAY') {
      return service.numberOfDays > 0;
    } else if (selectedMethod[service.additionalServiceId] === 'HALF_DAY') {
      return true;
    }
    return false;
  }
};

export const calculateTotalForExistingService = (service: any, isResident: any, vatApplicable: boolean) => {
  console.log({service});
  console.log({vatApplicable});

  let totalAmountWithoutTax = service.totalPrice || 0;
  let totalAmountWithTax = service.totalCharge || 0;
  let totalTaxAmount = 0;
  let taxArray = [];
  let discountAmount = 0;
  let totalAmountWithDiscountAndWithoutTax = totalAmountWithoutTax;

  if (service) {
    const adjustedQuantity = service.hallId !== null ? 1 : service.quantity;
    if (!adjustedQuantity || !service.method)
      return {
        serviceId: service.id,
        serviceName: service.name,
        totalAmountWithoutTax: 0,
        totalAmountWithDiscountAndWithoutTax: 0,
        totalAmountWithTax: 0,
        totalTaxAmount: 0,
        taxArray: [],
        discountAmount: 0,
        finalAmountWithoutTax: 0,
        finalAmountWithTax: 0,
      };

    if (service.extraHour > 0) {
      console.log({service});

      const pricePerHour = isResident ? service.perHourPrice : service.perHourPrice;
      console.log({pricePerHour});

      totalAmountWithoutTax += service.extraHour * adjustedQuantity * pricePerHour;
    }

    if (service.discountType === 'PERCENTAGE' && service.discountValue > 0) {
      discountAmount = totalAmountWithoutTax * (service.discountValue / 100);
    } else if (service.discountType === 'AMOUNT' && service.discountValue > 0) {
      discountAmount = service.discountValue;
    }

    totalAmountWithDiscountAndWithoutTax = totalAmountWithoutTax - discountAmount;

    if (service.taxList && service.taxList.length > 0) {
      const {
        totalAmount: totalAmountWithTaxCalculation,
        totalTaxAmount: calculatedTaxAmount,
        taxArray: calculatedTaxArray,
      } = calculateTotalTaxWithSequence(
        isNaN(totalAmountWithDiscountAndWithoutTax) ? 0 : totalAmountWithDiscountAndWithoutTax,
        service.taxList,
        vatApplicable,
      );

      totalAmountWithTax = totalAmountWithTaxCalculation; // Update total amount with tax included
      totalTaxAmount = calculatedTaxAmount; // Set total tax amount
      taxArray = calculatedTaxArray; // Set tax details
    } else {
      totalAmountWithTax = totalAmountWithDiscountAndWithoutTax; // If no tax, total amount with tax is the same as amount after discount
    }
  }

  const finalAmountWithoutTax = totalAmountWithDiscountAndWithoutTax;
  const finalAmountWithTax = totalAmountWithTax;

  return {
    serviceId: service.id,
    serviceName: service.name,
    totalAmountWithoutTax: isNaN(totalAmountWithoutTax) ? 0 : totalAmountWithoutTax,
    totalAmountWithDiscountAndWithoutTax: isNaN(totalAmountWithDiscountAndWithoutTax)
      ? 0
      : totalAmountWithDiscountAndWithoutTax,
    totalAmountWithTax: isNaN(totalAmountWithTax) ? 0 : totalAmountWithTax,
    totalTaxAmount: isNaN(totalTaxAmount) ? 0 : totalTaxAmount,
    taxArray: taxArray,
    discountAmount: isNaN(discountAmount) ? 0 : discountAmount,
    finalAmountWithoutTax: isNaN(finalAmountWithoutTax) ? 0 : finalAmountWithoutTax,
    finalAmountWithTax: isNaN(finalAmountWithTax) ? 0 : finalAmountWithTax,
  };
};

const data: ConvertedDataProps = {
  outStanding: 1331.2820512820513,
  totalAmount: 1200,
  roomNumber: null,
  paid: false,
  reservationCurrencyId: 2,
  invoiceId: 197,
  taxInclude: true,
  vatRegistryResponse: null,
  vatRegistryApplicable: false,
  additionalServiceForReservedRoomResponseList: [],
  checkInDate: '2024-06-07',
  checkOutDate: '2024-06-07',
  checkInTime: '11:26:36',
  checkOutTime: '',
  id: 0,
  additionalServices: [
    {
      comments: '',
      refundAmount: 2000,
      additionalServiceId: 100,
      refNumber: 'ADS - 00001',
      additionalServiceReservationStatus: 'BOOKED',
      service: 'Sketing',
      noOfHours: 10.0,
      noOfDays: 1.0,
      resident: true,
      prefix: 'LKR',
      hotelId: 1,
      count: 1,
      hallTime: 'PER_HOUR',
      totalPrice: 1000.0,
      totalPriceWithDiscount: 800,
      hourPrice: 100.0,
      halfDayPrice: 500.0,
      fullDayPrice: 1000.0,
      hallId: null,
      hallName: null,
      ownerName: 'Lavan',
      hallArea: 0,
      numberOfPerson: 0,
      hallType: null,
      discountType: 'AMOUNT',
      discount: 200,
      taxList: [
        {
          id: 14,
          name: 'Service Charge',
          rate: 10.0,
          formula: null,
          sequence: 1,
          serviceCharge: true,
          element: null,
          active: true,
          hotelId: 1,
          socialSecurityContributionLevy: false,
          vat: false,
          useReservation: false,
        },
        {
          id: 15,
          name: 'SSCL',
          rate: 2.5,
          formula: null,
          sequence: 2,
          serviceCharge: false,
          element: null,
          active: true,
          hotelId: 1,
          socialSecurityContributionLevy: true,
          vat: false,
          useReservation: false,
        },
        {
          id: 16,
          name: 'VAT',
          rate: 18.0,
          formula: null,
          sequence: 3,
          serviceCharge: false,
          element: null,
          active: true,
          hotelId: 1,
          socialSecurityContributionLevy: false,
          vat: true,
          useReservation: false,
        },
      ],
    },
    {
      comments: '',
      refundAmount: 2000,
      additionalServiceId: 103,
      refNumber: 'ADS - 00001',
      additionalServiceReservationStatus: 'BOOKED',
      service: 'Sketing',

      noOfHours: 10.0,
      noOfDays: 1.0,
      resident: true,
      prefix: 'LKR',
      hotelId: 1,
      count: 1,
      hallTime: 'PER_HOUR',
      totalPrice: 1000.0,
      totalPriceWithDiscount: 900,
      hourPrice: 100.0,
      halfDayPrice: 500.0,
      fullDayPrice: 1000.0,
      hallId: null,
      hallName: null,
      ownerName: 'Lavan',
      hallArea: 0,
      numberOfPerson: 0,
      hallType: null,
      discountType: 'PERCENTAGE',
      discount: 10,
      taxList: [
        {
          id: 14,
          name: 'Service Charge',
          rate: 10.0,
          formula: null,
          sequence: 1,
          serviceCharge: true,
          element: null,
          active: true,
          hotelId: 1,
          socialSecurityContributionLevy: false,
          vat: false,
          useReservation: false,
        },
        {
          id: 15,
          name: 'SSCL',
          rate: 2.5,
          formula: null,
          sequence: 2,
          serviceCharge: false,
          element: null,
          active: true,
          hotelId: 1,
          socialSecurityContributionLevy: true,
          vat: false,
          useReservation: false,
        },
        {
          id: 16,
          name: 'VAT',
          rate: 18.0,
          formula: null,
          sequence: 3,
          serviceCharge: false,
          element: null,
          active: true,
          hotelId: 1,
          socialSecurityContributionLevy: false,
          vat: true,
          useReservation: false,
        },
      ],
    },
  ],
};
export {calculateTotalForEachService, data, isValidService};
