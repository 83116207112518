import {DISABLE_GLOWING, GLOWING_ONE, GLOWING_TWO} from '@app/assets';
import React, {useState, useEffect} from 'react';
import * as S from './ContrastAnimation.style';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ContrastAnimation = ({isActive, onClick, disabled}: any) => {
  const [highContrast, setHighContrast] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setHighContrast(prevContrast => !prevContrast);
    }, 400);
    return () => clearInterval(interval);
  }, []);

  return (
    <S.ImageContainer onClick={onClick}>
      <S.Image
        src={disabled ? DISABLE_GLOWING : isActive ? (highContrast ? GLOWING_ONE : GLOWING_TWO) : DISABLE_GLOWING}
        alt="Contrast Animation"
      />
      <S.Text>
        House <br /> Keeping
      </S.Text>
    </S.ImageContainer>
  );
};

export default ContrastAnimation;
