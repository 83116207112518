import React from 'react';
import {Col, Row} from 'antd';
import {NotificationsDropdown} from '../components/notificationsDropdown/NotificationsDropdown';
import {ProfileDropdown} from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import {HeaderSearch} from '../components/HeaderSearch/HeaderSearch';
import {SettingsDropdown} from '../components/settingsDropdown/SettingsDropdown';
import * as S from '../Header.styles';
import {DashboardHome} from '../components/DashboardHome/DashboardHome';
import {useAppSelector} from '@app/hooks/reduxHooks';
import {HOTEL_SERVICE_MODULE_NAME, modulePermission} from '@app/utils/permissions';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({toggleSider, isSiderOpened}) => {
  const userPermission = useAppSelector(state => state.user.permissions);
  const permissions = modulePermission(userPermission, HOTEL_SERVICE_MODULE_NAME.DASHBOARD);
  return (
    <Row justify="space-between" align="middle">
      <Col>
        <ProfileDropdown />
      </Col>

      <Col>
        <Row align="middle">
          {/* <Col>
            <NotificationsDropdown />
          </Col> */}

          {/* <Col>
            <HeaderSearch />
          </Col> */}
          <Col hidden={!permissions.VIEW}>
            <DashboardHome />
          </Col>
          <Col>
            <SettingsDropdown />
          </Col>
        </Row>
      </Col>

      <S.BurgerCol>
        <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
      </S.BurgerCol>
    </Row>
  );
};
