import {LAYOUT} from '@app/styles/themes/constants';
import {Col, Row, Image, Card, Avatar, Typography, Badge, Space} from 'antd';
import {useTimer} from 'react-timer-hook';
import React, {useState} from 'react';
import * as S from './DashboardPage.styles';
import styled from 'styled-components';
import {IChefOrder, IItems} from './chefMockData';
import {IChefOrderItem} from '@app/api/getOrders.api';
import {Button} from '@app/components/common/buttons/Button/Button';
import {useStopwatch} from 'react-timer-hook';
import {chefOrderList} from '@app/utils/utils';
import {filter, find, isEmpty, sortBy, unionBy} from 'lodash';
import _ from 'lodash';
import ChefOrderCard from './ChefOrderCard';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import './ChefDashBoardStyle.css';
import {Card as CommonCard} from '@app/components/common/Card/Card';

import SockJS from 'sockjs-client';
import {Stomp} from '@stomp/stompjs';
import {RESTAURANT_WS_SERVICE} from '@app/api/resturantInstance';
import {
  GetResponse,
  ReadyToServe,
  acceptAllOrders,
  getAllCountStatus,
  getAllPlacedOrders,
  getAllPlacedOrdersNew,
  rejectAllOrderItems,
  rejectOrderItem,
  rejectOrderItemNew,
  requestReConfirmation,
} from './chef.api';
import {PlusOutlined} from '@ant-design/icons';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {Modal} from '@app/components/common/Modal/Modal';
import {Input} from '@app/components/common/inputs/Input/Input.styles';
import {TextArea} from '@app/components/common/inputs/Input/Input';
import {NOTIFY_SOUND} from '@app/assets';
import {updateOrder} from '@app/api/resturant/tablecategory/order.api';
import {notificationController} from '@app/controllers/notificationController';

import {InfoModal} from '@app/components/common/Modal/Modal';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {ShadowedButton} from '../Restaurant/WaiterDashboard/RightArea';
import Tooltip from '@app/components/common/Tooltip/Tooltip';
import {async} from 'q';
import BlinkingText from '../Restaurant/WaiterDashboard/BlinkingText';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {setChefSliderIndex} from '../Restaurant/slices/waiterDasboardSlice';

const {Text} = Typography;

const ProductsCountCard = styled.div`
  min-height: 6rem;
  width: 95%;
  box-shadow: 4px 4px #e3dcdc;
`;
const ProductImage = styled(Image)`
  width: 75%;
  height: 2.5rem;
  margin-top: 1.6rem;
  align-self: center;
  margin-left: 1rem;
`;

export const RightSideCol = styled.div`
  padding: 0.5rem 1rem;
  position: sticky;
  box-shadow: 0px 0px 8px #ccc;
  top: 0;
  display: flex;
  flex-direction: column;
  min-height: calc(70vh - ${LAYOUT.desktop.headerHeight});
  background-color: #ffff;
  overflow-y: auto;
  border-radius: 10px;

  width: 95%;
`;

const OrderItem = styled(Card)`
  min-height: 80px;

  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.32);
  box-shadow: 0px 0px 20px -13px rgba(0, 0, 0, 0.32);
`;

const ScrollCardWrapper = styled.div`
overflow-y: auto;
overflow-x: hidden;
min-height: 280px;

// background-color: #f0f0f0;

padding: 20px;
.ant-card-body {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  `;

export const CountCard = styled(Card)`
  .ant-card-body {
    padding: 0px !important;
  }
`;
export const CustomCard = styled(CommonCard)`
  width: 100%;
  margin-bottom: 1.25rem;
  .ant-card-head-title {
    font-size: 1.5rem;
  }
  .ant-card-body {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    align-items: center;
    padding: 10px 40px;
  }
  .ant-card-body:before {
    display: none;
  }
  .ant-card-body:after {
    display: none;
  }
  &.ant-card-bordered {
    border: 1px solid var(--border-color);
  }
`;

const ChefDashBoard = () => {
  const [form] = BaseForm.useForm();
  const chefSilderIndex = useAppSelector(state => state.waiterDasbordSlice.chefSilderIndex);
  const dispatch = useAppDispatch();
  const [selectedItems, setSelectedItems] = React.useState<IChefOrder[]>([]);
  const [selectedItemsTable, setSelectedItemsTable] = React.useState<IChefOrder[]>([]);
  const [currentItems, setCurrentItems] = React.useState<any>([]);
  const [newItems, setNewItems] = React.useState<any>([]);
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  const [openReason, setOpenReason] = React.useState<boolean>(false);

  const [selectedOrder, setSelectedOrder] = React.useState<IChefOrder>();
  const [selectedOrderItem, setSelectedOrderItem] = React.useState<any>();
  const [tableNumber, setTableNumber] = React.useState<any>();
  const [dashboardCount, setDashboardCount] = React.useState<{
    accepted: number;
    beingPrepared: number;
    placed: number;
    readyToServe: number;
    requestConfirmation: number;
  }>({accepted: 0, beingPrepared: 0, placed: 0, readyToServe: 0, requestConfirmation: 0});

  const [newReceivedItems, setNewReceivedItems] = React.useState<any[]>([]);
  const [enableRequest, setEnablerequest] = useState<boolean>(false);
  const [reconfirmTables, setReconfirmTables] = useState<string[]>([]);
  const [reason, setreason] = useState('');
  const [newOrderItemCount, setNewOrderItemCount] = React.useState<number>(0);

  const hotelServiceConfig = useAppSelector(state => state.hotelSlice.hotelServiceConfig);

  React.useEffect(() => {
    getAllChefOrders({});
    getAllProcessChefOrders();
    GetDashboardCounts();
  }, []);

  React.useEffect(() => {
    if (selectedItems.length > 0 && selectedItems.length - 1 < chefSilderIndex) {
      dispatch(setChefSliderIndex({index: selectedItems.length - 1}));
    }
  }, [selectedItems, newReceivedItems]);

  const GetDashboardCounts = async () => {
    try {
      const result: any = await getAllCountStatus(hotelServiceConfig.serviceId);

      setDashboardCount(result?.result?.orderCount);
    } catch (error) {}
  };

  React.useEffect(() => {
    let stompClient: any = null;

    const WebSocketClient = (url: string, tableIndex: number) => {
      const sock = new SockJS(RESTAURANT_WS_SERVICE);
      stompClient = Stomp.over(sock);

      sock.onopen = function () {
        console.log('onopen');
      };

      stompClient.connect({}, (frame: any) => {
        stompClient.subscribe(url, data => {
          const receivedData: any = JSON.parse(data.body);
          const key: any = Object.keys(receivedData)[0];
          const action: any = receivedData[key];

          if (receivedData && action === 'CANCELLED') {
            dispatch(setChefSliderIndex({index: 0}));
          }
          setNewReceivedItems(receivedData);
          if (receivedData) {
            getAllChefOrders({});
            getAllProcessChefOrders();
            GetDashboardCounts();
          }

          if (receivedData) {
            const audio = new Audio(NOTIFY_SOUND);
            audio.play();
          }
        });
      });
      stompClient.activate();
    };

    WebSocketClient(`/chef/${hotelServiceConfig.serviceId}`, chefSilderIndex);

    return () => {
      if (stompClient) {
        stompClient.disconnect();
      }
    };
  }, []);
  const getAllProcessChefOrders = async () => {
    try {
      const results: GetResponse = await getAllPlacedOrders(hotelServiceConfig.serviceId);
      const orderResults = results.result.order;

      setSelectedItemsTable(orderResults);
    } catch (error) {}
  };
  const getAllChefOrders = async (item?: any, isMount?: boolean) => {
    try {
      const results: GetResponse = await getAllPlacedOrdersNew(hotelServiceConfig.serviceId);
      const orderResults = results.result.orderItems;

      const sorterData = sortBy(orderResults, 'tableId');
      const orderResultsLength = sorterData?.length;
      // setSelectedItemsTable(orderResults);
      if (currentIndex > orderResultsLength) {
        dispatch(setChefSliderIndex({index: orderResultsLength > 0 ? orderResultsLength - 1 : 0}));
      }

      setSelectedItems(sorterData);
      const requestConfirmData = filter(sorterData, order => order.orderStatus === 'REQUEST_CONFIRMATION');
      const requestConfirmTables = requestConfirmData.map(post => post.tableNumber);
      const iniItemOrderCount = filter(sorterData, (item: any, index: number) =>
        hasNewOrderedItem(item.orderedItemResponseList),
      ).length;
      const itemListLength = filter(
        sorterData,
        (item: any, index: number) =>
          hasNewOrderedItem(item.orderedItemResponseList) && item.orderStatus !== 'REQUEST_CONFIRMATION',
      ).length;

      setNewOrderItemCount(itemListLength);
      setReconfirmTables(requestConfirmTables);
      if (iniItemOrderCount === 1) {
        setCurrentIndex(0);
      }
      setTableNumber(
        filter(sorterData, (item: any, index: number) => hasNewOrderedItem(item.orderedItemResponseList))[0]
          .tableNumber,
      );

      const filterData = filter(sorterData, (item: any, index: number) =>
        hasNewOrderedItem(item.orderedItemResponseList),
      );

      if (!selectedOrder && filterData.length > 0) {
        setSelectedOrder(filterData[0]);
      }

      const defaultData = [...filterData];
      if (tableNumber === undefined) {
        if (filterData.length > 0) {
          const tableOrderedItemResponseList = filterData[0].orderedItemResponseList;
          const findObj = find(tableOrderedItemResponseList, res => res.orderedItemStatus === 'REJECTED');
          if (findObj) {
            setEnablerequest(true);
          } else {
            setEnablerequest(false);
          }
        }
      } else {
        const tableOrderedItemResponseList = find(
          defaultData,
          (item: any, index: number) => item.tableNumber === tableNumber,
        );
        const findObj = find(
          tableOrderedItemResponseList?.orderedItemResponseList,
          res => res.orderedItemStatus === 'REJECTED',
        );
        if (findObj) {
          setEnablerequest(true);
        } else {
          setEnablerequest(false);
        }
      }
    } catch (error) {}
  };

  const rejectAll = async (orderId: number) => {
    try {
      const result: GetResponse = await rejectAllOrderItems(orderId);
      if (result.statusCode === '20000') {
        notificationController.success({message: result.message});
        await getAllChefOrders({});
      } else {
        notificationController.error({message: result.message});
      }
    } catch (error) {}
  };

  const onClickReject = async (item: IItems, post: IChefOrder, rejectReson: string) => {
    try {
      const result = await rejectOrderItemNew(item.id, rejectReson);
      if (result.statusCode === '20000') {
        notificationController.success({message: result.message});
        await getAllChefOrders({});
        setreason('');
      } else {
        notificationController.error({message: result.message});
      }

      setOpenReason(false);
      form.resetFields();
    } catch (error) {}
  };

  const onChangeCard = (value: number) => {
    dispatch(setChefSliderIndex({index: value}));
    const selectedData: any = filter(selectedItems, (item: any) => hasNewOrderedItem(item.orderedItemResponseList));

    const selectedItem = filter(selectedData, (item: IChefOrder, index: number) => value === index)[0];

    setSelectedOrder(selectedItem);
    setTableNumber(selectedItem.tableNumber);
    setCurrentItems(selectedItem);
    setCurrentIndex(value);
    const defaultData = [...selectedItems];
    const tableOrderedItemResponseList = find(
      defaultData,
      (item: any, index: number) => item.tableNumber === selectedItem.tableNumber,
    );
    const findObj = find(
      tableOrderedItemResponseList?.orderedItemResponseList,
      res => res.orderedItemStatus === 'REJECTED',
    );

    if (findObj) {
      setEnablerequest(true);
    } else {
      setEnablerequest(false);
    }
  };

  const handleClose = () => {
    setOpenReason(false);
    form.resetFields();
  };

  const handleAcceptAll = async (data: any) => {
    const result = await acceptAllOrders(data.id);

    if (result.statusCode === '20000') {
      notificationController.success({message: 'Order accepted successfully'});
      dispatch(setChefSliderIndex({index: 0}));
      await getAllChefOrders({});
      await getAllProcessChefOrders();
      await GetDashboardCounts();
    } else {
      notificationController.error({message: result.message});
    }
  };

  const hasNewOrderedItem = (orderedItemResponseList: any) => {
    return orderedItemResponseList.some(
      (item: any) => item.orderedItemStatus === 'NEW' || item.orderedItemStatus === 'REJECTED',
    );
  };

  const requestForRejection = async () => {
    const payload = {
      id: selectedOrder?.id,
      orderStatus: 'REQUEST_CONFIRMATION',
    };

    const result = await requestReConfirmation(selectedOrder?.id);

    if (result.statusCode === '20000') {
      notificationController.success({message: 'Request for reconfirm order has been sent'});
      await getAllChefOrders({});
      await GetDashboardCounts();
    } else {
      notificationController.error({message: result.message});
    }
  };
  const getTableTime = () =>
    filter(
      filter(selectedItems, (item: any) => hasNewOrderedItem(item.orderedItemResponseList)),
      (item: IChefOrder, index: number) => currentIndex === index,
    )[0]?.orderTime;

  return (
    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
      <PageTitle>Chef</PageTitle>

      <Row gutter={{xs: 10, md: 15, xl: 30}} style={{width: '100%'}}>
        <Col
          md={8}
          xl={5}
          xs={24}
          sm={24}
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}>
          <CountCard
            style={{
              width: '95%',
              padding: 3,
              alignSelf: 'end',
              height: 77,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.75)',
            }}>
            <div>Request Confirmation</div>
            <div
              style={{
                fontSize: '30px',
                color: '#9d5803',
                fontWeight: 700,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                height: 60,
              }}>
              <div>{dashboardCount?.requestConfirmation}</div>
              {reconfirmTables.length > 0 && (
                <div style={{position: 'absolute', bottom: 0, right: 0}}>
                  <BlinkingText size={14} title="Table" texts={reconfirmTables} interval={1300} type="CHEF" />
                </div>
              )}
            </div>
          </CountCard>
        </Col>
        <Col
          md={8}
          xl={5}
          xs={24}
          sm={24}
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}>
          <CountCard
            style={{
              width: '95%',
              padding: 3,
              alignSelf: 'end',
              height: 77,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.75)',
            }}>
            <div>New Orders</div>
            <div
              style={{
                fontSize: '30px',
                color: '#9d5803',
                fontWeight: 700,
              }}>
              {newOrderItemCount}
            </div>
          </CountCard>
        </Col>

        <Col md={8} xl={5} xs={24} sm={24}>
          {/* <Space size={42}> */}
          <CountCard
            style={{
              width: '95%',
              padding: 3,
              height: 77,
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'end',
              alignItems: 'center',
              textAlign: 'center',
              boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.75)',
            }}>
            <div>Accepted</div>
            <div
              style={{
                fontSize: '30px',
                color: '#096dd9',
                fontWeight: 700,
              }}>
              {dashboardCount?.accepted}
            </div>
          </CountCard>
        </Col>
        <Col md={8} xl={5} xs={24} sm={24}>
          <CountCard
            style={{
              width: '95%',
              padding: 3,
              height: 77,
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'end',
              alignItems: 'center',
              textAlign: 'center',
              boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.75)',
            }}>
            <div>Being Prepared</div>
            <div
              style={{
                fontSize: '30px',
                color: '#096dd9',
                fontWeight: 700,
              }}>
              {dashboardCount?.beingPrepared}
            </div>
          </CountCard>
        </Col>
        <Col md={8} xl={4} xs={24} sm={24}>
          <CountCard
            style={{
              width: '95%',
              padding: 3,
              height: 77,
              display: 'flex',
              alignSelf: 'end',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.75)',
            }}>
            <div>Ready to Serve</div>
            <div
              style={{
                fontSize: '30px',
                color: '#096dd9',
                fontWeight: 700,
              }}>
              {dashboardCount?.readyToServe}
            </div>
          </CountCard>
          {/* </Space> */}
        </Col>
      </Row>
      {/* <S.Space /> */}
      <div style={{margin: '0.15rem 0'}}></div>
      <Row style={{width: '100%', padding: '10px 6px', overflowY: 'scroll'}}>
        <Col md={24} xl={10} xxl={5}>
          <RightSideCol>
            <div
              id="blood-screening"
              style={{
                color: '#000',
                height: '2rem',
                borderBottom: '1px solid #cfcfcf',
                textAlign: 'center',
              }}>
              <Row>
                <Col span={getTableTime() ? 8 : 4}>{getTableTime() && `Table Time : ${getTableTime()}`}</Col>
                <Col span={getTableTime() ? 11 : 14}>
                  {filter(selectedItems, (item: any) => hasNewOrderedItem(item.orderedItemResponseList)).length === 0
                    ? 'No Orders'
                    : ` New Order ${selectedItems.length === 0 ? 0 : currentIndex + 1} of ${
                        filter(selectedItems, (item: any) => hasNewOrderedItem(item.orderedItemResponseList)).length
                      }`}
                </Col>
                <Col span={getTableTime() ? 5 : 6}>
                  {tableNumber && selectedItems.length > 0
                    ? filter(
                        filter(selectedItems, (item: any) => hasNewOrderedItem(item.orderedItemResponseList)),
                        (item: IChefOrder, index: number) => currentIndex === index,
                      )[0]
                      ? `Table - ${
                          filter(
                            filter(selectedItems, (item: any) => hasNewOrderedItem(item.orderedItemResponseList)),
                            (item: IChefOrder, index: number) => currentIndex === index,
                          )[0]?.tableNumber
                        }`
                      : ''
                    : ''}
                </Col>
              </Row>
            </div>
            <div style={{height: 15}}></div>

            <div>
              <Carousel
                selectedItem={chefSilderIndex}
                showStatus={false}
                onChange={onChangeCard}
                showIndicators={false}
                showThumbs={true}>
                {filter(selectedItems, (item: any, index: number) =>
                  hasNewOrderedItem(item.orderedItemResponseList),
                ).map((post: any, index: number) => {
                  const filterNewData = filter(
                    post.orderedItemResponseList,
                    (x: any) => x.orderedItemStatus === 'REJECTED',
                  );

                  const isEnab: boolean = filterNewData.length > 0 ? true : false;
                  return (
                    <div
                      key={index}
                      style={{
                        backgroundColor: '#f0f0f0',
                        borderRadius: '15px',
                        zIndex: 2,
                        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.30)',
                      }}>
                      <ScrollCardWrapper id="patient-timeline">
                        {filter(
                          post.orderedItemResponseList,
                          (item: IItems) =>
                            item.orderedItemStatus === 'NEW' ||
                            item.orderedItemStatus === 'CANCELLED' ||
                            item.orderedItemStatus === 'REJECTED',
                        ).map((item: IItems, index: number) => {
                          return (
                            <Badge.Ribbon
                              key={index}
                              text={
                                item.orderedItemStatus === 'NEW'
                                  ? 'New'
                                  : item.orderedItemStatus === 'REJECTED'
                                  ? 'Rejected'
                                  : item.orderedItemStatus
                              }
                              color={
                                item.orderedItemStatus === 'NEW'
                                  ? 'Green'
                                  : item.orderedItemStatus === 'REJECTED'
                                  ? 'Red'
                                  : item.orderedItemStatus
                              }
                              placement="start"
                              // style={{
                              //   display: item.dateTime === '16:orderedItemStatus00' ? 'flex' : 'none',
                              //   fontSize: '0.8rem',
                              //   marginLeft: '10px',
                              // }}
                            >
                              <div style={{width: '98%', marginLeft: 5}}>
                                <OrderItem
                                  bodyStyle={{
                                    padding: 0,
                                    height: '100%',
                                    opacity: item.orderedItemStatus === 'REJECTED' ? 0.5 : 1,
                                  }}
                                  key={index}
                                  style={{width: '95%', marginLeft: '10px', padding: 0}}>
                                  <Row
                                    justify={'center'}
                                    style={{
                                      height: '100%',
                                      overflow: 'hidden',
                                    }}>
                                    <Col span={6}>
                                      <Avatar
                                        style={{
                                          borderTopLeftRadius: 5,
                                          borderBottomLeftRadius: 5,
                                          borderTopRightRadius: 0,
                                          borderBottomRightRadius: 0,
                                          height: '130px',
                                          width: '100%',
                                        }}
                                        shape="circle"
                                        src={item.image}
                                      />
                                    </Col>
                                    <Col span={18}>
                                      <div
                                        style={{
                                          paddingLeft: 10,
                                          paddingRight: 15,
                                          paddingBottom: 1,
                                          paddingTop: 3,
                                          marginBottom: 1,
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'space-around',
                                          minHeight: 40,
                                        }}>
                                        <Tooltip title={item.itemName}>
                                          <Text
                                            style={{
                                              textAlign: 'left',
                                              fontStyle: 'italic',
                                              fontWeight: 600,
                                              color: '#5c5c5c',
                                              fontSize: '0.85rem',
                                              display: 'flex',
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                            }}>
                                            Item :- {'    '}
                                            <div
                                              style={{
                                                color: 'black',
                                                marginLeft: 12,
                                              }}>
                                              {' '}
                                              {item.itemName}
                                            </div>
                                          </Text>
                                        </Tooltip>

                                        {item.remarks && (
                                          <Text
                                            style={{
                                              textAlign: 'left',
                                              fontStyle: 'italic',
                                              fontWeight: 600,
                                              color: '#5c5c5c',
                                              fontSize: '0.85rem',
                                              display: 'flex',
                                              position: 'relative',
                                              bottom: '-1px',
                                              minHeight: '4rem',
                                            }}>
                                            <div
                                              style={{
                                                minWidth: '3.8rem',
                                              }}>
                                              Remarks :
                                            </div>

                                            {/* <Tooltip title={item.remarks}> */}
                                            <Text
                                              style={{
                                                color: 'black',
                                                fontSize: '0.85rem',
                                                marginLeft: 12,
                                                // whiteSpace: 'nowrap',
                                                // overflow: 'hidden',
                                                // textOverflow: 'ellipsis',
                                              }}>
                                              {item.remarks}
                                            </Text>
                                            {/* </Tooltip> */}
                                          </Text>
                                        )}

                                        <Row
                                          style={{
                                            position: 'relative',
                                            top: item.remarks ? '8px' : '55px',
                                          }}>
                                          <Col span={6} style={{textAlign: 'left', fontSize: '0.6rem'}}>
                                            <Text
                                              style={{
                                                textAlign: 'left',
                                                fontStyle: 'italic',
                                                fontWeight: 600,
                                                color: '#5c5c5c',
                                                fontSize: '0.85rem',
                                                display: 'flex',
                                              }}>
                                              Qty :
                                            </Text>
                                          </Col>
                                          <Col span={1} style={{textAlign: 'left', fontSize: '0.6rem'}}>
                                            <Text
                                              style={{
                                                color: 'black',
                                                fontSize: '0.85rem',
                                                marginLeft: -12,
                                              }}>
                                              {item.quantity}
                                            </Text>
                                          </Col>
                                          <Col
                                            span={15}
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'end',
                                            }}>
                                            <ShadowedButton
                                              size="small"
                                              disabled={item.orderedItemStatus === 'REJECTED'}
                                              style={{
                                                width: '60px',
                                                fontSize: '0.7rem',
                                                height: '30px',
                                                position: 'relative',
                                                bottom: '6px',
                                              }}
                                              type="primary"
                                              danger
                                              onClick={() => {
                                                setOpenReason(true);
                                                // onClickReject(item, post)
                                                setSelectedOrderItem(item);
                                                setSelectedOrder(post);
                                                form.resetFields();
                                              }}>
                                              {item.orderedItemStatus === 'REJECTED' ? 'Rejected' : 'Reject'}
                                            </ShadowedButton>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                </OrderItem>
                                <div style={{height: 15}}></div>
                              </div>
                            </Badge.Ribbon>
                          );
                        })}
                      </ScrollCardWrapper>

                      <div id="blood-screening" style={{height: '40px', marginTop: 16, borderRadius: 5}}>
                        <Row>
                          <Col span={2}> </Col>
                          <Col span={10}>
                            <ShadowedButton
                              disabled={post.orderStatus === 'REQUEST_CONFIRMATION' ? true : !isEnab}
                              style={{
                                width: '100%',
                                backgroundColor:
                                  post.orderStatus === 'REQUEST_CONFIRMATION' ? 'gray' : !isEnab ? 'gray' : 'green',
                              }}
                              size="small"
                              onClick={() => {
                                requestForRejection();
                              }}
                              type="primary">
                              Reconfirm order
                            </ShadowedButton>
                          </Col>
                          <Col span={2}> </Col>

                          <Col span={8}>
                            <ShadowedButton
                              size="small"
                              disabled={isEnab}
                              onClick={() => {
                                handleAcceptAll(post);
                              }}
                              style={{width: '100%', backgroundColor: isEnab ? 'gray' : 'green'}}
                              type="primary">
                              Accept Order
                            </ShadowedButton>
                          </Col>
                          <Col span={1}> </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </RightSideCol>
        </Col>
        <Col md={24} xl={14} xs={24} sm={12}>
          <ChefOrderCard
            GetDashboardCounts={() => GetDashboardCounts()}
            getAllChefOrders={getAllProcessChefOrders}
            acceptedItems={selectedItemsTable}
          />
        </Col>
      </Row>

      <Modal
        title="Reason for rejecting order"
        width={'350px'}
        style={{
          top: '3rem',
        }}
        open={openReason}
        // onOk={() => handleOpenChange(true, item)}

        onCancel={() => handleClose()}
        footer={false}>
        <div
          style={{
            width: '100%',
          }}>
          {/* <Meta
              style={{
                marginTop: '1rem',
              }}
              //   avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
              title={<Text style={{textAlign: 'center', fontSize: '1rem'}}>{selectedItem?.item}</Text>}

              //   description="This is the description"
            />
            <Text style={{textAlign: 'center', fontSize: '14px'}} type="danger">
              {selectedItem?.price} {selectedItem?.currency}
            </Text> */}
          <S.Space />
          <BaseForm form={form}>
            <BaseForm.Item
              name="reason"
              rules={[
                {
                  required: true,
                  message: 'Reason is required',
                },
              ]}>
              <TextArea
                rows={4}
                placeholder="Reason"
                onChange={e => setreason(e.target.value)}
                // ref={inputRef}
              />
            </BaseForm.Item>
          </BaseForm>

          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              marginBottom: '1rem',
              height: '50px',
            }}>
            <a onClick={() => handleClose()}>Close</a>
            <Button
              size="small"
              htmlType="submit"
              type="default"
              onClick={() => onClickReject(selectedOrderItem, selectedOrder, reason)}
              style={{
                fontSize: '12px',
                marginLeft: '0.7rem',
              }}>
              Save
            </Button>
          </div>
          {/* </BaseForm> */}
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(ChefDashBoard);
