import React from 'react';
import {Col, Row} from 'antd';
import {NotificationsDropdown} from '../components/notificationsDropdown/NotificationsDropdown';
import {ProfileDropdown} from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import {HeaderSearch} from '../components/HeaderSearch/HeaderSearch';
import {SettingsDropdown} from '../components/settingsDropdown/SettingsDropdown';
import {HeaderFullscreen} from '../components/HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';
import {SerendipityLogo, mainLogo} from '@app/assets';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import {useAppSelector} from '@app/hooks/reduxHooks';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const TopLevelDesktopHeader: React.FC<DesktopHeaderProps> = React.memo(({isTwoColumnsLayout}) => {
  const hotelConfig = useAppSelector(state => state.hotelSlice.hotelConfig);
  const location = useLocation();

  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <Row justify="space-between">
        <Col xl={15} xxl={12}>
          {/* <HeaderSearch /> */}
          <img alt="image" src={hotelConfig?.headerLogo} />
        </Col>
        <Col>{/* <S.GHButton /> */}</Col>
      </Row>
    </S.SearchColumn>
  ) : (
    <>
      <Col lg={10} xxl={8}>
        {/* <HeaderSearch /> */}
        <Link to={location.pathname === '/toplevel/products' ? '/toplevel/products' : '/toplevel/services'}>
          <img
            alt="image"
            src={
              location.pathname === '/toplevel/groups'
                ? SerendipityLogo
                : location.pathname === '/toplevel/products'
                ? hotelConfig?.groupHeaderLogo
                : location.pathname === '/toplevel/group-inventory-home'
                ? SerendipityLogo
                : hotelConfig?.headerLogo
            }
            style={{
              width: 340,
              maxHeight: 50,
              maxWidth: 340,
            }}
          />
        </Link>
      </Col>
      <Col>{/* <S.GHButton /> */}</Col>
    </>
  );

  return (
    <Row justify="space-between" align="middle">
      {leftSide}

      <S.ProfileColumn xl={8} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <Row align="middle" justify="end" gutter={[10, 10]}>
          <Col>
            <Row gutter={[{xxl: 10}, {xxl: 10}]}>
              <Col>{/* <HeaderFullscreen /> */}</Col>

              {/* <Col>
                <NotificationsDropdown />
              </Col> */}

              <Col>
                <SettingsDropdown />
              </Col>
            </Row>
          </Col>

          <Col>
            <ProfileDropdown />
          </Col>
        </Row>
      </S.ProfileColumn>
    </Row>
  );
});
