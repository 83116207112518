import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col, Image, Row, Card as AntdCard} from 'antd';
import {Card as CommonCard} from '@app/components/common/Card/Card';
import ProductItems from '../charts/ProductItems';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {useAppSelector} from '@app/hooks/reduxHooks';
import {
  getAllTotalItems,
  getAllTotalItemsForGroup,
  getLowItemCount,
  getLowItemCountForGroup,
  getOutOfStockItemCount,
  getOutOfStockItemCountForGroup,
  getZeroStockItemCount,
  getZeroStockItemCountForGroup,
} from '@app/api/inventory/dashboard.api';

const ProductsCountCard = styled.div`
  height: 6rem;
  width: 95%;
  box-shadow: 4px 4px #e3dcdc;
`;
const ProductImage = styled(Image)`
  width: 75%;
  height: 2.5rem;
  margin-top: 2rem;
  align-self: center;
  margin-left: 0.8rem;
`;
export const Card = styled(CommonCard)`
  width: 98%;
  boxShadow: '2px 2px 2px 2px #e3dcdc',
  margin-bottom: 1.25rem;
  .ant-card-head-title {
    font-size: 1rem;
    text-align: center;
  }
  .ant-card-body {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    align-items: center;
    padding: 0px 10px;
  }
  .ant-card-head {
    padding: 0 10px 10 0;
  }
  .ant-card-body:before {
    display: none;
  }
  .ant-card-body:after {
    display: none;
  }
  &.ant-card-bordered {
    border: 1px solid var(--border-color);
  }
`;

const {Meta} = AntdCard;
const InventoryDashboard = () => {
  const [totalItems, setTotalItems] = useState([]);
  const [lowItems, setLowItems] = useState([]);
  const [outOfStockItems, setOutOfStockItems] = useState([]);
  const [zeroStockItems, setZeroStockItems] = useState([]);
  const hotelConfig = useAppSelector(state => state.hotelSlice.hotelConfig);
  const {inventoryServiceId} = useAppSelector(state => state.serviceSlice.serviceConfig);

  const listTotalItems = async () => {
    try {
      const response = await getAllTotalItems(hotelConfig.hotelId, inventoryServiceId);
      setTotalItems(response?.result?.item);
    } catch (error) {}
  };

  const listLowItems = async () => {
    try {
      const response = await getLowItemCount(hotelConfig.hotelId, inventoryServiceId);
      setLowItems(response?.result?.item?.itemCount);
    } catch (error) {}
  };

  const listOutOfStockItems = async () => {
    try {
      const response = await getOutOfStockItemCount(hotelConfig.hotelId, inventoryServiceId);
      setOutOfStockItems(response?.result?.item?.itemCount);
    } catch (error) {}
  };

  const listZeroStockItems = async () => {
    try {
      const response = await getZeroStockItemCount(hotelConfig.hotelId, inventoryServiceId);
      setZeroStockItems(response?.result?.item?.itemCount);
    } catch (error) {}
  };

  // apis for group inventory
  const getTotalItemsForGroup = async () => {
    try {
      const response = await getAllTotalItemsForGroup(hotelConfig.groupId, inventoryServiceId);
      setTotalItems(response?.result?.item);
    } catch (error) {}
  };

  const getLowItemsForGroup = async () => {
    try {
      const response = await getLowItemCountForGroup(hotelConfig.groupId, inventoryServiceId);
      setLowItems(response?.result?.item?.itemCount);
    } catch (error) {}
  };

  const getOutOfStockItemsForGroup = async () => {
    try {
      const response = await getOutOfStockItemCountForGroup(hotelConfig.groupId, inventoryServiceId);
      setOutOfStockItems(response?.result?.item?.itemCount);
    } catch (error) {}
  };

  const getZeroStockItemsForGroup = async () => {
    try {
      const response = await getZeroStockItemCountForGroup(hotelConfig.groupId, inventoryServiceId);
      setZeroStockItems(response?.result?.item?.itemCount);
    } catch (error) {}
  };

  useEffect(() => {
    if (hotelConfig.hotelId === 0) {
      getTotalItemsForGroup();
      getLowItemsForGroup();
      getOutOfStockItemsForGroup();
      getZeroStockItemsForGroup();
    } else {
      listTotalItems();
      listLowItems();
      listOutOfStockItems();
      listZeroStockItems();
    }
  }, []);
  return (
    <div>
      <PageTitle>Dashboard</PageTitle>
      <Row>
        <Col md={6} xs={24} sm={12}>
          <ProductsCountCard style={{backgroundColor: '#f27b53'}}>
            <Row>
              <Col span={6}>
                <ProductImage preview={false} src="https://www.freeiconspng.com/uploads/production-icon-20.png" />
              </Col>
              <Col span={18} style={{paddingTop: '1.5rem'}}>
                <Meta
                  title={<div style={{fontSize: '1rem'}}>Total Products</div>}
                  description={<div style={{fontSize: '1rem', fontWeight: 'bold'}}>{totalItems}</div>}
                />
              </Col>
            </Row>
          </ProductsCountCard>
        </Col>
        <Col md={6} xs={24} sm={12}>
          <ProductsCountCard style={{backgroundColor: '#df577b'}}>
            <Row>
              <Col span={6}>
                <ProductImage preview={false} src="https://www.freeiconspng.com/uploads/production-icon-20.png" />
              </Col>
              <Col span={18} style={{paddingTop: '1.5rem'}}>
                <Meta
                  title={<div style={{fontSize: '1rem'}}>Low Stock Products</div>}
                  description={<div style={{fontSize: '1rem', fontWeight: 'bold'}}>{lowItems}</div>}
                />
              </Col>
            </Row>
          </ProductsCountCard>
        </Col>
        <Col md={6} xs={24} sm={12}>
          <ProductsCountCard style={{backgroundColor: '#847cc5'}}>
            <Row>
              <Col span={6}>
                <ProductImage preview={false} src="https://www.freeiconspng.com/uploads/production-icon-20.png" />
              </Col>
              <Col span={18} style={{paddingTop: '1.5rem'}}>
                <Meta
                  title={<div style={{fontSize: '1rem'}}>Out of Stock Products</div>}
                  description={<div style={{fontSize: '1rem', fontWeight: 'bold'}}>{outOfStockItems}</div>}
                />
              </Col>
            </Row>
          </ProductsCountCard>
        </Col>
        <Col md={6} xs={24} sm={12}>
          <ProductsCountCard style={{backgroundColor: '#fbb168'}}>
            <Row>
              <Col span={6}>
                <ProductImage preview={false} src="https://www.freeiconspng.com/uploads/production-icon-20.png" />
              </Col>
              <Col span={18} style={{paddingTop: '1.5rem'}}>
                <Meta
                  title={<div style={{fontSize: '1rem'}}>Zero Stock Products</div>}
                  description={<div style={{fontSize: '1rem', fontWeight: 'bold'}}>{zeroStockItems}</div>}
                />
              </Col>
            </Row>
          </ProductsCountCard>
        </Col>
      </Row>
      {/* <Row style={{marginTop: 20}}>
        <Col md={8} xs={24} sm={24}>
          <Card title="Stock Statistics">
            <DonutPlot data={stockData} title="Total Stock" />
          </Card>
        </Col>
        <Col md={8} xs={24} sm={24}>
          <Card title="Supplier Statistics">
            <DonutPlot data={supplierData} title="Total Supplier" />
          </Card>
        </Col>
        <Col md={8} xs={24} sm={24}>
          <Card title="GRN Statistics">
            <DonutPlot data={grnData} title="Total GRN" />
          </Card>
        </Col>
      </Row> */}

      {/* <Row style={{marginTop: 20}}>
        <Col md={24} xs={24} sm={24}>
          <Card style={{width: '100%'}} title="Top 5 Fast Moving Items">
            <LineChart />
          </Card>
        </Col>
      </Row> */}

      <Row style={{marginTop: 30}}>
        <Col md={24} xs={24} sm={24}>
          <Card style={{width: '100%'}} title="Product Items">
            <ProductItems />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default InventoryDashboard;
