import inventoryInstance, {INVENTORY_SERVICE} from '@app/api/inventoryInstance';

export const CreateLocation = (payload: CreateLocationProps): Promise<LocationResponse> => {
  return inventoryInstance.post<LocationResponse>(INVENTORY_SERVICE + 'location', payload).then(({data}) => data);
};

export const UpdateLocation = (payload: UpdateLocationProps): Promise<LocationResponse> => {
  return inventoryInstance.put<LocationResponse>(INVENTORY_SERVICE + 'location', payload).then(({data}) => data);
};

export const getAllLocations = (
  {name}: any,
  pageSize: number | undefined,
  current: number,
): Promise<LocationResponse> =>
  inventoryInstance
    .get<LocationResponse>(
      INVENTORY_SERVICE +
        `location/search?page=${current}&size=${pageSize}&sortField=id&direction=DESC&name=${name ? name : ''}`,
    )
    .then(({data}) => data);

export const getAllLocationsForDropDown = (): Promise<LocationResponse> =>
  inventoryInstance.get<LocationResponse>(INVENTORY_SERVICE + `location/`).then(({data}) => data);

export const DeleteLocation = (id: number): Promise<LocationResponse> =>
  inventoryInstance.delete<LocationResponse>(INVENTORY_SERVICE + `location/${id}`).then(({data}) => data);

export interface CreateLocationProps {
  name: string;
  descriptions: string;
}

export interface UpdateLocationProps {
  id: number;
  name: string;
  descriptions: string;
}

export interface LocationResponse {
  message: string;
  result: any;
  status: string;
  statusCode: string;
}

export interface FilterProps {
  name: string;
}
