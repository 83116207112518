import * as React from 'react';
import './TopLevelFilter.style.css';
import {Tag} from 'antd';

interface IProps {
  texts: string[];
  title: string;
  interval: number;
  size?: number;
  type?: string;
}

const BlinkingText = ({texts, title, size = 14, interval = 1300, type = 'USER'}: IProps) => {
  const [visibleTextIndex, setVisibleTextIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setVisibleTextIndex(prevIndex => (prevIndex + 1) % texts.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, [texts, interval]);

  return (
    <div
      style={{fontSize: `${size}px`, color: 'white', borderRadius: 0, cursor: 'default'}}
      className="blink-conformation-button">
      {type === 'USER' ? (
        texts &&
        texts[visibleTextIndex] && (
          <Tag style={{borderRadius: 0}} color="#f72e38">{`${title} ${texts[visibleTextIndex]}`}</Tag>
        )
      ) : texts && texts[visibleTextIndex] ? (
        <div>{`${title} ${texts[visibleTextIndex]}`}</div>
      ) : (
        ''
      )}
    </div>
  );
};
export default BlinkingText;
