import React from 'react';
import {
  UserOutlined,
  InsertRowLeftOutlined,
  DiffOutlined,
  SelectOutlined,
  CarryOutOutlined,
  CloseSquareOutlined,
  DollarCircleOutlined,
  PropertySafetyOutlined,
  SettingOutlined,
  TeamOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import {BsCurrencyEuro} from 'react-icons/bs';
import {FaChild} from 'react-icons/fa';
import {HOTEL_SERVICE_MODULE_NAME, SERVICE_ADMIN_MODULE_NAME} from '@app/utils/permissions';
import {BiUser} from 'react-icons/bi';
import {TbReport} from 'react-icons/tb';

export interface SidebarNavigationSubItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationSubItem[];
  icon?: React.ReactNode;
  isSubMenu: boolean;
}
export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationSubItem[];
  icon?: React.ReactNode;
  onTitleClick?: (key: string) => void;
  isSubMenu: boolean;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Manage Reservation',
    key: 'manage-reservation',
    icon: <CarryOutOutlined />,
    url: '/manage-reservation',
    isSubMenu: true,
    children: [
      {
        title: 'Reservations',
        key: HOTEL_SERVICE_MODULE_NAME.RESERVATIONS,
        url: '/reservations',
        isSubMenu: false,
      },
      {
        title: 'Reserve Rooms',
        key: HOTEL_SERVICE_MODULE_NAME.RESERVE_ROOMS,
        url: '/reservation',
        isSubMenu: false,
      },
      {
        title: 'Make Inquiry',
        key: HOTEL_SERVICE_MODULE_NAME.CREATE_INQUIRY,
        url: '/create-inquiry',
        isSubMenu: false,
      },
      {
        title: 'Inquiries History',
        key: HOTEL_SERVICE_MODULE_NAME.INQUIRY_HISTORY,
        url: '/inquiries',
        isSubMenu: false,
      },
      {
        title: 'Overview',
        key: HOTEL_SERVICE_MODULE_NAME.OVERVIEW,
        url: '/overview',
        isSubMenu: false,
      },
      {
        title: 'Room Overview',
        key: HOTEL_SERVICE_MODULE_NAME.ROOM_OVERVIEW,
        url: '/rooms-status-overview',
        isSubMenu: false,
      },
      {
        title: 'History',
        key: HOTEL_SERVICE_MODULE_NAME.HISTORY,
        url: '/history',
        isSubMenu: false,
      },
    ],
  },
  {
    title: 'Guest',
    key: HOTEL_SERVICE_MODULE_NAME.GUEST,
    url: '/guest',
    icon: <UserOutlined />,
    isSubMenu: false,
  },
  {
    title: 'Invoicing',
    key: 'invoice',
    icon: <DiffOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Invoice',
        key: HOTEL_SERVICE_MODULE_NAME.INVOICE,
        url: '/invoice/make-invoice',
        isSubMenu: false,
      },
      {
        title: 'Additional Billing',
        key: HOTEL_SERVICE_MODULE_NAME.ADDITIONAL_BILLING,
        url: '/invoice/additional-billing',
        isSubMenu: false,
      },
      {
        title: 'Invoice History',
        key: HOTEL_SERVICE_MODULE_NAME.INVOICE_HISTORY,
        url: '/invoice/invoice-history',
        isSubMenu: false,
      },
      {
        title: 'Events Invoice',
        key: HOTEL_SERVICE_MODULE_NAME.EVENT_INVOICE,
        url: '/invoice/additional-events',
        isSubMenu: false,
      },
    ],
  },
  {
    title: 'master.room',
    key: 'room',
    icon: <InsertRowLeftOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'View Type',
        key: HOTEL_SERVICE_MODULE_NAME.VIEW_TYPE,
        url: '/room/view-type',
        isSubMenu: false,
      },
      {
        title: 'Amenities',
        key: HOTEL_SERVICE_MODULE_NAME.AMENITIES,
        url: '/room/amenities',
        isSubMenu: false,
      },
      {
        title: 'Room Area',
        key: HOTEL_SERVICE_MODULE_NAME.ROOM_AREA,
        url: '/room/room-area',
        isSubMenu: false,
      },
      {
        title: 'Room Type',
        key: HOTEL_SERVICE_MODULE_NAME.ROOM_TYPE,
        url: '/room/room-type',
        isSubMenu: false,
      },

      {
        title: 'master.createRoom',
        key: HOTEL_SERVICE_MODULE_NAME.ROOM,
        url: '/room/manage',
        isSubMenu: false,
      },
      {
        title: 'Stay Type',
        key: HOTEL_SERVICE_MODULE_NAME.STAY_TYPE,
        url: '/room/stay-type',
        isSubMenu: false,
      },
    ],
  },

  {
    title: 'Channels',
    key: 'channels',
    icon: <SelectOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Reservation Type',
        key: HOTEL_SERVICE_MODULE_NAME.RESERVATION_TYPE,
        url: '/room/reservation-type',
        isSubMenu: false,
      },
      {
        title: 'Channel Register',
        key: HOTEL_SERVICE_MODULE_NAME.CHANNEL_REGISTER,
        url: '/channel/new-channel',
        isSubMenu: false,
      },
      {
        title: 'Season',
        key: HOTEL_SERVICE_MODULE_NAME.SEASON,
        url: '/room/season',
        isSubMenu: false,
      },

      {
        title: 'Guide',
        key: HOTEL_SERVICE_MODULE_NAME.GUIDE,
        url: '/channel/guide',
        isSubMenu: false,
      },
      {
        title: 'Driver',
        key: HOTEL_SERVICE_MODULE_NAME.DRIVER,
        url: '/channel/driver',
        isSubMenu: false,
      },
    ],
  },
  {
    title: 'Pricing',
    key: 'pricing',
    icon: <DollarCircleOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Channel Price',
        key: HOTEL_SERVICE_MODULE_NAME.CHANNEL_PRICE,
        url: '/channel/day-price',
        isSubMenu: false,
      },
      {
        title: 'Seasonal Price',
        key: HOTEL_SERVICE_MODULE_NAME.SEASONAL_PRICE,
        url: '/channel/seasonal-price',
        isSubMenu: false,
      },
    ],
  },
  {
    title: 'Additional Services',
    key: 'additionalReservation',
    icon: <CarryOutOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Hall',
        key: HOTEL_SERVICE_MODULE_NAME.HALL,
        url: '/additional-service/hall',
        isSubMenu: false,
      },
      {
        title: 'Other Services',
        key: HOTEL_SERVICE_MODULE_NAME.ADDITIONAL_SERVICES,
        url: '/additional-service/additional-services',
        isSubMenu: false,
      },
      {
        title: 'Make Booking ',
        key: HOTEL_SERVICE_MODULE_NAME.ADDITIONAL_SERVICES_BOOKING,
        url: '/additional-service/make-service-booking',
        isSubMenu: false,
      },
      {
        title: 'History',
        key: HOTEL_SERVICE_MODULE_NAME.ADDITIONAL_SERVICES_BOOKING_HISTORY,
        url: '/additional-service/additional-services-history',
        isSubMenu: false,
      },
    ],
  },
  {
    title: 'Additional Events',
    key: 'additionalActivity',
    icon: <CarryOutOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Events',
        key: HOTEL_SERVICE_MODULE_NAME.EVENT,
        url: '/additional-activity/create-activity',
        isSubMenu: false,
      },
      {
        title: 'Events Pack',
        key: HOTEL_SERVICE_MODULE_NAME.EVENT_PACK,
        url: '/additional-activity/create-activity-pack',
        isSubMenu: false,
      },
      {
        title: 'Booking',
        key: HOTEL_SERVICE_MODULE_NAME.EVENT_BOOKING,
        url: '/additional-activity/create-activity-booking',
        isSubMenu: false,
      },
      {
        title: 'History',
        key: HOTEL_SERVICE_MODULE_NAME.EVENT_HISTORY,
        url: '/additional-activity/activity-booking-history',
        isSubMenu: false,
      },
    ],
  },

  {
    title: 'Tax',
    key: HOTEL_SERVICE_MODULE_NAME.TAX,
    url: '/tax/tax-master',
    icon: <BsCurrencyEuro />,
    isSubMenu: true,
  },
  {
    title: 'VAT Registry',
    key: HOTEL_SERVICE_MODULE_NAME.VAT_REGISTRY,
    url: '/vat-registry',
    icon: <BsCurrencyEuro />,
    isSubMenu: true,
  },
  {
    title: 'VAT Registry Portal',
    key: HOTEL_SERVICE_MODULE_NAME.VAT_REGISTRY_PORTAL,
    url: '/vat-registry-portal',
    icon: <BsCurrencyEuro />,
    isSubMenu: true,
  },

  {
    title: 'Child Policy',
    key: HOTEL_SERVICE_MODULE_NAME.CHILD_POLICY,
    url: '/childPolicy/child-policy',
    icon: <FaChild />,
    isSubMenu: false,
  },
  {
    title: 'Cancellation Policy',
    key: HOTEL_SERVICE_MODULE_NAME.CANCELLATION_POLICY,
    icon: <CloseSquareOutlined />,
    url: '/managereservation/cancellation-policy',
    isSubMenu: false,
  },
  {
    title: 'master.currencyRates',
    key: HOTEL_SERVICE_MODULE_NAME.CURRENCY_RATES,
    icon: <PropertySafetyOutlined />,
    url: '/currency-rates/currency-exchange',
    isSubMenu: false,
  },
  // {
  //   title: 'Websites',
  //   key: 'websites',
  //   icon: <CarryOutOutlined />,
  //   isSubMenu: true,
  //   children: [
  //     {
  //       title: 'Palmyrah House',
  //       key: 'palmyrah-mock-website',
  //       url: '/palmyrah-mock-website',
  //       icon: <DashboardOutlined />,
  //       isSubMenu: false,
  //     },
  //     {
  //       title: 'Middle Street',
  //       key: 'middle-street-mock-website',
  //       url: '/middle-street-mock-website',
  //       icon: <DashboardOutlined />,
  //       isSubMenu: false,
  //     },
  //     {
  //       title: 'Villa Thuya',
  //       key: 'villa-thuya-mock-website',
  //       url: '/villa-thuya-mock-website',
  //       icon: <DashboardOutlined />,
  //       isSubMenu: false,
  //     },
  //     {
  //       title: 'Lilly Pod',
  //       key: 'lilly-pod-mock-website',
  //       url: '/lilly-pod-mock-website',
  //       icon: <DashboardOutlined />,
  //       isSubMenu: false,
  //     },
  //     {
  //       title: 'Dendrobium House',
  //       key: 'dendrobium-mock-website',
  //       url: '/dendrobium-mock-website',
  //       icon: <DashboardOutlined />,
  //       isSubMenu: false,
  //     },
  //   ],
  // },

  {
    title: 'Setting',
    key: 'Setting',
    icon: <SettingOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Service Role',
        key: SERVICE_ADMIN_MODULE_NAME.SERVICE_ROLE,
        url: '/hotel-setting/service-role',
        icon: <TeamOutlined />,
        isSubMenu: false,
      },
      {
        title: 'Service Role Privilege',
        key: SERVICE_ADMIN_MODULE_NAME.SERVICE_ROLE_PRIVILEGE,
        url: '/hotel-setting/service-role-privilege',
        icon: <BiUser />,
        isSubMenu: false,
      },
      {
        title: 'User',
        key: SERVICE_ADMIN_MODULE_NAME.EMPLOYEE,
        url: '/hotel-setting/hotel-employee',
        icon: <UserAddOutlined />,
        isSubMenu: false,
      },
      {
        title: 'Reports',
        key: SERVICE_ADMIN_MODULE_NAME.REPORT,
        url: '/hotel-setting/reports',
        icon: <TbReport />,
        isSubMenu: false,
      },
      {
        title: 'Lead Type',
        key: 'lead_type',
        url: '/hotel-setting/lead-type',
        icon: <BiUser />,
        isSubMenu: false,
      },
      {
        title: 'Audit Log',
        key: 'audit_log',
        url: '/hotel-setting/audit-log',
        icon: <BiUser />,
        isSubMenu: false,
      },
    ],
  },
];
