/* eslint-disable @typescript-eslint/no-explicit-any */
import {getItemsByPoId} from '@app/api/inventory/purchaseOrder.api';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const getPurchaseOrderItems = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  {purchaseOrder: any},
  {poId: number},
  {rejectValue: string}
>('purchaseOrder/purchaseOrderItems', async ({poId}, {rejectWithValue}) => {
  try {
    const {result} = await getItemsByPoId(poId);
    const purchaseOrder = result?.purchaseOrder || [];
    return {purchaseOrder: purchaseOrder};
  } catch (error) {
    return rejectWithValue('Failed to fetch purchase items');
  }
});

interface IPurchaseOrder {
  purchaseOrder: any;
  loading: boolean;
  error: any;
}

const initialState: IPurchaseOrder = {
  purchaseOrder: {},
  loading: false,
  error: null,
};

export const inventorySlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPurchaseOrderItems.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getPurchaseOrderItems.fulfilled, (state, action) => {
      state.loading = false;
      state.purchaseOrder = action.payload.purchaseOrder;
    });
    builder.addCase(getPurchaseOrderItems.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export default inventorySlice.reducer;
