/* eslint-disable react/jsx-key */
import TopLevelCard from '@app/components/cards/TopLevelCard';
import styled from 'styled-components';
import React, {useEffect, useState} from 'react';
import './ProductLevelHome.less';
import {useNavigate} from 'react-router-dom';
import {DENDROBIUM_HOUSE, LILY_POD, MIDDLE_STREET, PALMYRAH_HOUSE, VILLA_THUYA} from '@app/assets';
import {TopLevelCardProps} from './Restaurant/Interfaces';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {
  getHotelServiceByHotelId,
  setHotelConfig,
  setHotelServiceConfig,
  setIsServiceLevel,
} from '@app/store/slices/hotelSlice';
import {getAllHotelsByGroupId, getAllHotelsByUserAndGroupId} from '@app/api/groupAdmin/hotel/hotel.api';
import {Empty} from 'antd';
import {Button} from '@app/components/common/buttons/Button/Button';
import {Link} from 'react-router-dom';
import {sortBy} from 'lodash';
import {HOTEL_ADMIN_MODULE_NAME} from '@app/utils/permissions';
export const FadeIn = styled.div`
  animation: fadeInAnimation 1s linear;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
const ProductsContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

interface IHotelData {
  id: number;
  key: number;
  name: string;
  title: string;
  address: string;
  contactNumber?: string;
  email?: string;
  type?: string;

  cardLogo: string;
  cardImage: string;
  sideBarImage: string;
  titleImage: string;
  cardImageUrl?: string;
  cardLogoUrl?: string;
  sideBarImageUrl?: string;
  titleImageUrl?: string;
}

const ProductData: TopLevelCardProps[] = [
  {
    name: 'PALMYRAH HOUSE',
    logo: 'https://www.palmyrahhouse.com/assets/frontend//images/f_pal.png',
    bgUrl: PALMYRAH_HOUSE,
    info: 'Thalaimannar Rd, Mannar Island,Mannar, Sri Lanka.',
    headerLogo: 'https://www.palmyrahhouse.com/assets/frontend/images/logo.png',
    sideBarLogo: 'https://www.palmyrahhouse.com/assets/frontend//images/palmairah-002.png',
  },
  {
    name: '32 MIDDLE STREET',
    logo: 'https://www.palmyrahhouse.com/assets/frontend//images/f_middle.png',
    bgUrl: MIDDLE_STREET,
    info: '32 Middle Street Galle Fort, Galle. Sri Lanka.',
    headerLogo: 'https://www.32middlestreet.lk/assets/frontend/images/logo.png',
    sideBarLogo: 'https://www.palmyrahhouse.com/assets/frontend//images/middle-002.png',
  },

  {
    name: 'VILLA THUYA',
    logo: 'https://www.palmyrahhouse.com/assets/frontend//images/f_thuya.png',
    bgUrl: VILLA_THUYA,
    info: '520/3, Galle road, Balapitiya, Sri Lanka',
    headerLogo: 'https://www.villathuya.lk/assets/frontend/images/logo.png',
    sideBarLogo: 'https://www.palmyrahhouse.com/assets/frontend//images/villathuya-002.png',
  },

  {
    name: 'LILY POD',
    logo: 'https://www.palmyrahhouse.com/assets/frontend//images/f_lily.png',
    bgUrl: LILY_POD,
    info: 'No. 4 Lake World, Hirana, Bolgoda, Panadura,Sri Lanka.',
    headerLogo: '	https://www.lilypod.lk/assets/frontend/images/logo.png',
    sideBarLogo: 'https://www.palmyrahhouse.com/assets/frontend//images/lilpad-002.png',
  },
  {
    name: 'DENDROBIUM HOUSE',
    logo: 'https://www.palmyrahhouse.com/assets/frontend//images/dend_logo.png',
    bgUrl: DENDROBIUM_HOUSE,
    info: 'Igurugolla Estate, Hanthana, Kandy, Sri Lanka',
    headerLogo: 'https://www.dendrobiumhouse.lk/assets/frontend/images/logo.png',
    sideBarLogo: 'https://www.palmyrahhouse.com/assets/frontend//images/dend_logo.png',
  },
];

const ProductLevelHome = React.memo(() => {
  const [hotelData, setHotels] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {hotelConfig} = useAppSelector(state => state.hotelSlice);
  const {userPermission} = useAppSelector(state => state.user);

  const isEffectExecuted = localStorage.getItem('isEffectExecutedProd');
  useEffect(() => {
    localStorage.setItem('isEffectExecutedProd', ``);
    const xEff = isEffectExecuted ? true : false;
    if (!xEff) {
      dispatch(setIsServiceLevel(false));
      dispatch(
        setHotelServiceConfig({
          name: '',
          serviceId: 0,
          serviceType: 'NO_SERVICE',
        }),
      );
      localStorage.setItem('isEffectExecutedProd', `${true}`);
    }
  }, []);

  const onChangeCard = async (data: IHotelData) => {
    dispatch(
      setHotelConfig({
        hotelId: data.id,
        title: data.title,
        headerLogo: data.sideBarImage,
        sideBarLogo: data.titleImage,
        groupId: hotelConfig.groupId,
        groupTitle: hotelConfig.groupTitle,
        groupHeaderLogo: hotelConfig.groupHeaderLogo,
        hotelType: data.type,
        userLevel: hotelConfig.userLevel,
      }),
    );
    navigate('/toplevel/services', {state: {service: data}});
  };

  useEffect(() => {
    dispatch(
      setHotelConfig({
        hotelId: 0,
        title: '',
        headerLogo: '',
        sideBarLogo: '',
        groupId: hotelConfig.groupId,
        groupTitle: hotelConfig.groupTitle,
        groupHeaderLogo: hotelConfig.groupHeaderLogo,
        hotelType: '',
        userLevel: '',
      }),
    );
    getHotelByGroupId();
  }, []);

  const getHotelByGroupId = async () => {
    try {
      const results = await getAllHotelsByUserAndGroupId(hotelConfig.groupId, userPermission?.userId);

      if (results.result.hotel?.length > 0) {
        setIsCreate(true);
      } else {
        setIsCreate(false);
      }
      setHotels(results.result.hotel);
    } catch (error) {}
  };

  return (
    <ProductsContainer>
      {isCreate ? (
        sortBy(hotelData, 'id').map((post: IHotelData, i: number) => {
          return (
            <FadeIn onClick={() => onChangeCard(post)}>
              <TopLevelCard key={i} bgUrl={post.cardImage} logo={post.cardLogo} name={post.title} info={post.address} />
            </FadeIn>
          );
        })
      ) : (
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description={<div>{"Sorry, you don't have any hotels yet. Please create a new hotel to continue."}</div>}>
          <Link to={'/group-admin/hotel'}>
            <Button type="primary">Create Hotel</Button>
          </Link>
        </Empty>
      )}
    </ProductsContainer>
  );
});

export default ProductLevelHome;
