/* eslint-disable @typescript-eslint/no-explicit-any */
import instance, {HOTEL_SERVICE} from '@app/api/instance';
import {FilterProps, IReservedRoomFilter} from '@app/components/apps/roomFeed/interface';
import {
  IChangeRoomPayload,
  IChangeStayTypePayload,
  ICheckInPayload,
  ICheckOutPayload,
  ICreatePayload,
  IRoomDiscountPayload,
  IRoomExtendPayload,
} from '@app/pages/Hotel/RoomReservation/interface/interface';

export const getAllAvailableRooms = (
  hotelId: number,
  {checkedIn, checkedOut, ageList, resident, adultCount, childCount, channelId, showMore, hotelType}: FilterProps,
): Promise<ReservationResponse> =>
  instance
    .get<ReservationResponse>(
      HOTEL_SERVICE +
        `reservation/multi/reservation/stayType/search?page=0&size=100&sortField=id&direction=DESC&checkInDate=${checkedIn}&checkOutDate=${checkedOut}&hotelId=${hotelId}&childCount=${childCount}&adultCount=${adultCount}&resident=${resident}&idList=${ageList}&channelId=${channelId}&showMore=${showMore}&hotelType=${hotelType}`,
    )
    .then(({data}) => data);

export const createReservation = (payload: ICreatePayload): Promise<ReservationResponse> => {
  return instance.put<ReservationResponse>(HOTEL_SERVICE + 'reservation/room-block', payload).then(({data}) => data);
};

export const getAllReservedRooms = (
  hotelId: number,
  {checkInDate, mainGuestFirstName, checkOutDate, refNumber, channelName}: IReservedRoomFilter,
  pageSize: number | undefined,
  current: number,
): Promise<ReservationResponse> =>
  instance
    .get<ReservationResponse>(
      HOTEL_SERVICE +
        `reserved-room/search?page=${current}&size=${pageSize}&reservedRoomStatus=BOOKED,CHECKEDIN&checkOutDate=${
          checkOutDate ? checkOutDate : ''
        }&roomId=&checkInDate=${checkInDate ? checkInDate : ''}&mainGuestFirstName=${
          mainGuestFirstName ? mainGuestFirstName : ''
        }&hotelId=${hotelId}&refNumber=${refNumber ? refNumber : ''}&channelName=${
          channelName ? channelName : ''
        }&sortField=reservedRoomStatus&direction=DESC&sortField2=reservation.checkOutDate&direction2=ASC&sortField3=reservation.checkInDate&direction3=ASC`,
    )
    .then(({data}) => data);

export const getAllReservedHistories = (
  hotelId: number,
  {checkInDate, mainGuestFirstName, mainGuestLastName, checkOutDate, refNumber, channelName}: IReservedRoomFilter,
  pageSize: number | undefined,
  current: number,
): Promise<ReservationResponse> =>
  instance
    .get<ReservationResponse>(
      HOTEL_SERVICE +
        `reserved-room/history/search?page=${current}&size=${100}&sortField=id&direction=DESC&reservedRoomStatus=CANCELLED,CHECKEDOUT&checkOutDate=${
          checkOutDate ? checkOutDate : ''
        }&roomId=&checkInDate=${checkInDate ? checkInDate : ''}&mainGuestFirstName=${
          mainGuestFirstName ? mainGuestFirstName : ''
        }mainGuestLastName=${mainGuestLastName ? mainGuestLastName : ''}&hotelId=${hotelId}&refNumber=${
          refNumber ? refNumber : ''
        }&channelName=${channelName ? channelName : ''}`,
    )
    .then(({data}) => data);

export const getAllReservationsHistories = (
  hotelId: number,
  {checkInDate, mainGuestFirstName, mainGuestLastName, checkOutDate, refNumber, channelName}: IReservedRoomFilter,
  pageSize: number | undefined,
  current: number,
): Promise<ReservationResponse> =>
  instance
    .get<ReservationResponse>(
      HOTEL_SERVICE +
        `reservation/history/search?page=${current}&size=${pageSize}&sortField=id&direction=DESC&reservationStatusIds=4,6&checkOutDate=${
          checkOutDate ? checkOutDate : ''
        }&checkInDate=${checkInDate ? checkInDate : ''}&mainGuestFirstName=${
          mainGuestFirstName ? mainGuestFirstName : ''
        }&mainGuestLastName=${mainGuestLastName ? mainGuestLastName : ''}&refNumber=${
          refNumber ? refNumber : ''
        }&hotelId=${hotelId}&channelName=${channelName ? channelName : ''}&reservationStatus=`,
    )
    .then(({data}) => data);

export const getReservedRoomsById = (reservationId: number, roomId: number): Promise<ReservationResponse> =>
  instance
    .get<ReservationResponse>(HOTEL_SERVICE + `reserved-room/${reservationId}?roomId=${roomId}`)
    .then(({data}) => data);

export const getAllExpiredReservations = (
  hotelId: number,
  {mainGuestFirstName, mainGuestLastName, refNumber}: any,
): Promise<ReservationResponse> =>
  instance
    .get<ReservationResponse>(
      HOTEL_SERVICE +
        `reservation/all-expired/search?checkOutDate=&checkInDate=&mainGuestFirstName=${
          mainGuestFirstName ? mainGuestFirstName : ''
        }&mainGuestLastName=${mainGuestLastName ? mainGuestLastName : ''}&refNumber=${
          refNumber ? refNumber : ''
        }&hotelId=${hotelId}&channelName=&paymentStatus=COMPLETED`,
    )
    .then(({data}) => data);

export const updateReservedRoom = (payload: ICheckInPayload[]): Promise<ReservationResponse> =>
  instance.put<ReservationResponse>(HOTEL_SERVICE + `reserved-room`, payload).then(({data}) => data);

export const checkOutReservedRoom = (payload: ICheckOutPayload[]): Promise<ReservationResponse> =>
  instance.put<ReservationResponse>(HOTEL_SERVICE + `reserved-room/check-out`, payload).then(({data}) => data);

export const getCancellationPolicy = (
  reservedRoomId: number,
  hotelId: number,
  channelId: number,
): Promise<ReservationResponse> =>
  instance
    .get<ReservationResponse>(
      HOTEL_SERVICE +
        `reserved-room/cancellation?reservationRoomIds=${reservedRoomId}&hotelId=${hotelId}&channelId=${channelId}`,
    )
    .then(({data}) => data);

export const cancelReservation = (reservationId: number, payload: any[]): Promise<ReservationResponse> =>
  instance
    .put<ReservationResponse>(HOTEL_SERVICE + `reserved-room/cancel?reservationId=${reservationId}`, payload)
    .then(({data}) => data);

export const moveToExpireReservation = (payload: any): Promise<ReservationResponse> =>
  instance
    .put<ReservationResponse>(HOTEL_SERVICE + `reservation/expired-reserved-room`, payload)
    .then(({data}) => data);

export const getAvailableRoomsById = (
  id: number | undefined,
  checkedIn: string,
  checkOutDate: string,
  roomId: number,
): Promise<ReservationResponse> =>
  instance
    .get<ReservationResponse>(
      HOTEL_SERVICE +
        `reserved-room/available-room/${id}?checkInDate=${checkedIn}&checkOutDate=${checkOutDate}&roomId=${roomId}
      `,
    )
    .then(({data}) => data);

export const getPendingReservationStatus = (hotelId: number): Promise<ReservationResponse> =>
  instance
    .get<ReservationResponse>(
      HOTEL_SERVICE +
        `booking/reservation/blink-paid-pending/${hotelId}
          `,
    )
    .then(({data}) => data);

export const updateRoom = (payload: IChangeRoomPayload): Promise<ReservationResponse> =>
  instance.put<ReservationResponse>(HOTEL_SERVICE + `reserved-room/change-room`, payload).then(({data}) => data);

export const updateReservationStatus = (reservationId: number, paymentStatus: string): Promise<any> =>
  instance
    .get<any>(HOTEL_SERVICE + `booking/reservation/status-paid-pending/${reservationId}?paymentStatus=${paymentStatus}`)
    .then(({data}) => data);

export const getAllReservations = (
  hotelId: number,
  {checkInDate, mainGuestFirstName, checkOutDate, refNumber, channelName}: IReservedRoomFilter,
): Promise<ReservationResponse> =>
  instance
    .get<ReservationResponse>(
      HOTEL_SERVICE +
        `reservation/all/search?reservationStatusIds=1,2,3,5&checkOutDate=${
          checkOutDate ? checkOutDate : ''
        }&checkInDate=${checkInDate ? checkInDate : ''}&mainGuestFirstName=${
          mainGuestFirstName ? mainGuestFirstName : ''
        }&refNumber=${refNumber ? refNumber : ''}&hotelId=${hotelId ? hotelId : ''}&channelName=${
          channelName ? channelName : ''
        }&paymentStatus=COMPLETED
          `,
    )
    .then(({data}) => data);

export const getAllPendingReservations = (hotelId: number, status: string): Promise<ReservationResponse> =>
  instance
    .get<ReservationResponse>(
      HOTEL_SERVICE +
        `reservation/all/search?reservationStatusIds=1,2,3,5&checkOutDate=&checkInDate=&mainGuestFirstName=&refNumber=&hotelId=${
          hotelId ? hotelId : ''
        }&channelName=&paymentStatus=${status}
              `,
    )
    .then(({data}) => data);

export const getAllReservedRoomsByReservationId = (
  reservationId: number,
  status: string[],
): Promise<ReservationResponse> => {
  const joinedStatuses = status.join(',');
  return instance
    .get<ReservationResponse>(
      HOTEL_SERVICE +
        `reserved-room/all/search?reservationId=${reservationId}&reservedRoomStatus=${joinedStatuses}&roomId=&roomName=
          `,
    )
    .then(({data}) => data);
};

export const getAllCancellationPoliciesByReservationId = (
  reservationId: number,
  hotelId: number,
): Promise<ReservationResponse> =>
  instance
    .get<ReservationResponse>(
      HOTEL_SERVICE +
        `reserved-room/cancellation?hotelId=${hotelId}&reservationId=${reservationId}
          `,
    )
    .then(({data}) => data);

export const reSendPaymentMail = (reservationId: number): Promise<any> =>
  instance
    .get<any>(
      HOTEL_SERVICE +
        `payment-request-mail/${reservationId}
              `,
    )
    .then(({data}) => data);

export const reSendReservationMail = (reservationId: number): Promise<any> =>
  instance
    .get<any>(
      HOTEL_SERVICE +
        `payment-request-mail/${reservationId}
              `,
    )
    .then(({data}) => data);

export const changeStayTypes = (payload: IChangeStayTypePayload): Promise<ReservationResponse> =>
  instance.put<ReservationResponse>(HOTEL_SERVICE + `reserved-room/change-stay-type`, payload).then(({data}) => data);

export const updateExpireDateTime = (payload: any): Promise<ReservationResponse> =>
  instance.put<ReservationResponse>(HOTEL_SERVICE + `reservationpayment-time-update`, payload).then(({data}) => data);

export const blockedDirectBookingRoomsForReservation = (payload: any): Promise<ReservationResponse> =>
  instance.post<ReservationResponse>(HOTEL_SERVICE + `reservation/room-block`, payload).then(({data}) => data);

export const cancelBlockedReservation = (reservationId: number): Promise<ReservationResponse> =>
  instance
    .put<ReservationResponse>(HOTEL_SERVICE + `reservation/cancel-booking/${reservationId}`)
    .then(({data}) => data);
export const cancelBlockedReservedRoom = (reservationRoomId: number): Promise<ReservationResponse> =>
  instance
    .put<ReservationResponse>(HOTEL_SERVICE + `reservation/remove-guest/${reservationRoomId}`)
    .then(({data}) => data);
export const updateNationality = (reservationId: number, isResident: boolean): Promise<ReservationResponse> =>
  instance
    .put<ReservationResponse>(HOTEL_SERVICE + `reservation/change-resident/${reservationId}?resident=${isResident}`)
    .then(({data}) => data);
export const uploadbankSlip = (reservationId: number, payload: any): Promise<ReservationResponse> =>
  instance
    .put<ReservationResponse>(HOTEL_SERVICE + `invoice/bank-slip/${reservationId}`, payload)
    .then(({data}) => data);

export const updateLeadType = (reservationId: number, leadTypeId: number): Promise<ReservationResponse> =>
  instance
    .put<ReservationResponse>(HOTEL_SERVICE + `lead-type/${reservationId}?leadTypeId=${leadTypeId}`)
    .then(({data}) => data);
export const updateBookingType = (reservationId: number, bookingType: string): Promise<ReservationResponse> =>
  instance
    .put<ReservationResponse>(HOTEL_SERVICE + `reservation/booking-type-update`, {reservationId, bookingType})
    .then(({data}) => data);

export const sendPaymentmail = (
  reservationId: number,
  expiredDate: string,
  expiredTime: string,
): Promise<ReservationResponse> =>
  instance
    .get<ReservationResponse>(
      HOTEL_SERVICE +
        `reservation-ota-gust-mail/${reservationId}?expiredDate=${expiredDate}&expiredTime=${expiredTime}`,
    )
    .then(({data}) => data);

export const exportReservationData = (hotelId: number): Promise<ReservationResponse> =>
  instance
    .get<ReservationResponse>(
      HOTEL_SERVICE + `reservation/download?hotelId=${hotelId}&page=0&size=10&sortField=id&direction=DESC`,
      {
        responseType: 'blob',
        headers: {
          ContentType: 'application/vnd.ms-excel',
        },
      },
    )
    .then(({data}) => data);

export const updateRoomDiscount = (payload: IRoomDiscountPayload): Promise<ReservationResponse> =>
  instance.put<ReservationResponse>(HOTEL_SERVICE + `reserved-rooms-discount`, payload).then(({data}) => data);

export const getReservationsAudit = (
  hotelId: number,
  {rowName}: any,
  pageSize: number | undefined,
  current: number,
): Promise<ReservationResponse> =>
  instance
    .get<ReservationResponse>(
      HOTEL_SERVICE +
        `audit-trail/insertsearch?page=${current}&size=${pageSize}&sortField=id&direction=DESC&action=INSERT&hotelId=${hotelId}&tableName=reservation&rowName=${
          rowName ? rowName : ''
        }`,
    )
    .then(({data}) => data);

export const getReservationsExpandAudit = (
  hotelId: number,
  {}: any,
  pageSize: number | undefined,
  current: number,
  reservationId: number,
): Promise<ReservationResponse> =>
  instance
    .get<ReservationResponse>(
      HOTEL_SERVICE +
        `audit-trail/insertsearch?page=${current}&size=${pageSize}&sortField=id&direction=DESC&action=INSERT&hotelId=${hotelId}&tableName=reserved_room_day_price&reservationId=${reservationId}&rowName=`,
    )
    .then(({data}) => data);

export const checkAvailabilityForRoomExtend = (payload: IRoomExtendPayload): Promise<ReservationResponse> =>
  instance.put<ReservationResponse>(HOTEL_SERVICE + `reserved-room/extend-reservation`, payload).then(({data}) => data);

export const applyRoomExtendDays = (payload: any): Promise<ReservationResponse> =>
  instance
    .post<ReservationResponse>(HOTEL_SERVICE + `reserved-room/extend-reservation`, payload)
    .then(({data}) => data);

export const updateReservationRemarks = (payload: any): Promise<ReservationResponse> =>
  instance.put<ReservationResponse>(HOTEL_SERVICE + `reservation/remark-update`, payload).then(({data}) => data);

export const cancelExpiredReservation = (payload: any): Promise<ReservationResponse> =>
  instance
    .put<ReservationResponse>(HOTEL_SERVICE + `reservation/manual/expired-reserved-room`, payload)
    .then(({data}) => data);

export interface ReservedRooms {
  noOfAdults: number;
  noOfChildren: number;
  roomId: number;
  keyHandOver: boolean;
  stayTypeId: number;
  guest_id: number;
}

export interface CreateReservationProps {
  tourName: string;
  grcNumber: string;
  internalRemarks: string;
  checkInMeal: boolean;
  checkOutMeal: boolean;
  checkInDate: string;
  checkOutDate: string;
  checkInTime: string;
  checkOutTime: string;
  comments: string;
  channelId: number;
  reservationTypeId: number;
  guideId: number;
  baseCurrencyId: number;
  reservationCurrencyId: number;
  reservedRooms: ReservedRooms[];
}

export interface ReservationResponse {
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
  status: string;
  statusCode: string;
  pagination: {
    pageNumber: number;
    totalRecords: number;
  };
}
