import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface ICleaningCard {
  key: number;
  name: string;
  desc: string;
}

interface IHouseKeepingCard {
  key: number;
  name: string;
  desc: string;
}

interface ICleaningCard {
  key: number;
  name: string;
  desc: string;
}

interface ICheckList {
  cleaning: any;
  refilling: any;
  changing: any;
}

export interface ISelectedRooms {
  roomTypeId: any;
  roomId: number;
  roomName: string;
  roomImageList: string[];
  selected: boolean;
  checkList: ICheckList;
  roomNumber: string;
  roomTypeName: string;
  roomStatus: string;
  areaIdList: number[];
  onDemandStatus: string;
}

export interface ISelectedRoomsCheckList {
  roomId: number;
  roomName: string;
  roomImage: string;
  selected: boolean;
  checkList: ICheckList;
  roomNumber: string;
  roomStatus: string;
  id: number;
}

export interface ISelectedRoomActions {
  roomId: number;
  cleaning: number[];
  refilling: number[];
  changing: number[];
}
export interface ISelectedRoomActionsType {
  roomId: number;
  actionType: string;
}

export interface HouseKeepingState {
  cleaningCard: ICleaningCard;
  houseKeepingTopCards: IHouseKeepingCard;
  selectedCleaningRooms: ISelectedRooms[];
  selectedRoomsCheckList: ISelectedRoomsCheckList[];
  current: number;
  selectedCleaningRoomsActions: ISelectedRoomActions[];
  currentTap: number;
  selectedCleaningRoomsActionsType: ISelectedRoomActionsType;
}

const initialState: HouseKeepingState = {
  cleaningCard: {desc: '', key: 0, name: ''},
  houseKeepingTopCards: {desc: '', key: 0, name: ''},
  selectedCleaningRooms: [
    {
      roomId: 0,
      roomImageList: [],
      roomName: '',
      selected: false,
      checkList: {cleaning: [], refilling: []},
      roomNumber: '',
      roomTypeId: 0,
      roomTypeName: '',
      roomStatus: '',
      areaIdList: [],
    },
  ],
  current: 0,
  currentTap: 0,
  selectedCleaningRoomsActions: [],
  selectedCleaningRoomsActionsType: {actionType: '', roomId: 0},
  selectedRoomsCheckList: [
    {
      checkList: {cleaning: [], refilling: []},
      roomId: 0,
      roomImage: '',
      roomName: '',
      roomNumber: '',
      selected: false,
    },
  ],
};

export const houseKeepingSlide = createSlice({
  name: 'houseKeepingSlide',
  initialState,
  reducers: {
    setCleaningCardDetails: (state, action: PayloadAction<ICleaningCard>) => {
      state.cleaningCard = action.payload;
    },
    setHouseKeepingTopCardDetails: (state, action: PayloadAction<IHouseKeepingCard>) => {
      state.houseKeepingTopCards = action.payload;
    },
    setSelectedCleaningRooms: (state, action: PayloadAction<ISelectedRooms[]>) => {
      state.selectedCleaningRooms = action.payload;
    },
    setSelectedRoomsCheckList: (state, action: PayloadAction<ISelectedRoomsCheckList[]>) => {
      state.selectedRoomsCheckList = action.payload;
    },
    setCurrent: (state, action: PayloadAction<number>) => {
      state.current = action.payload;
    },
    setCurrentTap: (state, action: PayloadAction<number>) => {
      state.currentTap = action.payload;
    },
    setSelectedRoomActions: (state, action: PayloadAction<ISelectedRoomActions[]>) => {
      state.selectedCleaningRoomsActions = action.payload;
    },
    setSelectedRoomActionsType: (state, action: PayloadAction<ISelectedRoomActionsType>) => {
      state.selectedCleaningRoomsActionsType = action.payload;
    },
  },
});

export const {
  setCleaningCardDetails,
  setHouseKeepingTopCardDetails,
  setSelectedCleaningRooms,
  setCurrent,
  setSelectedRoomActions,
  setCurrentTap,
  setSelectedRoomActionsType,
  setSelectedRoomsCheckList,
} = houseKeepingSlide.actions;

export default houseKeepingSlide.reducer;
