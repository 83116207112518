import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import * as S from './ServiceReservation.style';
import {Tables} from '@app/components/tables/Tables/Tables';
import {ColumnsType} from 'antd/lib/table';
import MakeReservation from './MakeReservation';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {setLoading} from '@app/store/slices/commonSlice';
import {IServicesBookinsData} from './interface';
import {
  FilterProps,
  getAllBookedAdditionalServicesHistory,
} from '@app/api/hotel/additionalBookings/additionalBookings.api';
import {TablePaginationConfig} from 'antd/es/table';
import {HOTEL_SERVICE_MODULE_NAME, modulePermission} from '@app/utils/permissions';
import _ from 'lodash';
import InformationModal from './Modals/InformationModal';

export default function ServiceReservationHistory() {
  //get permission
  const userPermission = useAppSelector(state => state.user.permissions);
  const permissions = modulePermission(userPermission, HOTEL_SERVICE_MODULE_NAME.ADDITIONAL_SERVICES_BOOKING_HISTORY);
  const [form] = BaseForm.useForm();

  const dispatch = useAppDispatch();

  const [rowData, setRowData]: any = useState<IServicesBookinsData>();
  const [open, setopen] = useState(false);
  const [openCancel, setopenCancel] = useState(false);
  const [data, setdata] = useState([]);

  const [openInfo, setopenInfo] = useState(false);
  const [infoData, setInfoData] = useState<any>({});
  // eslint-disable-next-line prefer-const
  let [searchObj, setSearchObj]: any = useState({});

  const {hotelId} = useAppSelector(state => state.hotelSlice.hotelConfig);

  const [pagination, setPagination] = React.useState<TablePaginationConfig>({current: 0, pageSize: 10, total: 0});
  const columns: ColumnsType<any> = [
    {
      title: 'AD Invoice#',
      dataIndex: 'refNumber',
      align: 'center',
    },
    {
      title: 'Guest Name',
      dataIndex: 'ownerName',
      align: 'center',
      render: (text: string) => <span>{text}</span>,
    },

    {
      title: 'Booking Date',
      dataIndex: 'checkInDate',
      align: 'center',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Booking Time',
      dataIndex: 'checkInTime',
      align: 'center',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'additionalServiceReservationStatus',
      align: 'center',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Info',
      dataIndex: 'info',
      align: 'center',
      render(_value: any, record: any) {
        return (
          <S.InfoButton
            onClick={() => {
              setInfoData({
                ...record,
                totalAmount: record?.totalCharge,
              });
              setRowData(record);
              setopenInfo(true);
            }}>
            Info
          </S.InfoButton>
        );
      },
    },
  ];

  const resetForm = () => {
    form.resetFields();
    dispatch(setLoading(false));
  };

  const getTableData = async (searchQuery: FilterProps, pageSize: number | undefined, current: number) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = await getAllBookedAdditionalServicesHistory(
        hotelId,
        {
          additionalServiceReservationStatus: ['CANCELLED', 'RETRIEVED'],
          refNumber: searchQuery.refNumber,
          ownerName: searchQuery.ownerName,
        },
        pageSize,
        current,
      );
      setdata(data.result.additionalServiceReservation);
      setPagination({
        pageSize: pageSize,
        current: data.pagination.pageNumber + 1,
        total: data.pagination.totalRecords,
      });
    } catch (error) {}
  };

  useEffect(() => {
    getTableData(searchObj, pagination.pageSize, 0);
  }, []);

  const handleCloseInfo = () => {
    setopenInfo(false);
  };

  const handlePagination = (pagination: TablePaginationConfig) => {
    setPagination({
      pageSize: pagination.pageSize,
      current: pagination.current ? pagination.current - 1 : 0,
      total: pagination.total,
    });
    getTableData(searchObj, pagination.pageSize, pagination.current ? pagination.current - 1 : 0);
  };
  const onChangeTableSearch = (values: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = {...searchObj, ...values};
    searchObj = obj;
    setSearchObj(obj);
    getTableData(obj, pagination.pageSize, 0);
  };

  !permissions.DELETE && !permissions.EDIT && _.remove(columns, (col: any) => col.dataIndex === 'actions');

  console.log({rowData});

  return (
    <>
      <PageTitle>Additional Service Reservation History</PageTitle>
      <S.CurrencyWrapper>
        <S.TableWrapper>
          <Tables
            title="Additional Service Reservation History"
            tableData={data}
            columns={columns}
            isCreate={false}
            searchFields={['ownerName', 'refNumber']}
            onChangeSearch={onChangeTableSearch}
            pagination={{
              defaultPageSize: 10,
              defaultCurrent: 0,
              current: pagination.current,
              total: pagination.total,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20'],
            }}
            onChangeFilter={handlePagination}
            modalChildren={
              <MakeReservation
                reloadData={() => getTableData(searchObj, pagination.pageSize, 0)}
                form={form}
                rowData={rowData}
              />
            }
          />
        </S.TableWrapper>
        <InformationModal open={openInfo} close={handleCloseInfo} serviceId={rowData?.id} />
      </S.CurrencyWrapper>
    </>
  );
}
