import authInstance from '@app/api/authInstance';
import instance, {HOTEL_SERVICE, LOGIN_SERVICE} from '@app/api/instance';

export interface IHotelServiceRequest {
  id?: number;
  name: string;
  serviceType: 'RESTAURANT' | 'HOTEL' | 'INVENTORY' | 'HOUSEKEEPING';
  cardLogo: File;
  cardImage: File;
  active: boolean;
  hotelId: number;
}

export interface IApiResponse {
  message: string;
  result: any;
  status: string;
  statusCode: string;
}

export const CreateHotelService = (hotelPayload: IHotelServiceRequest): Promise<IApiResponse> => {
  const formData = new FormData();
  const obj = JSON.stringify({
    name: hotelPayload.name,
    serviceType: hotelPayload.serviceType,
    active: hotelPayload.active,
    hotelId: hotelPayload.hotelId,
  });
  formData.append('cardLogo', hotelPayload.cardLogo);
  formData.append('cardImage', hotelPayload.cardImage);

  formData.append('hotelService', obj);

  return instance.post<IApiResponse>(HOTEL_SERVICE + 'hotel-service', formData).then(({data}) => data);
};

export const getAllHotelServices = (): Promise<IApiResponse> =>
  instance.get<IApiResponse>(HOTEL_SERVICE + 'hotel-services').then(({data}) => data);

export const getHotelServicesByHotelId = (id: number): Promise<IApiResponse> =>
  instance.get<IApiResponse>(HOTEL_SERVICE + `hotel-service/hotel/${id}`).then(({data}) => data);

export const getHotelServicesByHotelIdList = (id: number[]): Promise<IApiResponse> =>
  instance.get<IApiResponse>(HOTEL_SERVICE + `hotel-service/hotel-list?hotelIdList=${id}`).then(({data}) => data);

export const getHotelServicesByGroupId = (id: number): Promise<IApiResponse> =>
  instance.get<IApiResponse>(HOTEL_SERVICE + `hotel-service/group/${id}`).then(({data}) => data);

export const UpdateHotelService = (hotelPayload: IHotelServiceRequest): Promise<IApiResponse> => {
  const formData = new FormData();
  const obj = JSON.stringify({
    id: hotelPayload.id,
    name: hotelPayload.name,
    serviceType: hotelPayload.serviceType,
    active: hotelPayload.active,
    hotelId: hotelPayload.hotelId,
  });
  formData.append('cardLogo', hotelPayload.cardLogo);
  formData.append('cardImage', hotelPayload.cardImage);

  formData.append('hotelService', obj);

  return instance.put<IApiResponse>(HOTEL_SERVICE + 'hotel-service', formData).then(({data}) => data);
};

export const DeleteHotelService = (id: number): Promise<IApiResponse> =>
  instance.delete<IApiResponse>(HOTEL_SERVICE + `hotel-service/${id}`).then(({data}) => data);

export const getHotelServicesByHotelIdAndUserId = (id: number, userId: number | undefined): Promise<IApiResponse> =>
  authInstance
    .get<IApiResponse>(LOGIN_SERVICE + `api/v1/hotel-service/hotel/${id}/user/${userId}`)
    .then(({data}) => data);
