export const BORDER_RADIUS = '7px';

export const BASE_COLORS = {
  white: '#ffffff',
  black: '#000000',
  green: '#008000',
  orange: '#ffb155',
  gray: '#808080',
  lightgrey: '#c5d3e0',
  violet: '#ee82ee',
  lightgreen: '#89dca0',
  pink: '#ffc0cb',
  blue: '#0000ff',
  skyblue: '#35a0dc',
  red: '#ff5252',
  primary: '#01509A',
  lightRed: '#f42f25',
  thinRed: '#ffccc7',
  redBorder: '#ff4d4f',
  lightBlue: '#EBF3FF',
  lightGray: '#F6F6F6',
  thinGreen: '#d9f7be',
  darkGreen: '#4C9A50',
  checkOutRed: '#ff0707',
  cancelRed: '#ff8e45',
  greenBorder: '#b7eb8f',
  skyblue2: '#259bc7',
  parcialColor: '#044f72',
  opacityOne: '#474747',
  yellow: '#ffec3d',
  cardHeader: '#CADCE9',
  cardBody: '#EDF5FB',
  redTag: '#FFE4DE',
  redTagText: '#FF5D34',
  redTagBorder: '#ffc5b9',
  lightningRed: '#ff0000',
  futureCheckin: '#9DB8A3',
};

export const ROOM_STATUS_COLOR = {
  ready: '#34eb67',
  checkedIn: '#34cceb',
  checkdOut: '#eba134',
  booked: '#9934eb',
  tempBlocked: '#1f1926',
  houseKeepingBlocked: '#6d676e',
};

export const LAYOUT = {
  mobile: {
    paddingVertical: '0.75rem',
    paddingHorizontal: '1rem',
    headerHeight: '4.25rem',
    headerPadding: '1rem',
  },
  desktop: {
    paddingVertical: '0.5rem',
    paddingHorizontal: '1rem',
    headerHeight: '4rem',
  },
};

export const FONT_FAMILY = {
  main: 'Montserrat',
  secondary: 'Lato',
};

export const FONT_SIZE = {
  xxxs: '0.5rem',
  xsm: '0.65rem',
  xxs: '0.75rem',
  xs: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  xxl: '1.5rem',
  xxxl: '1.625rem',
  xxxxl: '2rem',
};

export const FONT_WEIGHT = {
  thin: '100',
  extraLight: '200',
  light: '300',
  regular: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
  extraBold: '800',
  black: '900',
};

export const BREAKPOINTS = {
  xs: 360,
  sm: 568,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1920,
} as const;

const getMedia = <T extends number>(breakpoint: T): `(min-width: ${T}px)` => `(min-width: ${breakpoint}px)`;

export const media = {
  xs: getMedia(BREAKPOINTS.xs),
  sm: getMedia(BREAKPOINTS.sm),
  md: getMedia(BREAKPOINTS.md),
  lg: getMedia(BREAKPOINTS.lg),
  xl: getMedia(BREAKPOINTS.xl),
  xxl: getMedia(BREAKPOINTS.xxl),
};
