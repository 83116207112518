import styled, {css, keyframes} from 'styled-components';
import {Col, Typography} from 'antd';
import {BASE_COLORS, FONT_SIZE, FONT_WEIGHT, LAYOUT, media} from '@app/styles/themes/constants';
import {Card as CommonCard} from '@app/components/common/Card/Card';
import {MonthSelect} from '@app/components/common/selects/MonthSelect/MonthSelect';
import {Badge as CommonBadge, Calendar as CommonCalendar} from 'antd';
import Lottie from 'react-lottie';
import {Table} from '@app/components/common/Table/Table';

interface Event {
  $isPast: boolean;
}

interface MarkerProps {
  $isChild: boolean;
  $isReserved: boolean;
  $isToday: boolean;
}

type styleProps = {
  bgUrl: string;
};

const blinkAnimation = keyframes`
  0% { color: ${BASE_COLORS.white} }
  50% { color: ${BASE_COLORS.white} }
  100% { color: ${BASE_COLORS.black} }
`;

export const RightSideCol = styled(Col)`
  padding: 0rem 0rem;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${LAYOUT.desktop.headerHeight});
  background-color: var(--sider-background-color);
  overflow-y: auto;
  border-radius: 10px;
`;

export const LeftSideCol = styled(Col)`
  @media only screen and ${media.xl} {
    padding: 1rem;
    height: calc(100vh - ${LAYOUT.desktop.headerHeight});
    overflow: auto;
  }
`;

export const Space = styled.div`
  margin: 1rem 0;
`;

export const TopCatWrap = styled.div``;

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;

  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 15px;

  background: black;

  min-height: 300px;
  overflow-y: auto;
`;

export const Item = styled.div`
  background: red;
  height: 150px;
  flex-shrink: 0;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content flex-start;
  flex-wrap:wrap;
  gap: 15px;
  overflow-y: auto;
  box-shadow: 5px 5px 5px #bab9b6,
`;
export const ScrollCardWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 300px;
  padding: 4px;
  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`;
export const Card = styled.div`
  border: 1px solid var(--border-color);
  width: auto;
  border-radius: 8px;
  min-height: 182px;
`;

export const RoomCardContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
`;

export const FadeIn = styled.div`
  animation: fadeInAnimation 1s linear;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const GraphOutline = styled.div`
  border: 1px solid #01509a;
  background-color: #01509a;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Event = styled.div<Event>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: 85%;
  height: 85%;
  font-weight: ${FONT_WEIGHT.medium};
  background: #0dd713;
  color: #000
    ${props =>
      props.$isPast
        ? css`
            color: var(--text-main-color);
          `
        : css`
            color: var(--primary-color);
          `};

  border-radius: 50%;
`;

export const DashboardWrapper = styled.div`
  padding: 1rem;
`;
export const RoomsOverViewWrapper = styled.div`
  margin-bottom: 1rem;
`;
export const MarkerWrapper = styled.div<MarkerProps>`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.$isChild && props.$isReserved
      ? css`
          background-color: #0dd713;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          z-index: 1 !important;
          cursor: ${props.$isToday ? 'pointer' : 'not-allowed'};
        `
      : css`
          width: 20px;
          height: 20px;
          border-radius: 4px;
          background-color: #bbbbbb;
          cursor: ${props.$isToday ? 'pointer' : 'not-allowed'};
        `};
`;

export const CheckInReadyBox = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: #bbbbbb;
  cursor: not-allowed;
`;

export const StatusText = styled(Typography)`
  font-size: 8px;
  font-weight: bold;
`;

export const AvailableRoomForPending = styled.div<{isReserved: boolean; urgentRequested: boolean}>`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: ${props => (props.isReserved ? '#0dd713' : '#bbbbbb')};
  cursor: ${props => (props.urgentRequested ? 'not-allowed' : 'pointer')};
`;

export const AvailableRoomForInProgress = styled.div<{isReserved: boolean}>`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: ${props => (props.isReserved ? '#0dd713' : '#bbbbbb')};
  cursor: not-allowed;
`;

export const AvailableRoomForUrgentRequest = styled.div<{isReserved: boolean}>`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: ${props => (props.isReserved ? '#0dd713' : '#bbbbbb')};
  cursor: not-allowed;
`;

export const BlinkStatusText = styled(Typography)<{isBlink: boolean}>`
  font-size: 8px;
  font-weight: bold;
  animation: ${({isBlink}) =>
    isBlink
      ? css`
          ${blinkAnimation} 1s infinite;
        `
      : 'none'};
`;

const greenBlinkingAnimation = keyframes`
  0%{
    background-color: ${BASE_COLORS.lightningRed};
  }
  50% {
    background-color: #0dd713;
  }
  100% {
    background-color: ${BASE_COLORS.lightningRed};
  }
`;

export const CheckedInCompleted = styled.div<{isDisabled: boolean; isOndemand: boolean}>`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: #0dd713;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  // pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  ${({isOndemand}) =>
    isOndemand &&
    css`
      animation: ${greenBlinkingAnimation} 1s infinite;
    `}
`;

const blinkingAnimation = keyframes`
0%{
  background-color: ${BASE_COLORS.lightningRed};
}
50% {
  background-color: #0dd713;
}
100% {
  background-color: ${BASE_COLORS.lightningRed};
}
`;

export const BlinkMarkerWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0dd713;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  z-index: 1 !important;
  cursor: not-allowed;
  animation: ${greenBlinkingAnimation} 1s infinite;
`;

export const CustomMonthSelect = styled(MonthSelect)``;
export const Badge = styled(CommonBadge)``;
export const TopLevelCardWrap = styled.div`
  width: 100%;
  height: 18rem;
  background: #fff;
  padding: 1rem;
  border-radius: 12px;
  background-size: cover !important;
  background: linear-gradient(0deg, rgb(0 0 0 / 54%), rgb(187 187 187 / 39%)),
    url(${(props: styleProps) => props.bgUrl});
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  transition: all 0.4s ease-in-out;

  @media only screen and (max-width: 1400px) {
    margin-right: 1.3rem;
  }

  @media only screen and (min-width: 2000px) {
    width: 20.5rem;
    height: 30rem;
    font-size: 1rem;
  }
`;

export const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 2.3rem;
`;

export const TopLevelCardTitle = styled.div`
  font-weight: 700;
  color: #fff;

  @media only screen and (max-width: 1400px) {
    font-size: 1rem;
  }
`;

export const RoomTitle = styled.div`
  font-weight: 700;
  color: #000;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
  @media only screen and (max-width: 1400px) {
    font-size: 1.2rem;
  }
`;

export const CalendarTitle = styled.div`
  font-weight: 700;
  color: var(--primary-color);
  @media only screen and (max-width: 1400px) {
    font-size: ${FONT_SIZE.xxs};
  }
  text-align: center;
  margin-bottom: 5px;
`;
export const OverviewWrapper = styled.div``;
export const CalendarCardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const RoomCardWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
`;
export const Calendar = styled(CommonCalendar)`
  width: 14rem;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
  .ant-picker-cell-in-view .ant-picker-calendar-date-value {
    color: var(--text-main-color);
    font-weight: ${FONT_WEIGHT.regular};
    font-size: ${FONT_SIZE.xxs};
  }
  .ant-picker-panel {
    border-top: none;
    & .ant-picker-body {
      padding: 0;
    }
  }
  .ant-picker-calendar-header {
    display: none;
  }
  .ant-picker-content {
    table-layout: auto;
  }
  .ant-picker-cell-inner {
    font-weight: ${FONT_WEIGHT.medium};
    font-size: ${FONT_SIZE.xxs};
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .ant-picker-date-panel .ant-picker-content th {
    font-weight: ${FONT_WEIGHT.medium};
    font-size: ${FONT_SIZE.xxs};
    color: var(--primary-color);
  }
  .ant-picker-cell {
    &.ant-picker-cell-today {
      .ant-picker-cell-inner {
        &::before {
          border-color: var(--secondary-color);
        }
      }
    }

    &.ant-picker-cell-selected {
      .ant-picker-cell-inner {
        box-shadow: 0 5px 15px rgba(0, 89, 171, 0.3);

        background: var(--primary-color);

        border-radius: 50%;

        .ant-picker-calendar-date-value,
        ${Event} {
          color: var(--white);
        }

        .ant-picker-calendar-date-content > div {
          background: var(--primary-color);
        }
      }
    }

    .ant-picker-cell-inner {
      font-weight: ${FONT_WEIGHT.light};
      font-size: ${FONT_SIZE.xxs};
      height: 1.875rem;
      width: 1.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
    .ant-picker-cell-inner::before {
      border: none !important;
    }
  }
`;

export const ReserverLegend = styled.div`
  height: 20px;
  width: 20px;
  background-color: #0dd713;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

export const AvailableLegend = styled.div`
  height: 20px;
  width: 20px;
  background-color: #bbbbbb;
  border-radius: 2px;
`;
export const HouseKeepingLegend = styled.div`
  height: 20px;
  width: 20px;
  background-color: #bbbbbb;
  border-radius: 2px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

const onDemandBlinkAnimation = keyframes`
  0% { background-color: ${BASE_COLORS.white} }
  50% { background-color: ${BASE_COLORS.white} }
  100% { background-color: ${BASE_COLORS.lightningRed} }
`;

export const OnDemandLegend = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 2px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: ${BASE_COLORS.lightningRed};
  // animation: ${onDemandBlinkAnimation} 1s infinite;
`;

export const OnMorningEventLegend = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 2px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: #fadb14;
`;

export const OnEveningEventLegend = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 2px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: #fa8c16;
`;

export const OnMorningAandEveningEventLegend = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 2px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: #13c2c2;
`;

export const UrgentRequested = styled.div`
  height: 20px;
  width: 20px;
  background-color: #bbbbbb;
  border-radius: 2px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

export const BlinkLegendText = styled(Typography)`
  font-size: 9px;
  animation: ${blinkAnimation} 1s infinite;
`;
export const LegendText = styled(Typography)`
  font-size: 9px;
`;

export const ExtraWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  gap: 10px;
`;

export const LegendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
`;

export const LegendLabel = styled.div`
  font-size: 12px;
  color: #000;
  font-weight: ${FONT_WEIGHT.semibold};
`;

export const LegendSection = styled.div`
  display: flex;
  flex-direction: row;

  gap: 1rem;
`;

export const Title = styled.div`
  font-size: 1.4rem;
  color: #000;
  font-weight: 500;
`;
export const InnerTitle = styled.div`
  font-size: 1rem;
  color: #000;
  font-weight: 500;
  margin-top: 0.5rem;
`;
export const PrintIcon = styled.div`
  height: 50px;
  width: auto;
  background-color: #fff;
  border: 1px solid var(--border-base-color);
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0px 10px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
`;

export const StayTitleTable = styled.div`
  display: flex;
  line-height: 1.25rem;
  font-size: ${FONT_SIZE.xs};
  color: ${BASE_COLORS.black};
  gap: 0.3rem;
  align-items: center;
  background-color: white;
  height: 3rem;
`;

export const StayTitle = styled.div`
  display: flex;
  line-height: 1.25rem;
  font-size: ${FONT_SIZE.xs};
  color: ${BASE_COLORS.black};
  margin-top: 0.25rem;
  gap: 0.3rem;
`;
export const TitleIconWrapper = styled.div`
  display: flex;
  gap: 1px;
  align-items: center;
  justify-content: center;
`;
export const Popoverlabel = styled.div`
  font-size: ${FONT_SIZE.xs};
  color: ${BASE_COLORS.black};
`;
export const ReactLottie = styled(Lottie)`
  position: absolute;
  overflow: hidden;
`;
export const Tick = styled.div`
  height: 50%;
  width: 65%;
  font-size: 9px;
  justify-content: center;
  align-items: center;
  line-height: 14px;
  font-weight: ${FONT_WEIGHT.extraBold};
  border-radius: 50%;
  color: ${BASE_COLORS.black};
`;

export const FieldSetWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  margin-bottom: 1rem;
`;

export const DatePickerWrapper = styled.div`
  display: flex;
`;
export const CustomTable = styled(Table)`
  .ant-table-row {
    height: 5px;
    padding: 2px;
  }
  .ant-table.ant-table-small .ant-table-thead > tr > th {
    padding: 3px;
  }
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 3px;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  background-color: #e5e5e5;
  padding: 0.3rem;
  border: 1px solid cadetblue;
  border-radius: 5px;
  align-items: center;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
`;

export const FilterBtn = styled.div`
  border: solid 1px black;
  align-items: center;
  padding: 10px;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 5px;
  background-color: white;
  box-shadow: 5px 2px 5px #e9e2e2;
  cursor: pointer;
`;

export const DateCard = styled.div<{$isReserved: boolean; $isPastDate: boolean}>`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  // background-color: ${props => (props.$isReserved ? 'green' : 'grey')};
  // &:hover {
  //   background-color: ${props => !props.$isReserved && 'lightgray'};
  // }
  cursor: ${props => (props.$isReserved || props.$isPastDate ? 'not-allowed' : 'pointer')};
`;

export const DateCardLabel = styled.div<{$bgColor: string}>`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: ${props => props.$bgColor};
`;
export const StepsWrapper = styled.div`
  margin-bottom: 1rem;
  width: 90%;
`;

export const RenderedStayTitle = styled.div<{$isSelected: boolean}>`
  display: flex;
  line-height: 1.25rem;
  font-size: ${FONT_SIZE.xs};
  color: ${props => (props.$isSelected ? BASE_COLORS.white : BASE_COLORS.black)};
  margin-top: 0.25rem;
  gap: 0.3rem;
`;
export const StayTypeName = styled.div<{$isSelected: boolean}>`
  display: flex;
  border: 1px solid ${props => (props.$isSelected ? 'rgba(0, 123, 255, 0.5)' : BASE_COLORS.lightgrey)};
  background-color: ${props => (props.$isSelected ? '#5eef8eb3' : BASE_COLORS.white)};
  color: red;
  margin: 0.5rem 0.5rem 0.5rem 0;
  justify-content: center;
  align-items: center;
  font-size: ${FONT_SIZE.xs};
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
`;

export const BlueCard = styled.div`
  border: 1px solid var(--border-color);
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: ${BASE_COLORS.lightBlue};
  display: flex;
  justify-content: space-between;
  // align-items: center;
  // padding: 1rem;
  flex-direction: column;
`;
export const BlueCardWrapper = styled.div`
  display: flex;
  width: 30%;
  height: ${window.innerHeight - 100}px;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

export const Padding = styled.div`
  padding: 1rem;
`;

export const RoomNumber = styled.span`
  font-size: 18px;
  color: ${BASE_COLORS.primary};
  font-weight: bold;
`;
export const BookingDates = styled.span`
  font-size: 14px;
  color: ${BASE_COLORS.primary};
  font-weight: bold;
`;
export const DateSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const VerticalLine = styled.div`
  border-left: 1px solid var(--border-color);
  height: 40px;
`;
export const CheckInOutText = styled(Typography.Text)`
  display: flex;
  line-height: 1.25rem;
  font-size: ${FONT_SIZE.xs};
  color: ${BASE_COLORS.black};
  margin-bottom: 0.5rem;
`;

export const CheckIn = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DateText = styled(Typography.Text)`
  display: flex;
  line-height: 1.25rem;
  font-size: ${FONT_SIZE.xs};
  color: ${BASE_COLORS.black};
  font-weight: ${FONT_WEIGHT.bold};
`;
export const NightCount = styled(Typography.Text)`
  display: flex;
  line-height: 1.25rem;
  font-size: ${FONT_SIZE.xs};
  color: ${BASE_COLORS.black};
  font-weight: ${FONT_WEIGHT.bold};
`;
export const CheckOut = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StayNights = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
`;
export const RegularText = styled(Typography.Text)`
  display: flex;
  line-height: 1.25rem;
  font-size: ${FONT_SIZE.xs};
  color: ${BASE_COLORS.black};
  margin-bottom: 0.5rem;
  max-width: 60%;
`;
export const RoomNumberBold = styled.span`
  font-size: 28px;
  font-weight: bold;
  color: ${BASE_COLORS.primary};
`;

export const BookingTitle = styled(Typography.Text)`
  display: flex;
  line-height: 24px;
  font-weight: ${FONT_WEIGHT.bold};
  font-size: 16px;
  color: ${BASE_COLORS.black};
  margin-bottom: 0.3rem;
`;
export const TaxRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;
export const TaxInfoText = styled(Typography)`
  font-size: 14px;
  color: ${BASE_COLORS.opacityOne};
  font-weight: 400;
  line-height: 20px;
`;
export const ListTaxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const ListTaxRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const ListDiscountRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
`;
export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;
export const RemoveButton = styled.div`
  color: ${BASE_COLORS.red};
  font-size: ${FONT_SIZE.xs};
  cursor: pointer;
  display: flex;
  justify-content: end;
`;
export const OffPriceContainer = styled.div`
  display: flex;
  gap: 1rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`;
export const Price = styled(Typography)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
  color: ${BASE_COLORS.black};
`;

export const TaxInformationWapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;
export const TaxLeftWrapper = styled.div`
  display: flex;
  margin-top: 2px;
`;
export const VatCard = styled.div`
  border: 1px solid var(--border-color);
  width: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
`;
