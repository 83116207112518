import authInstance from '@app/api/authInstance';
import instance, {HOTEL_SERVICE, LOGIN_SERVICE} from '@app/api/instance';

interface IContactNumbers {
  contactNumbers: string;
}
export interface IHotelRequest {
  id?: number;
  name: string;
  title: string;
  address: string;
  contactNumber?: string;
  email?: string;
  type?: string;
  cardLogo: File;
  cardImage: File;
  sideBarImage: File;
  titleImage: File;
  groupId: number;
  contactNumbers?: IContactNumbers[];
  prefix?: string;
  propertyId?: string;
  vatApplicable: boolean;
  companyRegistrationNumber: string;
  companyRegistrationAddress: string;
  vatNumber?: string;
}

export interface IApiResponse {
  message: string;
  result: any;
  status: string;
  statusCode: string;
}

export const CreateHotelType = (hotelPayload: IHotelRequest): Promise<IApiResponse> => {
  const formData = new FormData();
  const obj = JSON.stringify({
    name: hotelPayload.name,
    title: hotelPayload.title,
    address: hotelPayload.address,
    contactNumber: hotelPayload.contactNumber,
    email: hotelPayload.email,
    type: hotelPayload.type,
    contactNumbersList: hotelPayload.contactNumbers,
    groupId: hotelPayload.groupId,
    bedsPropertyId: hotelPayload.propertyId,
    vatApplicable: hotelPayload.vatApplicable,
    companyRegistrationNumber: hotelPayload.companyRegistrationNumber,
    companyRegistrationAddress: hotelPayload.companyRegistrationAddress,
    vatNumber: hotelPayload.vatNumber,
  });
  formData.append('cardLogo', hotelPayload.cardLogo);
  formData.append('cardImage', hotelPayload.cardImage);
  formData.append('sideBarImage', hotelPayload.sideBarImage);
  formData.append('titleImage', hotelPayload.titleImage);

  formData.append('hotel', obj);

  return instance.post<IApiResponse>(HOTEL_SERVICE + 'hotel', formData).then(({data}) => data);
};

export const getAllHotels = (): Promise<IApiResponse> =>
  instance.get<IApiResponse>(HOTEL_SERVICE + 'hotels').then(({data}) => data);

export const UpdateHotel = (hotelPayload: IHotelRequest): Promise<IApiResponse> => {
  const formData = new FormData();
  const obj = JSON.stringify({
    id: hotelPayload.id,
    name: hotelPayload.name,
    title: hotelPayload.title,
    address: hotelPayload.address,
    contactNumber: hotelPayload.contactNumber,
    email: hotelPayload.email,
    type: hotelPayload.type,
    contactNumbersList: hotelPayload.contactNumbers,
    groupId: hotelPayload.groupId,
    prefix: hotelPayload.prefix,
    bedsPropertyId: hotelPayload.propertyId,
    vatApplicable: hotelPayload.vatApplicable,
    companyRegistrationNumber: hotelPayload.companyRegistrationNumber,
    companyRegistrationAddress: hotelPayload.companyRegistrationAddress,
    vatNumber: hotelPayload.vatNumber,
  });
  formData.append('cardLogo', hotelPayload.cardLogo);
  formData.append('cardImage', hotelPayload.cardImage);
  formData.append('sideBarImage', hotelPayload.sideBarImage);
  formData.append('titleImage', hotelPayload.titleImage);

  formData.append('hotel', obj);

  return instance.put<IApiResponse>(HOTEL_SERVICE + 'hotel', formData).then(({data}) => data);
};

export const DeleteHotel = (id: number): Promise<IApiResponse> =>
  instance.delete<IApiResponse>(HOTEL_SERVICE + `hotel/${id}`).then(({data}) => data);

export const getAllHotelsByGroupId = (groupId: number): Promise<IApiResponse> =>
  instance.get<IApiResponse>(HOTEL_SERVICE + `hotel/group/${groupId}`).then(({data}) => data);

export const getAllHotelsByUserAndGroupId = (groupId: number, userId: number | undefined): Promise<IApiResponse> =>
  authInstance.get<IApiResponse>(LOGIN_SERVICE + `api/v1/hotel/group/${groupId}/user/${userId}`).then(({data}) => data);

export const checkHotelIsVatAppilicable = (hotelId: number): Promise<IApiResponse> =>
  instance.get<IApiResponse>(HOTEL_SERVICE + `hotel/vat/${hotelId}`).then(({data}) => data);
