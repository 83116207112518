import inventoryInstance, {INVENTORY_SERVICE} from '@app/api/inventoryInstance';
import {HOUSE_KEEPING_SERVICE} from '@app/api/instance';
import houseKeepingInstance from '@app/api/houseKeepingInstance';

export const CreateLaundryItem = (payload: CreateRequestProps): Promise<RequestResponse> => {
  return houseKeepingInstance.post<RequestResponse>(HOUSE_KEEPING_SERVICE + 'trolley-request', payload).then(({data}) => data);
};

export const getAllLaundryItem = (hotelId: number, inventoryServiceId: number, name?: string): Promise<RequestResponse> =>
inventoryInstance.get<RequestResponse>(INVENTORY_SERVICE + `house-keeping-items?itemName=&hotelId=${hotelId}&inventoryServiceId=${inventoryServiceId}`).then(({data}) => data);



export interface CreateRequestProps {
  id: number;
  itemId: number;
  itemName: string;
  quantity: number;
  qty: number;
  key: number;
  amenityIds: [];
}

export interface UpdateStockProps {
  id: number;
  date: string;
  itemId: string;
  expireDate: string;
  quantity: number;
  hotelId: number;
  inventoryServiceId: number;
  locationId: number;
}

export interface RequestResponse {
  message: string;
  result: any;
  status: string;
  statusCode: string;
}

export interface FilterProps {
  itemName: string;
  unitName: string;
}
