import styled from 'styled-components';

export const FadeIn = styled.div`
  animation: fadeInAnimation 1s linear;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const ProductsContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
`;

export const TopBreadcrumb = styled.div`
  display: flex;
  justify-content: center;
`;
export const LeftBreadcrumb = styled.div`
  display: flex;
`;
export const PulseButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 9%;
  margin-top: 0.5rem;
`;
