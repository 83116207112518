import {httpApiMock} from '@app/api/mocks/http.api.mock';
import {AuthData} from '@app/api/auth.api';
import {initValues} from '@app/components/auth/LoginForm/LoginForm';

const avatarImg = process.env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar5.webp';

httpApiMock.onPost('login').reply(config => {
  const data: AuthData = JSON.parse(config.data || '');
  if (data.password === initValues.password) {
    return [
      200,
      {
        token: 'bearerToken',
        user: {
          id: 1,
          firstName: 'Group',
          lastName: 'Admin',
          nic: '980045852V',
          nationality: 'Sri Lankan',
          imgUrl: avatarImg,
          userName: '',
          email: {
            name: 'raviGroup23@gmail.com',
            verified: false,
          },
          phone: {
            number: '+94755517771',
            verified: true,
          },
          sex: 'male',
          birthday: '06/01/1998',
          lang: 'en',
          country: 'SL',
          city: 'Jaffna',
          address1: 'Nelliady, jaffna',
          zipcode: 40000,
          website: '',
          socials: {
            twitter: '',
            facebook: '',
            linkedin: '',
          },
        },
      },
    ];
  } else return [401, {message: 'Invalid Credentials'}];
});

httpApiMock.onPost('signUp').reply(200);

httpApiMock.onPost('forgotPassword').reply(200);

httpApiMock.onPost('verifySecurityCode').reply(200);

httpApiMock.onPost('setNewPassword').reply(200);
