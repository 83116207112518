import React from 'react';
import {Column, ColumnConfig} from '@ant-design/plots';
import {IReservationTypeGraph, IReservationTypeGraphData} from '../HotelDashboardPage';

interface IProps {
  reservationTypeData: IReservationTypeGraphData;
}
const ReservationTypeGraph: React.FC<IProps> = props => {
  const {reservationTypeData} = props;
  const [roomReservationStatisticsData, setRoomReservationStatisticsData] = React.useState<IReservationTypeGraph[]>([]);
  const [colorData, setColorData] = React.useState<string[]>([]);

  React.useEffect(() => {
    setColorData(reservationTypeData.colors);
    setRoomReservationStatisticsData(reservationTypeData.graphData);
  }, [reservationTypeData]);

  const config: ColumnConfig = {
    data: roomReservationStatisticsData,
    isGroup: true,
    xField: 'month',
    yField: 'typeBookedPercentage',
    seriesField: 'type',
    color: colorData,
    legend: {
      position: 'top',
    },
    yAxis: {
      title: {
        text: 'Reservation Type Percentage',
      },
      min: 0,
      max: 100,
      label: {
        formatter: (value: string) => `${value}`,
      },
      line: {
        style: {
          stroke: '#D2D3D4',
          lineWidth: 1.5,
        },
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
      title: {
        text: 'Months',
      },
    },
    label: {
      rotate: 30,
      position: 'middle',
      content: ({typeBookedPercentage}) => `${typeBookedPercentage === 0.0 ? '' : ''}`,
      style: {
        fontSize: 12,
        fontWeight: 700,
      },
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
    columnWidthRatio: 0.5,
  };

  return <Column {...config} />;
};

export default React.memo(ReservationTypeGraph);
