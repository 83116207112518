import React, {useEffect, useState} from 'react';
import {Breadcrumb} from 'antd';
import {HomeOutlined} from '@ant-design/icons';
import {useNavigate, useParams} from 'react-router-dom';
import MainContent from '../MainContent/MainContent';
import {MainHeader} from '../MainHeader/MainHeader';
import * as S from './MainLayout.styles';
import {Outlet, useLocation} from 'react-router-dom';
import {MEDICAL_DASHBOARD_PATH, NFT_DASHBOARD_PATH} from '@app/components/router/AppRouter';
import {useResponsive} from '@app/hooks/useResponsive';
import styled from 'styled-components';
import {ProductLevelHeader} from '@app/components/header/ProductLevelHeader';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {getCategoryStuctureByID} from '@app/api/resturant/tablecategory/categoryStucture.api';
import {resetMenuItem, setAllMenuItems, setCategoryData} from '@app/pages/Restaurant/slices/categoryNavSlice';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {setSelectedCategoryId} from '@app/pages/Restaurant/slices/waiterDasboardSlice';
import {Button} from '@app/components/common/buttons/Button/Button';
import {uniq, uniqBy} from 'lodash';

export const MainMenuBtn = styled(Button)`
  -webkit-box-shadow: 3px 3px 0px 0px rgb(133 135 159);
  -moz-box-shadow: 3px 3px 0px 0px rgb(133 135 159);
  box-shadow: 3px 3px 0px 0px rgb(133 135 159);
  border: none;
  font-weight: 500;
  -moz-box-shadow: 3px 3px 0px 0px rgb(133 135 159);
  /* border-radius: 13px; */
  margin: 0px 7px;
  color: white !important;
  font-size: 12px;
  padding: 7px 10px;
  min-width: 82px;
  text-align: center;
  color: white;
  background: #85615e;
  -moz-box-shadow: 3px 3px 0px 0px rgb(133 135 159);
  cursor: pointer;

  &:hover {
    background: #bfa19a;
  }
`;

const TopLevelLayout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const menuItemsList = useAppSelector(state => state.categoryNav.menuItems);
  const {cat} = useParams();
  const category = cat?.split(':');
  const realCat = category && category[1];
  const [form] = BaseForm.useForm();
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);

  // eslint-disable-next-line prefer-const
  let [isFromCard] = useState(false);

  const [searchedFoods, setSearchedFoods] = useState<any[]>([]);
  const [firstCatLayer, setfirstCatLayer] = useState<any[]>([]);
  const [secondCatLayer, setSecondCatLayer] = useState<any[]>([]);
  const [firstCatLayerSelected, setfirstCatLayerSelected] = useState<any>({});
  const [secondCatLayerSelected, setSecondCatLayerSelected] = useState<any>({});
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const {isDesktop} = useResponsive();
  const location = useLocation();

  const hotelServiceConfig = useAppSelector(state => state.hotelSlice.hotelServiceConfig);

  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  useEffect(() => {
    setfirstCatLayerSelected(menuItemsList[0]);
    setSecondCatLayerSelected(menuItemsList[1]);
    setIsTwoColumnsLayout([MEDICAL_DASHBOARD_PATH, NFT_DASHBOARD_PATH].includes(location.pathname) && isDesktop);

    // getCategoryById(0, 1);

    if (menuItemsList.length > 0) {
      // getSecondLayer(menuItemsList[0].id);
    }
  }, [location.pathname, isDesktop]);

  const Content = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    width: 90%;
    margin: auto;
  `;

  const getCategoryById = async (id: number, layerIndex?: number, index?: number) => {
    const result = await getCategoryStuctureByID(id, hotelServiceConfig.serviceId);

    const data: any[] = [];

    result.result.category &&
      result.result.category.map((post: any, i: any) => {
        data.push({
          id: post.id,
          backgroundImage: post.categoryImage,
          title: post.categoryTitle,
          children: [],
          expanded: post.expanded,
          categoryId: post.categoryId,
        });
      });

    if (layerIndex === 1) {
      setfirstCatLayer(data);
    } else if (layerIndex === 2) {
    } else {
      dispatch(setCategoryData({items: data}));
    }
  };

  const onClickBreadcrumbHandler = (data: any, index: any) => {
    getCategoryById(data.id);
    dispatch(setSelectedCategoryId({selectedCategoryId: data.id}));
    navigate('/category/main');

    const newArr = [...menuItemsList];
    newArr?.splice(index + 1);

    dispatch(setAllMenuItems({items: uniqBy(newArr, 'id')}));
  };

  const ContentWithoutSwipe = () => {
    return (
      <div>
        <S.BreadcrumbMenuWrap>
          <div>
            {location.pathname === '/toplevel/products' ? (
              <></>
            ) : location.pathname === '/toplevel/groups' ? (
              <></>
            ) : location.pathname === '/toplevel/services' ? (
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate('/toplevel/products')}></Breadcrumb.Item>
              </Breadcrumb>
            ) : location.pathname === '/toplevel/inventory-services' ? (
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate('/toplevel/products')}>
                  <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate('/toplevel/services')}>
                  Palmyrah House Services
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate('/toplevel/services')}>Inventory</Breadcrumb.Item>
              </Breadcrumb>
            ) : location.pathname === '/category/main' ? (
              <Breadcrumb>
                <Breadcrumb.Item
                  onClick={() => {
                    dispatch(resetMenuItem({}));

                    navigate('/toplevel/services');
                  }}>
                  HOME
                </Breadcrumb.Item>

                <Breadcrumb.Item
                  onClick={() => {
                    navigate('/category/main');
                    getCategoryById(0);
                    dispatch(setAllMenuItems({items: []}));
                  }}>
                  FOODS
                </Breadcrumb.Item>
                {!realCat &&
                  uniq(menuItemsList)?.map((post: any, index: any) => {
                    return (
                      <Breadcrumb.Item key={index} onClick={() => onClickBreadcrumbHandler(post, index)}>
                        {post.title}
                      </Breadcrumb.Item>
                    );
                  })}
              </Breadcrumb>
            ) : location.pathname === '/toplevel/chef-dashboard' ? (
              <Breadcrumb></Breadcrumb>
            ) : (
              <div></div>
            )}
          </div>
          {/* <S.CategoryFilterArea>
            {realCat ? (
              <div>
                <S.CategoryFilterCardWrap>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      marginBottom: '0.4rem',
                    }}>
                    {firstCatLayer.map((post: any, index) => {
                      return (
                        <CatFilterCards
                          background={post.id === firstCatLayerSelected?.id ? '#0014ff' : '#585858'}
                          onClick={() => {
                            isFromCard = true;
                            setfirstCatLayerSelected(post);
                            if (post.expanded) {
                              getSecondLayer(post.id);
                              post?.children.length > 0 && dispatch(setCategoryStatus({status: false}));
                              dispatch(setCategoryData({items: []}));
                              dispatch(addFoodItems({foodItems: []}));
                            } else {
                              localStorage.setItem('categoryId', post.id.toString());
                              dispatch(setCategoryStatus({status: false}));
                              getAllFoodItemsByCategory(post.id);
                            }
                          }}
                          backgroundImage={post.backgroundImage}
                          key={index}
                          title={post.title}
                        />
                      );
                    })}
                  </div>
                </S.CategoryFilterCardWrap>
                <S.CategoryFilterCardWrap>
                  {secondCatLayer.map((post: any, index) => {
                    return (
                      <CatFilterCards
                        background={post.id === secondCatLayerSelected?.id ? '#0014ff' : '#585858'}
                        onClick={() => {
                          setSecondCatLayerSelected(post);
                          isFromCard = true;

                          if (post.expanded) {
                            dispatch(setCategoryStatus({status: true}));
                            dispatch(setCategoryData({items: post.children}));
                            getCategoryById(post.id);
                          } else {
                            localStorage.setItem('categoryId', post.id.toString());
                            getAllFoodItemsByCategory(post.id);
                            dispatch(setCategoryStatus({status: false}));
                          }
                        }}
                        backgroundImage={post.backgroundImage}
                        key={index}
                        title={post.title}
                      />
                    );
                  })}
                </S.CategoryFilterCardWrap>
              </div>
            ) : (
              <></>
            )}
          </S.CategoryFilterArea>
          {realCat && MemorizedInput} */}
        </S.BreadcrumbMenuWrap>
      </div>
    );
  };
  return (
    <S.LayoutMaster>
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <ProductLevelHeader
            toggleSider={toggleSider}
            isSiderOpened={!siderCollapsed}
            isTwoColumnsLayout={isTwoColumnsLayout}
          />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <ContentWithoutSwipe />
          <Content
            style={{
              width: '100%',
            }}>
            <Outlet />
          </Content>
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default React.memo(TopLevelLayout);
