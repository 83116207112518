import userReducer from '@app/store/slices/userSlice';
import authReducer from '@app/store/slices/authSlice';
import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import fcsReducer from '@app/store/slices/foodCatSlice';
import hotelSlice from '@app/store/slices/hotelSlice';
import commonSlice from '@app/store/slices/commonSlice';
import reservationSlice from '@app/store/slices/reservationSlice';
import commonApiSlice from '@app/store/slices/commonApiSlice';
import serviceSlice from '@app/store/slices/serviceSlice';
import categoryNavSlice from '@app/pages/Restaurant/slices/categoryNavSlice';
import waiterDasboardSlice from '@app/pages/Restaurant/slices/waiterDasboardSlice';
import houseKeeping from './houseKeeping';
import webServiceSlice from './webServiceSlice';
import inventorySlice from './inventorySlice';
import notificationSlice from './notificationSlice';

export default {
  user: userReducer,
  auth: authReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  fcs: fcsReducer,
  hotelSlice: hotelSlice,
  commonSlice: commonSlice,
  reservationSlice: reservationSlice,
  commonApiSlice: commonApiSlice,
  serviceSlice: serviceSlice,
  categoryNav: categoryNavSlice,
  waiterDasbordSlice: waiterDasboardSlice,
  houseKeepingSlice: houseKeeping,
  webServiceSlice: webServiceSlice,
  inventorySlice: inventorySlice,
  notificationSlice: notificationSlice,
};
