import authInstance from '@app/api/authInstance';
import {LOGIN_SERVICE} from '@app/api/instance';

export const getAllRolePermissionByServiceType = (serviceType: string): Promise<RolePrivilegeResponse> =>
  authInstance
    .get<RolePrivilegeResponse>(LOGIN_SERVICE + `api/v1/permission/serviceType/${serviceType}`)
    .then(({data}) => data);

export const getAllRolePermissionByServiceId = (serviceId: number): Promise<RolePrivilegeResponse> =>
  authInstance
    .get<RolePrivilegeResponse>(LOGIN_SERVICE + `api/v1/services-permission/service/${serviceId}`)
    .then(({data}) => data);

export const getAllRolePermissionByRoleId = (roleId: number): Promise<RolePrivilegeResponse> =>
  authInstance
    .get<RolePrivilegeResponse>(LOGIN_SERVICE + `api/v1/service-role-services-permission/service-role/${roleId}`)
    .then(({data}) => data);

export const rolePermissionUpdate = (payload: RolePrivilegePayload): Promise<RolePrivilegeResponse> =>
  authInstance
    .put<RolePrivilegeResponse>(LOGIN_SERVICE + `api/v1/service-role-services-permission`, payload)
    .then(({data}) => data);

export const getAllServicePermissionByUserId = (userId: number, serviceId: number): Promise<RolePrivilegeResponse> =>
  authInstance
    .get<RolePrivilegeResponse>(
      LOGIN_SERVICE + `api/v1/service-role-services-permission/user/${userId}/service/${serviceId}`,
    )
    .then(({data}) => data);
export const getUserPermit = (userId: number): Promise<RolePrivilegeResponse> =>
  authInstance.get<RolePrivilegeResponse>(LOGIN_SERVICE + `api/v1/user/level/${userId}`).then(({data}) => data);

export const getAllPermissionByUserId = (userId: number): Promise<RolePrivilegeResponse> =>
  authInstance
    .get<RolePrivilegeResponse>(LOGIN_SERVICE + `api/v1/users/all-permission/${userId}`)
    .then(({data}) => data);

export interface RolePrivilegeResponse {
  message: string;
  result: any;
  status: string;
  statusCode: string;
}

export interface RolePrivilegePayload {
  id: any;
  status: string;
  serviceRoleId: number;
  servicePermissionId: number;
}
