import {Input} from '@app/components/common/inputs/Input/Input';
import {Select} from '@app/components/common/selects/Select/Select';
import {BASE_COLORS, BORDER_RADIUS, FONT_SIZE, FONT_WEIGHT} from '@app/styles/themes/constants';
import {Typography} from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  
`;

export const BlueCard = styled.div`
  border: 1px solid var(--border-color);
  width: 100%;
  border-radius: 8px;
  background-color: ${BASE_COLORS.lightBlue};
`;

export const QuantityCenter = styled.div`
  display: flex;
  width: 20px;
  max-width: 50px;
  justify-content: center;
  align-items: center;
`;

export const BlurCardWrapper = styled.div`
  display: flex;
  margin-top: 0rem;
  flex-direction: column;
  width: 100%;
  padding-right: 2rem;
  padding-left: 2rem;
`;
export const Padding = styled.div`
  padding: 0rem;
  display: flow-root;
`;
export const CardTitle = styled(Typography.Text)`
  display: flex;
  line-height: 1.25rem;
  font-size: ${FONT_SIZE.lg};
  color: ${BASE_COLORS.black};
  font-weight: ${FONT_WEIGHT.bold};
  margin-bottom: 1rem;
`;
export const TopContainer = styled.div`
  display: flex;
`;
export const PurchaseOrderWrapper = styled.div`
  display: contents;
`;
export const PurchaseOrderButtom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 2rem;
`;
export const ButtonWrapper = styled.div`
  display: grid;
  gap: 1rem;
  justify-content: end;
  align-items: center;
`;

export const TableButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1rem;
  
`;

export const ViewTag = styled.div`
  background-color: ${BASE_COLORS.cancelRed};
  font-size: ${FONT_SIZE.xxs};
  color: ${BASE_COLORS.white};
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  cursor: pointer;
`;

export const ViewTagWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomInput = styled(Input)`
  height: 34px;
`;
export const CustomSelectPicker = styled(Select)`
  font-weight: ${FONT_WEIGHT.medium};
  height: 34px;
  box-shadow: ${props => props.shadow && 'var(--box-shadow)'};

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 34px;
  }

  &.ant-select-borderless {
    background: var(--secondary-background-color);

    border-radius: ${BORDER_RADIUS};
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #e0e8ef;
  }
  &.ant-select-disabled:not(.ant-select-customize-input) {
    .ant-select-selector {
      color: red;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item,
    .ant-select-arrow {
      color: ${BASE_COLORS.black};
    }
  }
  .ant-select-selection-placeholder {
    font-size: ${FONT_SIZE.xs};
    color: var(--text-main-color);
  }

  .ant-select-arrow {
    color: var(--text-main-color);
  }
  .jogFpf .ant-input-group-addon .ant-select .ant-select-selection-item {
    min-width: 5.5rem;
    color: var(--primary-color);
    font-weight: 600;
    font-size: ${FONT_SIZE.xs};
  }

  &.ant-select-multiple.ant-select-sm .ant-select-selection-item {
    height: 1.3rem;
    line-height: ${FONT_SIZE.xs};
    font-size: ${FONT_SIZE.xs};
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;
  }
  &.duDJPZ.ant-select-multiple.ant-select-sm .ant-select-selection-item {
    height: 1.25rem;
    line-height: 0.875rem;
    font-size: 0.875rem;
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: var(--disabled-color);
  }

  .ant-select-clear {
    color: var(--disabled-color);
  }
  .ant-select-selection-item-remove {
    color: var(--icon-color);
    &:hover {
      color: var(--icon-hover-color);
    }
  }
  .ant-select-item-option-disabled {
    color: var(--disabled-color);
  }

  &.ant-select-multiple.ant-select-sm .ant-select-selection-item {
    height: 1.2rem;
    line-height: 0.875rem;
    font-size: 0.875rem;
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top:1rem;
  overflow-y:scroll;
  max-height:200px;

`;




export const StyleTableHead = styled.div`
  display: flex;  
`;

export const StyleTabledata = styled.div`
   overflow-y:scroll;
   max-height:500px;
`;


export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.5rem;

  thead {
    position: sticky;
    top: 0;
    background-color: white; 
    z-index: 1; 
  }
  
  tbody{
    max-height: 500px; 
    overflow-y: auto;
  }
  
`;

export const TableHeader = styled.th`
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #ddd;
`;

export const TableData = styled.td`
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  width: 21rem;
  max-width: 25rem;
`;

export const QuantityCell = styled.td`
  padding: 5px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}
`;

export const QuantityButton = styled.button`
  border: none;
  background-color: initial;
  padding: 4px 8px;
  cursor: pointer;
  text-align: center;
`;

export const DropdownWrapper = styled.div`
  margin-bottom: 1rem;
`;
export const EmptyFooter = styled.div`
  height: 0rem;
`;

export const StayTitle = styled.div`
  display: flex;
  line-height: 1.25rem;
  font-size: ${FONT_SIZE.xs};
  color: ${BASE_COLORS.black};
  // font-weight: ${FONT_WEIGHT.bold};
  margin-top: 0.25rem;
  gap: 0.3rem;
  // align-items: center;
  // justify-content: center;
`;

export const TitleIconWrapper = styled.div`
  display: flex;
  gap: 1px;
  align-items: center;
  justify-content: center;
`;

export const TopBreadcrumb = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;
export const PulseButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 25%;
  margin-top: 0.5rem;
`;
export const LeftBreadcrumb = styled.div`
  display: flex;
`;
