import axios from 'axios';
import {AxiosError} from 'axios';
import {ApiError} from '@app/api/ApiError';
import {readToken} from '@app/services/localStorage.service';
import {API_KEY_ENV} from './instance';

export const httpApi = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? API_KEY_ENV
      : process.env.REACT_APP_BASE_URL + '/restaurant-service/api/v1/',
});

httpApi.interceptors.request.use(config => {
  config.headers = {...config.headers};
  return config;
});

httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
  throw new ApiError<ApiErrorData>(error?.message);
});

export interface ApiErrorData {
  message: string;
}
