import {BREAK_FAST, DINNER, LUNCH} from '@app/assets';
import {TopLevelCardProps} from './Interfaces';

export const ProductData: TopLevelCardProps[] = [
  {
    name: 'Hoppers',
    origin: 'SRI LANKAN',
    time: 'BREAK FAST',
    bgUrl: 'https://www.australianeggs.org.au/assets/Uploads/AusEggs_WED2020-x-Adam-Liaw_Egg-hoppers2_lores.jpg',
    logo: BREAK_FAST,
  },
  {
    name: 'Roti',
    origin: 'SRI LANKAN',
    time: 'BREAK FAST',
    bgUrl: 'https://www.spiceupthecurry.com/wp-content/uploads/2022/05/phulka-roti-recipe-1-500x375.jpg',
    logo: BREAK_FAST,
  },
  {
    name: 'Bun',
    origin: 'SRI LANKAN',
    time: 'BREAK FAST',
    bgUrl:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/1/10/Sesame_seed_hamburger_buns.jpg/1200px-Sesame_seed_hamburger_buns.jpg',
    logo: BREAK_FAST,
  },
  {
    name: 'Pancakes',
    origin: 'WESTERN',
    time: 'BREAK FAST',
    logo: DINNER,
    bgUrl:
      'https://assets.bonappetit.com/photos/622a6c4f7d1e521b467c3d0b/1:1/w_2360,h_2360,c_limit/20220307%20A%20SKEPTIC%20TRIES%20PANCAKES%20LEDE.jpg',
  },
  {
    name: 'OMELETTE',
    origin: 'WESTERN',
    time: 'BREAK FAST',
    logo: DINNER,
    bgUrl:
      'https://images.eatsmarter.com/sites/default/files/styles/max_size/public/spinach-and-mushroom-omelet-with-toast-580742.jpg',
  },
  {
    name: 'Cereal with Milk',
    origin: 'WESTERN',
    time: 'BREAK FAST',
    logo: DINNER,
    bgUrl:
      'https://npr.brightspotcdn.com/dims4/default/8a456cf/2147483647/strip/true/crop/1000x667+0+0/resize/880x587!/quality/90/?url=http%3A%2F%2Fnpr-brightspot.s3.amazonaws.com%2Flegacy%2Fsites%2Fkera%2Ffiles%2F201811%2Fcereal___milk.jpg',
  },
  {
    name: 'Dosa',
    origin: 'INDIAN',
    time: 'BREAK FAST',
    logo: BREAK_FAST,
    bgUrl: 'https://madhurasrecipe.com/wp-content/uploads/2022/01/instant_dosa_featured.jpg',
  },
  {
    name: 'Poha',
    origin: 'INDIAN',
    time: 'BREAK FAST',
    logo: BREAK_FAST,
    bgUrl: 'https://www.indianveggiedelight.com/wp-content/uploads/2022/08/poha-chivda-featured.jpg',
  },
  {
    name: 'Upma',
    origin: 'INDIAN',
    time: 'BREAK FAST',
    logo: BREAK_FAST,
    bgUrl: 'https://myfoodstory.com/wp-content/uploads/2022/11/Vegetable-Upma-3-500x375.jpg',
  },

  {
    name: 'RICE & CURRY',
    logo: LUNCH,
    bgUrl: 'https://srilankaholidayguru.com/wp-content/uploads/2020/02/Sri-Lankan-Rice-and-Curry.jpg',
    origin: 'SRI LANKAN',
    time: 'LUNCH',
  },
  {
    name: 'Fried Rice',
    logo: LUNCH,
    bgUrl: 'https://static.toiimg.com/thumb/75581339.cms?width=1200&height=900',
    origin: 'SRI LANKAN',
    time: 'LUNCH',
  },
  {
    name: 'Kottu Rotti',
    logo: LUNCH,
    bgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a3/Chicken_Kottu.jpg/640px-Chicken_Kottu.jpg',
    origin: 'SRI LANKAN',
    time: 'LUNCH',
  },

  {
    name: 'Fried Rice',
    logo: LUNCH,
    bgUrl: 'https://static.toiimg.com/thumb/75581339.cms?width=1200&height=900',
    origin: 'SRI LANKAN',
    time: 'DINNER',
  },
  {
    name: 'Kottu Rotti',
    logo: LUNCH,
    bgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a3/Chicken_Kottu.jpg/640px-Chicken_Kottu.jpg',
    origin: 'SRI LANKAN',
    time: 'DINNER',
  },

  {
    name: 'PUTTU',
    logo: LUNCH,
    bgUrl: 'https://www.vegrecipesofindia.com/wp-content/uploads/2017/11/puttu-recipe-1a.jpg',
    origin: 'SRI LANKAN',
    time: 'DINNER',
  },

  {
    name: 'Steak with Mashed Potatoes',
    logo: DINNER,
    bgUrl:
      'https://img.taste.com.au/V1OgyjfW/taste/2020/08/september20_mustard-steak-with-corn-and-potato-mash-taste-164289-1.jpg',
    origin: 'WESTERN',
    time: 'DINNER',
  },
  {
    name: 'Spaghetti Bolognese',
    logo: DINNER,
    bgUrl: 'https://www.countdown.co.nz/Content/Recipes/230232-Classic-SpagBol_810x520.jpg',
    origin: 'WESTERN',
    time: 'DINNER',
  },
  {
    name: 'Roasted Chicken with Vegetables',
    logo: DINNER,
    bgUrl: 'https://www.spendwithpennies.com/wp-content/uploads/2018/11/SpendWithPennies-Juicy-Roast-Chicken-25.jpg',
    origin: 'WESTERN',
    time: 'DINNER',
  },

  {
    name: 'Parotta',
    logo: DINNER,
    bgUrl:
      'https://c.ndtvimg.com/2021-05/tj7sdqj8_parotta_625x300_14_May_21.jpg?im=FeatureCrop,algorithm=dnn,width=1200,height=675',
    origin: 'INDIAN',
    time: 'DINNER',
  },
  {
    name: 'Biryani',
    logo: DINNER,
    bgUrl: 'https://bonmasala.com/wp-content/uploads/2022/10/mutton-biriyani-recipe.jpeg',
    origin: 'INDIAN',
    time: 'DINNER',
  },

  {
    name: 'Salad',
    logo: LUNCH,
    bgUrl:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/2/23/Caesar_salad_%282%29.jpg/800px-Caesar_salad_%282%29.jpg',
    origin: 'WESTERN',
    time: 'LUNCH',
  },
  {
    name: 'Sandwich',
    logo: LUNCH,
    bgUrl: 'https://natashaskitchen.com/wp-content/uploads/2021/08/Grilled-Cheese-Sandwich-SQ.jpg',
    origin: 'WESTERN',
    time: 'LUNCH',
  },

  {
    name: 'Butter Chicken',
    logo: DINNER,
    bgUrl: 'https://www.cookingclassy.com/wp-content/uploads/2021/01/butter-chicken-4.jpg',
    origin: 'INDIAN',
    time: 'DINNER',
  },
  {
    name: 'Palak Paneer',
    logo: DINNER,
    bgUrl: 'https://healthynibblesandbits.com/wp-content/uploads/2020/01/Saag-Paneer-FF.jpg',
    origin: 'INDIAN',
    time: 'LUNCH',
  },
  {
    name: 'Biryani',
    logo: DINNER,
    bgUrl: 'https://upload.wikimedia.org/wikipedia/commons/5/5a/%22Hyderabadi_Dum_Biryani%22.jpg',
    origin: 'INDIAN',
    time: 'LUNCH',
  },
];
