/* eslint-disable @typescript-eslint/no-explicit-any */
import {getAllCountries} from '@app/api/hotel/channel.api';
import hotels from '@app/pages/Hotel/WebsiteService/constants';
import {filter} from 'lodash';

const getCountries = async (name?: string) => {
  try {
    const data = await getAllCountries(name);
    if (data?.result?.country) {
      const dataList = data.result.country.map(({name, id}: {name: string; id: number}) => ({
        title: name,
        value: id,
      }));
      return dataList;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const validateEmail = (_: any, value: string) => {
  if (!value) {
    return Promise.reject('Required field');
  } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)) {
    return Promise.reject('Invalid email');
  }
  return Promise.resolve();
};

const validateEmailWithoutRequired = (_: any, value: string) => {
  if (!value) {
    return Promise.resolve();
  } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)) {
    return Promise.reject('Invalid email');
  }
  return Promise.resolve();
};

const calculateTotalTaxAmount = (totalAmount: number, taxList: any[]) => {
  let serviceTaxAmount = 0;
  let otherTaxesAmount = 0;

  const activeTaxes = taxList?.filter((tax: {active: boolean}) => tax?.active);

  const serviceChargeTax = activeTaxes?.find((tax: {serviceCharge: boolean}) => tax?.serviceCharge);
  if (serviceChargeTax) {
    serviceTaxAmount = totalAmount * (serviceChargeTax.rate / 100);
  }
  const remainingAmount = totalAmount + serviceTaxAmount;
  const otherTaxes = activeTaxes?.filter((tax: {serviceCharge: boolean}) => !tax.serviceCharge);
  const otherTaxesArray = otherTaxes?.map((tax: {rate: number; name: string}) => {
    const taxAmount = remainingAmount * (tax.rate / 100);
    otherTaxesAmount += taxAmount;

    return {
      name: tax?.name,
      rate: tax?.rate,
      taxAmount: taxAmount,
    };
  });
  const totalTaxAmount = serviceTaxAmount + otherTaxesAmount;

  return {
    totalTaxAmount,
    taxArray: [
      {
        name: serviceChargeTax?.name,
        rate: serviceChargeTax?.rate,
        taxAmount: serviceTaxAmount,
      },
      ...otherTaxesArray,
    ],
  };
};

function isPositiveNumber(value: number) {
  return value >= 0;
}

function navigateToHotelWebsite(hotelId: any) {
  let hotel;
  switch (hotelId) {
    case 1:
      hotel = hotels.PALMYRAH_HOUSE;
      break;
    case 2:
      hotel = hotels.MIDDLE_STREET;
      break;
    case 3:
      hotel = hotels.VILLA_THUYA;
      break;
    case 4:
      hotel = hotels.LILY_POD;
      break;
    case 5:
      hotel = hotels.DENDROBIUM_HOUSE;
      break;
    default:
      return;
  }
  window.location.href = hotel.webUrl;
}

const getSsclValue = (rate: number) => {
  const value = (rate / (100 - rate)) * 100;
  return value;
};

const calculateTotalTaxWithSequence = (subTotal: number, taxList: any[], vatApplicable: boolean) => {
  let totalAmount = subTotal;
  let totalTaxAmount = 0;

  const updatedTaxList =
    taxList &&
    taxList.map(tax => {
      return {
        ...tax,
        updatedRate: tax.socialSecurityContributionLevy ? getSsclValue(tax.rate) : tax.rate,
        rate: tax.socialSecurityContributionLevy ? getSsclValue(tax.rate) : tax.rate,
        originRate: tax.rate,
      };
    });

  const taxResponseList = vatApplicable ? updatedTaxList : filter(updatedTaxList, tax => !tax.vat);

  const sequenceTaxRates: any = {};

  taxResponseList?.forEach((tax: any) => {
    if (!sequenceTaxRates.hasOwnProperty(tax.sequence)) {
      sequenceTaxRates[tax.sequence] = 0;
    }
    sequenceTaxRates[tax.sequence] += tax.updatedRate;
  });

  const taxArray: any[] = [];
  Object.keys(sequenceTaxRates).forEach(sequence => {
    const sequenceRate = sequenceTaxRates[sequence];
    const taxAmount = (totalAmount * sequenceRate) / 100;
    const sequenceTaxes = taxResponseList.filter((tax: any) => tax.sequence == sequence);
    sequenceTaxes.forEach((tax: any) => {
      taxArray.push({
        id: tax.id,
        amount: (totalAmount * tax.rate) / 100,
        taxAmount: (totalAmount * tax.updatedRate) / 100,
        invoiceId: tax.invoiceId,
        name: tax.name,
        rate: tax.rate,
        updatedRate: tax.updatedRate,
        originRate: tax.originRate,
      });
      totalTaxAmount += (totalAmount * tax.updatedRate) / 100;
    });
    totalAmount += taxAmount;
  });

  return {
    taxArray,
    totalAmount,
    totalTaxAmount,
  };
};

const checkVATAvailability = (taxList: any) => {
  const vatTax = taxList && taxList.find((tax: {vat: boolean}) => tax.vat === true);
  if (vatTax) {
    return vatTax.active;
  }
  return false;
};

export function roundNumber(number: number, decimals: number) {
  const factor = Math.pow(10, decimals);
  return Math.round(number * factor) / factor;
}

export {
  getCountries,
  validateEmail,
  calculateTotalTaxAmount,
  isPositiveNumber,
  navigateToHotelWebsite,
  validateEmailWithoutRequired,
  calculateTotalTaxWithSequence,
  checkVATAvailability,
};
