import instance, {HOTEL_SERVICE} from '@app/api/instance';

export const createChannel = (payload: CreateChannelProps): Promise<ChannelResponse> => {
  return instance.post<ChannelResponse>(HOTEL_SERVICE + 'channel', payload).then(({data}) => data);
};

export const getAllChannels = (
  {code, name, countryName, channelTypeName}: any,
  pageSize: number | undefined,
  current: number | undefined,
  groupId: number,
  hotelId: number,
  statusType: string,
): Promise<ChannelResponse> =>
  instance
    .get<ChannelResponse>(
      HOTEL_SERVICE +
        `channel/search?page=${current}&size=${pageSize}&sortField=id&direction=DESC&code=${code ? code : ''}&name=${
          name ? name : ''
        }&country=${countryName ? countryName : ''}&channelTypeName=${
          channelTypeName ? channelTypeName : ''
        }&groupId=${groupId}&hotelId=${hotelId}&channelStatus=${statusType}`,
    )
    .then(({data}) => data);

export const searchChannels = (channelName?: string, hotelId?: number): Promise<ChannelResponse> =>
  instance
    .get<ChannelResponse>(
      HOTEL_SERVICE + `channel/search-only?name=${channelName ? channelName : ''}&hotelId=${hotelId}`,
    )
    .then(({data}) => data);

export const getAllOnlineChannels = (): Promise<ChannelResponse> =>
  instance.get<ChannelResponse>(HOTEL_SERVICE + 'online-channel/search?name=&url').then(({data}) => data);

export const getAllCurrencies = (): Promise<ChannelResponse> =>
  instance.get<ChannelResponse>(HOTEL_SERVICE + 'currency/search?name=&prefix&symbol').then(({data}) => data);

export const getAllCountries = (name?: string): Promise<ChannelResponse> =>
  instance.get<ChannelResponse>(HOTEL_SERVICE + `country/search?name=${name ? name : ''}`).then(({data}) => data);

export const updateChannel = (payload: UpdateChannelProps): Promise<ChannelResponse> => {
  return instance.put<ChannelResponse>(HOTEL_SERVICE + 'channel', payload).then(({data}) => data);
};

export const deleteChannel = (id: number): Promise<ChannelResponse> =>
  instance.delete<ChannelResponse>(HOTEL_SERVICE + `channel/${id}`).then(({data}) => data);

export const acceptChannel = (id: number, hotelId: number, actionStatus: boolean): Promise<ChannelResponse> =>
  instance
    .put<ChannelResponse>(HOTEL_SERVICE + `channel/hotel/${hotelId}/channel/${id}?acceptChannel=${actionStatus}`)
    .then(({data}) => data);

export const getChannelsByReservationTypeId = (
  id: number,
  groupId: number,
  hotelId: number,
): Promise<ChannelResponse> =>
  instance
    .get<ChannelResponse>(
      HOTEL_SERVICE +
        `channel-by-reservation-type/${id}
  `,
    )
    .then(({data}) => data);

export const getChannelsSearchWithoutPagi = (
  id: number,
  searchObj: {name: string},
  groupId: number,
  hotelId: number,
): Promise<ChannelResponse> =>
  instance
    .get<ChannelResponse>(
      HOTEL_SERVICE +
        `channels-by-reservation-type/${id}?name=${searchObj.name ? searchObj.name : ''}&hotelId=${hotelId}`,
    )
    .then(({data}) => data);

export interface CreateChannelProps {
  address?: string;
  agentMails?: string[];
  allowCredit?: boolean;
  channelRate?: string;
  code: string;
  contactPerson: string;
  countryId?: number;
  creditPrice?: string;
  currencyId?: number;
  email: string;
  name: string;
  // notifyColour: string;
  // onlineChannelId?: number;
  phone: string;
  remarks: string;
  taxIncludeWithPrice: boolean;
  taxRate: string;
  tourOperator: boolean;
  vatNo: string;
  channelTypeId: number;
  prefix: string;
  hotelIds: number[];
  groupId: number;
  hotelId: number;
}
export interface UpdateChannelProps {
  id: number;
  address?: string;
  agentMails?: string[];
  allowCredit?: boolean;
  channelRate?: string;
  code: string;
  contactPerson: string;
  countryId?: number;
  creditPrice?: string;
  currencyId?: number;
  email: string;
  name: string;
  // notifyColour: string;
  // onlineChannelId?: number;
  phone: string;
  remarks: string;
  taxIncludeWithPrice: boolean;
  taxRate: string;
  tourOperator: boolean;
  vatNo: string;
  channelTypeId: number;
  prefix: string;
  hotelIds: number[];
  groupId: number;
  hotelId: number;
}
export interface ChannelResponse {
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
  status: string;
  statusCode: string;
}

export interface FilterProps {
  code: string;
  name: string;
  countryName: string;
  channelTypeName: string;
}
