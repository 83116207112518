import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {ICurrencyRates, IHotelId} from './interface';
import {getAllCurrency} from '@app/api/hotel/currencyExchangeRates/currencyExchange.api';
import {getAllChildPolicies} from '@app/api/hotel/ChildPolicy/childPolicy.api';
import {getAllRoomTypesForDropDown} from '@app/api/hotel/roomType.api';
import {getAllPaxes} from '@app/api/hotel/Pax/pax.api';
import {getAllAdultsRoomId} from '@app/api/resturant/tablecategory/stayType.api';
import {getAllLocationsForDropDown} from '@app/api/inventory/location.api';

export interface ICommonApi {
  currencyRates: ICurrencyRates[];
}

const initialState: ICommonApi = {
  currencyRates: [],
};

export const getCurrency = createAsyncThunk(
  'commonApiSlice/getCurrency',
  async (payload: ICurrencyRates, {dispatch}) => {
    const results = await getAllCurrency(payload);
    return results.result.currency;
  },
);

export const getAgeRange = createAsyncThunk('commonApiSlice/getAgeRange', async () => {
  const results = await getAllChildPolicies();
  return results.result.childPolicies;
});

export const getRoomTypes = createAsyncThunk('commonApiSlice/getRoomTypes', async (payload: IHotelId, {dispatch}) => {
  const results = await getAllRoomTypesForDropDown(payload.hotelId);
  return results.result.roomType;
});

export const getPacks = createAsyncThunk('commonApiSlice/getPacks', async (payload: IHotelId, {dispatch}) => {
  const results = await getAllPaxes(payload.hotelId);
  return results.result.pack;
});

export const getLocations = createAsyncThunk('commonApiSlice/getLocations', async () => {
  const results = await getAllLocationsForDropDown();
  return results.result.location;
});

export const getAdultsByRoom = createAsyncThunk(
  'commonApiSlice/getAdultsByRoom',
  async (payload: {roomId: number}, {dispatch}) => {
    const results = await getAllAdultsRoomId(payload.roomId);

    return results.room.adultCount;
  },
);

export const commonApiSlice = createSlice({
  initialState: initialState,
  name: 'commonApiSlice',
  reducers: {
    setCurrency: (state, action: PayloadAction<ICurrencyRates[]>) => {
      state.currencyRates = action.payload;
    },
  },
});

export const {setCurrency} = commonApiSlice.actions;
export default commonApiSlice.reducer;
