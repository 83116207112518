import {IServicesBookinsData} from '../interface';

export interface ITaxList {
  taxId: number;
  taxName: string;
  amount: number;
  rate: number;
  serviceCharge: boolean;
  vat: boolean;
  socialSecurityContributionLevy: boolean;
  sequence: number;
  name: string | null;
}
export interface ConvertedDataProps {
  advancePaid: null;
  ownerName: string;
  refNumber: string;
  resident: any;
  paidAmount: any;
  totalCharge: number;
  totalPrice: number;
  id: number;
  outStanding: number;
  roomNumber: null | string;
  paid: boolean;
  prefix: string;
  reservationCurrencyId: number;
  invoiceId: number;
  taxInclude: boolean;
  extraHour: number;
  checkInDate: string | null;
  checkOutDate: string | null;
  checkInTime: string | null;
  checkOutTime: string | null;
  additionalServiceForReservedRoomResponseList: any;
  comments: string | null;
  reservationRefNumber: string | null;
  vatRegistryResponse: {
    id: number | null;
    name: string;
    address: string;
    vatNumber: string;
    email: string;
    hotelId: string;
    hotelName: string;
    registryType: string[];
    groupId: number;
    groupName: string;
    onlyThisHotelView: boolean;
  } | null;
  vatRegistryApplicable: boolean;
  additionalServiceSingleReservationResponseList: IServicesBookinsData[];
  sumOfReservedServiceTaxes: ITaxList[];
  totalTax: number;
}

export interface IUpdateExtraHourPayload {
  id: number;
  reservedServiceId: number;
  extraHours: number;
}
export interface IUpdateDiscountPayload {
  id: number;
  reservedServiceId: number;
  discount: number;
  discountType: 'PERCENTAGE' | 'AMOUNT';
}

export const initialConvertedData = {
  id: 0,
  comments: '',
  reservationRefNumber: '',
  additionalServices: [],
  invoiceId: 0,
  outStanding: 0,
  paid: false,
  reservationCurrencyId: 0,
  roomNumber: '',
  extraHour: 0,
  totalAmount: 1200,
  taxInclude: false,
  vatRegistryApplicable: false,
  checkInDate: '',
  checkOutDate: '',
  additionalServiceForReservedRoomResponseList: [],
  checkInTime: '',
  checkOutTime: '',
  prefix: '',
  vatRegistryResponse: {
    address: '',
    email: '',
    groupId: 0,
    groupName: '',
    hotelId: '',
    hotelName: '',
    id: 0,
    name: '',
    onlyThisHotelView: false,
    registryType: [''],
    vatNumber: '',
  },
};
