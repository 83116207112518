import React, {useMemo} from 'react';
import {Trans} from 'react-i18next';
import {useTranslation} from 'react-i18next';
import {Button, Col, Row, Space} from 'antd';
import {Link} from 'react-router-dom';
import {Notification} from 'components/common/Notification/Notification';
import {capitalize} from 'utils/utils';
import {Mention, Notification as NotificationType} from 'api/notifications.api';
import {notificationsSeverities} from 'constants/notificationsSeverities';
import * as S from './NotificationsOverlay.styles';
import {acceptChannel} from '@app/api/hotel/channel.api';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {notificationController} from '@app/controllers/notificationController';
import {getNotificationByHotelId} from '@app/store/slices/notificationSlice';

interface NotificationsOverlayProps {
  notifications: NotificationType[];
  setNotifications: (state: NotificationType[]) => void;
  onClose?: () => void;
}

export const NotificationsOverlay: React.FC<NotificationsOverlayProps> = ({
  notifications,
  setNotifications,
  onClose,
  ...props
}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const hotelConfig = useAppSelector(state => state.hotelSlice.hotelConfig);

  const acceptChannelRequest = async (id: number, actionStatus: boolean) => {
    try {
      const result: any = await acceptChannel(id, hotelConfig.hotelId, actionStatus);

      if (result.statusCode === '20000') {
        notificationController.success({message: result.message});
        await dispatch(
          getNotificationByHotelId({
            id: hotelConfig.hotelId,
            hotelNotifications: [],
            groupId: hotelConfig.groupId,
          }),
        );
      } else {
        notificationController.error({message: result.message});
      }
    } catch (error) {}
  };

  const noticesList = useMemo(
    () =>
      notifications.map((notification, index) => {
        const type = notificationsSeverities.find(dbSeverity => dbSeverity.id === notification.id)?.name;

        return (
          <Notification
            key={index}
            type={type || 'warning'}
            title={capitalize(type || 'warning')}
            description={t(notification.description)}
            {...(type === 'mention' && {
              mentionIconSrc: (notification as Mention).userIcon,
              title: (notification as Mention).userName,
              description: (
                <Space>
                  <Button
                    size="small"
                    type="primary"
                    style={{backgroundColor: 'green'}}
                    onClick={() => {
                      acceptChannelRequest(notification.channelId, true);
                    }}>
                    Accept
                  </Button>
                  <Button
                    size="small"
                    type="ghost"
                    onClick={() => {
                      acceptChannelRequest(notification.channelId, false);
                    }}>
                    Reject
                  </Button>
                </Space>
              ),
            })}
          />
        );
      }),
    [notifications, t],
  );

  return (
    <S.NoticesOverlayMenu {...props}>
      <S.MenuRow gutter={[20, 20]}>
        <Col span={24}>
          {notifications.length > 0 ? (
            <Space
              direction="vertical"
              size={10}
              split={<S.SplitDivider />}
              style={{maxHeight: '25rem', overflowX: 'hidden'}}>
              {noticesList}
            </Space>
          ) : (
            <S.Text>{t('header.notifications.noNotifications')}</S.Text>
          )}
        </Col>
        <Col span={24}>
          <Row gutter={[10, 10]}>
            {/* {notifications.length > 0 && (
              <Col span={24}>
                <S.Btn type="ghost" onClick={() => setNotifications([])}>
                  {t('header.notifications.readAll')}
                </S.Btn>
              </Col>
            )} */}
            <Col span={24}>
              <S.Btn
                type="link"
                onClick={() => {
                  onClose && onClose();
                }}>
                <Link to="/channel/new-channel-request">{t('header.notifications.viewAll')}</Link>
              </S.Btn>
            </Col>
          </Row>
        </Col>
      </S.MenuRow>
    </S.NoticesOverlayMenu>
  );
};
