/* eslint-disable @typescript-eslint/no-explicit-any */
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RoomProps} from '@app/pages/Hotel/RoomReservation/RoomReservationPage';
import {FilterProps} from '@app/components/apps/roomFeed/interface';
import {getWebserviceAvailableRooms} from '@app/api/hotel/webBookingService/webBookingService';
import {IConvertedReservedRoom} from '@app/pages/Hotel/RoomReservation/interface/interface';

export interface IReservationResponse {
  roomId: string;
  roomName: string;
  roomPrice: string;
  roomImageUrl: string[];
  roomTypeId: string;
  roomTypeImage: string;
  amenities: string;
  viewTypeId: string;
  viewTypeName: string;
  stayTypeId: string;
  stayTypeName: string;
  roomTypeName: string;
}

interface IPagination {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

export interface WebServiceState {
  reservation: IReservationResponse[];
  loading: boolean;
  error: string | null;
  pagination: IPagination;
  selectedWebBookingRooms: ISelectedRooms;
  webBookingStep: number;
}

const initialState: WebServiceState = {
  reservation: [],
  loading: false,
  error: null,
  pagination: {pageNumber: 0, pageSize: 0, totalPages: 0, totalRecords: 0},
  selectedWebBookingRooms: {
    checkedIn: '',
    checkedOut: '',
    stayTypes: [],
    selectedRoomTypes: [],
    extraChild: [],
    channelId: 0,
    expiredTime: '',
    id: 0,
    expiredDate: '',
  },
  webBookingStep: 0,
};

export interface IExtraChild {
  name: string;
  extraChildCount: number;
  extraChildPrice: number;
  numberOfAdult: number;
  numberOfChildren: number;
  roomType: string;
  mealType: string;
}

export interface ISelectedRooms {
  checkedIn: string;
  checkedOut: string;
  stayTypes: IConvertedReservedRoom[];
  selectedRoomTypes: string[];
  extraChild: IExtraChild[];
  channelId: number;
  id: number;
  expiredTime: string;
  expiredDate: string;
  isResident: boolean;
  searchAdultCount: number;
  searchChildCount: number;
}

export const getWebServiceAvailableRooms = createAsyncThunk<
  {reservation: IReservationResponse[]; pagination: any},
  {hotelId: number; filterPayload: FilterProps},
  {rejectValue: string}
>('webService/getWebServiceAvailableRooms', async ({hotelId, filterPayload}, {rejectWithValue}) => {
  try {
    const {result, pagination} = await getWebserviceAvailableRooms(hotelId, filterPayload);
    const reservations = result?.reservation || [];
    const parsedReservations: IReservationResponse[] = reservations.map((room: RoomProps) => {
      return room;
    });
    return {reservation: parsedReservations, pagination: pagination};
  } catch (error) {
    return rejectWithValue('Failed to fetch reservation');
  }
});

export const webServiceSlice = createSlice({
  name: 'webService',
  initialState,
  reducers: {
    setSelectedWebBookingRooms: (state, action: PayloadAction<ISelectedRooms>) => {
      state.selectedWebBookingRooms = action.payload;
    },
    setWebBookingStep: (state, action: PayloadAction<number>) => {
      state.webBookingStep = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getWebServiceAvailableRooms.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getWebServiceAvailableRooms.fulfilled, (state, action) => {
      state.loading = false;
      state.reservation = action.payload.reservation;
      state.pagination = action.payload.pagination;
    });
    builder.addCase(getWebServiceAvailableRooms.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});
export const {setSelectedWebBookingRooms, setWebBookingStep} = webServiceSlice.actions;
export default webServiceSlice.reducer;
