/* eslint-disable @typescript-eslint/no-explicit-any */
import {Modal} from '@app/components/common/Modal/Modal';
import {Button} from '@app/components/common/buttons/Button/Button';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {Input} from '@app/components/common/inputs/Input/Input';
import {Space, Popconfirm} from 'antd';
import React, {FC, useEffect, useState} from 'react';

import {notificationController} from '@app/controllers/notificationController';
import {FONT_SIZE} from '@app/styles/themes/constants';
import {updateEventExtraHour} from '@app/api/hotel/additionalActivities/additionalActivities.api';
import {updateExtraHours} from '@app/api/hotel/additionalBookings/additionalBookings.api';
import {ConvertedDataProps, IUpdateExtraHourPayload} from '../helper/interface';
import {IServicesBookinsData} from '../interface';
import Tooltip from '@app/components/common/Tooltip/Tooltip';

interface Props {
  serviceData: IServicesBookinsData;
  reloadModalData: () => void;
  isDisable: boolean;
  reservationData: ConvertedDataProps;
}

const ApplyServiceExtraHours: FC<Props> = ({serviceData, reloadModalData, isDisable, reservationData}) => {
  const DISCOUNT_OPTION = {
    AMOUNT: 'AMOUNT',
    PERCENTAGE: 'PERCENTAGE',
  };
  const [discountForm] = BaseForm.useForm();
  const [freeofCharge, setFreeofCharge]: any = useState(false);
  const [isVisible, setIsVisible]: any = useState(false);

  const findResObj = serviceData;

  const handleChangeExtraHour = async () => {
    const formValues = discountForm.getFieldsValue();
    const extraNumberOfHour = formValues?.extraNumberOfHour;

    const payload: IUpdateExtraHourPayload = {
      id: reservationData.id,
      reservedServiceId: serviceData.id,
      extraHours: Number(extraNumberOfHour),
    };

    try {
      const response = await updateExtraHours(payload);
      if (response.statusCode === '20000') {
        notificationController.success({message: response.message});
        reloadModalData();
        discountForm.resetFields();
        setIsVisible(false);
      } else {
        notificationController.error({message: response.message});
      }
    } catch (error) {}
  };

  const onCancelClick = () => {
    discountForm.resetFields();
    setIsVisible(false);
  };

  const NUMBER_REGEX = /^\d*\.?\d*$/;
  const isHourPriceNull = findResObj?.hourPrice === null;

  return (
    <div>
      {isHourPriceNull ? (
        <Tooltip title="Please update hour price for this service">
          <div style={{display: 'inline-block'}}>
            <Button
              type="primary"
              size="small"
              style={{
                borderRadius: '30px',
                fontSize: '13px',
                cursor: 'help',
              }}
              onClick={() => {
                setIsVisible(true);
                setFreeofCharge(false);
              }}
              disabled>
              Extra Hours
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          type="primary"
          size="small"
          style={{
            borderRadius: '30px',
            fontSize: '13px',
          }}
          onClick={() => {
            setIsVisible(true);
            setFreeofCharge(false);
          }}>
          Extra Hours
        </Button>
      )}
      <Modal
        size="small"
        title={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              gap: 5,
              fontSize: FONT_SIZE.xs,
            }}>
            <div>Apply Extra hours for </div>
            <div> {findResObj?.service}</div>
          </div>
        }
        footer={
          <Space>
            <Button
              title="Close"
              onClick={() => {
                onCancelClick();
              }}>
              Close
            </Button>
            <Popconfirm
              placement="rightTop"
              title={`Are you sure to apply this Extra hour?`}
              onConfirm={() => {
                discountForm.submit();
              }}
              okText="Yes"
              cancelText="No">
              <Button type="primary">Apply</Button>
            </Popconfirm>
          </Space>
        }
        open={isVisible}
        onCancel={onCancelClick}>
        <BaseForm
          initialValues={{payable: false, discountOption: DISCOUNT_OPTION.AMOUNT}}
          onFinish={() => handleChangeExtraHour()}
          form={discountForm}
          onValuesChange={(changedValues, allFields) => {
            const name = Object.keys(changedValues)[0];
            const value = Object.values(changedValues)[0];
            console.log('onValuesChange');

            if (name === 'payable') {
              if (value) {
              }
            } else if (name === 'discountOption') {
              //   discountForm.resetFields(['discountAmount']);
            }
          }}>
          <BaseForm.Item
            name={`extraNumberOfHour`}
            label={`Extra Hours (${serviceData.hourPrice}/Hour)`}
            rules={[
              {
                required: false,
                message: 'Required field',
              },
              ({getFieldValue}) => ({
                validator(_, value) {
                  const inputValue: any = parseFloat(value);

                  const input = value.trim();
                  if (!freeofCharge && !input.match(NUMBER_REGEX)) {
                    return Promise.reject('Please enter numbers only');
                  }
                  if (inputValue < 0) {
                    return Promise.reject('Amount must be greater than or equal to 0');
                  }

                  if (inputValue === '') {
                    return Promise.reject('Please enter value');
                  }

                  return Promise.resolve();
                },
              }),
            ]}>
            <Input placeholder="Enter hours" />
          </BaseForm.Item>
        </BaseForm>
      </Modal>
    </div>
  );
};

export default React.memo(ApplyServiceExtraHours);
