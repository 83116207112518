import {httpApi} from '@app/api/http.api';
import './mocks/auth.api.mock';
import {UserModel} from '@app/domain/UserModel';
import {LOGIN_SERVICE} from './instance';
import {AxiosRequestConfig} from 'axios';
import authInstance from './authInstance';
import loginInstance from './loginInstance';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user: UserModel;
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
}

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> => {
  const grant = {
    name: 'grant_type',
    type: 'password',
  };
  // const oauthClient = {
  //   username: 'mobile',
  //   password: 'pin',
  // };
  const oauthClient = {
    username: 'client',
    password: 'secret',
  };

  const token = Buffer.from(`${oauthClient.username}:${oauthClient.password}`, 'utf8').toString('base64');

  const myHeaders = {
    Authorization: `Basic ${token}`,
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
  };
  const config: AxiosRequestConfig = {
    headers: myHeaders,
  };

  const urlencoded = new URLSearchParams();
  urlencoded.append('username', loginPayload.email);
  urlencoded.append('password', loginPayload.password);
  urlencoded.append('grant_type', 'password');

  return loginInstance.post<LoginResponse>(LOGIN_SERVICE + 'oauth/token', urlencoded, config).then(({data}) => data);
};

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('signUp', {...signUpData}).then(({data}) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('forgotPassword', {...resetPasswordPayload}).then(({data}) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('verifySecurityCode', {...securityCodePayload}).then(({data}) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>('setNewPassword', {...newPasswordData}).then(({data}) => data);
