import {BASE_COLORS} from '@app/styles/themes/constants';
import React from 'react';
import {Link} from 'react-router-dom';
import styled, {keyframes} from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Button = styled.button`
  position: relative;
  top: -6px;
  border: 0;
  transition: all 40ms linear;
  margin: 10px 2px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;

  &:focus {
    outline: none;
  }

  &:active,
  &.active {
    top: 2px;
  }
`;

const PrimaryButton = styled(Button)`
  background-color: #4274d7;
  box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 7px 0 0 #4d5bbe,
    0 8px 8px 1px rgba(0, 0, 0, 0.5);
  color: white;

  &:active,
  &.active {
    box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
  }
`;

const SelectedPrimaryButton = styled(Button)`
  background-color: #33b249;
  box-shadow: 0 0 0 1px #33b249 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #25a93b,
    0 5px 8px -4px rgba(0, 0, 0, 0.5);
  color: white;
  top: -3px;
  &:active,
  &.active {
    box-shadow: 0 0 0 1px #25a93b inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
  }
  cursor: not-allowed;
  pointer-events: none;
`;

const DisabledButton = styled(Button)`
  background-color: #999;
  box-shadow: 0 0 0 1px #999 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #777,
    0 5px 8px -4px rgba(0, 0, 0, 0.2);
  color: white;
  top: -3px;
  &:active,
  &.active {
    box-shadow: 0 0 0 1px #25a93b inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
  }
  cursor: not-allowed;
  pointer-events: none;
`;

const SuccessButton = styled(Button)`
  background-color: #228b22;
  box-shadow: 0 0 0 1px #1e6520 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #3c823e,
    0 8px 8px 1px rgba(0, 0, 0, 0.5);
  color: white;

  &:active,
  &.active {
    box-shadow: 0 0 0 1px #1e6520 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
  }
`;

const DangerButton = styled(Button)`
  background-color: #d73814;
  box-shadow: 0 0 0 1px #b93802 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #aa0000,
    0 8px 8px 1px rgba(0, 0, 0, 0.5);
  color: white;

  &:active,
  &.active {
    box-shadow: 0 0 0 1px #b93802 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
  }
`;

const BlackButton = styled(Button)`
  background-color: black;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5) inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 black,
    0 8px 8px 1px rgba(0, 0, 0, 0.5);
  color: white;

  &:active,
  &.active {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5) inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset,
      0 1px 3px 1px rgba(0, 0, 0, 0.3);
  }
`;

const GrayButton = styled(Button)`
  background-color: #808080;
  box-shadow: 0 4px 6px rgba(128, 128, 128, 0.2), 0 6px 8px rgba(128, 128, 128, 0.15);
  color: white;

  &:active,
  &.active {
    box-shadow: 0 1px 3px rgba(128, 128, 128, 0.4), 0 1px 2px rgba(128, 128, 128, 0.3);
  }
`;

interface Props {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  to?: any;
  onClick?: () => void;
  padding?: string;
  disabled?: boolean;
  isBlink?: boolean;
}

const INITIAL_BUTTON_PADDING = '6px 12px';

const blinkAnimation = keyframes`
  0% {
    opacity: 1;
    // color: ${BASE_COLORS.lightningRed}
  }
  50% {
    opacity: 0;
    // color: ${BASE_COLORS.lightningRed}
  }
  100% {
    opacity: 1;
    // color: ${BASE_COLORS.lightningRed}
  }
`;

const BlinkingText = styled.span`
  animation: ${blinkAnimation} 1s linear infinite;
`;

const AwesomeButton: React.FC<Props> = ({title, to, onClick, padding = INITIAL_BUTTON_PADDING, isBlink}) => {
  return (
    <Container>
      <Link to={to}>
        {isBlink ? (
          <PrimaryButton onClick={onClick} style={{padding: padding}}>
            <BlinkingText>{title}</BlinkingText>
          </PrimaryButton>
        ) : (
          <PrimaryButton onClick={onClick} style={{padding: padding}}>
            {title}
          </PrimaryButton>
        )}
      </Link>
    </Container>
  );
};

const AwesomeDangerButton: React.FC<Props> = ({title, to, padding = INITIAL_BUTTON_PADDING}) => {
  return (
    <Container>
      <Link to={to}>
        <DangerButton style={{padding: padding}}>{title}</DangerButton>
      </Link>
    </Container>
  );
};

const AwesomeSuccessButton: React.FC<Props> = ({title, to, onClick, padding = INITIAL_BUTTON_PADDING, disabled}) => {
  return (
    <Container>
      {disabled ? (
        <DisabledButton onClick={onClick} style={{padding: padding}}>
          {title}
        </DisabledButton>
      ) : (
        <Link to={to}>
          <SuccessButton onClick={onClick} style={{padding: padding}}>
            {title}
          </SuccessButton>
        </Link>
      )}
    </Container>
  );
};

const AwesomeDarkButton: React.FC<Props> = ({title, to, onClick, padding = INITIAL_BUTTON_PADDING}) => {
  return (
    <Container>
      <Link to={to}>
        <BlackButton onClick={onClick} style={{padding: padding}}>
          {title}
        </BlackButton>
      </Link>
    </Container>
  );
};

const AwesomeGrayButton: React.FC<Props> = ({title, to, onClick, padding = INITIAL_BUTTON_PADDING}) => {
  return (
    <Container>
      <Link to={to}>
        <GrayButton onClick={onClick} style={{padding: padding}}>
          {title}
        </GrayButton>
      </Link>
    </Container>
  );
};

const AwesomeSelectedPrimaryButton: React.FC<Props> = ({title, to, onClick, padding = INITIAL_BUTTON_PADDING}) => {
  return (
    <Container>
      <Link to={to}>
        <SelectedPrimaryButton onClick={onClick} style={{padding: padding}}>
          {title}
        </SelectedPrimaryButton>
      </Link>
    </Container>
  );
};

export {
  AwesomeButton,
  AwesomeSuccessButton,
  AwesomeDangerButton,
  AwesomeDarkButton,
  AwesomeGrayButton,
  AwesomeSelectedPrimaryButton,
};
