/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import * as S from './PaymentType.Style';
import {CARD, MONEY} from '@app/assets';

interface Props {
  isModalVisible: boolean;
  onCreatePayment: (type: 'CASH' | 'CREDITCARD') => void;
  defaultValue?: 'CASH' | 'CREDITCARD';
}

export const PaymentType: React.FC<Props> = ({isModalVisible, onCreatePayment, defaultValue}) => {
  const [paymentType, setpaymentType] = useState<'CASH' | 'CREDITCARD'>('CASH');

  useEffect(() => {
    defaultValue && setpaymentType(defaultValue);
  }, [defaultValue]);

  return (
    <S.FormContent>
      <S.PaymentOptionWrapper>
        <S.PaymentOptionSection
          onClick={() => {
            setpaymentType('CASH');
            onCreatePayment('CASH');
          }}>
          <S.PaymentOptionOutline $selected={paymentType === 'CASH'}>
            <S.Image src={MONEY} />
          </S.PaymentOptionOutline>
          <S.PaymentType $selected={paymentType === 'CASH'}>Cash</S.PaymentType>
        </S.PaymentOptionSection>
        <S.PaymentOptionSection
          onClick={() => {
            setpaymentType('CREDITCARD');
            onCreatePayment('CREDITCARD');
          }}>
          <S.PaymentOptionOutline $selected={paymentType === 'CREDITCARD'}>
            <S.Image src={CARD} />
          </S.PaymentOptionOutline>
          <S.PaymentType $selected={paymentType === 'CREDITCARD'}>Card</S.PaymentType>
        </S.PaymentOptionSection>
      </S.PaymentOptionWrapper>
      {/* <BaseForm
        size="middle"
        name="paymentForm"
        form={form}
        initialValues={{amount: outStandingAmount.toFixed(2)}}
        fields={fields}
        onFinish={onFinish}>
        <Row
          gutter={{xs: 10, md: 15, xl: 30}}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Col xs={24} md={16}>
            <BaseForm.Item name="amount" label="Amount" rules={[{required: true, message: 'Amount is required'}]}>
              <Input readOnly={true} placeholder="Enter Amount" prefix={currencyPrefix} />
            </BaseForm.Item>
          </Col>
        </Row>
      </BaseForm> */}
    </S.FormContent>
  );
};
