interface ModalSizes {
  small: string;
  medium: string;
  large: string;
  xm: string;
  xl: string;
  ml: string;
}

export const modalSizes: ModalSizes = {
  small: '400px',
  medium: '600px',
  large: '75%',
  xm: '50%',
  xl: '90%',
  ml: '80%',
};
