import {combineReducers, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  ResetPasswordRequest,
  login,
  LoginRequest,
  signUp,
  SignUpRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  NewPasswordData,
  setNewPassword,
} from '@app/api/auth.api';
import {setPermission, setUser, setUserPermission} from '@app/store/slices/userSlice';
import {deleteToken, deleteUser, persistToken} from '@app/services/localStorage.service';
import {ERole, IUserTypes, userTypes} from '@app/utils/utils';
import {IuserAuthorization, UserModel} from '@app/domain/UserModel';
import {find} from 'lodash';
import {setHotelConfig, setHotelGroups, setHotelServiceConfig, setHotelServices, setHotels} from './hotelSlice';
import jwtDecode from 'jwt-decode';

export interface AuthSlice {
  token: string | null;
}

const initialState: AuthSlice = {
  token: null,
};

export interface IResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
}
export interface IToken {
  authorities: ERole[];
  exp: number;
  scope: string[];
  user_name: string;
  userId: number;
  level: 'GROUP' | 'SERVICE' | 'HOTEL';
  hotelCount: 'GROUP' | 'SERVICE' | 'HOTEL';
  userName: string;
}
const hotelData: any = {
  id: 1,
  name: 'Palmyrah House',
  email: 'mannar@serendipityretreats.com',
  title: 'PALMYRAH HOUSE',
  address: 'Thalaimannar Rd, Mannar Island, Mannar, Sri Lanka.',
  cardLogo: 'https://hms-upload-images.s3.ap-south-1.amazonaws.com/Hotels/PMH_LOGO.jpg',
  cardImage: 'https://hms-upload-images.s3.ap-south-1.amazonaws.com/Hotels/PMH_CARD.jpg',
  sideBarImage: 'https://hms-upload-images.s3.ap-south-1.amazonaws.com/Hotels/PMH_SIDE_BAR.jpg',
  titleImage: 'https://hms-upload-images.s3.ap-south-1.amazonaws.com/Hotels/PMH_TITLE.jpg',
  type: 'HOTEL',
  groupId: 1,
  groupName: 'Serendip.ity Retreats',
  vatNumber: null,
  contactNumbersList: [],
};

const groupData: any = {
  id: 1,
  name: 'Serendip.ity Retreats',
  email: 'serendipity@tokyogroups.com',
  contactPerson: 'Manager',
  address: 'No.11 , Haig Road, Colombo 04',
  cardLogo: 'https://hms-upload-images.s3.ap-south-1.amazonaws.com/Groups/GROUP_LOGO.jpg',
  cardImage: 'https://hms-upload-images.s3.ap-south-1.amazonaws.com/Groups/GROUP_CARD.jpg',
  sideBarImage: 'https://hms-upload-images.s3.ap-south-1.amazonaws.com/Groups/GROUP_SIDE_BAR.jpg',
  active: true,
  contactNumbersList: ['+94777776065'],
};

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginRequest, {dispatch}) =>
  login(loginPayload).then((res: IResponse) => {
    const decoded_token: IToken = jwtDecode(res.access_token);

    const user = find(userTypes, (res: IUserTypes) => res.email === loginPayload.email);
    const findUser: UserModel = {
      email: {
        name: decoded_token.user_name,
        verified: true,
      },
      firstName: 'admin',
      lastName: 'admin',
      id: 1,
      phone: {
        number: '0778545658',
        verified: true,
      },
      userName: decoded_token.user_name,
      imgUrl: 'https://www.f4football.com/img/defaults/user_avatar.png',
    };

    const userMockData: IuserAuthorization = {
      userId: decoded_token.userId,
      role: user ? user.role : ERole.PDAMIN,
      groupIds: [1, 1682208],
      hotelIds: [1, 2],
      permission: [],
      userLevel: decoded_token.hotelCount,
      userName: decoded_token.userName,
    };

    // dispatch(setPermission(HOTEL_SERVICE_PERMISSION));
    dispatch(setUser(findUser));
    dispatch(setUserPermission(userMockData));

    persistToken(res.access_token);
    return {token: res.access_token, user: userMockData};
  }),
);

export const doSignUp = createAsyncThunk('auth/doSignUp', async (signUpPayload: SignUpRequest) =>
  signUp(signUpPayload),
);

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload),
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
);

export const doSetNewPassword = createAsyncThunk('auth/doSetNewPassword', async (newPasswordData: NewPasswordData) =>
  setNewPassword(newPasswordData),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, {dispatch}) => {
  deleteToken();
  deleteUser();
  dispatch(setUser(null));
  dispatch(
    setHotelConfig({
      hotelId: 0,
      title: '',
      headerLogo: '',
      sideBarLogo: '',
      groupId: 1,
      groupTitle: '',
      groupHeaderLogo: 'https://www.serendipityretreats.com/assets/frontend/images/mainlogo.png',
      theme: {},
      userLevel: '',
    }),
  );
  dispatch(setHotelServiceConfig({name: '', serviceId: 0, serviceType: 'HOTEL'}));
  dispatch(setHotelGroups([]));
  // dispatch(setHotelServices([]));
  // dispatch(setHotels([]));
  dispatch(setUserPermission(null));
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload.token;
    });
    builder.addCase(doLogout.fulfilled, state => {
      state.token = '';
    });
  },
});

export default authSlice.reducer;
