import inventoryInstance, {INVENTORY_SERVICE} from '@app/api/inventoryInstance';

export interface IInventoryServiceRequest {
  id?: number;
  // name: string;
  // cardLogo: File;
  // cardImage: File;
  // active: boolean;
  hotelId: number;
  // serviceType: string;
  groupInventoryServiceId: number;
}

export interface IApiResponse {
  message: string;
  result: any;
  status: string;
  statusCode: string;
}

export const CreateInventoryService = (payload: IInventoryServiceRequest): Promise<IApiResponse> => {
  // const formData = new FormData();
  // const obj = JSON.stringify({
  //   name: payload.name,
  //   active: payload.active,
  //   hotelId: payload.hotelId,
  //   // serviceType: payload.serviceType,
  // });
  // formData.append('cardLogo', payload.cardLogo);
  // formData.append('cardImage', payload.cardImage);

  // formData.append('inventoryService', obj);
  return inventoryInstance.post<IApiResponse>(INVENTORY_SERVICE + 'inventory-service', payload).then(({data}) => data);
  // return inventoryInstance.post<IApiResponse>(INVENTORY_SERVICE + 'inventory-service', formData).then(({data}) => data);
};

export const getAllInventoryServices = (): Promise<IApiResponse> =>
  inventoryInstance.get<IApiResponse>(INVENTORY_SERVICE + 'inventory-service').then(({data}) => data);

export const getInventoryServicesByHotelId = (id: number, groupId: number): Promise<IApiResponse> =>
  inventoryInstance.get<IApiResponse>(INVENTORY_SERVICE + `inventory-service/hotel/${id}`).then(({data}) => data);

export const UpdateInventoryService = (payload: IInventoryServiceRequest): Promise<IApiResponse> => {
  // const formData = new FormData();
  // const obj = JSON.stringify({
  //   id: payload.id,
  //   name: payload.name,
  //   active: payload.active,
  //   hotelId: payload.hotelId,
  //   serviceType: payload.serviceType,
  // });
  // formData.append('cardLogo', payload.cardLogo);
  // formData.append('cardImage', payload.cardImage);
  // formData.append('inventoryService', obj);

  // return inventoryInstance.put<IApiResponse>(INVENTORY_SERVICE + 'inventory-service', formData).then(({data}) => data);
  return inventoryInstance.post<IApiResponse>(INVENTORY_SERVICE + 'inventory-service', payload).then(({data}) => data);
};

export const DeleteInventoryService = (id: number): Promise<IApiResponse> =>
  inventoryInstance.delete<IApiResponse>(INVENTORY_SERVICE + `inventory-service/${id}`).then(({data}) => data);

export const getInventoryServicesByGroupId = (id: number): Promise<IApiResponse> =>
  inventoryInstance.get<IApiResponse>(INVENTORY_SERVICE + `inventory-service/group/${id}`).then(({data}) => data);

export const getInventoryServicesByHotelIdAndGroupInventoryServiceId = (
  id: number,
  groupInventoryServiceId: number | undefined,
): Promise<IApiResponse> =>
  inventoryInstance
    .get<IApiResponse>(INVENTORY_SERVICE + `inventory-service/hotel/${id}/group-inventory/${groupInventoryServiceId}`)
    .then(({data}) => data);
