import {find, isEmpty} from 'lodash';

export enum EPermissionType {
  'READ',
  'WRITE',
  'MAINTAIN',
  'NO_PERMISSION',
}
export interface IType {
  ADD: boolean;
  VIEW: boolean;
  EDIT: boolean;
  DELETE: boolean;
}
export interface IStatusType {
  [EPermissionType.READ]: IType;
  [EPermissionType.WRITE]: IType;
  [EPermissionType.MAINTAIN]: IType;
  [EPermissionType.NO_PERMISSION]: IType;
}
const PERMISSION_RULE: IStatusType = {
  '0': {
    ADD: false,
    VIEW: true,
    EDIT: false,
    DELETE: false,
  },
  '1': {
    ADD: true,
    VIEW: true,
    EDIT: true,
    DELETE: false,
  },

  '2': {
    ADD: true,
    VIEW: true,
    EDIT: true,
    DELETE: true,
  },
  '3': {
    ADD: false,
    VIEW: false,
    EDIT: false,
    DELETE: false,
  },
};

export const getPermissionStatus = (type: EPermissionType) => {
  return PERMISSION_RULE[type];
};

export const getActiveNavPath = (permissions: any[], navPaths: any) => {
  const filteredKeys = Object.keys(navPaths).filter(key =>
    permissions.some(item => item.prefix === key && item.status !== 3),
  );
  const activeKey = filteredKeys && filteredKeys[0] ? filteredKeys[0] : '';
  const activeNavPath = navPaths[activeKey];

  return activeNavPath;
};

export const MODULE_NAME = {
  GROUP: 'GROUP',
  HOTEL: 'HOTEL',
  HOTEL_SERVICE: 'HOTEL_SERVICE',
  USER: 'USER',
  USER_PERMISSION: 'USER_PERMISSION',
};

export const HOTEL_ADMIN_MODULE_NAME = {
  GROUP: 'GROUP',
  HOTEL: 'HOTEL',
  HOTEL_ROLE: 'HOTEL_ROLE',
  SERVICE_ROLE: 'SERVICE_ROLE',
  HOTEL_SERVICES: 'HOTEL_SERVICES',
  ROLE: 'ROLE',
  GROUP_ROLE: 'GROUP_ROLE',
  EMPLOYEE: 'EMPLOYEE',
  SYSTEMCONFIG: 'SYSTEMCONFIG',
  PAYMENT_TIMEOUT: 'PAYMENT_TIMEOUT',
  USER: 'USER',
  USER_ALLOCATION: 'USER_ALLOCATION',
  HOTEL_PRIVILEGE: 'HOTEL_PRIVILEGE',
  ROLE_PRIVILEGE: 'ROLE_PRIVILEGE',
  USER_PRIVILEGE: 'USER_PRIVILEGE',
  HOTEL_ROLE_PRIVILEGE: 'HOTEL_ROLE_PRIVILEGE',
  SERVICE_ROLE_PRIVILEGE: 'SERVICE_ROLE_PRIVILEGE',
  SERVICE_PRIVILEGE: 'SERVICE_PRIVILEGE',
  PROMOTIONS: 'PROMOTIONS',

  REPORT: 'REPORT',
  GROUP_TURNOVER: 'GROUP_TURNOVER',
  PAYMENT_CONFIG: 'PAYMENT_CONFIG',
  PATMENT_TIME_OUT_CONFIG: 'PATMENT_TIME_OUT_CONFIG',
  IDLE_TIME_OUT: 'IDLE_TIME_OUT',
  PAYMENT_TRANSACTION: 'PAYMENT_TRANSACTION',

  GROUP_PRIVILEGE: 'GROUP_PRIVILEGE',
  GROUP_ROLE_PRIVILEGE: 'GROUP_ROLE_PRIVILEGE',
  TERM_CONDITION: 'TERM_CONDITION',
  MAIL_CONFIG: 'MAIL_CONFIG',
  DISCOUNT: 'DISCOUNT',
  EDIT_GUEST_MAIL: 'EDIT_GUEST_MAIL',
};
export const HOTEL_ADMIN_MODULE_NAME_ACTIVE = {
  HOTEL: 'HOTEL',
  HOTEL_ROLE: 'HOTEL_ROLE',
  SERVICE_ROLE: 'SERVICE_ROLE',
  HOTEL_SERVICES: 'HOTEL_SERVICES',
  ROLE: 'ROLE',
  EMPLOYEE: 'EMPLOYEE',
  USER_PRIVILEGE: 'USER_PRIVILEGE',
  HOTEL_ROLE_PRIVILEGE: 'HOTEL_ROLE_PRIVILEGE',
  SERVICE_ROLE_PRIVILEGE: 'SERVICE_ROLE_PRIVILEGE',
  SERVICE_PRIVILEGE: 'SERVICE_PRIVILEGE',
};
export const SERVICE_ADMIN_MODULE_NAME = {
  SERVICE_ROLE: 'SERVICE_ROLE',
  SERVICE_ROLE_PRIVILEGE: 'SERVICE_ROLE_PRIVILEGE',
  EMPLOYEE: 'EMPLOYEE',
  REPORT: 'REPORT',
};

export const HOTEL_ADMIN_MODULE_NAV_PATH = {
  HOTEL: '/hotel-admin/hotel',
  HOTEL_SERVICES: '/hotel-admin/hotel-service',
  HOTEL_ROLE: '/hotel-admin/hotel-role',
  HOTEL_ROLE_PRIVILEGE: '/hotel-admin/hotel-role-privilege',
  SERVICE_ROLE: '/hotel-admin/service-role',
  SERVICE_ROLE_PRIVILEGE: '/hotel-admin/service-role-privilege',
  EMPLOYEE: '/hotel-admin/hotel-employee',
  ROLE: '/hotel-admin/role',
  HOTEL_PRIVILEGE: '/hotel-admin/hotel-privilege',
  SERVICE_PRIVILEGE: '/hotel-admin/service-privilege',
  REPORT: '/hotel-admin/reports',
  // GROUP: 'GROUP',
  // GROUP_ROLE: 'GROUP_ROLE',
  // USER: 'USER',
  // USER_ALLOCATION: 'USER_ALLOCATION',
  // ROLE_PRIVILEGE: 'ROLE_PRIVILEGE',
  // USER_PRIVILEGE: 'USER_PRIVILEGE',
};
export const GROUP_ADMIN_MODULE_NAV_PATH = {
  HOTEL: '/group-admin/hotel',
  HOTEL_SERVICES: '/group-admin/hotel-service',
  HOTEL_ROLE: '/group-admin/hotel-role',
  HOTEL_ROLE_PRIVILEGE: '/group-admin/hotel-role-privilege',
  SERVICE_ROLE: '/group-admin/service-role',
  SERVICE_ROLE_PRIVILEGE: '/group-admin/service-role-privilege',
  EMPLOYEE: '/group-admin/hotel-employee',
  ROLE: '/group-admin/role',
  HOTEL_PRIVILEGE: '/group-admin/hotel-privilege',
  SERVICE_PRIVILEGE: '/group-admin/service-privilege',
  //NEW PATH
  REPORT: '/group-admin/reports',
  PAYMENT_CONFIG: '/group-admin/hotel-systemconfig',
  PATMENT_TIME_OUT_CONFIG: '/group-admin/hotel-paymentTimeout',
  IDLE_TIME_OUT: '/group-admin/hotel-idle-timeout',
  PAYMENT_TRANSACTION: '/group-admin/payment-transaction',
  GROUP_PRIVILEGE: '/group-admin/group-privilege',
  GROUP_ROLE_PRIVILEGE: '/group-admin/group-role-privilege',
};

export const SERVICE_ADMIN_MODULE_NAV_PATH = {
  SERVICE_ROLE: '/service-admin/service-role',
  SERVICE_ROLE_PRIVILEGE: '/service-admin/service-role-privilege',
  EMPLOYEE: '/service-admin/hotel-employee',
  REPORT: '/service-admin/reports',
};

export const RESTAURANT_SERVICE_ADMIN_MODULE_NAV_PATH = {
  SERVICE_ROLE: '/restaurant-admin/service-role',
  SERVICE_ROLE_PRIVILEGE: '/restaurant-admin/service-role-privilege',
  EMPLOYEE: '/restaurant-admin/hotel-employee',
  REPORT: '/restaurant-admin/reports',
};

export const HOTEL_SERVICE_MODULE_NAME = {
  RESERVATIONS: 'RESERVATIONS',
  RESERVE_ROOMS: 'RESERVE_ROOMS',
  OVERVIEW: 'OVERVIEW',
  CREATE_INQUIRY: 'CREATE_INQUIRY',
  INQUIRY_HISTORY: 'INQUIRY_HISTORY',
  ROOM_OVERVIEW: 'ROOM_OVERVIEW',
  HISTORY: 'HISTORY',
  GUEST: 'GUEST',
  INVOICE: 'INVOICE',
  ADDITIONAL_BILLING: 'ADDITIONAL_BILLING',
  INVOICE_HISTORY: 'INVOICE_HISTORY',
  ADDITIONAL_SERVICES: 'ADDITIONAL_SERVICES',
  ADDITIONAL_SERVICES_BOOKING: 'ADDITIONAL_SERVICES_BOOKING',
  VIEW_TYPE: 'VIEW_TYPE',
  AMENITIES: 'AMENITIES',
  ROOM_TYPE: 'ROOM_TYPE',
  ROOM: 'ROOM',
  STAY_TYPE: 'STAY_TYPE',
  RESERVATION_TYPE: 'RESERVATION_TYPE',
  CHANNEL_REGISTER: 'CHANNEL_REGISTER',
  SEASON: 'SEASON',
  GUIDE: 'GUIDE',
  DRIVER: 'DRIVER',
  CHANNEL_PRICE: 'CHANNEL_PRICE',
  SEASONAL_PRICE: 'SEASONAL_PRICE',
  TAX: 'TAX',
  CHILD_POLICY: 'CHILD_POLICY',
  CANCELLATION_POLICY: 'CANCELLATION_POLICY',
  DASHBOARD: 'DASHBOARD',
  HALL: 'HALL',
  ADDITIONAL_SERVICES_BOOKING_HISTORY: 'ADDITIONAL_SERVICES_BOOKING_HISTORY',
  ROOM_AREA: 'ROOM_AREA',
  CURRENCY_RATES: 'CURRENCY_RATES',
  VAT_REGISTRY: 'VAT_REGISTRY',
  VAT_REGISTRY_PORTAL: 'VAT_REGISTRY_PORTAL',

  EVENT: 'EVENT',
  EVENT_INVOICE: 'EVENT_INVOICE',
  EVENT_PACK: 'EVENT_PACK',
  EVENT_BOOKING: 'EVENT_BOOKING',
  EVENT_HISTORY: 'EVENT_HISTORY',

  MAIL_CONFIG: 'MAIL_CONFIG',
  TERM_CONDITION: 'TERM_CONDITION',
  DISCOUNT: 'DISCOUNT',
  EDIT_GUEST_MAIL: 'EDIT_GUEST_MAIL',
};

export const HOTEL_SERVICE_MODULE_NAV_PATH = {
  RESERVATIONS: '/reservations',
  RESERVE_ROOMS: '/reservation',
  OVERVIEW: '/overview',
  ROOM_OVERVIEW: '/rooms-status-overview',
  HISTORY: '/history',
  GUEST: '/guest',
  INVOICE: '/invoice/make-invoice',
  ADDITIONAL_BILLING: '/invoice/additional-billing',
  INVOICE_HISTORY: '/invoice/invoice-history',
  ADDITIONAL_SERVICES: '/additional-service/additional-services',
  ADDITIONAL_SERVICES_BOOKING: '/additional-service/make-service-booking',
  VIEW_TYPE: '/room/view-type',
  AMENITIES: '/room/amenities',
  ROOM_TYPE: '/room/room-type',
  ROOM: '/room/manage',
  STAY_TYPE: '/room/stay-type',
  RESERVATION_TYPE: '/room/reservation-type',
  CHANNEL_REGISTER: '/channel/new-channel',
  SEASON: '/room/season',
  GUIDE: '/channel/guide',
  DRIVER: '/channel/driver',
  CHANNEL_PRICE: '/channel/day-price',
  SEASONAL_PRICE: '/channel/seasonal-price',
  TAX: '/tax/tax-master',
  CHILD_POLICY: '/childPolicy/child-policy',
  CANCELLATION_POLICY: '/managereservation/cancellation-policy',
  DASHBOARD: '/hotel/dashboard',
  HALL: '/additional-service/hall',
  ADDITIONAL_SERVICES_BOOKING_HISTORY: '/additional-service/additional-services-history',
  ROOM_AREA: '/room/room-area',
  CURRENCY_RATES: '/currency-rates/currency-exchange',

  EVENT: '/additional-activity/create-activity',
  EVENT_PACK: '/additional-activity/create-activity-pack',
  EVENT_INVOICE: '/additional-events',
  EVENT_BOOKING: '/additional-activity/create-activity-booking',
  EVENT_HISTORY: '/additional-activity/activity-booking-history',
};

export const RESTAURANT_ADMIN_MODULE_NAME = {
  TABLE: 'TABLE',
  TABLE_CATEGORY: 'TABLE_CATEGORY',
  CATEGORY: 'CATEGORY',
  ALLOCATE_CATEGORY: 'ALLOCATE_CATEGORY',
  FOOD_ITEMS: 'FOOD_ITEMS',
  RESTAURANT_INVOICE: 'RESTAURANT_INVOICE',
  RESTAURANT_TAX: 'RESTAURANT_TAX',
  RESTAURANT_DASHBOARD: 'RESTAURANT_DASHBOARD',
  CHEF_DASHBOARD: 'CHEF_DASHBOARD',
  STUART_DASHBOARD: 'STUART_DASHBOARD',
  FOOD_MENU: 'FOOD_MENU',
  ALLOW_ALL_TABLE: 'ALLOW_ALL_TABLE',
  VAT_REGISTRY: 'VAT_REGISTRY',
};

export const RESTAURANT_MASTER_MODULE_NAME = {
  TABLE: 'TABLE',
  TABLE_CATEGORY: 'TABLE_CATEGORY',
  CATEGORY: 'CATEGORY',
  ALLOCATE_CATEGORY: 'ALLOCATE_CATEGORY',
  FOOD_ITEMS: 'FOOD_ITEMS',
  RESTAURANT_INVOICE: 'RESTAURANT_INVOICE',
  RESTAURANT_TAX: 'RESTAURANT_TAX',
  VAT_REGISTRY: 'VAT_REGISTRY',
};

export const RESTAURANT_MASTER_MODULE_NAV_PATH = {
  FOOD_ITEMS: '/restaurant-admin/foods',
  TABLE_CATEGORY: '/restaurant-admin/table-category',
  TABLE: '/restaurant-admin/table',
  CATEGORY: '/restaurant-admin/category',
  ALLOCATE_CATEGORY: '/restaurant-admin/item-category-allocate',
  RESTAURANT_INVOICE: '/restaurant-admin/invoice',
  RESTAURANT_TAX: '/restaurant-admin/tax',
  CHEF_DASHBOARD: '/toplevel/chef-dashboard',
  // STUART_DASHBOARD: 'STUART_DASHBOARD',
  FOOD_MENU: '/category/main',
  VAT_REGISTRY: '/restaurant-admin/vat-registry',
};

export const HOUSEKEEPING_ADMIN_MODULE_NAME = {
  HOUSE_KEEPER: 'HOUSE_KEEPER',
  TROLLEY_CREATION: 'TROLLEY_CREATION',
  TROLLEY_ALLOCATION: 'TROLLEY_ALLOCATION',
  CHECK_LIST_ITEMS: 'CHECK_LIST_ITEMS',
  CHECK_LIST: 'CHECK_LIST',
  HOUSEKEEPING: 'HOUSEKEEPING',
};

export const HOUSEKEEPING_ADMIN_MODULE_NAV_PATH = {
  HOUSE_KEEPER: '/housekeeping/house-keeper',
  TROLLEY_CREATION: '/housekeeping/Trolley-item',
  TROLLEY_ALLOCATION: '/housekeeping/TrolleyAllocation-item',
  CHECK_LIST_ITEMS: '/housekeeping/check-list-action',
  CHECK_LIST: '/housekeeping/check-list',
  HOUSEKEEPING: '/toplevel/housekeepercard',
};

export const HOUSEKEEPING_MASTER_MODULE_NAME = {
  HOUSE_KEEPER: 'HOUSE_KEEPER',
  TROLLEY_CREATION: 'TROLLEY_CREATION',
  TROLLEY_ALLOCATION: 'TROLLEY_ALLOCATION',
  CHECK_LIST_ITEMS: 'CHECK_LIST_ITEMS',
  CHECK_LIST: 'CHECK_LIST',
};

export const HOUSEKEEPING_MASTER_MODULE_NAV_PATH = {
  HOUSE_KEEPER: '/housekeeping/house-keeper',
  TROLLEY_CREATION: '/housekeeping/Trolley-item',
  TROLLEY_ALLOCATION: '/housekeeping/TrolleyAllocation-item',
  CHECK_LIST_ITEMS: '/housekeeping/check-list-action',
  CHECK_LIST: '/housekeeping/check-list',
};

export const INVENTORY_SERVICE_MODULE_NAME = {
  INVENTORY_DASHBOARD: 'INVENTORY_DASHBOARD',
  STOCK: 'STOCK',
  GRN: 'GRN',
  TROLLY_REQUEST: 'TROLLY_REQUEST',
  TROLLY_REQUEST_HISTORY: 'TROLLY_REQUEST_HISTORY',
  STOCK_TRANSFER: 'STOCK_TRANSFER',
  STOCK_TRANSFER_HISTORY: 'STOCK_TRANSFER_HISTORY',
  MAIN_CATEGORY: 'MAIN_CATEGORY',
  SUB_CATEGORY: 'SUB_CATEGORY',
  INVENTORY_ITEM: 'INVENTORY_ITEM',
  UNIT_TYPE: 'UNIT_TYPE',
  INVENTORY_UNIT: 'INVENTORY_UNIT',
  INVENTORY_UNIT_CONVERSION: 'INVENTORY_UNIT_CONVERSION',
  SUPPLIER: 'SUPPLIER',
  PURCHASE_ORDER: 'PURCHASE_ORDER',
  PURCHASE_ORDER_HISTORY: 'PURCHASE_ORDER_HISTORY',
  INVENTORY_SERVICES: 'INVENTORY_SERVICES',
  INVENTORY_REPORT: 'INVENTORY_REPORT',
};

export const INVENTORY_SERVICE_MODULE_NAV_PATH = {
  INVENTORY_DASHBOARD: '/inventory/inventory-dashboard',
  STOCK: '/inventory/stock-management/opeingstock',
  GRN: '/inventory/stock-management/create-grn',
  TROLLY_REQUEST: '/inventory/stock-management/trolly-request',
  TROLLY_REQUEST_HISTORY: '/inventory/stock-management/trolly-request-history',
  STOCK_TRANSFER: '/inventory/stock-management/stock-transfer',
  STOCK_TRANSFER_HISTORY: '/inventory/stock-management/stock-transfer-history',
  MAIN_CATEGORY: '/inventory/item-master/main-category',
  SUB_CATEGORY: '/inventory/item-master/sub-category',
  INVENTORY_ITEM: '/inventory/item-master/item',
  UNIT_TYPE: '/inventory/item-master/unit-type',
  INVENTORY_UNIT: '/inventory/item-master/unit',
  INVENTORY_UNIT_CONVERSION: '/inventory/item-master/unit-conversion',
  SUPPLIER: '/inventory/supplier',
  PURCHASE_ORDER: '/inventory/stock-management/purchase-order',
  PURCHASE_ORDER_HISTORY: '/inventory/stock-management/grn-history',
  INVENTORY_SERVICES: '/inventory-admin/inventory-service',
  INVENTORY_REPORT: '/inventory/inventory-report',
};

export interface IPermission {
  id: number;
  name: string;
  status: EPermissionType;
  prefix: string;
  active: boolean;
}
export interface IPermissionRes {
  id: number;
  name: string;
  status: string;
  prefix: string;
  active: boolean;
  servicePermissionId: number;
}

export interface IHotelPermissionRes {
  id: number;
  name: string;
  status: string;
  prefix: string;
  active: boolean;
  hotelPermissionId: number;
}
export interface IGroupsPermissionRes {
  id: number;
  name: string;
  status: string;
  prefix: string;
  active: boolean;
  groupsPermissionId: number;
}
export interface IGroupPermisRes {
  id: number;
  name: string;
  status: string;
  prefix: string;
  active: boolean;
  permissionId: number;
}
export interface IHotellPermissionRes {
  id: number;
  name: string;
  status: string;
  prefix: string;
  active: boolean;
  permissionId: number;
}

export const modulePermission = (permissions: IPermission[] | undefined, moduleName: string) => {
  if (permissions !== undefined && !isEmpty(permissions)) {
    const findPermission: any = find(permissions, res => res.prefix === moduleName);
    if (findPermission === undefined) {
    }
    const findObj: IPermission = {
      id: findPermission?.id,
      name: findPermission?.name,
      prefix: findPermission?.prefix,
      status: findPermission?.status,
      active: findPermission?.active,
    };

    return PERMISSION_RULE[findObj.status]
      ? PERMISSION_RULE[findObj.status]
      : PERMISSION_RULE[EPermissionType.MAINTAIN];
  } else {
    return PERMISSION_RULE[EPermissionType.NO_PERMISSION];
  }
};
