/* eslint-disable react/jsx-key */
import TopLevelCard from '@app/components/cards/TopLevelCard';
import React, {useEffect, useState} from 'react';
import * as S from './HouseKeeper.style';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {setHouseKeepingTopCardDetails} from '@app/store/slices/houseKeeping';
import {getRoomReservationOverallStatus} from '@app/api/houseKeeping/cleaning/cleaning';
import BlinkCard from '@app/components/common/BlinkCard/BlinkCard';
import ContrastAnimation from '@app/components/common/ContrastAnimation/ContrastAnimation';
import {NAVIGATION} from '../navigationPaths/paths';

const HOUSE_KEEPING_CARDS = [
  {
    name: 'Cleaning',
    key: 1,
    image: 'https://img.freepik.com/free-photo/person-taking-care-office-cleaning_23-2149374426.jpg',
    desc: 'Cleaning rooms available',
  },
  {
    name: 'Trolley',
    key: 2,
    image:
      'https://media.istockphoto.com/id/479347789/photo/hotel-housekeeping-cart.jpg?s=612x612&w=0&k=20&c=HpXE7LZ152PfFoDOxDleSJRP2cKTwsmycEwZ_fu2I50=',
    desc: 'Trolley details available',
  },

  {
    name: 'Laundry',
    key: 3,
    image:
      'https://media.istockphoto.com/id/1318399310/photo/laundry-detergents-and-dirty-clothes.webp?b=1&s=170667a&w=0&k=20&c=Y_YqOi3SuAx35-ap2T0nVIN9D3tkyFLNhUJPzQVPwN8=',
    desc: 'Laundry details available',
  },
];

const HouseKeeperCard = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [status, setstatus] = useState({
    checkOutArgentRoomStatus: false,
    checkInOnDemandRoomStatus: false,
  });
  const {hotelId} = useAppSelector(state => state.hotelSlice.hotelConfig);

  const [isRequestedForCleaning, setisRequestedForCleaning] = useState(false);

  const onChangeCard = async (data: {name: string; key: number; image?: string; desc: string}) => {
    dispatch(
      setHouseKeepingTopCardDetails({
        desc: data.desc,
        key: data.key,
        name: data.name,
      }),
    );

    if (data.key === 1) {
      navigate('/house-keeping/cleaning');
    } else if (data.key === 3) {
      navigate('/house-keeping/laundry');
    } else {
      navigate('/house-keeping/Trolly');
    }
  };

  const getOverallStatus = async () => {
    try {
      const response = await getRoomReservationOverallStatus(hotelId);
      const status =
        response.result.rooms.checkOutArgentRoomStatus || response.result.rooms.checkInOnDemandRoomStatus
          ? true
          : false;
      setstatus({
        checkInOnDemandRoomStatus: response.result.rooms.checkInOnDemandRoomStatus,
        checkOutArgentRoomStatus: response.result.rooms.checkOutArgentRoomStatus,
      });
      setisRequestedForCleaning(status);
    } catch (error) {}
  };

  useEffect(() => {
    getOverallStatus();
  }, []);

  return (
    <S.ProductsContainer>
      <S.TopBreadcrumb>
        <S.LeftBreadcrumb>
          <S.PulseButtonWrapper>
            <ContrastAnimation
              isActive={status.checkInOnDemandRoomStatus}
              onClick={() => navigate(NAVIGATION.ONDEMAND_ROOMS.path)}
              disabled={false}
            />
          </S.PulseButtonWrapper>
        </S.LeftBreadcrumb>
      </S.TopBreadcrumb>
      {HOUSE_KEEPING_CARDS.map((post, i: number) => {
        return (
          <S.FadeIn onClick={() => onChangeCard(post)}>
            <BlinkCard
              isBlink={post.key === 1 ? isRequestedForCleaning : false}
              key={i}
              bgUrl={post.image}
              name={post.name}
              info={post.desc}
            />
          </S.FadeIn>
        );
      })}
    </S.ProductsContainer>
  );
});

export default HouseKeeperCard;
