import instance, {HOTEL_SERVICE} from '@app/api/instance';

export const CreateRoomType = (payload: CreateRoomTypeProps): Promise<RoomTypeResponse> => {
  const roomTypeFormData = new FormData();
  roomTypeFormData.append('roomType', JSON.stringify(payload.roomType));
  roomTypeFormData.append('image', payload.image);

  return instance.post<RoomTypeResponse>(HOTEL_SERVICE + 'room-type', roomTypeFormData).then(({data}) => data);
};

export const getAllRoomTypes = (
  hotelId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  {roomTypeName}: any,
  pageSize: number | undefined,
  current: number,
): Promise<RoomTypeResponse> =>
  instance
    .get<RoomTypeResponse>(
      HOTEL_SERVICE +
        `room-type/search?page=${current}&size=${pageSize}&sortField=id&direction=DESC&roomTypeName=${
          roomTypeName ? roomTypeName : ''
        }&capacity=&numberOfRooms=&apiTypeCode=&hotelName=&amenities=&hotelId=${hotelId}`,
    )
    .then(({data}) => data);

export const UpdateRoomType = (payload: UpdateRoomTypeProps): Promise<RoomTypeResponse> => {
  const roomTypeFormData = new FormData();
  roomTypeFormData.append('roomType', JSON.stringify(payload.roomType));
  roomTypeFormData.append('image', payload.image);
  return instance.put<RoomTypeResponse>(HOTEL_SERVICE + 'room-type', roomTypeFormData).then(({data}) => data);
};

export const DeleteRoomType = (id: number): Promise<RoomTypeResponse> =>
  instance.delete<RoomTypeResponse>(HOTEL_SERVICE + `room-type/${id}`).then(({data}) => data);

export const getAllRoomTypesForDropDown = (hotelId: any): Promise<RoomTypeResponse> =>
  instance.get<RoomTypeResponse>(HOTEL_SERVICE + `room-type/hotel/${hotelId}`).then(({data}) => data);

export const getAllRoomTypesForDropDownStay = (hotelId: any): Promise<RoomTypeResponse> =>
  instance.get<RoomTypeResponse>(HOTEL_SERVICE + `room-type/hotel/room/${hotelId}`).then(({data}) => data);
export interface CreateRoomTypeProps {
  roomType: {
    roomTypeName: string;
    roomArea: number;
    numberOfRooms: number;
    amenityIds: number[];
    areaIds: number[];
    apiTypeCode: number | null;
    hotelId: number;
    hotelType: string;
  };
  image: string;
}
export interface UpdateRoomTypeProps {
  roomType: {
    id: number;
    roomTypeName: string;
    roomArea: number;
    numberOfRooms: number;
    amenityIds: number[];
    areaIds: number[];
    apiTypeCode: number | null;
    hotelId: number;
    hotelType: string;
  };
  image: File;
}
export interface RoomTypeResponse {
  message: string;
  result: any;
  status: string;
  statusCode: string;
}

export interface FilterProps {
  roomTypeName: string;
}
