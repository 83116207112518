import axios from 'axios';
import {API_KEY_ENV} from './instance';
export const INVENTORY_SERVICE = 'inventory-service/api/v1/';

const inventoryInstance = axios.create({
  baseURL: `${process.env.NODE_ENV === 'production' ? API_KEY_ENV : process.env.REACT_APP_BASE_URL}:9093/`,
});

inventoryInstance.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('accessToken');

  if (token) {
    config.headers = {...config.headers};
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default inventoryInstance;
