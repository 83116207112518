/* eslint-disable react/jsx-key */
import TopLevelCard from '@app/components/cards/TopLevelCard';
import styled from 'styled-components';
import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {getHotelServiceByHotelId, setHotelServiceConfig, setIsServiceLevel} from '@app/store/slices/hotelSlice';
import {IHotelServiceDataRes} from './MasterPages/hotel/groupAdmin/hotelService/HotelServicePage';
import {Empty} from 'antd';
import {Button} from '@app/components/common/buttons/Button/Button';
import {getCategoryStuctureByID} from '@app/api/resturant/tablecategory/categoryStucture.api';
import {resetMenuItem, setCategoryData} from './Restaurant/slices/categoryNavSlice';
import {
  EPermissionType,
  HOTEL_SERVICE_MODULE_NAV_PATH,
  HOUSEKEEPING_ADMIN_MODULE_NAME,
  HOUSEKEEPING_ADMIN_MODULE_NAV_PATH,
  INVENTORY_SERVICE_MODULE_NAME,
  INVENTORY_SERVICE_MODULE_NAV_PATH,
  IPermission,
  RESTAURANT_ADMIN_MODULE_NAME,
  RESTAURANT_MASTER_MODULE_NAV_PATH,
  SERVICE_ADMIN_MODULE_NAV_PATH,
  getActiveNavPath,
  modulePermission,
} from '@app/utils/permissions';
import {setPermission} from '@app/store/slices/userSlice';
import {getAllServicePermissionByUserId} from '@app/api/privilege/serviceRolePrivilege/serviceRolePrivilege.api';
import {getHotelServicesByHotelIdAndUserId} from '@app/api/groupAdmin/hotelService/hotelService.api';
import {sortBy} from 'lodash';
// import Reveal from 'react-reveal/Reveal';

export const FadeIn = styled.div`
  animation: fadeInAnimation 1s linear;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
const ProductsContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;

const ServiceLevelHome = () => {
  const [hotelServices, setHotelServices] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const [isCardClick, setIsCardClick] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const hotelConfig = useAppSelector(state => state.hotelSlice.hotelConfig);

  const userConfig = useAppSelector(state => state.user.userPermission);
  const isEffectExecuted = localStorage.getItem('isEffectExecuted');
  useEffect(() => {
    localStorage.setItem('isEffectExecuted', ``);
    const xEff = isEffectExecuted ? true : false;
    if (!xEff) {
      dispatch(setIsServiceLevel(false));
      dispatch(
        setHotelServiceConfig({
          name: '',
          serviceId: 0,
          serviceType: 'NO_SERVICE',
        }),
      );
      localStorage.setItem('isEffectExecuted', `${true}`);
    }
  }, []);

  const getCategoryById = async (
    id: number,
    isStuart: boolean,
    isChef: boolean,
    serviceData: IHotelServiceDataRes,
    convertedPermission: IPermission[],
  ) => {
    const result = await getCategoryStuctureByID(id, serviceData.id);

    const data: any[] = [];

    result.result.category &&
      result.result.category.map((post: any, i: any) => {
        data.push({
          id: post.id,
          backgroundImage: post.categoryImage,
          title: post.categoryTitle,
          children: [],
          expanded: post.expanded,
          categoryId: post.categoryId,
        });
      });
    dispatch(resetMenuItem({}));
    const activeNavPath: string = getActiveNavPath(convertedPermission, {
      ...RESTAURANT_MASTER_MODULE_NAV_PATH,
      ...SERVICE_ADMIN_MODULE_NAV_PATH,
    });

    Promise.all([
      dispatch(setCategoryData({items: data})),
      navigate(isStuart ? '/category/main' : isChef ? '/toplevel/chef-dashboard' : activeNavPath, {state: data}),
      dispatch(
        setHotelServiceConfig({
          name: serviceData.name,
          serviceId: serviceData.id,
          serviceType: serviceData.serviceType,
        }),
      ),
      dispatch(setIsServiceLevel(true)),
    ]);
  };

  const onChangeCard = (data: IHotelServiceDataRes) => {
    setIsCardClick(true);

    if (data.serviceType === 'RESTAURANT') {
      getServicePermission(data);
    } else if (data.serviceType === 'INVENTORY') {
      // Promise.all([
      //   navigate('/toplevel/inventory-services', {state: data}),
      //   dispatch(
      //     setHotelServiceConfig({
      //       name: data.name,
      //       serviceId: data.id,
      //       serviceType: data.serviceType,
      //     }),
      //   ),
      // ]);
      getServicePermission(data);
    } else if (data.serviceType === 'HOUSEKEEPING') {
      getServicePermission(data);
    } else {
      getServicePermission(data);
    }
  };

  const getServicePermission = async (data: IHotelServiceDataRes) => {
    const userId = userConfig?.userId ? userConfig?.userId : 0;

    const result = await getAllServicePermissionByUserId(userId, data.id);
    const servicespermission = result.result.servicespermission;
    const convertedPermission: IPermission[] = servicespermission.map((post: any, index: number) => {
      return {
        id: post.id,
        name: post.name,
        prefix: post.prefix,
        status:
          post.status === 'READ'
            ? EPermissionType.READ
            : post.status === 'WRITE'
            ? EPermissionType.WRITE
            : post.status === 'MAINTAIN'
            ? EPermissionType.MAINTAIN
            : EPermissionType.NO_PERMISSION,
        active: post.active,
      };
    });

    if (data.serviceType === 'RESTAURANT') {
      const permissionsStuart = modulePermission(convertedPermission, RESTAURANT_ADMIN_MODULE_NAME.FOOD_MENU);
      const permissionsChef = modulePermission(convertedPermission, RESTAURANT_ADMIN_MODULE_NAME.CHEF_DASHBOARD);
      const isStuart = permissionsStuart.VIEW;
      const isChef = permissionsChef.VIEW && !isStuart;

      Promise.all([
        dispatch(setPermission(convertedPermission)),
        getCategoryById(0, isStuart, isChef, data, convertedPermission),
      ]);
    } else if (data.serviceType === 'HOTEL') {
      const activeNavPath: string = getActiveNavPath(convertedPermission, {
        ...HOTEL_SERVICE_MODULE_NAV_PATH,
        ...SERVICE_ADMIN_MODULE_NAV_PATH,
      });
      Promise.all([
        dispatch(setPermission(convertedPermission)),
        navigate(activeNavPath, {state: data}),
        dispatch(
          setHotelServiceConfig({
            name: data.name,
            serviceId: data.id,
            serviceType: data.serviceType,
          }),
        ),
        dispatch(setIsServiceLevel(true)),
      ]);
    } else if (data.serviceType === 'HOUSEKEEPING') {
      const permissionsHouseKeeper = modulePermission(convertedPermission, HOUSEKEEPING_ADMIN_MODULE_NAME.HOUSEKEEPING);
      const isHouseKeeper = permissionsHouseKeeper.VIEW;
      const activeHouseKeepingNavPath: string = getActiveNavPath(convertedPermission, {
        ...HOUSEKEEPING_ADMIN_MODULE_NAV_PATH,
        ...SERVICE_ADMIN_MODULE_NAV_PATH,
      });

      Promise.all([
        dispatch(setPermission(convertedPermission)),
        navigate(isHouseKeeper ? '/toplevel/housekeepercard' : activeHouseKeepingNavPath, {state: {}}),
        dispatch(
          setHotelServiceConfig({
            name: data.name,
            serviceId: data.id,
            serviceType: data.serviceType,
          }),
        ),
        dispatch(setIsServiceLevel(true)),
      ]);
    } else if (data.serviceType === 'INVENTORY') {
      const permissionsGroupInventoryAdmin = modulePermission(
        convertedPermission,
        INVENTORY_SERVICE_MODULE_NAME.PURCHASE_ORDER,
      );
      const groupInventoryAdmin = permissionsGroupInventoryAdmin.VIEW;
      const activeNavPath: string = getActiveNavPath(convertedPermission, {
        ...INVENTORY_SERVICE_MODULE_NAV_PATH,
      });

      Promise.all([
        dispatch(setPermission(convertedPermission)),
        navigate(groupInventoryAdmin ? '/toplevel/inventory-services' : '/toplevel/inventory-services', {state: {}}),
        dispatch(
          setHotelServiceConfig({
            name: data.name,
            serviceId: data.id,
            serviceType: data.serviceType,
          }),
        ),
        dispatch(setIsServiceLevel(true)),
      ]);
    } else {
    }
  };

  useEffect(() => {
    getHotelServices();
  }, []);

  const getHotelServices = async () => {
    try {
      const result = await getHotelServicesByHotelIdAndUserId(hotelConfig.hotelId, userConfig?.userId);
      if (result.result.hotelService?.length > 0) {
        setIsCreate(true);
      } else {
        setIsCreate(false);
      }
      await setHotelServices(result.result.hotelService);
    } catch (error) {}
  };

  return (
    <ProductsContainer>
      {isCreate ? (
        sortBy(hotelServices, 'id').map((post: IHotelServiceDataRes, i: number) => {
          return (
            <FadeIn onClick={() => onChangeCard(post)}>
              <TopLevelCard key={i} bgUrl={post.cardImage} logo={post.cardLogo} name={post.name} />
            </FadeIn>
          );
        })
      ) : (
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          // description={
          //   <div>{"Sorry, you don't have any hotel services  yet. Please create a new hotel service to continue."}</div>
          // }
        >
          {/* <Link to={'/group-admin/hotel-service'}>
            <Button type="primary">Create Hotel Service</Button>
          </Link> */}
        </Empty>
      )}
    </ProductsContainer>
  );
};

export default React.memo(ServiceLevelHome);
