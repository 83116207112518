import {TeamOutlined, UserAddOutlined} from '@ant-design/icons';
import {HOTEL_ADMIN_MODULE_NAME} from '@app/utils/permissions';
import React from 'react';
import {AiFillSetting} from 'react-icons/ai';
import {BiBuilding, BiHotel, BiRocket, BiUser} from 'react-icons/bi';
import {FaServicestack} from 'react-icons/fa';
import {MdOutlineSettings} from 'react-icons/md';
import {TbReport} from 'react-icons/tb';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  isSubMenu: boolean;
}

export const GroupAdminSidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Hotel Settings',
    key: 'hotel-settings',
    icon: <MdOutlineSettings />,
    isSubMenu: true,
    children: [
      {
        title: 'Hotel',
        key: HOTEL_ADMIN_MODULE_NAME.HOTEL,
        url: '/group-admin/hotel',
        isSubMenu: false,
      },
      {
        title: 'Services',
        key: HOTEL_ADMIN_MODULE_NAME.HOTEL_SERVICES,
        url: '/group-admin/hotel-service',
        isSubMenu: false,
      },
      {
        title: 'Hotel Role',
        key: HOTEL_ADMIN_MODULE_NAME.HOTEL_ROLE,
        url: '/group-admin/hotel-role',
        isSubMenu: false,
      },
      {
        title: 'Hotel Role Privilege',
        key: HOTEL_ADMIN_MODULE_NAME.HOTEL_ROLE_PRIVILEGE,
        url: '/group-admin/hotel-role-privilege',
        isSubMenu: false,
      },
      {
        title: 'Service Role',
        key: HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE,
        url: '/group-admin/service-role',
        isSubMenu: false,
      },
      {
        title: 'Service Role Privilege',
        key: HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE_PRIVILEGE,
        url: '/group-admin/service-role-privilege',
        isSubMenu: false,
      },
      {
        title: 'User',
        key: HOTEL_ADMIN_MODULE_NAME.EMPLOYEE,
        url: '/group-admin/hotel-employee',
        isSubMenu: false,
      },
    ],
  },
  {
    title: 'Reports',
    key: HOTEL_ADMIN_MODULE_NAME.REPORT,
    url: '/group-admin/reports',
    icon: <TbReport />,
    isSubMenu: false,
  },
  {
    title: 'System Config',
    key: 'system-config',
    icon: <MdOutlineSettings />,
    isSubMenu: true,
    children: [
      {
        title: 'Payment Config',
        key: HOTEL_ADMIN_MODULE_NAME.SYSTEMCONFIG,
        url: '/group-admin/hotel-systemconfig',
        isSubMenu: false,
      },

      {
        title: 'Payment Timeout Config',
        key: HOTEL_ADMIN_MODULE_NAME.PAYMENT_TIMEOUT,
        url: '/group-admin/hotel-paymentTimeout',
        isSubMenu: false,
      },
      {
        title: 'Idle Timeout',
        key: HOTEL_ADMIN_MODULE_NAME.IDLE_TIME_OUT,
        url: '/group-admin/hotel-idle-timeout',
        isSubMenu: false,
      },
      {
        title: 'Reservation Time Config',
        key: 'reservation-config',
        url: '/group-admin/reservation-config',
        isSubMenu: false,
      },
      // {
      //   title: 'Term Condition',
      //   key: HOTEL_ADMIN_MODULE_NAME.TERM_CONDITION,
      //   url: '/group-admin/term-condtion',
      //   isSubMenu: false,
      // },
      {
        title: 'Mail Config',
        key: HOTEL_ADMIN_MODULE_NAME.MAIL_CONFIG,
        url: '/group-admin/mail-config',
        isSubMenu: false,
      },
    ],
  },
  {
    title: 'Admin Settings',
    key: 'admin-settings',
    icon: <MdOutlineSettings />,
    isSubMenu: true,
    children: [
      {
        title: 'Group',
        key: HOTEL_ADMIN_MODULE_NAME.GROUP,
        url: '/group-admin/hotel-group',
        isSubMenu: false,
      },
      {
        title: 'Role',
        key: HOTEL_ADMIN_MODULE_NAME.ROLE,
        url: '/group-admin/role',
        isSubMenu: false,
      },
      {
        title: 'Group Role',
        key: HOTEL_ADMIN_MODULE_NAME.GROUP_ROLE,
        url: '/group-admin/group-role',
        isSubMenu: false,
      },

      {
        title: 'Group Privilege',
        key: HOTEL_ADMIN_MODULE_NAME.GROUP_PRIVILEGE,
        url: '/group-admin/group-privilege',
        isSubMenu: false,
      },
      {
        title: 'Group Role Privilege',
        key: HOTEL_ADMIN_MODULE_NAME.GROUP_ROLE_PRIVILEGE,
        url: '/group-admin/group-role-privilege',
        isSubMenu: false,
      },

      {
        title: 'Hotel Privilege',
        key: HOTEL_ADMIN_MODULE_NAME.HOTEL_PRIVILEGE,
        url: '/group-admin/hotel-privilege',
        isSubMenu: false,
      },
      {
        title: 'User Privilege',
        key: HOTEL_ADMIN_MODULE_NAME.USER_PRIVILEGE,
        url: '/group-admin/hotel-user-privilege',
        icon: <BiUser />,
        isSubMenu: false,
      },
      {
        title: 'Service Privilege',
        key: HOTEL_ADMIN_MODULE_NAME.SERVICE_PRIVILEGE,
        url: '/group-admin/service-privilege',
        isSubMenu: false,
      },
      {
        title: 'Payment Transaction',
        key: HOTEL_ADMIN_MODULE_NAME.PAYMENT_TRANSACTION,
        url: '/group-admin/payment-transaction',
        isSubMenu: false,
      },
    ],
  },

  // {
  //   title: 'Group Role',
  //   key: 'group-role',
  //   url: '/group-admin/group-role',
  //   icon: <TeamOutlined />,
  //   isSubMenu: false,
  // },

  // {
  //   title: 'User',
  //   key: HOTEL_ADMIN_MODULE_NAME.USER,
  //   url: '/group-admin/hotel-user',
  //   icon: <UserAddOutlined />,
  //   isSubMenu: false,
  // },
  // {
  //   title: 'User Allocation',
  //   key: 'user-allocation',
  //   url: '/group-admin/user-allocation',
  //   icon: <UserAddOutlined />,
  //   isSubMenu: false,
  // },
  // {
  //   title: 'Product Admin Privilege',
  //   key: 'product-admin-privilege',
  //   url: '/group-admin/product-admin-privilege',
  //   icon: <BiUser />,
  //   isSubMenu: false,
  // },
  // {
  //   title: 'User Privilege',
  //   key: HOTEL_ADMIN_MODULE_NAME.USER_PRIVILEGE,
  //   url: '/group-admin/hotel-user-privilege',
  //   icon: <BiUser />,
  //   isSubMenu: false,
  // },
  // {
  //   title: 'Admin Privilege',
  //   key: 'admin-privilege',
  //   url: '/group-admin/admin-privilege',
  //   icon: <BiUser />,
  //   isSubMenu: false,
  // },
];
