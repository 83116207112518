import {INVENTORY_SERVICE_MODULE_NAME} from '@app/utils/permissions';
import React from 'react';
import {FaServicestack} from 'react-icons/fa';
import {TbReportAnalytics} from 'react-icons/tb';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  isSubMenu: boolean;
}

export const InventoryAdminSidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Inventory Services',
    key: 'inventory-service',
    url: '/inventory-admin/inventory-service',
    icon: <FaServicestack />,
    isSubMenu: false,
  },
  {
    title: 'Report',
    key: INVENTORY_SERVICE_MODULE_NAME.INVENTORY_REPORT,
    url: '/inventory-admin/inventory-report',
    icon: <TbReportAnalytics />,
    isSubMenu: false,
  },
];
