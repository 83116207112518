/* eslint-disable @typescript-eslint/no-explicit-any */
import instance, {HOTEL_SERVICE} from '@app/api/instance';
import webBookingInstance from '../webBookingInstance';
import {ICreatePayload} from '@app/pages/Hotel/WebsiteService/interface/interface';

export const getWebserviceAvailableRooms = (
  hotelId: number,
  {checkedIn, checkedOut, ageList, resident, adultCount, childCount, channelId, showMore, hotelType}: FilterProps,
): Promise<ReservationResponse> =>
  webBookingInstance
    .get<ReservationResponse>(
      HOTEL_SERVICE +
        `reservation/reservation/booking/engine/search?hotelId=${hotelId}&adultCount=${adultCount}&resident=${resident}&childCount=${childCount}&checkInDate=${checkedIn}&checkOutDate=${checkedOut}&channelId=${channelId}&showMore=${showMore}&allowToBookingWidjet=true&hotelType=${hotelType}`,
    )
    .then(({data}) => data);

export const getReservationById = (orderId: number): Promise<ReservationResponse> =>
  webBookingInstance.get<ReservationResponse>(HOTEL_SERVICE + `reservation/${orderId}`).then(({data}) => data);

export const createWebserviceReservation = (payload: ICreatePayload): Promise<ReservationResponse> => {
  return webBookingInstance
    .put<ReservationResponse>(HOTEL_SERVICE + 'booking/reservation/reserved-room', payload)
    .then(({data}) => data);
};

export const createCheckoutSession = (orderId: string): Promise<any> => {
  return webBookingInstance
    .post<any>(HOTEL_SERVICE + `payment-gateway-session?orderId=${orderId}`)
    .then(({data}) => data);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const retrivePayment = (payload: any): Promise<any> => {
  return webBookingInstance.post<any>(HOTEL_SERVICE + 'retrieve-order', payload).then(({data}) => data);
};

export const blockedRoomsForReservation = (payload: any): Promise<ReservationResponse> =>
  webBookingInstance
    .post<ReservationResponse>(HOTEL_SERVICE + `booking/reservation/reserved-room`, payload)
    .then(({data}) => data);

export const cancelBlockedReservation = (reservationId: number): Promise<ReservationResponse> =>
  webBookingInstance
    .put<ReservationResponse>(HOTEL_SERVICE + `reservation/cancel-booking/${reservationId}`)
    .then(({data}) => data);

export const cancelBlockedReservedRoom = (reservationRoomId: number): Promise<ReservationResponse> =>
  webBookingInstance
    .put<ReservationResponse>(HOTEL_SERVICE + `reservation/remove-guest/${reservationRoomId}`)
    .then(({data}) => data);

export const getHotelById = (hotelId: number): Promise<ReservationResponse> =>
  webBookingInstance.get<ReservationResponse>(HOTEL_SERVICE + `hotel/web-site/${hotelId}`).then(({data}) => data);

export const getTermsConditions = (policyType: string): Promise<ReservationResponse> =>
  webBookingInstance
    .get<ReservationResponse>(HOTEL_SERVICE + `terms-policy/type?policyType=${policyType}`)
    .then(({data}) => data);

export const updateTermsCondition = (reservationId: number, termsPolicy: boolean): Promise<ReservationResponse> =>
  webBookingInstance
    .put<ReservationResponse>(HOTEL_SERVICE + `terms-policy/${reservationId}?termsPolicy=${termsPolicy}`)
    .then(({data}) => data);

export interface ReservationResponse {
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
  status: string;
  statusCode: string;
  pagination: {
    pageNumber: number;
    totalRecords: number;
  };
}

interface FilterProps {
  checkedIn: string;
  checkedOut: string;
  roomTypeId: string;
  resident?: boolean;
  ageList?: number[];
  adultCount?: number;
  childCount?: number;
  channelId: number;
  showMore: boolean;
}
