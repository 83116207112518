/* eslint-disable @typescript-eslint/no-explicit-any */
import inventoryInstance, {INVENTORY_SERVICE} from '@app/api/inventoryInstance';
import {ICreateGrnProps, IGRNFilterProps} from '@app/pages/MasterPages/inventory/purchaseOrder/interface/interface';

export const createGrn = (payload: ICreateGrnProps): Promise<any> => {
  return inventoryInstance.post<any>(INVENTORY_SERVICE + 'group-grn', payload).then(({data}) => data);
};

export const createHotelGrn = (payload: ICreateGrnProps): Promise<any> => {
  return inventoryInstance
    .post<any>(INVENTORY_SERVICE + 'hotel-purchase-order/hotel-grn', payload)
    .then(({data}) => data);
};

export const getItemsByPoId = (poId: number): Promise<any> => {
  return inventoryInstance.get<any>(INVENTORY_SERVICE + `purchase-order/${poId}`).then(({data}) => data);
};
export const getAllgrns = (
  groupId: number,
  groupInventoryServiceId: number,
  {grnNumber, groupPurchaseOrderPONumber, groupPurchaseOrderSupplierName}: IGRNFilterProps,
  pageSize: number | undefined,
  current: number,
): Promise<any> => {
  return inventoryInstance
    .get<any>(
      INVENTORY_SERVICE +
        `group-grn/search?page=${current}&size=${pageSize}&sortField=id&direction=ASC&groupPurchaseOrderSupplierName=${
          groupPurchaseOrderSupplierName ? groupPurchaseOrderSupplierName : ''
        }&groupId=${groupId}&groupInventoryServiceId=${groupInventoryServiceId}&grnNumber=${
          grnNumber ? grnNumber : ''
        }&groupPurchaseOrderPONumber=${groupPurchaseOrderPONumber ? groupPurchaseOrderPONumber : ''}`,
    )
    .then(({data}) => data);
};

export const getAllgrnsByPoId = (poId: number): Promise<any> => {
  return inventoryInstance.get<any>(INVENTORY_SERVICE + `group-grn/purchase-order/${poId}`).then(({data}) => data);
};
export const getAllHotelgrnsByPoId = (poId: number): Promise<any> => {
  return inventoryInstance
    .get<any>(INVENTORY_SERVICE + `hotel-grn/hotel-purchase-order/${poId}`)
    .then(({data}) => data);
};

export const getAllHotelgrns = (
  hotelId: number,
  inventoryServiceId: number,
  {grnNumber}: any,
  pageSize: number | undefined,
  current: number,
): Promise<any> => {
  return inventoryInstance
    .get<any>(
      INVENTORY_SERVICE +
        `hotel-grn/search?page=${current}&size=${pageSize}&sortField=id&direction=ASC&hotelId=${hotelId}&inventoryServiceId=${inventoryServiceId}&grnNumber=${
          grnNumber ? grnNumber : ''
        }
        `,
    )
    .then(({data}) => data);
};

export const reportDataForPurchaseOrder = (poId: number): Promise<any> => {
  return inventoryInstance.get<any>(INVENTORY_SERVICE + `purchase-order-report/${poId}`).then(({data}) => data);
};
export const reportDataForHotelPurchaseOrder = (poId: number): Promise<any> => {
  return inventoryInstance.get<any>(INVENTORY_SERVICE + `hotel-purchase-order-report/${poId}`).then(({data}) => data);
};
export const reportDataForGRN = (poId: number): Promise<any> => {
  return inventoryInstance.get<any>(INVENTORY_SERVICE + `grn-report/${poId}`).then(({data}) => data);
};
export const reportDataForHotelGRN = (poId: number): Promise<any> => {
  return inventoryInstance.get<any>(INVENTORY_SERVICE + `hotel-grn-report/${poId}`).then(({data}) => data);
};
