import instance, {HOTEL_SERVICE} from '@app/api/instance';
import restInstance, {RESTAURANT_SERVICE} from '@app/api/resturantInstance';

export const CreateFoodItem = (payload: CreateFoodProps): Promise<FoodResponse> => {
  const foodFormData = new FormData();
  foodFormData.append('item', JSON.stringify(payload.food));
  foodFormData.append('image', payload.image);
  return restInstance.post<FoodResponse>(RESTAURANT_SERVICE + 'item', foodFormData).then(({data}) => data);
};

export const getAllFoodItems = (
  hotelId: number,
  hotelServiceId: number,
  {name, price, ticketType}: FilterProps,
  pageSize: number | undefined,
  current: number,
): Promise<FoodResponse> =>
  restInstance
    .get<FoodResponse>(
      RESTAURANT_SERVICE +
        `item/search?page=${current}&size=${pageSize}&sortField=id&direction=DESC&name=${name ? name : ''}&price=${
          price ? price : '0'
        }&ticketType=${ticketType ? ticketType : ''}&subCategory=&hotelServiceId=${hotelServiceId}`,
    )
    .then(({data}) => data);

export const UpdateFoodItem = (payload: UpdateFoodProps): Promise<FoodResponse> => {
  const roomTypeFormData = new FormData();
  roomTypeFormData.append('item', JSON.stringify(payload.room));
  roomTypeFormData.append('image', payload.image);
  return restInstance.put<FoodResponse>(RESTAURANT_SERVICE + 'item', roomTypeFormData).then(({data}) => data);
};

export const DeleteFoodItem = (id: number): Promise<FoodResponse> =>
  restInstance.delete<FoodResponse>(RESTAURANT_SERVICE + `item/${id}`).then(({data}) => data);

export const searchFoodByName = (name: string, hotelServiceId: number): Promise<any> => {
  return restInstance
    .get<any>(RESTAURANT_SERVICE + `item/search-only?hotelServiceId=${hotelServiceId}&name=${name}`)
    .then(({data}) => data);
};

export interface IFoodDataCreate {
  name: string;
  price: number;
  categoryStructureIdList: any[];
  ticketType: string;
  itemType: string;
  hotelServiceId?: number;
  active: boolean;
}
export interface CreateFoodProps {
  food: IFoodDataCreate;
  image: string;
}

export interface IFoodData {
  id: number;
  name: string;
  price: number;
  categoryStructureIdList: any[];
  ticketType: string;
  itemType: string;
  hotelServiceId?: number;
  active: boolean;
}

export interface UpdateFoodProps {
  room: IFoodData;
  image: File;
}
export interface FoodResponse {
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
  status: string;
  statusCode: string;
}
export interface FilterProps {
  name: string;
  price: string;
  ticketType: string;
}
