/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {FC} from 'react';
import * as S from '@app/pages/Hotel/ServiceReservation/ServiceReservation.style';
import {Col, Row} from 'antd';
import {BaseForm} from '../forms/BaseForm/BaseForm';
import styled from 'styled-components';
import {DatePicker} from '../pickers/DatePicker';
import {Popconfirm} from '../Popconfirm/Popconfirm';
import {Button} from '../buttons/Button/Button';

interface Props {
  handlePressRetrive: (data: any) => void;
  disabledDate: any;
  isLastServiceToRetrive: boolean;
}

const CustomDatePicker = styled(DatePicker)`
  width: 100%;
`;

const CheckoutService: FC<Props> = ({handlePressRetrive, disabledDate, isLastServiceToRetrive}) => {
  const [bulkForm] = BaseForm.useForm();

  const handleSubmit = () => {
    const formData = bulkForm.getFieldsValue();
    handlePressRetrive(formData);
  };

  return (
    <S.BlurCardWrapper style={{marginBottom: '1rem'}}>
      <S.RedCard>
        <S.Padding>
          <BaseForm onFinish={handleSubmit} form={bulkForm} size="middle">
            <Row align="middle" gutter={{xs: 10, md: 15, xl: 30}}>
              <Col xs={24} md={16} xl={8}>
                <BaseForm.Item
                  name="checkOutDate"
                  label="Retrieve Date"
                  rules={[{required: true, message: 'Date is required'}]}>
                  <CustomDatePicker disabledDate={disabledDate} />
                </BaseForm.Item>
              </Col>
              <Col xs={24} md={16} xl={8}>
                <Popconfirm
                  placement="topRight"
                  title="Are you sure to retrive all services?"
                  onConfirm={() => bulkForm.submit()}
                  okText="Yes"
                  cancelText="No">
                  <Button disabled={isLastServiceToRetrive} danger type="primary">
                    Retrive All
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
            {/* <Row justify="end" gutter={{xs: 10, md: 15, xl: 30}}>
              
            </Row> */}
          </BaseForm>
        </S.Padding>
      </S.RedCard>
    </S.BlurCardWrapper>
  );
};

export default CheckoutService;
