import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  selectedCat: '',
  breadCrumbCat: '',
  mainCat: '',
  foodCat: '',
};

export const fooDcatSlice = createSlice({
  name: 'fcs',
  initialState,
  reducers: {
    setSelectedCat: (state, action) => {
      state.selectedCat = action.payload;
    },
    setSelectedBreadCrumbCat: (state, action) => {
      state.breadCrumbCat = action.payload;
    },
    setMainCat: (state, action) => {
      state.mainCat = action.payload;
    },
    setFoodCat: (state, action) => {
      state.foodCat = action.payload;
    },
  },
});

export const {setSelectedCat, setSelectedBreadCrumbCat, setMainCat, setFoodCat} = fooDcatSlice.actions;

export default fooDcatSlice.reducer;
