import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {notificationController} from '@app/controllers/notificationController';
import {useAppDispatch} from '@app/hooks/reduxHooks';
import {doSetNewPassword} from '@app/store/slices/authSlice';
import * as S from './NewPasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import {ResetPasswordProps, resetPassword} from '@app/api/user/user.api';

interface NewPasswordFormData {
  token: string;
  password: string;
  confirmPassword: string;
}
interface Props {
  token: string;
}
const initStates = {
  password: '',
  confirmPassword: '',
};

export const NewPasswordForm: React.FC<Props> = (props: Props) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {token} = props;

  const [isLoading, setLoading] = useState(false);

  const resetUserPassword = async (payload: ResetPasswordProps) => {
    try {
      const request: NewPasswordFormData = {
        token: token,
        password: payload.password,
        confirmPassword: payload.password,
      };
      const result = await resetPassword(request);
      setLoading(false);
      if (result.statusCode === '20000') {
        notificationController.success({message: result.message});
        // await onCloseModal();
        navigate('/auth/login/user');
      } else {
        notificationController.error({message: result.message});
        // dispatch(setLoading(false));
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = (values: NewPasswordFormData) => {
    setLoading(true);
    resetUserPassword(values);
    // dispatch(doSetNewPassword({newPassword: values.password}))
    //   .unwrap()
    //   .then(() => {
    //     navigate('/auth/login');
    //     notificationController.success({
    //       message: t('newPassword.successMessage'),
    //       description: t('newPassword.successDescription'),
    //     });
    //   })
    //   .catch(err => {
    //     notificationController.error({message: err.message});
    //     setLoading(false);
    //   });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initStates}>
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('newPassword.title')}</Auth.FormTitle>
        <S.Description>{t('newPassword.description')}</S.Description>
        <Auth.FormItem
          name="password"
          label={t('common.password')}
          rules={[{required: true, message: t('common.requiredField')}]}>
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="confirmPassword"
          label={t('common.confirmPassword')}
          dependencies={['password']}
          rules={[
            {required: true, message: t('common.requiredField')},
            ({getFieldValue}) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('common.confirmPasswordError')));
              },
            }),
          ]}
          hasFeedback>
          <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.resetPassword')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
