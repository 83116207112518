import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {doLogin} from '@app/store/slices/authSlice';
import {notificationController} from '@app/controllers/notificationController';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import {ERole} from '@app/utils/utils';
import {setHotelConfig, setHotelServiceConfig} from '@app/store/slices/hotelSlice';
import {PALMYRAH_HOUSE} from '@app/assets';
import {
  getAllPermissionByUserId,
  getAllServicePermissionByUserId,
  getUserPermit,
} from '@app/api/privilege/serviceRolePrivilege/serviceRolePrivilege.api';
import {setPermission} from '@app/store/slices/userSlice';
import {
  EPermissionType,
  HOTEL_SERVICE_MODULE_NAV_PATH,
  HOUSEKEEPING_ADMIN_MODULE_NAME,
  HOUSEKEEPING_ADMIN_MODULE_NAV_PATH,
  INVENTORY_SERVICE_MODULE_NAME,
  IPermission,
  RESTAURANT_ADMIN_MODULE_NAME,
  RESTAURANT_MASTER_MODULE_NAV_PATH,
  SERVICE_ADMIN_MODULE_NAV_PATH,
  getActiveNavPath,
  modulePermission,
} from '@app/utils/permissions';
import {Link} from 'react-router-dom';
import {getCategoryStuctureByID} from '@app/api/resturant/tablecategory/categoryStucture.api';
import {resetMenuItem, setCategoryData} from '@app/pages/Restaurant/slices/categoryNavSlice';
import {IHotelServiceDataRes} from '@app/pages/MasterPages/hotel/groupAdmin/hotelService/HotelServicePage';

interface LoginFormData {
  email: string;
  password: string;
}

export const initValues: LoginFormData = {
  email: '',
  password: '',
};

interface IProps {
  defaultUserName?: string;
}

export const LoginForm: React.FC<IProps> = props => {
  const {defaultUserName} = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [initialFormValue, setInitialFormValue] = useState<LoginFormData>(initValues);

  const [form] = BaseForm.useForm();

  const getServicePermissionUser = async (userId: number) => {
    const result = await getAllPermissionByUserId(userId);
    const servicespermission = result.result.role;
    const convertedPermission: IPermission[] = servicespermission.map((post: any, index: number) => {
      return {
        id: post.id,
        name: post.name,
        prefix: post.prefix,
        status:
          post.status === 'READ'
            ? EPermissionType.READ
            : post.status === 'WRITE'
            ? EPermissionType.WRITE
            : post.status === 'MAINTAIN'
            ? EPermissionType.MAINTAIN
            : EPermissionType.NO_PERMISSION,
        active: post.active,
      };
    });

    dispatch(setPermission(convertedPermission));
  };

  const handleSubmit = (values: LoginFormData) => {
    setLoading(true);

    dispatch(doLogin(values))
      .unwrap()
      .then(async res => {
        if (res.user.userLevel === 'SERVICE') {
          try {
            await getServicePermissionUser(res.user.userId);
            const result = await getUserPermit(res.user.userId);
            const groupDatails = result.result.user;

            Promise.all([
              dispatch(
                setHotelConfig({
                  hotelId: groupDatails.hotelResponse.id,
                  title: groupDatails.hotelResponse.name,
                  headerLogo: groupDatails.hotelResponse.sideBarImage,
                  sideBarLogo: groupDatails.hotelResponse.titleImage,
                  groupId: groupDatails.groupResponse.id,
                  groupTitle: groupDatails.groupResponse.name,
                  hotelType: groupDatails.hotelResponse.type,
                  groupHeaderLogo: 'https://www.serendipityretreats.com/assets/frontend/images/mainlogo.png',
                  theme: {},
                  userLevel: res.user.userLevel,
                }),
              ),
              navigate('/toplevel/services'),
            ]);
          } catch (error) {
            navigate('/toplevel/products');
          }
        } else if (res.user.userLevel === 'HOTEL' || res.user.userLevel === 'GROUP') {
          try {
            await getServicePermissionUser(res.user.userId);
            const result = await getUserPermit(res.user.userId);
            const groupDatails = result.result.user;

            Promise.all([
              dispatch(
                setHotelConfig({
                  hotelId: groupDatails.hotelResponse ? groupDatails.hotelResponse.id : 0,
                  title: groupDatails.hotelResponse ? groupDatails.hotelResponse.name : '',
                  headerLogo: groupDatails?.hotelResponse?.sideBarImage,
                  sideBarLogo: groupDatails?.hotelResponse?.titleImage,
                  groupId: groupDatails.groupResponse ? groupDatails?.groupResponse.id : 0,
                  groupTitle: groupDatails?.groupResponse?.name,
                  hotelType: groupDatails?.hotelResponse?.type,
                  groupHeaderLogo: 'https://www.serendipityretreats.com/assets/frontend/images/mainlogo.png',
                  theme: {},
                  userLevel: res.user.userLevel,
                }),
              ),
              navigate('/toplevel/products'),
            ]);
          } catch (error) {
            navigate('/toplevel/products');
          }
        } else if (res.user.userLevel === 'USER') {
          try {
            const result = await getUserPermit(res.user.userId);
            const groupDatails = result.result.user;

            Promise.all([
              dispatch(
                setHotelConfig({
                  hotelId: groupDatails.hotelResponse.id,
                  title: groupDatails.hotelResponse.name,
                  headerLogo: groupDatails.hotelResponse.sideBarImage,
                  sideBarLogo: groupDatails.hotelResponse.titleImage,
                  groupId: groupDatails.groupResponse.id,
                  groupTitle: groupDatails.groupResponse.name,
                  hotelType: groupDatails.hotelResponse.type,
                  groupHeaderLogo: 'https://www.serendipityretreats.com/assets/frontend/images/mainlogo.png',
                  theme: {},
                  userLevel: res.user.userLevel,
                }),
              ),
              dispatch(
                setHotelServiceConfig({
                  name: groupDatails.hotelServiceResponse.name,
                  serviceId: groupDatails.hotelServiceResponse.id,
                  serviceType: groupDatails.hotelServiceResponse.serviceType,
                }),
              ),
              getServicePermission(
                groupDatails.userId,
                groupDatails.hotelServiceResponse.id,
                groupDatails.hotelServiceResponse.serviceType,
              ),
            ]);
          } catch (error) {
            navigate('/toplevel/products');
          }
        } else {
          await getServicePermissionUser(res.user.userId);
          dispatch(
            setHotelConfig({
              hotelId: 0,
              title: '',
              headerLogo: '',
              sideBarLogo: '',
              groupId: 0,
              groupTitle: '',
              hotelType: '',
              groupHeaderLogo: 'https://www.serendipityretreats.com/assets/frontend/images/mainlogo.png',
              theme: {},
              userLevel: '',
            }),
          );
          navigate('/toplevel/groups');
        }
      })
      .catch(err => {
        notificationController.error({message: 'Username or Password is incorrect'});
        setLoading(false);
      });
  };

  const getCategoryById = async (
    id: number,
    isStuart: boolean,
    isChef: boolean,
    serviceId: number,
    convertedPermission: IPermission[],
  ) => {
    const result = await getCategoryStuctureByID(id, serviceId);

    const data: any[] = [];

    result.result.category &&
      result.result.category.map((post: any, i: any) => {
        data.push({
          id: post.id,
          backgroundImage: post.categoryImage,
          title: post.categoryTitle,
          children: [],
          expanded: post.expanded,
          categoryId: post.categoryId,
        });
      });
    dispatch(resetMenuItem({}));
    const activeNavPath: string = getActiveNavPath(convertedPermission, {
      ...RESTAURANT_MASTER_MODULE_NAV_PATH,
      ...SERVICE_ADMIN_MODULE_NAV_PATH,
    });

    Promise.all([
      dispatch(setCategoryData({items: data})),
      navigate(isStuart ? '/category/main' : isChef ? '/toplevel/chef-dashboard' : activeNavPath, {state: data}),
      navigate(
        isStuart
          ? '/category/main'
          : isChef
          ? '/toplevel/chef-dashboard'
          : !isStuart && !isChef
          ? activeNavPath
          : activeNavPath,
        {state: data},
      ),
    ]);
  };

  const getServicePermission = async (userId: number, serviceId: number, serviceType: string) => {
    // navigate('/reservations', {state: data}); EPermissionType
    const result = await getAllServicePermissionByUserId(userId, serviceId);
    const servicespermission = result.result.servicespermission;
    const convertedPermission: IPermission[] = servicespermission.map((post: any, index: number) => {
      return {
        id: post.id,
        name: post.name,
        prefix: post.prefix,
        status:
          post.status === 'READ'
            ? EPermissionType.READ
            : post.status === 'WRITE'
            ? EPermissionType.WRITE
            : post.status === 'MAINTAIN'
            ? EPermissionType.MAINTAIN
            : EPermissionType.NO_PERMISSION,
        active: post.active,
      };
    });
    if (serviceType === 'RESTAURANT') {
      const permissionsStuart = modulePermission(convertedPermission, RESTAURANT_ADMIN_MODULE_NAME.FOOD_MENU);
      const permissionsChef = modulePermission(convertedPermission, RESTAURANT_ADMIN_MODULE_NAME.CHEF_DASHBOARD);
      const isStuart = permissionsStuart.VIEW;
      const isChef = permissionsChef.VIEW && !isStuart;

      Promise.all([
        dispatch(setPermission(convertedPermission)),
        getCategoryById(0, isStuart, isChef, serviceId, convertedPermission),
      ]);
    } else if (serviceType === 'HOTEL') {
      const activeNavPath: string = getActiveNavPath(convertedPermission, {
        ...HOTEL_SERVICE_MODULE_NAV_PATH,
        ...SERVICE_ADMIN_MODULE_NAV_PATH,
      });

      if (activeNavPath === undefined) {
        setLoading(false);

        notificationController.error({message: 'No permission'});
      }
      Promise.all([dispatch(setPermission(convertedPermission)), navigate(activeNavPath, {state: {}})]);
    } else if (serviceType === 'HOUSEKEEPING') {
      const permissionsHouseKeeper = modulePermission(convertedPermission, HOUSEKEEPING_ADMIN_MODULE_NAME.HOUSEKEEPING);
      const isHouseKeeper = permissionsHouseKeeper.VIEW;
      const activeNavPath: string = getActiveNavPath(convertedPermission, {
        ...HOUSEKEEPING_ADMIN_MODULE_NAV_PATH,
        ...SERVICE_ADMIN_MODULE_NAV_PATH,
      });

      Promise.all([
        dispatch(setPermission(convertedPermission)),
        navigate(isHouseKeeper ? '/toplevel/housekeepercard' : activeNavPath, {state: {}}),
      ]);
    } else if (serviceType === 'INVENTORY') {
      const permissionsGroupInventoryAdmin = modulePermission(
        convertedPermission,
        INVENTORY_SERVICE_MODULE_NAME.PURCHASE_ORDER,
      );
      const groupInventoryAdmin = permissionsGroupInventoryAdmin.VIEW;
      const activeNavPath: string = getActiveNavPath(convertedPermission, {
        ...INVENTORY_SERVICE_MODULE_NAME,
      });

      Promise.all([
        dispatch(setPermission(convertedPermission)),
        navigate(groupInventoryAdmin ? '/toplevel/services' : '/toplevel/services', {state: {}}),
      ]);
    } else {
    }
  };

  useEffect(() => {
    if (defaultUserName) {
      if (defaultUserName === 'user') {
        setInitialFormValue({
          email: '',
          password: '',
        });
      } else {
        const decodedUsername = atob(`${defaultUserName}`);
        form.setFieldValue('email', decodedUsername);
      }
    }
  }, []);

  return (
    <Auth.FormWrapper>
      <BaseForm
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
        initialValues={initialFormValue}>
        <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
        <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            {required: true, message: t('common.requiredField')},
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}>
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{required: true, message: t('common.requiredField')}]}>
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <S.RememberMeText>{t('login.rememberMe')}</S.RememberMeText>
            </Auth.FormCheckbox>
          </BaseForm.Item>
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
