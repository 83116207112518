import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';

export interface CommonState {
  loading: boolean;
  modalVisible: boolean;
  isEditAction: boolean;
  isTouch: boolean;
  isClear: boolean;
}

const initialState: CommonState = {
  loading: false,
  modalVisible: false,
  isEditAction: false,
  isTouch: false,
  isClear: false,
};

export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setModalVisible: (state, action: PayloadAction<boolean>) => {
      state.modalVisible = action.payload;
      state.isEditAction = !action.payload ? false : state.isEditAction;
      state.isTouch = false;
    },
    setIsEditAction: (state, action: PayloadAction<boolean>) => {
      state.isEditAction = action.payload;
    },
    setIsTouchAction: (state, action: PayloadAction<boolean>) => {
      state.isTouch = action.payload;
    },
    setIsClear: (state, action: PayloadAction<boolean>) => {
      state.isClear = action.payload;
    },
  },
});

export const {setLoading, setModalVisible, setIsEditAction, setIsTouchAction, setIsClear} = commonSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const loading = (state: RootState) => state.commonSlice.loading;
export const modalVisible = (state: RootState) => state.commonSlice.modalVisible;

export default commonSlice.reducer;
