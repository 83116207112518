/* eslint-disable react/jsx-key */
import TopLevelCard from '@app/components/cards/TopLevelCard';
import styled from 'styled-components';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {Maintening, hotelImage, houseKeeping, rest} from '@app/assets';
import {TopLevelCardProps} from '../Restaurant/Interfaces';
import {getInventoryServicesByHotelId} from '@app/api/inventory/inventoryService.api';
import {
  IInventoryServiceData,
  IInventoryServiceDataRes,
} from '../MasterPages/inventory/inventoryAdmin/InventoryServicePage';
import {setServiceConfig} from '@app/store/slices/serviceSlice';
import {INVENTORY_SERVICE_MODULE_NAV_PATH, getActiveNavPath} from '@app/utils/permissions';

export const FadeIn = styled.div`
  animation: fadeInAnimation 1s linear;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
const ProductsContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
`;

const ProductData: TopLevelCardProps[] = [
  {
    name: 'Restaurant Inventory',
    logo: rest,
    bgUrl:
      'https://images.pexels.com/photos/5498218/pexels-photo-5498218.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  },
  {
    name: 'Hotel Inventory',
    logo: hotelImage,
    bgUrl: 'https://images.pexels.com/photos/4481327/pexels-photo-4481327.jpeg?auto=compress&cs=tinysrgb&w=600',
  },
  {
    name: 'Housekeeping Inventory',
    logo: houseKeeping,
    bgUrl: 'https://images.pexels.com/photos/271711/pexels-photo-271711.jpeg?auto=compress&cs=tinysrgb&w=600',
  },
  {
    name: 'Maintenance Inventory',
    logo: Maintening,
    bgUrl: 'https://www.supplymint.com/blogs/wp-content/uploads/2022/05/warehouse-management-software.png',
  },
];

const InventoryServiceLevelHome = () => {
  const navigate = useNavigate();
  const [inventoryServices, setInventoryServices] = useState([]);
  const hotelConfig = useAppSelector(state => state.hotelSlice.hotelConfig);
  const userPermission = useAppSelector(state => state.user.permissions);
  const dispatch = useAppDispatch();
  const onChangeCard = (data: IInventoryServiceData) => {
    dispatch(
      setServiceConfig({
        inventoryServiceId: data.id,
        active: true,
        cardImage: data.cardImage,
        cardLogo: data.cardLogo,
        hotelId: hotelConfig.hotelId,
        name: data.name,
        serviceType: data.serviceType,
        groupId: hotelConfig.groupId,
        groupInventoryServiceId: data?.groupInventoryServiceId,
      }),
    );

    const activeNavPath: string = getActiveNavPath(userPermission, {
      ...INVENTORY_SERVICE_MODULE_NAV_PATH,
    });

    if (data.name === 'Restaurant Inventory') {
      navigate(activeNavPath, {state: data});
    } else {
      navigate(activeNavPath, {state: data});
    }
  };

  useEffect(() => {
    getInventoryServices();
  }, []);

  const getInventoryServices = async () => {
    try {
      const results = await getInventoryServicesByHotelId(hotelConfig.hotelId);
      setInventoryServices(results.result.inventoryService);
    } catch (error) {}
  };

  return (
    <ProductsContainer>
      {/* <Reveal effect="fadeInUp"> */}
      {inventoryServices.map((post: IInventoryServiceData, i: number) => {
        return (
          <FadeIn onClick={() => onChangeCard(post)}>
            <TopLevelCard key={i} bgUrl={post.cardImage} logo={post.cardLogo} name={post.name} />
          </FadeIn>
        );
      })}

      {/* </Reveal> */}
    </ProductsContainer>
  );
};

export default InventoryServiceLevelHome;
