import React, {useEffect, useState} from 'react';
import * as S from './Chart.Style';
import {Tables} from '@app/components/tables/Tables/Tables';
import {BasicTableRow} from '@app/api/table.api';
import {ColumnsType} from 'antd/lib/table';
import {Space, Tag} from 'antd';
import {BasicTable} from '@app/components/tables/BasicTable/BasicTable';
import {ArrowDownOutlined, ArrowUpOutlined, CloseCircleOutlined, LineChartOutlined} from '@ant-design/icons';
import './ProductTable.Style.css';
import {Modal} from '@app/components/common/Modal/Modal';
import StatisticsGraph from './StatisticsGraph';
import {useAppSelector} from '@app/hooks/reduxHooks';
import {getAllItemReOrderLevel, getAllItemReOrderLevelForGroup} from '@app/api/inventory/dashboard.api';

export interface ProductTableRow {
  key: number;
  locationName: string;
  itemName: string;
  quantity: number;
  addOnStatus: boolean;
  reorderLevel: number;
}

const ProductItems = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableRecord, setTableRecord] = useState<ProductTableRow | null>(null);
  const [items, setItems] = useState([]);
  const hotelConfig = useAppSelector(state => state.hotelSlice.hotelConfig);
  const {inventoryServiceId} = useAppSelector(state => state.serviceSlice.serviceConfig);

  const listItems = async () => {
    try {
      const response = await getAllItemReOrderLevel(hotelConfig.hotelId, inventoryServiceId);
      setItems(response?.result?.item);
    } catch (error) {}
  };

  const getItemsForReorderLevel = async () => {
    try {
      const response = await getAllItemReOrderLevelForGroup(hotelConfig.groupId, inventoryServiceId);
      setItems(response?.result?.item);
    } catch (error) {}
  };

  useEffect(() => {
    if (hotelConfig.hotelId === 0) {
      getItemsForReorderLevel();
    } else {
      listItems();
    }
  }, []);

  const columns: ColumnsType<ProductTableRow> = [
    {
      title: 'Item',
      dataIndex: 'itemName',
    },
    {
      title: 'Location',
      dataIndex: 'locationName',
    },

    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
      align: 'center',
      sorter: (a: ProductTableRow, b: ProductTableRow) => a.quantity - b.quantity,
      render: (_, {quantity, addOnStatus}: any) => (
        <>
          <Space>
            <Tag color={'blue'} key={quantity}>
              {quantity}
            </Tag>
            {addOnStatus ? <ArrowUpOutlined style={{color: 'green'}} /> : <ArrowDownOutlined style={{color: 'red'}} />}
          </Space>
        </>
      ),
    },
    {
      title: 'Re-Order Level',
      dataIndex: 'reorderLevel',
      align: 'center',
      sorter: (a: ProductTableRow, b: ProductTableRow) => a.reorderLevel - b.reorderLevel,
    },

    // {
    //   title: 'Statistics',
    //   dataIndex: 'actions',
    //   width: '15%',
    //   render: (text: string, record: ProductTableRow) => {
    //     return (
    //       <Space>
    //         <LineChartOutlined onClick={() => showModal(record)} />
    //       </Space>
    //     );
    //   },
    //   align: 'center',
    // },
  ];

  const rowClassName = (record: ProductTableRow) => {
    return record.quantity < record.reorderLevel ? 'highlight-row' : '';
  };

  const showModal = (record: ProductTableRow) => {
    setIsModalOpen(true);
    setTableRecord(record);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setTableRecord(null);
  };

  const modalStyle = {
    top: 20,
  };

  return (
    <>
      <S.TableWrapper>
        <BasicTable tableData={items} columns={columns} rowClassName={rowClassName} />
      </S.TableWrapper>
      <Modal
        size="large"
        closeIcon={<CloseCircleOutlined style={{color: 'red'}} />}
        title={`Statistics Product Quantity Of ${tableRecord?.itemName}`}
        open={isModalOpen}
        okButtonProps={{size: 'small'}}
        cancelButtonProps={{size: 'small'}}
        onOk={handleOk}
        style={modalStyle}
        onCancel={handleCancel}
        footer={false}>
        <StatisticsGraph />
      </Modal>
    </>
  );
};

export default ProductItems;
