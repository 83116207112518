/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useRef, useMemo, memo} from 'react';
import {Input, InputRef, Space, TablePaginationConfig} from 'antd';
import {Table} from 'components/common/Table/Table';
import {ColumnType} from 'antd/es/table';
import {Button} from 'components/common/buttons/Button/Button';
import {useTranslation} from 'react-i18next';
import {useMounted} from '@app/hooks/useMounted';
import {SearchOutlined} from '@ant-design/icons';
import {ExpandableConfig, FilterConfirmProps, TableRowSelection} from 'antd/lib/table/interface';
import Highlighter from 'react-highlight-words';
import {find, isEmpty} from 'lodash';
import './basicTable.less';
import {DatePicker} from '@app/components/common/pickers/DatePicker';

interface Props {
  tableData?: any[];
  columns?: any[];
  rowClassName?: string;
  size?: 'small' | 'middle' | 'large';
  bordered?: boolean;
  pagination?: TablePaginationConfig;
  showPagination?: boolean;
  searchFields?: string[];
  searchPlaceholderNames?: any;
  onChangeFilter?: (pagination: TablePaginationConfig, filters: any) => void;
  onChangeSearch?: (values: any) => void;
  setSearchObject?: () => void;
  searchObject?: any;
  components?: any;
  expandable?: ExpandableConfig<any>;
  rowSelection?: TableRowSelection<any>;
  scoroll?: boolean;
  scorollValues?: {x?: number; y?: number};
  className?: string;
  summary?: any;
  loading?: boolean;
}

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
}

type DataIndex = keyof DataType;

export const BasicTable: React.FC<Props> = memo(props => {
  const {
    tableData,
    columns,
    rowClassName,
    size = 'small',
    bordered = true,
    pagination,
    showPagination = true,
    searchFields = [],
    searchPlaceholderNames = {},
    onChangeFilter,
    onChangeSearch,
    components,
    expandable,
    rowSelection,
    scoroll = true,
    className,
    scorollValues = {x: 800},
    summary,
    loading,
  } = props;

  const {t} = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [searchObj, setSearchObj]: any = useState();
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    // confirm();
    // setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void, dataIndex: string) => {
    const obj = {...searchObj};

    obj[dataIndex] = '';

    onChangeSearch && onChangeSearch(obj);
    clearFilters();
    setSearchText('');
    setSearchedColumn('');
  };

  const getPlaceholderName = (dataIndex: DataIndex) =>
    searchPlaceholderNames[dataIndex] ? searchPlaceholderNames[dataIndex] : dataIndex;

  const getColumnSearchProps = (dataIndex: DataIndex, columnType: string): ColumnType<DataType> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}: any) => {
      const isDateType = columnType === 'date';
      return (
        <div style={{padding: 8, marginTop: '-48%'}} className="search-area" onKeyDown={e => e.stopPropagation()}>
          {isDateType ? (
            <DatePicker
              value={selectedKeys[0]}
              onChange={date => {
                setSelectedKeys(date ? [date] : []);
                const obj: any = {};
                obj[dataIndex] = date ? date.format('YYYY-MM-DD') : '';
                onChangeSearch && onChangeSearch(obj);
              }}
              style={{marginBottom: 8, display: 'block', padding: '6.4px 11px'}}
            />
          ) : (
            <Input
              ref={searchInput}
              placeholder={`Search ${getPlaceholderName(dataIndex)}`}
              value={selectedKeys[0]}
              onChange={(e: any) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                const obj: any = {};

                obj[dataIndex] = e.target.value;

                onChangeSearch && onChangeSearch(obj);
              }}
              style={{marginBottom: 8, display: 'block', padding: '6.4px 11px'}}
            />
          )}
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              // icon={<SearchOutlined style={{fontSize: 15}} />}
              size="small"
              style={{width: 70, padding: '4px 16px', borderRadius: 6}}>
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters && handleReset(clearFilters, dataIndex);
              }}
              size="small"
              style={{width: 70, padding: '4px 16px', borderRadius: 6}}>
              Reset
            </Button>

            <Button
              size="small"
              style={{width: 70, padding: '4px 16px', borderRadius: 6}}
              onClick={() => {
                close();
              }}>
              Close
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const updatedColumn = useMemo(
    () =>
      columns?.map(post => {
        return !isEmpty(post) && find(searchFields, res => res === post.dataIndex)
          ? {
              ...post,
              ...getColumnSearchProps(post.dataIndex, post.columnType),
            }
          : post;
      }),
    [],
  );

  return (
    <div className="table-wrapper">
      <Table
        rowClassName={rowClassName}
        columns={updatedColumn}
        dataSource={tableData}
        scroll={scoroll ? scorollValues : {}}
        bordered={bordered}
        size={size}
        style={{width: '100%', borderRadius: '10px'}}
        pagination={showPagination ? {...pagination, size: 'small'} : false}
        onChange={onChangeFilter}
        components={components}
        expandable={expandable}
        rowSelection={rowSelection}
        className={className}
        loading={loading}
      />
    </div>
  );
});
