import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {getAllGroups} from '@app/api/groupAdmin/group/hotelGroup.api';
import {IGroupDataRes} from '@app/pages/MasterPages/hotel/groupAdmin/group/GroupPage';
import {IHotelData} from '@app/pages/MasterPages/hotel/groupAdmin/hotel/HotelPage';
import {getAllHotelsByGroupId} from '@app/api/groupAdmin/hotel/hotel.api';
import {IHotelServiceData} from '@app/pages/MasterPages/hotel/groupAdmin/hotelService/HotelServicePage';
import {getHotelServicesByHotelId} from '@app/api/groupAdmin/hotelService/hotelService.api';
import {Notification, notifications as fetchedNotifications} from '@app/api/notifications.api';

export interface IHotelConfig {
  title: string;
  headerLogo?: string;
  sideBarLogo?: string;
  hotelId: number;
  theme?: any;
  groupHeaderLogo?: string;
  groupId: number;
  groupTitle: string;
  hotelType?: string;
  userLevel: string;
}
export interface IHotelServiceConfig {
  name: string;
  serviceId: number;
  serviceType: 'RESTAURANT' | 'HOTEL' | 'INVENTORY' | 'HOUSEKEEPING' | 'NO_SERVICE';
}

export interface HotelState {
  hotelConfig: IHotelConfig;
  hotelServiceConfig: IHotelServiceConfig;
  hotelGroups: IGroupDataRes[];
  hotels: IHotelData[];
  hotelServices: IHotelServiceData[];
  isServiceLevel: boolean;
  hotelNotifications: Notification[];
}

interface IGroupId {
  id: number;
}

interface IHotelId {
  id: number;
  hotelNotifications: Notification[];
}

const initialState: HotelState = {
  hotelConfig: {
    title: '',
    headerLogo: '',
    sideBarLogo: '',
    hotelId: 0,
    theme: {},
    groupId: 0,
    groupTitle: '',
    hotelType: '',
    userLevel: '',
  },
  hotelServiceConfig: {name: '', serviceId: 0, serviceType: 'NO_SERVICE'},
  hotelGroups: [],
  hotels: [],
  hotelServices: [],
  isServiceLevel: false,
  hotelNotifications: [],
};

export const getGroups = createAsyncThunk('hotelConfig/getGroups', async (payload, {dispatch}) => {
  const results = await getAllGroups();
  dispatch(setHotelGroups(results.result.group));
  return results.result.group;
});

export const getHotelsByGroupId = createAsyncThunk(
  'hotelConfig/getHotelsByGroupId',
  async (payload: IGroupId, {dispatch}) => {
    const results = await getAllHotelsByGroupId(payload.id);
    dispatch(setHotels(results.result.hotel));
    return results.result.hotel;
  },
);

export const getHotelServiceByHotelId = createAsyncThunk(
  'hotelConfig/getHotelServicesByHotelId',
  async (payload: IGroupId, {dispatch}) => {
    const results = await getHotelServicesByHotelId(payload.id);

    // setHotelServices(results.result.hotelService);
    dispatch(setHotelServices(results.result.hotel));
    return results.result.hotelService;
  },
);

export const getNotificationByHotelId = createAsyncThunk(
  'hotelConfig/getNotificationByHotelId',
  async (payload: IHotelId, {dispatch}) => {
    const results = await getAllHotelsByGroupId(payload.id);

    const min = 1; // Minimum value for the random number
    const max = 100; // Maximum value for the random number
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    const obj = {
      id: randomNum,
      description: 'header.notifications.successPayment',
    };
    const x = [...payload.hotelNotifications];
    x.push(obj);
    dispatch(setHotelNotifications(x));
    return results.result.hotel;
  },
);

export const hotelSlice = createSlice({
  name: 'hotelConfig',
  initialState,
  reducers: {
    setHotelConfig: (state, action: PayloadAction<IHotelConfig>) => {
      state.hotelConfig = action.payload;
    },
    setHotelServiceConfig: (state, action: PayloadAction<IHotelServiceConfig>) => {
      state.hotelServiceConfig = action.payload;
    },
    setHotelGroups: (state, action: PayloadAction<IGroupDataRes[]>) => {
      state.hotelGroups = action.payload;
    },
    setHotels: (state, action: PayloadAction<IHotelData[]>) => {
      state.hotels = action.payload;
    },
    setHotelServices: (state, action: PayloadAction<IHotelServiceData[]>) => {
      state.hotelServices = action.payload;
    },
    setIsServiceLevel: (state, action: PayloadAction<boolean>) => {
      state.isServiceLevel = action.payload;
    },
    setHotelNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.hotelNotifications = action.payload;
    },
  },
});

export const {
  setHotelConfig,
  setHotelServiceConfig,
  setHotelGroups,
  setHotels,
  setHotelServices,
  setIsServiceLevel,
  setHotelNotifications,
} = hotelSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const hotelConfig = (state: RootState) => state.hotelSlice.hotelConfig;

export default hotelSlice.reducer;
