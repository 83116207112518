/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Modal} from '@app/components/common/Modal/Modal';
import React, {useEffect, useState} from 'react';
import {ICancelPayload, IRetiveModal} from '../interface';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from '../ServiceReservation.style';
import {Col, Popconfirm, Row} from 'antd';
import {Descriptions} from 'antd';
import {TextArea} from '@app/components/common/inputs/Input/Input';
import {convertNumberFormatWithDecimal} from '@app/utils/utils';
import {Button} from '@app/components/common/buttons/Button/Button';
import {
  cancelAdditionalBooking,
  getServiceBookingsByBookingId,
} from '@app/api/hotel/additionalBookings/additionalBookings.api';
import {notificationController} from '@app/controllers/notificationController';
import {BASE_COLORS} from '@app/styles/themes/constants';
import {ConvertedDataProps} from '../helper/interface';

export default function CancelModal({open, handleCancelModal, data, reloadData}: IRetiveModal) {
  const [bulkCancelForm] = BaseForm.useForm();
  const [singleCancelForms] = BaseForm.useForm();

  // @ts-ignore
  const [bookingData, setbookingData] = useState<ConvertedDataProps>({});

  const cancelBooking = async (service: any) => {
    const formData = singleCancelForms.getFieldsValue();
    const reason = formData[`reason-${service.id}`];

    const payload: ICancelPayload[] = [
      {
        mainReservationId: data?.id,
        additionalServiceId: service?.additionalServiceId,
        cancelReason: reason,
        reservedServiceId: service?.id,
      },
    ];
    try {
      const response = await cancelAdditionalBooking(payload);

      if (response.statusCode === '20000') {
        notificationController.success({message: response.message});
        reloadData();
        getServiceBookingsById(data?.id);
      } else {
        notificationController.error({message: response.message});
      }
    } catch (error) {}
  };

  const getServiceBookingsById = async (id: number) => {
    try {
      const response = await getServiceBookingsByBookingId(id);
      setbookingData(response.result.additionalServiceReservation);
    } catch (error) {}
  };

  const handleBulkCancelSubmit = async (values: any) => {
    const reasons = values.reason;
    const payload: ICancelPayload[] =
      bookingData.additionalServiceSingleReservationResponseList
        ?.filter(service => service.additionalServiceReservationStatus === 'BOOKED')
        .map(service => ({
          mainReservationId: data?.id,
          additionalServiceId: service?.additionalServiceId,
          cancelReason: reasons,
          reservedServiceId: service?.id,
        })) || [];

    try {
      const response = await cancelAdditionalBooking(payload);

      if (response.statusCode === '20000') {
        notificationController.success({message: response.message});
        reloadData();
        handleCancelModal();
      } else {
        notificationController.error({message: response.message});
      }
    } catch (error) {}
  };

  useEffect(() => {
    open && getServiceBookingsById(data?.id);
  }, [data, open]);

  const currency = bookingData && bookingData?.resident ? 'LKR' : 'USD';
  const bookedServices =
    bookingData &&
    bookingData?.additionalServiceSingleReservationResponseList?.filter(
      service => service.additionalServiceReservationStatus === 'BOOKED',
    );

  return (
    <Modal footer={null} title="Service Cancellation" size="large" open={open} onCancel={handleCancelModal}>
      <S.FormContent>
        <S.BlurCardWrapper>
          <S.BlueCard>
            <S.Padding>
              {bookingData ? (
                <Descriptions>
                  <Descriptions.Item label="Booking No">{bookingData?.refNumber}</Descriptions.Item>
                  {bookingData?.additionalServiceForReservedRoomResponseList?.reservedRoomNumber ? (
                    <Descriptions.Item label="Reservation No">
                      {bookingData?.additionalServiceForReservedRoomResponseList?.reservationRefNumber
                        ? bookingData?.additionalServiceForReservedRoomResponseList?.reservationRefNumber
                        : ''}
                    </Descriptions.Item>
                  ) : null}
                  <Descriptions.Item label="Guest Name">{bookingData?.ownerName}</Descriptions.Item>
                  <Descriptions.Item label="Booked Date">{bookingData?.checkInDate}</Descriptions.Item>
                  <Descriptions.Item label="Booked Time">{bookingData?.checkInTime}</Descriptions.Item>
                  <Descriptions.Item label="Comments">{bookingData?.comments}</Descriptions.Item>
                  <Descriptions.Item
                    contentStyle={{color: BASE_COLORS.checkOutRed}}
                    labelStyle={{color: BASE_COLORS.checkOutRed}}
                    label="Total Amount">
                    {currency}{' '}
                    {convertNumberFormatWithDecimal(bookingData?.totalCharge ? bookingData?.totalCharge : 0, 2)}
                  </Descriptions.Item>
                  {bookingData?.advancePaid !== null && (
                    <Descriptions.Item label="Advanced Amount">
                      {currency}{' '}
                      {convertNumberFormatWithDecimal(bookingData?.advancePaid ? bookingData?.advancePaid : 0, 2)}
                    </Descriptions.Item>
                  )}
                </Descriptions>
              ) : (
                <></>
              )}
            </S.Padding>
          </S.BlueCard>
        </S.BlurCardWrapper>

        {bookedServices && bookedServices.length > 0 ? (
          <Row gutter={{xs: 10, md: 15, xl: 30}}>
            <Col xs={24} md={24} xl={24}>
              <S.BlurCardWrapper style={{marginBottom: '1rem'}}>
                <S.RedCard>
                  <S.Padding>
                    <BaseForm onFinish={handleBulkCancelSubmit} form={bulkCancelForm} size="middle">
                      <Row align="middle" gutter={{xs: 10, md: 15, xl: 30}}>
                        <Col xs={24} md={24} xl={24}>
                          <BaseForm.Item name="reason" label="Reason" rules={[{required: false}]}>
                            <TextArea />
                          </BaseForm.Item>
                        </Col>
                      </Row>
                      <Row justify="end" gutter={{xs: 10, md: 15, xl: 30}}>
                        <Col>
                          <Popconfirm
                            placement="topRight"
                            title="Are you sure to cancel all services?"
                            onConfirm={() => bulkCancelForm.submit()}
                            okText="Yes"
                            cancelText="No">
                            <Button danger type="primary">
                              Cancel All
                            </Button>
                          </Popconfirm>
                        </Col>
                      </Row>
                    </BaseForm>
                  </S.Padding>
                </S.RedCard>
              </S.BlurCardWrapper>
            </Col>
          </Row>
        ) : null}

        <Row style={{marginBottom: '1rem'}} gutter={{xs: 10, md: 15, xl: 30}}>
          {bookedServices &&
            bookedServices.map(bookedService => {
              console.log({bookedService});

              return (
                <Col key={`${bookedService.id}-retrived_services`} xs={24} md={24} xl={12}>
                  <S.BlurCardWrapper style={{marginBottom: '1rem'}}>
                    <S.WhiteCard>
                      <S.Padding>
                        {bookedService ? (
                          <Descriptions column={2} title={`${bookedService?.service}`}>
                            {bookedService?.hallId === null ? (
                              <Descriptions.Item label="Count">{bookedService?.count}</Descriptions.Item>
                            ) : null}
                            <Descriptions.Item label="Event time">{bookedService?.hallTime}</Descriptions.Item>
                            {bookedService?.hallTime === 'PER_HOUR' && bookedService?.noOfHours > 0 && (
                              <Descriptions.Item label="Hours">{bookedService?.noOfHours}</Descriptions.Item>
                            )}
                            {bookedService?.hallTime === 'FULL_DAY' && bookedService?.noOfDays > 0 && (
                              <Descriptions.Item label="No. of Days">{bookedService?.noOfDays}</Descriptions.Item>
                            )}
                            {bookedService?.extraHour > 0 && (
                              <Descriptions.Item label="Extra hours">{bookedService?.extraHour}</Descriptions.Item>
                            )}
                            <Descriptions.Item label="Amount">
                              {currency} {convertNumberFormatWithDecimal(bookedService?.totalPrice, 2)}
                            </Descriptions.Item>
                          </Descriptions>
                        ) : null}
                        <BaseForm form={singleCancelForms} size="middle">
                          <Row align="middle" gutter={{xs: 10, md: 15, xl: 30}}>
                            <Col xs={24} md={24} xl={24}>
                              <BaseForm.Item
                                name={`reason-${bookedService.id}`}
                                label="Reason"
                                rules={[{required: false}]}>
                                <TextArea />
                              </BaseForm.Item>
                            </Col>
                          </Row>
                          <Row justify="end" gutter={{xs: 10, md: 15, xl: 30}}>
                            <Col>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to cancel this service?"
                                onConfirm={() => cancelBooking(bookedService)}
                                okText="Yes"
                                cancelText="No">
                                <Button type="primary" danger>
                                  Cancel
                                </Button>
                              </Popconfirm>
                            </Col>
                          </Row>
                        </BaseForm>
                      </S.Padding>
                    </S.WhiteCard>
                  </S.BlurCardWrapper>
                </Col>
              );
            })}
        </Row>
        <S.EmptyFooterSpace />
      </S.FormContent>
    </Modal>
  );
}
