/* eslint-disable @typescript-eslint/ban-ts-comment */
import {getEventsByEventId} from '@app/api/hotel/additionalActivities/additionalActivities.api';
import {Modal} from '@app/components/common/Modal/Modal';
import React, {FC, useEffect, useState} from 'react';
import {Col, Descriptions, Row, Tag} from 'antd';
import * as S from '../../AdditionalActivity/createBooking/CreateBooking.style';
import {convertNumberFormatWithDecimal} from '@app/utils/utils';
import {BASE_COLORS} from '@app/styles/themes/constants';
import {getServiceBookingsByBookingId} from '@app/api/hotel/additionalBookings/additionalBookings.api';
import {ConvertedDataProps} from '../helper/interface';

interface ServiceInfoModalProps {
  open: boolean;
  close: () => void;
  serviceId: number;
}

const ServiceInfoModal: FC<ServiceInfoModalProps> = ({close, open, serviceId}) => {
  // @ts-ignore
  const [serviceBookingData, setServiceBookingData] = useState<ConvertedDataProps>({});

  const getServiceBookingsById = async (serviceId: number) => {
    try {
      const response = await getServiceBookingsByBookingId(serviceId);
      setServiceBookingData(response.result.additionalServiceReservation);
      console.log({response});
    } catch (error) {}
  };

  useEffect(() => {
    if (open) getServiceBookingsById(serviceId);
  }, [serviceId, open]);
  return (
    <Modal footer={null} title="Additional Activity Information" size="large" open={open} onCancel={close}>
      <Row style={{marginBottom: '1rem'}} gutter={{xs: 10, md: 15, xl: 30}}>
        <Col key={`guest-details`} xs={24} md={24} xl={24}>
          <Descriptions title={``} size="small" column={3}>
            <Descriptions.Item label="Booking No">{serviceBookingData?.refNumber}</Descriptions.Item>
            {serviceBookingData?.additionalServiceForReservedRoomResponseList?.reservedRoomNumber ? (
              <Descriptions.Item label="Reservation No">
                {serviceBookingData?.additionalServiceForReservedRoomResponseList?.reservationRefNumber
                  ? serviceBookingData?.additionalServiceForReservedRoomResponseList?.reservationRefNumber
                  : ''}
              </Descriptions.Item>
            ) : null}
            <Descriptions.Item label="Booked Date">{serviceBookingData?.checkInDate}</Descriptions.Item>
            <Descriptions.Item label="Booked Time">{serviceBookingData?.checkInTime}</Descriptions.Item>
            <Descriptions.Item label="Guest Name">{serviceBookingData?.ownerName}</Descriptions.Item>
            {serviceBookingData?.additionalServiceForReservedRoomResponseList === null && (
              <Descriptions.Item label="Payment Status">
                {serviceBookingData.outStanding > 0 ? (
                  <Tag color="red">Not Paid</Tag>
                ) : (
                  <Tag color="green">Amount paid</Tag>
                )}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Comment">{serviceBookingData?.comments}</Descriptions.Item>
          </Descriptions>
        </Col>
        {serviceBookingData?.vatRegistryResponse !== null ? (
          <S.Padding>
            <S.BlurCardWrapper>
              <S.BlueCard>
                <S.Padding>
                  <S.CardTitle>VAT Details</S.CardTitle>
                  <Descriptions>
                    <Descriptions.Item label="Name">{serviceBookingData?.vatRegistryResponse?.name}</Descriptions.Item>
                    <Descriptions.Item label="Address">{`${serviceBookingData?.vatRegistryResponse?.address}`}</Descriptions.Item>
                    <Descriptions.Item label="VAT Number">
                      {serviceBookingData?.vatRegistryResponse?.vatNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label="Email">
                      {serviceBookingData?.vatRegistryResponse?.email}
                    </Descriptions.Item>
                  </Descriptions>
                </S.Padding>
              </S.BlueCard>
            </S.BlurCardWrapper>
          </S.Padding>
        ) : null}
        {serviceBookingData?.additionalServiceSingleReservationResponseList?.map(bookedService => {
          return (
            <Col key={`${bookedService.id}-retrived_services`} xs={24} md={24} xl={12}>
              <S.BlurCardWrapper style={{marginBottom: '1rem'}}>
                <S.WhiteCard
                  style={{
                    backgroundColor:
                      bookedService.additionalServiceReservationStatus === 'CANCELLED' ? '#fff1f0' : BASE_COLORS.white,
                    borderColor: bookedService.additionalServiceReservationStatus === 'CANCELLED' ? 'red' : '#cce1f4',
                  }}>
                  <S.Padding>
                    <Descriptions title={`${bookedService.service}`} size="small" column={2}>
                      <Descriptions.Item label="Status">
                        <Tag color={bookedService.additionalServiceReservationStatus === 'BOOKED' ? 'green' : 'red'}>
                          {bookedService.additionalServiceReservationStatus}
                        </Tag>
                      </Descriptions.Item>
                      {bookedService.hallId === null ? (
                        <Descriptions.Item label="Count">{bookedService.count}</Descriptions.Item>
                      ) : null}
                      <Descriptions.Item label="Event time">{bookedService.hallTime}</Descriptions.Item>

                      {bookedService.hallTime === 'PER_HOUR' && bookedService.noOfHours > 0 && (
                        <Descriptions.Item label="Hours">{bookedService.noOfHours}</Descriptions.Item>
                      )}
                      {bookedService.hallTime === 'FULL_DAY' && bookedService.noOfDays > 0 && (
                        <Descriptions.Item label="No. of Days">{bookedService.noOfDays}</Descriptions.Item>
                      )}
                      {bookedService.extraHour > 0 && (
                        <Descriptions.Item label="Extra hours">{bookedService.extraHour}</Descriptions.Item>
                      )}
                      {bookedService.discount > 0 && (
                        <>
                          <Descriptions.Item label="Discount Type">{bookedService.discountType}</Descriptions.Item>
                          {bookedService.discountType === 'AMOUNT' ? (
                            <Descriptions.Item label="Discount">{`${serviceBookingData.resident ? 'LKR' : 'USD'} ${
                              bookedService.discount
                            }`}</Descriptions.Item>
                          ) : (
                            <Descriptions.Item label="Discount">{`${bookedService.discount}%`}</Descriptions.Item>
                          )}
                        </>
                      )}
                      <Descriptions.Item label="Amount">
                        {`${serviceBookingData.resident ? 'LKR' : 'USD'} ${convertNumberFormatWithDecimal(
                          bookedService.totalCharge,
                          2,
                        )} (Inc.taxes)`}
                      </Descriptions.Item>
                    </Descriptions>
                  </S.Padding>
                </S.WhiteCard>
              </S.BlurCardWrapper>
            </Col>
          );
        })}
      </Row>
    </Modal>
  );
};

export default ServiceInfoModal;
