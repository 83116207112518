import {BASE_COLORS} from '@app/styles/themes/constants';

const CHANNEL_TYPES = {
  OTA: 'OTA',
  TA: 'TA',
  WEB: 'WEB',
  DIRECT: 'DIRECT',
};

const RESERVATION_STATUS = {
  RESERVED: 1,
  CHECKEDIN: 2,
  PARTIALCHECKEDIN: 3,
  CHECKEDOUT: 4,
  PARTIALCHECKEDOUT: 5,
  CANCELLED: 6,
};

const RESERVATION_STATUS_COLOR = {
  RESERVED: BASE_COLORS.orange,
  CHECKED_IN: BASE_COLORS.darkGreen,
  CANCELLED: BASE_COLORS.cancelRed,
  CHECKED_OUT: BASE_COLORS.checkOutRed,
};

const BOOKING_METHOD = [
  {
    name: 'PER_HOUR',
    key: 1,
  },
  {
    name: 'FULL_DAY',
    key: 2,
  },
  {
    name: 'HALF_DAY',
    key: 3,
  },
];

const NATIONALITY = [
  {
    key: 1,
    name: 'Resident',
    value: true,
  },
  {
    key: 2,
    name: 'Non-Resident',
    value: false,
  },
];

const MOCK_USER_ID = 2;

const RESERVATION_LEAD_TYPE = [
  {id: 1, name: 'Promotions', value: 'PROMOTIONS'},
  {id: 2, name: 'Social Media', value: 'SOCIAL_MEDIA'},
  {id: 3, name: 'Family', value: 'FAMILY'},
  {id: 4, name: 'Return Guest', value: 'RETURN_GUEST'},
  {id: 5, name: 'Tribe', value: 'TRIBE'},
];

export {
  CHANNEL_TYPES,
  RESERVATION_STATUS,
  RESERVATION_STATUS_COLOR,
  NATIONALITY,
  BOOKING_METHOD,
  MOCK_USER_ID,
  RESERVATION_LEAD_TYPE,
};
