import React, {useEffect} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {doLogout} from '@app/store/slices/authSlice';
import {
  GROUP_ADMIN_MODULE_NAV_PATH,
  HOTEL_ADMIN_MODULE_NAME,
  HOTEL_ADMIN_MODULE_NAV_PATH,
  HOUSEKEEPING_ADMIN_MODULE_NAV_PATH,
  HOUSEKEEPING_MASTER_MODULE_NAME,
  INVENTORY_SERVICE_MODULE_NAME,
  RESTAURANT_ADMIN_MODULE_NAME,
  RESTAURANT_MASTER_MODULE_NAME,
  RESTAURANT_MASTER_MODULE_NAV_PATH,
  RESTAURANT_SERVICE_ADMIN_MODULE_NAV_PATH,
  SERVICE_ADMIN_MODULE_NAME,
  SERVICE_ADMIN_MODULE_NAV_PATH,
  getActiveNavPath,
  modulePermission,
} from '@app/utils/permissions';
import {filter, includes, remove} from 'lodash';

export const ProfileOverlay: React.FC = ({...props}) => {
  const {t} = useTranslation();
  const location = useLocation();
  const {hotelConfig, hotelServiceConfig} = useAppSelector(state => state.hotelSlice);

  //get permission
  const userPermission = useAppSelector(state => state.user.permissions);
  const permissions = modulePermission(userPermission, RESTAURANT_ADMIN_MODULE_NAME.CHEF_DASHBOARD);

  const permissionsStuart = modulePermission(userPermission, RESTAURANT_ADMIN_MODULE_NAME.FOOD_MENU);
  const permissionsChef = modulePermission(userPermission, RESTAURANT_ADMIN_MODULE_NAME.CHEF_DASHBOARD);
  const permissionsInventory = modulePermission(userPermission, INVENTORY_SERVICE_MODULE_NAME.INVENTORY_SERVICES);
  const isStuart = permissionsStuart.VIEW;
  const isChef = permissionsChef.VIEW;
  const inventoryAdmin = permissionsInventory.VIEW;

  const activeResNavPath: string = getActiveNavPath(userPermission, {
    ...RESTAURANT_MASTER_MODULE_NAV_PATH,
    ...RESTAURANT_SERVICE_ADMIN_MODULE_NAV_PATH,
  });

  const activeHouseKeepingNavPath: string = getActiveNavPath(userPermission, {
    ...HOUSEKEEPING_ADMIN_MODULE_NAV_PATH,
    ...SERVICE_ADMIN_MODULE_NAV_PATH,
  });

  const activeAdminNavPath: string = getActiveNavPath(userPermission, {
    ...HOTEL_ADMIN_MODULE_NAV_PATH,
  });

  const activeGroupAdminNavPath: string = getActiveNavPath(userPermission, {
    ...GROUP_ADMIN_MODULE_NAV_PATH,
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const checkHouseKeepingAdminPermission = () => {
    const obj: any = HOUSEKEEPING_MASTER_MODULE_NAME;
    const anyStatusNot3 = userPermission.some(item => {
      return obj[item.prefix] && item.status !== 3;
    });

    return anyStatusNot3;
  };

  const checkInventoryAdminPermission = () => {
    const obj: any = INVENTORY_SERVICE_MODULE_NAME;
    const anyStatusNot3 = userPermission.some(item => {
      return obj[item.prefix] && item.status !== 3;
    });
    return anyStatusNot3;
  };

  const checkResturantAdminPermission = () => {
    const obj: any = {...RESTAURANT_MASTER_MODULE_NAME, ...SERVICE_ADMIN_MODULE_NAME};
    const anyStatusNot3 = userPermission.some(item => {
      return obj[item.prefix] && item.status !== 3;
    });

    return anyStatusNot3;
  };
  const checkHotelAdminPermission = () => {
    const obj: any = HOTEL_ADMIN_MODULE_NAME;
    const anyStatusNot3 = userPermission.some(item => {
      return obj[item.prefix] && item.status !== 3;
    });

    return anyStatusNot3;
  };
  const checkCurentNavpath = () => {
    const navPaths = {
      ...RESTAURANT_MASTER_MODULE_NAV_PATH,
      ...RESTAURANT_SERVICE_ADMIN_MODULE_NAV_PATH,
    };
    const valuesNav = Object.values(navPaths);
    remove(valuesNav, res => res === '/toplevel/chef-dashboard');
    const containCurrentNavPath: boolean = includes(valuesNav, location.pathname);

    return !containCurrentNavPath;
  };

  const onLogOut = async () => {
    dispatch(doLogout()).then(res => {
      navigate('/logout');
    });
  };

  return (
    <div {...props}>
      {hotelServiceConfig.serviceType === 'RESTAURANT' &&
        location.pathname !== '/toplevel/groups' &&
        location.pathname !== '/toplevel/products' &&
        location.pathname !== '/toplevel/services' && (
          <>
            {permissions.VIEW && location.pathname !== '/toplevel/chef-dashboard' && (
              <S.Text>
                <Link to="/toplevel/chef-dashboard">Chef</Link>
              </S.Text>
            )}
            {checkResturantAdminPermission() && checkCurentNavpath() && (
              <S.Text>
                <Link to={activeResNavPath}>Restaurant Admin</Link>
              </S.Text>
            )}
            {/* {checkResturantAdminPermission() && isStuart && !isChef && (
              <S.Text>
                <Link to={activeResNavPath}>Restaurant Admin</Link>
              </S.Text>
            )} */}
            {/* <S.ItemsDivider /> */}
          </>
        )}
      {hotelServiceConfig.serviceType === 'INVENTORY' && inventoryAdmin && (
        <S.Text>
          <Link to="/inventory-admin/inventory-service">Inventory Admin</Link>
        </S.Text>
      )}
      {(location.pathname === '/toplevel/groups' ||
        location.pathname === '/toplevel/products' ||
        location.pathname === '/toplevel/services') &&
        checkHotelAdminPermission() && (
          <S.Text>
            <Link to={hotelConfig.hotelId === 0 ? activeGroupAdminNavPath : activeAdminNavPath}>
              {hotelConfig.hotelId === 0 ? 'Setting' : 'Setting'}
            </Link>
          </S.Text>
        )}
      {location.pathname === '/toplevel/products' && checkInventoryAdminPermission() && (
        <S.Text>
          <Link to={'/toplevel/group-inventory-home'}>Group Inventory</Link>
        </S.Text>
      )}
      {hotelServiceConfig.serviceType === 'HOUSEKEEPING' &&
        location.pathname !== '/toplevel/groups' &&
        location.pathname !== '/toplevel/products' &&
        location.pathname !== '/toplevel/services' &&
        checkHouseKeepingAdminPermission() && (
          <S.Text>
            <Link to={activeHouseKeepingNavPath}>Housekeeping Admin</Link>
          </S.Text>
        )}
      <S.Text>
        <Link to={'/toplevel/change-password'}>{'Change password'}</Link>
      </S.Text>
      <S.ItemsDivider />
      <S.Text onClick={onLogOut}>{t('header.logout')}</S.Text>
    </div>
  );
};
