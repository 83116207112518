import React, {useEffect, useMemo, useRef, useState} from 'react';
import Overlay from '../../../../common/Overlay';
import {useResponsive} from 'hooks/useResponsive';
import * as S from './MainSider.styles';
import {SiderLogo} from '../SiderLogo';
import SiderMenu from '../SiderMenu/SiderMenu';

interface MainSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const MainSider: React.FC<MainSiderProps> = ({isCollapsed, setCollapsed, ...props}) => {
  const {isDesktop, mobileOnly, tabletOnly} = useResponsive();

  const isCollapsible = useMemo(() => mobileOnly && tabletOnly, [mobileOnly, tabletOnly]);

  const divRef: any = useRef(null);

  const toggleSider = () => setCollapsed(!isCollapsed);

  const expanMenuItem = (open: boolean, key: string) => {
    if (key === 'Setting') {
      setTimeout(() => {
        divRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
      }, 200);
    }
  };

  return (
    <>
      <S.Sider
        trigger={null}
        collapsed={!isDesktop && isCollapsed}
        collapsedWidth={tabletOnly ? 80 : 0}
        collapsible={isCollapsible}
        width={230}
        {...props}>
        <SiderLogo isSiderCollapsed={isCollapsed} toggleSider={toggleSider} />
        <S.SiderContent>
          <SiderMenu setCollapsed={setCollapsed} expandMenu={expanMenuItem} />
          <div ref={divRef} />
        </S.SiderContent>
      </S.Sider>

      {mobileOnly && <Overlay onClick={toggleSider} show={!isCollapsed} />}
    </>
  );
};

export default MainSider;
