import {getTablesForWaiterDashboard, getTablesForWaiterDashboardKot} from '@app/api/resturant/tablecategory/order.api';
import {Card} from '@app/components/common/Card/Card';
import {Button} from '@app/components/common/buttons/Button/Button';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {serveBotStatusChange, serveStatusChange} from '@app/pages/DashboardPages/chef.api';
import SockJS from 'sockjs-client';
import {Stomp} from '@stomp/stompjs';
import {NOTIFY_SOUND} from '@app/assets';
import {RESTAURANT_WS_SERVICE} from '@app/api/resturantInstance';
import {MdOutlineRestaurantMenu} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';
import {Table} from '@app/components/common/Table/Table';
import {filter, sortBy} from 'lodash';
import {SyncOutlined} from '@ant-design/icons';
import {MainMenuBtn} from '@app/components/layouts/main/MainLayout/TopLevelLayout';
import {useAppSelector} from '@app/hooks/reduxHooks';
import {RESTAURANT_ADMIN_MODULE_NAME, modulePermission} from '@app/utils/permissions';
import {Tag} from 'antd';

const CustomTable = styled(Table)`
  &.ant-table-wrapper {
    background: red !important;
  }

  &.ant-table-tbody > tr > td {
  }
`;
const TableButton = styled(Button)`
  background: #0057b1;
  border-radius: 15px;
  color: white;
  border: none;
  min-width: 80px;

  -webkit-box-shadow: 4px 8px 5px -1px rgba(0, 0, 0, 0.73);
  -moz-box-shadow: 4px 8px 5px -1px rgba(0, 0, 0, 0.73);
  box-shadow: 1px 3px 0px 0px rgba(0, 0, 0, 0.73);
`;

interface ITableDashboard {
  swaipIndex?: boolean;
}

const TableDashboard: React.FC<ITableDashboard> = ({swaipIndex}) => {
  const navigate = useNavigate();
  //get permission
  const userPermission = useAppSelector(state => state.user.permissions);
  const permissions = modulePermission(userPermission, RESTAURANT_ADMIN_MODULE_NAME.ALLOW_ALL_TABLE);

  const hotelServiceConfig = useAppSelector(state => state.hotelSlice.hotelServiceConfig);
  const hoteluserConfig = useAppSelector(state => state.user.userPermission);

  const [tables, setTables] = useState<any[]>([]);
  const [tablesKotData, setTablesKotData] = useState<any[]>([]);

  useEffect(() => {
    getTableKotList();
  }, [swaipIndex]);

  const getTableKotList = async () => {
    const results = await getTablesForWaiterDashboardKot(
      hotelServiceConfig.serviceId,
      hoteluserConfig?.userId,
      permissions.VIEW,
    );
    const tableAllocation = results.result.tableAllocation;

    const xData: any = [];
    tableAllocation.map((post: any, index: number) => {
      post.kotResponseList.map((res: any, indexKey: number) => {
        xData.push({
          id: post.id,
          number: post.tableNumber,
          tableId: post.tableId,
          status: '',
          currentOrderId: post.currentOrderId,
          kotNumber: res.kotNumber,
          botNumber: res.botNumber,
          kotId: res.id,
          ticketType: res.ticketType,
          kotStatus: res.ticketStatus,
          colSpan: indexKey === 0 ? post.kotResponseList.length : 0,
          orderStatus: post.orderStatus,
        });
      });
    });
    setTablesKotData(xData);
  };

  const getAllTables = async () => {
    try {
      const results = await getTablesForWaiterDashboard(hotelServiceConfig.serviceId);
      const resultsData = filter(results?.result?.restaurantTable, res => res.status !== 'SERVED');

      const finalData = sortBy(resultsData, res => res.status === 'READY_TO_SERVE').reverse();
      setTables(finalData);
    } catch (error) {}
  };

  useEffect(() => {
    let stompClient: any = null;

    const WebSocketClient = (url: string) => {
      const sock = new SockJS(RESTAURANT_WS_SERVICE);
      stompClient = Stomp.over(sock);

      sock.onopen = function () {
        //
      };

      stompClient.connect({}, (frame: any) => {
        stompClient.subscribe(url, data => {
          const receivedData = JSON.parse(data.body);

          if (receivedData) {
            const audio = new Audio(NOTIFY_SOUND);
            audio.play();
            getTableKotList();
          }
        });
      });
      stompClient.activate();
    };

    WebSocketClient(`/user/${hoteluserConfig?.userId}/queue/waiter`);

    return () => {
      // Cleanup the WebSocket connection on component unmount
      if (stompClient) {
        stompClient.disconnect();
      }
    };
  }, []);

  const serveTable = async (currentOrderId: number) => {
    const data: any = {
      id: currentOrderId,
      orderStatus: 'SERVED',
    };

    try {
      const result: any = await serveStatusChange(data);

      if (result?.statusCode === '20000') {
        await getTableKotList();
      } else {
      }
    } catch (error) {}
  };

  const serveBotTable = async (currentOrderId: number) => {
    const data: any = {
      id: currentOrderId,
      orderStatus: 'SERVED',
    };

    try {
      const result: any = await serveBotStatusChange(data);

      if (result?.statusCode === '20000') {
        await getTableKotList();
      } else {
      }
    } catch (error) {}
  };

  const columns = [
    {
      title: 'Table Number',
      dataIndex: 'number',
      onCell: (recode: any, index: number) => {
        if (recode.colSpan > 0) {
          return {
            rowSpan: recode.colSpan,
          };
        } else {
          return {rowSpan: 0};
        }
      },
      width: '15%',
      align: 'center',
    },
    {
      title: 'Order Status',
      dataIndex: 'orderStatus',
      onCell: (recode: any, index: number) => {
        if (recode.colSpan > 0) {
          return {
            rowSpan: recode.colSpan,
          };
        } else {
          return {rowSpan: 0};
        }
      },
      width: '15%',
      align: 'center',

      render: (text: string, record: any) => {
        return (
          <Tag
            style={{
              padding: '0px 5px',
              marginBottom: 2,
            }}
            color={
              record?.orderStatus === 'WAITING_FOR_ORDER'
                ? 'orange'
                : record?.orderStatus === 'BEING_PREPARED'
                ? 'blue'
                : 'green'
            }>
            {record?.orderStatus === 'BEING_PREPARED'
              ? 'BEING PREPARED'
              : record?.orderStatus === 'READY_TO_SERVE'
              ? 'READY TO SERVE'
              : record?.orderStatus === 'WAITING_FOR_ORDER'
              ? 'WAITING FOR ORDER'
              : record?.orderStatus === 'REQUEST_CONFIRMATION'
              ? 'REQUEST CONFIRMATION'
              : record?.orderStatus}
            {record?.orderStatus === 'BEING_PREPARED' ? (
              <SyncOutlined
                style={{
                  marginLeft: '0.5rem',
                }}
                spin
              />
            ) : (
              <></>
            )}
          </Tag>
        );
      },
    },
    {
      title: 'KOT',
      dataIndex: 'kotNumber',
      render: (text: string, recode: any) => <span>{recode.ticketType === 'KOT' ? text : ''}</span>,
      width: '20%',
      align: 'center',
    },
    {
      title: 'BOT',
      dataIndex: 'botNumber',
      render: (text: string, recode: any) => <span>{recode.ticketType === 'BOT' ? recode.kotNumber : ''}</span>,
      width: '20%',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'kotStatus',

      width: '20%',
      align: 'center',

      render: (text: string, record: any) => {
        return (
          <Tag
            style={{
              padding: '0px 5px',
              marginBottom: 2,
            }}
            color={
              record?.kotStatus === 'WAITING_FOR_ORDER'
                ? 'orange'
                : record?.kotStatus === 'BEING_PREPARED'
                ? 'blue'
                : 'green'
            }>
            {record?.kotStatus === 'BEING_PREPARED'
              ? 'BEING PREPARED'
              : record?.kotStatus === 'READY_TO_SERVE'
              ? 'READY TO SERVE'
              : record?.kotStatus === 'WAITING_FOR_ORDER'
              ? 'WAITING FOR ORDER'
              : record?.kotStatus}
            {record?.kotStatus === 'BEING_PREPARED' ? (
              <SyncOutlined
                style={{
                  marginLeft: '0.5rem',
                }}
                spin
              />
            ) : (
              <></>
            )}
          </Tag>
        );
      },
    },

    {
      title: 'Action',
      dataIndex: 'status',
      align: 'center',
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <TableButton
              onClick={() => {
                record.ticketType === 'KOT' ? serveTable(record?.kotId) : serveBotTable(record?.kotId);
              }}
              disabled={record?.kotStatus !== 'READY_TO_SERVE'}
              type="primary"
              title="">
              SERVED
            </TableButton>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <MainMenuBtn
        onClick={() => {
          navigate('/restaurant/dashboard/:1');
        }}
        style={{
          marginLeft: '0.5rem',
          padding: '9px 14px',
          fontSize: '12px',
          position: 'fixed',
          top: '12%',
          left: '1%',
        }}
        size="small"
        icon={<MdOutlineRestaurantMenu />}
        title="">
        Food Menu
      </MainMenuBtn>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Card
          size="small"
          style={{
            width: '100%',
            marginTop: '3rem',
            marginLeft: '5rem',
          }}
          bodyStyle={{
            padding: '10px 20px',
          }}>
          <CustomTable
            size="small"
            title={() => (
              <div
                style={{
                  fontWeight: '700',
                  fontSize: '18px',
                }}>
                Order Status
              </div>
            )}
            rowClassName={''}
            columns={columns}
            style={{
              width: '65rem',
            }}
            className="custom-css"
            dataSource={tablesKotData}
            pagination={false}
          />

          {/* <Table columns={columnsKot} dataSource={tablesKotData} bordered /> */}
        </Card>
      </div>
    </div>
  );
};

export default TableDashboard;
