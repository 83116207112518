import {userEmailVerification} from '@app/api/user/user.api';
import {Button, Result} from 'antd';
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router-dom';

function VerificationScreen() {
  const params = useParams();

  const encodedUsername = btoa(`${params?.email}`);

  useEffect(() => {
    userVerification();
  }, []);

  const userVerification = async () => {
    try {
      await userEmailVerification(params?.email, params?.token);
    } catch (error) {}
  };

  return (
    <div>
      <Result
        status="success"
        title="User Created Successfully!"
        extra={[
          <Link key="login-btn" to={`/auth/login/${encodedUsername}`}>
            <Button type="primary" key="console">
              Go to page
            </Button>
          </Link>,
        ]}
      />
    </div>
  );
}

export default VerificationScreen;
