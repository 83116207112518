/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-key */
/* eslint-disable prefer-const */
import * as React from 'react';
import * as S from './DashboardPage.styles';
import {Image, Tooltip} from 'antd';
import {IOrderItem} from '@app/api/getOrders.api';
import {Card} from 'antd';
import styled from 'styled-components';
import {Text} from '@app/components/Error/Error.styles';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {CategoryItem} from '../CategoryDatas';
import {FadeIn} from '../FoodCatagoryFoodLevel';
import TopLevelCard from '@app/components/cards/TopLevelCard';
import {ProductsContainer} from '../FoodCatagoryTop';
import {getCategoryStuctureByID} from '@app/api/resturant/tablecategory/categoryStucture.api';
import {setSelectedCategoryId} from '../slices/waiterDasboardSlice';
import {setCategoryData, setCategoryStatus} from '../slices/categoryNavSlice';

import './TopLevelFilter.style.css';

const {Meta} = Card;

const CustomImage = styled(Image)`
  img {
    object-fit: cover;
  }
`;

interface ILeftArea {
  orderFilterItems: any;
  getAllFoodItemsByCategory: (id: any, name?: string) => void;
  onChangeItem: (item: IOrderItem | any, index?: any, quantity?: number) => void;
}

const LeftArea: React.FC<ILeftArea> = ({orderFilterItems, onChangeItem, getAllFoodItemsByCategory}) => {
  const dispatch = useAppDispatch();

  const categoryLists = useAppSelector(state => state.categoryNav.categoryLists);
  const categoryStatus = useAppSelector(state => state.categoryNav.categoryStatus);

  const [previewImage, setPreviewImage] = React.useState<string>('');

  const hotelServiceConfig = useAppSelector(state => state.hotelSlice.hotelServiceConfig);

  const convertNumberFormat = (number: any) => {
    const num = number.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return num;
  };

  const onChangeCard = async (data: CategoryItem) => {
    if (data.expanded) {
      // await getCategoryStuctureByID(data.id, hotelServiceConfig.serviceId);
      // dispatch(setSelectedCategoryId({selectedCategoryId: null}));
      await getCategoryById(data.id);

      data?.children.length > 0 && dispatch(setCategoryStatus({status: true}));
    } else {
      localStorage.setItem('categoryId', data.id.toString());
      await getAllFoodItemsByCategory(data.id);
      dispatch(setCategoryStatus({status: false}));
    }
  };

  const getCategoryById = async (id: number, layerIndex?: number, index?: number) => {
    const result = await getCategoryStuctureByID(id, hotelServiceConfig.serviceId);

    const data: any[] = [];

    result.result.category &&
      result.result.category.map((post: any, i: any) => {
        data.push({
          id: post.id,
          backgroundImage: post.categoryImage,
          title: post.categoryTitle,
          children: [],
          expanded: post.expanded,
          categoryId: post.categoryId,
        });
      });
    dispatch(setCategoryData({items: data}));

    // if (layerIndex === 1) {
    //   setfirstCatLayer(data);
    // } else if (layerIndex === 2) {
    // } else {
    //   dispatch(setCategoryData({items: data}));
    // }
  };

  return (
    <div>
      {!categoryStatus ? (
        <S.CardWrapper style={{width: '100%', minWidth: '37rem'}}>
          {orderFilterItems.length === 0 ? (
            <div>Select food Category</div>
          ) : (
            orderFilterItems.map((item: IOrderItem, index: number) => {
              return (
                <div className="food-card-push">
                  <Card
                    key={index}
                    className={item.active ? 'food-card-front' : 'food-card-front-disable'}
                    onClick={() => item.active && onChangeItem(item)}
                    bodyStyle={{padding: '10px'}}
                    style={{
                      backgroundColor: item.active ? 'white' : '#c9c1c1',
                      cursor: item.active ? 'pointer' : 'default',
                    }}
                    cover={
                      <>
                        <CustomImage
                          preview={false}
                          onMouseOver={() => setPreviewImage(item.itemImage)}
                          style={{
                            height: 150,
                            width: '100%',
                            alignItems: 'center',
                            objectFit: 'cover',
                            cursor: item.active ? 'pointer' : 'default',
                            opacity: !item.active ? 0.5 : 1,
                          }}
                          height={150}
                          width={'100%'}
                          src={item.itemImage}
                        />
                        {!item.active && (
                          <span
                            style={{
                              position: 'absolute',
                              bottom: '60%',
                              paddingLeft: '23%',
                              color: 'white',
                              fontWeight: 'bolder',
                              fontSize: '16px',
                            }}>
                            Not available
                          </span>
                        )}
                      </>
                    }>
                    <Tooltip placement="bottomLeft" title={item.item}>
                      <Meta title={<Text style={{textAlign: 'center', fontSize: '1rem'}}>{item.item}</Text>} />
                    </Tooltip>

                    <Text style={{textAlign: 'center', fontSize: '14px'}} type={item.active ? 'danger' : 'secondary'}>
                      {convertNumberFormat(Number(item.price).toFixed(2))} {item.currency}
                    </Text>
                    {/* <br />

                    <Text style={{textAlign: 'center', fontSize: '14px'}} type={'danger'}>
                      {!item.active ? 'Not avalable' : ''}
                    </Text> */}
                  </Card>
                </div>
              );
            })
          )}
        </S.CardWrapper>
      ) : (
        <ProductsContainer>
          {categoryLists.map((post: CategoryItem, i: number) => {
            return (
              <FadeIn onClick={() => onChangeCard(post)}>
                <TopLevelCard
                  scale={'0.85'}
                  key={i}
                  bgUrl={post.backgroundImage}
                  logo={null}
                  name={post.title}
                  info={''}
                />
              </FadeIn>
            );
          })}
        </ProductsContainer>
      )}
    </div>
  );
};

export default React.memo(LeftArea);
