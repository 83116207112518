import React from 'react';
import * as S from './MainSider/MainSider.styles';
import {RightOutlined} from '@ant-design/icons';
import {useResponsive} from 'hooks/useResponsive';
import logo from 'assets/logo.png';
import logoDark from 'assets/logo-dark.png';
import {useAppSelector} from '@app/hooks/reduxHooks';
import {useLocation} from 'react-router-dom';
import {SerendipityLogo, SerendipitySideBarLogo} from '@app/assets';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({isSiderCollapsed, toggleSider}) => {
  const {tabletOnly} = useResponsive();
  const location = useLocation();

  const theme = useAppSelector(state => state.theme.theme);
  const hotelConfig = useAppSelector(state => state.hotelSlice.hotelConfig);

  const img = theme === 'dark' ? logoDark : logo;

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink>
        <img
          src={
            location.pathname === '/toplevel/groups'
              ? SerendipityLogo
              : location.pathname === '/toplevel/products'
              ? hotelConfig?.groupHeaderLogo
              : hotelConfig.sideBarLogo === ''
              ? SerendipitySideBarLogo
              : hotelConfig.sideBarLogo
          }
          alt="HMS"
          width={48}
          height={48}
        />
        <S.BrandSpan>
          {location.pathname === '/group-admin/hotel-group' ||
          location.pathname === '/group-admin/hotel' ||
          location.pathname === '/group-admin/hotel-service'
            ? 'Admin Panel '
            : hotelConfig?.title === ''
            ? hotelConfig.groupTitle
            : hotelConfig.title}
        </S.BrandSpan>
      </S.SiderLogoLink>
      {tabletOnly && (
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={toggleSider}
        />
      )}
    </S.SiderLogoDiv>
  );
};
