import React from 'react';
import {HomeOutlined} from '@ant-design/icons';
import {Button} from '@app/components/common/buttons/Button/Button';
import {useNavigate} from 'react-router-dom';
import {FaChartBar} from 'react-icons/fa';
import {TbChartBar} from 'react-icons/tb';
import {BAR_CHART} from '../../../../assets';
import {BASE_COLORS} from '@app/styles/themes/constants';
import {useAppSelector} from '@app/hooks/reduxHooks';
import {Tooltip} from 'antd';

export const DashboardHome: React.FC = () => {
  const hotelServiceConfig = useAppSelector(state => state.hotelSlice.hotelServiceConfig);
  const navigate = useNavigate();
  return (
    <Tooltip color="white" title={<div style={{color: 'black', fontSize: '12px'}}>Dashboard</div>}>
      <div
        hidden={hotelServiceConfig.serviceType === 'HOTEL' ? false : true}
        onClick={() => {
          navigate('/hotel/dashboard');
        }}
        style={{
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '3px',
          marginRight: '0.6rem',
          cursor: 'pointer',
        }}>
        <img style={{height: '1.3rem', width: '1.3rem'}} src={BAR_CHART} />
      </div>
    </Tooltip>
  );
};
