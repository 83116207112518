/* eslint-disable @typescript-eslint/no-explicit-any */
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {Button} from '@app/components/common/buttons/Button/Button';
import * as S from './ServiceReservation.style';
import {Tables} from '@app/components/tables/Tables/Tables';
import {Popconfirm} from '@app/components/common/Popconfirm/Popconfirm';
import {Space} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {ColumnsType} from 'antd/lib/table';
import MakeReservation from './MakeReservation';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {setIsEditAction, setLoading, setModalVisible} from '@app/store/slices/commonSlice';
import {IServicesBookinsData} from './interface';
import {
  FilterPropsQurey,
  getAllAdditionalServices,
  getAllBookedAdditionalServices,
} from '@app/api/hotel/additionalBookings/additionalBookings.api';
import {IAdditionalServiceData} from '@app/pages/MasterPages/hotel/Invoice/AdditionalServices/AdditionalServicesPage';
import {BASE_COLORS} from '@app/styles/themes/constants';
import Retrive from './Modals/Retrive';
import {HOTEL_SERVICE_MODULE_NAME, modulePermission} from '@app/utils/permissions';
import _ from 'lodash';
import CancelModal from './Modals/Cancel';
import {filter} from 'lodash';
import InfoModal from './tableInfo/Information';
import CreateAdditionalServiceModal from './Modals/CreateAdditionalServiceModal';
import RetriveServiceModal from './Modals/RetriveServiceModal';
import InformationModal from './Modals/InformationModal';

const DISCOUNT_OPTION = {
  AMOUNT: 'AMOUNT',
  PERCENTAGE: 'PERCENTAGE',
};

export default function ServiceReservation() {
  const [form] = BaseForm.useForm();

  const dispatch = useAppDispatch();

  const [rowData, setRowData]: any = useState<IServicesBookinsData>();
  const [open, setopen] = useState(false);
  const [openCancel, setopenCancel] = useState(false);
  const [data, setdata] = useState([]);
  const [openInfo, setopenInfo] = useState(false);
  const [isClosedModal, setisClosedModal] = useState(false);
  const [selectedServices, setselectedServices]: any = useState([]);
  const [discountOption, setDiscountOption] = useState({});

  const isTouch = useAppSelector(state => state.commonSlice.isTouch);
  const isEditAction = useAppSelector(state => state.commonSlice.isEditAction);
  const loading = useAppSelector(state => state.commonSlice.loading);
  const {hotelId} = useAppSelector(state => state.hotelSlice.hotelConfig);

  //get permission
  const userPermission = useAppSelector(state => state.user.permissions);
  const permissions = modulePermission(userPermission, HOTEL_SERVICE_MODULE_NAME.ADDITIONAL_SERVICES_BOOKING);

  // eslint-disable-next-line prefer-const
  let [searchObj, setSearchObj]: any = useState({});

  const handleClickRetrive = (record: IServicesBookinsData) => {
    setopen(true);
    setRowData(record);
  };
  const handleClickCancel = (record: IServicesBookinsData) => {
    setopenCancel(true);
    setRowData(record);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'AD Invoice#',
      dataIndex: 'refNumber',
      align: 'center',
    },
    {
      title: 'Guest Name',
      dataIndex: 'ownerName',
      align: 'center',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Booking Date',
      dataIndex: 'checkInDate',
      align: 'center',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Booked Time',
      dataIndex: 'checkInTime',
      align: 'center',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'additionalServiceReservationStatus',
      align: 'center',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Info',
      dataIndex: 'info',
      align: 'center',
      render(_value: any, record: any) {
        return (
          <S.InfoButton
            onClick={() => {
              setopenInfo(true);
              setRowData(record);
            }}>
            Info
          </S.InfoButton>
        );
      },
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '20%',
      render: (_text: string, record: IServicesBookinsData) => {
        return (
          record.additionalServiceReservationStatus === 'BOOKED' && (
            <S.ActionWraper>
              <S.ActionButton $color={BASE_COLORS.checkOutRed} onClick={() => handleClickRetrive(record)}>
                Retrieve
              </S.ActionButton>
              <S.ActionButton $color={BASE_COLORS.cancelRed} onClick={() => handleClickCancel(record)}>
                Cancel
              </S.ActionButton>
            </S.ActionWraper>
          )
        );
      },
      align: 'center',
    },
  ];

  const handleCloseInfo = () => {
    setopenInfo(false);
  };

  const resetForm = () => {
    form.resetFields();
    dispatch(setLoading(false));
    setselectedServices([]);
  };

  const getTableData = async (searchQuery: FilterPropsQurey) => {
    try {
      const data = await getAllAdditionalServices(hotelId, searchQuery);
      const filteredData: any = filter(
        data.result.additionalServiceReservation,
        res =>
          res.additionalServiceReservationStatus !== 'CANCELLED' &&
          res.additionalServiceReservationStatus !== 'RETRIEVED',
      );
      setdata(filteredData);
    } catch (error) {}
  };

  const onChangeTableSearch = (values: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = {...searchObj, ...values};
    searchObj = obj;
    setSearchObj(obj);
    getTableData(obj);
  };

  const handleCancelModal = () => {
    setopen(false);
  };
  const closeCancelModal = () => {
    setopenCancel(false);
  };

  useEffect(() => {
    getTableData(searchObj);
  }, []);

  !permissions.DELETE && !permissions.EDIT && _.remove(columns, (col: any) => col.dataIndex === 'actions');

  return (
    <>
      <PageTitle>Make Service Booking</PageTitle>
      <S.CurrencyWrapper>
        <S.TableWrapper>
          <Tables
            showPagination={false}
            title="Make Service Booking"
            tableData={data}
            columns={columns}
            modalChildren={
              <CreateAdditionalServiceModal
                reloadData={() => getTableData(searchObj)}
                form={form}
                rowData={rowData}
                isClosedModal={isClosedModal}
                selectedServices={selectedServices}
                setselectedServices={setselectedServices}
                discountOption={discountOption}
                setDiscountOption={setDiscountOption}
              />
            }
            modalSize="xl"
            isCreate={permissions.ADD}
            searchFields={['ownerName', 'refNumber']}
            onChangeSearch={onChangeTableSearch}
            onCancelModal={() => {
              resetForm();
              setisClosedModal(true);
              setselectedServices([]);
            }}
            modalTitle="Create Additional Service Booking"
            modalFooter={
              <Space>
                {isEditAction ? (
                  <Button
                    danger
                    title="Cancel"
                    type="ghost"
                    onClick={() => {
                      dispatch(setIsEditAction(false));
                      dispatch(setModalVisible(false));
                    }}>
                    Cancel
                  </Button>
                ) : (
                  <Button
                    danger
                    title="Clear"
                    type="ghost"
                    onClick={() => {
                      resetForm();
                      setisClosedModal(true);
                    }}>
                    Clear
                  </Button>
                )}
                <Button
                  title=""
                  disabled={isEditAction ? !isTouch : false}
                  type="primary"
                  loading={loading}
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  onClick={_e => {
                    form.submit();
                  }}>
                  {isEditAction ? 'Update' : 'Save'}
                </Button>
              </Space>
            }
          />
        </S.TableWrapper>
        {open && (
          <RetriveServiceModal
            reloadData={() => getTableData(searchObj)}
            data={rowData}
            handleCancelModal={handleCancelModal}
            open={open}
          />
        )}
        <CancelModal
          reloadData={() => getTableData(searchObj)}
          data={rowData}
          handleCancelModal={closeCancelModal}
          open={openCancel}
        />

        <InformationModal open={openInfo} close={handleCloseInfo} serviceId={rowData?.id} />
      </S.CurrencyWrapper>
    </>
  );
}
