import instance, {HOTEL_SERVICE} from '@app/api/instance';

export const CreatePax = (payload: CreatePaxProps): Promise<PaxResponse> => {
  return instance.post<PaxResponse>(HOTEL_SERVICE + 'pack', payload).then(({data}) => data);
};

export const UpdatePax = (payload: UpdatePaxProps): Promise<PaxResponse> => {
  return instance.put<PaxResponse>(HOTEL_SERVICE + 'pack', payload).then(({data}) => data);
};

export const getAllPaxes = (hotelId: number): Promise<PaxResponse> =>
  instance.get<PaxResponse>(HOTEL_SERVICE + `pack/hotel/${hotelId}`).then(({data}) => data);

export const getAllPaxeswithPagination = (
  hotelId: number,
  {name}: FilterProps,
  pageSize: number | undefined,
  current: number,
): Promise<PaxResponse> =>
  instance
    .get<PaxResponse>(
      HOTEL_SERVICE +
        `pack/search?page=${current}&size=${pageSize}&sortField=id&direction=DESC&name=${
          name ? name : ''
        }&&hotelId=${hotelId}`,
    )
    .then(({data}) => data);

export const DeletePax = (id: number): Promise<PaxResponse> =>
  instance.delete<PaxResponse>(HOTEL_SERVICE + `pack/${id}`).then(({data}) => data);

export interface CreatePaxProps {
  name: string;
  hotelId: number;
  value: number;
}

export interface UpdatePaxProps {
  id: number;
  name: string | number;
  hotelId: number;
  value: number;
}

export interface PaxResponse {
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
  status: string;
  statusCode: string;
}

export interface FilterProps {
  name: string;
}
