import axios from 'axios';
import env from '@beam-australia/react-env';

export const HOTEL_SERVICE = 'hotel-service/api/v1/';
export const RESTAURANT_SERVICE = 'restaurant-service/api/v1/';
export const LOGIN_SERVICE = 'authorization-service/';
export const HOUSE_KEEPING_SERVICE = 'house-keeping-service/api/v1/';

export const API_KEY_ENV = env('BASE_URL');

export const HOTEL_WS_SERVICE = `${
  process.env.NODE_ENV === 'production' ? API_KEY_ENV : process.env.REACT_APP_BASE_URL
}:9094/ws`;

export const BASE_URL = `${process.env.NODE_ENV === 'production' ? API_KEY_ENV : process.env.REACT_APP_BASE_URL}:9091/`;

const instance = axios.create({
  baseURL: BASE_URL,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
instance.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers = {...config.headers};
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default instance;
