import React from 'react';
import {Column, ColumnConfig} from '@ant-design/plots';
import {IOccupancyGraphData} from '../HotelDashboardPage';

interface IProps {
  occupancyGraphData: IOccupancyGraphData[];
}

const OccupancyGraph: React.FC<IProps> = props => {
  const {occupancyGraphData} = props;

  const [roomReservationStatisticsData, setRoomReservationStatisticsData] = React.useState<IOccupancyGraphData[]>([]);

  React.useEffect(() => {
    setRoomReservationStatisticsData(occupancyGraphData);
  }, [occupancyGraphData]);

  const config: ColumnConfig = {
    data: roomReservationStatisticsData,
    isStack: true,
    xField: 'month',
    yField: 'value',
    seriesField: 'type',
    yAxis: {
      title: {
        text: 'Number Of Nights',
      },
      label: {
        formatter: (value: string) => `${value}`,
      },
      line: {
        style: {
          stroke: '#D2D3D4',
          lineWidth: 1.5,
        },
      },
    },

    legend: {
      position: 'top',
    },
    label: {
      content: ({percentage}) => `${percentage}`,

      style: {
        fontSize: 12,
        fontWeight: 600,
      },
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
    color: ['#bbbbbb', '#0dd713'],
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
      title: {
        text: 'Months',
      },
    },
    meta: {
      type: {
        alias: 'numberOfNight',
      },
      guest: {
        alias: 'Room night count',
        formatter: (value: string) => `${value}`,
      },
      percentage: {
        alias: 'Percentage',
      },
    },
    columnStyle: {
      borderRadius: 25,
    },
  };

  return <Column {...config} />;
};

export default React.memo(OccupancyGraph);
