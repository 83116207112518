import inventoryInstance, {INVENTORY_SERVICE} from '@app/api/inventoryInstance';

export const getAllItemReOrderLevel = (
  hotelId: number,
  inventoryServiceId: number,
): Promise<ItemReOrderLevelResponse> =>
  inventoryInstance
    .get<ItemReOrderLevelResponse>(
      INVENTORY_SERVICE + `item-reorder-level/hotel/${hotelId}/inventory-service/${inventoryServiceId}`,
    )
    .then(({data}) => data);

export const getAllItemReOrderLevelForGroup = (
  groupId: number,
  inventoryServiceId: number,
): Promise<ItemReOrderLevelResponse> =>
  inventoryInstance
    .get<ItemReOrderLevelResponse>(
      INVENTORY_SERVICE + `item-reorder-level/group/${groupId}/group-inventory-service/${inventoryServiceId}`,
    )
    .then(({data}) => data);

export const getAllTotalItems = (hotelId: number, inventoryServiceId: number): Promise<ItemReOrderLevelResponse> =>
  inventoryInstance
    .get<ItemReOrderLevelResponse>(
      INVENTORY_SERVICE + `total-items/hotel/${hotelId}/inventory-service/${inventoryServiceId}`,
    )
    .then(({data}) => data);

export const getAllTotalItemsForGroup = (
  groupId: number,
  inventoryServiceId: number,
): Promise<ItemReOrderLevelResponse> =>
  inventoryInstance
    .get<ItemReOrderLevelResponse>(
      INVENTORY_SERVICE + `total-items/group/${groupId}/group-inventory-service/${inventoryServiceId}`,
    )
    .then(({data}) => data);

export const getLowItemCount = (hotelId: number, inventoryServiceId: number): Promise<ItemReOrderLevelResponse> =>
  inventoryInstance
    .get<ItemReOrderLevelResponse>(
      INVENTORY_SERVICE + `low-items/hotel/${hotelId}/inventory-service/${inventoryServiceId}`,
    )
    .then(({data}) => data);

export const getLowItemCountForGroup = (
  groupId: number,
  inventoryServiceId: number,
): Promise<ItemReOrderLevelResponse> =>
  inventoryInstance
    .get<ItemReOrderLevelResponse>(
      INVENTORY_SERVICE + `low-items/group/${groupId}/group-inventory-service/${inventoryServiceId}`,
    )
    .then(({data}) => data);

export const getOutOfStockItemCount = (
  hotelId: number,
  inventoryServiceId: number,
): Promise<ItemReOrderLevelResponse> =>
  inventoryInstance
    .get<ItemReOrderLevelResponse>(
      INVENTORY_SERVICE + `out-of-stock-items/hotel/${hotelId}/inventory-service/${inventoryServiceId}`,
    )
    .then(({data}) => data);

export const getOutOfStockItemCountForGroup = (
  groupId: number,
  inventoryServiceId: number,
): Promise<ItemReOrderLevelResponse> =>
  inventoryInstance
    .get<ItemReOrderLevelResponse>(
      INVENTORY_SERVICE + `out-of-stock-items/group/${groupId}/group-inventory-service/${inventoryServiceId}`,
    )
    .then(({data}) => data);

export const getZeroStockItemCount = (hotelId: number, inventoryServiceId: number): Promise<ItemReOrderLevelResponse> =>
  inventoryInstance
    .get<ItemReOrderLevelResponse>(
      INVENTORY_SERVICE + `zero-stock-items/hotel/${hotelId}/inventory-service/${inventoryServiceId}`,
    )
    .then(({data}) => data);

export const getZeroStockItemCountForGroup = (
  groupId: number,
  inventoryServiceId: number,
): Promise<ItemReOrderLevelResponse> =>
  inventoryInstance
    .get<ItemReOrderLevelResponse>(
      INVENTORY_SERVICE + `zero-stock-items/group/${groupId}/group-inventory-service/${inventoryServiceId}`,
    )
    .then(({data}) => data);

export interface ItemReOrderLevelResponse {
  message: string;
  result: any;
  status: string;
  statusCode: string;
}
