import {BASE_COLORS} from '@app/styles/themes/constants';
import {Collapse, Typography} from 'antd';
import styled from 'styled-components';

export const TotalPriceTitle = styled.span`
  font-size: 20px;
  color: ${BASE_COLORS.black};
  font-weight: 700;
`;
export const TotalPriceMiniTitle = styled.span`
  font-size: 12px;
  color: ${BASE_COLORS.black};
  font-weight: 400;
`;

export const TotalPriceExcExtraHourTitle = styled.span`
  font-size: 15px;
  color: ${BASE_COLORS.black};
  font-weight: 400;
`;
export const DuePaymentTitle = styled.span`
  font-size: 15px;
  color: ${BASE_COLORS.red};
  font-weight: 900;
`;

export const BlurCardWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  gap: 1rem;
`;
export const BlueCard = styled.div`
  border: 1px solid var(--border-color);
  width: 100%;
  border-radius: 8px;
  background-color: ${BASE_COLORS.lightBlue};
`;
export const Padding = styled.div`
  padding: 1rem;
`;

export const TaxInformationWapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;
export const TaxLeftWrapper = styled.div`
  display: flex;
  margin-top: 2px;
`;
export const TaxRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;
export const TaxInfoText = styled(Typography)`
  font-size: 14px;
  color: ${BASE_COLORS.opacityOne};
  font-weight: 400;
  line-height: 20px;
`;
export const ListTaxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const ListTaxRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const PriceRow = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CustomCollape = styled(Collapse)`
  &&.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0 !important;
  }
  &&.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    margin-right: 8px;
    vertical-align: 2px;
    font-size: 12px;
  }
  &&.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0px;
    padding-bottom: 5px;
    padding-right: 0;
    padding-left: 22px;
  }
`;
