import React from 'react';
import {Column, ColumnConfig} from '@ant-design/plots';
import {each, groupBy} from 'lodash';
import {IResidencyGraphData} from '../HotelDashboardPage';

interface IProps {
  residencyGraphData: IResidencyGraphData[];
}
const ResidencyGraph: React.FC<IProps> = props => {
  const {residencyGraphData} = props;
  const [roomReservationStatisticsData, setRoomReservationStatisticsData] = React.useState<IResidencyGraphData[]>([]);

  React.useEffect(() => {
    setRoomReservationStatisticsData(residencyGraphData);
  }, [residencyGraphData]);

  const annotations: any[] = [];
  each(groupBy(roomReservationStatisticsData, 'month'), (values: any, k) => {
    const value = values.reduce((a: any, b: any) => a + b.value, 0);
    annotations.push({
      type: 'text',
      position: [k, value],
      content: `${value}`,
      style: {
        textAlign: 'center',
        fontSize: 14,
        fill: 'rgba(0,0,0,0.85)',
      },
      offsetY: -10,
    });
  });

  const config: ColumnConfig = {
    data: roomReservationStatisticsData,
    isStack: true,
    xField: 'month',
    yField: 'percentage',
    seriesField: 'type',
    yAxis: {
      title: {
        text: 'Residency Percentage',
      },
      min: 0,
      max: 100,

      label: {
        formatter: (value: string) => `${value}`,
      },
      line: {
        style: {
          stroke: '#D2D3D4',
          lineWidth: 1.5,
        },
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
      title: {
        text: 'Months',
      },
    },
    legend: {
      position: 'top',
      name: {
        formatter: (text: string) => {
          // Customize the legend label names
          if (text === 'booked') return 'Custom Label 1';
          if (text === 'type2') return 'Custom Label 2';
          // Add more customizations as needed
          return text; // Fallback to original name
        },
      },
    },
    label: {
      content: ({percentage}) => `${percentage === 0 ? '' : `${percentage}%`}`,
      style: {
        fontSize: 12,
        fontWeight: 700,
      },
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
    color: ['#c77cff', '#00bfc4'],

    meta: {
      type: {
        alias: 'numberOfNight',
      },
      guest: {
        alias: 'Room night count',
        formatter: (value: string) => `${value}`,
      },
      percentage: {
        alias: 'Percentage',
      },
    },
    columnStyle: {
      borderRadius: 25,
    },
    // annotations,
  };

  return <Column {...config} />;
};

export default React.memo(ResidencyGraph);
