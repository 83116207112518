import styled from 'styled-components';
import {Card as CommonCard} from 'components/common/Card/Card';
import {Button} from 'antd';

export const TablesWrapper = styled.div`
  width: 100%;
`;

export const Card = styled(CommonCard)`
  .ant-card-extra {
    padding: 12px 0;
  }

  .ant-card-head-title {
    padding: 0px 0;
    font-size: 20px;
  }
  height: auto;
  margin-top: 1rem;
`;
export const Btn = styled(Button)`
  display: block;
  width: 100%;
  height: revert;
  font-weight: 500;
  min-width: 64px;
  padding: 6px 16px;
  font-size: 14px;
  border-radius: 6px;
`;
