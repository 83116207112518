/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {FC, useState} from 'react';
import * as S from './PriceCalculator.style';
import {isEmpty} from 'lodash';
import {convertNumberFormatWithDecimal} from '@app/utils/utils';
import {Col, Collapse, Divider, Row, Tag} from 'antd';
import {Button} from '../buttons/Button/Button';
import {ConvertedDataProps} from '@app/pages/Hotel/ServiceReservation/helper/interface';
import * as _ from 'lodash';

interface Props {
  currency: string;
  isVatApplicable: boolean;
  isRetiveAction?: boolean;
  handlePayNow?: (data: any) => void;
  serviceData: ConvertedDataProps;
  isCheckedInReservation: boolean;
}
const {Panel} = Collapse;

const ServicePriceCalculator: FC<Props> = ({
  currency,
  isRetiveAction = false,
  handlePayNow,
  serviceData,
  isCheckedInReservation,
}) => {
  const [paymentMethod, setpaymentMethod] = useState('CASH');

  const taxList = _.orderBy(serviceData.sumOfReservedServiceTaxes, ['sequence'], ['asc']);
  const bookedServices =
    serviceData &&
    serviceData?.additionalServiceSingleReservationResponseList?.filter(
      x => x.additionalServiceReservationStatus === 'BOOKED',
    );

  return (
    <S.BlurCardWrapper>
      <S.BlueCard>
        <S.Padding>
          <S.PriceWrapper>
            <S.TotalPriceTitle>Total Price</S.TotalPriceTitle>
            <S.TotalPriceTitle>{`${currency} ${convertNumberFormatWithDecimal(
              serviceData.totalCharge,
              2,
            )}`}</S.TotalPriceTitle>
          </S.PriceWrapper>

          {!isEmpty(bookedServices) ? (
            <S.ListTaxWrapper style={{marginTop: '1rem'}}>
              {bookedServices &&
                bookedServices.map((service, idx) => {
                  return (
                    <S.CustomCollape style={{padding: 0}} key={`tax-list${idx}`} ghost>
                      <Panel
                        header={
                          <S.ListTaxRow>
                            <S.TaxInfoText>{` ${service.service}`}</S.TaxInfoText>
                            <S.TaxInfoText>{`${currency} ${convertNumberFormatWithDecimal(
                              service.totalPrice,
                              2,
                            )}`}</S.TaxInfoText>
                          </S.ListTaxRow>
                        }
                        key={idx}>
                        <S.ListTaxRow>
                          <S.TaxInfoText>Original Price</S.TaxInfoText>
                          <S.TaxInfoText>{`${currency} ${convertNumberFormatWithDecimal(
                            service.totalPriceWithOutDiscount,
                            2,
                          )}`}</S.TaxInfoText>
                        </S.ListTaxRow>
                        <S.ListTaxRow>
                          <S.TaxInfoText>Discount</S.TaxInfoText>
                          {service.discountType === 'AMOUNT' ? (
                            <S.TaxInfoText>{`${currency} ${convertNumberFormatWithDecimal(
                              service.discount,
                              2,
                            )}`}</S.TaxInfoText>
                          ) : (
                            <S.TaxInfoText>{`${service.discount}%`}</S.TaxInfoText>
                          )}
                        </S.ListTaxRow>

                        {service.hallId === null ? (
                          <S.ListTaxRow>
                            <S.TaxInfoText>Count</S.TaxInfoText>
                            <S.TaxInfoText>{service.count}</S.TaxInfoText>
                          </S.ListTaxRow>
                        ) : null}
                        <S.ListTaxRow>
                          <S.TaxInfoText>Event Time</S.TaxInfoText>
                          <S.TaxInfoText>{service.hallTime}</S.TaxInfoText>
                        </S.ListTaxRow>
                        {service.hallTime === 'PER_HOUR' && service.noOfHours > 0 ? (
                          <S.ListTaxRow>
                            <S.TaxInfoText>Hours</S.TaxInfoText>
                            <S.TaxInfoText>{service.noOfHours}</S.TaxInfoText>
                          </S.ListTaxRow>
                        ) : null}
                        {service.hallTime === 'FULL_DAY' && service.noOfDays > 0 ? (
                          <S.ListTaxRow>
                            <S.TaxInfoText>Days</S.TaxInfoText>
                            <S.TaxInfoText>{service.noOfDays}</S.TaxInfoText>
                          </S.ListTaxRow>
                        ) : null}
                        <S.ListTaxRow>
                          <S.TaxInfoText>Extra Hours</S.TaxInfoText>
                          <S.TaxInfoText>{service.extraHours}</S.TaxInfoText>
                        </S.ListTaxRow>
                      </Panel>
                    </S.CustomCollape>
                  );
                })}
            </S.ListTaxWrapper>
          ) : null}

          {!isEmpty(taxList) ? (
            <S.TaxInformationWapper>
              <S.TaxRightWrapper>
                <S.TaxInfoText>{`Excludes ${currency} ${convertNumberFormatWithDecimal(
                  serviceData.totalTax,
                  2,
                )} in taxes and charges`}</S.TaxInfoText>
                <S.ListTaxWrapper>
                  {taxList.map((tax, idx) => {
                    return (
                      <S.ListTaxRow key={`tax-list${idx}`}>
                        <S.TaxInfoText>{` ${tax.taxName}`}</S.TaxInfoText>
                        <S.TaxInfoText>{`${currency} ${convertNumberFormatWithDecimal(tax.amount, 2)}`}</S.TaxInfoText>
                      </S.ListTaxRow>
                    );
                  })}
                </S.ListTaxWrapper>
              </S.TaxRightWrapper>
            </S.TaxInformationWapper>
          ) : null}

          {isRetiveAction && !isCheckedInReservation && (
            <>
              <Divider />
              <S.ListTaxRow style={{marginTop: '1rem'}}>
                <S.PaidAmountText>Paid Amount</S.PaidAmountText>
                <S.PaidAmountText>{`${currency} ${convertNumberFormatWithDecimal(
                  serviceData.paidAmount,
                  2,
                )}`}</S.PaidAmountText>
              </S.ListTaxRow>
              <S.ListTaxRow style={{marginTop: '1rem'}}>
                <S.DuePaymentText>Due Amount</S.DuePaymentText>
                <S.DuePaymentText>{`${currency} ${convertNumberFormatWithDecimal(
                  serviceData.outStanding,
                  2,
                )}`}</S.DuePaymentText>
              </S.ListTaxRow>
              <Divider />
              {serviceData.outStanding > 0 ? (
                <Row justify="end" gutter={{xs: 10, md: 15, xl: 30}}>
                  <Col>
                    <Button onClick={() => handlePayNow && handlePayNow(paymentMethod)} type="primary">
                      Pay Now
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Tag color="green">Amount Paid</Tag>
              )}
            </>
          )}
        </S.Padding>
      </S.BlueCard>
    </S.BlurCardWrapper>
  );
};

export default ServicePriceCalculator;
