import axios from 'axios';
import {API_KEY_ENV} from './instance';

export const BASE_URL = `${process.env.NODE_ENV === 'production' ? API_KEY_ENV : process.env.REACT_APP_BASE_URL}:9095/`;

const houseKeepingInstance = axios.create({
  baseURL: BASE_URL,
});

houseKeepingInstance.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers = {...config.headers};
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default houseKeepingInstance;
