import axios from 'axios';
import {API_KEY_ENV} from '../instance';

export const BASE_URL = `${process.env.NODE_ENV === 'production' ? API_KEY_ENV : process.env.REACT_APP_BASE_URL}:9091/`;

const webBookingInstance = axios.create({
  baseURL: BASE_URL,
});

export default webBookingInstance;
