import instance, {HOTEL_SERVICE} from '@app/api/instance';
import {IUpdateDiscountPayload, IUpdateExtraHourPayload} from '@app/pages/Hotel/ServiceReservation/helper/interface';
import {
  ICancelPayload,
  ICreatePayload,
  ICreateServiceBooking,
  IUpdatePayload,
} from '@app/pages/Hotel/ServiceReservation/interface';

export const getAvailableAdditionalServices = (
  hotelId: number,
  resident: boolean,
  checkInDate: string,
): Promise<Response> =>
  instance
    .get<Response>(
      HOTEL_SERVICE +
        `additional-service-reservations/available-service?hotelId=${hotelId}&resident=${resident}&checkInDate=${checkInDate}`,
    )
    .then(({data}) => data);

export const getAdditionalServiceCancellationPolicy = (reservationId: number): Promise<Response> =>
  instance
    .get<Response>(HOTEL_SERVICE + `additional-service-reservation/cancellation/${reservationId}`)
    .then(({data}) => data);

export const getCheckedInReservations = (hotelId: number): Promise<Response> =>
  instance.get<Response>(HOTEL_SERVICE + `reserved-room/available-service?hotelId=${hotelId}`).then(({data}) => data);

export const createAdditionalBooking = (payload: ICreateServiceBooking): Promise<Response> => {
  return instance.post<Response>(HOTEL_SERVICE + 'additional-service-reservation', payload).then(({data}) => data);
};

export const updateAdditionalBooking = (payload: any): Promise<Response> => {
  return instance.put<Response>(HOTEL_SERVICE + 'additional-service-reservation', payload).then(({data}) => data);
};

export const cancelAdditionalBooking = (payload: ICancelPayload[]): Promise<Response> => {
  return instance
    .put<Response>(HOTEL_SERVICE + 'additional-service-reservation/cancel', payload)
    .then(({data}) => data);
};

export const getAllBookedAdditionalServices = (
  hotelId: number,
  {ownerName, refNumber}: FilterPropsQurey,
): Promise<Response> =>
  instance
    .get<Response>(
      HOTEL_SERVICE +
        `additional-service-reservations/search/${hotelId}?refNumber=${refNumber ? refNumber : ''}&ownerName=${
          ownerName ? ownerName : ''
        }`,
    )
    .then(({data}) => data);

export const getAllAdditionalServices = (
  hotelId: number,
  {ownerName, refNumber}: FilterPropsQurey,
): Promise<Response> =>
  instance
    .get<Response>(
      HOTEL_SERVICE +
        `additional-service-reservation/all/${hotelId}?refNumber=${refNumber ? refNumber : ''}&ownerName=${
          ownerName ? ownerName : ''
        }`,
    )
    .then(({data}) => data);

export const getServiceBookingsByBookingId = (id: number): Promise<Response> =>
  instance.get<Response>(HOTEL_SERVICE + `additional-service-reservation/main/${id}`).then(({data}) => data);

export const updateExtraHours = (payload: IUpdateExtraHourPayload): Promise<Response> =>
  instance.put<Response>(HOTEL_SERVICE + `additional-service-reservation/extra-hours`, payload).then(({data}) => data);

export const updateDiscount = (payload: IUpdateDiscountPayload): Promise<Response> =>
  instance.put<Response>(HOTEL_SERVICE + `additional-service-reservation/discount`, payload).then(({data}) => data);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const servicePayment = (payload: any): Promise<Response> =>
  instance.put<Response>(HOTEL_SERVICE + `additional-service-reservation/payment`, payload).then(({data}) => data);

export const getAllBookedAdditionalServicesHistory = (
  hotelId: number,
  {additionalServiceReservationStatus, ownerName, refNumber}: FilterProps,
  pageSize: number | undefined,
  current: number,
): Promise<Response> =>
  instance
    .get<Response>(
      HOTEL_SERVICE +
        `additional-service-reservationpagination/search?page=${current}&size=${pageSize}&sortField=id&direction=DESC&hotelId=${hotelId}&additionalServiceReservationStatus=${additionalServiceReservationStatus}&refNumber=${
          refNumber ? refNumber : ''
        }&ownerName=${ownerName ? ownerName : ''}`,
    )
    .then(({data}) => data);

export interface Response {
  message: string;
  result: any;
  status: string;
  statusCode: string;
}

export interface FilterProps {
  additionalServiceReservationStatus: string[];
  refNumber?: string;
  ownerName?: string;
}
export interface FilterPropsQurey {
  refNumber?: string;
  ownerName?: string;
}
