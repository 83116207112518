import React, {memo} from 'react';
import {BasicTable} from '../BasicTable/BasicTable';
import {useTranslation} from 'react-i18next';
import * as S from './Tables.styles';
import {Modal} from '@app/components/common/Modal/Modal';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {setLoading, setModalVisible} from '@app/store/slices/commonSlice';
import {Space, TablePaginationConfig} from 'antd';
import _ from 'lodash';

import {Drawer} from '@app/components/common/Drawer/Drawer';
import {Button} from '@app/components/common/buttons/Button/Button';
import {ExpandableConfig} from 'antd/lib/table/interface';
import {TableRowSelection} from 'antd/lib/table/interface';

interface Props {
  title?: string;
  tableData?: any[];
  columns?: any[];
  isModal?: boolean;
  modalTitle?: string;
  modalChildren?: React.ReactNode;
  modalSize?: 'small' | 'medium' | 'large' | 'xm' | 'xl' | 'ml';
  modalFooter?: React.ReactNode;
  addButtonText?: string;
  onCancelModal?: () => void;
  searchFields?: string[];
  searchPlaceholderNames?: any;
  onChangeFilter?: (pagination: TablePaginationConfig, filters: any) => void;
  onChangeSearch?: (values: any) => void;
  isSearched?: boolean;
  resetAllSearch?: () => void;
  setSearchObject?: () => void;
  searchObject?: any;
  pagination?: TablePaginationConfig;
  extraHeader?: React.ReactNode;
  drawerChildren?: React.ReactNode;
  isDrawer?: boolean;
  drawerWidth?: number;
  isCreate?: boolean;
  components?: any;
  rowClassName?: any;
  showPagination?: boolean;
  bordered?: boolean;
  expandable?: ExpandableConfig<any>;
  size?: 'small' | 'middle' | 'large';
  rowSelection?: TableRowSelection<any>;
  className?: string;
  scroll?: boolean;
  scorollValues?: {x?: number; y?: number};
  isExtra?: boolean;
  summary?: any;
  loading?: boolean;
}
export const Tables: React.FC<Props> = memo(props => {
  const {
    title,
    tableData,
    columns,
    modalChildren,
    modalSize,
    modalFooter,
    onCancelModal,
    isModal = true,
    modalTitle = 'Save',
    addButtonText = 'Create',
    searchFields = [],
    searchPlaceholderNames = {},
    onChangeFilter,
    onChangeSearch,
    isSearched,
    resetAllSearch,
    setSearchObject,
    searchObject = {},
    pagination,
    extraHeader,
    drawerChildren,
    isDrawer = false,
    drawerWidth,
    isCreate = true,
    components,
    rowClassName,
    showPagination,
    bordered = true,
    expandable,
    size,
    rowSelection,
    className,
    scroll,
    scorollValues,
    isExtra = true,
    summary,
    loading,
  } = props;

  const {t} = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();
  const modalVisible = useAppSelector(state => state.commonSlice.modalVisible);
  return (
    <>
      <S.TablesWrapper>
        <S.Card
          extra={
            isExtra && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {extraHeader}
                {isCreate && (
                  <S.Btn
                    onClick={() => {
                      dispatch(setModalVisible(true));
                      dispatch(setLoading(false));
                    }}
                    type="primary"
                    hidden={!isModal}>
                    {addButtonText}
                  </S.Btn>
                )}
              </div>
            )
          }
          id="basic-table"
          title={
            !isCreate
              ? title && (
                  <div
                    style={{
                      minWidth: '64px',
                      padding: '11px 0px',
                    }}>
                    {title}
                  </div>
                )
              : title
          }
          padding="0rem 0.5rem 0">
          <BasicTable
            scoroll={scroll}
            scorollValues={scorollValues}
            bordered={bordered}
            rowSelection={rowSelection}
            size={size}
            searchFields={searchFields}
            searchPlaceholderNames={searchPlaceholderNames}
            tableData={tableData}
            columns={columns}
            onChangeFilter={onChangeFilter}
            onChangeSearch={onChangeSearch}
            setSearchObject={setSearchObject}
            searchObject={searchObject}
            pagination={pagination}
            components={components}
            rowClassName={rowClassName}
            showPagination={showPagination}
            expandable={expandable}
            className={className}
            summary={summary}
            loading={loading}
          />
        </S.Card>
      </S.TablesWrapper>
      {!isDrawer && (
        <Modal
          footer={modalFooter}
          size={modalSize}
          onCancel={() => {
            dispatch(setModalVisible(false));
            onCancelModal && onCancelModal();
          }}
          onOk={() => form.submit()}
          title={modalTitle}
          open={modalVisible}>
          {modalChildren}
        </Modal>
      )}
      {isDrawer && (
        <Drawer
          width={drawerWidth}
          onClose={() => {
            dispatch(setModalVisible(false));
            onCancelModal && onCancelModal();
          }}
          title="Create"
          extra={
            <Space>
              <Button onClick={() => dispatch(setModalVisible(false))}>Cancel</Button>
              <Button type="primary" onClick={() => dispatch(setModalVisible(false))}>
                Create
              </Button>
            </Space>
          }
          open={modalVisible}>
          {drawerChildren}
        </Drawer>
      )}
    </>
  );
});
