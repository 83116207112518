import React from 'react';
import {Pie, G2, PieConfig} from '@ant-design/plots';
import {IRoomTypeStatisticsData} from '../HotelDashboardPage';

interface IProps {
  roomTypeReservationData: IRoomTypeStatisticsData[];
}
const ReservationTypeCountGraph: React.FC<IProps> = props => {
  const {roomTypeReservationData} = props;
  const [roomTypeReservationStatisticsData, setRoomTypeReservationStatisticsData] = React.useState<
    IRoomTypeStatisticsData[]
  >([]);

  React.useEffect(() => {
    setRoomTypeReservationStatisticsData(roomTypeReservationData);
  }, [roomTypeReservationData]);

  const G = G2.getEngine('canvas');

  const cfg: PieConfig = {
    appendPadding: 10,
    data: roomTypeReservationStatisticsData,
    angleField: 'value',
    colorField: 'type',
    radius: 0.75,
    legend: false,
    label: {
      position: 'top',
      type: 'spider',
      labelHeight: 40,
      formatter: (data, mappingData) => {
        const group = new G.Group({});
        group.addShape({
          type: 'circle',
          attrs: {
            x: 0,
            y: 0,
            width: 40,
            height: 50,
            r: 5,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: 'text',
          attrs: {
            x: 12,
            y: 8,
            width: 500,
            text: `${data.type}`,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: 'text',
          attrs: {
            x: 0,
            y: 25,
            text: `${data.value} | ${Number(data.percent * 100).toFixed(0)}%`,
            fill: 'rgba(0, 0, 0, 0.65)',
            fontWeight: 700,
          },
        });
        return group;
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };
  const config: any = cfg;
  return <Pie {...config} />;
};
export default React.memo(ReservationTypeCountGraph);
