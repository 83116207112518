import {LAYOUT} from '@app/styles/themes/constants';
import {media} from '@app/styles/themes/constants';
import {Layout} from 'antd';
import styled, {css} from 'styled-components';

interface Header {
  $isTwoColumnsLayoutHeader: boolean;
}

export const Header = styled(Layout.Header)<Header>`
  line-height: 1.5;
  box-shadow: -3px -5px 12px -1px rgba(0, 0, 0, 0.75);

  @media only screen and ${media.md} {
    padding: 0.2rem 1.5rem;
    height: 3.4rem;
    box-shadow: -3px -5px 12px -1px rgba(0, 0, 0, 0.75);
  }

  @media only screen and ${media.md} {
    ${props =>
      props?.$isTwoColumnsLayoutHeader &&
      css`
        padding: 0;
      `}
  }
`;
