import React, {useState, useEffect, useCallback} from 'react';
import {useNavigate, Link, useParams, useLocation} from 'react-router-dom';
import {Image, Spin} from 'antd';
import {useTranslation} from 'react-i18next';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {VerificationCodeInput} from '@app/components/common/VerificationCodeInput/VerificationCodeInput';
import {useAppDispatch} from '@app/hooks/reduxHooks';
import {doVerifySecurityCode} from '@app/store/slices/authSlice';
import {notificationController} from '@app/controllers/notificationController';
import VerifyEmailImage from '@app/assets/images/verify-email.webp';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SecurityCodeForm.styles';
import {forgotPasswordOtpVaility} from '@app/api/user/user.api';

interface SecurityCodeFormProps {
  onBack?: () => void;
  onFinish?: () => void;
}

export const SecurityCodeForm: React.FC<SecurityCodeFormProps> = ({onBack, onFinish}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const location: any = useLocation();
  const userName = location?.state?.userName;

  const navigateBack = useCallback(() => navigate(-1), [navigate]);
  const navigateForward = useCallback(() => navigate('/auth/new-password'), [navigate]);

  const [securityCode, setSecurityCode] = useState('');
  const [isLoading, setLoading] = useState(false);

  const CheckforgotPasswordOtp = async (token: string) => {
    try {
      const result = await forgotPasswordOtpVaility(token, userName);
      setLoading(false);
      if (result.statusCode === '20000') {
        notificationController.success({message: 'OTP Verified Successfully'});
        navigate('/auth/new-password', {state: {token}});
        // await onCloseModal();
      } else {
        notificationController.error({message: 'OTP Verification Faild'});
        // dispatch(setLoading(false));
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (securityCode.length === 6) {
      setLoading(true);
      CheckforgotPasswordOtp(securityCode);
      // dispatch(doVerifySecurityCode({code: securityCode}))
      //   .unwrap()
      //   .then(onFinish || navigateForward)
      //   .catch(err => {
      //     notificationController.error({message: err.message});
      //     setLoading(false);
      //   });
    }
  }, [securityCode, navigateForward, onFinish, dispatch]);

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" requiredMark="optional">
        <Auth.BackWrapper onClick={onBack || navigateBack}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <S.ContentWrapper>
          <S.ImageWrapper>
            <Image src={VerifyEmailImage} alt="Not found" preview={false} />
          </S.ImageWrapper>
          <Auth.FormTitle>{t('securityCodeForm.title')}</Auth.FormTitle>
          <S.VerifyEmailDescription>{t('common.verifCodeSent')}</S.VerifyEmailDescription>
          {isLoading ? <Spin /> : <VerificationCodeInput autoFocus onChange={setSecurityCode} />}
          <Link to="/" target="_blank">
            <S.NoCodeText>{t('securityCodeForm.noCode')}</S.NoCodeText>
          </Link>
        </S.ContentWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
