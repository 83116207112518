import axios from 'axios';
import {API_KEY_ENV} from './instance';

export const RESTAURANT_SERVICE = 'restaurant-service/api/v1/';
export const RESTAURANT_WS_SERVICE = `${
  process.env.NODE_ENV === 'production' ? API_KEY_ENV : process.env.REACT_APP_BASE_URL
}:9094/ws`;

const restInstance = axios.create({
  baseURL: `${process.env.NODE_ENV === 'production' ? API_KEY_ENV : process.env.REACT_APP_BASE_URL}:9092/`,
});

restInstance.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('accessToken');

  if (token) {
    config.headers = {...config.headers};
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default restInstance;
