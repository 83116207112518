import React, {FC} from 'react';
import * as S from '../ServiceReservation.style';
import {Descriptions} from 'antd';
import {ConvertedDataProps} from '../helper/interface';

interface Props {
  data: ConvertedDataProps;
}

const ServiceDescription: FC<Props> = ({data}) => {
  return (
    <S.BlurCardWrapper>
      <S.BlueCard>
        <S.Padding>
          <Descriptions>
            <Descriptions.Item label="Booking No">{data?.refNumber ? data?.refNumber : ''}</Descriptions.Item>
            {data?.additionalServiceForReservedRoomResponseList?.reservedRoomNumber ? (
              <Descriptions.Item label="Reservation No">
                {data?.additionalServiceForReservedRoomResponseList?.reservationRefNumber
                  ? data?.additionalServiceForReservedRoomResponseList?.reservationRefNumber
                  : ''}
              </Descriptions.Item>
            ) : null}
            <Descriptions.Item label="Booked Date">{data?.checkInDate ? data?.checkInDate : ''}</Descriptions.Item>
            <Descriptions.Item label="Booked Time">{data?.checkInTime ? data?.checkInTime : ''}</Descriptions.Item>
            <Descriptions.Item label="Comments">{data?.comments ? data?.comments : ''}</Descriptions.Item>
            {data?.roomNumber && (
              <Descriptions.Item label="Room No">{data?.roomNumber ? data?.roomNumber : ''}</Descriptions.Item>
            )}
          </Descriptions>
        </S.Padding>
      </S.BlueCard>
    </S.BlurCardWrapper>
  );
};

export default ServiceDescription;
