import React, {useState} from 'react';
import {Col, Divider, Row} from 'antd';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {useResponsive} from '@app/hooks/useResponsive';
import * as S from './DashboardPage.styles';
import {useAppSelector} from '@app/hooks/reduxHooks';
import {
  getAllReservationStatistics,
  getAllResidencyStatistics,
  getLeadTypeCountStatistics,
  getReservationTypeCount,
  getReservationTypeCountStatistics,
  getRoomTypeCountStatistics,
  getSeasonCountStatistics,
} from '@app/api/hotel/reservationStatistics.api';
import SockJS from 'sockjs-client';
import {Stomp} from '@stomp/stompjs';
import {RESTAURANT_WS_SERVICE} from '@app/api/resturantInstance';
import SeasonVsReservation from './charts/SeasonVsReservation';
import OccupancyGraph from './charts/OccupancyGraph';
import ResidencyGraph from './charts/ResidencyGraph';
import ReservationTypeGraph from './charts/ReservationTypeGraph';
import RoomTypeVsReservation from './charts/RoomTypeVsReservation';
import LeadTypeGraph from './charts/LeadTypeGraph';
import AdvanceDateFilter from '@app/components/common/DateRangeFilter/AdvanceDateFilter';
import ReservationTypeCountGraph from './charts/ReservationTypeCountGraph';
import moment from 'moment';

export interface IOccupancyGraphData {
  month: string;
  percentage: string;
  value: number;
  type: string;
}
export interface IResidencyGraphData {
  month: string;
  percentage: string;
  value: number;
  type: string;
}

export interface IReservationTypeGraph {
  bookedNumberOfNight: number;
  month: string;
  numberOfReservationTypeReservation: number;
  numberOfReservationTypeReservationPercentage: number;
  numberOfTotalReservation: number;
  totalNumberOfNight: number;
  type: string;
  typeBookedNumberOfNight: number;
  typeBookedPercentage: number;
}
export interface IReservationTypeGraphData {
  graphData: IReservationTypeGraph[];
  colors: string[];
}

export interface IRoomTypeStatisticsData {
  type: string;
  value: number;
  valuePercentage: number;
  numOfReservation: number;
}
export interface IReservationTypeCountData {
  type: string;
  value: number;
  valuePercentage: number;
  numOfReservation: number;
}
export interface ILeadTypeStatisticsData {
  type: string;
  value: number;
  valuePercentage: number;
  numOfReservation: number;
}

export interface ISeasonStatisticsData {
  type: string;
  value: number;
  valuePercentage: number;
  numOfReservation: number;
}

export interface IDateRange {
  startDate: string;
  endDate: string;
}

const HotelDashboardPage: React.FC = () => {
  const {isDesktop} = useResponsive();
  const hotelConfig = useAppSelector(state => state.hotelSlice.hotelConfig);

  const [occupancyGraphData, setOccupancyGraphData] = React.useState<IOccupancyGraphData[]>([]);
  const [residencyGraphData, setResidencyGraphData] = React.useState<IResidencyGraphData[]>([]);
  const [reservationTypeGraph, setReservationTypeGraph] = React.useState<IReservationTypeGraphData>({
    graphData: [],
    colors: [],
  });
  const [reservationTypeCountGraphData, setReservationTypeCountGraphData] = React.useState<IReservationTypeCountData[]>(
    [],
  );
  const [roomTypeReservationGraphData, setRoomTypeReservationGraphData] = React.useState<IRoomTypeStatisticsData[]>([]);
  const [leadTypeReservationGraphData, setLeadTypeReservationGraphData] = React.useState<ILeadTypeStatisticsData[]>([]);
  const [seasonReservationGraphData, setSeasonReservationGraphData] = React.useState<ISeasonStatisticsData[]>([]);
  const [socketTrigger, setsocketTrigger] = useState(false);

  //filter states

  const currentYear = moment().year();
  const startDateOfYear = moment().year(currentYear).startOf('year').format('YYYY-MM-DD');
  const endDateOfYear = moment().year(currentYear).endOf('year').format('YYYY-MM-DD');

  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange>({
    startDate: startDateOfYear,
    endDate: endDateOfYear,
  });
  const [showLeadTypeReservationFilter, setShowLeadTypeReservationFilter] = React.useState<boolean>(false);

  React.useEffect(() => {
    let stompClient: any = null;

    const WebSocketClient = (url: string) => {
      const sock = new SockJS(RESTAURANT_WS_SERVICE);
      stompClient = Stomp.over(sock);

      sock.onopen = function () {
        console.log('onopen');
      };

      stompClient.connect({}, (frame: any) => {
        stompClient.subscribe(url, data => {
          const receivedData: any = JSON.parse(data.body);
          const key: any = Object.keys(receivedData)[0];
          const action: any = receivedData[key];

          if (receivedData) {
            reloadGraphData();
          }
        });
      });
      stompClient.activate();
    };

    WebSocketClient(`/reservation/${hotelConfig.hotelId}`);

    return () => {
      if (stompClient) {
        stompClient.disconnect();
      }
    };
  }, []);

  React.useEffect(() => {
    reloadGraphData();
  }, [socketTrigger]);

  const reloadGraphData = async () => {
    Promise.all([
      listOccupancyGraphStatistics(hotelConfig.hotelId),
      listResidencyGraphStatistics(hotelConfig.hotelId),
      listReservationTypeStatistics(hotelConfig.hotelId),
      listReservationTypeCountStatistics(hotelConfig.hotelId, selectedDateRange),
      listRoomTypeReservationStatistics(hotelConfig.hotelId),
      listSeasonReservationStatistics(hotelConfig.hotelId),
      listLeadTypeReservationStatistics(hotelConfig.hotelId),
    ]);
  };

  const listOccupancyGraphStatistics = async (hotelId: number) => {
    try {
      const results: any = await getAllReservationStatistics(hotelId);

      const convertedData: IOccupancyGraphData[] = [];
      results?.result?.room?.map((post: any) => {
        convertedData.push({
          month: post.month,
          value: post.nonBookingNumberOfNight,
          percentage:
            post.nonBookingPercentage === 0 || post.nonBookingPercentage === 100 ? '' : `${post.nonBookingPercentage}%`,
          type: 'Non Booked',
        });
        convertedData.push({
          month: post.month,
          value: post.numberOfNight,
          percentage: post.percentage === 0 || post.percentage === 100 ? '' : `${post.percentage}%`,
          type: 'Booked',
        });
      });
      setOccupancyGraphData(convertedData);
    } catch (error) {}
  };

  const listResidencyGraphStatistics = async (hotelId: number) => {
    try {
      const results: any = await getAllResidencyStatistics(hotelId);

      const convertedData: IResidencyGraphData[] = [];
      results?.result?.room?.map((post: any) => {
        convertedData.push({
          month: post.month.toString().toUpperCase(),
          value: post.residentBooked,
          percentage: post.residentPercentage,
          type: 'Resident',
        });
        convertedData.push({
          month: post.month.toString().toUpperCase(),
          value: post.nonResidentBooked,
          percentage: post.nonResidentPercentage,
          type: 'Non Resident',
        });
      });

      setResidencyGraphData(convertedData);
    } catch (error) {}
  };

  const listReservationTypeStatistics = async (hotelId: number) => {
    try {
      const results: any = await getReservationTypeCountStatistics(hotelId);
      const reservationTypeResponseList: IReservationTypeGraph[] =
        results?.result?.room?.reservationReservationTypeCountResponseList;
      const colors: string[] = results?.result?.room?.reservationTypeResponseList.map((post: any) => post.notifyColor);
      setReservationTypeGraph({
        colors,
        graphData: reservationTypeResponseList,
      });
    } catch (error) {}
  };

  const listReservationTypeCountStatistics = async (hotelId: number, dateRange: IDateRange) => {
    try {
      const results: any = await getReservationTypeCount(hotelId, dateRange);
      const reservationTypeResponseList: any[] = results?.result?.room;
      // const colors: string[] = results?.result?.room?.reservationTypeResponseList.map((post: any) => post.notifyColor);

      const data: IReservationTypeCountData[] = reservationTypeResponseList.map(post => {
        return {
          type: post.type,
          value: post.numOfReservationTypeReservation,
          valuePercentage: post.valuePercentage,
          numOfReservation: post.numOfReservation,
        };
      });
      setReservationTypeCountGraphData(data);
    } catch (error) {}
  };

  const listRoomTypeReservationStatistics = async (hotelId: number) => {
    try {
      const results: any = await getRoomTypeCountStatistics(hotelId);
      setRoomTypeReservationGraphData(results?.result?.room);
    } catch (error) {}
  };

  const listSeasonReservationStatistics = async (hotelId: number) => {
    try {
      const results: any = await getSeasonCountStatistics(hotelId);
      setSeasonReservationGraphData(results?.result?.room);
    } catch (error) {}
  };

  const listLeadTypeReservationStatistics = async (hotelId: number) => {
    try {
      const results: any = await getLeadTypeCountStatistics(hotelId);
      setLeadTypeReservationGraphData(results?.result?.reservation);
    } catch (error) {}
  };

  const desktopLayout = (
    <S.LeftSideCol sm={24} md={24} xl={24} xxl={24}>
      <Row gutter={[30, 30]}>
        <Divider plain>
          <S.InnerTitle>Occupancy</S.InnerTitle>
        </Divider>
        <Col id="occupancy" sm={24} md={24} xl={24} xxl={24}>
          <OccupancyGraph occupancyGraphData={occupancyGraphData} />
        </Col>
        <Divider plain>
          <S.InnerTitle>Reservation By Residency</S.InnerTitle>
        </Divider>
        <Col id="residency" sm={24} md={24} xl={24} xxl={24}>
          <ResidencyGraph residencyGraphData={residencyGraphData} />
        </Col>
        <Divider plain style={{margin: 5}}>
          <S.InnerTitle>By Reservation Types</S.InnerTitle>
        </Divider>
        <Col id="channelType" sm={24} md={24} xl={24} xxl={24}>
          <ReservationTypeGraph reservationTypeData={reservationTypeGraph} />
        </Col>
        <Col id="roomtypecount" sm={24} md={24} xl={24} xxl={24}>
          <Divider plain style={{margin: 5}}>
            <S.InnerTitle>Reservation Type Count - Current Year</S.InnerTitle>
          </Divider>
          <AdvanceDateFilter
            key={1}
            getDateRange={range => {
              if (range.startDate === '' && range.endDate === '') {
                setSelectedDateRange({startDate: startDateOfYear, endDate: endDateOfYear});
                listReservationTypeCountStatistics(hotelConfig.hotelId, {
                  startDate: startDateOfYear,
                  endDate: endDateOfYear,
                });
              } else {
                setSelectedDateRange(range);
                listReservationTypeCountStatistics(hotelConfig.hotelId, range);
              }
            }}
          />
          <ReservationTypeCountGraph roomTypeReservationData={reservationTypeCountGraphData} />
        </Col>
        <Col id="roomtype" sm={24} md={12} xl={12} xxl={24}>
          <Divider plain style={{margin: 5}}>
            <S.InnerTitle>Roomtype Reservation - Current Year</S.InnerTitle>
          </Divider>
          <RoomTypeVsReservation roomTypeReservationData={roomTypeReservationGraphData} />
        </Col>
        <Col id="leadtype" sm={24} md={12} xl={12} xxl={24}>
          <Divider plain style={{margin: 5}}>
            <S.InnerTitle>Lead Type Reservation</S.InnerTitle>
          </Divider>
          <LeadTypeGraph roomTypeReservationData={leadTypeReservationGraphData} />
        </Col>
        <Col id="roomtype" sm={24} md={24} xl={24} xxl={24}>
          <Divider plain style={{margin: 5}}>
            <S.InnerTitle>Seasonal Reservation - Current Year</S.InnerTitle>
          </Divider>
          <SeasonVsReservation seasonReservationData={seasonReservationGraphData} />
        </Col>
      </Row>
    </S.LeftSideCol>
  );

  return (
    <>
      <PageTitle>Hotel Dashboard</PageTitle>
      {isDesktop ? desktopLayout : desktopLayout}
    </>
  );
};

export default HotelDashboardPage;

interface RoomCardProps {
  name: string;
  image: string;
  status?: string;
}
