import instance, {HOTEL_SERVICE} from '@app/api/instance';

export interface StayTypeRequest {
  id?: number;
  stayType: string;

  maxAdults: number;
  maxChildren: number;
  validationCombination?: boolean;
  allowToBookingEng?: boolean;
  allowToBookingWidjet?: boolean;
  description: string;
  meal: 'BREAKFAST' | 'LUNCH' | 'DINNER' | string;
  hotelId: number;
}

export interface TableCategoryResponse {
  message: string;
  result: any;
  status: string;
  statusCode: string;
  pagination: any;
}

export const CreateStaytype = (tableCategoryPayload: StayTypeRequest): Promise<TableCategoryResponse> =>
  instance.post<TableCategoryResponse>(HOTEL_SERVICE + 'stayType', {...tableCategoryPayload}).then(({data}) => data);

export const getAllStaytypeByRoomType = (roomTypeId: number): Promise<TableCategoryResponse> =>
  instance.get<TableCategoryResponse>(HOTEL_SERVICE + `stayType/room-type/${roomTypeId}`).then(({data}) => data);

export const getAllStaytype = (
  hotelId: number,
  searchFeilds: any,
  currentPage?: number,
  pageSize?: number,
): Promise<TableCategoryResponse> =>
  instance
    .get<TableCategoryResponse>(
      HOTEL_SERVICE +
        `stayType/room-type?page=${currentPage}&size=${pageSize}&sortField=id&maxAdults=${
          searchFeilds?.maxAdults ? searchFeilds?.maxAdults : ''
        }&stayType=${searchFeilds?.stayType ? searchFeilds?.stayType : ''}&roomTypeName=${
          searchFeilds?.roomTypeName ? searchFeilds?.roomTypeName : ''
        }&maxChildren=${searchFeilds?.maxChildren ? searchFeilds?.maxChildren : ''}&description=${
          searchFeilds?.description ? searchFeilds?.description : ''
        }&direction=DESC&hotelId=${hotelId}
  `,
    )
    .then(({data}) => data);

export const getAllAdultsRoomId = (roomId: number): Promise<any> =>
  instance
    .get<any>(
      HOTEL_SERVICE +
        `room/adult/${roomId}
        `,
    )
    .then(({data}) => data);

export const searchStayTypes = (reservedRoomId: number): Promise<any> =>
  instance
    .get<any>(
      HOTEL_SERVICE +
        `reserved-room/reserved-room/stayType/${reservedRoomId}
        `,
    )
    .then(({data}) => data);
export const searchMealTypesTypes = (reservedRoomId: number): Promise<any> =>
  instance
    .get<any>(
      HOTEL_SERVICE +
        `reserved-room/reserved-room/stayTypeMeal/${reservedRoomId}
        `,
    )
    .then(({data}) => data);

export const getAllChildsRoomId = (roomId: number, noOfAdults: number): Promise<any> =>
  instance
    .get<any>(
      HOTEL_SERVICE +
        `room/child/${roomId}?&adultCount=${noOfAdults}
            `,
    )
    .then(({data}) => data);

export const getAllRoomtype = (
  hotelId: number,
  searchFeilds: any,
  currentPage?: number,
  pageSize?: number,
): Promise<any> =>
  instance
    .get<any>(
      HOTEL_SERVICE +
        `room-type/search?page=${currentPage}&size=${pageSize}&sortField=id&direction=DESC&roomTypeName=${
          searchFeilds.roomTypeName ? searchFeilds.roomTypeName : ''
        }&capacity=${searchFeilds.capacity ? searchFeilds.capacity : ''}&numberOfRooms=${
          searchFeilds.numberOfRooms ? searchFeilds.numberOfRooms : ''
        }&apiTypeCode=${searchFeilds.apiTypeCode ? searchFeilds.apiTypeCode : ''}&hotelName=${
          searchFeilds.hotelName ? searchFeilds.hotelName : ''
        }&id=&amenities=&hotelId=${hotelId}
    `,
    )
    .then(({data}) => data);

export const UpdateStaytype = (tableCategoryPayload: StayTypeRequest): Promise<TableCategoryResponse> =>
  instance.put<TableCategoryResponse>(HOTEL_SERVICE + 'stayType', {...tableCategoryPayload}).then(({data}) => data);

export const DeleteStaytype = (id: number): Promise<TableCategoryResponse> =>
  instance.delete<TableCategoryResponse>(HOTEL_SERVICE + `stayType/${id}`).then(({data}) => data);

export const checkChannelPriceIsDepend = (id: number): Promise<TableCategoryResponse> =>
  instance.get<TableCategoryResponse>(HOTEL_SERVICE + `stayTypes/${id}`).then(({data}) => data);
