import React, {useState} from 'react';
import * as S from './DateRangeFilter.style';
import {Button, DatePicker, Radio, RadioChangeEvent, Select} from 'antd';
import moment from 'moment';
import {DatePickerProps, RangePickerProps} from 'antd/lib/date-picker';

const {RangePicker} = DatePicker;

interface IDateRange {
  startDate: string;
  endDate: string;
}

interface IPropsFilter {
  getDateRange: (range: IDateRange) => void;
}

function CustomDateRangeFilter(props: IPropsFilter) {
  const {getDateRange} = props;
  const [dateType, setDateType] = useState<any>('year');
  const [singleDatePicker, setSingleDatePicker] = useState<moment.Moment | null>(moment());
  const [rangeDatePicker, setRangeDatePicker] = useState<[moment.Moment, moment.Moment] | [null, null]>([null, null]);
  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange>({
    startDate: '',
    endDate: '',
  });
  const [selectDateType, setSelectDateType] = useState<'SINGLE' | 'RANGE' | undefined>('SINGLE');
  const [isAllDate, setIsAllDate] = useState(false);

  const onChange = (e: RadioChangeEvent) => {
    setDateType(e.target.value);
    setSingleDatePicker(null);
    setRangeDatePicker([null, null]);
    setSelectedDateRange({
      startDate: '',
      endDate: '',
    });
    setSelectDateType('SINGLE');
    if (e.target.value === 'all') {
      setIsAllDate(true);
    } else {
      setIsAllDate(false);
    }
  };

  const handleChangeDateSelectType = (value: 'SINGLE' | 'RANGE' | undefined) => {
    setSelectDateType(value);
  };

  const onChangeSingleDate: DatePickerProps['onChange'] = (date, dateString) => {
    setSingleDatePicker(date);
    if (date !== null) {
      if (dateType === 'month') {
        const startDate: any = date?.startOf('month').format('YYYY-MM-DD');
        const endDate: any = date?.endOf('month').format('YYYY-MM-DD');
        setSelectedDateRange({
          startDate: startDate,
          endDate: endDate,
        });
      } else if (dateType === 'year') {
        const startDate: any = date?.startOf('year').format('YYYY-MM-DD');
        const endDate: any = date?.endOf('year').format('YYYY-MM-DD');
        setSelectedDateRange({
          startDate: startDate,
          endDate: endDate,
        });
      } else if (dateType === 'date') {
        setSelectedDateRange({
          startDate: dateString,
          endDate: dateString,
        });
      } else {
      }
    } else {
      //   setShowReport(false);
      setSelectedDateRange({
        startDate: '',
        endDate: '',
      });
    }
  };

  const onChangeRangeDate: RangePickerProps['onChange'] = (date, dateString) => {
    if (date !== null) {
      setRangeDatePicker(date);
      if (dateType === 'month') {
        const startMonth = date && date[0];
        const endMonth = date && date[1];
        const startDate: any = startMonth?.startOf('month').format('YYYY-MM-DD');
        const endDate: any = endMonth?.endOf('month').format('YYYY-MM-DD');
        setSelectedDateRange({
          startDate: startDate,
          endDate: endDate,
        });
      } else if (dateType === 'year') {
        const startYear = date && date[0];
        const endYear = date && date[1];
        const startDate: any = startYear?.startOf('year').format('YYYY-MM-DD');
        const endDate: any = endYear?.endOf('year').format('YYYY-MM-DD');
        setSelectedDateRange({
          startDate: startDate,
          endDate: endDate,
        });
      } else if (dateType === 'date') {
        setSelectedDateRange({
          startDate: dateString[0],
          endDate: dateString[1],
        });
      } else {
      }
    } else {
      setRangeDatePicker([null, null]);
      setSelectedDateRange({
        startDate: '',
        endDate: '',
      });
    }
  };

  return (
    <S.SearchWrapper>
      <Radio.Group onChange={onChange} value={dateType} size="small">
        <Radio value={'date'}>Date</Radio>
        <Radio value={'month'}>Month</Radio>
        <Radio value={'year'}>Year</Radio>
      </Radio.Group>
      {dateType !== 'all' && (
        <Select
          size="small"
          placeholder="Type"
          value={selectDateType}
          style={{width: 150}}
          onChange={handleChangeDateSelectType}
          options={[
            {
              value: 'SINGLE',
              label: `Single ${dateType}`,
            },
            {
              value: 'RANGE',
              label: `Range ${dateType}`,
            },
          ]}
        />
      )}
      {selectDateType === 'SINGLE' && dateType !== 'all' ? (
        <DatePicker size="small" value={singleDatePicker} showNow onChange={onChangeSingleDate} picker={dateType} />
      ) : (
        dateType !== 'all' && (
          <RangePicker size="small" onChange={onChangeRangeDate} picker={dateType} value={rangeDatePicker} />
        )
      )}
      <Button
        size="small"
        type="primary"
        style={{fontSize: '14px'}}
        onClick={() => {
          getDateRange(selectedDateRange);
        }}
        // loading={btnLoading}
      >
        Apply
      </Button>
      <Button
        size="small"
        type="ghost"
        style={{fontSize: '14px'}}
        onClick={() => {
          setSelectDateType('SINGLE');
          setSingleDatePicker(moment());
          setDateType('year');
          setSelectedDateRange({
            startDate: '',
            endDate: '',
          });
          getDateRange({
            startDate: '',
            endDate: '',
          });
        }}>
        Reset
      </Button>
    </S.SearchWrapper>
  );
}

export default CustomDateRangeFilter;
