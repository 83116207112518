import React, {useEffect, useState} from 'react';
import * as S from './BlinkCard.style';
import {Avatar, Col, Row} from 'antd';
import {BLINK} from '@app/assets';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BlinkCard: React.FC<any> = ({name, bgUrl, logo, onClick, info, scale, isUrgent, isBlink}) => {
  const [blinkComponent, setblinkComponent] = useState<JSX.Element>();

  useEffect(() => {
    setTimeout(() => {
      setblinkComponent(() => {
        return (
          <S.ReactLottie
            style={{position: 'absolute', top: 0, left: 0}}
            options={{
              loop: true,
              autoplay: true,
              animationData: BLINK,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={50}
            width={50}
          />
        );
      });
    }, 100);
  }, [isBlink]);
  return (
    <S.TopLevelCardWrap scale={scale} onClick={onClick} bgUrl={bgUrl}>
      <S.Wrapper>
        <S.ImgWrapper>
          {logo && <Avatar shape="circle" style={{height: '100px', width: '100px'}} src={logo} alt="Profile" />}
        </S.ImgWrapper>

        <Row justify={'center'}>
          <Col>
            <S.TopLevelCardTitle>{name && name.toUpperCase()}</S.TopLevelCardTitle>
          </Col>
        </Row>
      </S.Wrapper>
      <S.TopLevelCardInfo>{info}</S.TopLevelCardInfo>
      {isUrgent && <S.BlinkingText>URGENT</S.BlinkingText>}
      {isBlink && blinkComponent}
    </S.TopLevelCardWrap>
  );
};

export default BlinkCard;
