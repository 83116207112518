import authInstance from '@app/api/authInstance';
import {LOGIN_SERVICE} from '@app/api/instance';
import {IRoleData} from '@app/pages/MasterPages/hotel/groupAdmin/role/AddRole';

export const getAllUsers = (): Promise<UserResponse> =>
  authInstance.get<UserResponse>(LOGIN_SERVICE + `api/v1/user`).then(({data}) => data);

export const getAllUsersByHotelId = (hotelId: any): Promise<UserResponse> =>
  authInstance
    .get<UserResponse>(LOGIN_SERVICE + `api/v1/user/hotel/${hotelId}?hotelId1=${hotelId}`)
    .then(({data}) => data);

export const CreateUser = (payload: CreateUserProps): Promise<UserResponse> => {
  return authInstance.post<UserResponse>(LOGIN_SERVICE + 'api/v1/user', payload).then(({data}) => data);
};

export const UpdateUser = (payload: UpdateUserProps): Promise<UserResponse> => {
  return authInstance.put<UserResponse>(LOGIN_SERVICE + 'api/v1/user', payload).then(({data}) => data);
};

export const DeleteUser = (id: number): Promise<UserResponse> =>
  authInstance.delete<UserResponse>(LOGIN_SERVICE + `api/v1/user/${id}`).then(({data}) => data);

export const getAllUsersByServiceRoleId = (serviceRoleId: number): Promise<UserResponse> =>
  authInstance.get<UserResponse>(LOGIN_SERVICE + `api/v1/user/service-role/${serviceRoleId}`).then(({data}) => data);

export const getAllUserFilter = (filter: IUserFilter): Promise<UserResponse> => {
  const payload: any = {
    hoteId: filter.hoteId === 0 ? '' : filter.hoteId,
    serviceId: filter.serviceId === 0 ? '' : filter.serviceId,
    serviceRoleId: filter.serviceRoleId === 0 ? '' : filter.serviceRoleId,
  };

  return authInstance
    .get<UserResponse>(
      LOGIN_SERVICE +
        `api/v1/users/search?hotelId=${payload.hoteId}&serviceId=${payload.serviceId}&serviceRoleId=${payload.serviceRoleId}`,
    )
    .then(({data}) => data);
};
export const getUserById = (id: number): Promise<UserResponse> =>
  authInstance.get<UserResponse>(LOGIN_SERVICE + `api/v1/user/${id}`).then(({data}) => data);

export const changePassword = (payload: ChangePasswordProps): Promise<UserResponse> => {
  return authInstance.put<UserResponse>(LOGIN_SERVICE + 'api/v1/user/changePassword', payload).then(({data}) => data);
};
export const forgotPassword = (email: string): Promise<UserResponse> => {
  return authInstance.post<UserResponse>(LOGIN_SERVICE + `api/v1/user/forgotPassword/${email}`).then(({data}) => data);
};

export const forgotPasswordOtpVaility = (token: string, userName: string): Promise<UserResponse> => {
  return authInstance
    .get<UserResponse>(LOGIN_SERVICE + `api/v1/user/otp/isValid/${userName}/${token}`)
    .then(({data}) => data);
};

export const resetPassword = (payload: ResetPasswordProps): Promise<UserResponse> => {
  return authInstance.put<UserResponse>(LOGIN_SERVICE + 'api/v1/user/resetPassword', payload).then(({data}) => data);
};

export const userEmailVerification = (email: string | undefined, token: string | undefined): Promise<UserResponse> =>
  authInstance
    .get<UserResponse>(LOGIN_SERVICE + `api/v1/email-verification/email/${email}/token/${token}`)
    .then(({data}) => data);

export const getUserRoleById = (id: number): Promise<UserResponse> =>
  authInstance.get<UserResponse>(LOGIN_SERVICE + `api/v1/employee/roles/${id}`).then(({data}) => data);

export const UpdateUserAndRole = (payload: any): Promise<UserResponse> => {
  return authInstance.put<UserResponse>(LOGIN_SERVICE + 'api/v1/users', payload).then(({data}) => data);
};

export interface UserResponse {
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
  status: string;
  statusCode: string;
}

export interface CreateUserProps {
  email: string;
  employeeId: number;
  // password: string;
  hotelRoleIds: number[];
  serviceRoleIds: number[];
  groupRoleIds?: number[];
}

export interface UpdateUserProps {
  id: number;
  employeeId: number;
  email: string;
  hotelRoleIds: number[];
  serviceRoleIds: number[];
}

export interface ChangePasswordProps {
  oldPassword: string;
  newPassword: string;
}

export interface OTPVerificationProps {
  token: string;
}
export interface IUserFilter {
  hoteId: number;
  serviceId: number;
  serviceRoleId: number;
}

export interface IUserFilterPayload {
  hoteId: string;
  serviceId: string;
  serviceRoleId: string;
}

export interface ResetPasswordProps {
  token: string;
  password: string;
}
