const PALMYRAH_HOUSE = {
  id: 1,
  webUrl: 'https://www.palmyrahhouse.com',
};
const MIDDLE_STREET = {
  id: 2,
  webUrl: 'https://www.32middlestreet.lk',
};
const VILLA_THUYA = {
  id: 3,
  webUrl: 'https://www.villathuya.lk',
};
const LILY_POD = {
  id: 4,
  webUrl: 'https://www.lilypod.lk',
};
const DENDROBIUM_HOUSE = {
  id: 5,
  webUrl: 'https://www.dendrobiumhouse.lk',
};

const hotels = {
  PALMYRAH_HOUSE,
  DENDROBIUM_HOUSE,
  LILY_POD,
  MIDDLE_STREET,
  VILLA_THUYA,
};

export default hotels;
