import styled from 'styled-components';
import {Select as AntSelect} from 'antd';
import {Dimension} from 'interfaces/interfaces';
import {normalizeProp} from 'utils/utils';
import {FONT_WEIGHT, BORDER_RADIUS, FONT_SIZE, BASE_COLORS} from '@app/styles/themes/constants';

export interface SelectProps {
  width?: Dimension;
  shadow?: boolean;
  background?: string;
}

export const Select = styled(AntSelect).withConfig({
  shouldForwardProp: prop => !['shadow', 'width'].includes(prop),
})<SelectProps>`
  width: ${props => props.width && normalizeProp(props.width)};

  font-weight: ${FONT_WEIGHT.medium};

  .ant-select-selector {
    background: ${(props: SelectProps) => props.background} !important;
  }

  box-shadow: ${props => props.shadow && 'var(--box-shadow)'};

  &.ant-select-borderless {
    background: var(--secondary-background-color);

    border-radius: ${BORDER_RADIUS};
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #e0e8ef;
  }
  &.ant-select-disabled:not(.ant-select-customize-input) {
    .ant-select-selector {
      color: red;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item,
    .ant-select-arrow {
      color: ${BASE_COLORS.black};
    }
  }
  .ant-select-selection-placeholder {
    font-size: ${FONT_SIZE.xs};
    color: var(--text-main-color);
  }

  .ant-select-arrow {
    color: var(--text-main-color);
  }
  .jogFpf .ant-input-group-addon .ant-select .ant-select-selection-item {
    min-width: 5.5rem;
    color: var(--primary-color);
    font-weight: 600;
    font-size: ${FONT_SIZE.xs};
  }

  &.ant-select-multiple.ant-select-sm .ant-select-selection-item {
    height: 0.875rem;
    line-height: ${FONT_SIZE.xs};
    font-size: ${FONT_SIZE.xs};
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: var(--disabled-color);
  }

  .ant-select-clear {
    color: var(--disabled-color);
  }
  .ant-select-selection-item-remove {
    color: var(--icon-color);
    &:hover {
      color: var(--icon-hover-color);
    }
  }
  .ant-select-item-option-disabled {
    color: var(--disabled-color);
  }
`;
