/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from './Modal.style';
import {Col, Row} from 'antd';
import {Input} from '@app/components/common/inputs/Input/Input';
import {FormInstance} from 'antd/es/form/Form';
import {useAppDispatch} from '@app/hooks/reduxHooks';
import {setLoading} from '@app/store/slices/commonSlice';
import {notificationController} from '@app/controllers/notificationController';
import {CARD, MONEY} from '@app/assets';
import {ConvertedDataProps} from '../helper/interface';
import {servicePayment} from '@app/api/hotel/additionalBookings/additionalBookings.api';
import {roundNumberDeci} from '@app/utils/utils';

export interface IPaymentValues {
  amount: string;
}

interface FieldData {
  name: string | number;
  value?: any;
}

export interface MakePaymentProps {
  amount: number;
  invoiceId: number;
  paid: boolean;
  currencyId: number;
  paymentMethod?: string;
  account: string;
}

export interface IInvoiceData {
  id: number;
  dueAmount: number;
  currencyPrefix: string;
  currencyId: number;
}
interface Props {
  form: FormInstance;
  reloadData: () => void;
  isModalVisible: boolean;
  onCancel: () => void;
  readOnly?: boolean;
  outStandingAmount: number;
  reservationData: ConvertedDataProps;
}

export const MakePayment: React.FC<Props> = ({
  isModalVisible,
  form,
  reloadData,
  outStandingAmount,
  reservationData,
  onCancel,
}) => {
  const [fields, setFields] = React.useState<FieldData[]>([{name: 'amount', value: undefined}]);
  const [currencyPrefix, setCurrencyPrefix] = React.useState('');
  const [paymentType, setpaymentType] = useState<'CASH' | 'CREDITCARD'>('CASH');
  const dispatch = useAppDispatch();

  const onFinish = async () => {
    dispatch(setLoading(true));
    try {
      const payload = {
        additionalServiceMainId: reservationData.id,
        paymentType: 'OUTSTANDING',
        paymentMethod: paymentType,
        amount: Number(roundNumberDeci(outStandingAmount, 2)),
        currencyId: reservationData.reservationCurrencyId,
      };
      const result = await servicePayment(payload);
      if (result.statusCode === '20000') {
        notificationController.success({message: result.message});
        dispatch(setLoading(false));
        reloadData();
        onCancel();
      } else {
        notificationController.error({message: result.message});
        dispatch(setLoading(false));
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (isModalVisible) {
      form.setFieldValue('amount', roundNumberDeci(outStandingAmount, 2)), setCurrencyPrefix(reservationData.prefix);
    }
  }, [reservationData, outStandingAmount, isModalVisible, form]);

  return (
    <S.FormContent>
      <S.PaymentOptionWrapper>
        <S.PaymentOptionSection onClick={() => setpaymentType('CASH')}>
          <S.PaymentOptionOutline $selected={paymentType === 'CASH'}>
            <S.Image src={MONEY} />
          </S.PaymentOptionOutline>
          <S.PaymentType $selected={paymentType === 'CASH'}>Cash</S.PaymentType>
        </S.PaymentOptionSection>
        <S.PaymentOptionSection onClick={() => setpaymentType('CREDITCARD')}>
          <S.PaymentOptionOutline $selected={paymentType === 'CREDITCARD'}>
            <S.Image src={CARD} />
          </S.PaymentOptionOutline>
          <S.PaymentType $selected={paymentType === 'CREDITCARD'}>Credit Card</S.PaymentType>
        </S.PaymentOptionSection>
      </S.PaymentOptionWrapper>
      <BaseForm
        size="middle"
        name="paymentForm"
        form={form}
        initialValues={{amount: outStandingAmount.toFixed(2)}}
        fields={fields}
        onFinish={onFinish}>
        <Row
          gutter={{xs: 10, md: 15, xl: 30}}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Col xs={24} md={16}>
            <BaseForm.Item name="amount" label="Amount" rules={[{required: true, message: 'Amount is required'}]}>
              <Input readOnly={true} placeholder="Enter Amount" prefix={currencyPrefix} />
            </BaseForm.Item>
          </Col>
        </Row>
      </BaseForm>
    </S.FormContent>
  );
};
