// import instance, {HOTEL_SERVICE, RESTAURANT_SERVICE} from '@app/api/instance';

import restInstance, {RESTAURANT_SERVICE} from '@app/api/resturantInstance';
import {IHotelId} from '@app/store/slices/interface';

export const getCategoryStuctureByID = (id: number, hotelServiceId: number, name?: string): Promise<any> =>
  restInstance
    .get<any>(RESTAURANT_SERVICE + `category-structure/${hotelServiceId}/${id}?name=${name ? name : ''}`)
    .then(({data}) => data);

export const getLastCategoryStucture = (hotenId: IHotelId, hotelServiceId: number, title?: string): Promise<any> =>
  restInstance
    .get<any>(
      RESTAURANT_SERVICE + `category-structure/last-layer?title=${title ? title : ''}&hotelServiceId=${hotelServiceId}`,
    )
    .then(({data}) => data);

export const CreateCategoryStucture = (payload: any): Promise<any> =>
  restInstance.post<any>(RESTAURANT_SERVICE + 'category-structure', payload).then(({data}) => data);

export const getCategoryStucture = (hotelServiceId: number): Promise<any> =>
  restInstance
    .get<any>(RESTAURANT_SERVICE + `category-structure/hotel-service/${hotelServiceId}`)
    .then(({data}) => data);

export const getAllCategoryName = (hotelServiceId: number, title?: string): Promise<any> =>
  restInstance
    .get<any>(
      RESTAURANT_SERVICE + `categories/search-only?title=${title ? title : ''}&hotelServiceId=${hotelServiceId}`,
    )
    .then(({data}) => data);

export const DeleteStucture = (hotelServiceId: number, id?: any): Promise<any> =>
  restInstance
    .delete<any>(RESTAURANT_SERVICE + `category-structure/${hotelServiceId}/${id ? id : ''}`)
    .then(({data}) => data);

export const disableCategoryStucture = (payload: any, hotelServiceId: number, id: number): Promise<any> =>
  restInstance
    .put<any>(RESTAURANT_SERVICE + `category-structure/${hotelServiceId}/${id ? id : ''}`, payload)
    .then(({data}) => data);
