import houseKeepingInstance from '@app/api/houseKeepingInstance';
import instance, {HOTEL_SERVICE, HOUSE_KEEPING_SERVICE} from '@app/api/instance';

export const getAllCheckedoutRooms = (): Promise<Response> =>
  instance.get<Response>(HOTEL_SERVICE + `check-out-rooms`).then(({data}) => data);

export const getAllRoomsForCleaning = (status: string[], hotelId: number): Promise<Response> => {
  const joinedStatuses = status.join(',');
  return instance
    .get<Response>(HOTEL_SERVICE + `room/search-only?statusList=${joinedStatuses}&hotelId=${hotelId}`)
    .then(({data}) => data);
};

export const getOnDemandRooms = (hotelId: number): Promise<Response> => {
  return instance
    .get<Response>(HOTEL_SERVICE + `argentRequest/search?hotelId=${hotelId}&status=ONDEMAND_REQUEST`)
    .then(({data}) => data);
};

export const getAllOccupiedRooms = (): Promise<Response> =>
  instance.get<Response>(HOTEL_SERVICE + `check-in-rooms`).then(({data}) => data);

export const getAllCheckedoutRoomsActions = (payload: any): Promise<Response> =>
  houseKeepingInstance.post<Response>(HOUSE_KEEPING_SERVICE + `tasks`, payload).then(({data}) => data);

export const getCheckedoutRoomsActions = (userId: number): Promise<Response> =>
  houseKeepingInstance.get<Response>(HOUSE_KEEPING_SERVICE + `tasks/${userId}`).then(({data}) => data);

export const updateActionStatusById = (payload: {
  roomTaskCheckListActionId: number;
  status: boolean;
}): Promise<Response> =>
  houseKeepingInstance
    .put<Response>(HOUSE_KEEPING_SERVICE + `tasks/room-check-list-check-action`, payload)
    .then(({data}) => data);

export const updateCleaningStatus = (payload: any): Promise<Response> =>
  houseKeepingInstance.put<Response>(HOUSE_KEEPING_SERVICE + `tasks/room`, payload).then(({data}) => data);

export const getRoomReservationOverallStatus = (roomId: any): Promise<Response> =>
  instance.get<Response>(HOTEL_SERVICE + `room/request/${roomId}`).then(({data}) => data);

export interface Response {
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
  status: string;
  statusCode: string;
}

export interface PaginatedResponse {
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
  status: string;
  statusCode: string;
  pagination: {
    pageNumber: number;
    totalRecords: number;
  };
}
