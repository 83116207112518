import {BASE_COLORS, FONT_WEIGHT, media} from '@app/styles/themes/constants';
import styled, {keyframes} from 'styled-components';
import Lottie from 'react-lottie';

export const TopLevelCardInfo = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin-top: 0.5rem;
  bottom: -20px;
  position: relative;
  transition: all 1s ease-in-out;
  transform: translateY(50px);
  opacity: 0;
`;

export const TopLevelCardTitle = styled.div`
  font-weight: 700;
  color: #fff;

  @media only screen and (max-width: 1400px) {
    font-size: 1rem;
  }
`;

type styleProps = {
  bgUrl?: string;
  isCatagory?: boolean;
  circleColor?: string;
  scale?: string;
};

type bgProps = {
  isCatagory: boolean;
};

export const TopLevelCardWrap = styled.div`
  transform: scale(${(props: styleProps) => props.scale});
  width: 15.5rem;
  height: 22rem;
  border-radius: 1rem;
  background: #fff;
  padding: 1rem;
  background-size: cover !important;
  background: linear-gradient(0deg, rgb(0 0 0 / 54%), rgb(187 187 187 / 39%)),
    url(${(props: styleProps) => props.bgUrl});
  -webkit-box-shadow: -1px 1px 23px -3px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: -1px 1px 23px -3px rgba(0, 0, 0, 0.37);
  box-shadow: -1px 1px 23px -3px rgba(0, 0, 0, 0.37);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1.6rem;
  margin-bottom: 1rem;
  flex-direction: column;
  transition: all 0.4s ease-in-out;
  background-position-x: center;
  position: relative;

  &:hover {
    transform: scale(${(props: styleProps) => (props.scale ? '1.01' : '1.05')});
    background-size: 180%;
  }

  &:hover ${TopLevelCardInfo} {
    opacity: 1;
    transform: translateY(0px);
  }

  @media only screen and (max-width: 1400px) {
    margin-right: 1.3rem;
  }

  @media only screen and (min-width: 2000px) {
    width: 20.5rem;
    height: 30rem;
    font-size: 1rem;
  }
`;

export const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 0rem;
`;

export const ImgWrapper = styled.div`
  width: 6.9375rem;
  margin: 0 auto 1.25rem auto;
  display: flex;
  justify-content: center;
  border-radius: 50%;

  background: #a26658;

  @media only screen and ${media.lg} {
  }
  @media only screen and ${media.xl} {
    width: 7rem;
    margin: 0 auto 1rem auto;
  }
  @media only screen and (min-width: 2400px) {
    width: 9rem;
  }

  & > span {
    margin: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);

    @media only screen and ${media.xl} {
      margin: 3px;
    }
  }
`;

export const ReactLottie = styled(Lottie)`
  position: absolute;
`;

const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const BlinkingText = styled.div`
  color: ${BASE_COLORS.lightningRed};
  animation: ${blinkAnimation} 1s infinite;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
  font-weight: ${FONT_WEIGHT.bold};
  background-color: ${BASE_COLORS.white};
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 50px;
`;
