import styled from 'styled-components';
import {Modal as AntModal} from 'antd';

export const Modal = styled(AntModal)`
  .ant-modal-body {
    padding: 20px !important;
    padding-bottom: 0px !important;
    padding-top: 8px !important;
  }
`;
