import React, {useEffect, useState} from 'react';
import {ConfigProvider} from 'antd';
import {HelmetProvider} from 'react-helmet-async';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import {AppRouter} from './components/router/AppRouter';
import {useLanguage} from './hooks/useLanguage';
import {useAutoNightMode} from './hooks/useAutoNightMode';
import {usePWA} from './hooks/usePWA';
import {useThemeWatcher} from './hooks/useThemeWatcher';
import {useAppDispatch, useAppSelector} from './hooks/reduxHooks';
import {themeObject} from './styles/themes/themeVariables';
import {BrowserRouter} from 'react-router-dom';
import {useIdleTimer} from 'react-idle-timer'; // Import the useIdleTimer hook
import {doLogout} from './store/slices/authSlice';
import {getIdeleTimeout} from './api/hotel/systemConfig/systemConfig.api';

const App: React.FC = () => {
  const {language} = useLanguage();
  const dispatch = useAppDispatch();
  const [timeoutSeconds, settimeoutSeconds] = useState(1800);

  const theme = useAppSelector(state => state.theme.theme);

  usePWA();

  useAutoNightMode();

  useThemeWatcher();

  const handleOnIdle = () => {
    dispatch(doLogout());
  };

  const getTimeout = async () => {
    try {
      const response = await getIdeleTimeout();
      const newTimeoutSeconds = response.result.IdleTimeOut[0].seconds;
      if (newTimeoutSeconds !== timeoutSeconds) {
        settimeoutSeconds(newTimeoutSeconds);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getTimeout();
  }, []);

  useIdleTimer({
    timeout: 1000 * timeoutSeconds,
    onIdle: handleOnIdle,
  });

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <HelmetProvider>
        <ConfigProvider locale={language === 'en' ? enUS : deDe}>
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </ConfigProvider>
      </HelmetProvider>
    </>
  );
};

export default App;
