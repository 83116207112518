import {TeamOutlined, UserAddOutlined} from '@ant-design/icons';
import {HOTEL_ADMIN_MODULE_NAME, SERVICE_ADMIN_MODULE_NAME} from '@app/utils/permissions';
import React from 'react';
import {BiBuilding, BiHotel, BiRocket, BiUser} from 'react-icons/bi';
import {FaServicestack} from 'react-icons/fa';
import {TbReport} from 'react-icons/tb';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  isSubMenu: boolean;
}

export const ServiceAdminSidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Service Role',
    key: SERVICE_ADMIN_MODULE_NAME.SERVICE_ROLE,
    url: '/service-admin/service-role',
    icon: <TeamOutlined />,
    isSubMenu: false,
  },
  {
    title: 'Service Role Privilege',
    key: SERVICE_ADMIN_MODULE_NAME.SERVICE_ROLE_PRIVILEGE,
    url: '/service-admin/service-role-privilege',
    icon: <BiUser />,
    isSubMenu: false,
  },
  {
    title: 'User',
    key: SERVICE_ADMIN_MODULE_NAME.EMPLOYEE,
    url: '/service-admin/hotel-employee',
    icon: <UserAddOutlined />,
    isSubMenu: false,
  },
  {
    title: 'Reports',
    key: SERVICE_ADMIN_MODULE_NAME.REPORT,
    url: '/hotel-admin/reports',
    icon: <TbReport />,
    isSubMenu: false,
  },
  // {
  //   title: 'User',
  //   key: 'hotel-user',
  //   url: '/service-admin/hotel-user',
  //   icon: <UserAddOutlined />,
  //   isSubMenu: false,
  // },
  // {
  //   title: 'Role Privilege',
  //   key: 'hotel-role-privilege',
  //   url: '/service-admin/hotel-role-privilege',
  //   icon: <BiUser />,
  //   isSubMenu: false,
  // },
  // {
  //   title: 'User Privilege',
  //   key: 'hotel-user-privilege',
  //   url: '/service-admin/hotel-user-privilege',
  //   icon: <BiUser />,
  //   isSubMenu: false,
  // },
];
