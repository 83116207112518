import React from 'react';
// import { TopLevelCardWrap } from './TopLevelCard.style';
import * as S from './TopLevelCard.style';
import {Avatar, Col, Row} from 'antd';
import {Button} from '../common/buttons/Button/Button';
import {TopLevelCardProps} from '@app/pages/Restaurant/Interfaces';

const TopLevelCard: React.FC<any> = ({name, bgUrl, logo, onClick, info, isCatagory, scale}) => {
  return (
    <S.TopLevelCardWrap scale={scale} onClick={onClick} bgUrl={bgUrl}>
      <S.Wrapper>
        <S.ImgWrapper>
          {logo && <Avatar shape="circle" style={{height: '100px', width: '100px'}} src={logo} alt="Profile" />}
        </S.ImgWrapper>

        <Row justify={'center'}>
          <Col>
            <S.TopLevelCardTitle>{name && name.toUpperCase()}</S.TopLevelCardTitle>
          </Col>
        </Row>
      </S.Wrapper>
      <S.TopLevelCardInfo>{info}</S.TopLevelCardInfo>
      {/* <div className="overlay"></div> */}
    </S.TopLevelCardWrap>
  );
};

export default TopLevelCard;
