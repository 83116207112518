import {createAction, createSlice, PayloadAction, PrepareAction} from '@reduxjs/toolkit';
import {IuserAuthorization, IUserPermission, UserModel} from '@app/domain/UserModel';
import {persistUser, readUser} from '@app/services/localStorage.service';
import {RootState} from '../store';
import {IPermission} from '@app/utils/permissions';

export interface UserState {
  user: UserModel | null;
  userPermission?: IuserAuthorization | null;
  isAuthenticated: boolean;
  permissions: IPermission[];
}

const initialState: UserState = {
  user: readUser(),
  userPermission: null,
  isAuthenticated: false,
  permissions: [],
};

export const setUser = createAction<PrepareAction<UserModel>>('user/setUser', newUser => {
  persistUser(newUser);
  return {
    payload: newUser,
  };
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserPermission: (state, action: PayloadAction<IuserAuthorization | null>) => {
      state.isAuthenticated = true;
      state.userPermission = action.payload;
    },
    setPermission: (state, action: PayloadAction<IPermission[]>) => {
      state.permissions = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });
  },
});

export const {setUserPermission, setPermission} = userSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const isAuthenticated = (state: RootState) => state.user.isAuthenticated;

export default userSlice.reducer;
