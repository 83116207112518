import {IDateRange} from '@app/pages/Hotel/Dashboard/HotelDashboardPage';
import instance, {HOTEL_SERVICE} from '../instance';

export const getAllReservationStatistics = (hotelId: number) =>
  instance.get(HOTEL_SERVICE + `reservation/roomCount/${hotelId}`).then(({data}) => data);

export const getAllResidencyStatistics = (hotelId: number) =>
  instance.get(HOTEL_SERVICE + `reservation/reservationCount/${hotelId}`).then(({data}) => data);

export const getReservationTypeCountStatistics = (hotelId: number) =>
  instance.get(HOTEL_SERVICE + `reservation/reservationTypeCount/${hotelId}`).then(({data}) => data);

export const getRoomTypeCountStatistics = (hotelId: number) =>
  instance.get(HOTEL_SERVICE + `reservation/reservationRoomType/${hotelId}`).then(({data}) => data);

export const getSeasonCountStatistics = (hotelId: number) =>
  instance.get(HOTEL_SERVICE + `reservation/reservationSeason/${hotelId}`).then(({data}) => data);

export const getLeadTypeCountStatistics = (hotelId: number) =>
  instance.get(HOTEL_SERVICE + `lead-type/dash-board?hotelId=${hotelId}`).then(({data}) => data);

export const getReservationTypeCount = (hotelId: number, dateRange: IDateRange) =>
  instance
    .get(
      HOTEL_SERVICE +
        `reservation/reservationTypeReservationCount?hotelId=${hotelId}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`,
    )
    .then(({data}) => data);
