import styled from 'styled-components';
import {Button, Typography} from 'antd';
import {BASE_COLORS, FONT_SIZE, FONT_WEIGHT} from '@app/styles/themes/constants';
import {Option} from '@app/components/common/selects/Select/Select';

interface ActionButtonProps {
  $color: string;
}

export const Btn = styled(Button)`
  display: block;
  width: 20%;
`;
export const CurrencyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const TableWrapper = styled.div`
  display: flex;
  width: 100%;
`;
export const FormContent = styled.div``;
export const ActionButton = styled.div<ActionButtonProps>`
  height: 2rem;
  padding-left: 10px;
  padding-right: 10px;
  background-color: ${props => props.$color};
  color: ${BASE_COLORS.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  align-self: center;
  min-width: 5.6rem;
  position: relative;
`;
export const ActionWraper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 1rem;
`;

export const BlurCardWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  gap: 1rem;
`;

export const FlexWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const BlueCard = styled.div`
  border: 1px solid var(--border-color);
  width: 100%;
  border-radius: 8px;
  background-color: ${BASE_COLORS.lightBlue};
`;
export const RedCard = styled.div`
  border: 1px solid ${BASE_COLORS.redTagBorder};
  width: 100%;
  border-radius: 8px;
  background-color: ${BASE_COLORS.redTag};
`;
export const WhiteCard = styled.div`
  border: 1px solid var(--border-color);
  width: 100%;
  border-radius: 8px;
  background-color: ${BASE_COLORS.white};
`;
export const Padding = styled.div`
  padding: 1rem;
`;
export const EmptyFooterSpace = styled.div`
  height: 1rem;
`;

export const InfoButton = styled.div`
  height: 2rem;
  padding-left: 8px;
  padding-right: 8px;
  background-color: ${BASE_COLORS.skyblue2};
  color: ${BASE_COLORS.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  align-self: center;
  min-width: 2.5rem;
`;
export const PaidStatusTag = styled.div`
  background-color: ${BASE_COLORS.darkGreen};
  width: 10rem;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
`;
export const PaidStatusText = styled(Typography)`
  font-size: ${FONT_SIZE.xs};
  color: ${BASE_COLORS.white};
`;
export const NotPaidStatusTag = styled.div`
  background-color: ${BASE_COLORS.checkOutRed};
  width: 12rem;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
`;
export const PayButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const CustomOptionText = styled.span`
  font-size: 14px;
`;
export const CardTitle = styled(Typography.Text)`
  display: flex;
  line-height: 1.25rem;
  font-size: ${FONT_SIZE.md};
  color: ${BASE_COLORS.black};
  font-weight: ${FONT_WEIGHT.bold};
  margin-bottom: 0.5rem;
  align-vertical: center;
`;
export const CheckboxLabel = styled.span`
  color: ${BASE_COLORS.primary};
  font-size: 14px;
  margin-bottom: 10px;
`;
export const VatCard = styled.div`
  border: 1px solid var(--border-color);
  width: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
`;
export const Title = styled(Typography.Text)`
  display: flex;
  line-height: 24px;
  font-weight: ${FONT_WEIGHT.bold};
  font-size: 16px;
  color: ${BASE_COLORS.black};
  margin-bottom: 0.3rem;
`;
export const ServiceTitle = styled(Typography.Text)`
  font-size: 16px;
  text-align: right;
  font-weight: 500;
`;

export const TotalPriceTitle = styled(Typography.Text)`
  font-size: 16px;
  text-align: right;
  font-weight: bold;
`;
export const TotalPrice = styled(Typography.Text)`
  font-size: 16px;
  text-align: right;
  font-weight: bold;
`;
export const ServiceTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
export const ServiceCharge = styled.span`
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
