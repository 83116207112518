// import instance, {HOTEL_SERVICE, RESTAURANT_SERVICE} from '@app/api/instance';

import instance, {HOTEL_SERVICE} from '@app/api/instance';
import restInstance, {RESTAURANT_SERVICE} from '@app/api/resturantInstance';
import dayjs from 'dayjs';

export const getOrdersByTableId = (hotelServiceId: number, id?: number): Promise<any> => {
  const data: any = {
    date: dayjs(new Date()).format('YYYY-MM-DD'),
    tableId: id,
    orderStatus: 'PROCESSING',
  };
  return restInstance
    .get<any>(RESTAURANT_SERVICE + `order-status?tableId=${id}&hotelServiceId=${hotelServiceId}`)
    .then(({data}) => data);
};

export const createOrder = (orderData: any): Promise<any> =>
  restInstance.post<any>(RESTAURANT_SERVICE + 'order', {...orderData}).then(({data}) => data);

export const updateOrder = (orderData: any): Promise<any> =>
  restInstance.put<any>(RESTAURANT_SERVICE + 'order', {...orderData}).then(({data}) => data);

export const getTablesForWaiterDashboard = (hotelServiceId: number, name?: string): Promise<any> =>
  restInstance
    .get<any>(
      RESTAURANT_SERVICE +
        `restaurant-tables/search-only?hotelServiceId=${hotelServiceId}&statusList=OCCUPIED,WAITING_FOR_ORDER,READY_TO_SERVE`,
    )
    .then(({data}) => data);

export const getTableSearch = (hotelServiceId: number, userId: number | undefined): Promise<any> =>
  restInstance
    .get<any>(
      RESTAURANT_SERVICE +
        `restaurant-tables/search-only?userId=${userId}&hotelServiceId=${hotelServiceId}&statusList=VACANT`,
    )
    .then(({data}) => data);

export const cancelOrder = (id: any): Promise<any> =>
  restInstance.delete<any>(RESTAURANT_SERVICE + `order/${id}`).then(({data}) => data);

export const cancelOrderNew = (id: any): Promise<any> =>
  restInstance.delete<any>(RESTAURANT_SERVICE + `ordered-item-holdings/cancel/${id}`).then(({data}) => data);

export const completeCurrentOrder = (id: any, hotelId: number, paymentType: 'CASH' | 'CREDITCARD',payload:any): Promise<any> =>
  restInstance
    .put<any>(RESTAURANT_SERVICE + `order/complete/${id}?hotelId=${hotelId}&paymentPaidMethod=${paymentType}`,payload)
    .then(({data}) => data);

export const updateTableStatus = (id: any, status: string): Promise<any> =>
  restInstance
    .put<any>(RESTAURANT_SERVICE + `restaurant-table/status/${id}?status=${status ? status : ''}`)
    .then(({data}) => data);

export const createInvoice = (data: any): Promise<any> =>
  restInstance.post<any>(RESTAURANT_SERVICE + `invoice`, {...data}).then(({data}) => data);

export const createTableAllocation = (data: any): Promise<any> =>
  restInstance.post<any>(RESTAURANT_SERVICE + `restaurant-table/allocate`, {...data}).then(({data}) => data);

export const updateTableAllocation = (data: any): Promise<any> =>
  restInstance.put<any>(RESTAURANT_SERVICE + `restaurant-table/allocate`, {...data}).then(({data}) => data);

export const updateTableAllocationStatus = (id: any): Promise<any> =>
  restInstance.put<any>(RESTAURANT_SERVICE + `restaurant-table/deallocate/${id}`).then(({data}) => data);

export const createCustomFood = (data: any): Promise<any> =>
  restInstance.post<any>(RESTAURANT_SERVICE + `custom-dishes`, {...data}).then(({data}) => data);

export const searchCustomFood = (name: any): Promise<any> =>
  restInstance
    .get<any>(RESTAURANT_SERVICE + `item/search-only?name=${name}&itemType=CUSTOM_DISH`)
    .then(({data}) => data);

export const getRoomNoSearch = (name: string, hotelId: number): Promise<any> =>
  instance
    .get<any>(HOTEL_SERVICE + `reserved-room/search-by-room?hotelId=${hotelId}&roomNo=${name}`)
    .then(({data}) => data);

export const getTablesForWaiterDashboardKot = (
  hotelServiceId: number,
  userId: number | undefined,
  allowAllTable: boolean,
): Promise<any> =>
  restInstance
    .get<any>(RESTAURANT_SERVICE + `table-allocation/${hotelServiceId}/${userId}?allowAllTable=${allowAllTable}`)
    .then(({data}) => data);

// export const getRoomNoSearch = (name?: string): Promise<any> =>
//   instance.get<any>('https://randomuser.me/api/?results=5').then(({data}) => data);

// export const serveItem = (id: any): Promise<any> =>
// restInstance.put<any>(RESTAURANT_SERVICE + `order/complete/${id}`).then(({data}) => data);

export const updateVatapplicable = (vatData: any): Promise<any> =>
  restInstance.put<any>(RESTAURANT_SERVICE + 'order/vat-applicable', vatData).then(({data}) => data);
