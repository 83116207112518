import React from 'react';
import {InsertRowLeftOutlined, SettingOutlined, TeamOutlined, UserAddOutlined, UserOutlined} from '@ant-design/icons';
import {ReactComponent as NftIcon} from '@app/assets/icons/nft-icon.svg';
import {INVENTORY_SERVICE_MODULE_NAME, SERVICE_ADMIN_MODULE_NAME} from '@app/utils/permissions';
import {BiUser} from 'react-icons/bi';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  isSubMenu: boolean;
}

export const HotelInventorySidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Dashboard',
    key: INVENTORY_SERVICE_MODULE_NAME.INVENTORY_DASHBOARD,
    url: '/inventory/inventory-dashboard',
    icon: <NftIcon />,
    isSubMenu: false,
  },
  {
    title: 'Stock Management',
    key: 'stock_management',
    url: '/inventory/stock-management',
    icon: <InsertRowLeftOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Stock',
        key: INVENTORY_SERVICE_MODULE_NAME.STOCK,
        url: '/inventory/stock-management/opeingstock',
        isSubMenu: false,
      },
      {
        title: 'Purchase Order',
        key: INVENTORY_SERVICE_MODULE_NAME.PURCHASE_ORDER,
        url: '/inventory/stock-management/purchase-order',
        isSubMenu: false,
      },

      {
        title: 'Purchase Orders History',
        key: INVENTORY_SERVICE_MODULE_NAME.PURCHASE_ORDER_HISTORY,
        url: '/inventory/stock-management/grn-history',
        isSubMenu: false,
      },
      {
        title: 'GRN',
        key: INVENTORY_SERVICE_MODULE_NAME.GRN,
        url: '/inventory/stock-management/create-grn',
        isSubMenu: false,
      },

      {
        title: 'Stock Transfer',
        key: INVENTORY_SERVICE_MODULE_NAME.STOCK_TRANSFER,
        url: '/inventory/stock-management/stock-transfer',
        isSubMenu: false,
      },
      {
        title: 'Stock Transfer History',
        key: INVENTORY_SERVICE_MODULE_NAME.STOCK_TRANSFER_HISTORY,
        url: '/inventory/stock-management/stock-transfer-history',
        isSubMenu: false,
      },
    ],
  },
  {
    title: 'Item Master',
    key: 'item-master',
    icon: <InsertRowLeftOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Main Category',
        key: INVENTORY_SERVICE_MODULE_NAME.MAIN_CATEGORY,
        url: '/inventory/item-master/main-category',
        isSubMenu: false,
      },
      {
        title: 'Sub Category',
        key: INVENTORY_SERVICE_MODULE_NAME.SUB_CATEGORY,
        url: '/inventory/item-master/sub-category',
        isSubMenu: false,
      },
      {
        title: 'Unit Type',
        key: INVENTORY_SERVICE_MODULE_NAME.UNIT_TYPE,
        url: '/inventory/item-master/unit-type',
        isSubMenu: false,
      },
      {
        title: 'Unit',
        key: INVENTORY_SERVICE_MODULE_NAME.INVENTORY_UNIT,
        url: '/inventory/item-master/unit',
        isSubMenu: false,
      },
      {
        title: 'Item',
        key: INVENTORY_SERVICE_MODULE_NAME.INVENTORY_ITEM,
        url: '/inventory/item-master/item',
        isSubMenu: false,
      },
      // {
      //   title: 'Unit Conversion',
      //   key: 'unit-conversion',
      //   url: '/inventory/item-master/unit-conversion',
      //   isSubMenu: false,
      // },
    ],
  },
  {
    title: 'Supplier',
    key: INVENTORY_SERVICE_MODULE_NAME.SUPPLIER,
    url: '/inventory/supplier',
    icon: <UserOutlined />,
    isSubMenu: false,
  },
];

export const HotelHouseKeepingInventorySidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Dashboard',
    key: INVENTORY_SERVICE_MODULE_NAME.INVENTORY_DASHBOARD,
    url: '/inventory/inventory-dashboard',
    icon: <NftIcon />,
    isSubMenu: false,
  },
  {
    title: 'Stock Management',
    key: 'stock_management',
    url: '/inventory/stock-management',
    icon: <InsertRowLeftOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Stock',
        key: INVENTORY_SERVICE_MODULE_NAME.STOCK,
        url: '/inventory/stock-management/opeingstock',
        isSubMenu: false,
      },
      {
        title: 'Purchase Order',
        key: INVENTORY_SERVICE_MODULE_NAME.PURCHASE_ORDER,
        url: '/inventory/stock-management/purchase-order',
        isSubMenu: false,
      },

      {
        title: 'Purchase Orders History',
        key: INVENTORY_SERVICE_MODULE_NAME.PURCHASE_ORDER_HISTORY,
        url: '/inventory/stock-management/grn-history',
        isSubMenu: false,
      },
      {
        title: 'GRN',
        key: INVENTORY_SERVICE_MODULE_NAME.GRN,
        url: '/inventory/stock-management/create-grn',
        isSubMenu: false,
      },
      {
        title: 'Trolly Request',
        key: INVENTORY_SERVICE_MODULE_NAME.TROLLY_REQUEST,
        url: '/inventory/stock-management/trolly-request',
        isSubMenu: false,
      },
      {
        title: 'Trolly Request History',
        key: INVENTORY_SERVICE_MODULE_NAME.TROLLY_REQUEST_HISTORY,
        url: '/inventory/stock-management/trolly-request-history',
        isSubMenu: false,
      },
      {
        title: 'Stock Transfer',
        key: INVENTORY_SERVICE_MODULE_NAME.STOCK_TRANSFER,
        url: '/inventory/stock-management/stock-transfer',
        isSubMenu: false,
      },
      {
        title: 'Stock Transfer History',
        key: INVENTORY_SERVICE_MODULE_NAME.STOCK_TRANSFER_HISTORY,
        url: '/inventory/stock-management/stock-transfer-history',
        isSubMenu: false,
      },
    ],
  },
  {
    title: 'Item Master',
    key: 'item-master',
    icon: <InsertRowLeftOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Main Category',
        key: INVENTORY_SERVICE_MODULE_NAME.MAIN_CATEGORY,
        url: '/inventory/item-master/main-category',
        isSubMenu: false,
      },
      {
        title: 'Sub Category',
        key: INVENTORY_SERVICE_MODULE_NAME.SUB_CATEGORY,
        url: '/inventory/item-master/sub-category',
        isSubMenu: false,
      },
      {
        title: 'Unit Type',
        key: INVENTORY_SERVICE_MODULE_NAME.UNIT_TYPE,
        url: '/inventory/item-master/unit-type',
        isSubMenu: false,
      },
      {
        title: 'Unit',
        key: INVENTORY_SERVICE_MODULE_NAME.INVENTORY_UNIT,
        url: '/inventory/item-master/unit',
        isSubMenu: false,
      },
      {
        title: 'Item',
        key: INVENTORY_SERVICE_MODULE_NAME.INVENTORY_ITEM,
        url: '/inventory/item-master/item',
        isSubMenu: false,
      },
      // {
      //   title: 'Unit Conversion',
      //   key: INVENTORY_SERVICE_MODULE_NAME.INVENTORY_UNIT_CONVERSION,
      //   url: '/inventory/item-master/unit-conversion',
      //   isSubMenu: false,
      // },
      // {
      //   title: 'Unit Conversion',
      //   key: 'unit-conversion',
      //   url: '/inventory/item-master/unit-conversion',
      //   isSubMenu: false,
      // },
    ],
  },
  {
    title: 'Supplier',
    key: INVENTORY_SERVICE_MODULE_NAME.SUPPLIER,
    url: '/inventory/supplier',
    icon: <UserOutlined />,
    isSubMenu: false,
  },
  {
    title: 'Setting',
    key: 'Setting',
    icon: <SettingOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Service Role',
        key: SERVICE_ADMIN_MODULE_NAME.SERVICE_ROLE,
        url: '/inventory/service-role',
        icon: <TeamOutlined />,
        isSubMenu: false,
      },
      {
        title: 'Service Role Privilege',
        key: SERVICE_ADMIN_MODULE_NAME.SERVICE_ROLE_PRIVILEGE,
        url: '/inventory/service-role-privilege',
        icon: <BiUser />,
        isSubMenu: false,
      },
      {
        title: 'User',
        key: SERVICE_ADMIN_MODULE_NAME.EMPLOYEE,
        url: '/inventory/hotel-employee',
        icon: <UserAddOutlined />,
        isSubMenu: false,
      },
    ],
  },
];

export const GroupInventorySidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Dashboard',
    key: INVENTORY_SERVICE_MODULE_NAME.INVENTORY_DASHBOARD,
    url: '/inventory/inventory-dashboard',
    icon: <NftIcon />,
    isSubMenu: false,
  },
  {
    title: 'Stock Management',
    key: 'stock_management',
    url: '/inventory/stock-management',
    icon: <InsertRowLeftOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Stock',
        key: INVENTORY_SERVICE_MODULE_NAME.STOCK,
        url: '/inventory/stock-management/opeingstock',
        isSubMenu: false,
      },
      {
        title: 'Purchase Order',
        key: INVENTORY_SERVICE_MODULE_NAME.PURCHASE_ORDER,
        url: '/inventory/stock-management/purchase-order',
        isSubMenu: false,
      },

      {
        title: 'Purchase Orders History',
        key: INVENTORY_SERVICE_MODULE_NAME.PURCHASE_ORDER_HISTORY,
        url: '/inventory/stock-management/grn-history',
        isSubMenu: false,
      },

      {
        title: 'Stock Transfer',
        key: INVENTORY_SERVICE_MODULE_NAME.STOCK_TRANSFER,
        url: '/inventory/stock-management/stock-transfer',
        isSubMenu: false,
      },
      {
        title: 'Stock Transfer History',
        key: INVENTORY_SERVICE_MODULE_NAME.STOCK_TRANSFER_HISTORY,
        url: '/inventory/stock-management/stock-transfer-history',
        isSubMenu: false,
      },
    ],
  },
  {
    title: 'Item Master',
    key: 'item-master',
    icon: <InsertRowLeftOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Main Category',
        key: INVENTORY_SERVICE_MODULE_NAME.MAIN_CATEGORY,
        url: '/inventory/item-master/main-category',
        isSubMenu: false,
      },
      {
        title: 'Sub Category',
        key: INVENTORY_SERVICE_MODULE_NAME.SUB_CATEGORY,
        url: '/inventory/item-master/sub-category',
        isSubMenu: false,
      },
      {
        title: 'Unit Type',
        key: INVENTORY_SERVICE_MODULE_NAME.UNIT_TYPE,
        url: '/inventory/item-master/unit-type',
        isSubMenu: false,
      },
      {
        title: 'Unit',
        key: INVENTORY_SERVICE_MODULE_NAME.INVENTORY_UNIT,
        url: '/inventory/item-master/unit',
        isSubMenu: false,
      },
      {
        title: 'Item',
        key: INVENTORY_SERVICE_MODULE_NAME.INVENTORY_ITEM,
        url: '/inventory/item-master/item',
        isSubMenu: false,
      },
      // {
      //   title: 'Unit Conversion',
      //   key: INVENTORY_SERVICE_MODULE_NAME.INVENTORY_UNIT_CONVERSION,
      //   url: '/inventory/item-master/unit-conversion',
      //   isSubMenu: false,
      // },
      // {
      //   title: 'Unit Conversion',
      //   key: 'unit-conversion',
      //   url: '/inventory/item-master/unit-conversion',
      //   isSubMenu: false,
      // },
    ],
  },
  {
    title: 'Supplier',
    key: INVENTORY_SERVICE_MODULE_NAME.SUPPLIER,
    url: '/inventory/supplier',
    icon: <UserOutlined />,
    isSubMenu: false,
  },
];
