import React from 'react';
import {
  DashboardFilled,
  InsertRowLeftOutlined,
  SettingOutlined,
  TableOutlined,
  TeamOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import {BiFoodMenu, BiUser} from 'react-icons/bi';
import {FaFileInvoice} from 'react-icons/fa';
import {MdMoney, MdPercent} from 'react-icons/md';
import {RESTAURANT_ADMIN_MODULE_NAME, SERVICE_ADMIN_MODULE_NAME} from '@app/utils/permissions';
import {TbReport} from 'react-icons/tb';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  isSubMenu: boolean;
}

export const RestaurantSidebarNavigation: SidebarNavigationItem[] = [
  // {
  //   title: 'Dashboard',
  //   key: RESTAURANT_ADMIN_MODULE_NAME.RESTAURANT_DASHBOARD,
  //   url: '/restaurant-admin/dashboard',
  //   icon: <DashboardFilled />,
  //   isSubMenu: false,
  // },
  {
    title: 'Food Items',
    key: RESTAURANT_ADMIN_MODULE_NAME.FOOD_ITEMS,
    url: '/restaurant-admin/foods',
    icon: <BiFoodMenu />,
    isSubMenu: false,
  },
  {
    title: 'Table',
    key: 'table',
    icon: <TableOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Table Category',
        key: RESTAURANT_ADMIN_MODULE_NAME.TABLE_CATEGORY,
        url: '/restaurant-admin/table-category',
        isSubMenu: false,
      },
      {
        title: 'Table',
        key: RESTAURANT_ADMIN_MODULE_NAME.TABLE,
        url: '/restaurant-admin/table',
        isSubMenu: false,
      },
    ],
  },
  {
    title: 'Menu Category',
    key: 'item-category',
    isSubMenu: true,
    icon: <InsertRowLeftOutlined />,
    children: [
      {
        title: 'Category',
        key: RESTAURANT_ADMIN_MODULE_NAME.CATEGORY,
        url: '/restaurant-admin/category',
        isSubMenu: false,
      },

      {
        title: 'Allocate Category',
        key: RESTAURANT_ADMIN_MODULE_NAME.ALLOCATE_CATEGORY,
        url: 'restaurant-admin/item-category-allocate',
        isSubMenu: false,
      },
    ],
  },
  {
    title: 'Invoice',
    key: RESTAURANT_ADMIN_MODULE_NAME.RESTAURANT_INVOICE,
    url: '/restaurant-admin/invoice',
    icon: <FaFileInvoice />,
    isSubMenu: false,
  },
  {
    title: 'Tax',
    key: RESTAURANT_ADMIN_MODULE_NAME.RESTAURANT_TAX,
    url: '/restaurant-admin/tax',
    icon: <MdMoney />,
    isSubMenu: false,
  },
  {
    title: 'VAT Registry',
    key: RESTAURANT_ADMIN_MODULE_NAME.VAT_REGISTRY,
    url: '/restaurant-admin/vat-registry',
    icon: <MdPercent />,
    isSubMenu: false,
  },
  {
    title: 'Setting',
    key: 'Setting',
    icon: <SettingOutlined />,
    isSubMenu: true,
    children: [
      {
        title: 'Service Role',
        key: SERVICE_ADMIN_MODULE_NAME.SERVICE_ROLE,
        url: '/restaurant-admin/service-role',
        icon: <TeamOutlined />,
        isSubMenu: false,
      },
      {
        title: 'Service Role Privilege',
        key: SERVICE_ADMIN_MODULE_NAME.SERVICE_ROLE_PRIVILEGE,
        url: '/restaurant-admin/service-role-privilege',
        icon: <BiUser />,
        isSubMenu: false,
      },
      {
        title: 'User',
        key: SERVICE_ADMIN_MODULE_NAME.EMPLOYEE,
        url: '/restaurant-admin/hotel-employee',
        icon: <UserAddOutlined />,
        isSubMenu: false,
      },
      {
        title: 'Reports',
        key: SERVICE_ADMIN_MODULE_NAME.REPORT,
        url: '/restaurant-admin/reports',
        icon: <TbReport />,
        isSubMenu: false,
      },
    ],
  },
];
