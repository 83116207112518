import styled from 'styled-components';

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  background-color: #e5e5e5;
  padding: 0.3rem;
  border: 1px solid cadetblue;
  border-radius: 0px;
  align-items: center;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
`;

export const FilterBtn = styled.div`
  border: solid 1px black;
  align-items: center;

  height: 2rem;
  width: 2rem;
  border-radius: 5px;
  background-color: white;
  box-shadow: 5px 2px 5px #e9e2e2;
  cursor: pointer;
`;
