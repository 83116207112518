import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getAllHotelsByGroupId} from '@app/api/groupAdmin/hotel/hotel.api';
import {Notification, notifications as fetchedNotifications} from '@app/api/notifications.api';
import {getAllChannels} from '@app/api/hotel/channel.api';
import {filter} from 'lodash';

export interface NotificationState {
  hotelNotifications: Notification[];
}

interface IHotelId {
  id: number;
  groupId: number;
  hotelNotifications: Notification[];
}

const initialState: NotificationState = {
  hotelNotifications: [],
};

export const getNotificationByHotelId = createAsyncThunk(
  'hotelConfig/getNotificationByHotelId',
  async (payload: IHotelId, {dispatch}) => {
    const result: any = await getAllChannels(
      {code: '', countryName: '', name: '', channelTypeName: ''},
      200,
      0,
      1,
      payload.id,
      'PENDING',
    );

    const channels: any[] = result?.result?.channels;

    const notific = filter(channels, (res, index) => index < 10 && res.channelStatus === 'NEW_PENDING').map(
      (post, index) => {
        return {
          id: 4,
          description: '',
          userName: `You have receive new ${post.name} channel  request from ${post.initialHotelName}.`,
          userIcon: post.hotelCardLogo,
          place: '',
          href: '',
          channelId: post.id,
        };
      },
    );

    dispatch(setHotelNotifications(notific));
    return result?.result?.channels;
  },
);

export const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    setHotelNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.hotelNotifications = action.payload;
    },
  },
});

export const {setHotelNotifications} = notificationSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export default notificationSlice.reducer;
