/* eslint-disable react/jsx-key */
import React, {useEffect, useState} from 'react';
import TopLevelCard from '@app/components/cards/TopLevelCard';
import styled from 'styled-components';

// import Reveal from 'react-reveal/Reveal';

// import './ProductLevelHome.less';
import {useNavigate, useParams} from 'react-router-dom';
import {BREAK_FAST, DRESSERTS, Snacks, VILLA_THUYA, beverages} from '@app/assets';
import {useDispatch} from 'react-redux';
import {setFoodCat, setSelectedBreadCrumbCat, setSelectedCat} from '@app/store/slices/foodCatSlice';
import {useAppSelector} from '@app/hooks/reduxHooks';
import {ProductData} from './FoodData';
import {TopLevelCardProps} from './Interfaces';
export const FadeIn = styled.div`
  animation: fadeInAnimation 1s linear;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
const ProductsContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
export const breakFastCatData: TopLevelCardProps[] = [
  {
    name: 'Sri Lankan',
    logo: BREAK_FAST,
    bgUrl:
      'https://www.intrepidtravel.com/adventures/wp-content/uploads/2018/05/shutterstock_514422628-e1527484322874.jpg',
    isCatagory: true,
    circleColor: '#215E81',
  },

  {
    name: 'Western',
    logo: BREAK_FAST,
    bgUrl:
      'https://rimage.gnst.jp/livejapan.com/public/article/detail/a/00/00/a0000963/img/basic/a0000963_main.jpg?20180808150720&q=80',
    isCatagory: true,
    circleColor: '#215E81',
  },

  {
    name: 'Indian',
    logo: BREAK_FAST,
    bgUrl: 'https://sukhis.com/app/uploads/2019/09/Indian-Breakfast.jpg',
    isCatagory: true,
    circleColor: '#215E81',
  },
];

const drinksCatData: TopLevelCardProps[] = [
  {
    name: 'fresh juice',
    logo: beverages,
    bgUrl:
      'https://images.pexels.com/photos/1194028/pexels-photo-1194028.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    isCatagory: true,
    circleColor: '#215E81',
  },
  {
    name: 'alcoholic drinks',
    logo: beverages,
    bgUrl:
      'https://images.pexels.com/photos/1283219/pexels-photo-1283219.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    isCatagory: true,
    circleColor: '#215E81',
  },
  {
    name: 'Soft drinks',
    logo: beverages,
    bgUrl:
      'https://images.pexels.com/photos/2983100/pexels-photo-2983100.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    isCatagory: true,
    circleColor: '#215E81',
  },
  {
    name: 'Tea Time',
    logo: beverages,
    bgUrl:
      'https://images.pexels.com/photos/230490/pexels-photo-230490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    isCatagory: true,
    circleColor: '#215E81',
  },
];

const snacksData: TopLevelCardProps[] = [
  {
    name: 'Biscuits',
    logo: Snacks,
    bgUrl:
      'https://images.pexels.com/photos/298217/pexels-photo-298217.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    isCatagory: true,
    circleColor: '#215E81',
  },

  {
    name: 'Nuts',
    logo: Snacks,
    bgUrl:
      'https://images.pexels.com/photos/86649/pexels-photo-86649.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    isCatagory: true,
    circleColor: '#215E81',
  },

  {
    name: 'Chips',
    logo: Snacks,
    bgUrl:
      'https://images.pexels.com/photos/1894325/pexels-photo-1894325.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    isCatagory: true,
    circleColor: '#215E81',
  },
];

const desertsData: TopLevelCardProps[] = [
  {
    name: 'Cakes',
    logo: DRESSERTS,
    bgUrl:
      'https://images.pexels.com/photos/4109998/pexels-photo-4109998.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    isCatagory: true,
    circleColor: '#215E81',
  },

  {
    name: 'Ice Creams',
    logo: DRESSERTS,
    bgUrl:
      'https://images.pexels.com/photos/749102/pexels-photo-749102.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    isCatagory: true,
    circleColor: '#215E81',
  },

  {
    name: 'chocolates',
    logo: DRESSERTS,
    bgUrl:
      'https://images.pexels.com/photos/918327/pexels-photo-918327.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    isCatagory: true,
    circleColor: '#215E81',
  },
];

const FoodThirdCatagory = (props: any) => {
  const {cat} = useParams();

  const category = cat?.split(':');
  const realCat = category && category[1];

  const selectedCat = useAppSelector(state => state.fcs.selectedCat);
  const breadCrumbCat = useAppSelector(state => state.fcs.breadCrumbCat);
  const mainCat = useAppSelector(state => state.fcs.mainCat);
  const foodCat = useAppSelector(state => state.fcs.foodCat);

  const dispatch = useDispatch();
  const [categoryList, setCategoryList] = useState<any>([]);
  const navigate = useNavigate();
  const onChangeCard = (data: TopLevelCardProps) => {
    // if (cat === ':Sri Lankan' || cat === ':Western' || cat === ':Indian') {
    //   setCategoryList(ProductData);
    // } else
    // {
    dispatch(setSelectedCat(data.name));
    dispatch(setFoodCat(data.name));

    navigate(`/toplevel/restaurant-dashboard/:${data?.name?.toUpperCase()}`, {state: data});
    // }
  };

  useEffect(() => {
    const filteredData = ProductData.filter(
      (post: TopLevelCardProps) => post.origin === realCat && post.time === mainCat,
    );

    setCategoryList(filteredData);
  }, []);
  return (
    <ProductsContainer>
      {/* <Reveal effect="fadeInUp"> */}
      {categoryList.map((post: TopLevelCardProps, i: number) => {
        return (
          <FadeIn onClick={() => onChangeCard(post)}>
            <TopLevelCard key={i} bgUrl={post.bgUrl} logo={post.logo} name={post.name} info={post.info} />
          </FadeIn>
        );
      })}

      {/* </Reveal> */}
    </ProductsContainer>
  );
};

export default FoodThirdCatagory;
