import React, {useEffect} from 'react';
import {Col, Image, Row} from 'antd';
import {NotificationsDropdown} from '../components/notificationsDropdown/NotificationsDropdown';
import {ProfileDropdown} from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import {SettingsDropdown} from '../components/settingsDropdown/SettingsDropdown';
import * as S from '../Header.styles';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {H4} from '@app/components/common/typography/H4/H4';
import {DashboardHome} from '../components/DashboardHome/DashboardHome';
import {HOTEL_SERVICE_MODULE_NAME, modulePermission} from '@app/utils/permissions';
import {getPendingResStatus} from '@app/store/slices/reservationSlice';
import {RESTAURANT_WS_SERVICE} from '@app/api/resturantInstance';
import SockJS from 'sockjs-client';
import {Stomp} from '@stomp/stompjs';
import {notification} from 'antd';
import type {NotificationPlacement} from 'antd/es/notification';
import {TiTickOutline} from 'react-icons/ti';
import {getNotificationByHotelId} from '@app/store/slices/notificationSlice';

const Context = React.createContext({name: 'Default'});

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({isTwoColumnsLayout}) => {
  const [api, contextHolder] = notification.useNotification();

  const serviceConfig = useAppSelector(state => state.hotelSlice.hotelServiceConfig);
  const {name, serviceType} = useAppSelector(state => state.serviceSlice.serviceConfig);
  const userPermission = useAppSelector(state => state.user.permissions);
  const permissions = modulePermission(userPermission, HOTEL_SERVICE_MODULE_NAME.DASHBOARD);
  const hotelConfig = useAppSelector(state => state.hotelSlice.hotelConfig);
  const hotelConfigNoti = useAppSelector(state => state.notificationSlice.hotelNotifications);
  const dispatch = useAppDispatch();

  const openNotification = (placement: NotificationPlacement, receivedData: any) => {
    api.success({
      icon: <Image style={{width: '28px'}} src={receivedData?.hotelCardLogo} />,
      style: {backgroundColor: 'white', border: 0, width: '30rem'},
      message: (
        <div
          style={{
            marginRight: '0rem',
          }}>{`You have receive new ${receivedData?.channelName} channel  request from ${receivedData?.hotelName}.`}</div>
      ),
      description: '',
      placement,
    });
  };

  useEffect(() => {
    dispatch(getPendingResStatus(hotelConfig.hotelId));
  }, []);

  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <Row justify="space-between">
        <Col xl={15} xxl={12}>
          <H4>{serviceConfig.name}</H4>
        </Col>
        <Col>{/* <S.GHButton /> */}</Col>
      </Row>
    </S.SearchColumn>
  ) : (
    <>
      <Row justify="space-between" style={{width: '50%'}}>
        <Col xl={15} xxl={12} md={24}>
          {serviceConfig.serviceType === 'INVENTORY' ? <H4>{name}</H4> : <H4>{serviceConfig.name}</H4>}
        </Col>
        <Col>{/* <S.GHButton /> */}</Col>
      </Row>
    </>
  );

  React.useEffect(() => {
    let stompClient: any = null;

    const WebSocketClient = (url: string) => {
      const sock = new SockJS(RESTAURANT_WS_SERVICE);
      stompClient = Stomp.over(sock);

      sock.onopen = function () {
        console.log('onopen');
      };

      stompClient.connect({}, (frame: any) => {
        stompClient.subscribe(url, data => {
          const receivedData: any = JSON.parse(data.body);

          if (receivedData) {
            getNotifications();
            getNotificationsFetch(receivedData);
          }
        });
      });
      stompClient.activate();
    };

    WebSocketClient(
      `/channel-creation/type/${serviceConfig.serviceType === 'NO_SERVICE' ? 'HOTEL' : serviceConfig.serviceType}/${
        hotelConfig.hotelId
      }`,
    );

    return () => {
      if (stompClient) {
        stompClient.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotificationsFetch = async (receivedData: any) => {
    openNotification('top', receivedData);
  };

  const getNotifications = async () => {
    await dispatch(
      getNotificationByHotelId({
        id: hotelConfig.hotelId,
        hotelNotifications: hotelConfigNoti,
        groupId: hotelConfig.groupId,
      }),
    );
  };

  return (
    <Row justify="space-between" align="middle">
      {leftSide}
      {contextHolder}
      <S.ProfileColumn xl={8} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <Row align="middle" justify="end" gutter={[10, 10]}>
          <Col>
            <Row align="middle" gutter={[{xxl: 10}, {xxl: 10}]}>
              <Col hidden={!permissions.VIEW}>
                <DashboardHome />
              </Col>
              <Col>
                <SettingsDropdown />
              </Col>
              {serviceConfig.serviceType === 'HOTEL' && (
                <Col>
                  <NotificationsDropdown />
                </Col>
              )}
            </Row>
          </Col>
          <Col>
            <ProfileDropdown />
          </Col>
        </Row>
      </S.ProfileColumn>
    </Row>
  );
};
