import houseKeepingInstance from '@app/api/houseKeepingInstance';
import {HOUSE_KEEPING_SERVICE} from '@app/api/instance';
import {ICreatePayload} from '@app/pages/houseKeeping/masterPage/trolley/interface';

export const getAllTrolly = (hotelId: number): Promise<TrollyResponse> =>
  houseKeepingInstance.get<TrollyResponse>(HOUSE_KEEPING_SERVICE + `trolley/all/hotel/${hotelId}`).then(({data}) => data);

export const createTrolly = (payload: ICreatePayload): Promise<TrollyResponse> => {
  return houseKeepingInstance.post<TrollyResponse>(HOUSE_KEEPING_SERVICE + 'trolley', payload).then(({data}) => data);
};

export const updateTrolly = (payload: ICreatePayload): Promise<TrollyResponse> => {
  return houseKeepingInstance.put<TrollyResponse>(HOUSE_KEEPING_SERVICE + 'trolley', payload).then(({data}) => data);
};

export const deleteTrolly = (id: number): Promise<TrollyResponse> => {
  return houseKeepingInstance.delete<TrollyResponse>(HOUSE_KEEPING_SERVICE + `trolley/${id}`).then(({data}) => data);
};

export interface TrollyResponse {
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
  status: string;
  statusCode: string;
}
export interface FilterProps {
  roomNumber: string;
  unitCode: string;
  viewType: string;
  roomType: string;
  phoneExtention: string;
  roomName: string;
}
