import CatFilterCards from '@app/components/layouts/main/MainLayout/CatFilterCards';
import {CategoryFilterArea, CategoryFilterCardWrap} from '@app/components/layouts/main/MainLayout/MainLayout.styles';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import React, {useEffect, useMemo, useState} from 'react';
import {
  setAllMenuItems,
  setCategoryData,
  setCategoryStatus,
  setChangeNavOption,
  setFirstCatLayerSelectedId,
  setSecondCatLayerSelectedId,
} from '../slices/categoryNavSlice';
import {getCategoryStuctureByID} from '@app/api/resturant/tablecategory/categoryStucture.api';
import {notificationController} from '@app/controllers/notificationController';
import {addFoodItems} from '../slices/waiterDasboardSlice';
import styled from 'styled-components';
import {Button} from '@app/components/common/buttons/Button/Button';
import {useNavigate} from 'react-router-dom';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';

import {Col, Row} from 'antd';

import './TopLevelFilter.style.css';

const MainMenuBtn = styled(Button)`
  -webkit-box-shadow: 3px 3px 0px 0px rgb(133 135 159);
  -moz-box-shadow: 3px 3px 0px 0px rgb(133 135 159);
  box-shadow: 3px 3px 0px 0px rgb(133 135 159);
  border: none;
  font-weight: 500;
  -moz-box-shadow: 3px 3px 0px 0px rgb(133 135 159);
  /* border-radius: 13px; */
  margin: 0px 7px;
  color: white !important;
  font-size: 12px;
  padding: 6px 12px;
  min-width: 82px;
  text-align: center;
  color: white;
  background: #85615e;
  -moz-box-shadow: 3px 3px 0px 0px rgb(133 135 159);
  cursor: pointer;

  &:hover {
    background: #bfa19a;
  }
`;

const TopLevelFilter = () => {
  const [form] = BaseForm.useForm();
  const menuItemsList = useAppSelector(state => state.categoryNav.menuItems);
  const isChangeNavOpt = useAppSelector(state => state.categoryNav.isChangeNavOpt);
  const firstLayerObj = useAppSelector(state => state.categoryNav.firstLayerObj);
  const secondLayerObj = useAppSelector(state => state.categoryNav.secondLayerObj);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [firstCatLayer, setfirstCatLayer] = React.useState<any[]>([]);
  const [secondCatLayer, setSecondCatLayer] = React.useState<any[]>([]);

  const [firstCatLayerSelected, setfirstCatLayerSelected] = React.useState<any>({});
  const [secondCatLayerSelected, setSecondCatLayerSelected] = React.useState<any>({});
  const [isChangeCard, setIsChangeCard] = useState<boolean>(false);

  const hotelServiceConfig = useAppSelector(state => state.hotelSlice.hotelServiceConfig);

  useEffect(() => {
    if (!isChangeNavOpt) {
      dispatch(setFirstCatLayerSelectedId(menuItemsList[0]));
      dispatch(setSecondCatLayerSelectedId(menuItemsList[1]));
      if (menuItemsList.length > 0) {
        getSecondLayer(menuItemsList[0].id);
      }
    } else {
      if (menuItemsList.length > 0) {
        getSecondLayer(firstLayerObj?.id);
      }
    }

    getCategoryById(0, 1);
  }, []);

  const getCategoryById = async (id: number, layerIndex?: number, index?: number) => {
    const result = await getCategoryStuctureByID(id, hotelServiceConfig.serviceId);

    const data: any[] = [];

    result.result.category &&
      result.result.category.map((post: any, i: any) => {
        data.push({
          id: post.id,
          backgroundImage: post.categoryImage,
          title: post.categoryTitle,
          children: [],
          expanded: post.expanded,
          categoryId: post.categoryId,
        });
      });

    if (layerIndex === 1) {
      setfirstCatLayer(data);
    } else if (layerIndex === 2) {
    } else {
      dispatch(setCategoryData({items: data}));
    }
  };

  const getAllFoodItemsByCategory = async (id: any, name?: string) => {
    const results = await getCategoryStuctureByID(id, hotelServiceConfig.serviceId, name);
    if (results?.statusCode === '20000') {
      const data: any[] = [];

      results.result.category &&
        results.result.category.map((post: any, i: any) => {
          data.push({
            id: post.id,
            category: '',
            currency: 'LKR',
            itemImage: post.image,
            item: post.name,
            quantity: 1,
            commentQuantity: 0,
            price: post.price,
            orderedItemStatus: 'NEW',
            active: post.active,
          });
        });

      dispatch(addFoodItems({foodItems: data}));
    } else {
      notificationController.error({message: results?.message});
    }
  };

  const getSecondLayer = async (id: number) => {
    try {
      const result = await getCategoryStuctureByID(id, hotelServiceConfig.serviceId);

      const data: any[] = [];

      const categoryData = result.result.category;

      result.result.category &&
        result.result.category.map((post: any, i: any) => {
          data.push({
            id: post.id,
            backgroundImage: post.categoryImage,
            title: post.categoryTitle,
            children: [],
            expanded: post.expanded,
            categoryId: post.categoryId,
          });
        });

      if (categoryData && categoryData[0]?.name) {
        setSecondCatLayer([]);
      } else {
        setSecondCatLayer(data);
      }

      if (isFromCard) {
        const firstItem = data[0];
        firstItem && dispatch(setSecondCatLayerSelectedId(data[0]));
        firstItem && setSecondCatLayerSelected(data[0]);
        if (firstItem) {
          if (firstItem.expanded) {
            dispatch(setCategoryStatus({status: true}));
            dispatch(setCategoryData({items: firstItem.children}));

            getCategoryById(firstItem.id);
          } else {
            localStorage.setItem('categoryId', firstItem.id.toString());
            getAllFoodItemsByCategory(firstItem.id);
            dispatch(setCategoryStatus({status: false}));
          }
        }
      } else {
        // getFoodsByCategoryId(realCat);
      }
    } catch (error) {}
  };

  let [isFromCard] = React.useState(false);

  return (
    <div>
      <Row
        style={{
          width: '100%',
        }}>
        <Col md={1} xs={24}>
          {/* <MainMenuBtn
            title=""
            onClick={() => {
              navigate('/category/main');
              getCategoryById(0);
              dispatch(setAllMenuItems({items: []}));
            }}>
            Main menu
          </MainMenuBtn> */}
        </Col>
        <Col md={22} xs={24}>
          <CategoryFilterArea>
            <div>
              <CategoryFilterCardWrap>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    marginBottom: '0.4rem',
                  }}>
                  {firstCatLayer.map((post: any, index) => {
                    return (
                      <button
                        key={index}
                        className="pushable"
                        onClick={() => {
                          dispatch(setChangeNavOption(true));
                          isFromCard = true;
                          dispatch(setFirstCatLayerSelectedId(post));
                          setfirstCatLayerSelected(post);
                          if (post.expanded) {
                            getSecondLayer(post.id);
                            post?.children.length > 0 && dispatch(setCategoryStatus({status: false}));
                            dispatch(setCategoryData({items: []}));
                            dispatch(addFoodItems({foodItems: []}));
                          } else {
                            localStorage.setItem('categoryId', post.id.toString());
                            dispatch(setCategoryStatus({status: false}));
                            getAllFoodItemsByCategory(post.id);
                            setSecondCatLayer([]);
                          }
                        }}>
                        <span
                          className="front"
                          style={{backgroundColor: post.id === firstLayerObj?.id ? '#0014ff' : '#585858'}}>
                          {post.title}
                        </span>
                      </button>
                    );
                  })}
                </div>
              </CategoryFilterCardWrap>
              <CategoryFilterCardWrap>
                {secondCatLayer.map((post: any, index) => {
                  return (
                    <button
                      key={index}
                      className="pushable"
                      onClick={() => {
                        dispatch(setChangeNavOption(true));
                        dispatch(setSecondCatLayerSelectedId(post));
                        setSecondCatLayerSelected(post);
                        isFromCard = true;

                        if (post.expanded) {
                          dispatch(setCategoryStatus({status: true}));
                          dispatch(setCategoryData({items: post.children}));
                          getCategoryById(post.id);
                        } else {
                          localStorage.setItem('categoryId', post.id.toString());
                          getAllFoodItemsByCategory(post.id);
                          dispatch(setCategoryStatus({status: false}));
                        }
                      }}>
                      <div
                        className="front"
                        style={{
                          backgroundColor: post.id === secondLayerObj?.id ? '#0014ff' : '#585858',
                          marginTop: '0.2rem',
                        }}>
                        {post.title}
                      </div>
                    </button>
                  );
                })}
              </CategoryFilterCardWrap>
            </div>
          </CategoryFilterArea>
        </Col>
      </Row>
    </div>
  );
};

export default TopLevelFilter;
