/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import * as S from './DashboardPage.styles';
import {ExclamationCircleOutlined, LeftCircleOutlined, RightCircleOutlined} from '@ant-design/icons';

import {
  Button,
  Col,
  Row,
  Input,
  Form,
  Radio,
  Tooltip,
  Badge,
  Tag,
  Drawer,
  Space,
  Divider,
  SelectProps,
  Spin,
  Popconfirm,
  TimePicker,
  Checkbox,
} from 'antd';
import {IOrderItem} from '@app/api/getOrders.api';

import {MdNoFood, MdClose, MdTableRestaurant} from 'react-icons/md';

import SockJS from 'sockjs-client';
import {Stomp} from '@stomp/stompjs';

import {FaRegCommentDots, FaRegComment} from 'react-icons/fa';

import {PlusOutlined, SyncOutlined} from '@ant-design/icons';
import {Avatar, Card} from 'antd';
import {debounce, filter, find, isArray, remove, uniq, uniqBy} from 'lodash';
import {Carousel} from 'react-responsive-carousel';
import styled from 'styled-components';
// import {Popover} from '@app/components/common/Popover/Popover';
import {TextArea} from '@app/components/common/inputs/Input/Input';
import Modal from 'antd/lib/modal/Modal';
import {Text} from '@app/components/Error/Error.styles';
import {Select} from '@app/components/common/selects/Select/Select.styles';
import {
  cancelOrder,
  completeCurrentOrder,
  createCustomFood,
  createOrder,
  createTableAllocation,
  getOrdersByTableId,
  getRoomNoSearch,
  getTableSearch,
  searchCustomFood,
  updateOrder,
  updateTableAllocation,
  updateTableAllocationStatus,
  updateVatapplicable,
} from '@app/api/resturant/tablecategory/order.api';
import {convertNumberFormatWithDecimal, noNegativeValues, noNegativeValuesWithDecimals} from '@app/utils/utils';
import {notificationController} from '@app/controllers/notificationController';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {INewOrderList, addTableId, setNewOrderList, setsliderIndex} from '../slices/waiterDasboardSlice';
import {RESTAURANT_WS_SERVICE} from '@app/api/resturantInstance';
import {NOTIFY_SOUND} from '@app/assets';
import {GetResponse, ReadyToServe, changeStatus} from '@app/pages/DashboardPages/chef.api';
import ServeIcon from './ServeIcon';
import {AutoComplete} from '@app/components/common/AutoComplete/AutoComplete';
import {useEffect, useState} from 'react';

import './TopLevelFilter.style.css';
import {async} from 'q';
import BlinkingText from './BlinkingText';
import {BsClockFill} from 'react-icons/bs';
import moment from 'moment';
import {PaymentType} from './PaymentType';
import {calculateTotalTaxWithSequence} from '@app/utils/functions';
import {CustomerVatRegister} from '../VatRegistry/CustomerVatRegister';

const {Meta} = Card;

interface IStyleProps {
  isSelected: boolean;
}

const OrderItem = styled(Card)`
  height: 70px;

  border-radius: 15px;
  margin-left: 10px;

  -webkit-box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.32);
  box-shadow: 0px 0px 20px -13px rgba(0, 0, 0, 0.32);
`;

const TableButton = styled(Button)`
  box-shadow: 1px 3px 0px 0px rgb(133 135 159);
  margin-right: 10px;
  background: ${(props: IStyleProps) => (props.isSelected ? '#fd860c' : '#686868')};
  border: ${(props: IStyleProps) => (props.isSelected ? 'none' : 'none')};
  color: ${(props: IStyleProps) => (props.isSelected ? '#fff' : 'white')};
  min-width: 102px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${(props: IStyleProps) => (props.isSelected ? '  #fd860cd4' : '#686868c2')};
    color: #fff;
    transform: translateY(-1px);
  }
  &:active {
    transform: scale(0.98);
    transition: all 100ms ease-in;
    background: ${(props: IStyleProps) => (props.isSelected ? '#fd860c' : '#686868')};
  }
  &:focus {
    background: ${(props: IStyleProps) => (props.isSelected ? '#fd860c' : '#686868')};
  }

  &.ant-btn-primary:focus {
    background: ${(props: IStyleProps) => (props.isSelected ? '#fd860c' : '#686868')};
  }
`;

const Quntity = styled('div')`
  display: flex;
  align-items: center;
`;

export const ShadowedButton = styled(Button)`
  box-shadow: rgb(83 83 92) 1px 3px 0px 0px !important;
  &:active {
    transform: scale(0.98);
    transition: all 100ms ease-in;
  }
`;

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

function DebounceSelect<ValueType extends {key?: string; label: React.ReactNode; value: string | number} = any>({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = React.useRef(0);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then(newOptions => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

// Usage of DebounceSelect
interface UserValue {
  label: string;
  value: string;
}

interface IProps {
  getReadyToServeStatus: (status: boolean) => void;
}
const RightArea: React.FC<IProps> = (props: IProps) => {
  const {getReadyToServeStatus} = props;

  const selelctedTableId = useAppSelector(state => state.waiterDasbordSlice.selectedTableId);
  const silderIndex = useAppSelector(state => state.waiterDasbordSlice.silderIndex);
  const dispatch = useAppDispatch();
  const orderList = useAppSelector(state => state.waiterDasbordSlice.orderList);
  const [isTakeAway, setIsTakeAway] = React.useState<boolean>(false);
  // const [tableId, setTableId] = React.useState<number | null>(null);
  const [tables, setTables] = React.useState<any[]>([]);
  const [isEditOrder, setIsEditOrder] = React.useState<boolean>(false);
  const [orderId, setOrderId] = React.useState<number>();
  const [isAddNewOrder, setIsAddNewOrder] = React.useState<boolean>(true);
  const [opacity, setOpacity] = React.useState<number>(0.2);
  const [selectedTable, setSelectedTable] = React.useState<number>();
  const [orderStatus, setOrderStatus] = React.useState<string>('');
  const [currentOrderStatus, setCurrentOrderStatus] = React.useState<string>('');
  const [selectedItem, setSelectedItem] = React.useState<any>({});
  const [filteredTables, setFilteredTables] = React.useState<any[]>([]);
  const [autofillData, setAutofillData] = React.useState<any[]>([]);
  const [tableOrderData, setTableOrderData] = React.useState<INewOrderList[]>([]);
  const newOrderList = useAppSelector(state => state.waiterDasbordSlice.newOrderList);
  const [open, setOpen] = React.useState<boolean>(false);
  const [comment, setComment] = React.useState<string | undefined>('');
  const [openNewFood, setOpenNewFood] = React.useState<boolean>(false);
  const [enableOrderBtn, setEnableOrderBtn] = React.useState<boolean>(true);
  const [tableIdSocket, settableIdSocket] = React.useState(undefined);
  const [openTableSelection, setOpenTableSelection] = React.useState(false);
  const [searchRoomNo, setSearchRoomNo] = useState<UserValue[]>([]);
  const [isRoomNoRequired, setIsRoomNoRequired] = useState<boolean>(true);
  const [newTableId, setNewTableId] = React.useState<number>(0);
  const [allocateTableStatus, setAllocateTableStatus] = React.useState<string>('');
  const [isServedAny, setIsServedAny] = React.useState<boolean>(false);
  const [openRoomSelect, setOpenRoomSelect] = React.useState<boolean>(false);
  const [openCashSelect, setOpenCashSelect] = React.useState<boolean>(false);
  const [tableAllocationIdUpdate, setTableAllocationIdUpdate] = React.useState(undefined);
  const [reconfirmTables, setReconfirmTables] = useState<string[]>([]);
  const [orderTax, setOrderTax] = useState<any>({
    subTotal: 0.0,
    discount: 0.0,
    serviceCharge: 0.0,
    serviceChargeRate: 0.0,
    taxResponseList: [],
    totalCharge: 0,
  });
  const [reactedOrderItems, setReactedOrderItems] = React.useState<any[]>([]);
  const [paymentType, setPaymentType] = React.useState<any>('');
  const [paymentTypeOrigin, setpaymentTypeOrigin] = React.useState<any>('');
  const [selectedRoomNo, setSelectedRoomNo] = React.useState<string>('');
  const [disableRemarkBtn, setDisableRemarkBtn] = React.useState<boolean>(false);
  const [stopApiCall, setStopApiCall] = React.useState<boolean>(false);
  const [enableCancelBtnPlaceStatus, setEnableCancelBtnPlaceStatus] = React.useState<boolean>(false);
  const [openPreOrderTime, setOpenPreOrderTime] = React.useState<boolean>(false);
  const [openPaymentType, setOpenPaymentType] = React.useState<boolean>(false);
  const [invoicePaymentType, setInvoicePaymentType] = useState<'CASH' | 'CREDITCARD'>('CASH');

  const [orderTime, setOrderTime] = React.useState<any>(null);
  const [customerName, setCustomerName] = React.useState<any>(null);
  const [vatApplicable, setVatApplicable] = React.useState<boolean>(true);
  const [vatRegiesterCustomer, setVatRegiesterCustomer] = React.useState<boolean>(false);
  const [vatActive, setVatActive] = React.useState<boolean>(true);
  const [reservedRoomData, setReservedRoomData] = React.useState<any[]>([]);
  const [selectedReservedRoomData, setSelectedReservedRoomData] = React.useState<any>({});
  const [vatRegiestryId, setVatRegiestryId] = React.useState<any>(0);

  const [form] = Form.useForm();
  const [formTableSelect] = Form.useForm();
  const [formPreOrder] = Form.useForm();
  const [formCashSelect] = Form.useForm();
  const [customerVatRegisterForm] = Form.useForm();

  const hotelServiceConfig = useAppSelector(state => state.hotelSlice.hotelServiceConfig);
  const hotelConfig = useAppSelector(state => state.hotelSlice.hotelConfig);
  const hoteluserConfig = useAppSelector(state => state.user.userPermission);

  async function fetchUserList(username: string): Promise<UserValue[]> {
    const results = await getRoomNoSearch(username, hotelConfig.hotelId);
    setReservedRoomData(results.result.reservedRoom);
    return results.result.reservedRoom.map((room: {reservedRoomId: number; roomNo: string}) => ({
      label: room.roomNo,
      value: room.reservedRoomId,
    }));
  }

  useEffect(() => {
    onChangeTable(selelctedTableId, 'start', false);
  }, [tableIdSocket, currentOrderStatus]);

  React.useEffect(() => {
    getAllTables(false);
    selelctedTableId && onChangeTable(selelctedTableId, 'start', false);
  }, []);

  React.useEffect(() => {
    setTableOrderData(newOrderList);
  }, [newOrderList]);

  React.useEffect(() => {
    let stompClient: any = null;

    const WebSocketClient = (url: string) => {
      const sock = new SockJS(RESTAURANT_WS_SERVICE);
      stompClient = Stomp.over(sock);

      sock.onopen = function () {};

      stompClient.connect({}, (frame: any) => {
        stompClient.subscribe(url, data => {
          //

          const receivedData = JSON.parse(data.body);

          if (receivedData) {
            const audio = new Audio(NOTIFY_SOUND);
            audio.play();
          }

          const inCommingTableId = receivedData[0]?.tableId;

          setOrderStatus(receivedData.orderStatus);
          const newData: any[] = [];

          if (receivedData) {
            if (isArray(receivedData)) {
              receivedData &&
                receivedData.map((post: any, i: any) => {
                  newData.push({
                    id: post.id,
                    itemId: post.itemId,
                    category: '',
                    currency: 'LKR',
                    itemImage: post.image,
                    item: post.itemName,
                    quantity: post.quantity,
                    commentQuantity: 0,
                    price: post.price,
                    orderedItemStatus: post.orderedItemStatus,
                    selelctedTableId: selelctedTableId,
                    totalPrice: post.price,
                  });
                });
              setCurrentOrderStatus('REJECTED');
              settableIdSocket(inCommingTableId);
            } else {
              const key = Object.keys(receivedData)[0];
              const status = receivedData[key];
              setCurrentOrderStatus(status);
              settableIdSocket(selelctedTableId);

              const localOrderId = localStorage.getItem('orderId');

              if (Number(localOrderId) === receivedData.orderId) {
                if (receivedData?.orderedItemStatus === 'BEING_PREPARED') {
                } else if (receivedData?.orderedItemStatus === 'READY_TO_SERVE') {
                  getAllTables(false);
                }
              }
            }
          }
        });
      });
      stompClient.activate();
    };

    WebSocketClient(`/user/${hoteluserConfig?.userId}/queue/waiter`);

    return () => {
      // Cleanup the WebSocket connection on component unmount
      if (stompClient) {
        stompClient.disconnect();
      }
    };
  }, []);

  const convertNumberFormat = (number: any) => {
    const num = number.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return num;
  };
  const calculateTotalPrice = () => {
    const defaultData: INewOrderList[] = [...newOrderList];
    const findObj = find(defaultData, res => res.tableId === Number(selelctedTableId));

    const selectTableOrderList: INewOrderList = findObj
      ? findObj
      : {tableId: 0, orderId: 0, orderStatus: '', foodItemList: []};
    const orderListItems: IOrderItem[] = selectTableOrderList.foodItemList;
    const filterNewItemList: IOrderItem[] = filter(orderListItems, res => res.orderedItemStatus !== 'REJECTED');

    const totalPrice: number = filterNewItemList.reduce((total, item) => total + item.totalPrice, 0);
    const serviceChargeRate = orderTax.serviceChargeRate;

    const serviceCharge = (totalPrice * serviceChargeRate) / 100;
    const totalAmount = totalPrice + serviceCharge;

    const taxResults = calculateTotalTaxWithSequence(totalAmount, orderTax.taxResponseList, vatApplicable);
    const finalTotalCharge = taxResults.totalAmount;

    const finalCgarge = {
      subTotal: convertNumberFormatWithDecimal(Number(totalPrice), 2),
      serviceCharge: convertNumberFormatWithDecimal(Number(serviceCharge), 2),
      totalAmount: convertNumberFormatWithDecimal(Number(finalTotalCharge), 2),
      taxResponseList: taxResults.taxArray,
    };

    return finalCgarge;
  };

  const converFinalData = (data: INewOrderList[]) => {
    const updatedTableData = data.map(table => {
      const updatedFoodItemList = table.foodItemList.map(item => {
        const matchingItems = table.foodItemList.filter(foodItem => foodItem.uniqKey === item.uniqKey);
        const totalQuantity = matchingItems.reduce((sum, foodItem) => sum + foodItem.quantity, 0);
        const totalPrice = totalQuantity * item.price;

        return {
          ...item,
          totalPrice: totalPrice,
          quantity: totalQuantity,
        };
      });

      return {
        tableId: table.tableId,
        foodItemList: uniqBy(updatedFoodItemList, 'uniqKey'),
      };
    });
    return updatedTableData;
  };

  const onChangeTable = async (id: any, status: any, rejected: boolean) => {
    if (id !== undefined && id !== null) {
      const defaultData: INewOrderList[] = [...newOrderList];
      const findObj = find(defaultData, res => res.tableId === Number(id));

      const selectTableOrderList: INewOrderList = findObj
        ? findObj
        : {tableId: 0, orderId: 0, orderStatus: '', foodItemList: []};

      const filterNewItemList: IOrderItem[] = filter(
        selectTableOrderList?.foodItemList,
        res => res.orderedItemStatus === 'NEW',
      );

      try {
        const orderItemsFinal: IOrderItem[] = [];
        const results = await getOrdersByTableId(hotelServiceConfig.serviceId, id);

        const orderedItems = results.result.order;
        const taxInt = {
          subTotal: 0.0,
          discount: 0.0,
          serviceCharge: 0.0,
          serviceChargeRate: 10.0,
          taxResponseList: [],
          totalCharge: 0,
        };
        getReadyToServeStatus(orderedItems.readyToServe);
        setReconfirmTables(orderedItems?.requestConfirmationTableList);
        orderedItems?.paymentMethod !== null && setPaymentType(orderedItems?.paymentMethod);
        orderedItems?.paymentMethod !== null && setpaymentTypeOrigin(orderedItems?.paymentMethod);

        orderedItems?.roomNumber !== null && setSelectedRoomNo(orderedItems?.roomNumber);

        const orderBill = orderedItems.bill ? orderedItems.bill : taxInt;

        setOrderTax(orderBill);
        setOrderId(orderedItems.id);
        setOrderStatus(orderedItems.orderStatus);
        setVatApplicable(orderedItems.vatApplicable);
        setVatActive(orderedItems.vatActive);
        setVatRegiestryId(orderedItems.vatRegistryId);
        setCustomerName(orderedItems.custmorName);

        formCashSelect.setFieldValue('custmorName', orderedItems.remarks);
        formCashSelect.setFieldValue('guestName', orderedItems?.customerName);
        setOrderTime(orderedItems.orderTime);
        formPreOrder.setFieldValue(
          'preOrderTime',
          orderedItems.orderTime ? moment(orderedItems.orderTime, 'HH:mm') : undefined,
        );

        setIsServedAny(orderedItems.servedAny);
        if (orderedItems.orderStatus === 'REQUEST_CONFIRMATION') {
          setEnableOrderBtn(false);
        } else {
          setEnableOrderBtn(true);
        }
        const orderedItemsList: any[] = orderedItems.orderedItemDtoList ? orderedItems.orderedItemDtoList : [];
        const findNewOrder = filter(orderedItemsList, item => item.orderedItemStatus === 'NEW');

        if (orderedItems.orderedItemDtoList) {
          if (findNewOrder.length > 0) {
            setEnableCancelBtnPlaceStatus(true);
          } else {
            setEnableCancelBtnPlaceStatus(false);
          }
        } else {
          setEnableCancelBtnPlaceStatus(false);
        }
        if (orderedItemsList.length > 0) {
          setIsEditOrder(true);

          const rejectedItems = filter(orderedItemsList, res => res.orderedItemStatus === 'REJECTED');
          setReactedOrderItems(rejectedItems);
        } else {
          setIsEditOrder(orderedItems.orderStatus !== null ? true : false);
          setEnableOrderBtn(true);
        }
        orderedItemsList.map((post: any) => {
          const findRejectedItem = find(filterNewItemList, res => res.itemId === post.itemId);

          if (findRejectedItem) {
            remove(filterNewItemList, res => res.itemId === findRejectedItem.itemId);
          }

          orderItemsFinal.push({
            id: post.id,
            key: post.id,
            uniqKey: `${id}_${post.itemId}_${post.orderedItemStatus}`,
            quantity: post.quantity,
            item: post.name,
            itemId: post.itemId,
            currency: 'LKR',
            itemImage: post.image,
            comments: post.remarks,
            commentQuantity: '',
            orderedItemStatus: post.orderedItemStatus,
            selelctedTableId: id,
            price: post.price,
            category: '',
            kotId: post.kotId,
            totalPrice: post.price,
            hotelServiceId: orderedItems.hotelServiceId,
            active: post.active,
          });

          return null;
        });

        const objOrder: INewOrderList = {
          tableId: id,
          orderStatus: orderedItems.orderStatus,
          orderId: Number(orderedItems.id),
          foodItemList: [...orderItemsFinal, ...filterNewItemList],
        };

        remove(defaultData, res => res.tableId === id);
        defaultData.push(objOrder);

        !rejected && dispatch(setNewOrderList({orderList: converFinalData(defaultData)}));
        setCurrentOrderStatus('');
      } catch (error) {
        setIsEditOrder(false);
      }
    }
  };

  const isServerd = () => {
    const defaultData: INewOrderList[] = [...newOrderList];
    const findObj = find(defaultData, res => res.tableId === Number(selelctedTableId));

    const selectTableOrderList: INewOrderList = findObj
      ? findObj
      : {tableId: 0, orderId: 0, orderStatus: '', foodItemList: []};
    //

    const orderedItemResponseList = selectTableOrderList.foodItemList;
    if (selectTableOrderList.foodItemList.length > 0) {
      return orderedItemResponseList.some((item: any) => item.orderedItemStatus !== 'SERVED');
    } else {
      return true;
    }
    // return selectTableOrderList.orderStatus === 'SERVED' ? false : true;
  };

  const placeOrder = async () => {
    const findTable = find(tables, res => res.value === selelctedTableId);

    const tableAllocationId = findTable && findTable.tableAllocationId;
    if (paymentType === 'OTHER' || paymentType === undefined || paymentType === null) {
      notificationController.error({message: 'Please select a payment method (Cash / Room).'});
    } else {
      setStopApiCall(true);
      if (isEditOrder) {
        const orderItems: any[] = [];

        const defaultData: INewOrderList[] = [...tableOrderData];
        const findObj = find(defaultData, res => res.tableId === selelctedTableId);

        const selectTableOrderList: INewOrderList = findObj
          ? findObj
          : {tableId: 0, orderId: 0, orderStatus: '', foodItemList: []};
        const initOrderList: IOrderItem[] = selectTableOrderList.foodItemList;

        const filterData = filter(
          initOrderList,
          res =>
            res.orderedItemStatus !== 'READY_TO_SERVE' &&
            res.orderedItemStatus !== 'BEING_PREPARED' &&
            res.orderedItemStatus !== 'SERVED' &&
            res.orderedItemStatus !== 'ACCEPTED' &&
            res.orderedItemStatus !== 'COMPLETED' &&
            res.orderedItemStatus !== 'REJECTED',
        );

        filterData.map((post: IOrderItem) => {
          orderItems.push({
            quantity: post.quantity,
            remarks: post.comments ? post.comments : '',
            itemId: post.itemId,
            kotId: post.kotId,
            orderedItemStatus: post.orderedItemStatus,
            id: post.id === 0 ? null : post.id,
          });
        });

        try {
          const data = {
            id: orderId,
            guestName: '',
            phoneNo: '',
            totalPrice: 1000,
            employeeId: '',
            orderStatus: orderStatus === 'REQUEST_CONFIRMATION' ? 'PLACED' : orderStatus,
            guestType: 'INSIDE',
            orderType: isTakeAway ? 'TAKEAWAY' : 'DINE',
            tableId: selelctedTableId,
            tableAllocationId: tableAllocationId,
            orderedItemDtoList: orderItems,
            orderTime: orderTime,
            customerName: customerName,
            vatApplicable: vatApplicable,
          };
          const result = await updateOrder(data);
          setStopApiCall(false);
          if (result.statusCode === '20000') {
            notificationController.success({message: result.message});
            setEnableOrderBtn(true);
            setIsAddNewOrder(true);
            onChangeTable(selelctedTableId, {}, false);
          } else {
            notificationController.error({message: result.message});
          }
        } catch (error) {
          setStopApiCall(false);
        }
      } else {
        setEnableOrderBtn(false);
        const orderItems: any[] = [];

        const defaultData: INewOrderList[] = [...tableOrderData];
        const findObj = find(defaultData, res => res.tableId === selelctedTableId);

        const selectTableOrderList: INewOrderList = findObj
          ? findObj
          : {tableId: 0, orderId: 0, orderStatus: '', foodItemList: []};
        const initOrderList: IOrderItem[] = selectTableOrderList.foodItemList;

        initOrderList.map((post: IOrderItem) => {
          orderItems.push({
            quantity: post.quantity,
            remarks: post.comments ? post.comments : '',
            itemId: post.itemId,
            orderedItemStatus: post.orderedItemStatus,
          });
        });
        try {
          const data = {
            guestName: '',
            phoneNo: '',
            totalPrice: 1000,
            employeeId: '',
            guestType: 'INSIDE',
            orderType: isTakeAway ? 'TAKEAWAY' : 'DINE',
            tableId: selelctedTableId,
            tableAllocationId: tableAllocationId,
            orderedItemDtoList: orderItems,
            orderTime: orderTime,
            customerName: customerName,
            vatApplicable: vatApplicable,
          };

          const result = await createOrder(data);
          setStopApiCall(false);
          if (result.statusCode === '20000') {
            notificationController.success({message: result.message});
            onChangeTable(selelctedTableId, {}, false);
          } else {
            notificationController.error({message: result.message});
          }
        } catch (error) {
          setStopApiCall(false);
        }
      }
    }
  };

  const getAllTables = async (status: boolean) => {
    const tableData: any[] = [];
    const data: any = await getTableSearch(hotelServiceConfig.serviceId, hoteluserConfig?.userId);

    data?.result?.restaurantTable.map((post: any) => {
      tableData.push({
        value: post?.id,
        title: post?.number,
        status: post?.status,
        tableAllocationId: post?.tableAllocationId,
        paymentMethod: post?.paymentMethod,
        reservedRoomId: post?.reservedRoomId,
        roomNumber: post?.roomNumber,
      });
    });

    setTables(tableData);

    const filteredData: any[] = filter(
      tableData,
      (res: any) =>
        res?.status === 'OCCUPIED' ||
        res?.status === 'RESERVED' ||
        res?.status === 'WAITING_FOR_ORDER' ||
        res?.status === 'READY_TO_SERVE' ||
        res?.status === 'SERVED',
    );
    if (filteredData.length > 0) {
      setFilteredTables(filteredData);
      const initId = filteredData && filteredData[0]?.value;
      const initRoomNo = filteredData && filteredData[0]?.roomNumber ? filteredData[0]?.roomNumber : '';
      silderIndex === 0 && setSelectedRoomNo(initRoomNo);
      if (selelctedTableId && selelctedTableId !== 0 && selelctedTableId !== null) {
        dispatch(addTableId({selectedTableId: Number(selelctedTableId)}));
      } else {
        onChangeTable(initId, 'start', false);
        dispatch(addTableId({selectedTableId: initId}));
      }

      if (status) {
        dispatch(setsliderIndex({index: 0}));
        dispatch(addTableId({selectedTableId: initId}));
        onChangeTable(initId, 'start', false);
      }
    } else {
      setFilteredTables([]);
      setPaymentType('');
      setSelectedRoomNo('');
      dispatch(addTableId({selectedTableId: 0}));
    }
  };

  const getNewAddedItemStatus = () => {
    const defaultData: INewOrderList[] = [...newOrderList];
    const findObj = find(defaultData, res => res.tableId === Number(selelctedTableId));

    const selectTableOrderList: INewOrderList = findObj
      ? findObj
      : {tableId: 0, orderId: 0, orderStatus: '', foodItemList: []};
    const orderedItemResponseList = selectTableOrderList.foodItemList;
    const filterNewItems: IOrderItem[] = filter(
      orderedItemResponseList,
      res => res.id === 0 && res.orderedItemStatus === 'NEW',
    );

    return filterNewItems.length === 0 && enableOrderBtn ? true : false;
  };

  const completeOrder = async (isVatRegistry: boolean, vatRegiestryObj: any) => {
    try {
      const registryTypeArray = vatRegiestryObj?.registryType ? vatRegiestryObj?.registryType : [];
      const mergeType = uniq([...registryTypeArray, 'RESTAURANT']);
      const payload = {
        id:
          paymentType === 'CASH'
            ? vatRegiestryObj?.id === 0
              ? null
              : vatRegiestryObj?.id
            : vatRegiestryId
            ? vatRegiestryId
            : vatRegiestryObj?.id === 0
            ? null
            : vatRegiestryObj?.id,
        name: vatRegiestryObj?.name,
        address: vatRegiestryObj?.address,
        vatNumber: vatRegiestryObj?.vatNumber,
        email: vatRegiestryObj?.email,
        hotelId: hotelConfig.hotelId,
        registryType: mergeType,
        groupId: hotelConfig.groupId,
        onlyThisHotelView: vatRegiestryObj?.onlyThisHotelView,
        vatRegistryApplicable: isVatRegistry ? isVatRegistry : vatRegiestryId ? true : false,
      };

      const result: any = await completeCurrentOrder(orderId, hotelConfig.hotelId, invoicePaymentType, payload);

      if (result?.statusCode === '20000') {
        notificationController.success({message: result?.message});

        //start defect fix DEF-158
        const defaultData: INewOrderList[] = [...newOrderList];
        remove(defaultData, res => res.tableId === selelctedTableId);
        dispatch(setNewOrderList({orderList: [...defaultData]}));
        //end defect fix DEF-158

        setOrderStatus('');
        dispatch(setsliderIndex({index: 0}));
        getAllTables(true);
        setOpenPaymentType(false); // onChangeTable(selelctedTableId, {});
        customerVatRegisterForm.resetFields();
        setVatRegiesterCustomer(false);
      } else {
        notificationController.error({message: result?.message});
      }
    } catch (error) {}
  };

  const serveItem = async (itemId: number) => {
    try {
      const result: GetResponse = await changeStatus(itemId, 'SERVED');
      if (result.statusCode === '20000') {
        await onChangeTable(selelctedTableId, {}, false);
      } else {
        notificationController.error({message: result.message});
      }
    } catch (error) {}
  };

  const handleSearch = async (newValue: string, searchField: string) => {
    let searchParams: Record<string, any> = {};

    if (searchField === 'FIRST_NAME') {
      searchParams = {firstName: newValue};
    } else if (searchField === 'LAST_NAME') {
      searchParams = {lastName: newValue};
    }
    const result = await searchCustomFood(newValue);

    const data = result?.result?.item?.map(
      (item: {id: number; image: string; itemType: 'TEMPORARY'; name: string; price: number}) => ({
        name: item.name,
        id: item.id,
        price: item.price,
        value: item.id,
        label: item.name,
      }),
    );
    setAutofillData(data);
  };

  const handleSelect = async (value: any, option: any) => {
    const {name, id, price, label} = option;

    form.setFieldValue('name', name);
    form.setFieldValue('price', price);
  };
  //new start

  const onChangeCard = (value: number, status?: string) => {
    dispatch(setsliderIndex({index: value}));
    formPreOrder.resetFields();
    const currentTable = filteredTables[value] ? filteredTables[value] : {};
    currentTable?.roomNumber !== null ? setSelectedRoomNo(currentTable?.roomNumber) : setSelectedRoomNo('');
    const currentTableId = filteredTables[value] ? filteredTables[value].value : 0;

    setPaymentType(currentTable.paymentMethod);
    onChangeTable(currentTableId, {}, false);
    dispatch(addTableId({selectedTableId: currentTableId}));
  };

  const decreaseQuantity = (tableId: number, uniqKey: string) => {
    setEnableOrderBtn(false);
    setTableOrderData(prevTableData => {
      const updatedTableData = prevTableData.map(table => {
        if (table.tableId === tableId) {
          const updatedFoodItemList = table.foodItemList.map(item => {
            if (item.uniqKey === uniqKey) {
              const newQuantity = Math.max(item.quantity - 1, 1);
              const newPrice = item.price * newQuantity;
              return {...item, quantity: newQuantity, totalPrice: newPrice};
            }
            return item;
          });
          return {...table, foodItemList: updatedFoodItemList};
        }
        return table;
      });
      dispatch(setNewOrderList({orderList: updatedTableData}));
      return updatedTableData;
    });
  };

  const increaseQuantity = (tableId: number, uniqKey: string) => {
    setEnableOrderBtn(false);
    setTableOrderData(prevTableData => {
      const updatedTableData = prevTableData.map(table => {
        if (table.tableId === tableId) {
          const updatedFoodItemList = table.foodItemList.map(item => {
            if (item.uniqKey === uniqKey) {
              const newQuantity = item.quantity + 1;
              const newPrice = item.price * newQuantity;
              return {...item, quantity: newQuantity, totalPrice: newPrice};
            }
            return item;
          });
          return {...table, foodItemList: updatedFoodItemList};
        }
        return table;
      });
      dispatch(setNewOrderList({orderList: updatedTableData}));
      return updatedTableData;
    });
  };

  const onRemoveItem = (item: IOrderItem, i?: number) => {
    const defaultData: INewOrderList[] = [...tableOrderData];
    const findObj = find(newOrderList, res => res.tableId === selelctedTableId);

    const selectTableOrderList: INewOrderList = findObj
      ? findObj
      : {tableId: 0, orderId: 0, orderStatus: '', foodItemList: []};
    const initOrderList: IOrderItem[] = selectTableOrderList.foodItemList;
    const xOrder = [...initOrderList];
    if (findObj) {
      remove(xOrder, res => res.uniqKey === item.uniqKey);
      remove(defaultData, res => res.tableId === selelctedTableId);

      const filterNewOrder = filter(xOrder, res => res.id === 0);

      if (orderStatus) {
        setEnableOrderBtn(false);
      } else {
        if (filterNewOrder.length === 0) {
          setEnableOrderBtn(true);
        } else {
          setEnableOrderBtn(false);
        }
      }

      const newTableObj: INewOrderList = {
        tableId: Number(selelctedTableId),
        orderId: selectTableOrderList.orderId,
        orderStatus: selectTableOrderList.orderStatus,
        foodItemList: xOrder,
      };
      setEnableCancelBtnPlaceStatus(false);
      defaultData.push(newTableObj);
    }
    dispatch(setNewOrderList({orderList: defaultData}));
  };

  const handleOpenChange = (newOpen: boolean, itemStatus: string, id?: IOrderItem, isEdit?: boolean) => {
    setDisableRemarkBtn(itemStatus === 'NEW' || itemStatus === 'PLACED' ? false : true);
    setSelectedItem(id);
    // setRemarkAddOrderBtn()
    setOpen(newOpen);
    setEnableOrderBtn(false);
    if (isEdit) {
      setComment(id?.comments);
    }
  };

  const handleClose = () => {
    setOpenNewFood(false);
    form.resetFields();
  };

  // table selection
  const showTableSelectionDrawer = () => {
    setOpenTableSelection(true);
  };

  const onTableSelectionClose = () => {
    setNewTableId(0);
    setAllocateTableStatus('');
    setOpenTableSelection(false);
  };

  const updateAllocationOccupyTable = async (
    data: any,
    type: string,
    reservedRoomObj: any,
    custmorName: string,
    guestName: string,
  ) => {
    try {
      const payload = {
        id: data?.tableAllocationId,
        tableId: data?.value,
        waiterId: hoteluserConfig?.userId,
        paymentMethod: type === 'CASH' ? type : 'ROOM_BILL',
        reservedRoomId: reservedRoomObj && reservedRoomObj?.value ? reservedRoomObj?.value : null,
        roomNumber: reservedRoomObj && reservedRoomObj?.label ? reservedRoomObj?.label : null,
        remarks: custmorName ? custmorName : null,
        vatRegistryId: selectedReservedRoomData?.vatRegistryResponse?.id
          ? selectedReservedRoomData?.vatRegistryResponse?.id
          : null,
        vatApplicable: selectedReservedRoomData?.vatApplicableForReservation,
        hotelServiceId: hotelServiceConfig.serviceId,
        customerName: guestName ? guestName : null,
      };
      setSearchRoomNo(payload.roomNumber);
      const result: any = await updateTableAllocation(payload);
      if (result.statusCode === '20000') {
        await getAllTables(false);
        if (payload.paymentMethod === 'ROOM_BILL') {
          setVatApplicable(selectedReservedRoomData?.vatApplicableForReservation);
          setPaymentType('ROOM_BILL');
          setSelectedRoomNo(payload.roomNumber);
          setOpenRoomSelect(false);
        } else {
          setPaymentType('CASH');

          setVatApplicable(result?.result?.tableAllocation?.vatApplicable);
          setOpenCashSelect(false);
        }
        notificationController.success({
          message: 'Payment method updated successfully',
        });
      } else {
        notificationController.error({message: result.message});
      }
    } catch (error) {}
  };
  const allocationOccupyTable = async (tableId: any) => {
    try {
      const payload = {
        id: null,
        tableId: tableId,
        waiterId: hoteluserConfig?.userId,
        paymentMethod: 'OTHER',
        reservedRoomId: null,
        roomNo: null,
      };

      const result: any = await createTableAllocation(payload);
      if (result.statusCode === '20000') {
        await getAllTables(true);
      } else {
        notificationController.error({message: result.message});
      }
      setTimeout(() => {
        setNewTableId(0);
      }, 300);
    } catch (error) {
      setTimeout(() => {
        setNewTableId(0);
      }, 300);
    }
  };
  const onFinishTableSelect = async () => {
    const formData = formTableSelect.getFieldsValue();
    const findObj: any = find(filteredTables, res => res.value === selelctedTableId);
    const currentTab: any = findObj ? findObj : {};
    updateAllocationOccupyTable(currentTab, paymentTypeOrigin, formData?.roomNo, '', '');
  };

  const onFinishCashSelect = async () => {
    const formData = formCashSelect.getFieldsValue();
    const findObj: any = find(filteredTables, res => res.value === selelctedTableId);
    const currentTab: any = findObj ? findObj : {};

    updateAllocationOccupyTable(currentTab, paymentTypeOrigin, {}, formData?.custmorName, formData?.guestName);
  };

  const deallocateTable = async (tableAllocationId: number) => {
    const result: any = await updateTableAllocationStatus(tableAllocationId);
    if (result.statusCode === '20000') {
      await getAllTables(true);
    } else {
      notificationController.error({message: result.message});
    }
    setTimeout(() => {
      setNewTableId(0);
    }, 300);
  };

  const onChangeBillOption = (e: any) => {
    const findObj: any = find(filteredTables, res => res.value === selelctedTableId);
    const currentTab: any = findObj ? findObj : {};
    if (e.target.value === 'ROOM_BILL') {
      setpaymentTypeOrigin(e.target.value);
      setOpenRoomSelect(true);
    } else {
      setSelectedReservedRoomData({});
      setpaymentTypeOrigin(e.target.value);
      setOpenCashSelect(true);

      // updateAllocationOccupyTable(currentTab, e.target.value, {});
    }
  };
  const confirmRejection = async () => {
    const payload = {
      id: orderId,
      orderStatus: 'PLACED',
    };
    const result = await ReadyToServe(payload);

    if (result.statusCode === '20000') {
      // notificationController.success({message: 'Order accepted successfully'});

      await onChangeTable(selelctedTableId, {}, false);
    } else {
      notificationController.error({message: result.message});
    }
  };

  const isOrderItemsIsHave = (tableId: number) => {
    const defaultData: INewOrderList[] = [...newOrderList];

    const findObj: any = find(defaultData, (item: any) => item.tableId === tableId);

    const selectTableOrderList: INewOrderList = findObj
      ? findObj
      : {tableId: 0, orderId: 0, orderStatus: '', foodItemList: []};
    const orderedItemResponseList = selectTableOrderList.foodItemList;

    return orderedItemResponseList.length > 0 ? true : false;
  };

  const curentOrderCancel = async () => {
    try {
      const result: any = await cancelOrder(orderId);

      if (result?.statusCode === '20000') {
        notificationController.success({message: result?.message});

        formPreOrder.resetFields();

        const findObj = find(newOrderList, res => res.tableId === selelctedTableId);
        const defaultData: INewOrderList[] = [...newOrderList];

        const findTable = find(tables, (tab: any) => tab.value === selelctedTableId);

        const defaultTableNumber = [...reconfirmTables];

        remove(defaultTableNumber, (ta: any) => ta === findTable.title);
        setReconfirmTables(defaultTableNumber);

        const selectTableOrderList: INewOrderList = findObj
          ? findObj
          : {tableId: 0, orderId: 0, orderStatus: '', foodItemList: []};
        const initOrderList: IOrderItem[] = selectTableOrderList.foodItemList;
        const xOrder = [...initOrderList];
        remove(xOrder, res => res.orderedItemStatus === 'NEW');
        remove(xOrder, res => res.orderedItemStatus === 'REJECTED');
        remove(defaultData, res => res.tableId === selelctedTableId);
        if (orderStatus === 'PLACED') {
          setEnableOrderBtn(true);
        }
        if (xOrder.length === 0) {
          setPaymentType('OTHER');
          setSelectedRoomNo('');
          setOrderStatus('');
          formTableSelect.resetFields();
          setEnableCancelBtnPlaceStatus(false);
          setIsEditOrder(false);
          formCashSelect.resetFields();
          setOrderTax({
            subTotal: 0.0,
            discount: 0.0,
            serviceCharge: 0.0,
            serviceChargeRate: orderTax.serviceChargeRate,
            taxResponseList: orderTax.taxResponseList,
            totalCharge: 0,
          });
        } else {
          setEnableOrderBtn(true);
          setIsEditOrder(true);
          onChangeTable(selelctedTableId, false, true);
        }

        if (xOrder.length === 0) {
          setEnableOrderBtn(true);
        }

        const newTableObj: INewOrderList = {
          tableId: Number(selelctedTableId),
          orderId: 0,
          orderStatus: selectTableOrderList.orderStatus,
          foodItemList: xOrder,
        };
        defaultData.push(newTableObj);

        dispatch(setNewOrderList({orderList: [...defaultData]}));

        // onChangeTable(selelctedTableId, false);
      } else {
        notificationController.error({message: result?.message});
      }
    } catch (error) {}
  };

  const onFinishPreOrder = async () => {
    const formData = formPreOrder.getFieldsValue();
    const getTime = formData.preOrderTime.format('HH:mm');
    // setCustomerName(formData?.custmorName);
    setOrderTime(getTime);

    setTimeout(() => {
      setOpenPreOrderTime(false);
    }, 300);
  };

  const disabledTime = (current: moment.Moment) => {
    const currentTime = moment();
    const currentHour = currentTime.hour();
    const currentMinute = currentTime.minute();

    // Create a moment object for the current time
    const currentMoment = moment({hour: currentHour, minute: currentMinute});

    // Define the disabled range
    const disabledRange = {
      disabledHours: () => {
        // Disable all hours before the current hour
        return [...Array(currentHour).keys()];
      },
      disabledMinutes: (selectedHour: any) => {
        if (selectedHour === currentHour || selectedHour === -1) {
          // If it's the current hour, disable all minutes before the current minute
          return [...Array(currentMinute).keys()];
        } else {
          // For other hours, don't disable any minutes
          return [];
        }
      },
    };

    return disabledRange;
  };

  const disableOrderTime = () => {
    return orderStatus === 'PLACED' ||
      orderStatus === 'NEW' ||
      orderStatus === '' ||
      orderStatus === undefined ||
      orderStatus === null
      ? false
      : true;
  };

  const updateVatAppilicableOrder = async (appilicable: boolean) => {
    try {
      const data = {
        id: orderId,
        vatApplicable: appilicable,
      };

      const result = await updateVatapplicable(data);

      if (result.statusCode === '20000') {
        notificationController.success({message: result.message});

        // onChangeTable(selelctedTableId, {}, false);
      } else {
        notificationController.error({message: result.message});
      }
    } catch (error) {}
  };

  return (
    <div>
      {reconfirmTables.length > 0 && (
        <div style={{marginTop: '-14px', display: 'flex', justifyContent: 'center', cursor: 'none'}}>
          <BlinkingText title="Reconfirm Order for Table" texts={reconfirmTables} interval={1300} />
          {/* <div className="blink-conformation-button">Reconfirm Order</div> */}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 10,
        }}>
        <div>
          <Radio.Group
            value={paymentType}
            onChange={onChangeBillOption}
            disabled={filteredTables.length > 0 ? false : true}>
            <Radio
              onClick={() => {
                setOpenCashSelect(true);
              }}
              value="CASH">
              Cash
            </Radio>
            <Radio value="ROOM_BILL">
              {selectedRoomNo && paymentType === 'ROOM_BILL' ? `Room - ${selectedRoomNo}` : 'Room'}
            </Radio>
          </Radio.Group>
        </div>
        {/* <div style={{fontWeight: 'bold', marginRight: '2rem'}}> {selectedRoomNo}</div> */}
        <ShadowedButton
          icon={<BsClockFill />}
          style={{
            background: 'rgb(1 119 154)',
            border: 'none',
          }}
          size="small"
          type="primary"
          onClick={() => {
            setOpenPreOrderTime(true);
          }}
        />

        <ShadowedButton
          style={{
            background: 'rgb(1 119 154)',
            border: 'none',
          }}
          size="small"
          type="primary"
          onClick={() => {
            getAllTables(false);

            showTableSelectionDrawer();
          }}>
          Allocate Table
        </ShadowedButton>
      </div>
      <div style={{height: '10px'}}></div>
      <div
        onMouseOver={() => setOpacity(1)}
        onMouseLeave={() => setOpacity(0.2)}
        style={{
          marginTop: '6px',
          height: '308px',
        }}>
        <Carousel
          renderArrowPrev={(clickHandler, hasPrev) => {
            return (
              hasPrev && (
                <div>
                  <LeftCircleOutlined
                    style={{
                      opacity: opacity,
                      position: 'absolute',
                      top: '140px',
                      zIndex: 55,
                      fontSize: 25,
                      cursor: 'pointer',
                      transition: '200ms ease-in-out',
                    }}
                    onClick={clickHandler}
                  />
                </div>
              )
            );
          }}
          renderArrowNext={(clickHandler, hasNext) => {
            return (
              hasNext && (
                <div>
                  <RightCircleOutlined
                    style={{
                      opacity: opacity,

                      position: 'relative',
                      right: '-309px',
                      top: '-164px',
                      zIndex: 15,
                      fontSize: 25,
                      cursor: 'pointer',
                      transition: '200ms ease-in-out',
                    }}
                    onClick={clickHandler}
                  />
                </div>
              )
            );
          }}
          showArrows={true}
          showStatus={false}
          selectedItem={silderIndex}
          onChange={(value: any) => onChangeCard(value)}
          showIndicators={false}
          showThumbs={true}>
          {filteredTables.length === 0
            ? [1].map((post: any, index: number) => {
                return (
                  <div key={index}>
                    <div
                      id="blood-screening"
                      style={{
                        backgroundColor: '#fff',
                        color: '#000',
                        height: '8%',
                        borderBottom: '2px solid #32325c',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      <div
                        style={{
                          color: 'black',
                        }}>
                        Table: {post?.title}
                      </div>

                      {vatActive && (
                        <div
                          style={{
                            color: 'black',
                          }}>
                          <Checkbox
                            disabled={orderStatus ? true : false}
                            checked={vatApplicable}
                            onChange={e => {
                              setVatApplicable(e.target.checked);
                            }}>
                            VAT
                          </Checkbox>
                        </div>
                      )}

                      {orderStatus && (
                        <div>
                          <Tag
                            style={{
                              padding: '0px 5px',
                              marginBottom: 2,
                            }}
                            color={
                              orderStatus === 'ACCEPTED'
                                ? 'orange'
                                : orderStatus === 'BEING_PREPARED'
                                ? 'blue'
                                : 'green'
                            }>
                            {orderStatus === 'BEING_PREPARED'
                              ? 'BEING PREPARED'
                              : orderStatus === 'READY_TO_SERVE'
                              ? 'READY TO SERVE'
                              : orderStatus}
                            {orderStatus === 'BEING_PREPARED' ? (
                              <SyncOutlined
                                style={{
                                  marginLeft: '0.5rem',
                                }}
                                spin
                              />
                            ) : (
                              <></>
                            )}
                          </Tag>
                        </div>
                      )}

                      <div></div>
                    </div>
                    <S.Space />
                  </div>
                );
              })
            : filteredTables.map((post: any, i: number) => {
                const fillterOrderItems: any[] = find(
                  tableOrderData,
                  res => Number(res.tableId) === Number(selelctedTableId),
                )
                  ? find(tableOrderData, res => Number(res.tableId) === Number(selelctedTableId))?.foodItemList
                  : [];
                const finalOrderData = filter(
                  fillterOrderItems,
                  res => res.hotelServiceId === hotelServiceConfig.serviceId,
                );

                return (
                  <div
                    key={i}
                    style={{
                      height: '308px',
                      width: '98%',
                      alignItems: 'center',
                    }}>
                    <div
                      id="blood-screening"
                      style={{
                        backgroundColor: '#fff',
                        color: '#000',
                        height: '8%',
                        borderBottom: '2px solid #32325c',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      <div
                        style={{
                          color: 'black',
                        }}>
                        Table: {post?.title}
                      </div>

                      {vatActive && (
                        <div
                          style={{
                            color: 'black',
                          }}>
                          <Checkbox
                            disabled={orderStatus && orderStatus !== 'PLACED' ? true : false}
                            checked={vatApplicable}
                            onChange={e => {
                              setVatApplicable(e.target.checked);
                              orderId && updateVatAppilicableOrder(e.target.checked);
                            }}>
                            VAT
                          </Checkbox>
                        </div>
                      )}

                      {orderStatus && (
                        <div>
                          <Tag
                            style={{
                              padding: '0px 5px',
                              marginBottom: 2,
                            }}
                            color={
                              orderStatus === 'ACCEPTED'
                                ? 'orange'
                                : orderStatus === 'BEING_PREPARED'
                                ? 'blue'
                                : 'green'
                            }>
                            {orderStatus === 'BEING_PREPARED'
                              ? 'BEING PREPARED'
                              : orderStatus === 'READY_TO_SERVE'
                              ? 'READY TO SERVE'
                              : orderStatus}
                            {orderStatus === 'BEING_PREPARED' ? (
                              <SyncOutlined
                                style={{
                                  marginLeft: '0.5rem',
                                }}
                                spin
                              />
                            ) : (
                              <></>
                            )}
                          </Tag>
                        </div>
                      )}
                    </div>
                    <S.Space />
                    <S.ScrollCardWrapper id="patient-timeline">
                      {finalOrderData.map((item: IOrderItem, index: number) => {
                        return (
                          <div key={index}>
                            <OrderItem
                              bodyStyle={{
                                padding: 0,
                                height: '97%',
                              }}
                              key={index}
                              style={{width: '100%', padding: 0}}>
                              <Row
                                justify={'center'}
                                style={{
                                  height: '100%',
                                  overflow: 'hidden',
                                }}>
                                <Col span={6}>
                                  {item.orderedItemStatus === 'REJECTED' ? (
                                    <Badge.Ribbon
                                      style={{
                                        fontSize: '10px',
                                        marginRight: '5px',
                                        height: '20px',
                                      }}
                                      text={
                                        <div
                                          style={{
                                            fontSize: '10px',
                                            marginLeft: '5px',
                                            marginTop: '-2px',
                                          }}>
                                          {item.orderedItemStatus}
                                        </div>
                                      }
                                      color="red"
                                      placement="start">
                                      <Avatar
                                        style={{
                                          borderTopLeftRadius: 5,
                                          borderBottomLeftRadius: 5,
                                          borderTopRightRadius: 0,
                                          borderBottomRightRadius: 0,
                                          height: '100%',
                                          width: '100%',
                                        }}
                                        shape="square"
                                        src={item.itemImage}
                                      />
                                    </Badge.Ribbon>
                                  ) : (
                                    <Avatar
                                      style={{
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        height: '100%',
                                        width: '100%',
                                      }}
                                      shape="square"
                                      src={item.itemImage}
                                    />
                                  )}
                                </Col>
                                <Col
                                  span={18}
                                  style={{
                                    padding: '0px 12px',
                                  }}>
                                  <Row
                                    style={{
                                      height: '16px',
                                    }}>
                                    <Col span={8}></Col>
                                    <Col span={14}>
                                      <Text
                                        ellipsis={true}
                                        style={{
                                          textAlign: 'right',
                                          fontSize: '0.8rem',
                                          height: '26px',
                                          marginTop: '4px',
                                        }}>
                                        {item.item}
                                      </Text>
                                    </Col>
                                    <Col span={2}>
                                      {item.orderedItemStatus === 'NEW' || item.orderedItemStatus === 'REJECTED' ? (
                                        finalOrderData.length > 1 ? (
                                          <MdClose
                                            onClick={() => onRemoveItem(item, index)}
                                            style={{
                                              color: '#898686',
                                              cursor: 'pointer',
                                              fontSize: '1.25rem',
                                              marginTop: 5,
                                              marginLeft: 0,
                                              fontWeight: 'bolder',
                                            }}
                                          />
                                        ) : (
                                          <Popconfirm
                                            placement="leftBottom"
                                            okText="Yes"
                                            onConfirm={async () => {
                                              onRemoveItem(item, index);
                                              curentOrderCancel();
                                            }}
                                            cancelText="No"
                                            title={'Are you sure you want to cancel this order?'}
                                            icon={<ExclamationCircleOutlined style={{color: 'red'}} />}>
                                            <MdClose
                                              style={{
                                                color: '#898686',
                                                cursor: 'pointer',
                                                fontSize: '1.25rem',
                                                marginTop: 5,
                                                marginLeft: 0,
                                                fontWeight: 'bolder',
                                              }}
                                            />
                                          </Popconfirm>
                                        )
                                      ) : item.orderedItemStatus === 'READY_TO_SERVE' ? (
                                        <Tooltip title="Serve now" trigger={'hover'}>
                                          <span
                                            onClick={() => {
                                              serveItem(item.id);
                                            }}
                                            style={{
                                              position: 'absolute',
                                              top: '5px',
                                              right: -9,
                                              cursor: 'pointer',
                                            }}>
                                            <ServeIcon />
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={12}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'end',
                                      }}>
                                      {item.orderedItemStatus === 'NEW' ? (
                                        <Quntity>
                                          <div>
                                            <Button
                                              size="small"
                                              style={{fontSize: '10px', height: '1.3rem', width: '1.8rem'}}
                                              onClick={() => {
                                                decreaseQuantity(Number(selelctedTableId), item.uniqKey);
                                              }}>
                                              -
                                            </Button>
                                          </div>
                                          <div
                                            style={{
                                              fontSize: 15,
                                              margin: '0px 4px',
                                              marginBottom: '-5px',
                                            }}>
                                            {item.quantity}
                                          </div>
                                          <div>
                                            <Button
                                              size="small"
                                              style={{fontSize: '10px', height: '1.3rem', width: '1.8rem'}}
                                              onClick={() => {
                                                increaseQuantity(Number(selelctedTableId), item.uniqKey);
                                              }}>
                                              +
                                            </Button>
                                          </div>
                                        </Quntity>
                                      ) : (
                                        <div
                                          style={{
                                            fontSize: 12,
                                          }}>
                                          Qty : {item.quantity}
                                        </div>
                                      )}
                                    </Col>
                                    <Col span={10}>
                                      <Text
                                        style={{
                                          textAlign: 'right',
                                          fontSize: '0.6rem',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          position: 'relative',
                                          top: '25px',
                                        }}>
                                        {convertNumberFormat(Number(item.totalPrice).toFixed(2))} {item.currency}
                                      </Text>
                                    </Col>
                                    <Col span={2} hidden={item.orderedItemStatus !== 'NEW' && !item.comments}>
                                      {item.comments ? (
                                        <Tooltip placement="bottomRight" title={'Remarks'}>
                                          <FaRegCommentDots
                                            style={{
                                              color: '#30544e',
                                              fontSize: '1.5rem',
                                              position: 'relative',
                                              bottom: '-23px',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                              handleOpenChange(true, item.orderedItemStatus, item, true);
                                            }}
                                          />
                                        </Tooltip>
                                      ) : (
                                        <Tooltip placement="bottomRight" title={'Remarks'}>
                                          <FaRegComment
                                            style={{
                                              color: '#30544e',
                                              fontSize: '1.5rem',
                                              position: 'relative',
                                              bottom: '-23px',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                              handleOpenChange(true, item.orderedItemStatus, item, true);
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </OrderItem>

                            <div style={{height: 5}}></div>
                          </div>
                        );
                      })}
                    </S.ScrollCardWrapper>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginBottom: '0.6rem',
                      }}>
                      <Button
                        title="Custom Dish"
                        size="small"
                        onClick={() => setOpenNewFood(true)}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: 10,
                        }}
                        icon={<MdNoFood color="green" size={25} />}>
                        <span
                          style={{
                            fontSize: 14,
                            paddingLeft: 2,
                          }}>
                          Custom Dish
                        </span>
                      </Button>
                    </div>
                  </div>
                );
              })}
        </Carousel>
      </div>

      <Modal
        width={'350px'}
        style={{
          top: '3rem',
        }}
        open={openNewFood}
        onCancel={() => handleClose()}
        footer={false}>
        <div
          style={{
            width: '100%',
          }}>
          <S.Space />
          <Form
            onFinish={async values => {
              const itemData: any = {
                name: values?.name,
                price: Number(values.price),
              };

              try {
                const result: any = await createCustomFood(itemData);

                const customDish: any = result?.result?.customDish;

                if (result?.statusCode === '20000') {
                  const findObj = find(newOrderList, res => res.tableId === selelctedTableId);
                  const defaultData: INewOrderList[] = [...newOrderList];
                  setEnableOrderBtn(false);
                  const selectTableOrderList: INewOrderList = findObj
                    ? findObj
                    : {tableId: 0, orderId: 0, orderStatus: '', foodItemList: []};
                  const initOrderList: IOrderItem[] = selectTableOrderList.foodItemList;
                  const xOrder = [...initOrderList];
                  const newItem: IOrderItem = {
                    id: 0,
                    key: customDish.id,
                    uniqKey: `${selelctedTableId}_${customDish.id}_NEW`,
                    quantity: 1,
                    item: customDish.name,
                    itemId: customDish.id,
                    currency: 'LKR',
                    itemImage: 'https://serendipitystorage.blob.core.windows.net/customdish/custom-dish.png',
                    comments: '',
                    commentQuantity: 0,
                    orderedItemStatus: 'NEW',
                    selelctedTableId: Number(selelctedTableId),
                    price: customDish.price,
                    category: '',
                    totalPrice: customDish.price,
                    hotelServiceId: hotelServiceConfig.serviceId,
                    active: true,
                  };
                  remove(defaultData, res => res.tableId === selelctedTableId);

                  xOrder.push(newItem);
                  const newTableObj: INewOrderList = {
                    tableId: Number(selelctedTableId),
                    orderId: selectTableOrderList.orderId,
                    orderStatus: selectTableOrderList.orderStatus,
                    foodItemList: xOrder,
                  };

                  defaultData.push(newTableObj);

                  dispatch(setNewOrderList({orderList: defaultData}));
                  form.resetFields();
                  setOpenNewFood(false);
                }
              } catch (error) {}
            }}
            form={form}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Description required',
                },
              ]}>
              <AutoComplete
                options={autofillData}
                onSearch={value => handleSearch(value, 'FIRST_NAME')}
                onSelect={handleSelect}>
                <TextArea rows={2} placeholder="Description" />
              </AutoComplete>
            </Form.Item>
            <Form.Item
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Price required',
                },

                {
                  pattern: new RegExp(noNegativeValuesWithDecimals),
                  message: 'Invalid price',
                },
              ]}>
              <Input
                style={{
                  marginTop: '1rem',
                }}
                placeholder="Price"
              />
            </Form.Item>

            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                marginTop: '1rem',
              }}>
              <a onClick={() => handleClose()}>Close</a>
              <Button
                size="small"
                htmlType="submit"
                icon={<PlusOutlined />}
                style={{
                  fontSize: '12px',
                  marginLeft: '0.7rem',
                }}>
                ADD
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      <div>
        <div id="blood-screening" style={{backgroundColor: '#d9d9d9', padding: 10, borderRadius: 5, minHeight: '20vh'}}>
          <Row>
            <Col span={10}>
              <Text style={{textAlign: 'right', fontSize: '0.8rem', fontWeight: 700}}>Subtotal</Text>
            </Col>
            <Col span={4}></Col>
            <Col span={3}>
              <Text style={{textAlign: 'left', fontSize: '0.8rem', fontWeight: 600}}>LKR</Text>
            </Col>
            <Col span={7} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', height: '28px'}}>
              <Text style={{textAlign: 'right', fontSize: '1rem', fontWeight: 600}}>
                {calculateTotalPrice().subTotal}
              </Text>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Text style={{textAlign: 'right', fontSize: '0.8rem', fontWeight: 700}}>
                {`Service Charge ( ${orderTax.serviceChargeRate}% )`}{' '}
              </Text>
            </Col>
            <Col span={2}></Col>
            <Col span={3}>
              <Text style={{textAlign: 'left', fontSize: '0.8rem', fontWeight: 600}}>LKR</Text>
            </Col>
            <Col span={7} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', height: '28px'}}>
              <Text style={{textAlign: 'right', fontSize: '1rem', fontWeight: 600}}>
                {calculateTotalPrice().serviceCharge}
              </Text>
            </Col>
          </Row>

          {calculateTotalPrice().taxResponseList.map((tax: any, index: number) => {
            return (
              <Row key={index}>
                <Col span={12}>
                  <Text style={{textAlign: 'right', fontSize: '0.8rem', fontWeight: 700}}>
                    {`${tax.name} ( ${tax.originRate}% )`}{' '}
                  </Text>
                </Col>
                <Col span={2}></Col>
                <Col span={3}>
                  <Text style={{textAlign: 'left', fontSize: '0.8rem', fontWeight: 600}}>LKR</Text>
                </Col>
                <Col
                  span={7}
                  style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', height: '28px'}}>
                  <Text style={{textAlign: 'right', fontSize: '1rem', fontWeight: 600}}>
                    {convertNumberFormatWithDecimal(tax.amount, 2)}
                  </Text>
                </Col>
              </Row>
            );
          })}

          <Row style={{borderTopWidth: '1px', borderTop: '0.1rem solid black'}}>
            <Col span={10}>
              <Text style={{textAlign: 'right', fontSize: '0.8rem', fontWeight: 700}}>Total</Text>
            </Col>
            <Col span={4}></Col>
            <Col span={3}>
              <Text style={{textAlign: 'left', fontSize: '0.8rem', fontWeight: 600}}>LKR</Text>
            </Col>
            <Col span={7} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', height: '28px'}}>
              <Text style={{textAlign: 'left', fontSize: '1rem', fontWeight: 600}}>
                {calculateTotalPrice().totalAmount}
              </Text>
            </Col>
          </Row>
        </div>

        <div id="blood-screening" style={{height: '50px', marginTop: 0, padding: '5px 10px', borderRadius: 5}}>
          <Row>
            <Col span={11}>
              <Popconfirm
                disabled={
                  enableCancelBtnPlaceStatus
                    ? false
                    : !orderId || (orderStatus !== 'PLACED' && orderStatus !== 'REQUEST_CONFIRMATION')
                }
                placement="leftBottom"
                okText="Yes"
                onConfirm={async () => {
                  curentOrderCancel();
                }}
                cancelText="No"
                title={'Are you sure you want to cancel this order?'}
                icon={<ExclamationCircleOutlined style={{color: 'red'}} />}>
                <ShadowedButton
                  disabled={
                    enableCancelBtnPlaceStatus
                      ? false
                      : !orderId ||
                        (orderStatus !== 'PLACED' && orderStatus !== 'REQUEST_CONFIRMATION') ||
                        (isServedAny && orderStatus !== 'REQUEST_CONFIRMATION')
                  }
                  size="small"
                  style={{width: '100%', backgroundColor: !orderId ? '#c5d3e0' : ''}}
                  type="primary"
                  danger>
                  Cancel Order
                </ShadowedButton>
              </Popconfirm>
            </Col>
            <Col span={2}> </Col>

            <Col span={11}>
              <ShadowedButton
                size="small"
                loading={stopApiCall}
                onClick={async () => {
                  // orderStatus === 'REQUEST_CONFIRMATION' && (await confirmRejection());
                  !stopApiCall && (await placeOrder());
                }}
                disabled={getNewAddedItemStatus()}
                style={{
                  width: '100%',
                  backgroundColor: getNewAddedItemStatus() ? '#c5d3e0' : '#003eb3',
                }}
                type="primary">
                {isEditOrder ? (orderStatus === 'REQUEST_CONFIRMATION' ? 'Confirm Order' : 'Update Order') : 'Order'}
              </ShadowedButton>
            </Col>
          </Row>
          <Row>
            <ShadowedButton
              size="small"
              onClick={() => {
                setOpenPaymentType(true);
                // paymentType === 'CASH' ? setOpenPaymentType(true) : completeOrder(false, {});
              }}
              disabled={isServerd()}
              style={{
                width: '100%',
                backgroundColor: isServerd() ? '#c5d3e0' : 'green',
                marginTop: '0.4rem',
              }}
              type="primary">
              Prepare Invoice
            </ShadowedButton>
          </Row>
          <div style={{height: '0.5rem'}}></div>
        </div>
      </div>

      <Modal
        width={'600px'}
        style={{
          top: '3rem',
        }}
        open={open}
        onCancel={() => {
          setOpen(false);
          setComment('');
        }}
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}>
            <a
              onClick={() => {
                setOpen(false);
                setComment('');
              }}>
              Close
            </a>
            <Button
              size="small"
              disabled={disableRemarkBtn || (comment && comment?.length > 60) ? true : false}
              onClick={() => {
                const findObj = find(tableOrderData, res => res.tableId === selelctedTableId);
                const defaultData: INewOrderList[] = [...tableOrderData];
                const selectTableOrderList: INewOrderList = findObj
                  ? findObj
                  : {tableId: 0, orderId: 0, orderStatus: '', foodItemList: []};
                const initOrderList: IOrderItem[] = selectTableOrderList.foodItemList;
                const xOrder = [...initOrderList];
                const newItem: IOrderItem = {
                  ...selectedItem,
                  comments: comment,
                };
                remove(xOrder, res => res.uniqKey === selectedItem.uniqKey);
                remove(defaultData, res => res.tableId === selelctedTableId);

                // xOrder.push(newItem);
                const newTableObj: INewOrderList = {
                  tableId: Number(selelctedTableId),
                  orderId: selectTableOrderList.orderId,
                  orderStatus: selectTableOrderList.orderStatus,
                  foodItemList: [newItem, ...xOrder],
                };
                defaultData.push(newTableObj);

                dispatch(setNewOrderList({orderList: defaultData}));
                setOpen(false);
              }}
              icon={<PlusOutlined />}
              style={{
                fontSize: '12px',
                marginLeft: '0.7rem',
              }}>
              ADD
            </Button>
          </div>
        }>
        <div
          style={{
            width: '100%',
          }}>
          <Row gutter={20}>
            <Col span={12}>
              <img
                style={{
                  width: '100%',
                  marginTop: '1rem',
                  maxHeight: 250,
                  objectFit: 'cover',
                }}
                src={selectedItem?.itemImage}
                alt={selectedItem?.item}
              />
            </Col>
            <Col span={12}>
              <Meta
                style={{
                  marginTop: '1rem',
                }}
                title={<Text style={{textAlign: 'center', fontSize: '1rem'}}>{selectedItem?.item}</Text>}
              />
              <Text style={{textAlign: 'center', fontSize: '14px'}} type="danger">
                {selectedItem?.price} {selectedItem?.currency}
              </Text>

              <TextArea
                rows={4}
                placeholder="Remarks"
                readOnly={disableRemarkBtn}
                value={comment}
                style={{
                  marginTop: '1rem',
                }}
                onChange={e => {
                  setComment(e.target.value);
                }}
              />
              {comment && comment?.length > 60 && (
                <div style={{color: 'red', fontSize: '14px'}}>Character Limit Exceeded! (Max: 60 characters)</div>
              )}
            </Col>
          </Row>
        </div>
      </Modal>

      <Drawer
        title="Select Table"
        width={520}
        onClose={() => {
          setNewTableId(0);
          setAllocateTableStatus('');
          setOpenTableSelection(false);
          // Promise.all([getAllTables(true), setOpenTableSelection(false)]);
        }}
        open={openTableSelection}
        bodyStyle={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',

          paddingBottom: 80,
        }}
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}>
            <Space align="end">
              <ShadowedButton size="small" onClick={onTableSelectionClose}>
                Cancel
              </ShadowedButton>
            </Space>
          </div>
        }>
        <Space wrap align="center">
          {tables.length === 0 ? (
            <div>No tables available </div>
          ) : (
            tables.map((res: any, i: number) => {
              return (
                <TableButton
                  // disabled={newTableId !== 0 ? (res.value !== newTableId ? true : false) : false}
                  type="primary"
                  isSelected={
                    res?.status === 'OCCUPIED' ||
                    res?.status === 'RESERVED' ||
                    res?.status === 'WAITING_FOR_ORDER' ||
                    res?.status === 'READY_TO_SERVE' ||
                    res?.status === 'SERVED'
                  }
                  onClick={async () => {
                    setAllocateTableStatus(res?.status);

                    if (res?.status === 'VACANT') {
                      allocationOccupyTable(res.value);
                    } else if (res?.status === 'OCCUPIED' || res?.status === 'SERVED') {
                      setNewTableId(res.value);
                      // const defaultData: INewOrderList[] = [...newOrderList];

                      // const findObj: any = find(defaultData, (item: any) => item.tableId === res?.value);

                      // const selectTableOrderList: INewOrderList = findObj
                      //   ? findObj
                      //   : {tableId: 0, orderId: 0, orderStatus: '', foodItemList: []};
                      // const orderedItemResponseList = selectTableOrderList.foodItemList;
                      if (isOrderItemsIsHave(res?.value)) {
                        notificationController.warning({
                          message: 'Unable to deallocate table',
                        });
                      } else {
                        deallocateTable(res.tableAllocationId);
                      }
                    } else {
                      notificationController.warning({
                        message: 'Unable to deallocate table',
                      });
                    }
                  }}
                  icon={
                    <MdTableRestaurant
                      style={{
                        marginRight: '5px',
                      }}
                    />
                  }
                  key={i}
                  size="small"
                  title="">
                  <div
                    style={{
                      marginBottom: '3px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}>
                    <div>{res?.title}</div>
                    <div
                      hidden={res?.status === 'OCCUPIED' ? (isOrderItemsIsHave(res?.value) ? true : false) : true}
                      style={{
                        color: 'blue',
                        marginLeft: '10px',
                      }}>
                      N
                    </div>
                  </div>
                </TableButton>
              );
            })
          )}
        </Space>
      </Drawer>
      <Modal
        width={'400px'}
        style={{
          top: '3rem',
        }}
        open={openRoomSelect}
        onCancel={() => {
          setOpenRoomSelect(false);
          // setPaymentType(paymentTypeOrigin);
          formTableSelect.resetFields();
        }}
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}>
            <a
              onClick={() => {
                setOpenRoomSelect(false);
              }}>
              Close
            </a>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                formTableSelect.submit();
              }}
              style={{
                fontSize: '12px',
                marginLeft: '0.7rem',
              }}>
              Submit
            </Button>
          </div>
        }>
        <Row style={{width: '100%'}}>
          <Col md={24} xs={24}>
            <Form form={formTableSelect} layout="vertical" onFinish={onFinishTableSelect}>
              <Col md={24} xs={24}>
                <Form.Item
                  name="roomNo"
                  label="Select Room"
                  rules={[
                    {
                      required: isRoomNoRequired,
                      message: 'Room No is required',
                    },
                  ]}>
                  <DebounceSelect
                    showSearch
                    value={searchRoomNo}
                    placeholder="Select Room No"
                    onSelect={value => {
                      const findObj = find(reservedRoomData, res => res?.reservedRoomId);
                      if (findObj) {
                        setVatApplicable(findObj?.vatRegistryApplicable);
                        setSelectedReservedRoomData(findObj);
                      }
                    }}
                    fetchOptions={fetchUserList}
                    onChange={newValue => {
                      setSearchRoomNo(newValue as UserValue[]);
                    }}
                    style={{width: '100%'}}
                  />
                </Form.Item>
              </Col>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        width={'400px'}
        style={{
          top: '3rem',
        }}
        open={openCashSelect}
        onCancel={() => {
          setOpenCashSelect(false);
          // formCashSelect.resetFields();
        }}
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}>
            <a
              onClick={() => {
                setOpenCashSelect(false);
              }}>
              Close
            </a>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                formCashSelect.submit();
              }}
              style={{
                fontSize: '12px',
                marginLeft: '0.7rem',
              }}>
              Submit
            </Button>
          </div>
        }>
        <Row style={{width: '100%'}}>
          <Col md={24} xs={24}>
            <Form form={formCashSelect} layout="vertical" onFinish={onFinishCashSelect}>
              <Col md={24} xs={24}>
                <Form.Item
                  name="custmorName"
                  label="Remark"
                  rules={[
                    {
                      required: false,
                      message: 'Remark is required',
                    },
                  ]}>
                  <Input.TextArea placeholder="Enter remark" />
                </Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item
                  name="guestName"
                  label="Guest Name"
                  rules={[
                    {
                      required: false,
                      message: 'Remark is required',
                    },
                  ]}>
                  <Input placeholder="Enter name" />
                </Form.Item>
              </Col>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        width={'400px'}
        style={{
          top: '3rem',
        }}
        open={openPreOrderTime}
        onCancel={() => {
          setOpenPreOrderTime(false);
          // setPaymentType(paymentTypeOrigin);
          // formPreOrder.resetFields();
        }}
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}>
            <Button
              size="small"
              type="primary"
              disabled={disableOrderTime()}
              onClick={() => {
                formPreOrder.submit();
              }}
              style={{
                fontSize: '12px',
                marginLeft: '0.7rem',
              }}>
              Submit
            </Button>
          </div>
        }>
        <div style={{width: '100%'}}>
          <Form form={formPreOrder} layout="vertical" onFinish={onFinishPreOrder}>
            <Row>
              <Col md={12} xs={24}>
                <Form.Item
                  name="preOrderTime"
                  label="Table Time"
                  rules={[
                    {
                      required: true,
                      message: 'Time is required',
                    },
                  ]}>
                  <TimePicker
                    disabled={disableOrderTime()}
                    inputReadOnly
                    disabledTime={disabledTime}
                    format="HH:mm"
                    placeholder="Select time"
                  />
                </Form.Item>
              </Col>
              {/* <Col md={12} xs={24}>
                <Form.Item
                  name="custmorName"
                  label="Name"
                  rules={[
                    {
                      required: false,
                      message: 'Name is required',
                    },
                  ]}>
                  <Input placeholder="Enter name" />
                </Form.Item>
              </Col> */}
            </Row>
          </Form>
        </div>
      </Modal>

      <Modal
        width={'40%'}
        style={{
          top: '3rem',
        }}
        open={openPaymentType}
        onCancel={() => {
          setOpenPaymentType(false);
          customerVatRegisterForm.resetFields();
          setVatRegiesterCustomer(false);
        }}
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                vatRegiesterCustomer ? customerVatRegisterForm.submit() : completeOrder(vatRegiesterCustomer, {});
              }}
              style={{
                fontSize: '12px',
                marginLeft: '0.7rem',
              }}>
              Proceed
            </Button>
          </div>
        }>
        <Row gutter={{xs: 10, md: 15, xl: 30}}>
          {paymentType === 'CASH' && (
            <Col xs={24} md={24}>
              <div style={{width: '100%'}}>
                <div>Select payment type</div>
                <PaymentType
                  isModalVisible={openPaymentType}
                  onCreatePayment={type => {
                    setInvoicePaymentType(type);
                  }}
                />
              </div>
            </Col>
          )}
          {vatActive && (
            <Col xs={24} md={12}>
              <div style={{width: '100%', display: 'flex', flexDirection: 'row', gap: 20}}>
                <div>Vat register customer ? </div>
                <div
                  style={{
                    color: 'black',
                  }}>
                  <Checkbox
                    checked={vatRegiesterCustomer}
                    onChange={e => {
                      setVatRegiesterCustomer(e.target.checked);
                      if (!e.target.checked) customerVatRegisterForm.resetFields();
                    }}
                  />
                </div>
              </div>
            </Col>
          )}
          {vatActive && vatRegiesterCustomer && (
            <Col xs={24} md={24}>
              <div style={{width: '100%'}}>
                <CustomerVatRegister
                  form={customerVatRegisterForm}
                  getFormData={formData => {
                    completeOrder(vatRegiesterCustomer, formData);
                  }}
                />
              </div>
            </Col>
          )}
        </Row>
      </Modal>
    </div>
  );
};

export default React.memo(RightArea);
