import React, {useEffect, useState} from 'react';
import {BellOutlined} from '@ant-design/icons';
import {Button} from '@app/components/common/buttons/Button/Button';
import {Badge} from '@app/components/common/Badge/Badge';
import {NotificationsOverlay} from '@app/components/header/components/notificationsDropdown/NotificationsOverlay/NotificationsOverlay';
import {notifications as fetchedNotifications, Notification} from '@app/api/notifications.api';
import {HeaderActionWrapper} from '@app/components/header/Header.styles';
import {Popover} from '@app/components/common/Popover/Popover';
import {useAppSelector} from '@app/hooks/reduxHooks';

export const NotificationsDropdown: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isOpened, setOpened] = useState(false);
  const hotelNotifications = useAppSelector(state => state.notificationSlice.hotelNotifications);

  useEffect(() => {
    setNotifications(hotelNotifications);
  }, [hotelNotifications]);

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      content={
        <NotificationsOverlay
          notifications={notifications}
          setNotifications={setNotifications}
          onClose={() => {
            setOpened(false);
          }}
        />
      }
      // overlayStyle={{width: '30rem'}}
      open={isOpened}
      onOpenChange={setOpened}>
      <HeaderActionWrapper>
        <Button
          type={isOpened ? 'ghost' : 'text'}
          icon={
            notifications.length > 0 ? (
              <Badge count={notifications.length} overflowCount={10}>
                <BellOutlined style={{fontSize: '1.4rem'}} />
              </Badge>
            ) : (
              <BellOutlined style={{fontSize: '1.4rem'}} />
            )
          }
        />
      </HeaderActionWrapper>
    </Popover>
  );
};
