/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Modal} from '@app/components/common/Modal/Modal';
import React, {useEffect, useState} from 'react';
import {IRetiveModal, IServicesBookinsData} from '../interface';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from '../ServiceReservation.style';
import {Col, Collapse, Descriptions, Divider, Row, Space, Tag} from 'antd';
import styled from 'styled-components';
import {DatePicker} from '@app/components/common/pickers/DatePicker';
import {Input} from '@app/components/common/inputs/Input/Input';
import {convertNumberFormatWithDecimal, formatNumberToDecimal} from '@app/utils/utils';
import {Button} from '@app/components/common/buttons/Button/Button';
import {
  Response,
  getServiceBookingsByBookingId,
  updateAdditionalBooking,
} from '@app/api/hotel/additionalBookings/additionalBookings.api';
import {notificationController} from '@app/controllers/notificationController';
import {checkVATAvailability, validateEmail, validateEmailWithoutRequired} from '@app/utils/functions';
import {find, isEmpty} from 'lodash';
import {Popconfirm} from '@app/components/common/Popconfirm/Popconfirm';
import {MakePayment, MakePaymentProps} from './MakePayment';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {Checkbox} from '@app/components/common/Checkbox/Checkbox';
import {
  searchedExactVatUsersApi,
  searchedVatUsersApi,
  updateVatUserApiForAdditionalService,
} from '@app/api/hotel/guest/guest.api';
import {AutoComplete} from '@app/components/common/AutoComplete/AutoComplete';
import ServiceDescription from './ServiceDescription';
import {data as MOCKDATA, calculateTotalForExistingService} from '../helper/helperFunction';
import {ConvertedDataProps, initialConvertedData} from '../helper/interface';
import moment from 'moment';
import dayjs from 'dayjs';
import ApplyEventExtraHours from '../../AdditionalActivity/createBooking/ApplyEventExtraHours';
import ApplyEventDiscount from '../../AdditionalActivity/createBooking/ApplyEventDiscount';
import ServicePriceCalculator from '@app/components/common/ServicePriceCalculator/ServicePriceCalculator';
import ApplyServiceExtraHours from './ApplyServiceExtraHours';
import ApplyServiceDiscount from './ApplyServiceDicount';
import CheckoutService from '@app/components/common/CheckoutService/CheckoutService';

const CustomDatePicker = styled(DatePicker)`
  width: 100%;
`;

const {Panel} = Collapse;

export default function RetriveServiceModal({open, handleCancelModal, data, reloadData}: IRetiveModal) {
  // Local States Hooks

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVatDetailsAvailable, setisVatDetailsAvailable] = useState(false);
  const [searchedVatUsers, setsearchedVatUsers] = useState([]);
  const [isExistingVatUser, setisExistingVatUser] = useState(false);
  const [vatUserFormData, setvatUserFormData]: any = useState({
    vatPersonName: null,
    vatNumber: null,
    vatPersonEmail: null,
    vatPersonAddress: null,
  });
  const [isVatActive, setisVatActive] = useState(false);
  const [isResident, setisResident]: any = useState(true);
  const [isVatApplicable, setisVatApplicable] = useState(false);

  // @ts-ignore
  const [convertedData, setconvertedData] = useState<ConvertedDataProps>(initialConvertedData);

  const [form] = BaseForm.useForm();
  const [serviceForm] = BaseForm.useForm();
  const [vatForm] = BaseForm.useForm();
  const [paymentForm] = BaseForm.useForm();

  //   Global States hooks
  const {hotelId, groupId} = useAppSelector(state => state.hotelSlice.hotelConfig);

  //   Event handlers and functions

  const generatePayload = (
    id: number,
    checkOutDate: string,
    additionalServiceReservationRequestList: {id: number; additionalServiceId: number; hotelId: number}[],
  ) => ({
    id,
    checkInDate: convertedData.checkInDate,
    checkOutDate: dayjs(checkOutDate).format('YYYY-MM-DD'),
    checkOutTime: moment().format('hh:mm:ss'),
    additionalServiceReservationRequestList,
  });

  const handleApiResponse = async (response: Response) => {
    if (response.statusCode === '20000') {
      notificationController.success({message: response.message});
      resetVatDetails();
      reloadData();
    } else {
      notificationController.error({message: response.message});
    }
  };

  const handleSingleRetive = async (services: IServicesBookinsData) => {
    await serviceForm.validateFields([`${services.additionalServiceId}-checkOutDate`]);

    const formData = serviceForm.getFieldsValue();

    const payload = generatePayload(convertedData.id, formData[`${services.additionalServiceId}-checkOutDate`], [
      {
        id: services.id,
        additionalServiceId: services.additionalServiceId,
        hotelId: hotelId,
      },
    ]);
    try {
      const response = await updateAdditionalBooking(payload);
      getServiceBookingsById(data?.id);
      await handleApiResponse(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleBulkRetive = async (formData: any) => {
    const dataList = convertedData.additionalServiceSingleReservationResponseList.map(service => ({
      id: service.id,
      additionalServiceId: service.additionalServiceId,
      hotelId: hotelId,
    }));

    const payload = generatePayload(convertedData.id, formData?.checkOutDate, dataList);

    try {
      const response = await updateAdditionalBooking(payload);
      handleCancelModal();
      await handleApiResponse(response);
    } catch (error) {
      console.error(error);
    }
  };

  const isLastServiceToRetrive = () => {
    const isCheckedInReservation = convertedData?.additionalServiceForReservedRoomResponseList !== null;
    const bookedServices =
      convertedData &&
      convertedData?.additionalServiceSingleReservationResponseList?.filter(
        x => x.additionalServiceReservationStatus === 'BOOKED',
      );

    if (
      bookedServices &&
      bookedServices.length === 1 &&
      convertedData &&
      convertedData.outStanding > 0 &&
      !isCheckedInReservation
    ) {
      return true;
    } else {
      return false;
    }
  };

  const disabledDate = (current: {isBefore: (arg0: any) => any; isAfter: (arg0: any) => any}) => {
    const currentDate: any = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    return current.isBefore(currentDate);
  };

  const handlePopulateVatInformation = (vatDetails: any) => {
    const {name, address, vatNumber, email} = vatDetails;
    const fieldData = {
      vatPersonName: name,
      vatNumber: vatNumber,
      vatPersonEmail: email,
      vatPersonAddress: address,
    };
    vatForm.setFieldsValue(fieldData);
    setvatUserFormData(fieldData);
    setisExistingVatUser(true);
  };

  const resetVatDetails = () => {
    vatForm.resetFields(['vatPersonName', 'vatNumber', 'vatPersonEmail', 'vatPersonAddress']);
    setisVatDetailsAvailable(false);
    setsearchedVatUsers([]);
    setisExistingVatUser(false);
    setvatUserFormData({
      vatPersonName: null,
      vatNumber: null,
      vatPersonEmail: null,
      vatPersonAddress: null,
    });
    setisVatActive(false);
  };

  const handleClickVATDetails = (checked: boolean) => {
    setisVatDetailsAvailable(checked);
    if (convertedData?.vatRegistryResponse && convertedData?.vatRegistryResponse?.vatNumber !== null) {
      handlePopulateVatInformation(convertedData?.vatRegistryResponse);
    }
    if (!checked) {
      vatForm.resetFields(['vatPersonName', 'vatNumber', 'vatPersonEmail', 'vatPersonAddress']);
      setisVatDetailsAvailable(false);
      setsearchedVatUsers([]);
      setisExistingVatUser(false);
      setvatUserFormData({
        vatPersonName: null,
        vatNumber: null,
        vatPersonEmail: null,
        vatPersonAddress: null,
      });
    }
  };

  const handleUpdateVatRegistry = async () => {
    await vatForm.validateFields(['vatPersonName', 'vatNumber', 'vatPersonEmail', 'vatPersonAddress']);
    const formData = vatForm.getFieldsValue();

    const payload = {
      vatRegistryApplicable: true,
      reservationId: convertedData.id,
      email: formData?.vatPersonEmail,
      registryType: ['ADDITIONAL_SERVICE'],
      hotelId: hotelId,
      vatNumber: formData?.vatNumber,
      onlyThisHotelView: false,
      name: formData?.vatPersonName,
      groupId: groupId,
      address: formData?.vatPersonAddress,
    };

    try {
      const response = await updateVatUserApiForAdditionalService(payload);
      if (response.statusCode === '20000') {
        notificationController.success({message: response.message});
      } else {
        notificationController.error({message: response.message});
      }
    } catch (error) {}
  };

  const handleChangeVatInput = (changedFieldName: string) => {
    const fields = ['vatPersonName', 'vatNumber', 'vatPersonEmail', 'vatPersonAddress'];
    const fieldsForReset = fields.filter(o => o !== changedFieldName);

    console.log({changedFieldName});

    if (isExistingVatUser && vatUserFormData[changedFieldName] !== null) {
      vatForm.resetFields(fieldsForReset);
      setvatUserFormData({
        vatPersonName: null,
        vatNumber: null,
        vatPersonEmail: null,
        vatPersonAddress: null,
      });
      setisExistingVatUser(false);
    }
  };

  const handleBlurPopulate = async (value: string, searchField: string) => {
    let searchParams = {};
    if (searchField === 'vatPersonEmail') {
      searchParams = {email: value};
    } else if (searchField === 'vatNumber') {
      searchParams = {vatNumber: value};
    }
    const result = await searchedExactVatUsersApi(groupId, searchParams, 20, 0);
    const data = result?.result?.Guests?.map(
      (item: {id: number; vatNumber: number; address: string; name: string; email: string}) => ({
        id: item.id,
        vatNumber: item.vatNumber,
        address: item.address,
        name: item.name,
        email: item.email,
        value: item.id,
        label: searchField === 'vatPersonEmail' ? item.email : item.vatNumber,
      }),
    );
    if (!isEmpty(data)) handlePopulateVatInformation(data[0]);
  };

  const resetVatSearchedData = () => {
    setsearchedVatUsers([]);
  };

  const handleSearchVatUsers = async (value: string, searchField: string) => {
    let searchParams = {};
    if (searchField === 'vatPersonEmail') {
      searchParams = {email: value};
    } else if (searchField === 'vatNumber') {
      searchParams = {vatNumber: value};
    }

    const result = await searchedVatUsersApi(groupId, searchParams, 20, 0);

    const data = result?.result?.Guests?.map(
      (item: {id: number; vatNumber: number; address: string; name: string; email: string}) => ({
        id: item.id,
        vatNumber: item.vatNumber,
        address: item.address,
        name: item.name,
        email: item.email,
        value: item.id,
        label: searchField === 'vatPersonEmail' ? item.email : item.vatNumber,
      }),
    );

    setsearchedVatUsers(data);
  };

  //   Make payment modal
  const onModalCancel = () => {
    setIsModalVisible(false);
  };

  const openMakePaymentModal = () => {
    setIsModalVisible(true);
  };

  const onCloseModal = () => {
    paymentForm.resetFields();
    setIsModalVisible(false);
  };

  const populateInitialFormValues = () => {
    convertedData?.additionalServiceSingleReservationResponseList?.map(service => {
      const formData = {
        [`${service.additionalServiceId}-discountValue`]: service.discount,
        [`${service.additionalServiceId}-discountOption`]: service.discountType,
      };
      form.setFieldsValue(formData);
    });
  };

  const getServiceBookingsById = async (id: number) => {
    try {
      const response = await getServiceBookingsByBookingId(id);
      const serviceReservation = response.result.additionalServiceReservation;
      setconvertedData(serviceReservation);
      setisVatDetailsAvailable(serviceReservation?.vatRegistryResponse !== null);
      setisResident(serviceReservation.resident);
      const isVatAvailableForHotel = checkVATAvailability(
        serviceReservation?.additionalServiceSingleReservationResponseList[0]?.taxList,
      );
      setisVatActive(isVatAvailableForHotel);
      setisVatApplicable(true);
      const vatDetails = {
        vatPersonEmail: serviceReservation.vatRegistryResponse.email,
        vatNumber: serviceReservation.vatRegistryResponse.vatNumber,
        vatPersonName: serviceReservation.vatRegistryResponse.name,
        vatPersonAddress: serviceReservation.vatRegistryResponse.address,
      };

      vatForm.setFieldsValue(vatDetails);
      setvatUserFormData(vatDetails);
      if (serviceReservation.vatRegistryResponse.vatNumber !== null) setisExistingVatUser(true);
    } catch (error) {}
  };

  useEffect(() => {
    // setisVatDetailsAvailable(MOCKDATA?.vatRegistryResponse !== null);
    populateInitialFormValues();
    if (data) getServiceBookingsById(data?.id);
  }, [data]);

  return (
    <Modal footer={null} title="Service Retrieval" size="xl" open={open} onCancel={handleCancelModal}>
      <Row gutter={{xs: 10, md: 15, xl: 30}}>
        <Col xs={24} md={16} xl={16}>
          <S.FormContent>
            <ServiceDescription data={convertedData} />
            <Row gutter={{xs: 10, md: 15, xl: 30}}>
              <Col xs={24} md={24} xl={24}>
                <CheckoutService
                  handlePressRetrive={formData => handleBulkRetive(formData)}
                  disabledDate={disabledDate}
                  isLastServiceToRetrive={isLastServiceToRetrive()}
                />
              </Col>
            </Row>
            <BaseForm form={form} size="middle">
              <Row gutter={{xs: 10, md: 15, xl: 30}}>
                {convertedData?.additionalServiceSingleReservationResponseList
                  ?.filter(x => x.additionalServiceReservationStatus === 'BOOKED')
                  ?.map((service: IServicesBookinsData) => {
                    return (
                      <Col key={`${service.additionalServiceId}-services`} xs={24} md={24} xl={12}>
                        <BaseForm form={serviceForm} size="middle">
                          <S.BlurCardWrapper>
                            <S.WhiteCard>
                              <S.Padding>
                                <Row justify="space-between">
                                  <S.ServiceTitle>{service.service}</S.ServiceTitle>
                                  <S.ServiceTitle>
                                    {convertedData.resident ? 'LKR' : 'USD'}{' '}
                                    {convertNumberFormatWithDecimal(service.totalPrice, 2)}
                                  </S.ServiceTitle>
                                </Row>
                                <Row>
                                  <S.ServiceCharge>
                                    Service Charge Include {service.taxInclude ? '✅' : '❌'}
                                  </S.ServiceCharge>
                                </Row>
                                <Row gutter={{xs: 10, md: 15, xl: 30}}>
                                  <Col xs={24} md={12} xl={24}>
                                    <BaseForm.Item
                                      name={`${service.additionalServiceId}-checkOutDate`}
                                      label="Retrieve Date"
                                      rules={[{required: true, message: 'Date is required'}]}>
                                      <CustomDatePicker
                                      // disabledDate={disabledDate}
                                      />
                                    </BaseForm.Item>
                                  </Col>
                                </Row>

                                <Row justify="end" gutter={{xs: 10, md: 15, xl: 30}}>
                                  <Col>
                                    <div>
                                      <ApplyServiceExtraHours
                                        serviceData={service}
                                        reservationData={convertedData}
                                        reloadModalData={() => getServiceBookingsById(data?.id)}
                                        isDisable={false}
                                      />
                                    </div>
                                  </Col>
                                  <Col>
                                    <div>
                                      <ApplyServiceDiscount
                                        serviceData={service}
                                        reservationData={convertedData}
                                        reloadModalData={() => getServiceBookingsById(data?.id)}
                                        isDisable={convertedData?.outStanding <= 0}
                                      />
                                    </div>
                                  </Col>
                                  <Col>
                                    <Popconfirm
                                      placement="topRight"
                                      title="Are you sure to retrive?"
                                      onConfirm={() => handleSingleRetive(service)}
                                      okText="Yes"
                                      cancelText="No">
                                      <Button disabled={isLastServiceToRetrive()} type="primary" danger>
                                        Retrive
                                      </Button>
                                    </Popconfirm>
                                  </Col>
                                </Row>
                              </S.Padding>
                            </S.WhiteCard>
                          </S.BlurCardWrapper>
                        </BaseForm>
                      </Col>
                    );
                  })}
              </Row>
            </BaseForm>

            <Divider />

            <Collapse>
              <Panel header="Retrieved Services here" key="1">
                <Row style={{marginBottom: '1rem'}} gutter={{xs: 10, md: 15, xl: 30}}>
                  {convertedData?.additionalServiceSingleReservationResponseList
                    ?.filter(x => x.additionalServiceReservationStatus === 'RETRIEVED')
                    ?.map((service: IServicesBookinsData) => {
                      return (
                        <Col key={`${service.id}-retrived_services`} xs={24} md={24} xl={12}>
                          <S.BlurCardWrapper>
                            <S.WhiteCard>
                              <S.Padding>
                                <Descriptions title={`${service.service}`} size="small" column={1}>
                                  <Descriptions.Item label="Status">
                                    <Tag
                                      color={service.additionalServiceReservationStatus === 'BOOKED' ? 'green' : 'red'}>
                                      {service.additionalServiceReservationStatus}
                                    </Tag>
                                  </Descriptions.Item>
                                  {service.hallId === null ? (
                                    <Descriptions.Item label="Count">{service.count}</Descriptions.Item>
                                  ) : null}
                                  <Descriptions.Item label="Event time">{service.hallTime}</Descriptions.Item>

                                  {service.hallTime === 'PER_HOUR' && service.noOfHours > 0 && (
                                    <Descriptions.Item label="Hours">{service.noOfHours}</Descriptions.Item>
                                  )}
                                  {service.hallTime === 'FULL_DAY' && service.noOfDays > 0 && (
                                    <Descriptions.Item label="No. of Days">{service.noOfDays}</Descriptions.Item>
                                  )}
                                  {service.extraHour > 0 && (
                                    <Descriptions.Item label="Extra hours">{service.extraHour}</Descriptions.Item>
                                  )}
                                  {service.discount > 0 && (
                                    <>
                                      <Descriptions.Item label="Discount Type">
                                        {service.discountType}
                                      </Descriptions.Item>
                                      {service.discountType === 'AMOUNT' ? (
                                        <Descriptions.Item label="Discount">{`${
                                          convertedData.resident ? 'LKR' : 'USD'
                                        } ${service.discount}`}</Descriptions.Item>
                                      ) : (
                                        <Descriptions.Item label="Discount">{`${service.discount}%`}</Descriptions.Item>
                                      )}
                                    </>
                                  )}
                                  <Descriptions.Item label="Amount">
                                    {`${convertedData.resident ? 'LKR' : 'USD'} ${convertNumberFormatWithDecimal(
                                      service.totalCharge,
                                      2,
                                    )} (Inc.taxes)`}
                                  </Descriptions.Item>
                                </Descriptions>
                              </S.Padding>
                            </S.WhiteCard>
                          </S.BlurCardWrapper>
                        </Col>
                      );
                    })}
                </Row>
              </Panel>
            </Collapse>

            <S.EmptyFooterSpace />
          </S.FormContent>
        </Col>
        <Col xs={24} md={24} xl={8}>
          <Row gutter={{xs: 10, md: 15, xl: 30}}>
            <Col xs={24} md={24} xl={24}>
              <ServicePriceCalculator
                currency={isResident ? 'LKR' : 'USD'}
                isVatApplicable={isVatApplicable}
                handlePayNow={openMakePaymentModal}
                isRetiveAction={true}
                serviceData={convertedData}
                isCheckedInReservation={convertedData?.additionalServiceForReservedRoomResponseList !== null}
              />
            </Col>
          </Row>

          {isVatActive && convertedData?.additionalServiceForReservedRoomResponseList === null ? (
            <BaseForm key={`vat-details`} form={vatForm} size="middle">
              <Row gutter={{xs: 10, md: 15, xl: 30}}>
                <Col xs={24} md={24} xl={24}>
                  <S.BlurCardWrapper>
                    <S.BlueCard>
                      <S.Padding>
                        <S.CardTitle>VAT Details</S.CardTitle>
                        <Row style={{gap: 10}} justify="start">
                          <BaseForm.Item label="">
                            <Checkbox
                              value={isVatDetailsAvailable}
                              checked={isVatDetailsAvailable}
                              onChange={e => handleClickVATDetails(e.target.checked)}>
                              <S.CheckboxLabel>Add VAT Details</S.CheckboxLabel>
                            </Checkbox>
                          </BaseForm.Item>
                        </Row>
                        {isVatDetailsAvailable ? (
                          <>
                            <Row gutter={{xs: 10, md: 15, xl: 30}}>
                              <Col xs={24} md={24}>
                                <BaseForm.Item
                                  name="vatPersonEmail"
                                  label="Email"
                                  rules={[
                                    {
                                      required: isVatDetailsAvailable,
                                      validator: isVatDetailsAvailable ? validateEmail : validateEmailWithoutRequired,
                                    },
                                  ]}>
                                  <AutoComplete
                                    disabled={!isVatDetailsAvailable}
                                    onBlur={(event: any) => {
                                      const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(event.target.value);
                                      if (event.target.value.trim() !== '' && isValidEmail) {
                                        handleBlurPopulate(event.target.value, 'vatPersonEmail');
                                      }
                                      resetVatSearchedData();
                                    }}
                                    options={searchedVatUsers}
                                    onSearch={value => handleSearchVatUsers(value, 'vatPersonEmail')}
                                    onSelect={value => {
                                      const selectedGuest = find(
                                        searchedVatUsers,
                                        (o: {value: number}) => o.value === value,
                                      );

                                      if (selectedGuest !== undefined) handlePopulateVatInformation(selectedGuest);
                                    }}>
                                    <Input
                                      disabled={!isVatDetailsAvailable}
                                      onChange={() => handleChangeVatInput('vatPersonEmail')}
                                    />
                                  </AutoComplete>
                                </BaseForm.Item>
                              </Col>
                              <Col xs={24} md={24}>
                                <BaseForm.Item
                                  name="vatNumber"
                                  label="VAT Number"
                                  rules={[
                                    {
                                      required: isVatDetailsAvailable,
                                      message: 'Required field',
                                    },
                                  ]}>
                                  <AutoComplete
                                    disabled={!isVatDetailsAvailable}
                                    onBlur={(event: any) => {
                                      if (event.target.value.trim() !== '') {
                                        handleBlurPopulate(event.target.value, 'vatNumber');
                                      }
                                      resetVatSearchedData();
                                    }}
                                    options={searchedVatUsers}
                                    onSearch={value => handleSearchVatUsers(value, 'vatNumber')}
                                    onSelect={value => {
                                      console.log(value);

                                      const selectedGuest = find(
                                        searchedVatUsers,
                                        (o: {value: number}) => o.value === value,
                                      );
                                      console.log({selectedGuest});

                                      if (selectedGuest !== undefined) handlePopulateVatInformation(selectedGuest);
                                    }}>
                                    <Input
                                      disabled={!isVatDetailsAvailable}
                                      onChange={() => handleChangeVatInput('vatNumber')}
                                    />
                                  </AutoComplete>
                                </BaseForm.Item>
                              </Col>
                              <Col xs={24} md={24}>
                                <BaseForm.Item
                                  name="vatPersonName"
                                  label="VAT Person name"
                                  rules={[
                                    {
                                      required: isVatDetailsAvailable,
                                      message: 'Required field',
                                    },
                                  ]}>
                                  <Input
                                    disabled={!isVatDetailsAvailable}
                                    onChange={() => handleChangeVatInput('vatPersonName')}
                                  />
                                </BaseForm.Item>
                              </Col>
                              <Col xs={24} md={24}>
                                <BaseForm.Item
                                  name="vatPersonAddress"
                                  label="VAT Person Address"
                                  rules={[
                                    {
                                      required: isVatDetailsAvailable,
                                      message: 'Required field',
                                    },
                                  ]}>
                                  <Input
                                    disabled={!isVatDetailsAvailable}
                                    onChange={() => handleChangeVatInput('vatPersonAddress')}
                                  />
                                </BaseForm.Item>
                              </Col>
                            </Row>
                            <Popconfirm
                              placement="topRight"
                              title="Are you sure to update the vat details?"
                              onConfirm={handleUpdateVatRegistry}
                              okText="Yes"
                              cancelText="No">
                              <Button disabled={!isVatDetailsAvailable} type="primary">
                                Update VAT
                              </Button>
                            </Popconfirm>
                          </>
                        ) : null}
                      </S.Padding>
                    </S.BlueCard>
                  </S.BlurCardWrapper>
                </Col>
              </Row>
            </BaseForm>
          ) : null}
        </Col>
      </Row>

      <Modal
        footer={[
          <Space align="end" key="submit-area">
            <Popconfirm
              placement="rightTop"
              title={`Are you sure to pay the total amount of ${
                convertedData?.resident ? 'LKR' : 'USD'
              } ${formatNumberToDecimal(convertedData.outStanding ? convertedData.outStanding : 0, 2)}?`}
              onConfirm={() => {
                paymentForm.submit();
              }}
              okText="Yes"
              cancelText="No">
              <Button
                disabled={!(convertedData.outStanding > 0) && convertedData?.reservationRefNumber !== null}
                key="submit"
                type="primary">
                Pay
              </Button>
            </Popconfirm>
          </Space>,
        ]}
        title="Make Payment"
        size="small"
        open={isModalVisible}
        onCancel={onModalCancel}>
        <MakePayment
          isModalVisible={isModalVisible}
          readOnly={false}
          onCancel={onModalCancel}
          form={paymentForm}
          reservationData={convertedData}
          outStandingAmount={convertedData.outStanding}
          reloadData={() => getServiceBookingsById(data?.id)}
        />
      </Modal>
    </Modal>
  );
}
