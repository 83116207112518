import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {notificationController} from '@app/controllers/notificationController';
import * as S from './ChangePasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import {ChangePasswordProps, changePassword} from '@app/api/user/user.api';
import {useAppDispatch} from '@app/hooks/reduxHooks';
import {doLogout} from '@app/store/slices/authSlice';
import {Popconfirm} from 'antd';

export interface ChangePasswordFormData {
  oldPassword: string;
  newPassword: string;
}

const initStates = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export const ChangePasswordForm: React.FC = () => {
  const {t} = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const changeUserPassword = async (payload: ChangePasswordProps) => {
    try {
      const request: ChangePasswordProps = {
        oldPassword: payload.oldPassword,
        newPassword: payload.newPassword,
      };
      const result = await changePassword(request);
      setLoading(false);
      if (result.statusCode === '20000') {
        notificationController.success({message: result.message});
        // await onCloseModal();
        dispatch(doLogout());
      } else {
        notificationController.error({message: result.message});
        // dispatch(setLoading(false));
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handleSubmit = (values: ChangePasswordFormData) => {
    changeUserPassword(values);
  };

  const confirm = () => {
    form.submit();
  };

  const cancel = () => {
    console.log('cancel');
  };

  return (
    <Auth.FormWrapper>
      <BaseForm
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
        initialValues={initStates}>
        <Auth.FormTitle>Change Your Password</Auth.FormTitle>
        <Auth.FormItem
          name="oldPassword"
          label="Current Password"
          rules={[{required: true, message: t('common.requiredField')}]}>
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="newPassword"
          label="New Password"
          rules={[{required: true, message: t('common.requiredField')}]}>
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="confirmPassword"
          label="Confirm Password"
          dependencies={['password']}
          rules={[
            {required: true, message: t('common.requiredField')},
            ({getFieldValue}) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('common.confirmPasswordError')));
              },
            }),
          ]}
          hasFeedback>
          <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <Popconfirm
            title="System will Restart once you changed your password.. Are you sure to changed your Password?"
            onConfirm={confirm}
            onCancel={cancel}
            okText="Yes"
            cancelText="No">
            <S.SubmitButton type="primary" loading={isLoading}>
              Change Password
            </S.SubmitButton>
          </Popconfirm>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
