/* eslint-disable @typescript-eslint/no-explicit-any */
import instance, {HOTEL_SERVICE} from '@app/api/instance';

export const createGuest = (payload: CreateGuestProps): Promise<GuestResponse> => {
  const guestFormData = new FormData();
  guestFormData.append('profile', JSON.stringify(payload.profile));
  guestFormData.append('passportImage', payload.passportImage);
  return instance.post<GuestResponse>(HOTEL_SERVICE + 'guest', guestFormData).then(({data}) => data);
};

export const getAllGuests = (
  hotelId: number,
  {idNumber, firstName, lastName, nationality, email}: FilterProps,
  pageSize: number | undefined,
  current: number,
  status: string[],
): Promise<GuestResponse> => {
  const joinedStatuses = status.join(',');
  return instance
    .get<GuestResponse>(
      HOTEL_SERVICE +
        `guest/search?page=${current}&size=${pageSize}&sortField=firstName&direction=ASC&idNumber=${
          idNumber ? idNumber : ''
        }&firstName=${firstName ? firstName : ''}&lastName=${lastName ? lastName : ''}&nationality=${
          nationality ? nationality : ''
        }&phoneNumber&address&city&email=${email ? email : ''}&guestType=${joinedStatuses}`,
    )
    .then(({data}) => data);
};

export const updateGuest = (payload: UpdateGuestProps | IReservationUpdateGuest): Promise<GuestResponse> => {
  const guestFormData = new FormData();
  guestFormData.append('profile', JSON.stringify(payload.profile));
  guestFormData.append('passportImage', payload.passportImage);
  return instance.put<GuestResponse>(HOTEL_SERVICE + 'guest', guestFormData).then(({data}) => data);
};

export const updateInquiryGuest = (payload: UpdateGuestProps | IReservationUpdateGuest): Promise<GuestResponse> => {
  const guestFormData = new FormData();
  guestFormData.append('profile', JSON.stringify(payload.profile));
  guestFormData.append('passportImage', payload.passportImage);
  return instance
    .put<GuestResponse>(HOTEL_SERVICE + 'reservation/inquiry/update', guestFormData)
    .then(({data}) => data);
};

export const updateIDImage = (payload: any): Promise<GuestResponse> => {
  const guestFormData = new FormData();
  guestFormData.append('guestEmail', payload.email);
  guestFormData.append('passportImage', payload.passportImage);
  return instance.put<GuestResponse>(HOTEL_SERVICE + 'guest-passport-update', guestFormData).then(({data}) => data);
};

export const reservationguestUpdate = (guest: any): Promise<GuestResponse> => {
  const guestFormData = new FormData();
  guestFormData.append('guestDetails', JSON.stringify(guest));
  // guestFormData.append('guestEmail', JSON.stringify(emails));
  return instance.put<GuestResponse>(HOTEL_SERVICE + 'guest-update', guestFormData).then(({data}) => data);
};

export const deleteGuest = (id: number): Promise<GuestResponse> =>
  instance.delete<GuestResponse>(HOTEL_SERVICE + `guest/${id}`).then(({data}) => data);

export const getGuestById = (id: number): Promise<GuestResponse> =>
  instance.get<GuestResponse>(HOTEL_SERVICE + `guest/${id}`).then(({data}) => data);

export const exportGuestData = (): Promise<GuestResponse> =>
  instance
    .get<GuestResponse>(
      HOTEL_SERVICE +
        `guest/download?page=0&size=10&sortField=id&direction=DESC&idNumber=&firstName=&lastName=&nationality=&phoneNumber&address&city&email&guestType`,
      {
        responseType: 'blob',
        headers: {
          ContentType: 'application/vnd.ms-excel',
        },
      },
    )
    .then(({data}) => data);

export const searchedVatUsersApi = (
  groupId: number,
  {email, vatNumber}: any,
  pageSize: number | undefined,
  current: number,
): Promise<GuestResponse> => {
  return instance
    .get<GuestResponse>(
      HOTEL_SERVICE +
        `vat-registry/search?email=${email ? email : ''}&vatNumber=${
          vatNumber ? vatNumber : ''
        }&page=${current}&size=${pageSize}&direction=DESC&sortField=id&groupId=${groupId}`,
    )
    .then(({data}) => data);
};

export const searchedExactVatUsersApi = (
  groupId: number,
  {email, vatNumber}: any,
  pageSize: number | undefined,
  current: number,
): Promise<GuestResponse> => {
  return instance
    .get<GuestResponse>(
      HOTEL_SERVICE +
        `vat-registry/search/exact?email=${email ? email : ''}&vatNumber=${
          vatNumber ? vatNumber : ''
        }&page=${current}&size=${pageSize}&direction=DESC&sortField=id&groupId=${groupId}`,
    )
    .then(({data}) => data);
};

export const updateVatUserApi = (payload: any): Promise<any> => {
  return instance.put<GuestResponse>(HOTEL_SERVICE + 'reservation-vag-registry', payload).then(({data}) => data);
};

export const updateVatUserApiForAdditionalService = (payload: any): Promise<any> => {
  return instance.put<GuestResponse>(HOTEL_SERVICE + 'additional-service-vat-registry', payload).then(({data}) => data);
};
export const updateVatUserApiForAdditionalActivity = (payload: any): Promise<any> => {
  return instance
    .put<GuestResponse>(HOTEL_SERVICE + 'additional-activity-vat-registry', payload)
    .then(({data}) => data);
};

export interface CreateGuestProps {
  profile: {
    idNumber: string;
    nicNumber: boolean;
    firstName: string;
    lastName: string;
    nationality: string;
    dateOfBirth: string;
    phoneNumber: string;
    secondPhoneNumber?: string;
    address: string;
    secondAddress: string;
    city: string;
    countryId: number;
    email: string;
    guestType: string;
    comments: string;
    groupId: number;
    onlyThisHotelView: boolean;
    hotelId: number;
  };
  image: string;
  passportImage: any;
}
export interface UpdateGuestProps {
  profile: {
    id: number;
    idNumber: string;
    nicNumber: boolean;
    firstName: string;
    lastName: string;
    nationality: string;
    dateOfBirth: string;
    phoneNumber: string;
    secondPhoneNumber?: string;
    address: string;
    secondAddress: string;
    city: string;
    countryId: number;
    email: string;
    guestType: string;
    comments: string;
    lastVisit: string;
    hotelId: number;
    onlyThisHotelView: boolean;
  };
  passportImage: any;
}
export interface IReservationUpdateGuest {
  profile: {
    id: number;
    idNumber: string;
    nicNumber: boolean;
    firstName: string;
    lastName: string;
    address: string;
    countryId: number;
    email: string;
  };
  passportImage: any;
}
export interface GuestResponse {
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
  status: string;
  statusCode: string;
  pagination: {
    pageNumber: number;
    totalRecords: number;
  };
}

export interface FilterProps {
  idNumber?: string;
  firstName?: string;
  lastName?: string;
  nationality?: string;
  email?: string;
}
