import styled from 'styled-components';
import {Layout} from 'antd';
import {media} from '@app/styles/themes/constants';

interface filterCardProps {
  background?: string;
}

export const LayoutMaster = styled(Layout)`
  height: 100vh;
`;

export const LayoutMain = styled(Layout)`
  @media only screen and ${media.md} {
    margin-left: 4rem;
  }

  @media only screen and ${media.xl} {
    margin-left: unset;
  }
`;

export const BreadcrumbMenuWrap = styled('div')`
  cursor: pointer;
  margin: 0rem 0rem;
  display: flex;
  justify-content: space-between;
`;

export const CategoryFilterCards = styled('button')`
  -webkit-box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.41);
  box-shadow: 1.5px 5px 0px 0px rgb(133 135 159);
  border: none;
  font-weight: 500;
  -moz-box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.36);
  border-radius: 13px;
  margin: 0px 7px;
  color: white !important;
  font-size: 12px;
  padding: 7px 10px;
  min-width: 82px;
  text-align: center;
  color: white;
  background: ${(props: filterCardProps) => (props.background ? props.background : '#585858')};
  -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.75);
  cursor: pointer;

  &:active {
    transform: scale(0.95);
    transition: all 200ms ease-in;
  }
`;

export const CategoryFilterCardWrap = styled('div')`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: row;
  margin-bottom: 5px;
  gap: 10px;
`;
export const CategoryFilterArea = styled('div')`
  display: flex;
  justify-content: center;
`;
