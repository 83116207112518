import {DownOutlined} from '@ant-design/icons';
import {media} from '@app/styles/themes/constants';
import styled from 'styled-components';
import {HeaderActionWrapper} from '../../../Header.styles';
import {H1} from '@app/components/common/typography/H1/H1';
import {H6} from '@app/components/common/typography/H6/H6';

export const ProfileDropdownHeader = styled(HeaderActionWrapper)`
  cursor: pointer;

  @media only screen and ${media.md} {
    border-radius: 50px;
    padding: 0.3125rem 0.2rem;
  }
`;

export const ProfileTitle = styled(H6)`
  font-size: 0.8rem;
`;

export const DownArrow = styled(DownOutlined)`
  color: var(--text-secondary-color);

  @media only screen and ${media.md} {
    color: var(--text-main-color);
  }
`;
