import React, {useState} from 'react';
import {HomeOutlined} from '@ant-design/icons';
import {Button} from '@app/components/common/buttons/Button/Button';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {
  HOTEL_SERVICE_MODULE_NAV_PATH,
  HOUSEKEEPING_ADMIN_MODULE_NAME,
  HOUSEKEEPING_ADMIN_MODULE_NAV_PATH,
  RESTAURANT_ADMIN_MODULE_NAME,
  RESTAURANT_MASTER_MODULE_NAV_PATH,
  SERVICE_ADMIN_MODULE_NAV_PATH,
  getActiveNavPath,
  modulePermission,
} from '@app/utils/permissions';
import {getCategoryStuctureByID} from '@app/api/resturant/tablecategory/categoryStucture.api';
import {resetMenuItem, setCategoryData} from '@app/pages/Restaurant/slices/categoryNavSlice';

export const SettingsDropdown: React.FC = () => {
  const [isOpened, setOpened] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userConfig = useAppSelector(state => state.user.userPermission);
  const hotelConfig = useAppSelector(state => state.hotelSlice.hotelConfig);
  const hotelServiceConfig = useAppSelector(state => state.hotelSlice.hotelServiceConfig);
  const userPermission = useAppSelector(state => state.user.permissions);
  const permissionsStuart = modulePermission(userPermission, RESTAURANT_ADMIN_MODULE_NAME.FOOD_MENU);
  const permissionsChef = modulePermission(userPermission, RESTAURANT_ADMIN_MODULE_NAME.CHEF_DASHBOARD);
  const isStuart = permissionsStuart.VIEW;
  const isChef = permissionsChef.VIEW && !isStuart;

  const permissionsHouseKeeper = modulePermission(userPermission, HOUSEKEEPING_ADMIN_MODULE_NAME.HOUSEKEEPING);
  const isHouseKeeper = permissionsHouseKeeper.VIEW;

  const activeResNavPath: string = getActiveNavPath(userPermission, {
    ...RESTAURANT_MASTER_MODULE_NAV_PATH,
    ...SERVICE_ADMIN_MODULE_NAV_PATH,
  });
  const activeHotelNavPath: string = getActiveNavPath(userPermission, {
    ...HOTEL_SERVICE_MODULE_NAV_PATH,
    ...SERVICE_ADMIN_MODULE_NAV_PATH,
  });

  const activeHouseKeepingNavPath: string = getActiveNavPath(userPermission, {
    ...HOUSEKEEPING_ADMIN_MODULE_NAV_PATH,
    ...SERVICE_ADMIN_MODULE_NAV_PATH,
  });

  const getCategoryById = async (id: number) => {
    const result = await getCategoryStuctureByID(id, hotelServiceConfig.serviceId);

    const data: any[] = [];

    result.result.category &&
      result.result.category.map((post: any, i: any) => {
        data.push({
          id: post.id,
          backgroundImage: post.categoryImage,
          title: post.categoryTitle,
          children: [],
          expanded: post.expanded,
          categoryId: post.categoryId,
        });
      });

    Promise.all([dispatch(resetMenuItem({})), dispatch(setCategoryData({items: data})), navigate('/category/main')]);
  };
  return (
    <Button
      onClick={() => {
        if (userConfig?.userLevel === 'HOTEL') {
          navigate('/toplevel/products');
        } else if (userConfig?.userLevel === 'SERVICE') {
          navigate('/toplevel/services');
        } else if (userConfig?.userLevel === 'USER') {
          if (hotelServiceConfig.serviceType === 'HOTEL') {
            navigate(activeHotelNavPath);
          } else if (hotelServiceConfig.serviceType === 'RESTAURANT') {
            if (isStuart) {
              getCategoryById(0);
            } else if (isChef) {
              navigate('/toplevel/chef-dashboard');
            } else {
              navigate(activeResNavPath);
            }
          } else if (hotelServiceConfig.serviceType === 'HOUSEKEEPING') {
            navigate(isHouseKeeper ? '/toplevel/housekeepercard' : activeHouseKeepingNavPath);
          } else if (hotelServiceConfig.serviceType === 'INVENTORY') {
            navigate('/toplevel/services');
          }
        } else if (hotelServiceConfig.serviceType === 'HOUSEKEEPING') {
          navigate(isHouseKeeper ? '/toplevel/housekeepercard' : activeHouseKeepingNavPath);
        } else {
          hotelConfig.groupId === 0 ? navigate('/toplevel/groups') : navigate('/toplevel/products');
        }
      }}
      type={isOpened ? 'ghost' : 'text'}
      icon={<HomeOutlined style={{fontSize: '1.3rem', marginTop: '0.2rem'}} />}
    />
  );
};
