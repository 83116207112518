import instance, {HOTEL_SERVICE} from '@app/api/instance';

export const CreateChildPolicy = (payload: CreateChildPolicyProps[]): Promise<ChildPolicyResponse> => {
  return instance.post<ChildPolicyResponse>(HOTEL_SERVICE + 'child-policy', payload).then(({data}) => data);
};

export const UpdateChildPolicy = (payload: UpdateChildPolicyProps[]): Promise<ChildPolicyResponse> => {
  return instance.put<ChildPolicyResponse>(HOTEL_SERVICE + 'child-policy', payload).then(({data}) => data);
};

export const getAllChildPolicies = (): Promise<ChildPolicyResponse> =>
  instance.get<ChildPolicyResponse>(HOTEL_SERVICE + 'child-policy').then(({data}) => data);

export const DeleteChildPolicy = (id: number): Promise<ChildPolicyResponse> =>
  instance.delete<ChildPolicyResponse>(HOTEL_SERVICE + `child-policy/${id}`).then(({data}) => data);

export interface CreateChildPolicyProps {
  childPolicyTypes: string;
  minAge: number;
  maxAge: number;
  adult: boolean;
  paid: boolean;
}

export interface UpdateChildPolicyProps {
  id: number;
  childPolicyTypes: string;
  minAge: number;
  maxAge: number;
  adult: boolean;
  paid: boolean;
}

export interface ChildPolicyResponse {
  message: string;
  result: any;
  status: string;
  statusCode: string;
}
