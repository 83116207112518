import React from 'react';
import {DesktopHeader} from './layouts/DesktopHeader';
import {MobileHeader} from './layouts/MobileHeader';
import {useResponsive} from '@app/hooks/useResponsive';
import {TopLevelDesktopHeader} from './layouts/TopLevelDesktopHeader';
import {TopLevelMobile} from './layouts/TobLevelMobile';

interface HeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
  isTwoColumnsLayout: boolean;
}

export const ProductLevelHeader: React.FC<HeaderProps> = React.memo(
  ({toggleSider, isSiderOpened, isTwoColumnsLayout}) => {
    const {isTablet} = useResponsive();

    return isTablet ? (
      <TopLevelDesktopHeader isTwoColumnsLayout={isTwoColumnsLayout} />
    ) : (
      <TopLevelMobile toggleSider={toggleSider} isSiderOpened={isSiderOpened} />
    );
  },
);
