import React, {useEffect, useState} from 'react';
import * as S from './StockRequest.style';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {Option, Select} from '@app/components/common/selects/Select/Select';
import {Col, Row} from 'antd';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {Button} from '@app/components/common/buttons/Button/Button';
import {DeleteOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {setIsEditAction, setLoading, setModalVisible} from '@app/store/slices/commonSlice';
import {TablePaginationConfig} from 'antd/es/table';
import {notificationController} from '@app/controllers/notificationController';
import moment from 'moment';
import {CreateLaundryItem, getAllLaundryItem} from '@app/api/houseKeeping/StockRequest/stockRequest.api';
import {getAllTrolly} from '../trolly.api';
import {AwesomeButton, AwesomeSelectedPrimaryButton} from '@app/components/common/Button3D/Button3D';
import {NAVIGATION} from '../navigationPaths/paths';
import {getRoomReservationOverallStatus} from '@app/api/houseKeeping/cleaning/cleaning';
import ContrastAnimation from '@app/components/common/ContrastAnimation/ContrastAnimation';
import {useNavigate} from 'react-router-dom';

export interface ITableData {
  id: number;
  itemId: number;
  itemName: string;
  quantity: number;
  qty: number;
  key: number;
}

export default function StockRequest() {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = BaseForm.useForm();
  const hotelConfig = useAppSelector(state => state.hotelSlice.hotelConfig);
  const {inventoryServiceId} = useAppSelector(state => state.serviceSlice.serviceConfig);
  const [pagination, setPagination] = React.useState<TablePaginationConfig>({current: 0, pageSize: 10, total: 0});
  const [grnForm] = BaseForm.useForm();
  const [item, setItem] = useState([]);
  const [tableCategories, setTableCategories] = useState<ITableData[]>();
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [rowData, setRowData] = React.useState<ITableData[]>([]);
  const [trolleyAllocation, setTrollyAllocation] = React.useState([]);
  const isEditAction = useAppSelector(state => state.commonSlice.isEditAction);
  const {hotelId} = useAppSelector(state => state.hotelSlice.hotelConfig);
  const navigate = useNavigate();

  const [status, setstatus] = useState({
    checkOutArgentRoomStatus: false,
    checkInOnDemandRoomStatus: false,
  });

  const getOverallStatus = async () => {
    try {
      const response = await getRoomReservationOverallStatus(hotelId);
      setstatus({
        checkOutArgentRoomStatus: response.result.rooms.checkOutArgentRoomStatus,
        checkInOnDemandRoomStatus: response.result.rooms.checkInOnDemandRoomStatus,
      });
    } catch (error) {}
  };
  useEffect(() => {
    getOverallStatus();
  }, []);

  useEffect(() => {
    getallTrollyAllocatonTypes();
    getAllStockAllocation();
  }, [isEditAction]);
  const getallTrollyAllocatonTypes = async () => {
    try {
      const result = await getAllTrolly(hotelId);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataList: any = [];
      result?.result?.trolley.map((type: {trolleyNo: string; id: number}) => {
        dataList.push({
          title: type.trolleyNo,
          value: type.id,
        });
      });
      setTrollyAllocation(dataList);
    } catch (error) {}
  };

  const create = async () => {
    try {
      const laundryItems: any[] = [];
      rowData.map((post: any, i) => {
        laundryItems.push({
          quantity: post.qty,
          itemRequestStatus: 'REQUEST',
          requestDate: moment().format('YYYY-MM-DD'),
          acceptDate: moment().format('YYYY-MM-DD'),
          itemId: post.value,
          itemName: post.title,
        });
      });

      const request: any = {
        trolleyRequestItemsDtoList: laundryItems,
        trolleyId: selectedItem,
      };

      const result = await CreateLaundryItem(request);
      if (result.statusCode === '20000') {
        notificationController.success({message: result.message});
        setRowData([]);
        form.resetFields();
        getAllStockAllocation();
        setSelectedItem(null);
      } else {
        notificationController.error({message: result.message});
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const handleItemSelect = (value: number[]) => {
    // setSelectedItems(value);
    const filterByIds: any = value;
    const filteredTableData = item.filter((item: any) => filterByIds.indexOf(item.value) !== -1);
    const hiddenOptions = item.length > 3 ? item.slice(0, 3) : [];
    setRowData(filteredTableData);
  };

  const handletrollySelect = (value: number) => {
    setSelectedItem(value);
  };

  const getAllStockAllocation = async () => {
    try {
      const result = await getAllLaundryItem(
        hotelId,
        hotelId === 1
          ? 3
          : hotelId === 2
          ? 7
          : hotelId === 3
          ? 11
          : hotelId === 4
          ? 15
          : hotelId === 5
          ? 19
          : inventoryServiceId,
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataLists: any = [];
      result?.result?.openingStock.map((type: {itemName: string; itemId: number; quantity: number}) => {
        dataLists.push({
          title: type.itemName,
          value: type.itemId,
          quantity: type.quantity,
          key: type.itemId,
          qty: 1,
        });
      });
      setItem(dataLists);
    } catch (error) {}
  };

  const handleIncrease = (index: number) => {
    const newData = [...rowData];
    newData[index].qty = newData[index].qty < newData[index].quantity ? newData[index].qty + 1 : newData[index].qty;
    setRowData(newData);
  };

  const handleDecrease = (index: number) => {
    const newData = [...rowData];
    newData[index].qty = newData[index].qty > 1 ? newData[index].qty - 1 : newData[index].qty;
    setRowData(newData);
  };

  return (
    <S.Container>
      <S.TopBreadcrumb>
        <S.LeftBreadcrumb>
          <AwesomeButton
            isBlink={status.checkInOnDemandRoomStatus || status.checkOutArgentRoomStatus}
            to={NAVIGATION.CLEANING.path}
            title={NAVIGATION.CLEANING.name}
          />
          <AwesomeSelectedPrimaryButton to={NAVIGATION.TROLLY.path} title={NAVIGATION.TROLLY.name} />
          <AwesomeButton to={NAVIGATION.LAUNDRY.path} title={NAVIGATION.LAUNDRY.name} />
        </S.LeftBreadcrumb>
        <S.PulseButtonWrapper>
          <ContrastAnimation
            isActive={status.checkInOnDemandRoomStatus}
            onClick={() => navigate(NAVIGATION.ONDEMAND_ROOMS.path)}
            disabled={false}
          />
        </S.PulseButtonWrapper>
      </S.TopBreadcrumb>
      <S.BlurCardWrapper>
        <S.CardTitle>Trolley Stock Request</S.CardTitle>
        <BaseForm form={form} size="middle">
          <S.PurchaseOrderWrapper>
            <S.Padding>
              <Row gutter={{xs: 30, md: 10, xl: 60}}>
                <Col xs={24} md={12}>
                  <BaseForm.Item
                    name="itemId"
                    label="Items"
                    rules={[
                      {
                        required: true,
                        message: 'Item is required',
                      },
                    ]}>
                    <Select placeholder={`Select Item`} mode="multiple" onChange={(e: any) => handleItemSelect(e)}>
                      {item?.map((post: {title: string; value: number}, key) => {
                        return (
                          <Option key={key} value={post.value} style={{innerHeight: '50px'}}>
                            <div style={{fontSize: '14px'}}>{post.title}</div>
                          </Option>
                        );
                      })}
                    </Select>
                  </BaseForm.Item>
                </Col>

                <Col xs={24} md={12}>
                  <BaseForm.Item
                    name="trolley"
                    label="Trolley Number"
                    rules={[
                      {
                        required: true,
                        message: 'Trolley Number is required',
                      },
                    ]}>
                    <Select placeholder={`Select Trolley Number`} onChange={(e: any) => handletrollySelect(e)}>
                      {trolleyAllocation?.map((post: {title: string; value: number}, key) => {
                        return (
                          <Option key={key} value={post.value}>
                            {post.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </BaseForm.Item>
                </Col>
              </Row>
            </S.Padding>
          </S.PurchaseOrderWrapper>
        </BaseForm>
      </S.BlurCardWrapper>

      <S.BlurCardWrapper>
        <S.Padding>
          <S.TableWrapper>
            <S.StyledTable>
              <thead>
                <tr>
                  <S.TableHeader>Items</S.TableHeader>
                  <S.TableHeader>Available Stock</S.TableHeader>
                  <S.TableHeader>Quantity</S.TableHeader>
                </tr>
              </thead>
              <tbody>
                {rowData.length > 0 &&
                  selectedItem &&
                  rowData.map((post: any, i: number) => (
                    <tr key={i}>
                      <S.TableData>{post.title}</S.TableData>
                      <S.TableData>{post.quantity}</S.TableData>
                      
                    <S.QuantityCell>
                        <S.QuantityButton onClick={() => handleDecrease(i)}>
                          <Button icon={<MinusOutlined />} danger />
                        </S.QuantityButton>
                   <S.QuantityCenter>
                   {post.qty}
                   </S.QuantityCenter>                
                        <S.QuantityButton onClick={() => handleIncrease(i)}>
                          <Button htmlType="submit" type="primary" icon={<PlusOutlined />} />
                        </S.QuantityButton>
                      </S.QuantityCell>
               
                    </tr>
                  ))}
              </tbody>
            </S.StyledTable>

            <S.EmptyFooter />
          </S.TableWrapper>
        </S.Padding>

        <S.ButtonWrapper>
          <Button onClick={() => create()} type="primary" disabled={rowData.length > 0 && selectedItem ? false : true}>
            Request
          </Button>
        </S.ButtonWrapper>
      </S.BlurCardWrapper>
    </S.Container>
  );
}
