/* eslint-disable react/jsx-key */
import TopLevelCard from '@app/components/cards/TopLevelCard';
import styled from 'styled-components';
import React, {useState} from 'react';
// import Reveal from 'react-reveal/Reveal';

// import './ProductLevelHome.less';
import {useNavigate} from 'react-router-dom';

import {useDispatch} from 'react-redux';

import {CategoryItem} from './CategoryDatas';
import {getCategoryStuctureByID} from '@app/api/resturant/tablecategory/categoryStucture.api';
import {useAppSelector} from '@app/hooks/reduxHooks';
import {
  addMenuItem,
  setCategoryData,
  setCategoryStatus,
  setChangeNavOption,
  setFromCard,
} from './slices/categoryNavSlice';
import {addFoodItems, setSelectedCategoryId} from './slices/waiterDasboardSlice';

import {notificationController} from '@app/controllers/notificationController';
export const FadeIn = styled.div`
  animation: fadeInAnimation 1s linear;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const ProductsContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const FoodCatagoryTop = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hotelServiceConfig = useAppSelector(state => state.hotelSlice.hotelServiceConfig);

  const categoryLists = useAppSelector(state => state.categoryNav.categoryLists);
  const menuItemsList = useAppSelector(state => state.categoryNav.menuItems);

  const [categories, setCategories] = useState<CategoryItem[]>([]);
  const [categoryId, setCategoryId] = useState<number>(0);

  const [firstCatLayer, setfirstCatLayer] = useState<any[]>([]);
  const [secondCatLayer, setSecondCatLayer] = useState<any[]>([]);

  // const {hotelServiceConfig} = useAppSelector(state => state.hotelSlice);

  const getCategoryById = async (id: number, layer?: number) => {
    const result = await getCategoryStuctureByID(id, hotelServiceConfig.serviceId);

    const data: any[] = [];

    result.result.category &&
      result.result.category.map((post: any, i: any) => {
        data.push({
          id: post.id,
          backgroundImage: post.categoryImage,
          title: post.categoryTitle,
          children: [],
          expanded: post.expanded,
          categoryId: post.categoryId,
        });
      });

    if (layer === 1) {
      setfirstCatLayer(data);
      getCategoryById(data[0]?.id, 2);
    } else if (layer === 2) {
      setSecondCatLayer(data);
      dispatch(setCategoryData({items: []}));
      dispatch(addFoodItems({foodItems: []}));
      if (data[0]?.expanded) {
        getCategoryById(data[0]?.id);
        dispatch(addFoodItems({foodItems: []}));
      }
    } else {
      setCategories(data);
      dispatch(setCategoryData({items: data}));
    }
  };

  React.useEffect(() => {
    // getCategoryById(0);
  }, []);

  const getAllFoodItemsByCategory = async (id: any, name?: string) => {
    const results = await getCategoryStuctureByID(id, hotelServiceConfig.serviceId, name);
    if (results?.statusCode === '20000') {
      const data: any[] = [];

      results.result.category &&
        results.result.category.map((post: any, i: any) => {
          data.push({
            id: post.id,
            category: '',
            currency: 'LKR',
            itemImage: post.image,
            item: post.name,
            // itemId: post.id,
            quantity: 1,
            commentQuantity: 0,
            price: post.price,
            orderedItemStatus: 'NEW',
            active: post.active,
          });
        });

      dispatch(addFoodItems({foodItems: data}));
    } else {
      notificationController.error({message: results?.message});
    }
  };

  const onChangeCard = async (data: CategoryItem) => {
    if (data.expanded) {
      dispatch(setChangeNavOption(false));

      // setCategories(data.children);
      dispatch(addMenuItem({item: data}));

      setCategoryId(data.id);
      await getCategoryById(data.id);
      dispatch(setSelectedCategoryId({selectedCategoryId: null}));
    } else {
      dispatch(addMenuItem({item: data}));

      localStorage.setItem('categoryId', data.id.toString());

      await getAllFoodItemsByCategory(data.id);
      dispatch(setCategoryStatus({status: false}));
      dispatch(setFromCard({status: true}));

      setTimeout(() => {
        navigate(`/restaurant/dashboard/:${data.id}`, {state: data});
      }, 200);
    }

    // navigate(`/category/food/:${data.name}`, {state: data});
    // dispatch(setSelectedCat(data.name));
    // dispatch(setMainCat(data.name));
  };
  return (
    <div>
      {/* <CategoryFilterArea>
        {location.pathname === '/category/main' ? (
          <div>
            <CategoryFilterCardWrap>
              {firstCatLayer.map((post: any, index) => {
                return (
                  <CatFilterCards
                    onClick={() => {
                      

                      if (post.expanded) {
                        // setCategories(data.children);
                        getCategoryById(post.id, 2);
                      } else {
                        localStorage.setItem('categoryId', post.id.toString());

                        navigate(`/restaurant/dashboard/:${post.id}`, {state: post});
                      }
                    }}
                    backgroundImage={post.backgroundImage}
                    key={index}
                    title={post.title}
                  />
                );
              })}
            </CategoryFilterCardWrap>
            <CategoryFilterCardWrap>
              {secondCatLayer.map((post: any, index) => {
                return (
                  <CatFilterCards
                    background="#14bf50"
                    onClick={() => {
                      if (post.expanded) {
                        // setCategories(data.children);

                        getCategoryById(post.id);
                      } else {
                        localStorage.setItem('categoryId', post.id.toString());

                        navigate(`/restaurant/dashboard/:${post.id}`, {state: post});
                      }
                    }}
                    backgroundImage={post.backgroundImage}
                    key={index}
                    title={post.title}
                  />
                );
              })}
            </CategoryFilterCardWrap>
          </div>
        ) : (
          <></>
        )}
      </CategoryFilterArea> */}
      <ProductsContainer>
        {categoryLists.map((post: CategoryItem, i: number) => {
          return (
            <FadeIn onClick={() => onChangeCard(post)}>
              <TopLevelCard
                scale={'0.85'}
                key={i}
                bgUrl={post.backgroundImage}
                logo={null}
                name={post.title}
                info={''}
              />
            </FadeIn>
          );
        })}
      </ProductsContainer>
    </div>
  );
};

export default React.memo(FoodCatagoryTop);
