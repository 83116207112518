import {createAction, createSlice, PayloadAction, PrepareAction} from '@reduxjs/toolkit';
import {IuserAuthorization, IUserPermission, UserModel} from '@app/domain/UserModel';
import {persistUser, readUser} from '@app/services/localStorage.service';
import {RootState} from '@app/store/store';
import {IOrderItem} from '@app/api/getOrders.api';
import _, {concat, filter} from 'lodash';

// export interface UserState {
//   user: UserModel | null;
//   userPermission?: IuserAuthorization | null;
//   isAuthenticated: boolean;
// }

export interface INewOrderList {
  tableId: number;
  orderId: number;
  orderStatus: string;
  foodItemList: IOrderItem[];
}
interface IinitialState {
  foodItems: IOrderItem[];
  orderList: IOrderItem[];
  searchedFoods: any[];
  tableItems: any[];
  selectedTableId: number | null;
  orderId: number | null;
  selectedCategoryId: number | null;
  newOrderList: INewOrderList[];
  silderIndex: number;
  chefSilderIndex: number;
}

const initialState: IinitialState = {
  foodItems: [],
  orderList: [],
  selectedTableId: null,
  orderId: null,
  searchedFoods: [],
  selectedCategoryId: null,
  tableItems: [],
  newOrderList: [],
  silderIndex: 0,
  chefSilderIndex: 0,
};

// export const setUser = createAction<PrepareAction<UserModel>>('user/setUser', newUser => {
//   persistUser(newUser);

//   return {
//     payload: newUser,
//   };
// });

export const waiterDasboardSlice = createSlice({
  name: 'waiterDasboard',
  initialState,
  reducers: {
    addFoodItems: (state, action: PayloadAction<{foodItems: any[]}>) => {
      state.foodItems = action.payload.foodItems;
    },
    addFoodItem: (state, action: PayloadAction<{foodItem: any}>) => {
      state.foodItems = [...state.foodItems, action.payload.foodItem];
    },

    addOrderList: (state, action: PayloadAction<{orderList: any[]}>) => {
      state.orderList = action.payload.orderList;
    },
    addSelectedItem: (state, action: PayloadAction<{orderItem: any}>) => {
      state.orderList = [...state.orderList, action.payload.orderItem];
    },

    addTableId: (state, action: PayloadAction<{selectedTableId: number}>) => {
      state.selectedTableId = action.payload.selectedTableId;
    },

    resetTableId: (state, action: PayloadAction<any>) => {
      state.selectedTableId = null;
    },

    addOrderId: (state, action: PayloadAction<{orderId: number}>) => {
      state.orderId = action.payload.orderId;
    },

    resetOrderId: (state, action: PayloadAction<any>) => {
      state.orderId = null;
    },

    addSearchedFoods: (state, action: PayloadAction<{searchedFoods: any[]}>) => {
      state.searchedFoods = action.payload.searchedFoods;
    },

    setSelectedCategoryId: (state, action: PayloadAction<{selectedCategoryId: number | null}>) => {
      state.selectedCategoryId = action.payload.selectedCategoryId;
    },

    resetSelectedCategoryId: (state, action: PayloadAction<any>) => {
      state.selectedCategoryId = null;
    },

    addTableItems: (state, action: PayloadAction<{tableItems: any}>) => {
      const mergerdItems = concat(state.tableItems, action.payload.tableItems);

      const unwrappedData = JSON.parse(JSON.stringify(mergerdItems));

      const filteredData = _(unwrappedData)
        .groupBy('id')
        .map(items => _.head(items))
        .value();

      state.tableItems = mergerdItems;
    },

    setNewOrderList: (state, action: PayloadAction<{orderList: INewOrderList[]}>) => {
      state.newOrderList = action.payload.orderList;
    },
    setsliderIndex: (state, action: PayloadAction<{index: number}>) => {
      state.silderIndex = action.payload.index;
    },
    setChefSliderIndex: (state, action: PayloadAction<{index: number}>) => {
      state.chefSilderIndex = action.payload.index;
    },
    // getTableItem: (state, action: PayloadAction<{tableId: any}>) => {
    //   // state.tableItems = [...state.tableItems, action.payload.tableItems];
    //   return filter(state.tableItems,(res:any)=>res?.tableId===  action.payload)
    // },
  },
});

export const {
  addFoodItems,
  addFoodItem,
  addOrderList,
  addSelectedItem,
  addTableId,
  resetTableId,
  addOrderId,
  resetOrderId,
  addSearchedFoods,
  setSelectedCategoryId,
  resetSelectedCategoryId,
  addTableItems,
  setNewOrderList,
  setsliderIndex,
  setChefSliderIndex,
} = waiterDasboardSlice.actions;
// Other code such as selectors can use the imported `RootState` type
// export const isAuthenticated = (state: RootState) => state.user.isAuthenticated;

export default waiterDasboardSlice.reducer;
