/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {useEffect, useState} from 'react';
import * as S from '../ServiceReservation.style';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {setIsTouchAction, setLoading, setModalVisible} from '@app/store/slices/commonSlice';
import {Col, Row, Select, TimePicker} from 'antd';
import {IAvailableService, ICreateServiceBooking, ICreateServiceBookingService} from '../interface';
import {
  createAdditionalBooking,
  getAvailableAdditionalServices,
  getCheckedInReservations,
} from '@app/api/hotel/additionalBookings/additionalBookings.api';
import Tooltip from '@app/components/common/Tooltip/Tooltip';
import {Input, TextArea} from '@app/components/common/inputs/Input/Input';
import {BOOKING_METHOD, NATIONALITY} from '@app/shared/constants';
import {Option} from '@app/components/common/selects/Select/Select';
import styled from 'styled-components';
import {DatePicker} from '@app/components/common/pickers/DatePicker';
import {BASE_COLORS} from '@app/styles/themes/constants';
import {find, isEmpty} from 'lodash';
import {notificationController} from '@app/controllers/notificationController';
import {checkVATAvailability, validateEmail, validateEmailWithoutRequired} from '@app/utils/functions';
import {Switch} from '@app/components/common/Switch/Switch';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {Checkbox} from '@app/components/common/Checkbox/Checkbox';
import {AutoComplete} from '@app/components/common/AutoComplete/AutoComplete';
import {searchedExactVatUsersApi, searchedVatUsersApi} from '@app/api/hotel/guest/guest.api';
import PriceCalculator from '@app/components/common/PriceCalculator/PriceCalculator';
import {convertNumberFormatWithDecimal, roundNumberDeci} from '@app/utils/utils';
import {calculateTotalForEachService, isValidService} from '../helper/helperFunction';
import moment from 'moment';
import CreateServicePriceCalculator from '@app/components/common/PriceCalculator/CreateServicePriceCalculator';

const invoiceType = [
  {label: 'Cash', value: 3},
  {label: 'Reservation', value: 1},
  {label: 'Room', value: 2},
];
const CustomDatePicker = styled(DatePicker)`
  width: 100%;
`;

const CustomTimePicker = styled(TimePicker)`
  width: 100%;
`;

const DISCOUNT_OPTION = {
  AMOUNT: 'AMOUNT',
  PERCENTAGE: 'PERCENTAGE',
};

const NUMBER_REGEX = /^\d*\.?\d*$/;

export default function CreateAdditionalServiceModal({
  form,
  reloadData,
  selectedServices,
  setselectedServices,
  setDiscountOption,
  discountOption,
}: any) {
  // Local State Hooks
  const [isVatDetailsAvailable, setisVatDetailsAvailable] = useState(false);
  const [searchedVatUsers, setsearchedVatUsers] = useState([]);
  const [isExistingVatUser, setisExistingVatUser] = useState(false);
  const [vatUserFormData, setvatUserFormData]: any = useState({
    vatPersonName: null,
    vatNumber: null,
    vatPersonEmail: null,
    vatPersonAddress: null,
  });
  const [isVatActive, setisVatActive] = useState(false);
  const [isVatApplicable, setisVatApplicable] = useState(false);
  const [selectedReservation, setselectedReservation]: any = useState({});
  const [selectedInvoiceType, setselectedInvoiceType] = useState(3);
  const [availableServices, setavailableServices] = useState<IAvailableService[]>([]);
  const [isResident, setisResident]: any = useState(true);
  const [checkedInReservations, setCheckedInReservations] = useState([]);
  const [selectedMethod, setselectedMethod]: any = useState({});

  //   Global State Hooks
  const {hotelId, groupId} = useAppSelector(state => state.hotelSlice.hotelConfig);

  //Custom Hook Usage
  const dispatch = useAppDispatch();

  console.log(discountOption);

  //   Functions and Event Handlers
  const onFinish = async (values: any) => {
    const serviceList: ICreateServiceBookingService[] = [];
    dispatch(setLoading(true));

    selectedServices.map((service: any) => {
      const convertedServiceData = {
        quantity: service.selectedQuantity,
        method: service.method,
        numberOfDays: service.numberOfDays,
        numberOfHours: service.numberOfHours,
        perHourPrice: service.hourPrice,
        fullDayPrice: service.fullDayPrice,
        halfDayPrice: service.halfDayPrice,
        hallId: service.hallId,
        discountType: service.discountType,
        discountValue: service.discountValue,
        taxList: service.taxList,
        id: service.additionalServiceId,
        name: service.service,
      };
      const {totalAmountWithTax, totalAmountWithDiscountAndWithoutTax} = calculateTotalForEachService(
        convertedServiceData,
        isResident,
        isVatApplicable,
      );

      const updatedTaxList = service?.taxList?.map((tax: any) => ({
        ...tax,
        useReservation: tax.vat ? isVatApplicable : tax.serviceCharge ? service.taxInclude : tax.useReservation,
      }));

      serviceList.push({
        additionalServiceId: service.additionalServiceId,
        checkInDate: values?.checkInDate,
        checkInTime: moment(values?.checkInTime).format('hh:mm:ss'),
        count: service?.selectedQuantity,
        discount: service?.discountValue,
        discountType: service?.discountType ? service?.discountType : 'AMOUNT',
        hallTime: service?.method,
        numberOfDays: service?.numberOfDays,
        numberOfHour: service?.numberOfHours,
        ownerName: values.ownerName,
        paymentMethod: values?.paymentMethod,
        reservedRoomsId:
          selectedInvoiceType === 1 ? values?.reservedRoomId : selectedInvoiceType === 2 ? values?.roomId : null,
        resident: isResident,
        taxList: updatedTaxList,
        totalPrice: totalAmountWithDiscountAndWithoutTax,
        totalCharge: totalAmountWithTax,
        vatRegistry: {
          email: values?.vatPersonEmail,
          registryType: ['ADDITIONAL_SERVICE'],
          hotelId: hotelId,
          vatNumber: values?.vatNumber,
          onlyThisHotelView: false,
          name: values?.vatPersonName,
          address: values?.vatPersonAddress,
        },
        vatRegistryApplicable: isVatDetailsAvailable,
        vatApplicable: isVatApplicable,
      });
    });

    const payload: ICreateServiceBooking = {
      additionalServiceReservationRequestList: serviceList,
      checkInDate: values?.checkInDate,
      checkInTime: moment(values?.checkInTime).format('hh:mm:ss'),
      checkOutDate: values?.checkInDate,
      checkOutTime: moment(values?.checkInTime).format('hh:mm:ss'),
      comments: values?.comments,
      hotelId: hotelId,
      ownerName: values?.ownerName,
      paymentMethod: values?.paymentMethod,
      advancedPayment: values?.advancedPayment,
      reservedRoomsId:
        selectedInvoiceType === 1 ? values?.reservedRoomId : selectedInvoiceType === 2 ? values?.roomId : null,
      resident: isResident,
      totalPrice: result.finalAmountWithoutTax,
      totalCharge: result.finalAmountWithTax,
      vatRegistry: {
        email: values?.vatPersonEmail,
        registryType: ['ADDITIONAL_SERVICE'],
        hotelId: hotelId,
        vatNumber: values?.vatNumber,
        onlyThisHotelView: false,
        name: values?.vatPersonName,
        address: values?.vatPersonAddress,
      },
      vatRegistryApplicable: isVatDetailsAvailable,
      vatApplicable: isVatApplicable,
    };
    try {
      const response = await createAdditionalBooking(payload);
      if (response.statusCode === '20000') {
        notificationController.success({message: 'Additional service booked successfully'});
        setselectedInvoiceType(3);
        setselectedReservation({});
        dispatch(setModalVisible(false));
        onCloseModal();
      } else {
        notificationController.error({message: response.message});
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
    }
    console.log({payload});
  };

  const onCloseModal = () => {
    form.resetFields();
    dispatch(setLoading(false));
    reloadData();
    resetVatDetails();
    setselectedServices([]);
    setDiscountOption({});
    setselectedMethod({});
  };

  const resetVatDetails = () => {
    form.resetFields(['vatPersonName', 'vatNumber', 'vatPersonEmail', 'vatPersonAddress']);
    setisVatDetailsAvailable(false);
    setsearchedVatUsers([]);
    setisExistingVatUser(false);
    setvatUserFormData({
      vatPersonName: null,
      vatNumber: null,
      vatPersonEmail: null,
      vatPersonAddress: null,
    });
    setisVatActive(false);
    setisVatApplicable(false);
  };

  const getCheckedInReservationsData = async () => {
    try {
      const data = await getCheckedInReservations(hotelId);
      setCheckedInReservations(data.result.additionalServiceReservation);
    } catch (error) {}
  };

  const getAvailableServices = async (resident: boolean, checkInDate: string) => {
    try {
      const data = await getAvailableAdditionalServices(hotelId, resident, checkInDate);
      const updatedResponse = data.result.additionalServiceReservation.map((res: any) => {
        return {
          ...res,
          method: '',
          selectedQuantity: 0,
          numberOfDays: 0,
          numberOfHours: 0,
          discountType: '',
          discountValue: 0,
        };
      });

      console.log({updatedResponse});

      setavailableServices(updatedResponse);
    } catch (error) {}
  };

  const disabledDate = (current: {isBefore: (arg0: any) => any; isAfter: (arg0: any) => any}) => {
    const checkInDate: any = new Date(selectedReservation && selectedReservation?.checkInDate);
    const checkOutDate: any = new Date(selectedReservation && selectedReservation?.checkOutDate);
    const nextDayofCheckout: any = new Date(checkOutDate);

    const currentDate: any = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    nextDayofCheckout.setDate(checkOutDate.getDate() + 1);
    if (!checkInDate || !nextDayofCheckout) {
      return false;
    }
    return current.isBefore(checkInDate) || current.isAfter(nextDayofCheckout) || current.isBefore(currentDate);
  };

  const handleSelect = (value: number) => {
    console.log({value});
    console.log({availableServices});

    const findObj = availableServices.find(data => data.additionalServiceId === value);

    if (findObj !== undefined) {
      if (findObj.fullDayPrice === null && findObj.halfDayPrice === null && findObj.hourPrice === null) {
        notificationController.info({message: 'Enter the relevant Price for the selected Service', placement: 'top'});
        return;
      }

      if (!selectedServices.some((service: {additionalServiceId: number}) => service.additionalServiceId === value)) {
        const updatedServices = [...selectedServices, findObj];
        setselectedServices(updatedServices);
      }

      const isActiveVat = checkVATAvailability(findObj?.taxList);
      setisVatActive(isActiveVat);

      if (selectedInvoiceType === 3) {
        setisVatApplicable(true);
      } else {
        setisVatApplicable(selectedReservation?.vatApplicable);
      }
    }
  };

  const handleDeselect = (value: number) => {
    const updatedServices = selectedServices.filter(
      (service: {additionalServiceId: number}) => service.additionalServiceId !== value,
    );
    setselectedServices(updatedServices);
    form.resetFields([
      `${value}-days`,
      `${value}-hours`,
      `${value}-hallTime`,
      `${value}-count`,
      `${value}-discountValue`,
      `${value}-discountType`,
    ]);
  };

  const handleClickVATDetails = (checked: boolean) => {
    setisVatDetailsAvailable(checked);
    if (selectedReservation?.vatRegistry && selectedReservation?.vatRegistry?.vatNumber !== null) {
      const vatFormData = {
        vatPersonName: selectedReservation.vatRegistry.name,
        vatNumber: selectedReservation.vatRegistry.vatNumber,
        vatPersonEmail: selectedReservation.vatRegistry.email,
        vatPersonAddress: selectedReservation.vatRegistry.address,
      };
      form.setFieldsValue(vatFormData);
      setvatUserFormData(vatFormData);
    }
  };

  const handlePopulateVatInformation = (vatDetails: any) => {
    const {name, address, vatNumber, email} = vatDetails;
    const fieldData = {
      vatPersonName: name,
      vatNumber: vatNumber,
      vatPersonEmail: email,
      vatPersonAddress: address,
    };
    form.setFieldsValue(fieldData);
    setvatUserFormData(fieldData);
    setisExistingVatUser(true);
  };

  const handleBlurPopulate = async (value: string, searchField: string) => {
    let searchParams = {};
    if (searchField === 'vatPersonEmail') {
      searchParams = {email: value};
    } else if (searchField === 'vatNumber') {
      searchParams = {vatNumber: value};
    }
    const result = await searchedExactVatUsersApi(groupId, searchParams, 20, 0);
    const data = result?.result?.Guests?.map(
      (item: {id: number; vatNumber: number; address: string; name: string; email: string}) => ({
        id: item.id,
        vatNumber: item.vatNumber,
        address: item.address,
        name: item.name,
        email: item.email,
        value: item.id,
        label: searchField === 'vatPersonEmail' ? item.email : item.vatNumber,
      }),
    );
    if (!isEmpty(data)) handlePopulateVatInformation(data[0]);
  };

  const resetVatSearchedData = () => {
    setsearchedVatUsers([]);
  };

  const handleSearchVatUsers = async (value: string, searchField: string) => {
    let searchParams = {};
    if (searchField === 'vatPersonEmail') {
      searchParams = {email: value};
    } else if (searchField === 'vatNumber') {
      searchParams = {vatNumber: value};
    }

    const result = await searchedVatUsersApi(groupId, searchParams, 20, 0);

    const data = result?.result?.Guests?.map(
      (item: {id: number; vatNumber: number; address: string; name: string; email: string}) => ({
        id: item.id,
        vatNumber: item.vatNumber,
        address: item.address,
        name: item.name,
        email: item.email,
        value: item.id,
        label: searchField === 'vatPersonEmail' ? item.email : item.vatNumber,
      }),
    );

    setsearchedVatUsers(data);
  };

  const handleChangeVatInput = (changedFieldName: string) => {
    const fields = ['vatPersonName', 'vatNumber', 'vatPersonEmail', 'vatPersonAddress'];
    const fieldsForReset = fields.filter(o => o !== changedFieldName);

    if (isExistingVatUser && vatUserFormData[changedFieldName] !== null) {
      form.resetFields(fieldsForReset);
      setvatUserFormData({
        vatPersonName: null,
        vatNumber: null,
        vatPersonEmail: null,
        vatPersonAddress: null,
      });
      setisExistingVatUser(false);
    }
  };

  const handleMethodSelect = (value: any, additionalServiceId: number) => {
    console.log({value});

    const prevData = [...selectedServices];
    const updatedData = prevData.map(data => {
      if (data.additionalServiceId === additionalServiceId) {
        return {
          ...data,
          method: value,
        };
      }
      return data;
    });
    setselectedServices(updatedData);

    setselectedMethod((prevMethods: any) => ({
      ...prevMethods,
      [additionalServiceId]: value,
    }));
  };

  const handleHoursChange = (
    service: {
      hallId?: null;
      availableCount?: any;
      hourPrice: any;
      fullDayPrice?: any;
      halfDayPrice?: any;
      service?: string;
      count?: number;
      additionalServiceId?: number;
      selectedQuantity: number;
    },
    value: string | number,
  ) => {
    const prevData = [...selectedServices];
    const updatedData = prevData.map(data => {
      if (data.additionalServiceId === service.additionalServiceId) {
        return {
          ...data,
          method: 'PER_HOUR',
          numberOfHours: Number(value),
          numberOfDays: 0,
        };
      }
      return data;
    });
    setselectedServices(updatedData);
  };

  const handleDaysChange = (
    service: {
      hallId?: null;
      availableCount?: any;
      hourPrice: any;
      fullDayPrice?: any;
      halfDayPrice?: any;
      service?: string;
      count?: number;
      additionalServiceId?: number;
      selectedQuantity: number;
    },
    value: string | number,
  ) => {
    const prevData = [...selectedServices];
    const updatedData = prevData.map(data => {
      if (data.additionalServiceId === service.additionalServiceId) {
        return {
          ...data,
          method: 'FULL_DAY',
          numberOfDays: Number(value),
          numberOfHours: 0,
        };
      }
      return data;
    });
    setselectedServices(updatedData);
  };

  const handleQuantityChange = (
    service: {
      hallId?: null;
      availableCount?: any;
      hourPrice: any;
      fullDayPrice?: any;
      halfDayPrice?: any;
      service?: string;
      count?: number;
      additionalServiceId: number;
    },
    value: string | number,
  ) => {
    const prevData = [...selectedServices];
    const updatedData = prevData.map(data => {
      if (data.additionalServiceId === service.additionalServiceId) {
        return {
          ...data,
          selectedQuantity: Number(value),
        };
      }
      return data;
    });
    setselectedServices(updatedData);
  };

  const handleDiscountTypeChange = (
    service: {
      hallId?: null;
      availableCount?: any;
      hourPrice: any;
      fullDayPrice?: any;
      halfDayPrice?: any;
      service?: string;
      count?: number;
      additionalServiceId: number;
    },
    value: string | number,
  ) => {
    // @ts-ignore
    setDiscountOption(prevState => ({
      ...prevState,
      [service.additionalServiceId]: value,
    }));
    // setDiscountOption(value);
    form.resetFields([`${service.additionalServiceId}-discountValue`]);
    const prevData = [...selectedServices];
    const updatedData = prevData.map(data => {
      if (data.additionalServiceId === service.additionalServiceId) {
        return {
          ...data,
          discountType: value,
        };
      }
      return data;
    });
    setselectedServices(updatedData);
  };
  const handleDiscountChange = (
    service: {
      hallId?: null;
      availableCount?: any;
      hourPrice: any;
      fullDayPrice?: any;
      halfDayPrice?: any;
      service?: string;
      count?: number;
      additionalServiceId: number;
    },
    value: string | number,
  ) => {
    const prevData = [...selectedServices];
    const updatedData = prevData.map(data => {
      if (data.additionalServiceId === service.additionalServiceId) {
        return {
          ...data,
          discountValue: Number(value),
        };
      }
      return data;
    });
    setselectedServices(updatedData);
  };

  const calculateTotalForAllServices = (selectedServices: any) => {
    let finalAmountWithTax = 0;
    let finalAmountWithoutTax = 0;
    let totalTaxAmount = 0;
    let totalDiscountAmount = 0;
    const allTaxArrays: any[] = [];
    const activityDetailsList: any[] = [];

    const taxTotals: any = {};

    selectedServices &&
      selectedServices.forEach(
        (service: {
          selectedQuantity: number;
          method: any;
          numberOfDays: number;
          numberOfHours: number;
          hourPrice: number;
          fullDayPrice: number;
          halfDayPrice: number;
          hallId: number | null;
          discountType: string;
          discountValue: number;
          taxList: any;
          additionalServiceId: number;
          service: string;
        }) => {
          const convertedServiceData = {
            quantity: service.selectedQuantity,
            method: service.method,
            numberOfDays: service.numberOfDays,
            numberOfHours: service.numberOfHours,
            perHourPrice: service.hourPrice,
            fullDayPrice: service.fullDayPrice,
            halfDayPrice: service.halfDayPrice,
            hallId: service.hallId,
            discountType: service.discountType,
            discountValue: service.discountValue,
            taxList: service.taxList,
            id: service.additionalServiceId,
            name: service.service,
          };
          const {
            totalAmountWithTax: activityFinalAmountWithTax,
            totalAmountWithoutTax: activityFinalAmountWithoutTax,
            taxArray,
            totalTaxAmount: activityTotalTaxAmount,
            discountAmount,
            serviceName: activityName,
            totalAmountWithoutTax,
            totalAmountWithDiscountAndWithoutTax,
            ...rest
          } = calculateTotalForEachService(convertedServiceData, isResident, isVatApplicable);
          console.log({activityName});

          if (!isNaN(activityFinalAmountWithTax)) {
            finalAmountWithTax += activityFinalAmountWithTax;
          } else {
            console.error(service);
          }

          if (!isNaN(activityFinalAmountWithoutTax)) {
            finalAmountWithoutTax += activityFinalAmountWithoutTax;
          } else {
            console.error(service);
          }

          if (!isNaN(activityTotalTaxAmount)) {
            totalTaxAmount += activityTotalTaxAmount;
          } else {
            console.error(service);
          }

          totalDiscountAmount += discountAmount;

          allTaxArrays.push(...taxArray);

          activityDetailsList.push({
            activityName: service.service,
            totalAmountWithoutTax,
            totalAmountWithDiscountAndWithoutTax,
            discountType: service.discountType,
            discount: discountAmount,
          });
        },
      );
    allTaxArrays &&
      allTaxArrays.forEach(tax => {
        if (!taxTotals[tax.name]) {
          taxTotals[tax.name] = 0;
        }
        taxTotals[tax.name] += tax.taxAmount;
      });

    const taxTotalsList = Object.entries(taxTotals).map(([name, taxAmount]) => ({
      name,
      taxAmount,
    }));
    return {
      finalAmountWithTax,
      finalAmountWithoutTax,
      totalTaxAmount,
      totalDiscountAmount,
      allTaxArrays,
      taxTotals: taxTotalsList,
      activityDetailsList,
    };
  };

  const result = calculateTotalForAllServices(selectedServices);

  console.log({result});

  useEffect(() => {
    getCheckedInReservationsData();
  }, []);

  return (
    <S.FormContent>
      <BaseForm
        initialValues={{
          resident: true,
          invoiceType: 3,
          paymentMethod: 'CASH',
        }}
        name="stepForm"
        form={form}
        onFinish={onFinish}
        onFieldsChange={(_, allFields) => {
          dispatch(setIsTouchAction(true));
          //   calculateAmount(isVatApplicable);
        }}>
        <Row gutter={{xs: 10, md: 15, xl: 30}}>
          <Col xs={24} md={16} xl={16}>
            <Row gutter={{xs: 10, md: 15, xl: 30}}>
              <Col xs={24} md={12} xl={6}>
                <BaseForm.Item
                  name="invoiceType"
                  label="Cash/Room"
                  rules={[{required: false, message: 'field is required'}]}>
                  <Select
                    options={invoiceType}
                    onChange={value => {
                      //   setselectedService(undefined);
                      setselectedReservation({});
                      setselectedInvoiceType(value);
                      setselectedServices([]);

                      resetVatDetails();
                      if (value === 1 || value === 2) {
                        setavailableServices([]);
                      } else {
                        // getAvailableServices(true);
                      }
                      form.resetFields([
                        'serviceId',
                        'count',
                        'ownerName',
                        'hallTime',
                        'hours',
                        'days',
                        'checkInDate',
                        'checkInTime',
                        'resident',
                        'reservationCheckinDate',
                      ]);
                      setisResident(true);
                    }}
                  />
                </BaseForm.Item>
              </Col>

              {selectedInvoiceType === 1 ? (
                <>
                  <Col xs={24} sm={24} md={12} xl={6}>
                    <BaseForm.Item
                      name="reservedRoomId"
                      label="Reservation"
                      rules={[{required: true, message: 'Reservation is required'}]}>
                      <Select
                        showSearch
                        onSelect={(_value: any) => {
                          const findObj: any = checkedInReservations.find(
                            (reservation: {reservedRoomId: number}) => reservation.reservedRoomId === _value,
                          );
                          resetVatDetails();
                          form.setFieldValue(
                            'reservationCheckinDate',
                            `${findObj.checkInDate} - ${findObj.checkOutDate}`,
                          );
                          setselectedReservation(findObj);
                          setisResident(findObj?.resident);
                          const name =
                            findObj !== undefined ? `${findObj?.guestFirstName} ${findObj?.guestLastName}` : '';
                          form.resetFields([
                            'serviceId',
                            'count',
                            'ownerName',
                            'hallTime',
                            'hours',
                            'days',
                            'checkInDate',
                            'checkInTime',
                            'resident',
                          ]);
                          form.setFieldValue('ownerName', name);
                          setselectedServices([]);
                        }}
                        options={
                          checkedInReservations &&
                          checkedInReservations.map(
                            (
                              reservation: {
                                reservedRoomId: number;
                                reservationRefNumber: number;
                                reservedRoomNumber: string;
                                roomName: string;
                              },
                              idx,
                            ) => {
                              return {
                                label: (
                                  <Tooltip
                                    title={`${reservation?.reservationRefNumber} - ${reservation?.roomName} (
                               ${reservation?.reservedRoomNumber})`}>
                                    <S.CustomOptionText>
                                      {`${reservation?.reservationRefNumber} - ${reservation?.roomName} (${reservation?.reservedRoomNumber})`}
                                    </S.CustomOptionText>
                                  </Tooltip>
                                ),
                                value: reservation?.reservedRoomId,
                              };
                            },
                          )
                        }
                        filterOption={(input, option) => {
                          const optionText = option?.label?.props?.title ? option?.label?.props?.title.toString() : ''; // Convert option.children to string
                          return optionText.toLowerCase().includes(input.toLowerCase());
                        }}
                        defaultValue={true}
                        placeholder="Select reservation"
                      />
                    </BaseForm.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={6}>
                    <BaseForm.Item name="reservationCheckinDate" label="Reservation Date" rules={[{required: false}]}>
                      <Input disabled />
                    </BaseForm.Item>
                  </Col>
                </>
              ) : selectedInvoiceType === 2 ? (
                <>
                  <Col xs={24} sm={24} md={12} xl={6}>
                    <BaseForm.Item name="roomId" label="Room" rules={[{required: true, message: 'Room is required'}]}>
                      <Select
                        showSearch
                        onSelect={(_value: any) => {
                          const findObj: any = checkedInReservations.find(
                            (reservation: {reservedRoomId: number}) => reservation.reservedRoomId === _value,
                          );

                          resetVatDetails();
                          form.setFieldValue(
                            'reservationCheckinDate',
                            `${findObj.checkInDate} - ${findObj.checkOutDate}`,
                          );
                          setselectedReservation(findObj);
                          setisResident(findObj?.resident);
                          const name =
                            findObj !== undefined ? `${findObj?.guestFirstName} ${findObj?.guestLastName}` : '';
                          form.resetFields([
                            'serviceId',
                            'count',
                            'ownerName',
                            'hallTime',
                            'hours',
                            'days',
                            'checkInDate',
                            'checkInTime',
                            'resident',
                          ]);
                          form.setFieldValue('ownerName', name);
                          setselectedServices([]);
                          setavailableServices([]);
                        }}
                        defaultValue={true}
                        placeholder="Select reservation"
                        options={
                          checkedInReservations &&
                          checkedInReservations.map(
                            (
                              reservation: {
                                reservedRoomId: number;
                                reservationRefNumber: number;
                                roomName: string;
                                reservedRoomNumber: string;
                              },
                              idx,
                            ) => {
                              return {
                                label: (
                                  <Tooltip
                                    title={`${reservation?.roomName} - ${reservation?.reservedRoomNumber} (
                               ${reservation?.reservationRefNumber})`}>
                                    <S.CustomOptionText>
                                      {`${reservation?.roomName} - ${reservation?.reservedRoomNumber} (
                               ${reservation?.reservationRefNumber})`}
                                    </S.CustomOptionText>
                                  </Tooltip>
                                ),
                                value: reservation?.reservedRoomId,
                              };
                            },
                          )
                        }
                        filterOption={(input, option) => {
                          console.log(input, option);

                          const optionText = option?.label?.props?.title ? option?.label?.props?.title.toString() : ''; // Convert option.children to string
                          return optionText.toLowerCase().includes(input.toLowerCase());
                        }}
                      />
                    </BaseForm.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={6}>
                    <BaseForm.Item name="reservationCheckinDate" label="Reservation Date" rules={[{required: false}]}>
                      <Input disabled />
                    </BaseForm.Item>
                  </Col>
                </>
              ) : null}

              {selectedInvoiceType === 3 && (
                <Col xs={24} sm={24} md={12} xl={6}>
                  <BaseForm.Item
                    name="resident"
                    label="Resident/Non-Resident"
                    rules={[{required: true, message: 'Service is required'}]}>
                    <Select
                      onSelect={(value: any) => {
                        setisResident(value);
                        form.resetFields([
                          'serviceId',
                          'count',
                          'ownerName',
                          'hallTime',
                          'hours',
                          'days',
                          'checkInDate',
                          'checkInTime',
                        ]);
                        setselectedServices([]);
                        setavailableServices([]);
                      }}
                      defaultValue={true}
                      placeholder="Select nationality">
                      {NATIONALITY.map(nationality => {
                        return (
                          <Option key={nationality.key} value={nationality.value}>
                            {nationality.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </BaseForm.Item>
                </Col>
              )}
              <Col xs={24} sm={24} md={12} xl={6}>
                <BaseForm.Item
                  name="checkInDate"
                  label="Booking date"
                  rules={[{required: true, message: 'Date is required'}]}>
                  <CustomDatePicker
                    onChange={(value: any, date: string) => {
                      console.log(date);
                      getAvailableServices(isResident, date);
                    }}
                    disabledDate={disabledDate}
                    dateRender={(current: any) => {
                      const checkInDate = selectedReservation && new Date(selectedReservation.checkInDate);
                      const checkOutDate = selectedReservation && new Date(selectedReservation.checkOutDate);
                      checkInDate.setDate(checkInDate.getDate() - 1);
                      if (checkInDate && checkOutDate) {
                        const currentDate = new Date(current.year(), current.month(), current.date());
                        if (currentDate >= checkInDate && currentDate <= checkOutDate) {
                          return (
                            <div style={{border: `1px solid ${BASE_COLORS.primary}`, borderRadius: '10px'}}>
                              {current.date()}
                            </div>
                          );
                        }
                      }

                      return <div>{current.date()}</div>;
                    }}
                  />
                </BaseForm.Item>
              </Col>
              <Col xs={24} sm={24} md={12} xl={6}>
                <BaseForm.Item name="checkInTime" label="Time" rules={[{required: true, message: 'Time is required'}]}>
                  <CustomTimePicker allowClear={false} placement="topRight" />
                </BaseForm.Item>
              </Col>
              <Col xs={24} sm={24} md={12} xl={6}>
                <BaseForm.Item
                  name="ownerName"
                  label="Guest Name"
                  rules={[{required: true, message: 'Name is required'}]}>
                  <Input
                    disabled={selectedInvoiceType === 1 || selectedInvoiceType === 2 ? true : false}
                    placeholder="Enter guest name"
                  />
                </BaseForm.Item>
              </Col>
              <Col xs={24} sm={24} md={12} xl={12}>
                <BaseForm.Item
                  name="serviceId"
                  label="Additional Service"
                  rules={[{required: true, message: 'Service is required'}]}>
                  <Select
                    mode="multiple"
                    onSelect={handleSelect}
                    onDeselect={handleDeselect}
                    placeholder="Select service">
                    {availableServices &&
                      availableServices.map((res, idx) => {
                        return (
                          <Option key={idx} value={res.additionalServiceId}>
                            {res.service}
                          </Option>
                        );
                      })}
                  </Select>
                </BaseForm.Item>
              </Col>
              <Col xs={24} md={24} xl={12}>
                <BaseForm.Item name="comments" label="Comments" rules={[{required: false}]}>
                  <TextArea rows={2} placeholder="Enter comments" />
                </BaseForm.Item>
              </Col>
            </Row>

            <Row gutter={{xs: 10, md: 15, xl: 30}}>
              {selectedServices.map(
                (service: {
                  taxList: any;
                  hallId: null;
                  availableCount: any;
                  hourPrice: any;
                  fullDayPrice: any;
                  halfDayPrice: any;
                  service: string;
                  count: number;
                  additionalServiceId: number;
                  selectedQuantity: number;
                  method: string;
                  numberOfDays: number;
                  numberOfHours: number;
                  discountType: string;
                  discountValue: number;
                  taxInclude: boolean;
                }) => {
                  const convertedServiceData = {
                    quantity: service.selectedQuantity,
                    method: service.method,
                    numberOfDays: service.numberOfDays,
                    numberOfHours: service.numberOfHours,
                    perHourPrice: service.hourPrice,
                    fullDayPrice: service.fullDayPrice,
                    halfDayPrice: service.halfDayPrice,
                    hallId: service.hallId,
                    discountType: service.discountType,
                    discountValue: service.discountValue,
                    taxList: service.taxList,
                    id: service.additionalServiceId,
                    name: service.service,
                  };

                  const calculatedResult = calculateTotalForEachService(
                    convertedServiceData,
                    isResident,
                    isVatApplicable,
                  );

                  const isValid = isValidService(service, selectedMethod);

                  return (
                    <Col key={`${service.additionalServiceId}-services`} xs={24} md={24} xl={12}>
                      <S.BlurCardWrapper>
                        <S.WhiteCard>
                          <S.Padding>
                            <Row justify="space-between">
                              <S.ServiceTitle>{service.service}</S.ServiceTitle>
                              <S.ServiceTitle>
                                {isResident ? 'LKR' : 'USD'}{' '}
                                {convertNumberFormatWithDecimal(
                                  calculatedResult.totalAmountWithDiscountAndWithoutTax,
                                  2,
                                )}
                              </S.ServiceTitle>
                            </Row>
                            <Row>
                              <S.ServiceCharge>
                                Service Charge Include {service.taxInclude ? '✅' : '❌'}
                              </S.ServiceCharge>
                            </Row>
                            <Row gutter={{xs: 10, md: 15, xl: 30}}>
                              <Col xs={24} sm={24} md={24} xl={24}>
                                <BaseForm.Item
                                  name={`${service.additionalServiceId}-hallTime`}
                                  label="Booking rate"
                                  rules={[{required: true, message: 'Booking hours is required'}]}>
                                  <Select
                                    onSelect={value => handleMethodSelect(value, service.additionalServiceId)}
                                    placeholder="Select booking hours">
                                    {BOOKING_METHOD.map(method => {
                                      let price;
                                      switch (method.name) {
                                        case 'PER_HOUR':
                                          price = service?.hourPrice
                                            ? `${isResident ? 'LKR' : 'USD'} ${service?.hourPrice}`
                                            : null;
                                          break;
                                        case 'FULL_DAY':
                                          price = service?.fullDayPrice
                                            ? `${isResident ? 'LKR' : 'USD'} ${service?.fullDayPrice}`
                                            : null;
                                          break;
                                        case 'HALF_DAY':
                                          price = service?.halfDayPrice
                                            ? `${isResident ? 'LKR' : 'USD'} ${service?.halfDayPrice}`
                                            : null;
                                          break;
                                        default:
                                          price = null;
                                      }

                                      if (price) {
                                        return (
                                          <Option key={method.key} value={method.name}>
                                            {method.name} - {price}
                                          </Option>
                                        );
                                      }
                                      return null; // Exclude the option if price is null
                                    })}
                                  </Select>
                                </BaseForm.Item>
                              </Col>
                            </Row>
                            <Row gutter={{xs: 10, md: 15, xl: 30}}>
                              {selectedMethod[service.additionalServiceId] === 'PER_HOUR' ? (
                                <Col xs={24} sm={24} md={12} xl={12}>
                                  <BaseForm.Item
                                    name={`${service.additionalServiceId}-hours`}
                                    label="Number of hours"
                                    rules={[
                                      {
                                        required: true,
                                        validator: (rule, value) => {
                                          if (value < 0) {
                                            return Promise.reject('Hours cannot be negative');
                                          } else {
                                            return value === 0 || value === '0' || value === '' || value === undefined
                                              ? Promise.reject('Hours is required')
                                              : Promise.resolve();
                                          }
                                        },
                                      },
                                    ]}>
                                    <Input
                                      onChange={e => handleHoursChange(service, e.target.value)}
                                      type="number"
                                      placeholder="Total hours"
                                    />
                                  </BaseForm.Item>
                                </Col>
                              ) : selectedMethod[service.additionalServiceId] === 'FULL_DAY' ? (
                                <Col xs={24} sm={24} md={12} xl={12}>
                                  <BaseForm.Item
                                    name={`${service.additionalServiceId}-days`}
                                    label="Number of days"
                                    rules={[
                                      {
                                        required: true,
                                        validator: (rule, value) => {
                                          if (value < 0) {
                                            return Promise.reject('Days cannot be negative');
                                          } else {
                                            return value === 0 || value === '0' || value === '' || value === undefined
                                              ? Promise.reject('Days is required')
                                              : Promise.resolve();
                                          }
                                        },
                                      },
                                    ]}>
                                    <Input
                                      onChange={e => handleDaysChange(service, e.target.value)}
                                      type="number"
                                      placeholder="Enter the days"
                                    />
                                  </BaseForm.Item>
                                </Col>
                              ) : null}
                              {service?.hallId == null && (
                                <Col xs={24} sm={24} md={12} xl={12}>
                                  <BaseForm.Item
                                    name={`${service.additionalServiceId}-count`}
                                    label="Quantity"
                                    rules={[{required: true, message: 'Quantity is required'}]}>
                                    <Select
                                      onSelect={value => handleQuantityChange(service, value)}
                                      placeholder="Quantity">
                                      {Array.from(
                                        {
                                          length: Number(service?.availableCount),
                                        },
                                        (_, index) => index + 1,
                                      ).map(cNumber => {
                                        return <Option key={cNumber}>{cNumber}</Option>;
                                      })}
                                    </Select>
                                  </BaseForm.Item>
                                </Col>
                              )}
                            </Row>
                            <Row gutter={{xs: 10, md: 15, xl: 30}}>
                              <Col xs={24} sm={24} md={12} xl={12}>
                                <BaseForm.Item
                                  name={`${service.additionalServiceId}-discountOption`}
                                  label="Discount Option"
                                  rules={[{required: false}]}>
                                  <Select
                                    disabled={!isValid}
                                    onSelect={value => handleDiscountTypeChange(service, value)}
                                    placeholder="Discount Option">
                                    <Option value="PERCENTAGE">Percentage</Option>
                                    <Option value="AMOUNT">Amount</Option>
                                  </Select>
                                </BaseForm.Item>
                              </Col>
                              <Col xs={24} sm={24} md={12} xl={12}>
                                <BaseForm.Item
                                  name={`${service.additionalServiceId}-discountValue`}
                                  label="Discount Value"
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field',
                                    },
                                    ({getFieldValue}) => ({
                                      validator(_, value) {
                                        if (!value || value.trim() === '') {
                                          return Promise.resolve();
                                        }

                                        const inputValue = parseFloat(value);
                                        const trimmedValue = value.trim();

                                        if (discountOption[service.additionalServiceId] === DISCOUNT_OPTION.AMOUNT) {
                                          if (!trimmedValue.match(NUMBER_REGEX)) {
                                            return Promise.reject('Please enter numbers only');
                                          }
                                          if (inputValue < 0) {
                                            return Promise.reject('Amount must be greater than or equal to 0');
                                          }
                                          if (
                                            inputValue >
                                            Number(roundNumberDeci(calculatedResult.totalAmountWithoutTax, 2))
                                          ) {
                                            return Promise.reject(
                                              `Amount must be less than or equal to ${convertNumberFormatWithDecimal(
                                                calculatedResult.totalAmountWithoutTax,
                                                2,
                                              )}`,
                                            );
                                          }
                                        } else if (
                                          discountOption[service.additionalServiceId] === DISCOUNT_OPTION.PERCENTAGE
                                        ) {
                                          if (!trimmedValue.match(NUMBER_REGEX)) {
                                            return Promise.reject('Please enter numbers only');
                                          }
                                          if (inputValue < 0) {
                                            return Promise.reject('Percentage must be greater than or equal to 0');
                                          }
                                          if (inputValue > 100) {
                                            return Promise.reject('Percentage must be less than or equal to 100');
                                          }
                                        }

                                        return Promise.resolve();
                                      },
                                    }),
                                  ]}>
                                  <Input
                                    disabled={service.discountType === ''}
                                    onChange={e => handleDiscountChange(service, e.target.value)}
                                    placeholder="Discount Value"
                                  />
                                </BaseForm.Item>
                              </Col>
                            </Row>
                          </S.Padding>
                        </S.WhiteCard>
                      </S.BlurCardWrapper>
                    </Col>
                  );
                },
              )}
            </Row>
          </Col>
          <Col xs={24} md={24} xl={8}>
            <Row gutter={{xs: 10, md: 15, xl: 30}}>
              <Col xs={24} md={24} xl={24}>
                <CreateServicePriceCalculator
                  currency={isResident ? 'LKR' : 'USD'}
                  isVatApplicable={isVatApplicable}
                  totalAmount={result.finalAmountWithTax}
                  taxArray={result.taxTotals}
                  totalTaxAmount={result.totalTaxAmount}
                  activityDetailsList={result.activityDetailsList}
                  isServiceBooking={true}
                />
              </Col>
            </Row>
            <Row gutter={{xs: 10, md: 15, xl: 30}}>
              <Col xs={24} md={24} xl={24}>
                <S.BlurCardWrapper>
                  <S.BlueCard>
                    <S.Padding>
                      {isVatActive ? (
                        <Row gutter={{xs: 10, md: 15, xl: 30}}>
                          <Col xs={24} md={24} xl={24}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                alignItems: 'center',
                                marginBottom: '10px',
                                justifyContent: 'flex-end',
                              }}>
                              <S.Title>Applicable VAT</S.Title>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                // defaultChecked
                                checked={isVatApplicable}
                                onChange={value => {
                                  setisVatApplicable(value);
                                  //   calculateAmount(value);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      <Row gutter={{xs: 10, md: 15, xl: 30}}>
                        {selectedInvoiceType === 3 && (
                          <Col xs={24} sm={24} md={24} xl={12}>
                            <BaseForm.Item
                              name="advancedPayment"
                              label={`Advance Payment(${isResident ? 'LKR' : 'USD'})`}
                              rules={[
                                {
                                  required: false,
                                  validator: (rule, value) => {
                                    const inputValue = parseFloat(value);

                                    if (inputValue < 0) {
                                      return Promise.reject('Invalid amount');
                                    } else if (inputValue > Number(roundNumberDeci(result.finalAmountWithTax, 2))) {
                                      return Promise.reject("Ensure payment doesn't exceed exact charges");
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                },
                              ]}>
                              <Input type="number" placeholder="Enter the advance payment" />
                            </BaseForm.Item>
                          </Col>
                        )}
                        {selectedInvoiceType === 3 && (
                          <Col xs={24} sm={24} md={24} xl={12}>
                            <BaseForm.Item name="paymentMethod" label="Payment Method" rules={[{required: false}]}>
                              <Select placeholder="Select payment method">
                                <Option value="CASH">Cash</Option>
                                <Option value="CREDITCARD">Credit card</Option>
                              </Select>
                            </BaseForm.Item>
                          </Col>
                        )}
                      </Row>
                      {selectedInvoiceType === 3 && isVatActive ? (
                        <>
                          <S.CardTitle>VAT Details</S.CardTitle>
                          <Row style={{gap: 10}} justify="start">
                            <BaseForm.Item label="">
                              <Checkbox
                                value={isVatDetailsAvailable}
                                checked={isVatDetailsAvailable}
                                onChange={e => handleClickVATDetails(e.target.checked)}>
                                <S.CheckboxLabel>Add VAT Details</S.CheckboxLabel>
                              </Checkbox>
                            </BaseForm.Item>
                          </Row>
                          {isVatDetailsAvailable ? (
                            <>
                              <Row gutter={{xs: 10, md: 15, xl: 30}}>
                                <Col xs={24} md={24}>
                                  <BaseForm.Item
                                    name="vatPersonEmail"
                                    label="Email"
                                    rules={[
                                      {
                                        required: isVatDetailsAvailable,
                                        validator: isVatDetailsAvailable ? validateEmail : validateEmailWithoutRequired,
                                      },
                                    ]}>
                                    <AutoComplete
                                      disabled={!isVatDetailsAvailable}
                                      onBlur={(event: any) => {
                                        const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(
                                          event.target.value,
                                        );
                                        if (event.target.value.trim() !== '' && isValidEmail) {
                                          handleBlurPopulate(event.target.value, 'vatPersonEmail');
                                        }
                                        resetVatSearchedData();
                                      }}
                                      options={searchedVatUsers}
                                      onSearch={value => handleSearchVatUsers(value, 'vatPersonEmail')}
                                      onSelect={value => {
                                        console.log(value);

                                        const selectedGuest = find(
                                          searchedVatUsers,
                                          (o: {value: number}) => o.value === value,
                                        );
                                        console.log({selectedGuest});

                                        if (selectedGuest !== undefined) handlePopulateVatInformation(selectedGuest);
                                      }}>
                                      <Input
                                        disabled={!isVatDetailsAvailable}
                                        onChange={() => handleChangeVatInput('vatPersonEmail')}
                                      />
                                    </AutoComplete>
                                  </BaseForm.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                  <BaseForm.Item
                                    name="vatNumber"
                                    label="VAT Number"
                                    rules={[
                                      {
                                        required: isVatDetailsAvailable,
                                        message: 'Required field',
                                      },
                                    ]}>
                                    <AutoComplete
                                      disabled={!isVatDetailsAvailable}
                                      onBlur={(event: any) => {
                                        if (event.target.value.trim() !== '') {
                                          handleBlurPopulate(event.target.value, 'vatNumber');
                                        }
                                        resetVatSearchedData();
                                      }}
                                      options={searchedVatUsers}
                                      onSearch={value => handleSearchVatUsers(value, 'vatNumber')}
                                      onSelect={value => {
                                        console.log(value);

                                        const selectedGuest = find(
                                          searchedVatUsers,
                                          (o: {value: number}) => o.value === value,
                                        );

                                        if (selectedGuest !== undefined) handlePopulateVatInformation(selectedGuest);
                                      }}>
                                      <Input
                                        disabled={!isVatDetailsAvailable}
                                        onChange={() => handleChangeVatInput('vatNumber')}
                                      />
                                    </AutoComplete>
                                  </BaseForm.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                  <BaseForm.Item
                                    name="vatPersonName"
                                    label="VAT Person name"
                                    rules={[
                                      {
                                        required: isVatDetailsAvailable,
                                        message: 'Required field',
                                      },
                                    ]}>
                                    <Input
                                      disabled={!isVatDetailsAvailable}
                                      onChange={() => handleChangeVatInput('vatPersonName')}
                                    />
                                  </BaseForm.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                  <BaseForm.Item
                                    name="vatPersonAddress"
                                    label="VAT Person Address"
                                    rules={[
                                      {
                                        required: isVatDetailsAvailable,
                                        message: 'Required field',
                                      },
                                    ]}>
                                    <Input
                                      disabled={!isVatDetailsAvailable}
                                      onChange={() => handleChangeVatInput('vatPersonAddress')}
                                    />
                                  </BaseForm.Item>
                                </Col>
                              </Row>
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </S.Padding>
                  </S.BlueCard>
                </S.BlurCardWrapper>
              </Col>
            </Row>
          </Col>
          {console.log({selectedServices})}
        </Row>
      </BaseForm>
    </S.FormContent>
  );
}
