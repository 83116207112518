import instance, {HOTEL_SERVICE} from '@app/api/instance';

interface IContactNumbers {
  contactNumbers: string;
}
export interface IGroupRequest {
  id?: number;
  name: string;
  address: string;
  contactPerson?: string;
  email?: string;
  active: boolean;
  cardLogo: File;
  cardImage: File;
  sideBarImage: File;
  contactNumbers?: IContactNumbers[];
}

export interface IApiResponse {
  message: string;
  result: any;
  status: string;
  statusCode: string;
}

export const CreateGroup = (hotelPayload: IGroupRequest): Promise<IApiResponse> => {
  const formData = new FormData();
  const obj = JSON.stringify({
    name: hotelPayload.name,
    address: hotelPayload.address,
    contactPerson: hotelPayload.contactPerson,
    email: hotelPayload.email,
    active: hotelPayload.active,
    contactNumbersList: hotelPayload.contactNumbers,
  });
  formData.append('cardLogo', hotelPayload.cardLogo);
  formData.append('cardImage', hotelPayload.cardImage);
  formData.append('sideBarImage', hotelPayload.sideBarImage);

  formData.append('group', obj);

  return instance.post<IApiResponse>(HOTEL_SERVICE + 'group', formData).then(({data}) => data);
};

export const getAllGroups = (): Promise<IApiResponse> =>
  instance.get<IApiResponse>(HOTEL_SERVICE + 'groups').then(({data}) => data);

export const UpdateGroup = (hotelPayload: IGroupRequest): Promise<IApiResponse> => {
  const formData = new FormData();
  const obj = JSON.stringify({
    id: hotelPayload.id,
    name: hotelPayload.name,
    address: hotelPayload.address,
    contactPerson: hotelPayload.contactPerson,
    email: hotelPayload.email,
    active: hotelPayload.active,
    contactNumbersList: hotelPayload.contactNumbers,
  });
  formData.append('cardLogo', hotelPayload.cardLogo);
  formData.append('cardImage', hotelPayload.cardImage);
  formData.append('sideBarImage', hotelPayload.sideBarImage);

  formData.append('group', obj);

  return instance.put<IApiResponse>(HOTEL_SERVICE + 'group', formData).then(({data}) => data);
};

export const DeleteGroup = (id: number): Promise<IApiResponse> =>
  instance.delete<IApiResponse>(HOTEL_SERVICE + `group/${id}`).then(({data}) => data);
