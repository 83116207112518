import React from 'react';
import {Routes, Route, useNavigate, Navigate} from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';
import ChangePasswordPage from '@app/pages/ChangePasswordPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import {withLoading} from '@app/hocs/withLoading.hoc';
import TopLevelLayout from '../layouts/main/MainLayout/TopLevelLayout';
import ProductLevelHome from '@app/pages/ProductLevelHome';
import ServiceLevelHome from '@app/pages/ServiceLevelHome';
import RestaurantDashBoardPage from '@app/pages/Restaurant/WaiterDashboard/WaiterDashboard';
// import TableDashBoardPage from '@app/pages/Restaurant/';
import HotelDashBoardPage from '@app/pages/Hotel/Dashboard/HotelDashboardPage';

import FoodCatagoryFoodLevel from '@app/pages/Restaurant/FoodCatagoryFoodLevel';
import InventoryServiceLevelHome from '@app/pages/Inventory/InventoryHome';
import GroupInventoryServiceLevelHome from '@app/pages/Inventory/GroupInventoryHome';
import InventoryDashboard from '@app/pages/Inventory/dashboard/InventoryDashboard';
import ChefDashBoard from '@app/pages/DashboardPages/ChefDashBoard';
import TableBoardPage from '@app/pages/Restaurant/WaiterDashboard/TableDashboard';
import FoodCatagoryTop from '@app/pages/Restaurant/FoodCatagoryTop';
import FoodThirdCatagory from '@app/pages/Restaurant/FoodThirdCatagory';
import ServiceReservation from '@app/pages/Hotel/ServiceReservation/ServiceReservation';
import HouseKeeperCard from '@app/pages/houseKeeping/HouseKeepercard/HouseKeeperCard';
import StockRequest from '@app/pages/houseKeeping/Trolly/StockRequest';
import ServiceReservationHistory from '@app/pages/Hotel/ServiceReservation/ServiceReservationHistory';
import {withoutLoading} from '@app/hocs/withoutLoading.hoc';

import {useAppSelector} from '@app/hooks/reduxHooks';
import {
  HOTEL_ADMIN_MODULE_NAME,
  HOTEL_SERVICE_MODULE_NAME,
  HOUSEKEEPING_ADMIN_MODULE_NAME,
  INVENTORY_SERVICE_MODULE_NAME,
  RESTAURANT_ADMIN_MODULE_NAME,
  modulePermission,
} from '@app/utils/permissions';
import VerificationScreen from '@app/pages/VerificationScreen';

const NewsFeedPage = React.lazy(() => import('@app/pages/NewsFeedPage'));
const KanbanPage = React.lazy(() => import('@app/pages/KanbanPage'));
const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const Error403Page = React.lazy(() => import('@app/pages/Error403Page'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));
const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));
const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
const GoogleMaps = React.lazy(() => import('@app/pages/maps/GoogleMapsPage/GoogleMapsPage'));
const LeafletMaps = React.lazy(() => import('@app/pages/maps/LeafletMapsPage/LeafletMapsPage'));
const ReactSimpleMaps = React.lazy(() => import('@app/pages/maps/ReactSimpleMapsPage/ReactSimpleMapsPage'));
const PigeonsMaps = React.lazy(() => import('@app/pages/maps/PigeonsMapsPage/PigeonsMapsPage'));
const Logout = React.lazy(() => import('./Logout'));

// Master Pages
const CurrencyPage = React.lazy(() => import('@app/pages/MasterPages/hotel/Currency/CurrencyPage'));
const RoomPage = React.lazy(() => import('@app/pages/MasterPages/hotel/Room/room/RoomPage'));
const RoomViewTypePage = React.lazy(() => import('@app/pages/MasterPages/hotel/Room/roomViewType/RoomViewType'));
const GuestPage = React.lazy(() => import('@app/pages/MasterPages/hotel/Guest/GuestPage'));
const RoomStayTypePage = React.lazy(() => import('@app/pages/MasterPages/hotel/Room/StayType/RoomStayType'));
const RoomTypePage = React.lazy(() => import('@app/pages/MasterPages/hotel/Room/roomType/RoomType'));
const SeasonPage = React.lazy(() => import('@app/pages/MasterPages/hotel/Season/SeasonPage'));
const TaxPage = React.lazy(() => import('@app/pages/MasterPages/hotel/Tax/TaxPage'));
const RoomOfferPricePage = React.lazy(() => import('@app/pages/MasterPages/hotel/Room/RoomPrice/RoomOfferPrice'));
const AmenitiesPage = React.lazy(() => import('@app/pages/MasterPages/hotel/Room/Amenities/Amenities'));
// const SeasonalPriceChangePage = React.lazy(
//   () => import('@app/pages/MasterPages/hotel/Room/SeasonalPrice/SeasonalPricePage'),
// );
const ChildPolicyPage = React.lazy(() => import('@app/pages/MasterPages/hotel/ChildPolicy/ChildPolicyPage'));
const ReservationTypePage = React.lazy(
  () => import('@app/pages/MasterPages/hotel/ReservationType/ReservationTypePage'),
);
const OnlineRateChangerPage = React.lazy(
  () => import('@app/pages/MasterPages/hotel/Room/onlineRateChanger/OnlineRateChangerPage'),
);
const PaxPage = React.lazy(() => import('@app/pages/MasterPages/hotel/Room/pax/PaxPage'));
const RoomAreaPage = React.lazy(() => import('@app/pages/MasterPages/hotel/Room/RoomAreas/RoomAreaPage'));

// channel master
const NewChannelPage = React.lazy(() => import('@app/pages/MasterPages/hotel/Channel/newChannel/NewChannelPage'));
const NewChannelRequsestPage = React.lazy(
  () => import('@app/pages/MasterPages/hotel/Channel/newChannel/ChannelRequestPage'),
);
const ChannelPricePage = React.lazy(() => import('@app/pages/MasterPages/hotel/Channel/channelPrice/ChannelPricePage'));
const SeasonalPricePage = React.lazy(() => import('@app/pages/MasterPages/hotel/Channel/seasonalPrice/SeasonalPage'));
const DayPricePage = React.lazy(() => import('@app/pages/MasterPages/hotel/Channel/dayPrice/DayPricePage'));
const NewCalendarPricePage = React.lazy(
  () => import('@app/pages/MasterPages/hotel/Channel/NewCalendarPrice/NewCalendarPrice'),
);
const GuidePage = React.lazy(() => import('@app/pages/MasterPages/hotel/Channel/guide/GuidePage'));
const InvoicePage = React.lazy(() => import('@app/pages/MasterPages/hotel/Invoice/InvoicePage'));
const AdditionalBillingPage = React.lazy(
  () => import('@app/pages/MasterPages/hotel/Invoice/AdditionalBilling/AdditionalBillingPage'),
);

const AdditionalEventInvoicePage = React.lazy(
  () => import('@app/pages/MasterPages/hotel/Invoice/additionalEvents/AdditionalEvents'),
);

const InvoiceHistoryPage = React.lazy(
  () => import('@app/pages/MasterPages/hotel/Invoice/InvoiceHistory/InvoiceHistoryPage'),
);
const AdditionalServicesPage = React.lazy(
  () => import('@app/pages/MasterPages/hotel/Invoice/AdditionalServices/AdditionalServicesPage'),
);
const DriverPage = React.lazy(() => import('@app/pages/MasterPages/hotel/Channel/driver/DriverPage'));
const OnlineChannelPage = React.lazy(
  () => import('@app/pages/MasterPages/hotel/Channel/onlineChannel/OnlineChannelPage'),
);

const CreateAdditionalActivityServicePage = React.lazy(
  () => import('@app/pages/Hotel/AdditionalActivity/createService/CreateService'),
);
const CreateAdditionalActivityBookingPage = React.lazy(
  () => import('@app/pages/Hotel/AdditionalActivity/createBooking/CreateBooking'),
);
const HistoryAdditionalActivityBookingPage = React.lazy(
  () => import('@app/pages/Hotel/AdditionalActivity/bookingHistory/HistoryBooking'),
);

const CreateActivityPackPage = React.lazy(() => import('@app/pages/Hotel/AdditionalActivity/pack/CreatePack'));

// Hall Master
const HallPage = React.lazy(() => import('@app/pages/MasterPages/hotel/Room/hall/HallPage'));

//Group Admin Pages
const HotelPage = React.lazy(() => import('@app/pages/MasterPages/hotel/groupAdmin/hotel/HotelPage'));
const HotelServicePage = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/hotelService/HotelServicePage'),
);
const GroupPage = React.lazy(() => import('@app/pages/MasterPages/hotel/groupAdmin/group/GroupPage'));
const GroupLevelHomePage = React.lazy(() => import('@app/pages/MasterPages/hotel/groupAdmin/group/GroupLevelHome'));
const EmployeeContent = React.lazy(() => import('@app/pages/MasterPages/hotel/groupAdmin/employee/EmployeePage'));
const SystemconfigContent = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/systemconfig/paymentconfiig/SystemconfigPage'),
);
const TermConditionContent = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/systemconfig/termAndCondition/TermAndConditionPage'),
);
const MailCofigContent = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/systemconfig/emailConfig/MailConfigPage'),
);
const PaymentTimeout = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/systemconfig/paymenttimeout/PaymenttimeoutPage'),
);
const LeadType = React.lazy(() => import('@app/pages/MasterPages/hotel/leadType/LeadTypePage'));
const AuditLog = React.lazy(() => import('@app/pages/Hotel/AuditLog/AuditLog'));
const IdleTimeoutPage = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/systemconfig/idleTimeout/IdleTimeOut'),
);
const ReservationConfigPage = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/systemconfig/reservationConfig/ReservationConfig'),
);
const UserContent = React.lazy(() => import('@app/pages/MasterPages/hotel/groupAdmin/user/UserPage'));
const RoleContent = React.lazy(() => import('@app/pages/MasterPages/hotel/groupAdmin/role/AddRole'));
const GroupRoleContent = React.lazy(() => import('@app/pages/MasterPages/hotel/groupAdmin/groupRole/GroupRolePage'));
const HotelRoleContent = React.lazy(() => import('@app/pages/MasterPages/hotel/groupAdmin/hotelRole/HotelRolePage'));
const ServiceRoleContent = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/serviceRole/AddServiceRole'),
);
const UserPrivilege = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/privilege/userPrivilege/UserPrivilege'),
);
const RolePrivilege = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/privilege/serviceRolePrivilege/ServiceRolePrivilege'),
);
const HotelRolePrivilege = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/privilege/hotelRolePrivilege/HotelRolePrivilege'),
);
const GroupRolePrivilege = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/privilege/groupRolePrivilege/GroupRolePrivilege'),
);
const GroupPrivilege = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/privilege/groupPrivilege/GroupPrivilege'),
);
const HotelPrivilege = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/privilege/hotelPrivilege/HotelPrivilege'),
);
const ServicePrivilege = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/privilege/servicePrivilege/ServicePrivilege'),
);
const Promotion = React.lazy(() => import('@app/pages/MasterPages/hotel/Promotions/Promotions'));
const PaymentTransactionsPage = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/paymentTransactions/PaymentTransactions'),
);
const UserAllocation = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/userAllocation/UserAllocation'),
);
const AdminPrivilege = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/privilege/adminPrivilege/AdminPrivilege'),
);
const ProductAdminPrivilege = React.lazy(
  () => import('@app/pages/MasterPages/hotel/groupAdmin/privilege/productAdminPrivilege/ProductAdminPrivilege'),
);
//Inventory master pages
const LocationPage = React.lazy(() => import('@app/pages/MasterPages/inventory/locations/LocationPage'));
const OpeingStockPage = React.lazy(() => import('@app/pages/MasterPages/inventory/opeingStock/StockPage'));
const InventroyCategoryPage = React.lazy(() => import('@app/pages/MasterPages/inventory/category/CategoryPage'));
const InventroyMainCategoryPage = React.lazy(
  () => import('@app/pages/MasterPages/inventory/mainCategory/MainCategoryPage'),
);
const InventroySubCategoryPage = React.lazy(
  () => import('@app/pages/MasterPages/inventory/subCategory/SubCategoryPage'),
);
const UnitGroupPage = React.lazy(() => import('@app/pages/MasterPages/inventory/unitGroup/UnitGroupPage'));
const UnitPage = React.lazy(() => import('@app/pages/MasterPages/inventory/unit/UnitPage'));
const UnitConversionPage = React.lazy(
  () => import('@app/pages/MasterPages/inventory/unitConversion/UnitConversionPage'),
);
const InventoryServicePage = React.lazy(
  () => import('@app/pages/MasterPages/inventory/inventoryAdmin/InventoryServicePage'),
);
const SupplierPage = React.lazy(() => import('@app/pages/MasterPages/inventory/supplier/SupplierPage'));
const InventoryItemPage = React.lazy(() => import('@app/pages/MasterPages/inventory/item/ItemPage'));
const PurchaseOrderPage = React.lazy(() => import('@app/pages/MasterPages/inventory/purchaseOrder/PurchaseOrder'));
const GRNHistoryPage = React.lazy(() => import('@app/pages/MasterPages/inventory/grn/GrnHistory'));
const CreateGrnPage = React.lazy(() => import('@app/pages/MasterPages/inventory/grn/CreateGrn'));
const StockTransferPage = React.lazy(() => import('@app/pages/MasterPages/inventory/stockTransfer/StockTransfer'));
const StockTransferHistoryPage = React.lazy(
  () => import('@app/pages/MasterPages/inventory/stockTransfer/StockTransferHistory'),
);
const TrollyRequestPage = React.lazy(() => import('@app/pages/MasterPages/inventory/trollyRequest/TrollyRequest'));
const TrollyRequestHistoryPage = React.lazy(
  () => import('@app/pages/MasterPages/inventory/trollyRequest/trolleyRequestHistory'),
);

const InventoryReportPage = React.lazy(
  () => import('@app/pages/MasterPages/inventory/inventoryReport/InventoryReportPage'),
);

//Restaurant master pages
const RestaurantAdminDashBoardPage = React.lazy(() => import('@app/pages/DashboardPages/RestaurantAdminDashBoard'));
const TableCategoryPage = React.lazy(() => import('@app/pages/Restaurant/tableCategory/TableCategoryPage'));
const TablePage = React.lazy(() => import('@app/pages/Restaurant/table/TablePage'));
const Category = React.lazy(() => import('@app/pages/Restaurant/Category/CategoryPage'));
const ManageFoodPage = React.lazy(() => import('@app/pages/Restaurant/FoodItems/ManageFoods'));
const ManageInvoice = React.lazy(() => import('@app/pages/Restaurant/RestaurantInvoice/RestInvoicePage'));
const ManageRestTax = React.lazy(() => import('@app/pages/Restaurant/Tax/RestTaxPage'));
const RestaurantVatRegistry = React.lazy(() => import('@app/pages/Restaurant/VatRegistry/VatRegistryPage'));

// Reservation
const RoomReservationPage = React.lazy(() => import('@app/pages/Hotel/RoomReservation/RoomReservationPage'));
const RoomStatusOverviewPage = React.lazy(() => import('@app/pages/Hotel/RoomStatusOverview/RoomStatusOverview'));

// Refactored Reservation
const ReservationPage = React.lazy(() => import('@app/pages/Hotel/RoomReservation/Reservation'));
const ReservationCancellationPolicy = React.lazy(
  () => import('@app/pages/Hotel/RoomReservation/CancellationPolicy/CancellationPolicy'),
);
const ManageReservationPage = React.lazy(
  () => import('@app/pages/Hotel/RoomReservation/ManageReservation/ManageReservation'),
);
const ReservationHistoryPage = React.lazy(
  () => import('@app/pages/Hotel/RoomReservation/ReservationHistory/ReservationHistory'),
);

// Reservations
const ReservationsPage = React.lazy(() => import('@app/pages/Hotel/RoomReservation/Reservations/Reservations'));
const RoomOverview = React.lazy(() => import('@app/pages/Hotel/Dashboard/RoomsOverview/RoomOverviewTap'));
const NotFound = React.lazy(() => import('@app/pages/Error404Page'));
const RestaurantCategoryAllocation = React.lazy(() => import('@app/pages/Restaurant/CategoryTree/CategoryTree'));

//start House Keeping
const HouseKeeperPage = React.lazy(() => import('@app/pages/houseKeeping/masterPage/houseKeeper/HouseKeeperPage'));
const TrolleyPage = React.lazy(() => import('@app/pages/houseKeeping/masterPage/trolley/Trolley'));
const TrolleyAllocation = React.lazy(
  () => import('@app/pages/houseKeeping/masterPage/trolleyAllocation/trolleyallocation'),
);
const CleaningPage = React.lazy(() => import('@app/pages/houseKeeping/Cleaning/Cleaning'));
const CheckListActionPage = React.lazy(
  () => import('@app/pages/houseKeeping/masterPage/checkListActions/CheckListActionPage'),
);
const CheckListPage = React.lazy(() => import('@app/pages/houseKeeping/masterPage/checkList/CheckListPage'));
const CleaningProgressPage = React.lazy(
  () => import('@app/pages/houseKeeping/Cleaning/checkedOutRooms/steps/CleaningProgress'),
);
const CleaningRoomPage = React.lazy(
  () => import('@app/pages/houseKeeping/Cleaning/checkedOutRooms/steps/CleaningRooms'),
);

const CleaningOccupiedRoomPage = React.lazy(
  () => import('@app/pages/houseKeeping/Cleaning/occupiedRooms/steps/CleaningOccupiedRooms'),
);
const CleaningProgressOccupiedRoomPage = React.lazy(
  () => import('@app/pages/houseKeeping/Cleaning/occupiedRooms/steps/CleaningOccupiedProgress'),
);
const LaundryPage = React.lazy(() => import('@app/pages/houseKeeping/Laundry/Laundry'));
const DeliveryPage = React.lazy(() => import('@app/pages/houseKeeping/Laundry/delivery/Delivery'));
const ReceivePage = React.lazy(() => import('@app/pages/houseKeeping/Laundry/receive/Receive'));
const CleaningRequestPage = React.lazy(
  () => import('@app/pages/houseKeeping/masterPage/cleaningRequest/CleaningRequest'),
);
const OnDemandRoomsPage = React.lazy(() => import('@app/pages/houseKeeping/Cleaning/onDemandRooms/OnDemandRooms'));

// Website Bookings
const WebsiteServicePage = React.lazy(() => import('@app/pages/Hotel/WebsiteService/WebsiteService'));
const CustomizedWebServicePage = React.lazy(
  () => import('@app/pages/Hotel/WebsiteService/customizedBookingEngine/WebsiteService'),
);
const SearchEnginePage = React.lazy(
  () => import('@app/pages/Hotel/WebsiteService/customizedBookingEngine/searchEngine/SearchEngine'),
);
const SecureBookingPaymentPayment = React.lazy(() => import('@app/pages/Hotel/WebsiteService/payment/Payment'));
const BookingPaymentReturnPage = React.lazy(() => import('@app/pages/Hotel/WebsiteService/payment/ReturnPage'));
const WebsiteBookingPaymentReturnPage = React.lazy(
  () => import('@app/pages/Hotel/WebsiteService/payment/WebsiteReturnPage'),
);
const TimeOutPage = React.lazy(() => import('@app/pages/Hotel/WebsiteService/payment/TimeOutPage'));
const CancelledPage = React.lazy(() => import('@app/pages/Hotel/WebsiteService/payment/CancelledPage'));
const DummyWebsitePage = React.lazy(() => import('@app/pages/Hotel/WebsiteService/DummyWebsite'));
const PalmyrahDummyWebsitePage = React.lazy(() => import('@app/pages/Hotel/WebsiteService/PalmyrahWebsite'));
const MiddleDummyWebsitePage = React.lazy(() => import('@app/pages/Hotel/WebsiteService/MiddleWebsite'));
const LillyPodDummyWebsitePage = React.lazy(() => import('@app/pages/Hotel/WebsiteService/LilyPodWebsite'));
const DendrobiumDummyWebsitePage = React.lazy(() => import('@app/pages/Hotel/WebsiteService/DendWebsite'));
const MailPaymentPage = React.lazy(() => import('@app/pages/Hotel/WebsiteService/payment/MailPayment'));

//end House Keeping

//reports
const ReportGeneratePage = React.lazy(() => import('@app/pages/Hotel/Reports/HotelReports/ReportGeneratePage'));

//notification
const NotificationPage = React.lazy(() => import('@app/pages/Hotel/Notification/ViewNotification'));
// Vat registry
const HotelVatRegistry = React.lazy(() => import('@app/pages/MasterPages/hotel/VatRegistry/VatRegistryPage'));
const HotelVatRegistryPortal = React.lazy(() => import('@app/pages/MasterPages/hotel/VatRegistry/VatRegistryPortal'));

// Inquiry Pages
const InquiryOverviewPage = React.lazy(() => import('@app/pages/Hotel/Dashboard/RoomsOverview/InquiryOverview'));
const InquiryHistoryPage = React.lazy(() => import('@app/pages/Hotel/Dashboard/Inquiry/InquiryHistory'));

export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const NewsFeed = withLoading(NewsFeedPage);
const Kanban = withLoading(KanbanPage);
const AdvancedForm = withLoading(AdvancedFormsPage);

// UI Components
const Buttons = withLoading(ButtonsPage);
const Spinners = withLoading(SpinnersPage);
const Inputs = withLoading(InputsPage);
const Checkboxes = withLoading(CheckboxesPage);
const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Switches = withLoading(SwitchesPage);
const Uploads = withLoading(UploadsPage);
const Rates = withLoading(RatesPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const Steps = withLoading(StepsPage);
const DateTimePickers = withLoading(DateTimePickersPage);
const Dropdowns = withLoading(DropdownsPage);
const Breadcrumbs = withLoading(BreadcrumbsPage);
const Tabs = withLoading(TabsPage);
const Avatars = withLoading(AvatarsPage);
const Badges = withLoading(BadgesPage);
const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);
const Modals = withLoading(ModalsPage);
const Popovers = withLoading(PopoversPage);
const Popconfirms = withLoading(PopconfirmsPage);
const Progress = withLoading(ProgressPage);
const Results = withLoading(ResultsPage);
const Alerts = withLoading(AlertsPage);
const NotificationsUI = withLoading(NotificationsUIPage);
const Skeletons = withLoading(SkeletonsPage);

const DataTables = withLoading(DataTablesPage);
const Charts = withLoading(ChartsPage);

// Maps
const Google = withLoading(GoogleMaps);
const Leaflet = withLoading(LeafletMaps);
const ReactSimple = withLoading(ReactSimpleMaps);
const Pigeons = withLoading(PigeonsMaps);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);
const Error403 = withLoading(Error403Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

// Master Data
const Currency = withLoading(CurrencyPage);
const Room = withLoading(RoomPage);
const Amenities = withLoading(AmenitiesPage);
const RoomViewType = withLoading(RoomViewTypePage);
const Guest = withLoading(GuestPage);
const RoomStayType = withLoading(RoomStayTypePage);
const RoomType = withLoading(RoomTypePage);
const RoomOfferPrice = withLoading(RoomOfferPricePage);
const Tax = withLoading(TaxPage);
const ChildPolicy = withLoading(ChildPolicyPage);
const ReservationType = withLoading(ReservationTypePage);
const OnlineRateChanger = withLoading(OnlineRateChangerPage);
const Invoice = withLoading(InvoicePage);
const AdditionalBilling = withLoading(AdditionalBillingPage);
const AdditionalEventInvoice = withLoading(AdditionalEventInvoicePage);
const InvoiceHistory = withLoading(InvoiceHistoryPage);
const AdditionalServices = withLoading(AdditionalServicesPage);
const Driver = withLoading(DriverPage);
const Pax = withLoading(PaxPage);
const OnlineChannel = withLoading(OnlineChannelPage);
const Season = withLoading(SeasonPage);
const RoomArea = withLoading(RoomAreaPage);

const CreateAdditionalActivityService = withLoading(CreateAdditionalActivityServicePage);
const CreateAdditionalActivityBooking = withLoading(CreateAdditionalActivityBookingPage);
const CreateActivityPack = withLoading(CreateActivityPackPage);
const HistoryAdditionalActivityBooking = withLoading(HistoryAdditionalActivityBookingPage);

// Channel Master
const NewChannel = withLoading(NewChannelPage);
const ChannelRequest = withLoading(NewChannelRequsestPage);
const ChannelPrice = withLoading(ChannelPricePage);
const SeasonalPrice = withLoading(SeasonalPricePage);
const DayPrice = withLoading(DayPricePage);
const Guide = withLoading(GuidePage);
const NewCalendarPrice = withLoading(NewCalendarPricePage);

//Group Admin
const ManageHotel = withLoading(HotelPage);
const ManageHotelService = withLoading(HotelServicePage);
const ManageGroup = withLoading(GroupPage);
const GroupLevelHome = withLoading(GroupLevelHomePage);
const ManageEmployee = withLoading(EmployeeContent);
const ManageSystemconfig = withLoading(SystemconfigContent);
const LeadTypeScreen = withLoading(LeadType);
const AuditLogPage = withLoading(AuditLog);
const ManageTermConditionConfig = withLoading(TermConditionContent);
const ManageMailConfigConfig = withLoading(MailCofigContent);
const ManagePaymentTimeout = withLoading(PaymentTimeout);
const IdleTimeout = withLoading(IdleTimeoutPage);
const ReservationConfig = withLoading(ReservationConfigPage);
const ManageUser = withLoading(UserContent);
const Role = withLoading(RoleContent);
const GroupRole = withLoading(GroupRoleContent);
const HotelRole = withLoading(HotelRoleContent);
const ServiceRole = withLoading(ServiceRoleContent);
const ManageUserPrivilege = withLoading(UserPrivilege);
const ManageRolePrivilege = withLoading(RolePrivilege);
const ManageHotelRolePrivilege = withLoading(HotelRolePrivilege);
const ManageGroupRolePrivilege = withLoading(GroupRolePrivilege);
const ManageGroupPrivilege = withLoading(GroupPrivilege);
const ManageHotelPrivilege = withLoading(HotelPrivilege);
const ManageServicePrivilege = withLoading(ServicePrivilege);
const ManageUserAllocation = withLoading(UserAllocation);
const ManageAdminPrivilege = withLoading(AdminPrivilege);
const ManageProductAdminPrivilege = withLoading(ProductAdminPrivilege);
const PaymentTransactions = withLoading(PaymentTransactionsPage);
const PromotionScreen = withLoading(Promotion);

// Reservation
const RoomReservation = withLoading(RoomReservationPage);
const RoomStatusOverview = withLoading(RoomStatusOverviewPage);

// Refactored Resrvation
const Reservation = withLoading(ReservationPage);
const CancellationPolicy = withLoading(ReservationCancellationPolicy);
const ManageReservation = withLoading(ManageReservationPage);
const ReservationHistory = withLoading(ReservationHistoryPage);

// Reservations
const Reservations = withLoading(ReservationsPage);

//Dashboard
const RestaurantDashBoard = withLoading(RestaurantDashBoardPage);
const HotelDashBoard = withLoading(HotelDashBoardPage);
const RoomsOverview = withLoading(RoomOverview);
const RestaurantInventoryDashBoard = withLoading(InventoryDashboard);
const ChefRestaurantDashBoard = withLoading(ChefDashBoard);
const TableDashBoard = withLoading(TableBoardPage);
const RestaurantAdminDashBoard = withLoading(RestaurantAdminDashBoardPage);

//Inventory master pages
const InventoryLocationPage = withLoading(LocationPage);
const InventoryOpeingStockPage = withLoading(OpeingStockPage);
const InventroyCategory = withLoading(InventroyCategoryPage);
const InventroyMainCategory = withLoading(InventroyMainCategoryPage);
const InventroySubCategory = withLoading(InventroySubCategoryPage);
const UnitGroup = withLoading(UnitGroupPage);
const Unit = withLoading(UnitPage);
const UnitConversion = withLoading(UnitConversionPage);
const InventoryService = withLoading(InventoryServicePage);
const Supplier = withLoading(SupplierPage);
const InventoryItem = withLoading(InventoryItemPage);
const PurchaseOrder = withLoading(PurchaseOrderPage);
const GRNHistory = withLoading(GRNHistoryPage);
const CreateGrn = withLoading(CreateGrnPage);
const StockTransfer = withLoading(StockTransferPage);
const StockTransferHistory = withLoading(StockTransferHistoryPage);
const TrollyRequest = withLoading(TrollyRequestPage);
const TrollyRequestHistory = withLoading(TrollyRequestHistoryPage);
const InventoryReport = withLoading(InventoryReportPage);

//Restaurant master pages
const RestaurantTableCategoryPage = withLoading(TableCategoryPage);
const RestaurantTablePage = withLoading(TablePage);
const CategoryPage = withLoading(Category);
const RestaurantCategoryAllocationPage = withLoading(RestaurantCategoryAllocation);
const ManageFoods = withLoading(ManageFoodPage);
const RestInvoice = withLoading(ManageInvoice);
const ManageRestaurantTax = withLoading(ManageRestTax);
const RestaurantVatRegistryPage = withLoading(RestaurantVatRegistry);
const NotFoundPage = withLoading(NotFound);

// Hall Master
const Hall = withLoading(HallPage);

//start Master House Keeping
const HouseKeeper = withLoading(HouseKeeperPage);
const Trolley = withLoading(TrolleyPage);
const Trolleyitem = withLoading(TrolleyAllocation);
const CleaningRequest = withLoading(CleaningRequestPage);

const Cleaning = withLoading(CleaningPage);
const CheckListAction = withLoading(CheckListActionPage);
const CheckList = withLoading(CheckListPage);
const CleaningProgress = withLoading(CleaningProgressPage);
const CleaningRoom = withLoading(CleaningRoomPage);
const CleaningOccupiedRooms = withLoading(CleaningOccupiedRoomPage);
const CleaningProgressOccupiedRooms = withLoading(CleaningProgressOccupiedRoomPage);
const Delivery = withLoading(DeliveryPage);
const Laundry = withLoading(LaundryPage);
const Receive = withLoading(ReceivePage);
const OnDemandRooms = withLoading(OnDemandRoomsPage);

// Website Booking
const DummyWebsite = withLoading(DummyWebsitePage);
const PalmyrahDummyWebsite = withLoading(PalmyrahDummyWebsitePage);
const MiddleDummyWebsite = withLoading(MiddleDummyWebsitePage);
const LillyPodDummyWebsite = withLoading(LillyPodDummyWebsitePage);
const DendrobiumDummyWebsite = withLoading(DendrobiumDummyWebsitePage);
const WebsiteService = withoutLoading(WebsiteServicePage);
const CustomizedWebService = withoutLoading(CustomizedWebServicePage);
const SearchEngine = withoutLoading(SearchEnginePage);
const SecureBookingPayment = withoutLoading(SecureBookingPaymentPayment);
const BookingPaymentReturn = withoutLoading(BookingPaymentReturnPage);
const WebsiteBookingPaymentReturn = withoutLoading(WebsiteBookingPaymentReturnPage);
const TimeOutScreen = withoutLoading(TimeOutPage);
const CancelledScreen = withoutLoading(CancelledPage);
const MailPaymentScreen = withoutLoading(MailPaymentPage);
//end House Keeping

//reports
const ReportGenerate = withoutLoading(ReportGeneratePage);

//notification
const ViewNotification = withoutLoading(NotificationPage);
// Vat registry
const HotelVatRegistryScreen = withLoading(HotelVatRegistry);
const HotelVatRegistryPortalScreen = withLoading(HotelVatRegistryPortal);

// Inquiry
const InquiryOverview = withLoading(InquiryOverviewPage);
const InquiryHistory = withLoading(InquiryHistoryPage);

export const AppRouter: React.FC = () => {
  const userPermission = useAppSelector(state => state.user.permissions);

  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  const useRequireAuth = (moduleName: string) => {
    const permission = modulePermission(userPermission, moduleName);

    const isAuthenticated = true;
    const hasRequiredPrivilege: boolean = permission.VIEW;

    return isAuthenticated && hasRequiredPrivilege;
  };

  return (
    <Routes>
      <Route
        path="/category"
        element={
          <RequireAuth>
            <TopLevelLayout />
          </RequireAuth>
        }>
        <Route
          path="main"
          element={useRequireAuth(RESTAURANT_ADMIN_MODULE_NAME.FOOD_MENU) ? <FoodCatagoryTop /> : <Error403 />}
        />
        <Route path="food/:cat" element={<FoodCatagoryFoodLevel />} />
        <Route path="food/cat/:cat" element={<FoodThirdCatagory />} />
      </Route>

      <Route
        path="/restaurant"
        element={
          <RequireAuth>
            <TopLevelLayout />
          </RequireAuth>
        }>
        <Route path="dashboard/:cat" element={<RestaurantDashBoard />} />
      </Route>

      <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>
        {/* inventory pages routes */}
        <Route
          path="inventory/inventory-dashboard"
          element={
            useRequireAuth(INVENTORY_SERVICE_MODULE_NAME.INVENTORY_DASHBOARD) ? (
              <RestaurantInventoryDashBoard />
            ) : (
              <Error403 />
            )
          }
        />

        <Route path="inventory/item-master">
          <Route
            path="category"
            element={
              useRequireAuth(INVENTORY_SERVICE_MODULE_NAME.INVENTORY_DASHBOARD) ? <InventroyCategory /> : <Error403 />
            }
          />
          <Route
            path="main-category"
            element={
              useRequireAuth(INVENTORY_SERVICE_MODULE_NAME.MAIN_CATEGORY) ? <InventroyMainCategory /> : <Error403 />
            }
          />
          <Route
            path="sub-category"
            element={
              useRequireAuth(INVENTORY_SERVICE_MODULE_NAME.SUB_CATEGORY) ? <InventroySubCategory /> : <Error403 />
            }
          />
          <Route
            path="item"
            element={useRequireAuth(INVENTORY_SERVICE_MODULE_NAME.INVENTORY_ITEM) ? <InventoryItem /> : <Error403 />}
          />
          <Route
            path="unit-type"
            element={useRequireAuth(INVENTORY_SERVICE_MODULE_NAME.UNIT_TYPE) ? <UnitGroup /> : <Error403 />}
          />
          <Route
            path="unit"
            element={useRequireAuth(INVENTORY_SERVICE_MODULE_NAME.INVENTORY_UNIT) ? <Unit /> : <Error403 />}
          />
          <Route
            path="unit-conversion"
            element={
              useRequireAuth(INVENTORY_SERVICE_MODULE_NAME.INVENTORY_UNIT_CONVERSION) ? (
                <UnitConversion />
              ) : (
                <Error403 />
              )
            }
          />
        </Route>

        <Route path="inventory/stock-management">
          <Route
            path="purchase-order"
            element={useRequireAuth(INVENTORY_SERVICE_MODULE_NAME.PURCHASE_ORDER) ? <PurchaseOrder /> : <Error403 />}
          />
          <Route path="grn-history" element={<GRNHistory />} />
          <Route
            path="create-grn"
            element={useRequireAuth(INVENTORY_SERVICE_MODULE_NAME.GRN) ? <CreateGrn /> : <Error403 />}
          />
          <Route path="locations" element={<InventoryLocationPage />} />
          <Route
            path="opeingstock"
            element={useRequireAuth(INVENTORY_SERVICE_MODULE_NAME.STOCK) ? <InventoryOpeingStockPage /> : <Error403 />}
          />
          <Route
            path="stock-transfer"
            element={useRequireAuth(INVENTORY_SERVICE_MODULE_NAME.STOCK_TRANSFER) ? <StockTransfer /> : <Error403 />}
          />
          <Route
            path="stock-transfer-history"
            element={
              useRequireAuth(INVENTORY_SERVICE_MODULE_NAME.STOCK_TRANSFER_HISTORY) ? (
                <StockTransferHistory />
              ) : (
                <Error403 />
              )
            }
          />
          <Route
            path="trolly-request"
            element={useRequireAuth(INVENTORY_SERVICE_MODULE_NAME.TROLLY_REQUEST) ? <TrollyRequest /> : <Error403 />}
          />
          <Route
            path="trolly-request-history"
            element={
              useRequireAuth(INVENTORY_SERVICE_MODULE_NAME.TROLLY_REQUEST_HISTORY) ? (
                <TrollyRequestHistory />
              ) : (
                <Error403 />
              )
            }
          />
        </Route>
        <Route
          path="inventory/supplier"
          element={useRequireAuth(INVENTORY_SERVICE_MODULE_NAME.SUPPLIER) ? <Supplier /> : <Error403 />}
        />
        <Route path="notfound" element={<NotFoundPage />} />
        <Route path="inventory">
          <Route path="inventory-service" element={<InventoryService />} />
        </Route>

        {/* inventory admin pages routes */}
        <Route path="inventory-admin">
          <Route path="inventory-service" element={<InventoryService />} />
          <Route path="inventory-report" element={true ? <InventoryReport /> : <Error403 />} />
        </Route>

        {/* restaurant admin pages routes */}
        <Route path="restaurant-admin">
          <Route
            path="dashboard"
            element={
              useRequireAuth(RESTAURANT_ADMIN_MODULE_NAME.RESTAURANT_DASHBOARD) ? (
                <RestaurantAdminDashBoard />
              ) : (
                <Error403 />
              )
            }
          />
          <Route
            path="table-category"
            element={
              useRequireAuth(RESTAURANT_ADMIN_MODULE_NAME.TABLE_CATEGORY) ? (
                <RestaurantTableCategoryPage />
              ) : (
                <Error403 />
              )
            }
          />
          <Route
            path="vat-registry"
            element={
              useRequireAuth(RESTAURANT_ADMIN_MODULE_NAME.VAT_REGISTRY) ? <RestaurantVatRegistryPage /> : <Error403 />
            }
          />
          <Route
            path="table"
            element={useRequireAuth(RESTAURANT_ADMIN_MODULE_NAME.TABLE) ? <RestaurantTablePage /> : <Error403 />}
          />
          <Route
            path="category"
            element={useRequireAuth(RESTAURANT_ADMIN_MODULE_NAME.CATEGORY) ? <CategoryPage /> : <Error403 />}
          />
          <Route
            path="item-category-allocate"
            element={
              useRequireAuth(RESTAURANT_ADMIN_MODULE_NAME.ALLOCATE_CATEGORY) ? (
                <RestaurantCategoryAllocationPage />
              ) : (
                <Error403 />
              )
            }
          />
          <Route
            path="foods"
            element={useRequireAuth(RESTAURANT_ADMIN_MODULE_NAME.FOOD_ITEMS) ? <ManageFoods /> : <Error403 />}
          />
          <Route
            path="invoice"
            element={useRequireAuth(RESTAURANT_ADMIN_MODULE_NAME.RESTAURANT_INVOICE) ? <RestInvoice /> : <Error403 />}
          />
          <Route
            path="tax"
            element={
              useRequireAuth(RESTAURANT_ADMIN_MODULE_NAME.RESTAURANT_TAX) ? <ManageRestaurantTax /> : <Error403 />
            }
          />

          <Route
            path="service-role"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE) ? <ServiceRole /> : <Error403 />}
          />
          <Route
            path="hotel-employee"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.EMPLOYEE) ? <ManageEmployee /> : <Error403 />}
          />
          <Route
            path="service-role-privilege"
            element={
              useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE_PRIVILEGE) ? <ManageRolePrivilege /> : <Error403 />
            }
          />
          <Route path="reports" element={<ReportGenerate />} />
        </Route>

        {/* restaurant admin pages routes */}
        <Route path="group-admin">
          <Route
            path="hotel"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.HOTEL) ? <ManageHotel /> : <Error403 />}
          />
          <Route
            path="hotel-service"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.HOTEL_SERVICES) ? <ManageHotelService /> : <Error403 />}
          />
          <Route
            path="hotel-group"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.GROUP) ? <ManageGroup /> : <Error403 />}
          />
          <Route path="role" element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.ROLE) ? <Role /> : <Error403 />} />
          <Route
            path="group-role"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.GROUP_ROLE) ? <GroupRole /> : <Error403 />}
          />
          <Route
            path="hotel-role"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.HOTEL_ROLE) ? <HotelRole /> : <Error403 />}
          />
          <Route
            path="service-role"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE) ? <ServiceRole /> : <Error403 />}
          />
          <Route
            path="hotel-employee"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.EMPLOYEE) ? <ManageEmployee /> : <Error403 />}
          />
          <Route
            path="hotel-systemconfig"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SYSTEMCONFIG) ? <ManageSystemconfig /> : <Error403 />}
          />

          <Route
            path="hotel-paymentTimeout"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.PAYMENT_TIMEOUT) ? <ManagePaymentTimeout /> : <Error403 />}
          />
          <Route path="hotel-idle-timeout" element={<IdleTimeout />} />
          <Route path="reservation-config" element={<ReservationConfig />} />
          <Route
            path="hotel-user"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.USER) ? <ManageUser /> : <Error403 />}
          />
          <Route
            path="hotel-user-privilege"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.USER_PRIVILEGE) ? <ManageUserPrivilege /> : <Error403 />}
          />
          <Route
            path="service-role-privilege"
            element={
              useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE_PRIVILEGE) ? <ManageRolePrivilege /> : <Error403 />
            }
          />
          <Route
            path="hotel-role-privilege"
            element={
              useRequireAuth(HOTEL_ADMIN_MODULE_NAME.HOTEL_ROLE_PRIVILEGE) ? <ManageHotelRolePrivilege /> : <Error403 />
            }
          />
          <Route
            path="group-role-privilege"
            element={
              useRequireAuth(HOTEL_ADMIN_MODULE_NAME.GROUP_ROLE_PRIVILEGE) ? <ManageGroupRolePrivilege /> : <Error403 />
            }
          />
          <Route
            path="service-privilege"
            element={
              useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SERVICE_PRIVILEGE) ? <ManageServicePrivilege /> : <Error403 />
            }
          />

          <Route path="payment-transaction" element={<PaymentTransactions />} />
          <Route
            path="group-privilege"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.GROUP_PRIVILEGE) ? <ManageGroupPrivilege /> : <Error403 />}
          />
          <Route
            path="hotel-privilege"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.HOTEL_PRIVILEGE) ? <ManageHotelPrivilege /> : <Error403 />}
          />
          <Route path="product-admin-privilege" element={<ManageProductAdminPrivilege />} />
          <Route path="user-allocation" element={<ManageUserAllocation />} />
          <Route path="admin-privilege" element={<ManageAdminPrivilege />} />

          <Route path="reports" element={<ReportGenerate />} />

          <Route
            path="term-condtion"
            element={
              useRequireAuth(HOTEL_ADMIN_MODULE_NAME.TERM_CONDITION) ? <ManageTermConditionConfig /> : <Error403 />
            }
          />

          <Route
            path="mail-config"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.MAIL_CONFIG) ? <ManageMailConfigConfig /> : <Error403 />}
          />
        </Route>

        <Route path="hotel-admin">
          <Route
            path="hotel"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.HOTEL) ? <ManageHotel /> : <Error403 />}
          />
          <Route
            path="hotel-service"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.HOTEL_SERVICES) ? <ManageHotelService /> : <Error403 />}
          />
          <Route
            path="hotel-role"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.HOTEL_ROLE) ? <HotelRole /> : <Error403 />}
          />
          <Route
            path="service-role"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE) ? <ServiceRole /> : <Error403 />}
          />
          <Route
            path="hotel-employee"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.EMPLOYEE) ? <ManageEmployee /> : <Error403 />}
          />
          <Route
            path="hotel-user"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.USER) ? <ManageUser /> : <Error403 />}
          />
          <Route
            path="hotel-user-privilege"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.USER_PRIVILEGE) ? <ManageUserPrivilege /> : <Error403 />}
          />
          <Route
            path="service-role-privilege"
            element={
              useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE_PRIVILEGE) ? <ManageRolePrivilege /> : <Error403 />
            }
          />
          <Route
            path="service-privilege"
            element={
              useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SERVICE_PRIVILEGE) ? <ManageServicePrivilege /> : <Error403 />
            }
          />
          <Route
            path="hotel-role-privilege"
            element={
              useRequireAuth(HOTEL_ADMIN_MODULE_NAME.HOTEL_ROLE_PRIVILEGE) ? <ManageHotelRolePrivilege /> : <Error403 />
            }
          />
          <Route path="reports" element={<ReportGenerate />} />
          <Route
            path="promotions"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.PROMOTIONS) ? <PromotionScreen /> : <Error403 />}
          />
        </Route>

        <Route path="service-admin">
          <Route
            path="hotel"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.HOTEL) ? <ManageHotel /> : <Error403 />}
          />
          <Route
            path="hotel-service"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.HOTEL_SERVICES) ? <ManageHotelService /> : <Error403 />}
          />
          <Route
            path="hotel-role"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.HOTEL_ROLE) ? <HotelRole /> : <Error403 />}
          />
          <Route
            path="service-role"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE) ? <ServiceRole /> : <Error403 />}
          />
          <Route
            path="hotel-employee"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.EMPLOYEE) ? <ManageEmployee /> : <Error403 />}
          />
          <Route
            path="service-role-privilege"
            element={
              useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE_PRIVILEGE) ? <ManageRolePrivilege /> : <Error403 />
            }
          />
          {/* <Route
            path="hotel-employee"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.EMPLOYEE) ? <ManageEmployee /> : <Error403 />}
          /> */}
          <Route
            path="hotel-user"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.USER) ? <ManageUser /> : <Error403 />}
          />
          <Route
            path="hotel-user-privilege"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.USER_PRIVILEGE) ? <ManageUserPrivilege /> : <Error403 />}
          />
          <Route path="reports" element={<ReportGenerate />} />
        </Route>

        {/* <Route path="inventory-group-admin">
          <Route
            path=""
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.HOTEL) ? <ManageHotel /> : <Error403 />}
          />
        </Route> */}

        <Route path="apps">
          <Route path="feed" element={<NewsFeed />} />
          <Route path="kanban" element={<Kanban />} />
        </Route>
        <Route path="forms">
          <Route path="advanced-forms" element={<AdvancedForm />} />
        </Route>
        <Route path="data-tables" element={<DataTables />} />
        <Route path="charts" element={<Charts />} />
        <Route path="maps">
          <Route path="google-maps" element={<Google />} />
          <Route path="leaflet-maps" element={<Leaflet />} />
          <Route path="react-simple-maps" element={<ReactSimple />} />
          <Route path="pigeon-maps" element={<Pigeons />} />
        </Route>
        <Route path="server-error" element={<ServerError />} />
        <Route path="404" element={<Error404 />} />
        <Route path="profile" element={<ProfileLayout />}>
          <Route path="personal-info" element={<PersonalInfo />} />
          <Route path="security-settings" element={<SecuritySettings />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="payments" element={<Payments />} />
        </Route>
        <Route path="ui-components">
          <Route path="button" element={<Buttons />} />
          <Route path="spinner" element={<Spinners />} />
          <Route path="input" element={<Inputs />} />
          <Route path="checkbox" element={<Checkboxes />} />
          <Route path="radio" element={<Radios />} />
          <Route path="select" element={<Selects />} />
          <Route path="switch" element={<Switches />} />
          <Route path="upload" element={<Uploads />} />
          <Route path="rate" element={<Rates />} />
          <Route path="auto-complete" element={<AutoCompletes />} />
          <Route path="steps" element={<Steps />} />
          <Route path="date-time-picker" element={<DateTimePickers />} />
          <Route path="dropdown" element={<Dropdowns />} />
          <Route path="breadcrumbs" element={<Breadcrumbs />} />
          <Route path="tabs" element={<Tabs />} />
          <Route path="avatar" element={<Avatars />} />
          <Route path="badge" element={<Badges />} />
          <Route path="collapse" element={<Collapse />} />
          <Route path="pagination" element={<Pagination />} />
          <Route path="modal" element={<Modals />} />
          <Route path="popover" element={<Popovers />} />
          <Route path="popconfirm" element={<Popconfirms />} />
          <Route path="progress" element={<Progress />} />
          <Route path="result" element={<Results />} />
          <Route path="alert" element={<Alerts />} />
          <Route path="notification" element={<NotificationsUI />} />
          <Route path="skeleton" element={<Skeletons />} />
        </Route>
        {/* Master Data */}
        <Route path="currency-rates">
          <Route
            path="currency-exchange"
            element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.CURRENCY_RATES) ? <Currency /> : <Error403 />}
          />
        </Route>

        {/* Hotel Service */}
        <Route
          path="/hotel/dashboard"
          element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.DASHBOARD) ? <HotelDashBoard /> : <Error403 />}
        />

        <Route path="room">
          <Route path="manage" element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.ROOM) ? <Room /> : <Error403 />} />
          <Route
            path="amenities"
            element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.AMENITIES) ? <Amenities /> : <Error403 />}
          />
          <Route
            path="room-area"
            element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.ROOM_AREA) ? <RoomArea /> : <Error403 />}
          />
          <Route
            path="view-type"
            element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.VIEW_TYPE) ? <RoomViewType /> : <Error403 />}
          />
          <Route
            path="stay-type"
            element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.STAY_TYPE) ? <RoomStayType /> : <Error403 />}
          />
          <Route
            path="room-type"
            element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.ROOM_TYPE) ? <RoomType /> : <Error403 />}
          />

          <Route path="offer-price" element={<RoomOfferPrice />} />
          <Route path="online-rate-changer" element={<OnlineRateChanger />} />
          <Route
            path="reservation-type"
            element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.RESERVATION_TYPE) ? <ReservationType /> : <Error403 />}
          />
          <Route path="pax" element={<Pax />} />
          <Route path="season" element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.SEASON) ? <Season /> : <Error403 />} />
        </Route>

        <Route path="channel">
          <Route
            path="new-channel"
            element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.CHANNEL_REGISTER) ? <NewChannel /> : <Error403 />}
          />
          <Route
            path="new-channel-request"
            element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.CHANNEL_REGISTER) ? <ChannelRequest /> : <Error403 />}
          />
          <Route path="channel-price" element={<ChannelPrice />} />
          <Route
            path="seasonal-price"
            element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.SEASONAL_PRICE) ? <SeasonalPrice /> : <Error403 />}
          />
          <Route
            path="day-price"
            element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.CHANNEL_PRICE) ? <DayPrice /> : <Error403 />}
          />
          <Route
            path="new-calendar-price"
            element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.CHANNEL_PRICE) ? <NewCalendarPrice /> : <Error403 />}
          />
          <Route path="guide" element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.GUIDE) ? <Guide /> : <Error403 />} />
          <Route path="driver" element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.DRIVER) ? <Driver /> : <Error403 />} />
          <Route path="online-channel" element={<OnlineChannel />} />
        </Route>

        <Route path="inventory">
          <Route
            path="service-role"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE) ? <ServiceRole /> : <Error403 />}
          />
          <Route
            path="hotel-employee"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.EMPLOYEE) ? <ManageEmployee /> : <Error403 />}
          />
          <Route
            path="service-role-privilege"
            element={
              useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE_PRIVILEGE) ? <ManageRolePrivilege /> : <Error403 />
            }
          />
        </Route>
        <Route path="hotel-setting">
          <Route
            path="service-role"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE) ? <ServiceRole /> : <Error403 />}
          />
          <Route
            path="hotel-employee"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.EMPLOYEE) ? <ManageEmployee /> : <Error403 />}
          />
          <Route
            path="service-role-privilege"
            element={
              useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE_PRIVILEGE) ? <ManageRolePrivilege /> : <Error403 />
            }
          />
          <Route path="reports" element={<ReportGenerate />} />
          <Route path="lead-type" element={<LeadTypeScreen />} />

          <Route path="audit-log" element={<AuditLogPage />} />
        </Route>

        <Route path="managereservation">
          <Route
            path="cancellation-policy"
            element={
              useRequireAuth(HOTEL_SERVICE_MODULE_NAME.CANCELLATION_POLICY) ? <CancellationPolicy /> : <Error403 />
            }
          />
        </Route>

        <Route path="tax">
          <Route path="tax-master" element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.TAX) ? <Tax /> : <Error403 />} />
        </Route>

        <Route
          path="vat-registry"
          element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.VAT_REGISTRY) ? <HotelVatRegistryScreen /> : <Error403 />}
        />

        <Route
          path="vat-registry-portal"
          element={
            useRequireAuth(HOTEL_SERVICE_MODULE_NAME.VAT_REGISTRY) ? <HotelVatRegistryPortalScreen /> : <Error403 />
          }
        />

        <Route path="childPolicy">
          <Route
            path="child-policy"
            element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.CHILD_POLICY) ? <ChildPolicy /> : <Error403 />}
          />
        </Route>

        <Route path="invoice">
          <Route
            path="make-invoice"
            element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.INVOICE) ? <Invoice /> : <Error403 />}
          />
          <Route
            path="additional-billing"
            element={
              useRequireAuth(HOTEL_SERVICE_MODULE_NAME.ADDITIONAL_BILLING) ? <AdditionalBilling /> : <Error403 />
            }
          />
          <Route
            path="invoice-history"
            element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.INVOICE_HISTORY) ? <InvoiceHistory /> : <Error403 />}
          />
          <Route path="additional-events" element={<AdditionalEventInvoice />} />
        </Route>

        <Route path="additional-activity">
          <Route path="create-activity" element={<CreateAdditionalActivityService />} />
          <Route path="create-activity-booking" element={<CreateAdditionalActivityBooking />} />
          <Route path="create-activity-pack" element={<CreateActivityPack />} />
          <Route path="activity-booking-history" element={<HistoryAdditionalActivityBooking />} />
        </Route>

        <Route path="additional-service">
          <Route
            path="additional-services"
            element={
              useRequireAuth(HOTEL_SERVICE_MODULE_NAME.ADDITIONAL_SERVICES) ? <AdditionalServices /> : <Error403 />
            }
          />
          <Route
            path="make-service-booking"
            element={
              useRequireAuth(HOTEL_SERVICE_MODULE_NAME.ADDITIONAL_SERVICES_BOOKING) ? (
                <ServiceReservation />
              ) : (
                <Error403 />
              )
            }
          />
          <Route path="hall" element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.HALL) ? <Hall /> : <Error403 />} />
          <Route
            path="additional-services-history"
            element={
              useRequireAuth(HOTEL_SERVICE_MODULE_NAME.ADDITIONAL_SERVICES_BOOKING_HISTORY) ? (
                <ServiceReservationHistory />
              ) : (
                <Error403 />
              )
            }
          />
        </Route>

        {/* House keeping route */}
        <Route path="housekeeping">
          <Route
            path="house-keeper"
            element={useRequireAuth(HOUSEKEEPING_ADMIN_MODULE_NAME.HOUSE_KEEPER) ? <HouseKeeper /> : <Error403 />}
          />
          <Route
            path="Trolley-item"
            element={useRequireAuth(HOUSEKEEPING_ADMIN_MODULE_NAME.TROLLEY_CREATION) ? <Trolley /> : <Error403 />}
          />
          <Route
            path="TrolleyAllocation-item"
            element={useRequireAuth(HOUSEKEEPING_ADMIN_MODULE_NAME.TROLLEY_ALLOCATION) ? <Trolleyitem /> : <Error403 />}
          />

          <Route
            path="check-list-action"
            element={
              useRequireAuth(HOUSEKEEPING_ADMIN_MODULE_NAME.CHECK_LIST_ITEMS) ? <CheckListAction /> : <Error403 />
            }
          />
          <Route
            path="check-list"
            element={useRequireAuth(HOUSEKEEPING_ADMIN_MODULE_NAME.CHECK_LIST) ? <CheckList /> : <Error403 />}
          />
          <Route path="cleaning-request" element={<CleaningRequest />} />

          <Route
            path="service-role"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE) ? <ServiceRole /> : <Error403 />}
          />
          <Route
            path="hotel-employee"
            element={useRequireAuth(HOTEL_ADMIN_MODULE_NAME.EMPLOYEE) ? <ManageEmployee /> : <Error403 />}
          />
          <Route
            path="service-role-privilege"
            element={
              useRequireAuth(HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE_PRIVILEGE) ? <ManageRolePrivilege /> : <Error403 />
            }
          />
        </Route>

        <Route path="guest" element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.GUEST) ? <Guest /> : <Error403 />} />
        <Route path="notification" element={<ViewNotification />} />
        {/* Reservation */}
        <Route
          index
          path="/create-inquiry"
          element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.CREATE_INQUIRY) ? <InquiryOverview /> : <Error403 />}
        />

        <Route
          index
          path="/inquiries"
          element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.INQUIRY_HISTORY) ? <InquiryHistory /> : <Error403 />}
        />

        <Route path="reservation">
          <Route
            index
            path="/reservation"
            element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.RESERVE_ROOMS) ? <Reservation /> : <Error403 />}
          />
        </Route>
        <Route index path="/manage-reservation" element={<ManageReservation />} />
        <Route
          index
          path="/overview"
          element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.OVERVIEW) ? <RoomsOverview /> : <Error403 />}
        />
        <Route
          index
          path="/rooms-status-overview"
          element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.ROOM_OVERVIEW) ? <RoomStatusOverview /> : <Error403 />}
        />
        <Route
          index
          path="/history"
          element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.HISTORY) ? <ReservationHistory /> : <Error403 />}
        />

        <Route
          index
          path="/reservations"
          element={useRequireAuth(HOTEL_SERVICE_MODULE_NAME.RESERVATIONS) ? <Reservations /> : <Error403 />}
        />
      </Route>

      <Route path="apps">
        <Route path="feed" element={<NewsFeed />} />
        <Route path="kanban" element={<Kanban />} />
      </Route>
      <Route path="forms">
        <Route path="advanced-forms" element={<AdvancedForm />} />
      </Route>
      <Route path="data-tables" element={<DataTables />} />
      <Route path="charts" element={<Charts />} />
      <Route path="maps">
        <Route path="google-maps" element={<Google />} />
        <Route path="leaflet-maps" element={<Leaflet />} />
        <Route path="react-simple-maps" element={<ReactSimple />} />
        <Route path="pigeon-maps" element={<Pigeons />} />
      </Route>
      <Route path="server-error" element={<ServerError />} />
      <Route path="404" element={<Error404 />} />
      <Route path="403" element={<Error403Page />} />
      <Route path="profile" element={<ProfileLayout />}>
        <Route path="personal-info" element={<PersonalInfo />} />
        <Route path="security-settings" element={<SecuritySettings />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="payments" element={<Payments />} />
      </Route>
      <Route path="ui-components">
        <Route path="button" element={<Buttons />} />
        <Route path="spinner" element={<Spinners />} />
        <Route path="input" element={<Inputs />} />
        <Route path="checkbox" element={<Checkboxes />} />
        <Route path="radio" element={<Radios />} />
        <Route path="select" element={<Selects />} />
        <Route path="switch" element={<Switches />} />
        <Route path="upload" element={<Uploads />} />
        <Route path="rate" element={<Rates />} />
        <Route path="auto-complete" element={<AutoCompletes />} />
        <Route path="steps" element={<Steps />} />
        <Route path="date-time-picker" element={<DateTimePickers />} />
        <Route path="dropdown" element={<Dropdowns />} />
        <Route path="breadcrumbs" element={<Breadcrumbs />} />
        <Route path="tabs" element={<Tabs />} />
        <Route path="avatar" element={<Avatars />} />
        <Route path="badge" element={<Badges />} />
        <Route path="collapse" element={<Collapse />} />
        <Route path="pagination" element={<Pagination />} />
        <Route path="modal" element={<Modals />} />
        <Route path="popover" element={<Popovers />} />
        <Route path="popconfirm" element={<Popconfirms />} />
        <Route path="progress" element={<Progress />} />
        <Route path="result" element={<Results />} />
        <Route path="alert" element={<Alerts />} />
        <Route path="notification" element={<NotificationsUI />} />
        <Route path="skeleton" element={<Skeletons />} />
      </Route>
      {/* Master Data */}
      <Route path="currency-rates">
        <Route path="currency-exchange" element={<Currency />} />
      </Route>

      <Route path="/auth" element={<AuthLayoutFallback />}>
        <Route path="login/:userName" element={<LoginPage />} />
        <Route path="sign-up" element={<SignUpPage />} />
        <Route
          path="lock"
          element={
            <RequireAuth>
              <LockPage />
            </RequireAuth>
          }
        />
        <Route path="forgot-password" element={<ForgotPasswordPage />} />
        <Route path="security-code" element={<SecurityCodePage />} />
        <Route path="new-password" element={<NewPasswordPage />} />
        <Route path="user-verification/:email/:token" element={<VerificationScreen />} />
        {/* <Route path="change-password" element={<ChangePasswordPage />} /> */}
      </Route>
      <Route path="/logout" element={<LogoutFallback />} />

      <Route
        path="/toplevel"
        element={
          <RequireAuth>
            <TopLevelLayout />
          </RequireAuth>
        }>
        <Route path="change-password" element={<ChangePasswordPage />} />
        <Route path="groups" element={<GroupLevelHome />} />
        <Route path="products" element={<ProductLevelHome />} />
        <Route path="services" element={<ServiceLevelHome />} />
        <Route path="inventory-services" element={<InventoryServiceLevelHome />} />
        <Route path="group-inventory-home" element={<GroupInventoryServiceLevelHome />} />
        <Route
          path="housekeepercard"
          element={useRequireAuth(HOUSEKEEPING_ADMIN_MODULE_NAME.HOUSEKEEPING) ? <HouseKeeperCard /> : <Error403 />}
        />
        <Route
          path="chef-dashboard"
          element={
            useRequireAuth(RESTAURANT_ADMIN_MODULE_NAME.CHEF_DASHBOARD) ? <ChefRestaurantDashBoard /> : <Error403 />
          }
        />
        <Route
          path="table-dashboard"
          element={useRequireAuth(RESTAURANT_ADMIN_MODULE_NAME.STUART_DASHBOARD) ? <TableDashBoard /> : <Error403 />}
        />
      </Route>

      <Route
        path="/house-keeping"
        element={
          <RequireAuth>
            <TopLevelLayout />
          </RequireAuth>
        }>
        <Route path="cleaning" element={<Cleaning />} />
        <Route path="cleaning/checked-out/progressing" element={<CleaningProgress />} />
        <Route path="cleaning/checked-out/room-selection" element={<CleaningRoom />} />
        <Route path="cleaning/occupied/room-selection" element={<CleaningOccupiedRooms />} />
        <Route path="cleaning/occupied/progressing" element={<CleaningProgressOccupiedRooms />} />
        <Route path="cleaning/ondemand-rooms" element={<OnDemandRooms />} />

        <Route path="laundry" element={<Laundry />} />
        <Route path="laundry/receive" element={<Receive />} />
        <Route path="laundry/delivery" element={<Delivery />} />
        <Route path="Trolly" element={<StockRequest />} />
      </Route>

      <Route path="/booking-engine" element={<WebsiteService />} />
      <Route path="/customized-booking-engine" element={<CustomizedWebService />} />
      <Route path="/search-engine" element={<SearchEngine />} />
      <Route path="/secure-booking-payment" element={<SecureBookingPayment />} />
      <Route path="/secure-booking-mail-payment" element={<MailPaymentScreen />} />
      <Route path="/secure-booking-payment-return" element={<BookingPaymentReturn />} />
      <Route path="/web-secure-booking-payment-return" element={<WebsiteBookingPaymentReturn />} />
      <Route path="/secure-booking-timeout" element={<TimeOutScreen />} />
      <Route path="/secure-booking-cancelled" element={<CancelledScreen />} />

      <Route path="/palmyrah-mock-website" element={<PalmyrahDummyWebsite />} />
      <Route path="/middle-street-mock-website" element={<MiddleDummyWebsite />} />
      <Route path="/villa-thuya-mock-website" element={<DummyWebsite />} />
      <Route path="/lilly-pod-mock-website" element={<LillyPodDummyWebsite />} />
      <Route path="/dendrobium-mock-website" element={<DendrobiumDummyWebsite />} />
    </Routes>
  );
};
