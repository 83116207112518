import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {
  Avatar,
  Card,
  Col,
  Descriptions,
  List,
  Row,
  Space,
  Table,
  Typography,
  Checkbox,
  Divider,
  Segmented,
  Tag,
  Tooltip,
} from 'antd';

import {useStopwatch, useTimer} from 'react-timer-hook';
import {EditOutlined, EllipsisOutlined, SettingOutlined, SyncOutlined} from '@ant-design/icons';
import {FcApproval} from 'react-icons/fc';
import {BiCheckCircle} from 'react-icons/bi';
import ChefOrderTable from './ChefOrderTable';
import {IChefOrderItem} from '@app/api/getOrders.api';
import {ColumnsType} from 'antd/lib/table';
import {Button} from '@app/components/common/buttons/Button/Button';
import {IChefOrder, IItems} from './chefMockData';
import {Option, Select} from '@app/components/common/selects/Select/Select';
import {SegmentedArea} from './DashboardPage.styles';
import {filter} from 'lodash';
import {GetResponse, ReadyToServe, ReadyToServeKot, changeStatus, readyToServeBot} from './chef.api';
import {notificationController} from '@app/controllers/notificationController';
import {ShadowedButton} from '../Restaurant/WaiterDashboard/RightArea';

interface IChefOrderCard {
  acceptedItems: IChefOrder[];
  getAllChefOrders: (item: any) => void;
  GetDashboardCounts: () => void;
}
const {Paragraph, Text} = Typography;
const ChefOrderCard: FC<IChefOrderCard> = ({acceptedItems, getAllChefOrders, GetDashboardCounts}) => {
  const [selectedTableId, setselectedTableId] = useState<number | undefined>(0);

  const columns: ColumnsType<any> = [
    {
      title: 'Item',
      dataIndex: 'itemName',
      width: 150,
      showSorterTooltip: false,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      align: 'center',
      showSorterTooltip: false,
    },
    {
      title: 'Comments',
      dataIndex: 'remarks',
      align: 'center',
      width: 150,
      render(value, record, index) {
        return <div style={{width: '150px', textAlign: 'left'}}>{value}</div>;
      },
    },

    {
      title: 'Status',
      key: 'orderedItemStatus',
      align: 'center',
      width: 150,

      render: (_, record: IItems) => (
        <Space size="middle">
          <Tag
            // icon={}
            // pos
            color={
              record.orderedItemStatus === 'ACCEPTED'
                ? 'orange'
                : record.orderedItemStatus === 'BEING_PREPARED'
                ? 'blue'
                : 'green'
            }>
            {record.orderedItemStatus === 'BEING_PREPARED'
              ? 'BEING PREPARED'
              : record.orderedItemStatus === 'READY_TO_SERVE'
              ? 'READY TO SERVE'
              : record.orderedItemStatus}
            {record.orderedItemStatus === 'BEING_PREPARED' ? (
              <SyncOutlined
                style={{
                  marginLeft: '0.5rem',
                }}
                spin
              />
            ) : (
              <></>
            )}
          </Tag>
        </Space>
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      width: 150,

      render: (_, record: IItems) => (
        <Space size="middle">
          {record.orderedItemStatus === 'ACCEPTED' ? (
            <ShadowedButton
              size="small"
              onClick={() => {
                handleStatusChange(record.id, 'BEING_PREPARED', record?.kotId);
              }}
              style={{width: '60px', fontSize: '0.8rem', height: '30px', textAlign: 'center'}}
              type="primary">
              Prepare
            </ShadowedButton>
          ) : record.orderedItemStatus === 'BEING_PREPARED' ? (
            <ShadowedButton
              size="small"
              onClick={() => {
                handleStatusChange(record.id, 'READY_TO_SERVE', record?.kotId);
              }}
              style={{
                width: '60px',
                fontSize: '0.8rem',
                height: '30px',
                border: 'none',
                background: '#52c41a',
                textAlign: 'center',
              }}
              type="primary">
              Ready
            </ShadowedButton>
          ) : (
            <BiCheckCircle color="Green" fontSize={24} />
          )}
        </Space>
      ),
    },
  ];

  const handleStatusChange = async (itemId: number, status: string, kotNo?: number) => {
    const result: GetResponse = await changeStatus(itemId, status);

    setselectedTableId(kotNo);
    // tableRef?.current.scrollIntoView({behavior: 'smooth'});
    if (result.statusCode === '20000') {
      // notificationController.success({message: result.message});
      await getAllChefOrders({});
      await GetDashboardCounts();
    } else {
      notificationController.error({message: result.message});
    }
  };

  const readyToserve = async (orderId: number, orderStatus: string, kotId: number | undefined) => {
    const data: any = {
      id: orderId,
      orderStatus: orderStatus,
      kotId: kotId,
    };

    try {
      const result: GetResponse = await ReadyToServeKot(kotId);
      if (result.statusCode === '20000') {
        notificationController.success({message: 'Order Ready to Serve'});
        await getAllChefOrders({});
        await GetDashboardCounts();
      } else {
        notificationController.error({message: result.message});
      }
    } catch (error) {}
  };

  const readyToserveBot = async (orderId: number, orderStatus: string, kotId: number | undefined) => {
    const data: any = {
      id: orderId,
      orderStatus: orderStatus,
      kotId: kotId,
    };

    try {
      const result: GetResponse = await readyToServeBot(kotId);
      if (result.statusCode === '20000') {
        notificationController.success({message: 'Order Ready to Serve'});
        await getAllChefOrders({});
        await GetDashboardCounts();
      } else {
        notificationController.error({message: result.message});
      }
    } catch (error) {}
  };

  // const isReadyToServe = (orderedItemResponseList: any) => {
  //   return orderedItemResponseList.every((item: any) => item.orderedItemStatus === 'READY TO SERVE');
  // };

  function isOdd(num: number) {
    return num % 2 === 1 ? true : false;
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
      {acceptedItems.map((order: IChefOrder, index) => {
        const totalLength = order.orderedItemResponseList.length;
        const notServeLength = filter(
          order.orderedItemResponseList,
          (res: any) => res.orderedItemStatus === 'READY_TO_SERVE' || res.orderedItemStatus === 'SERVED',
        ).length;

        const disableRedyToServe: boolean =
          totalLength > notServeLength ? true : order.kotStatus !== 'READY_TO_SERVE' ? false : true;

        const tableData = order.orderedItemResponseList.map((post: any, index: number) => {
          return {...post, tableId: order.tableId};
        });

        return (
          <Card
            key={index}
            style={{
              width: '100%',
              backgroundColor: !isOdd(index) ? '#e8e6e6' : '#fafafa',
              boxShadow: '0px 0px 8px #ccc',
              marginTop: index == 0 ? 0 : 20,
            }}>
            <div style={{padding: '0px 0px'}}>
              <Row>
                <Col span={3}>Table No :</Col>
                <Col span={3}>{order.tableNumber}</Col>
                {/* <Col span={3} hidden={order?.kotNumber ? false : true}>
                  KOT.NO :
                </Col> */}
                <Col span={4}>{order?.kotNumber && order?.kotNumber}</Col>
                {/* <Col span={3} hidden={order?.botNumber ? false : true}>
                  BOT.NO :
                </Col> */}
                <Col span={4}>{order?.botNumber && order?.botNumber}</Col>
                <Col span={2}></Col>
                {order?.orderTime && <Col span={5}>Table Time :</Col>}
                <Col span={3}>{order?.orderTime}</Col>
              </Row>
            </div>
            <div
              ref={ref => selectedTableId === order.kotId && ref?.scrollIntoView({behavior: 'smooth'})}
              style={{height: 20}}></div>
            <div>
              <Table
                style={{borderRadius: '10px', fontSize: '14px', borderColor: 'black'}}
                pagination={false}
                size="small"
                columns={columns}
                // components={}
                dataSource={tableData}
              />
            </div>
            <div style={{height: 10}}></div>
            <Row>
              <Col span={18}></Col>
              <Col span={6}>
                <div
                  style={{
                    width: '100%',
                    justifyContent: 'end',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                    }}>
                    {/* {minutes !== 0 && (
                      <div
                        style={{
                          width: 25,
                        }}>
                        {minutes} :
                      </div>
                    )}{' '}
                    {seconds !== 0 && <div>{seconds}</div>} */}
                  </div>

                  <ShadowedButton
                    size="small"
                    // ghost={true}

                    onClick={() => {
                      order.ticketType === 'BOT'
                        ? readyToserveBot(order.id, 'READY_TO_SERVE', order?.kotId)
                        : readyToserve(order.id, 'READY_TO_SERVE', order?.kotId);
                    }}
                    disabled={disableRedyToServe}
                    style={{
                      width: '75%',
                      backgroundColor: `${disableRedyToServe ? 'gray' : 'Green'}`,
                      fontSize: '1rem',
                      height: '30px',
                      border: 'none',
                      margin: '0.5rem',
                    }}
                    type="primary">
                    Ready to serve
                  </ShadowedButton>
                </div>
              </Col>
            </Row>
            {/* <ChefOrderTable tableData={order.items} onChangeAction={onClickAction} /> */}
            {/* <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                    title={<a href="https://ant.design">{item.title}</a>}
                    description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                  />
                </List.Item>
              )}
            /> */}
          </Card>
        );
      })}
    </div>
  );
};

export default React.memo(ChefOrderCard);
