import styled from 'styled-components';
import {Collapse, Card as CommonCard} from 'antd';
import {Select as CommonSelect} from '@app/components/common/selects/Select/Select';
import {Button} from '@app/components/common/buttons/Button/Button';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {BASE_COLORS, FONT_SIZE, FONT_WEIGHT} from '@app/styles/themes/constants';
import {Steps as AntdSteps} from 'antd';
import Webcam from 'react-webcam';

export const WebCamWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;
export const WebCam = styled(Webcam)`
  border-radius: 50%;
  border: 1px solid ${BASE_COLORS.primary};
  padding: 0.75rem;
`;

export const Card = styled(CommonCard)`
  width: 100%;
  margin-bottom: 1.25rem;

  .ant-card-head-title {
    font-size: 1rem;
  }
  .ant-card-body {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    align-items: center;
  }
  .ant-card-body:before {
    display: none;
  }
  .ant-card-body:after {
    display: none;
  }
  &.ant-card-bordered {
    border: 1px solid var(--border-color);
  }
`;

export const InputsWrapper = styled.div`
  width: 30rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const CollapseWrapper = styled(Collapse)`
  width: 40rem;
`;

export const PhoneItem = styled(BaseForm.Item)`
  .ant-input-group-addon {
    padding: 0;
  }

  .ant-input-group-addon > div {
    width: 100% !important;
  }
`;

export const Steps = styled(AntdSteps)`
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--primary-color);
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--subtext-color);
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    border-color: var(--subtext-color);
    & > .ant-steps-icon {
      color: var(--subtext-color);
    }
  }

  & .ant-steps-item {
    &-description,
    &-subtitle {
      color: var(--subtext-color) !important;
    }
  }
`;

export const FormContent = styled.div`
  margin: 1.25rem 0.5rem;
`;

export const PrevButton = styled(Button)`
  margin: 0 0.5rem;
`;

export const Select = styled(CommonSelect)`
  width: 5.9375rem;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin: 1.25rem 10.5rem;
`;

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DetailsTitle = styled.div`
  color: var(--text-light-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  margin-right: 0.5rem;
`;

export const DetailsValue = styled.div`
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
`;

export const Row = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
`;

export const Btn = styled(Button)`
  display: block;
  width: 20%;
`;
export const CurrencyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const TableWrapper = styled.div`
  display: flex;
  width: 100%;
`;
