import {BASE_COLORS} from '@app/styles/themes/constants';
import {Typography} from 'antd';
import styled, {keyframes} from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 1s ease;
  position: relative;
  border-radius: 50%;
  box-shadow: -1px 1px 3px -3px #d2d2d2;
`;

export const Image = styled.img`
  max-width: 100%;
  height: 85px;
  width: 85px;
  transition: opacity 1s ease;
  cursor: pointer;
  object-fit: cover;
`;
export const Text = styled(Typography)`
  font-size: 12px;
  color: ${BASE_COLORS.white};
  position: absolute;
  cursor: pointer;
  text-align: center;
`;
