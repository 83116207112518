import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';

export interface IInventoryServiceConfig {
  name: string;
  active: boolean;
  cardImage: string;
  cardLogo: string;
  serviceType: 'RESTAURANT' | 'HOTEL' | 'MAINTENANCE' | 'HOUSEKEEPING';
  hotelId: number;
  inventoryServiceId: number;
  groupId: number;
  groupInventoryServiceId?: number;
}

export interface InventoryServiceState {
  serviceConfig: IInventoryServiceConfig;
}

const initialState: InventoryServiceState = {
  serviceConfig: {
    name: '',
    active: true,
    cardImage: '',
    cardLogo: '',
    serviceType: 'HOTEL',
    hotelId: 0,
    inventoryServiceId: 0,
    groupId: 0,
    groupInventoryServiceId: 0,
  },
};

export const serviceSlice = createSlice({
  name: 'serviceConfig',
  initialState,
  reducers: {
    setServiceConfig: (state, action: PayloadAction<IInventoryServiceConfig>) => {
      state.serviceConfig = action.payload;
    },
  },
});

export const {setServiceConfig} = serviceSlice.actions;

export const serviceConfig = (state: RootState) => state.serviceSlice.serviceConfig;

export default serviceSlice.reducer;
