import styled from 'styled-components';
import {Button, Typography} from 'antd';
import {BASE_COLORS, FONT_SIZE, FONT_WEIGHT} from '@app/styles/themes/constants';

export const FormContent = styled.div``;

export const Btn = styled(Button)`
  display: block;
  width: 20%;
`;
export const CurrencyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const TableWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const PriceGroupTitle = styled.div`
  color: #505050;
  font-size: 13px;
  font-weight: 700;
  position: relative;
  top: -16px;
  width: 100%;
  text-align: center;
  margin-left: 0px;
  margin-bottom: -15px;
`;
export const ActionButton = styled.div<{$bgColor: string}>`
  height: 2rem;
  padding-left: 10px;
  padding-right: 10px;
  background-color: ${props => props.$bgColor};
  color: ${BASE_COLORS.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  align-self: center;
  min-width: 5.6rem;
  position: relative;
`;
export const NotPaidStatusTag = styled.div`
  background-color: ${BASE_COLORS.checkOutRed};
  width: 12rem;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
`;
export const PaidStatusTag = styled.div`
  background-color: ${BASE_COLORS.darkGreen};
  width: 10rem;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
`;
export const PaidStatusText = styled(Typography)`
  font-size: ${FONT_SIZE.xs};
  color: ${BASE_COLORS.white};
`;
export const ActionWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
export const InfoButton = styled.div`
  height: 2rem;
  padding-left: 8px;
  padding-right: 8px;
  background-color: ${BASE_COLORS.skyblue2};
  color: ${BASE_COLORS.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  align-self: center;
  min-width: 2.5rem;
`;
export const BlurCardWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  gap: 1rem;
`;
export const BlueCard = styled.div`
  border: 1px solid var(--border-color);
  width: 100%;
  border-radius: 8px;
  background-color: ${BASE_COLORS.lightBlue};
`;
export const Padding = styled.div`
  padding: 0.8rem;
`;
export const Title = styled(Typography.Text)`
  display: flex;
  line-height: 24px;
  font-weight: ${FONT_WEIGHT.bold};
  font-size: 16px;
  color: ${BASE_COLORS.black};
  margin-bottom: 0.3rem;
`;
export const CardTitle = styled(Typography.Text)`
  display: flex;
  line-height: 1.25rem;
  font-size: ${FONT_SIZE.md};
  color: ${BASE_COLORS.black};
  font-weight: ${FONT_WEIGHT.bold};
  margin-bottom: 0.5rem;
  align-vertical: center;
`;
export const CheckboxLabel = styled.span`
  color: ${BASE_COLORS.primary};
  font-size: 14px;
  margin-bottom: 10px;
`;
export const WhiteCard = styled.div`
  border: 1px solid var(--border-color);
  width: 100%;
  border-radius: 8px;
  background-color: ${BASE_COLORS.white};
`;

export const ServiceTitle = styled(Typography.Text)`
  font-size: 16px;
  text-align: right;
  font-weight: 500;
`;
export const ServiceTitleSmall = styled(Typography.Text)`
  font-size: 14px;
  text-align: right;
  font-weight: 500;
  color: red;
  margin-right: 1rem;
  text-decoration: line-through;
  text-decoration-color: black;
`;

export const ServiceDiscountPrice = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: red;
  position: relative;
  display: inline-block;
`;

export const TotalPriceTitle = styled(Typography.Text)`
  font-size: 16px;
  text-align: right;
  font-weight: bold;
`;
export const TotalPrice = styled(Typography.Text)`
  font-size: 16px;
  text-align: right;
  font-weight: bold;
`;
export const ServiceTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
export const ServiceCharge = styled.span`
  font-size: 14px;
`;

export const BulkCancelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  // margin-top: 1rem;
`;
