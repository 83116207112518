import {TeamOutlined, UserAddOutlined} from '@ant-design/icons';
import {HOTEL_ADMIN_MODULE_NAME} from '@app/utils/permissions';
import React from 'react';
import {BiBuilding, BiHotel, BiRocket, BiUser} from 'react-icons/bi';
import {FaServicestack} from 'react-icons/fa';
import {TbReport} from 'react-icons/tb';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  isSubMenu: boolean;
}

export const HotelAdminSidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Hotel',
    key: HOTEL_ADMIN_MODULE_NAME.HOTEL,
    url: '/hotel-admin/hotel',
    icon: <BiHotel />,
    isSubMenu: false,
  },
  {
    title: 'Hotel Services',
    key: HOTEL_ADMIN_MODULE_NAME.HOTEL_SERVICES,
    url: '/hotel-admin/hotel-service',
    icon: <FaServicestack />,
    isSubMenu: false,
  },
  {
    title: 'Hotel Role',
    key: HOTEL_ADMIN_MODULE_NAME.HOTEL_ROLE,
    url: '/hotel-admin/hotel-role',
    icon: <TeamOutlined />,
    isSubMenu: false,
  },
  {
    title: 'Service Role',
    key: HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE,
    url: '/hotel-admin/service-role',
    icon: <TeamOutlined />,
    isSubMenu: false,
  },
  {
    title: 'User',
    key: HOTEL_ADMIN_MODULE_NAME.EMPLOYEE,
    url: '/hotel-admin/hotel-employee',
    icon: <UserAddOutlined />,
    isSubMenu: false,
  },
  {
    title: 'Reports',
    key: HOTEL_ADMIN_MODULE_NAME.REPORT,
    url: '/hotel-admin/reports',
    icon: <TbReport />,
    isSubMenu: false,
  },
  // {
  //   title: 'User',
  //   key: HOTEL_ADMIN_MODULE_NAME.USER,
  //   url: '/hotel-admin/hotel-user',
  //   icon: <UserAddOutlined />,
  //   isSubMenu: false,
  // },
  {
    title: 'Hotel Role Privilege',
    key: HOTEL_ADMIN_MODULE_NAME.HOTEL_ROLE_PRIVILEGE,
    url: '/hotel-admin/hotel-role-privilege',
    icon: <BiUser />,
    isSubMenu: false,
  },
  {
    title: 'Service Role Privilege',
    key: HOTEL_ADMIN_MODULE_NAME.SERVICE_ROLE_PRIVILEGE,
    url: '/hotel-admin/service-role-privilege',
    icon: <BiUser />,
    isSubMenu: false,
  },
  // {
  //   title: 'User Privilege',
  //   key: HOTEL_ADMIN_MODULE_NAME.USER_PRIVILEGE,
  //   url: '/hotel-admin/hotel-user-privilege',
  //   icon: <BiUser />,
  //   isSubMenu: false,
  // },
  {
    title: 'Service Privilege',
    key: HOTEL_ADMIN_MODULE_NAME.SERVICE_PRIVILEGE,
    url: '/hotel-admin/service-privilege',
    icon: <BiUser />,
    isSubMenu: false,
  },
  {
    title: 'Promotions',
    key: HOTEL_ADMIN_MODULE_NAME.PROMOTIONS,
    url: '/hotel-admin/promotions',
    icon: <BiUser />,
    isSubMenu: false,
  },
];
