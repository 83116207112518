import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from './ForgotPasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import {useAppDispatch} from '@app/hooks/reduxHooks';
import {doResetPassword} from '@app/store/slices/authSlice';
import {notificationController} from '@app/controllers/notificationController';
import {forgotPassword} from '@app/api/user/user.api';
import {setLoading} from '@app/store/slices/commonSlice';

interface ForgotPasswordFormData {
  email: string;
}

const initValues = {
  email: '',
};

export const ForgotPasswordForm: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const forgotUserPassword = async (email: string) => {
    try {
      const result = await forgotPassword(email);
      setLoading(false);
      if (result.statusCode === '20000') {
        notificationController.success({message: result.message});
        navigate('/auth/security-code', {state: {userName: email}});
        // await onCloseModal();
      } else {
        notificationController.error({message: result.message});
        // dispatch(setLoading(false));
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = (values: ForgotPasswordFormData) => {
    setLoading(true);
    forgotUserPassword(values.email);
    // dispatch(doResetPassword(values))
    //   .unwrap()
    //   .then(() => {
    //     navigate('/auth/security-code');
    //   })
    //   .catch(err => {
    //     notificationController.error({message: err.message});
    //     setLoading(false);
    //   });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('common.resetPassword')}</Auth.FormTitle>
        <S.Description>{t('forgotPassword.description')}</S.Description>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[{required: true, message: t('common.emailError')}]}>
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('forgotPassword.sendInstructions')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
