/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';
import * as S from './SiderMenu.styles';
import {sidebarNavigation, SidebarNavigationItem} from '../sidebarNavigation';
import {
  GroupInventorySidebarNavigation,
  HotelHouseKeepingInventorySidebarNavigation,
  HotelInventorySidebarNavigation,
} from '../InventorySidebarNavigation';
import {RestaurantSidebarNavigation} from '../RestaurantSidebarNavigation';
import {GroupAdminSidebarNavigation} from '../GroupAdminSidebarNavigation';
import {HotelAdminSidebarNavigation} from '../HotelAdminSidebarNavigation';
import {InventoryAdminSidebarNavigation} from '../InventoryAdminSidebarNavigation';
import {useAppSelector} from '@app/hooks/reduxHooks';
import {HOTEL_SERVICE_MODULE_NAME, modulePermission} from '@app/utils/permissions';
import {HouseKeepingSidebarNavigation} from '../HouseKeepingSidebarNavigation';
import {ServiceAdminSidebarNavigation} from '../ServiceAdminSidebarNavigation';
import {filter, remove} from 'lodash';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
  expandMenu: (isExpand: boolean, navKey: string) => void;
}

const sideNavBar = (navigation: SidebarNavigationItem[]) => {
  const sidebarNavFlat = navigation.reduce(
    (result: SidebarNavigationItem[], current) =>
      result.concat(current.children && current.children.length > 0 ? current.children : current),
    [],
  );

  return sidebarNavFlat;
};

const SiderMenu: React.FC<SiderContentProps> = ({setCollapsed, expandMenu}) => {
  const userPermission = useAppSelector(state => state.user.permissions);
  const {hotelType, hotelId} = useAppSelector(state => state.hotelSlice.hotelConfig);
  const {serviceType} = useAppSelector(state => state.serviceSlice.serviceConfig);
  const {t} = useTranslation();
  const location = useLocation();
  const path: string = window.location.pathname;
  const splitPath = path.split('/');
  const maiPpath: string = splitPath && splitPath[1] ? splitPath[1] : path;

  const getHotelSidebarNavigation = () => {
    const defaultSideBar: SidebarNavigationItem[] = [...sidebarNavigation];
    hotelType === 'HOTEL' && remove(defaultSideBar, nav => nav.key === HOTEL_SERVICE_MODULE_NAME.EVENT);
    // const filterdPermitedMenu = filter(defaultSideBar, o => o.key === 'invoice');
    // hotelType === 'HOTEL' &&
    //   remove(filterdPermitedMenu[0]?.children, nav => nav.key === HOTEL_SERVICE_MODULE_NAME.EVENT_INVOICE);

    return defaultSideBar;
  };

  const hotelInventorySidebar =
    serviceType === 'HOUSEKEEPING' ? HotelHouseKeepingInventorySidebarNavigation : HotelInventorySidebarNavigation;

  const pageNavigation: SidebarNavigationItem[] =
    maiPpath === 'inventory'
      ? hotelId === 0
        ? GroupInventorySidebarNavigation
        : hotelInventorySidebar
      : maiPpath === 'inventory-admin'
      ? InventoryAdminSidebarNavigation
      : maiPpath === 'restaurant-admin'
      ? RestaurantSidebarNavigation
      : maiPpath === 'group-admin'
      ? GroupAdminSidebarNavigation
      : maiPpath === 'hotel-admin'
      ? HotelAdminSidebarNavigation
      : maiPpath === 'housekeeping'
      ? HouseKeepingSidebarNavigation
      : maiPpath === 'service-admin'
      ? ServiceAdminSidebarNavigation
      : getHotelSidebarNavigation();

  const currentMenuItem = sideNavBar(pageNavigation).find(({url}) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = pageNavigation.find(({children}) => children?.some(({url}) => url === location.pathname));
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  const getSubmenu = (isSubMenu: any, nav: SidebarNavigationItem) =>
    isSubMenu &&
    nav.children &&
    nav.children
      .map(childNav => {
        const permission = modulePermission(userPermission, childNav.key);

        if (permission.VIEW)
          return {
            key: childNav.key,
            label: <Link to={childNav.url || ''}>{t(childNav.title)}</Link>,
            title: t(childNav.title),
            isSubMenu: childNav.isSubMenu,
            url: childNav.url,
          };
      })
      .filter(Boolean);

  const permittedMenu: SidebarNavigationItem[] = pageNavigation
    .map((nav: SidebarNavigationItem) => {
      const isSubMenu = nav.children?.length;
      const permission = modulePermission(userPermission, nav.key);

      if (permission.VIEW) {
        return {
          key: nav.key,
          title: t(nav.title),
          label: isSubMenu ? t(nav.title) : <Link to={nav.url || ''}>{t(nav.title)}</Link>,
          icon: nav.icon,
          url: nav.url,
          isSubMenu: nav.isSubMenu,
          children: getSubmenu(isSubMenu, nav),
        };
      }
    })
    .filter(Boolean);

  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onClick={() => setCollapsed(true)}
      items={permittedMenu.map(nav => {
        const isSubMenu = nav.children?.length;
        const isValidNav = nav.isSubMenu && nav.children?.length === 0 ? false : true;
        //

        if (isValidNav) {
          return {
            key: nav.key,
            title: t(nav.title),
            onTitleClick: () => {
              expandMenu && expandMenu(true, nav.key);
            },
            label: isSubMenu ? t(nav.title) : <Link to={nav.url || ''}>{t(nav.title)}</Link>,
            icon: nav.icon,
            children:
              isSubMenu &&
              nav.children &&
              nav.children !== undefined &&
              nav.children.map(childNav => {
                return {
                  key: childNav.key,
                  label: <Link to={childNav.url || ''}>{t(childNav.title)}</Link>,
                  title: t(childNav.title),
                };
              }),
          };
        } else {
          return null;
        }
      })}
    />
  );
};

export default SiderMenu;
