/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {FC, useState} from 'react';
import * as S from './PriceCalculator.style';
import {isEmpty} from 'lodash';
import {BsCashStack} from 'react-icons/bs';
import {BASE_COLORS} from '@app/styles/themes/constants';
import {convertNumberFormatWithDecimal} from '@app/utils/utils';
import {Col, Collapse, Divider, Row, Tag} from 'antd';
import {BaseForm} from '../forms/BaseForm/BaseForm';
import {Checkbox} from '../Checkbox/Checkbox';
import {Option, Select} from '../selects/Select/Select';
import {Button} from '../buttons/Button/Button';
import {Popconfirm} from '../Popconfirm/Popconfirm';

interface Props {
  currency: string;
  totalAmount: number;
  isVatApplicable: boolean;
  taxArray: any[];
  totalTaxAmount: number;
  activityDetailsList: any[];
  paymentStatus?: boolean;
  isRetiveAction?: boolean;
  handlePayNow?: (data: any) => void;
  isServiceBooking?: boolean;
}

const {Panel} = Collapse;

const CreateServicePriceCalculator: FC<Props> = ({
  currency,
  totalAmount,
  taxArray,
  totalTaxAmount,
  activityDetailsList,
  paymentStatus,
  isRetiveAction = false,
  handlePayNow,
  isServiceBooking = false,
}) => {
  const [isReadyToPay, setisReadyToPay] = useState(false);
  const [paymentMethod, setpaymentMethod] = useState('CASH');

  return (
    <S.BlurCardWrapper>
      <S.BlueCard>
        <S.Padding>
          <S.PriceWrapper>
            <S.TotalPriceTitle>Total Price</S.TotalPriceTitle>
            <S.TotalPriceTitle>{`${currency} ${convertNumberFormatWithDecimal(totalAmount, 2)}`}</S.TotalPriceTitle>
          </S.PriceWrapper>

          {!isEmpty(activityDetailsList) ? (
            <S.ListTaxWrapper style={{marginTop: '1rem'}}>
              {activityDetailsList.map((service, idx) => {
                return (
                  <S.CustomCollape style={{padding: 0}} key={`tax-list${idx}`} ghost>
                    <Panel
                      header={
                        <S.ListTaxRow>
                          <S.TaxInfoText>{` ${service.activityName}`}</S.TaxInfoText>
                          <S.TaxInfoText>{`${currency} ${convertNumberFormatWithDecimal(
                            service.totalAmountWithDiscountAndWithoutTax,
                            2,
                          )}`}</S.TaxInfoText>
                        </S.ListTaxRow>
                      }
                      key={idx}>
                      <S.ListTaxRow key={`tax-list${idx}`}>
                        <S.ListTaxRow>
                          <S.TaxInfoText>Discount</S.TaxInfoText>
                          <S.TaxInfoText>{`${currency} ${convertNumberFormatWithDecimal(
                            service.discount,
                            2,
                          )}`}</S.TaxInfoText>
                        </S.ListTaxRow>
                      </S.ListTaxRow>
                    </Panel>
                  </S.CustomCollape>
                );
              })}
            </S.ListTaxWrapper>
          ) : null}

          {!isEmpty(taxArray) ? (
            <S.TaxInformationWapper>
              <S.TaxRightWrapper>
                <S.TaxInfoText>{`Excludes ${currency} ${convertNumberFormatWithDecimal(
                  totalTaxAmount,
                  2,
                )} in taxes and charges`}</S.TaxInfoText>
                <S.ListTaxWrapper>
                  {taxArray.map((tax, idx) => {
                    return (
                      <S.ListTaxRow key={`tax-list${idx}`}>
                        <S.TaxInfoText>{` ${tax.name}`}</S.TaxInfoText>
                        <S.TaxInfoText>{`${currency} ${convertNumberFormatWithDecimal(
                          tax.taxAmount,
                          2,
                        )}`}</S.TaxInfoText>
                      </S.ListTaxRow>
                    );
                  })}
                </S.ListTaxWrapper>
              </S.TaxRightWrapper>
            </S.TaxInformationWapper>
          ) : null}
          {isRetiveAction && (
            <>
              <Divider />
              {!paymentStatus ? (
                <>
                  <Row gutter={{xs: 10, md: 15, xl: 30}}>
                    <Col xs={24} sm={24} md={6} xl={12}>
                      <BaseForm.Item
                        valuePropName="checked"
                        name="paidStatus"
                        label="Payment status"
                        rules={[{required: false, message: 'payment status is required'}]}>
                        <Checkbox
                          onChange={e => {
                            setisReadyToPay(e.target.checked);
                          }}
                        />
                      </BaseForm.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} xl={12}>
                      <BaseForm.Item
                        name="paymentMethod"
                        label="Payment Method"
                        rules={[{required: true, message: 'Payment method is required'}]}>
                        <Select
                          onSelect={(value: any) => setpaymentMethod(value)}
                          size="small"
                          disabled={!isReadyToPay}
                          placeholder="Select payment method">
                          <Option value="CASH">Cash</Option>
                          <Option value="CREDITCARD">Credit card</Option>
                        </Select>
                      </BaseForm.Item>
                    </Col>
                  </Row>
                  <Row justify="end" gutter={{xs: 10, md: 15, xl: 30}}>
                    <Col>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to pay now?"
                        onConfirm={() => handlePayNow && handlePayNow(paymentMethod)}
                        okText="Yes"
                        cancelText="No">
                        <Button disabled={!isReadyToPay} type="primary">
                          Pay Now
                        </Button>
                      </Popconfirm>
                    </Col>
                  </Row>
                </>
              ) : (
                <Tag color="green">Amount Paid</Tag>
              )}
            </>
          )}
        </S.Padding>
      </S.BlueCard>
    </S.BlurCardWrapper>
  );
};

export default CreateServicePriceCalculator;
