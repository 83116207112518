/* eslint-disable @typescript-eslint/no-explicit-any */
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  getAllAvailableRooms,
  getAllCancellationPoliciesByReservationId,
  getAllReservedRoomsByReservationId,
  getPendingReservationStatus,
} from '@app/api/hotel/reservation/reservation.api';
import {RoomProps} from '@app/pages/Hotel/RoomReservation/RoomReservationPage';
import {FilterProps} from '@app/components/apps/roomFeed/interface';
import {IReservationList, IReservedRoomCancelModalData, IReservedRoomModalData} from './interface';
import {IConvertedReservedRoom} from '@app/pages/Hotel/RoomReservation/interface/interface';

export interface IReservationResponse {
  roomId: string;
  roomName: string;
  roomPrice: string;
  roomImageUrl: string[];
  roomTypeId: string;
  roomTypeImage: string;
  amenities: string;
  viewTypeId: string;
  viewTypeName: string;
  stayTypeId: string;
  stayTypeName: string;
  roomTypeName: string;
}

interface IPagination {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

interface ICheckDate {
  checkedIn: string;
  checkedOut: string;
}
export interface IResDueAmount {
  dueAmount: string;
}

export interface ReservationState {
  reservation: IReservationResponse[];
  loading: boolean;
  error: string | null;
  pagination: IPagination;
  selectedRooms: ISelectedRooms;
  markedRoom: number;
  checkedDate: ICheckDate;
  isVisibleReservationDrawer: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  roomDetails: any;
  current: number;
  reservationList: IReservationList[];
  modalData: IReservedRoomModalData;
  loadingModal: boolean;
  errorModal: string | null;
  cancelModalData: IReservedRoomCancelModalData;
  loadingCancelModal: boolean;
  errorCancelModal: string | null;
  isPendingAlertOpen: boolean;
  isPendingStatus: boolean;
  remainingTime: {expiredTime: string; expiredDate: string};
  reservationDueAmount: IResDueAmount;
}

const modalDataIntialState: IReservedRoomModalData = {
  reservationListResponse: {
    channelId: 0,
    channelName: '',
    checkInDate: '',
    checkInTime: '',
    checkOutDate: '',
    hotelId: 0,
    mainGuestFirstName: '',
    mainGuestId: 0,
    mainGuestLastName: '',
    paymentDone: false,
    refNumber: '',
    reservationCurrencyId: 0,
    reservationCurrencyPrefix: '',
    reservationId: 0,
    reservationStatus: '',
    channelEmail: '',
  },
  reservedRoomDetailsResponseList: [
    {
      adultCount: 0,
      checkInTimeReservedRoom: '',
      checkOutTime: '',
      checkOutTimeReservedRoom: '',
      childCount: 0,
      keyHandOver: false,
      keyRetrieval: false,
      mainGuest: {
        address: '',
        applicable: false,
        city: '',
        comments: '',
        countryId: 0,
        countryName: '',
        dateOfBirth: '',
        email: '',
        firstName: '',
        guestType: '',
        id: 0,
        idNumber: '',
        imageurl: '',
        lastName: '',
        lastVisit: '',
        nationality: '',
        nicNumber: false,
        phoneNumber: '',
        secondAddress: '',
        secondPhoneNumber: '',
        passportImageUrl: '',
      },
      otherGuestList: [
        {
          address: '',
          applicable: false,
          city: '',
          comments: '',
          countryId: 0,
          countryName: '',
          dateOfBirth: '',
          email: '',
          firstName: '',
          guestType: '',
          id: 0,
          idNumber: '',
          imageurl: '',
          lastName: '',
          lastVisit: '',
          nationality: '',
          nicNumber: false,
          phoneNumber: '',
          secondAddress: '',
          secondPhoneNumber: '',
          passportImageUrl: '',
        },
      ],
      meal: '',
      noOfAdults: 0,
      noOfChildren: 0,
      refNumber: '',
      reservationCurrencyId: 0,
      reservationCurrencyName: '',
      reservationCurrencyPrefix: '',
      reservedRoomId: 0,
      reservedRoomName: '',
      reservedRoomStatus: '',
      roomId: 0,
      roomNumber: '',
      roomTypeId: 0,
      roomTypeName: '',
      stayTypeId: 0,
      stayTypeName: '',
      viewTypeId: 0,
      viewTypeName: '',
      checkInDate: '',
      checkOutDate: '',
    },
  ],
};

const cancelDataIntialState: IReservedRoomCancelModalData = {
  reservationListResponse: {
    channelId: 0,
    channelName: '',
    checkInDate: '',
    checkInTime: '',
    checkOutDate: '',
    hotelId: 0,
    mainGuestFirstName: '',
    mainGuestId: 0,
    mainGuestLastName: '',
    paymentDone: false,
    refNumber: '',
    reservationCurrencyId: 0,
    reservationCurrencyPrefix: '',
    reservationId: 0,
    reservationStatus: '',
    channelEmail: '',
  },
  reservedRoomDetailsResponseList: [
    {
      adultCount: 0,
      checkInTimeReservedRoom: '',
      checkOutTime: '',
      checkOutTimeReservedRoom: '',
      childCount: 0,
      keyHandOver: false,
      keyRetrieval: false,
      mainGuest: {
        address: '',
        applicable: false,
        city: '',
        comments: '',
        countryId: 0,
        countryName: '',
        dateOfBirth: '',
        email: '',
        firstName: '',
        guestType: '',
        id: 0,
        idNumber: '',
        imageurl: '',
        lastName: '',
        lastVisit: '',
        nationality: '',
        nicNumber: false,
        phoneNumber: '',
        secondAddress: '',
        secondPhoneNumber: '',
        passportImageUrl: '',
      },
      otherGuestList: [
        {
          address: '',
          applicable: false,
          city: '',
          comments: '',
          countryId: 0,
          countryName: '',
          dateOfBirth: '',
          email: '',
          firstName: '',
          guestType: '',
          id: 0,
          idNumber: '',
          imageurl: '',
          lastName: '',
          lastVisit: '',
          nationality: '',
          nicNumber: false,
          phoneNumber: '',
          secondAddress: '',
          secondPhoneNumber: '',
          passportImageUrl: '',
        },
      ],
      meal: '',
      noOfAdults: 0,
      noOfChildren: 0,
      refNumber: '',
      reservationCurrencyId: 0,
      reservationCurrencyName: '',
      reservationCurrencyPrefix: '',
      reservedRoomId: 0,
      reservedRoomName: '',
      reservedRoomStatus: '',
      roomId: 0,
      roomNumber: '',
      roomTypeId: 0,
      roomTypeName: '',
      stayTypeId: 0,
      stayTypeName: '',
      viewTypeId: 0,
      viewTypeName: '',
      cancellationPolicyResponseList: [
        {
          cancelDate: '',
          id: 0,
          name: '',
          noOfDays: 0,
          penaltyAmount: 0,
          percentage: 0,
          reason: '',
          refundAmount: 0,
          totalPrice: 0,
          reservationCurrencyPrefix: '',
          additionalTaxPrice: 0,
          reservedRoomDayPriceTaxResponse: [{amount: 0, rate: 0, serviceCharge: false, taxId: 0, taxName: ''}],
          serviceChargeTaxPrice: 0,
        },
      ],
    },
  ],
};

const initialState: ReservationState = {
  reservation: [],
  loading: false,
  error: null,
  pagination: {pageNumber: 0, pageSize: 0, totalPages: 0, totalRecords: 0},
  selectedRooms: {
    checkedIn: '',
    checkedOut: '',
    stayTypes: [],
    selectedRoomTypes: [],
    extraChild: [],
    channelId: 0,
    isResident: true,
  },
  markedRoom: 0,
  checkedDate: {
    checkedIn: '',
    checkedOut: '',
  },
  isVisibleReservationDrawer: false,
  roomDetails: {},
  current: 0,
  reservationList: [],
  modalData: modalDataIntialState,
  errorModal: null,
  loadingModal: false,
  cancelModalData: cancelDataIntialState,
  errorCancelModal: null,
  loadingCancelModal: false,
  isPendingAlertOpen: false,
  isPendingStatus: false,
  remainingTime: {expiredTime: '', expiredDate: ''},
  reservationDueAmount: {
    dueAmount: '',
  },
};

export interface IExtraChild {
  name: string;
  extraChildCount: number;
  extraChildPrice: number;
  numberOfAdult: number;
  numberOfChildren: number;
  roomType: string;
  mealType: string;
}

export interface ISelectedRooms {
  expiredDate: any;
  checkedIn: string;
  checkedOut: string;
  searchAdultCount: number;
  searchChildCount: number;
  stayTypes: IConvertedReservedRoom[];
  selectedRoomTypes: string[];
  extraChild: IExtraChild[];
  channelId: number;
  expiredTime: string;
  id: number;
  extraChildCountInRooms: any;
  isResident: boolean;
  vatRegistry: {
    email: string;
    registryType: string[];
    hotelId: number;
    vatNumber: number;
    onlyThisHotelView: boolean;
    name: string;
    address: string;
  };
  isVatApplicable: boolean;
  isVatDetailsApplicable: boolean;
  reserveType: string;
}

export const fetchReservation = createAsyncThunk<
  {reservation: IReservationResponse[]; pagination: any},
  {hotelId: number; filterPayload: FilterProps},
  {rejectValue: string}
>('reservation/fetchReservation', async ({hotelId, filterPayload}, {rejectWithValue}) => {
  try {
    const {result, pagination} = await getAllAvailableRooms(hotelId, filterPayload);
    const reservations = result?.reservation || [];
    const parsedReservations: IReservationResponse[] = reservations.map((room: RoomProps) => {
      return room;
    });

    return {reservation: parsedReservations, pagination: pagination};
  } catch (error) {
    return rejectWithValue('Failed to fetch reservation');
  }
});

export const getReservedRoomModalData = createAsyncThunk<
  {reservedRoom: IReservedRoomModalData},
  {reservationId: number; status: string[]},
  {rejectValue: string}
>('reservation/getReservedRoomModalData', async ({reservationId, status}, {rejectWithValue}) => {
  try {
    const {result} = await getAllReservedRoomsByReservationId(reservationId, status);
    const reservedRooms = result?.reservedRoom || [];

    return reservedRooms;
  } catch (error) {
    return rejectWithValue('Failed to fetch reservation');
  }
});

export const getCancelModalData = createAsyncThunk<
  {cancelReservedRoom: IReservedRoomCancelModalData},
  {reservationId: number; hotelId: number},
  {rejectValue: string}
>('reservation/getCancelModalData', async ({reservationId, hotelId}, {rejectWithValue}) => {
  try {
    const {result} = await getAllCancellationPoliciesByReservationId(reservationId, hotelId);
    const cancelReservedRoom = result?.cancelReservedRoom || [];

    return cancelReservedRoom;
  } catch (error) {
    return rejectWithValue('Failed to fetch reservation');
  }
});

export const getPendingResStatus = createAsyncThunk(
  'reservation/getPendingResStatus',
  async (hotelId: number, {dispatch}) => {
    const results = await getPendingReservationStatus(hotelId);

    dispatch(setPendingReservationStatus(results.result.reservation));
    return results.result.reservation;
  },
);

export const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    setSelectedRooms: (state, action: PayloadAction<ISelectedRooms>) => {
      state.selectedRooms = action.payload;
    },
    setMarkedRoom: (state, action: PayloadAction<number>) => {
      state.markedRoom = action.payload;
    },
    setCheckedDate: (state, action: PayloadAction<ICheckDate>) => {
      state.checkedDate = action.payload;
    },
    setVisibleReservationDrawer: (state, action: PayloadAction<boolean>) => {
      state.isVisibleReservationDrawer = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setRoomDetails: (state, action: PayloadAction<any>) => {
      state.roomDetails = action.payload;
    },
    setCurrent: (state, action: PayloadAction<number>) => {
      state.current = action.payload;
    },
    setReservedRoomList: (state, action: PayloadAction<IReservationList[]>) => {
      state.reservationList = action.payload;
    },
    setPendingAlertOpen: (state, action: PayloadAction<boolean>) => {
      state.isPendingAlertOpen = action.payload;
    },
    setPendingReservationStatus: (state, action: PayloadAction<boolean>) => {
      state.isPendingStatus = action.payload;
    },
    setRemainingTime: (state, action: PayloadAction<any>) => {
      state.remainingTime = action.payload;
    },
    setReservationDueAmount: (state, action: PayloadAction<IResDueAmount>) => {
      state.reservationDueAmount = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchReservation.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchReservation.fulfilled, (state, action) => {
      state.loading = false;
      state.reservation = action.payload.reservation;
      state.pagination = action.payload.pagination;
    });
    builder.addCase(fetchReservation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getReservedRoomModalData.pending, state => {
      state.loadingModal = true;
      state.errorModal = null;
    });
    builder.addCase(getReservedRoomModalData.fulfilled, (state, action) => {
      state.loadingModal = false;
      state.modalData = action.payload;
    });
    builder.addCase(getReservedRoomModalData.rejected, (state, action) => {
      state.loadingModal = false;
      state.errorModal = action.payload as string;
    });
    builder.addCase(getCancelModalData.pending, state => {
      state.loadingCancelModal = true;
      state.errorCancelModal = null;
    });
    builder.addCase(getCancelModalData.fulfilled, (state, action) => {
      state.loadingCancelModal = false;
      state.cancelModalData = action.payload;
    });
    builder.addCase(getCancelModalData.rejected, (state, action) => {
      state.loadingCancelModal = false;
      state.errorCancelModal = action.payload as string;
    });
  },
});

export const {
  setSelectedRooms,
  setMarkedRoom,
  setCheckedDate,
  setVisibleReservationDrawer,
  setRoomDetails,
  setCurrent,
  setReservedRoomList,
  setPendingAlertOpen,
  setPendingReservationStatus,
  setRemainingTime,
  setReservationDueAmount,
} = reservationSlice.actions;
export default reservationSlice.reducer;
