import styled, {css} from 'styled-components';
import {Col, Segmented} from 'antd';
import {BASE_COLORS, BORDER_RADIUS, FONT_SIZE, FONT_WEIGHT, LAYOUT, media} from '@app/styles/themes/constants';
import {Card as CommonCard} from '@app/components/common/Card/Card';
import generateCalendar from 'antd/lib/calendar/generateCalendar';
import {AppDate} from '@app/constants/Dates';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';

const AntCalendar = generateCalendar<AppDate>(dayjsGenerateConfig);
interface Event {
  $isPast: boolean;
}

export const RightSideCol = styled(Col)`
  padding: 0rem 0rem;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${LAYOUT.desktop.headerHeight});
  background-color: var(--sider-background-color);
  overflow-y: auto;
  border-radius: 10px;
`;

export const LeftSideCol = styled(Col)`
  @media only screen and ${media.xl} {
    height: calc(100vh - ${LAYOUT.desktop.headerHeight});
  }
`;

export const SegmentedArea = styled(Segmented)`
  .ant-segmented-item-selected {
    background-color: #005eb4;
    color: #fff;
  }
  .ant-segmented-item {
    transition: red 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-segmented-thumb {
    background-color: #005eb4;
  }
`;

export const Space = styled.div`
  margin: 1rem 0;
`;

export const TopCatWrap = styled.div``;

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;

  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 15px;

  background: black;

  min-height: 300px;
  overflow-y: auto;
`;

export const Item = styled.div`
  background: red;
  height: 150px;
  flex-shrink: 0;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content flex-start;
  flex-wrap:wrap;
  gap: 15px;
  overflow-y: auto;
  box-shadow: 5px 5px 5px #bab9b6,
`;
export const ScrollCardWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 300px;
  padding: 4px;
  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`;
export const Card = styled(CommonCard)`
  width: 100%;
  margin-bottom: 1.25rem;
  .ant-card-head-title {
    font-size: 1rem;
  }
  .ant-card-body {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    align-items: center;
    padding: 10px 40px;
  }
  .ant-card-body:before {
    display: none;
  }
  .ant-card-body:after {
    display: none;
  }
  &.ant-card-bordered {
    border: 1px solid var(--border-color);
  }
`;

export const RoomCardContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
`;

export const FadeIn = styled.div`
  animation: fadeInAnimation 1s linear;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const GraphOutline = styled.div`
  border: 1px solid #01509a;
  background-color: #01509a;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Event = styled.div<Event>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color:${BASE_COLORS.green}
  font-weight: ${FONT_WEIGHT.bold};
  box-shadow: 0 5px 15px rgba(0, 89, 171, 0.3);
  ${props =>
    props.$isPast
      ? css`
          color: var(--text-light-color);
          background-color: ${BASE_COLORS.green};
        `
      : css`
          color: var(--white);
        `};

  border-radius: ${BORDER_RADIUS};
  background-color: ${BASE_COLORS.green};
`;

export const Calendar = styled(AntCalendar)`
  .ant-picker-calendar-header {
    display: none;
  }

  .ant-picker-panel {
    border-top: none;

    & .ant-picker-body {
      padding: 0;
    }
  }

  .ant-picker-date-panel .ant-picker-content th {
    font-weight: ${FONT_WEIGHT.medium};

    font-size: ${FONT_SIZE.xs};

    color: var(--primary-color);
  }

  .ant-picker-cell {
    &.ant-picker-cell-today {
      .ant-picker-cell-inner {
        &::before {
          border-color: var(--secondary-color);
        }
      }
    }

    &.ant-picker-cell-selected {
      .ant-picker-cell-inner {
        box-shadow: 0 5px 15px rgba(0, 89, 171, 0.3);

        background: var(--primary-color);
        .ant-picker-calendar-date-value,
        ${Event} {
          color: var(--white);
        }
        .ant-picker-calendar-date-value,
        .ant-picker-calendar-date-content > div {
          background: var(--primary-color);
        }
      }
    }

    .ant-picker-cell-inner {
      font-weight: ${FONT_WEIGHT.medium};
      font-size: ${FONT_SIZE.xs};
      height: 1.875rem;
      width: 1.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
  }
`;

export const DashboardWrapper = styled.div`
  padding: 1rem;
`;
