import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {G2, Line, LineConfig} from '@ant-design/plots';
import {lineData} from '@app/utils/utils';

interface IData {
  date: string;
  type: string;
  value: number;
}
const StatisticsGraph = () => {
  const [data, setData] = useState<IData[]>([]);

  useEffect(() => {
    setData(lineData);
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    fetch('https://gw.alipayobjects.com/os/bmw-prod/c48dbbb1-fccf-4a46-b68f-a3ddb4908b68.json')
      .then(response => response.json())
      .then(json => console.log(json))
      .catch(error => {
        console.log('fetch data failed', error);
      });
  };

  G2.registerShape('point', 'breath-point', {
    draw(cfg, container) {
      const data: any = cfg.data;

      const point: any = {
        x: cfg.x,
        y: cfg.y,
      };
      const group: any = container.addGroup();

      if (data.value === 20 && data.date === '2023-4-14') {
        const decorator1 = group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 10,
            fill: cfg.color,
            opacity: 0.5,
          },
        });
        const decorator2 = group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 10,
            fill: cfg.color,
            opacity: 0.5,
          },
        });
        const decorator3 = group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 10,
            fill: cfg.color,
            opacity: 0.5,
          },
        });
        decorator1.animate(
          {
            r: 20,
            opacity: 0,
          },
          {
            duration: 1800,
            easing: 'easeLinear',
            repeat: true,
          },
        );
        decorator2.animate(
          {
            r: 20,
            opacity: 0,
          },
          {
            duration: 1800,
            easing: 'easeLinear',
            repeat: true,
            delay: 600,
          },
        );
        decorator3.animate(
          {
            r: 20,
            opacity: 0,
          },
          {
            duration: 1800,
            easing: 'easeLinear',
            repeat: true,
            delay: 1200,
          },
        );
        group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 6,
            fill: cfg.color,
            opacity: 0.7,
          },
        });
        group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 1.5,
            fill: cfg.color,
          },
        });
      } else {
        group.addShape('circle', {
          name: 'outer-point',
          attrs: {
            x: point.x,
            y: point.y,
            fill: cfg.color || 'red',
            opacity: 0.5,
            r: 6,
          },
        });
        group.addShape('circle', {
          name: 'inner-point',
          attrs: {
            x: point.x,
            y: point.y,
            fill: cfg.color || 'red',
            opacity: 1,
            r: 2,
          },
        });
      }

      return group;
    },
  });

  const config: LineConfig = {
    data,
    xField: 'date',
    yField: 'value',
    // yAxis: {
    //   label: {
    //     // 数值格式化为千分位
    //     formatter: (v: any) =>
    //       `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
    //   },
    // },
    xAxis: {
      title: {
        text: 'Dates For next 14 days ',
        style: {
          fontWeight: 'bold',
        },
      },

      label: {
        // autoRotate: true,
        // rotate: 90,
        style: (item, index, items) => {
          if (item === '2023-4-14') {
            return {fontWeight: 'bolder', fill: 'red'}; // Bold 2018 label
          } else if (item === '2023-4-9') {
            return {fontWeight: 'bolder', fill: 'green'};
          } else {
            return {fontWeight: 'normal'};
          }
        },
        // Prevent auto-rotation of x-axis labels
      },
    },
    yAxis: {
      title: {
        text: 'Quantity',
        style: {
          fontWeight: 'bold',
        },
      },
    },
    seriesField: 'type',
    color: ({type}: any) => {
      return type === 'Expected Quantity Level' ? '#F4664A' : type === 'Current Quantity' ? '#30BF78' : '#FAAD14';
    },
    lineStyle: ({type}: any) => {
      if (type === 'Expected Quantity Level') {
        return {
          lineDash: [4, 4],
          opacity: 1,
        };
      }

      return {
        opacity: 0.5,
      };
    },
    annotations: [
      // 低于中位数颜色变化
      //   {
      //     type: "bill",
      //     start: ["min", "median"],
      //     end: ["max", "0"],
      //     color: "#F4664A",
      //   },
      {
        type: 'text',
        position: [0, 20],
        content: 'Re-Order Level',
        offsetY: -4,
        style: {
          textBaseline: 'bottom',
        },
      },
      {
        type: 'line',

        start: [0, 20],
        end: [50, 20],
        style: {
          stroke: '#F4664A',
          lineDash: [2, 2],
        },
      },
    ],
    point: {
      shape: 'breath-point',
    },
  };

  return <Line {...config} />;
};

export default StatisticsGraph;
