import axios from 'axios';
import {API_KEY_ENV} from './instance';

const loginInstance = axios.create({
  baseURL: `${process.env.NODE_ENV === 'production' ? API_KEY_ENV : process.env.REACT_APP_BASE_URL}:9080/`,
});

loginInstance.interceptors.request.use((config: any) => {
  config.headers = {...config.headers};

  return config;
});

export default loginInstance;
