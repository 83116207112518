import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {ChangePasswordForm} from '@app/components/auth/ChangePasswordForm/ChangePasswordForm';

const ChangePasswordPage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('common.newPassword')}</PageTitle>
      <ChangePasswordForm />
    </>
  );
};

export default ChangePasswordPage;
