import instance, {HOTEL_SERVICE} from '@app/api/instance';

export interface CurrencyExchangeRateRequest {
  id?: number;
  firstCurrency: number;
  isReservationCurrency: boolean;
  currencyId: number;
}

export interface CurrencyExchangeRateResponse {
  message: string;
  result: any;
  status: string;
  statusCode: string;
  pagination: any;
}

export const CreateCurrencyExchangeRate = (currencyExchangeRatePayload: CurrencyExchangeRateRequest): Promise<CurrencyExchangeRateResponse> =>
  instance.post<CurrencyExchangeRateResponse>(HOTEL_SERVICE + 'currency-exchange-rates', {...currencyExchangeRatePayload}).then(({data}) => data);

export const getAllCurrencyExchangeRates = (
  searchFeilds: any,
  currentPage?: number,
  pageSize?: number,
): Promise<CurrencyExchangeRateResponse> =>
  instance
    .get<CurrencyExchangeRateResponse>(
      HOTEL_SERVICE +
        `currency-exchange-rates/search?page=${currentPage}&size=${pageSize}&sortField=id&currencyPrefix=${
          searchFeilds?.currencyPrefix ? searchFeilds?.currencyPrefix : ''
        }&direction=DESC
  `,
    )
    .then(({data}) => data);

export const getAllCurrency = (searchFeilds: any): Promise<any> =>
  instance
    .get<any>(
      HOTEL_SERVICE +
        `currency/search?name=${
          searchFeilds.name ? searchFeilds.name : ''
        }&prefix=${searchFeilds.prefix ? searchFeilds.prefix : ''}&symbol=${
          searchFeilds.symbol ? searchFeilds.symbol : ''}
    `,
    )
    .then(({data}) => data);

export const UpdateCurrencyExchangeRate = (currencyExchangeRatePayload: CurrencyExchangeRateRequest): Promise<CurrencyExchangeRateResponse> =>
  instance.put<CurrencyExchangeRateResponse>(HOTEL_SERVICE + 'currency-exchange-rates', {...currencyExchangeRatePayload}).then(({data}) => data);

export const DeleteCurrencyExchangeRate = (id: number): Promise<CurrencyExchangeRateResponse> =>
  instance.delete<CurrencyExchangeRateResponse>(HOTEL_SERVICE + `currency-exchange-rates/${id}`).then(({data}) => data);
