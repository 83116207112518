import React from 'react';
import { DrawerProps as AntDrawerProps } from 'antd';
import { modalSizes } from 'constants/modalSizes';
import * as S from './Drawer.style';

interface DrawerProps extends AntDrawerProps {
  size?: 'default' | 'large';
}

export const Drawer: React.FC<DrawerProps> = ({ size = 'medium', children, ...props }) => {
  const drawerSize = Object.entries(modalSizes).find(sz => sz[0] === size)?.[1];

  return (
    <S.Drawer getContainer={false} width={drawerSize} {...props}>
      {children}
    </S.Drawer>
  );
};
