/* eslint-disable @typescript-eslint/no-explicit-any */
import {Modal} from '@app/components/common/Modal/Modal';
import {Button} from '@app/components/common/buttons/Button/Button';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {Input} from '@app/components/common/inputs/Input/Input';
import {Space, Select, Popconfirm} from 'antd';
import React, {FC, useEffect, useState} from 'react';

import {notificationController} from '@app/controllers/notificationController';
import {FONT_SIZE} from '@app/styles/themes/constants';
import {convertNumberFormatWithDecimal} from '@app/utils/utils';
import {updateEventDiscount} from '@app/api/hotel/additionalActivities/additionalActivities.api';
import {ConvertedDataProps, IUpdateDiscountPayload} from '../helper/interface';
import {IServicesBookinsData} from '../interface';
import {updateDiscount} from '@app/api/hotel/additionalBookings/additionalBookings.api';

const {Option} = Select;

interface Props {
  serviceData: IServicesBookinsData;
  reloadModalData: () => void;
  isDisable: boolean;
  reservationData: ConvertedDataProps;
}

const ApplyServiceDiscount: FC<Props> = ({reloadModalData, isDisable, serviceData, reservationData}) => {
  const DISCOUNT_OPTION = {
    AMOUNT: 'AMOUNT',
    PERCENTAGE: 'PERCENTAGE',
  };
  const [discountForm] = BaseForm.useForm();
  const [discountOption, setDiscountOption] = useState(DISCOUNT_OPTION.AMOUNT);
  const [freeofCharge, setFreeofCharge]: any = useState(false);

  const [amountInput, setamountInput]: any = useState('');
  const [optionInput, setoptionInput] = useState('');
  const [isVisible, setIsVisible]: any = useState(false);

  const findResObj: any = serviceData;

  const validateRoomPrice = findResObj?.totalPriceWithOutDiscount;

  const handleChangeDiscount = async () => {
    const formValues = discountForm.getFieldsValue();
    const discountAmount = formValues?.discountAmount;
    const discountType = formValues?.discountOption;

    const payload: IUpdateDiscountPayload = {
      discount: Number(discountAmount),
      discountType: discountType,
      id: reservationData.id,
      reservedServiceId: serviceData.id,
    };

    console.log(payload);

    try {
      const response = await updateDiscount(payload);
      if (response.statusCode === '20000') {
        notificationController.success({message: response.message});
        reloadModalData();
        discountForm.resetFields();
        setIsVisible(false);
      } else {
        notificationController.error({message: response.message});
      }
    } catch (error) {}
  };

  const onCancelClick = () => {
    discountForm.resetFields();
    setIsVisible(false);
    setDiscountOption(DISCOUNT_OPTION.AMOUNT);
  };

  useEffect(() => {
    const defaultDiscountOption = DISCOUNT_OPTION.AMOUNT;
    const {discountType, discount = 0} = findResObj;
    const isAmountDiscount = discountType === DISCOUNT_OPTION.AMOUNT;
    const isPercentageDiscount = discountType === DISCOUNT_OPTION.PERCENTAGE;

    const discountOption = isAmountDiscount || isPercentageDiscount ? discountType : defaultDiscountOption;

    setDiscountOption(discountOption);
    discountForm.setFieldsValue({
      discountOption,
      discountAmount: discount,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceData, isVisible, findResObj, discountForm]);

  const NUMBER_REGEX = /^\d*\.?\d*$/;

  return (
    <div>
      <Button
        type="primary"
        size="small"
        style={{
          borderRadius: '30px',
          //   height: '1.5rem',
          fontSize: '13px',
          backgroundColor: '#01509a',
          color: 'white',
        }}
        onClick={() => {
          setIsVisible(true);
          setFreeofCharge(false);
        }}>
        Discount
      </Button>
      <Modal
        size="small"
        title={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              gap: 5,
              fontSize: FONT_SIZE.xs,
            }}>
            <div>Apply discount for </div>
            <div> {findResObj?.additionalActivityName}</div>
          </div>
        }
        footer={
          <Space>
            <Button
              title="Close"
              onClick={() => {
                onCancelClick();
              }}>
              Close
            </Button>
            <Popconfirm
              placement="rightTop"
              title={`Are you sure to apply this discount?`}
              onConfirm={() => {
                discountForm.submit();
              }}
              okText="Yes"
              cancelText="No">
              <Button type="primary">Apply</Button>
            </Popconfirm>
          </Space>
        }
        open={isVisible}
        onCancel={onCancelClick}>
        <BaseForm
          initialValues={{payable: false, discountOption: DISCOUNT_OPTION.AMOUNT}}
          onFinish={() => handleChangeDiscount()}
          form={discountForm}
          onValuesChange={(changedValues, allFields) => {
            const name = Object.keys(changedValues)[0];
            const value = Object.values(changedValues)[0];
            console.log('onValuesChange');

            if (name === 'payable') {
              if (value) {
                discountForm.resetFields(['discountOption']);
                discountForm.setFieldValue('discountAmount', findResObj.totalPrice);

                setDiscountOption(DISCOUNT_OPTION.AMOUNT);
              }
            } else if (name === 'discountOption') {
              discountForm.resetFields(['discountAmount']);
            }
          }}>
          {/* <BaseForm.Item
            name="payable"
            label="Free of charge"
            valuePropName="checked"
            rules={[
              {
                required: false,
                message: 'Required field',
              },
            ]}>
            <Checkbox
              //   value={checkBoxValue}
              //   checked={checkBoxValue}
              onChange={e => {
                setFreeofCharge(e.target.checked);
                setcheckBoxValue(e.target.checked);
              }}
            />
          </BaseForm.Item> */}

          <BaseForm.Item name="discountOption" label="Discount Option" rules={[{required: false}]}>
            <Select
              disabled={freeofCharge}
              style={{width: '82%'}}
              placeholder="Select option"
              onSelect={value => {
                console.log({value});

                setDiscountOption(value);
                setoptionInput(value);
              }}>
              <Option value={DISCOUNT_OPTION.AMOUNT}>Amount</Option>
              <Option value={DISCOUNT_OPTION.PERCENTAGE}>Percentage</Option>
            </Select>
          </BaseForm.Item>
          <BaseForm.Item
            name="discountAmount"
            label={discountOption === DISCOUNT_OPTION.AMOUNT ? 'Amount' : 'Percentage'}
            rules={[
              {
                required: false,
                message: 'Required field',
              },
              ({getFieldValue}) => ({
                validator(_, value) {
                  const inputValue: any = parseFloat(value);

                  if (discountOption === DISCOUNT_OPTION.AMOUNT) {
                    const input = value.trim();
                    if (!input.match(NUMBER_REGEX)) {
                      return Promise.reject('Please enter numbers only');
                    }
                    if (inputValue < 0) {
                      return Promise.reject('Amount must be greater than or equal to 0');
                    }
                    if (inputValue > Number(validateRoomPrice)) {
                      return Promise.reject(
                        `Amount must be less than or equal to ${convertNumberFormatWithDecimal(validateRoomPrice, 2)}`,
                      );
                    }
                    if (inputValue === '') {
                      return Promise.reject('Please enter value');
                    }
                  } else if (discountOption === DISCOUNT_OPTION.PERCENTAGE) {
                    const input = value.trim();
                    if (!input.match(NUMBER_REGEX)) {
                      return Promise.reject('Please enter numbers only');
                    }
                    if (inputValue < 0) {
                      return Promise.reject('Percentage must be greater than or equal to 0');
                    }

                    if (inputValue > 100) {
                      return Promise.reject(`Percentage must be less than or equal to 100`);
                    }
                    if (value === '') {
                      return Promise.reject('Please enter value');
                    }
                  }

                  return Promise.resolve();
                },
              }),
            ]}>
            <Input
              //   value={amountInput}
              onChange={e => setamountInput(e.target.value)}
              disabled={freeofCharge}
              style={{width: '82%'}}
              placeholder={discountOption === DISCOUNT_OPTION.AMOUNT ? 'Amount' : 'Percentage'}
            />
          </BaseForm.Item>
        </BaseForm>
      </Modal>
    </div>
  );
};

export default React.memo(ApplyServiceDiscount);
