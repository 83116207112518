import React from 'react';
import {useTranslation} from 'react-i18next';
import {LoginForm} from '@app/components/auth/LoginForm/LoginForm';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {SerendipityLogo} from '@app/assets';
import {Image} from 'antd';
import {useParams} from 'react-router';

const LoginPage: React.FC = () => {
  const {t} = useTranslation();

  const params = useParams();

  return (
    <>
      <PageTitle>{t('common.login')}</PageTitle>

      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignSelf: 'flex-start',
        }}>
        <Image preview={false} src={SerendipityLogo} />
      </div>

      <div
        style={{
          boxShadow: '8px 8px #e3dcdc',
          border: '1px solid',
          borderColor: 'black',
          borderRadius: 10,
        }}>
        <LoginForm defaultUserName={params?.userName} />
      </div>
    </>
  );
};

export default LoginPage;
