import React, {useEffect, useState} from 'react';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from './VatRegistry.Style';
import {AutoComplete, Col, FormInstance, Row} from 'antd';
import {Input, TextArea} from '@app/components/common/inputs/Input/Input';
import {useAppDispatch} from '@app/hooks/reduxHooks';
import {setIsTouchAction, setLoading} from '@app/store/slices/commonSlice';
import {debounce, find} from 'lodash';
import {searchVatRegistryUser} from '@app/api/resturant/vatRegistry/vatRegiestry.api';

interface Props {
  form: FormInstance;
  getFormData: (formData: any) => void;
}

export const CustomerVatRegister: React.FC<Props> = ({form, getFormData}) => {
  const dispatch = useAppDispatch();

  const [readOnlyEmail, setReadOnlyEmail] = useState<boolean>(false);
  const [readOnlyVatNo, setReadOnlyVatNo] = useState<boolean>(false);
  const [disableInput, setDisableInput] = useState<boolean>(true);
  const [vatRegistryId, setVatRegistryId] = useState<number>(0);

  const [options, setOptions] = useState<{value: string; label: string}[]>([]);
  const [searchData, setSearchData] = useState<any[]>([]);
  const [selectVatObj, setSelectVatObj] = useState<any>({});

  // Define your debounced search function
  const handleSearch = debounce((value: string, name: string) => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) && name === 'email') {
      featchVatUser(value);
    } else if (name === 'vatNumber') {
      featchVatUserByVatNo(value);
    } else {
      setReadOnlyVatNo(false);
      setReadOnlyEmail(false);
      form.resetFields(['name', 'address', 'vatNumber']);
    }
  }, 600);

  const onFinish = () => {
    dispatch(setLoading(true));
    const formData = form.getFieldsValue();
    const data = find(searchData, res => res.email === formData.email);
    const obj = data ? data : {};

    getFormData({
      id: vatRegistryId,
      onlyThisHotelView: obj?.onlyThisHotelView,
      registryType: obj?.registryType,
      ...formData,
    });
  };

  const featchVatUser = async (value: string) => {
    try {
      const results = await searchVatRegistryUser(value, '');

      const searchResult: any[] = results?.result?.vatRegistry ? results?.result?.vatRegistry : [];
      setSearchData(searchResult);
      if (searchResult.length > 0) {
        const data = searchResult[0];
        form.setFieldsValue({name: data.name, vatNumber: data.vatNumber, address: data.address, email: data.email});

        setVatRegistryId(data.id);
        setReadOnlyVatNo(true);
        setDisableInput(false);
      } else {
        setDisableInput(false);
        setReadOnlyVatNo(false);
        setReadOnlyEmail(false);
        form.resetFields(['name', 'address', 'vatNumber']);
      }
    } catch (error) {}
  };

  const featchVatUserByVatNo = async (value: string) => {
    try {
      const results = await searchVatRegistryUser('', value);

      const searchResult: any[] = results?.result?.vatRegistry ? results?.result?.vatRegistry : [];
      if (searchResult.length > 0) {
        setSearchData(searchResult);
        const opt = searchResult.map(res => ({
          value: res.vatNumber,
          label: `${res.vatNumber}`,
        }));
        setOptions(opt);
        // const data = searchResult[0];
        // form.setFieldsValue({name: data.name, email: data.email, address: data.address, vatNumber: data.vatNumber});
        //
        // setVatRegistryId(data.id);
        // setReadOnlyEmail(true);
        // setDisableInput(false);
      } else {
        setOptions([]);
        setSearchData([]);
        // setDisableInput(false);
        // setReadOnlyVatNo(false);
        // setReadOnlyEmail(false);
        // form.resetFields(['name', 'address', 'email']);
      }
    } catch (error) {}
  };

  return (
    <BaseForm
      name="addTableCategory"
      form={form}
      onFinish={onFinish}
      onFieldsChange={(_: any, allFields: any[]) => {
        dispatch(setIsTouchAction(true));
      }}
      onValuesChange={(changedValues, allFields) => {
        const name = Object.keys(changedValues)[0];
        const value: any = Object.values(changedValues)[0];

        if (name === 'email') {
          handleSearch(value, name);
        } else if (name === 'vatNumber' && !form.getFieldValue('email')) {
          const data = find(searchData, res => res.vatNumber === value);
          if (data) {
            setSelectVatObj(data);
            form.setFieldsValue({name: data.name, email: data.email, address: data.address, vatNumber: data.vatNumber});
            setVatRegistryId(data.id);
            setReadOnlyEmail(true);
            setDisableInput(false);
          } else {
            setSelectVatObj({});
            setDisableInput(false);
            setReadOnlyVatNo(false);
            setReadOnlyEmail(false);
            form.resetFields(['name', 'address', 'email']);
          }
        }
      }}>
      <S.FormContent>
        <Row gutter={{xs: 10, md: 15, xl: 30}}>
          <Col xs={24} sm={24} md={12} xl={12}>
            <BaseForm.Item
              name="email"
              label="Email"
              rules={[{required: true, type: 'email', message: 'Email is required'}]}>
              <Input placeholder="Enter email" readOnly={readOnlyEmail} />
            </BaseForm.Item>
          </Col>
          <Col xs={24} md={12}>
            <BaseForm.Item name="name" label={'Name'} rules={[{required: true, message: 'Name is required'}]}>
              <Input placeholder="Enter name" disabled={disableInput} readOnly={readOnlyEmail || readOnlyVatNo} />
            </BaseForm.Item>
          </Col>

          <Col xs={24} md={12}>
            <BaseForm.Item
              name="vatNumber"
              label={'VAT Number'}
              rules={[{required: true, message: 'VAT number is required'}]}>
              {/* <Input placeholder="Enter vat number" readOnly={readOnlyVatNo} /> */}
              <AutoComplete
                style={{width: '100%'}}
                onSearch={value => {
                  !form.getFieldValue('email') && handleSearch(value, 'vatNumber');
                }}
                placeholder="input here"
                options={options}
              />
            </BaseForm.Item>
          </Col>
          <Col xs={24} md={12}>
            <BaseForm.Item name="address" label={'Address'} rules={[{required: true, message: 'Address is required'}]}>
              <TextArea
                placeholder="Enter address"
                rows={2}
                disabled={disableInput}
                readOnly={readOnlyEmail || readOnlyVatNo}
              />
            </BaseForm.Item>
          </Col>
        </Row>
      </S.FormContent>
    </BaseForm>
  );
};
