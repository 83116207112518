/* eslint-disable @typescript-eslint/no-empty-function */
import {createAction, createSlice, PayloadAction, PrepareAction} from '@reduxjs/toolkit';
import {IuserAuthorization, IUserPermission, UserModel} from '@app/domain/UserModel';
import {persistUser, readUser} from '@app/services/localStorage.service';
import {RootState} from '@app/store/store';

// export interface UserState {
//   user: UserModel | null;
//   userPermission?: IuserAuthorization | null;
//   isAuthenticated: boolean;
// }
interface IinitialState {
  menuItems: any[];
  categoryLists: any[];
  allCategoryList: any[];
  categoryStatus: boolean;
  fromCard: boolean;
  silderStorIndex: number;
  isChangeNavOpt: boolean;
  firstLayerObj: any;
  secondLayerObj: any;
}

const initialState: IinitialState = {
  menuItems: [],
  categoryLists: [],
  allCategoryList: [],
  categoryStatus: false,
  fromCard: false,
  silderStorIndex: 0,
  isChangeNavOpt: false,
  firstLayerObj: {},
  secondLayerObj: {},
};

export const categoryNavSlice = createSlice({
  name: 'foodCard',
  initialState,
  reducers: {
    addMenuItem: (state, action: PayloadAction<{item: any}>) => {
      state.menuItems = [...state.menuItems, action.payload.item];
    },
    resetMenuItem: (state, action: PayloadAction<any>) => {
      state.menuItems = [];
    },

    setAllMenuItems: (state, action: PayloadAction<{items: any[]}>) => {
      state.menuItems = action.payload.items;
    },
    setCategoryData: (state, action: PayloadAction<{items: any[]}>) => {
      state.categoryLists = action.payload.items;
    },

    setCategoryStatus: (state, action: PayloadAction<{status: boolean}>) => {
      state.categoryStatus = action.payload.status;
    },

    setFromCard: (state, action: PayloadAction<{status: boolean}>) => {
      state.fromCard = action.payload.status;
    },

    AddAllCategoryList: (state, action: PayloadAction<{items: any[]}>) => {
      state.allCategoryList = [...state.allCategoryList, action.payload.items];
    },
    setChefSliderStorIndex: (state, action: PayloadAction<{index: number}>) => {
      state.silderStorIndex = action.payload.index;
    },
    setChangeNavOption: (state, action: PayloadAction<boolean>) => {
      state.isChangeNavOpt = action.payload;
    },
    setFirstCatLayerSelectedId: (state, action: PayloadAction<any>) => {
      state.firstLayerObj = action.payload;
    },
    setSecondCatLayerSelectedId: (state, action: PayloadAction<any>) => {
      state.secondLayerObj = action.payload;
    },
  },
});

export const {
  addMenuItem,
  setCategoryData,
  resetMenuItem,
  setAllMenuItems,
  AddAllCategoryList,
  setCategoryStatus,
  setFromCard,
  setChefSliderStorIndex,
  setChangeNavOption,
  setFirstCatLayerSelectedId,
  setSecondCatLayerSelectedId,
} = categoryNavSlice.actions;
// Other code such as selectors can use the imported `RootState` type
// export const isAuthenticated = (state: RootState) => state.user.isAuthenticated;

export default categoryNavSlice.reducer;
