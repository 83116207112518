import restInstance, {RESTAURANT_SERVICE} from '@app/api/resturantInstance';

export const getAllPlacedOrders = (hotelServiceId: number): Promise<any> =>
  restInstance
    .get<GetResponse>(RESTAURANT_SERVICE + `orders/active?hotelServiceId=${hotelServiceId}&sortField=orderStatus`)
    .then(({data}) => data);
export const getAllPlacedOrdersNew = (hotelServiceId: number): Promise<any> =>
  restInstance.get<GetResponse>(RESTAURANT_SERVICE + `ordered-item-holdings/${hotelServiceId}`).then(({data}) => data);

export const getAllPlacedOrdersProcess = (hotelServiceId: number): Promise<any> =>
  restInstance.get<GetResponse>(RESTAURANT_SERVICE + `ordered-item-holdings/${hotelServiceId}`).then(({data}) => data);

export const getAllCountStatus = (hotelServiceId: number): Promise<any> =>
  restInstance.get<GetResponse>(RESTAURANT_SERVICE + `order/count/${hotelServiceId}`).then(({data}) => data);

export const rejectAllOrderItems = (orderId: number): Promise<any> =>
  restInstance.put<GetResponse>(RESTAURANT_SERVICE + `order-items/reject-all/${orderId}`).then(({data}) => data);

export const rejectOrderItem = (itemId: number, reason: string): Promise<any> =>
  restInstance
    .put<GetResponse>(RESTAURANT_SERVICE + `order-items/reject/${itemId}?reason=${reason}`)
    .then(({data}) => data);

export const rejectOrderItemNew = (itemId: number, reason: string): Promise<any> =>
  restInstance
    .put<GetResponse>(RESTAURANT_SERVICE + `ordered-item-holdings/reject/${itemId}?reason=${reason}`)
    .then(({data}) => data);

export const acceptAllOrders = (orderId: number): Promise<any> =>
  restInstance.put<GetResponse>(RESTAURANT_SERVICE + `order/accept/${orderId}`).then(({data}) => data);

export const changeStatus = (itemId: number, status: string): Promise<any> =>
  restInstance
    .put<GetResponse>(RESTAURANT_SERVICE + `order-items/status/${itemId}?orderedItemStatus=${status}`)
    .then(({data}) => data);

export const ReadyToServe = (payload: any): Promise<any> => {
  return restInstance.put<any>(RESTAURANT_SERVICE + 'order/status', payload).then(({data}) => data);
};

export const requestReConfirmation = (payload: any): Promise<any> => {
  return restInstance
    .put<any>(RESTAURANT_SERVICE + `order/request-confirmation/${payload}`, payload)
    .then(({data}) => data);
};

export const ReadyToServeKot = (payload: any): Promise<any> => {
  return restInstance.put<any>(RESTAURANT_SERVICE + `kot/ready-to-serve/${payload}`, payload).then(({data}) => data);
};

export const readyToServeBot = (payload: any): Promise<any> => {
  return restInstance.put<any>(RESTAURANT_SERVICE + `bot/ready-to-serve/${payload}`, payload).then(({data}) => data);
};

export const serveStatusChange = (payload: any): Promise<any> => {
  return restInstance.put<any>(RESTAURANT_SERVICE + `kot/serve/${payload.id}`, payload).then(({data}) => data);
};

export const serveBotStatusChange = (payload: any): Promise<any> => {
  return restInstance.put<any>(RESTAURANT_SERVICE + `bot/serve/${payload.id}`, payload).then(({data}) => data);
};

export interface GetResponse {
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: {orderItems: any};
  status: string;
  statusCode: string;
}
