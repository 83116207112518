const NAVIGATION = {
  CLEANING: {
    path: '/house-keeping/cleaning',
    name: 'Cleaning',
  },
  TROLLY: {
    path: '/house-keeping/Trolly',
    name: 'Trolley',
  },
  LAUNDRY: {
    path: '/house-keeping/laundry',
    name: 'Laundry',
  },
  CHECKED_OUT: {
    path: '/house-keeping/cleaning/checked-out/room-selection',
    name: 'Check-out',
  },
  OCCUPIED: {
    path: '/house-keeping/cleaning/occupied/room-selection',
    name: 'Occupied',
  },
  OCCUPIED_PROGRESS: {
    path: '/house-keeping/cleaning/occupied/progressing',
    name: 'In Progress',
  },
  CHECKED_OUT_PROGRESS: {
    path: '/house-keeping/cleaning/checked-out/progressing',
    name: 'In Progress',
  },
  FOR_LAUNDRY: {
    path: '/house-keeping/laundry/delivery',
    name: 'For Laundry',
  },
  FROM_LAUNDRY: {
    path: '/house-keeping/laundry/receive',
    name: 'From Laundry',
  },
  ONDEMAND_ROOMS: {
    path: '/house-keeping/cleaning/ondemand-rooms',
    name: 'On demand',
  },
};

export {NAVIGATION};
