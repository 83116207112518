/* eslint-disable react/jsx-key */
/* eslint-disable prefer-const */
import * as React from 'react';
import * as S from './DashboardPage.styles';
import {useTranslation} from 'react-i18next';
import {Row, Form, Space, Button, Col} from 'antd';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {IOrderItem} from '@app/api/getOrders.api';
import {SearchOutlined} from '@ant-design/icons';
import {find, remove, uniqBy} from 'lodash';
import RightArea from './RightArea';
import LeftArea from './LeftArea';
import {getCategoryStuctureByID} from '@app/api/resturant/tablecategory/categoryStucture.api';
import {notificationController} from '@app/controllers/notificationController';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {INewOrderList, addFoodItems, addTableItems, setNewOrderList} from '../slices/waiterDasboardSlice';
import TopLevelFilter from './TopLevelFilter';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {Input} from '@app/components/common/inputs/Input/Input';
import {searchFoodByName} from '@app/api/resturant/tablecategory/foodItems.api';
import {MdTableRestaurant} from 'react-icons/md';
import {useNavigate} from 'react-router';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProps {}

const WaiterDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const windowSize = React.useRef([window.innerWidth, window.innerHeight]);
  const foodItems = useAppSelector(state => state.waiterDasbordSlice.foodItems);

  const newOrderList = useAppSelector(state => state.waiterDasbordSlice.newOrderList);

  const selelctedTableId = useAppSelector(state => state.waiterDasbordSlice.selectedTableId);

  const [isReadyToServe, setIsReadyToServe] = React.useState<boolean>(false);

  const [form] = Form.useForm();

  const hotelServiceConfig = useAppSelector(state => state.hotelSlice.hotelServiceConfig);

  const getAllFoodItemsByCategory = async (id: any, name?: string) => {
    const results = await getCategoryStuctureByID(id, hotelServiceConfig.serviceId, name);
    if (results?.statusCode === '20000') {
      let data: any[] = [];

      results.result.category &&
        results.result.category.map((post: any, i: any) => {
          data.push({
            id: post.id,
            category: '',
            currency: 'LKR',
            itemImage: post.image,
            item: post.name,
            quantity: 1,
            commentQuantity: 0,
            price: post.price,
            orderedItemStatus: 'NEW',
            totalPrice: post.price,
            active: post.active,
          });
        });

      dispatch(addFoodItems({foodItems: data}));
    } else {
      notificationController.error({message: results?.message});
    }
  };
  const converFinalData = (data: INewOrderList[]) => {
    const updatedTableData = data.map(table => {
      const updatedFoodItemList = table.foodItemList.map(item => {
        const matchingItems = table.foodItemList.filter(foodItem => foodItem.uniqKey === item.uniqKey);
        const totalQuantity = matchingItems.reduce((sum, foodItem) => sum + foodItem.quantity, 0);
        const totalPrice = totalQuantity * item.price;

        return {
          ...item,
          totalPrice: totalPrice,
          quantity: totalQuantity,
        };
      });

      return {
        tableId: table.tableId,
        foodItemList: uniqBy(updatedFoodItemList, 'uniqKey'),
      };
    });
    return updatedTableData;
  };

  const onChangeItem = (item: IOrderItem, index?: any, quantity?: number) => {
    if (selelctedTableId) {
      const findObj = find(newOrderList, res => res.tableId === selelctedTableId);
      const defaultData: INewOrderList[] = [...newOrderList];
      const selectTableOrderList: INewOrderList = findObj
        ? findObj
        : {tableId: 0, orderId: 0, orderStatus: '', foodItemList: []};
      const initOrderList: IOrderItem[] = selectTableOrderList.foodItemList;

      const selectedItem = find(
        initOrderList,
        res => res.uniqKey === `${selelctedTableId}_${item.id}_${item.orderedItemStatus}`,
      );

      const extingComment = selectedItem ? selectedItem.comments : '';

      const newItem: IOrderItem = {
        id: 0,
        key: '',
        uniqKey: `${selelctedTableId}_${item.id}_${item.orderedItemStatus}`,
        quantity: item.quantity,
        item: item.item,
        itemId: item.id,
        currency: item.currency,
        itemImage: item.itemImage,
        comments: extingComment,
        commentQuantity: item.commentQuantity,
        orderedItemStatus: item.orderedItemStatus,
        selelctedTableId: selelctedTableId,
        price: item.price,
        category: item.category,
        totalPrice: item.price,
        hotelServiceId: hotelServiceConfig.serviceId,
        active: item.active,
      };
      if (findObj) {
        remove(defaultData, res => res.tableId === selelctedTableId);
        const xOrder = [newItem, ...initOrderList];
        // xOrder.push(newItem);
        const newTableObj: INewOrderList = {
          tableId: selelctedTableId,
          orderId: selectTableOrderList.orderId,
          orderStatus: selectTableOrderList.orderStatus,
          foodItemList: xOrder,
        };
        defaultData.push(newTableObj);
      } else {
        const xOrder = [newItem, ...initOrderList];
        // xOrder.push(newItem);
        const newTableObj: INewOrderList = {
          tableId: selelctedTableId,
          orderId: selectTableOrderList.orderId,
          orderStatus: selectTableOrderList.orderStatus,
          foodItemList: xOrder,
        };
        defaultData.push(newTableObj);
      }

      // addTableItems;
      dispatch(addTableItems({tableItems: defaultData}));
      dispatch(setNewOrderList({orderList: converFinalData(defaultData)}));
    } else {
      notificationController.warning({message: 'Please select a table'});
    }
  };

  const getFoodsByCategoryId = async (id: any) => {
    try {
      const result = await getCategoryStuctureByID(id, hotelServiceConfig.serviceId, '');
      const foodItems: any[] = [];
      result.result.category &&
        result.result.category.map((post: any, i: any) => {
          foodItems.push({
            id: post.id,
            category: '',
            currency: 'LKR',
            itemImage: post.image,
            item: post.name,
            quantity: 1,
            commentQuantity: 0,
            price: post.price,
            orderedItemStatus: 'NEW',
            totalPrice: post.price,
            active: post.active,
          });
        });
      dispatch(addFoodItems({foodItems: foodItems}));
    } catch (error) {}
  };

  const handleSearchFoods = (text: any) => {
    foodSearchByName(text);
  };

  const foodSearchByName = async (name: string, dropdownSearch?: boolean) => {
    try {
      const result = await searchFoodByName(name, hotelServiceConfig.serviceId);

      if (name.length === 0) {
        getFoodsByCategoryId(localStorage.getItem('categoryId'));
      } else {
        if (result?.statusCode === '20000') {
          if (dropdownSearch) {
            const dropDownItems: any[] = [];
            result.result.item &&
              result.result.item.map((post: any, i: any) => {
                dropDownItems.push({
                  value: post.id,
                  title: post.name,
                });
              });
          } else {
            const foodItems: any[] = [];

            result.result.item &&
              result.result.item.map((post: any, i: any) => {
                foodItems.push({
                  id: post.id,
                  category: '',
                  currency: 'LKR',
                  itemImage: post.image,
                  item: post.name,
                  quantity: 1,
                  commentQuantity: 0,
                  price: post.price,
                  orderedItemStatus: 'NEW',
                  totalPrice: post.price,
                  active: post.active,
                });
              });
            dispatch(addFoodItems({foodItems: foodItems}));
          }
        } else {
        }
      }
    } catch (error) {}
  };
  React.useEffect(() => {
    //
  }, []);

  const getReadyToServeStatus = (status: boolean) => {
    setIsReadyToServe(status);
  };

  const MemorizedInput = React.useMemo(
    () => (
      <BaseForm
        name="stepForm"
        form={form}
        onValuesChange={(info: any, data: any) => {
          handleSearchFoods(data.search);
        }}>
        <BaseForm.Item name="search">
          <Input
            size="small"
            suffix={<SearchOutlined />}
            placeholder="Search foods..."
            style={{
              width: '100%',
              marginTop: '1%',
              marginRight: '2%',
            }}
          />
        </BaseForm.Item>
      </BaseForm>
    ),

    [],
  );

  const desktopLayout = (
    <Row
      style={{
        width: '100%',
        background: '#fff',
        padding: 0,
        minHeight: `${windowSize.current[1] + 35}px`,
        // overflowY: 'scroll',
      }}>
      <Col sm={24} md={14} xl={17} xxl={17}>
        {/* <Space direction="vertical" size={30}> */}
        <div style={{position: 'sticky', top: -10, zIndex: 1, backgroundColor: 'white', marginTop: '-10px'}}>
          <div style={{height: '20px'}}></div>
          <TopLevelFilter />
        </div>

        <Row style={{overflowY: 'scroll', maxHeight: `${windowSize.current[1] - 120}px`}}>
          <div style={{marginTop: '10px'}}>
            <LeftArea
              onChangeItem={onChangeItem}
              getAllFoodItemsByCategory={getAllFoodItemsByCategory}
              orderFilterItems={foodItems}
            />
          </div>
        </Row>
        {/* </Space> */}
      </Col>

      <S.RightSideCol md={10} xs={24} xl={7} xxl={7}>
        <div style={{position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white'}}>
          <Row>
            <Col md={14} xl={14}>
              <div>{MemorizedInput}</div>
            </Col>
            <Col md={10} xl={10}>
              <S.BlinkButton
                isBlink={isReadyToServe}
                onClick={() => {
                  navigate('/toplevel/table-dashboard');
                }}
                style={{
                  marginLeft: '0.5rem',
                  padding: '5px 3px',
                  fontSize: '12px',
                  width: '95%',
                  position: 'relative',
                  backgroundColor: '#52c41a',
                }}
                size="small"
                icon={<MdTableRestaurant />}
                title="">
                Dashboard
              </S.BlinkButton>
            </Col>
          </Row>

          <div
            style={{
              border: '2px solid #32a1ce',
              minHeight: `${windowSize.current[1] - 25}px`,
              padding: '10px',
              borderRadius: '10px',
            }}>
            <RightArea getReadyToServeStatus={getReadyToServeStatus} />
          </div>
          {/* </Space> */}
        </div>
      </S.RightSideCol>
    </Row>
  );

  return (
    <>
      <PageTitle>Steward</PageTitle>
      {desktopLayout}
    </>
  );
};

export default React.memo(WaiterDashboard);
